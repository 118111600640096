import React, {Component} from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import $ from 'jquery';
import 'jquery-ui';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import 'jquery-ui/themes/base/datepicker.css';
import Select from 'react-select';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import SweetAlert from 'react-bootstrap-sweetalert';
import Constant from "../common/constants";
import {AllModules} from '@ag-grid-enterprise/all-modules';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
import 'ag-grid-enterprise';
import { withTranslation } from 'react-i18next';
import { getHyphenDDMMMYYYYHHMM, getHyphenDDMMMYYYY} from '../common/utils'; 
import {verifyext} from "../common/verifyext";
import getToolConfiguration from "./commonFunctions";
import redirectURL from '../redirectURL';
import GridButton from './gridButton';
import NAButton from './naButton';
import StaticButton from './static-button';

var infoBox = require('../common/google-infowindow');
const scaleanimation = {
  transition: 'all 1s easy-out'
}

var moment = require("moment");
var markersArray;
var map;
var mapcluster;

var overlay;
var infowindows=[];
var markersArraylist=[];

 class DispatchPlanSummary extends Component {
    constructor(props){
        super(props);
        this.state = {
            modules : AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
                resizable:true
            },
            frameworkComponents:{
                GridButton:GridButton,
                NAButton:NAButton,
            },
            rowData:[],
            latestData:[],
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
			detailCellRendererParams:{},
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:1000,
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            animateRows: true,
            showSlideBlockRoute:"sidebarcls",
            showSlideBlockUpload:"sidebarcls",
            showSlideBlockStack:"sidebarcls",
            overlayBlock:"show-n",
            routeData:[],
            rfreshmap:1,
            deliverpickupslist:[],
            dealers:[],
            plants:[],
            uploadfile:'',        
            alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
            loadplan:[],
            allLoadPlan:[],
            deliverydates:[],
            deliveryopts:[],
            filterDeliveryDate:{"value":"","label":"Select Delivery Date"},
            clusterlist:[],
            filtercluster:{"value":"","label":"Select Cluster"},
            vehicletypelist:[],
            filterVehicleType:{"value":"","label":"All"},
            mapcol:"show-n",
            gridocl:"col-sm-12",
            filter_date:"",
            vtypediv:"show-n",
            vsupplierdiv:"show-n",
            selectedCluster:"",
            fdealerlist:[],
            filterdealer:{"value":"","label":"All"},
            showloader:"show-n",
            mpnode:[],
            mpfor:"",
            clusterpoints:[],
            nmapcluster:"show-n",
            nmap:"show-m",
            scluster:"",
            stackContent:"",
            stackImage:"",
            stacktbl:[],
            stacktblbottom:[],
            stackOriginalData:[],
            showrunprocessbtn:"show-m",
            showanimate:"show-n",
            locationNames:[],
            loadFile:'',
            material_configurations:0,
            location:{label:"Select Location",value:""},
            loadshow:'show-m',
            overly:'show-m',
            processErrMsg : "",
            processErrShow : "",
            transactionId : "",
            droppedList : [],
            inputDetails:[],
            tool_config:[],
            defFlag:0,
            sla:{value:1,label:"SLA 24hrs"}
        }
        this.getDispatchPlanData = this.getDispatchPlanData.bind(this);
    }
    async componentDidMount() {
        $( ".datepicker" ).datepicker({
            dateFormat:"dd-mm-yy",
            changeYear:true
        });
        // $(".datepicker").val(moment.parseZone().format("DD-MM-YYYY"))
        let url_type = window.location.href
        // console.log(url_type,"url_type")
        let path_type = url_type.split('?')
        // console.log(path_type,"path_type")
        let data_type
        let path = path_type[path_type.length-1]

        // console.log(path,"path")
        if (path == 'inbound'){
            data_type = 1
        }else{
            data_type = 2
        }
        await this.setState({data_type : data_type})
        let params = {
            data_type : data_type
        }
        await getToolConfiguration(params)
        
        await setTimeout(async () => {
            await this.getDispatchPlanData()
        
        },500)
        let toolConfig = JSON.parse(localStorage.getItem('tlcfg'));
        var material_configurations = 0;
        if(toolConfig !=undefined && toolConfig !="")
        {
            if(toolConfig.length > 0)
            {
                material_configurations = toolConfig[0].material_configuration;
            }
        }
        this.setState({
            material_configurations : material_configurations
        });
        await this.boundTypeLocations(params)
        this.setState({
            loadshow:'show-n',
            overly:'show-n',
            overlayBlock:"show-n",
        })
    }

    getDispatchPlanData = () =>{
        this.setState({
            loadshow:'show-m',
            overly:'show-m',
            overlayBlock:"show-n",
        })
        var fromDate = $("#fromDate").val();
        var toDate = $("#toDate").val();
        var location = this.state.location.value;
        var sla = this.state.sla.value;
        if(location !== "")
        {
            var params = {
                from_date:fromDate,
                to_date:toDate,
                sla:sla,
                lcoation_code:location,
            }
            // console.log("params ", params)
            redirectURL.post('dispatch/getFilterPlanData',params)
            .then(async response =>{
                try {
                    var records = response.data;
                    this.setState({
                        rowData:records,
                        loadshow:'show-n',
                        overly:'show-n',
                        overlayBlock:"show-n",
                        defFlag:1
                    })
                }
                catch(e){}
            });
        }
        else{
            this.setState({
                show:true,
                basicTitle:'Please select pickup location',
                basicType:'danger',
                loadshow:'show-n',
                overly:'show-n',
                overlayBlock:"show-n",
            })
        }
    }

    boundTypeLocations = async(param) => {

      let locationNames = this.state.locationNames;
      await redirectURL.post('master/getBoundTypeLocations',param)
        .then(async (response) =>{
            if(response.data.status == 'success'){
                let boundLocations = response.data.boundLocations
                // locationNames.push({ value : "",
                //     label : "Select" })
                if(boundLocations.length > 0){
                    boundLocations.map(item =>{
                        locationNames.push({ value : item.location_code,
                            label : item.location_name })
                    })
                    await this.setState({
                        locationNames : locationNames,
                        location : locationNames[0]
                    })
                }
            }
        })
        
    }

    closeAlert = () => {
        this.setState({
            show: false
        });
    }
    
    onClickShowUpload = () =>{
        // this.setState({
        //     showSlideBlockUpload:"slide25",
        //     overlayBlock:"show-m"
        // })
        let url_type = window.location.href;
        let path_type = url_type.split('?')
        let path = path_type[path_type.length-1]
        window.location.href="loadprocessrun?"+path;
    }

    hideSlideBlock = () =>{
        this.setState({
            overlayBlock:"show-n",
            showSlideBlock:"",
            showSlideBlockUpload:"",
            showSlideBlockRoute:"",
            showSlideBlockStack:"",
            loadFile:'',
            loadshow:'show-n',
            overly:'show-n',
        })
        $("#uploadFile").val("");
        $("#notify_email").val("");
    }
    fixProcess = () => {
        this.setState({
            processErrMsg : "",
            processErrShow : 0,
            loadFile:''
        });
        $("#uploadFile").val("");
        $("#notify_email").val("");
        //document.getElementById("upform").reset();
    }
    ignoreProcessError = () => {
        this.setState({
            loadshow:'show-m',
            overly:'show-m',
        });
        var transactionId = this.state.transactionId;
        // console.log("transactionId",transactionId)
        var params = {
            transactionId : transactionId,
            data_type : this.state.data_type,
        }
        redirectURL.post("dispatch/generateLoadByTransactionId",params).then((response)=>{
            //console.log(response.data,"response")
            var statusCode = response.data.status;
            if(statusCode == 0 || statusCode == 1)
            {
                this.setState({                    
                    basicTitle : response.data.message,
                    basicType : "success",
                    show : true,
                    processErrMsg : "",
                    processErrShow : 0,
                    loadshow:'show-n',
                    overly:'show-n',
                    overlayBlock:"show-n",
                    showSlideBlockUpload:"",
                });
            }
            else
            {
                this.setState({
                    processErrMsg : response.data.message,
                    processErrShow : 1,
                    transactionId : response.data.transactionId,
                    loadshow:'show-n',
                    overly:'show-n',
                    overlayBlock:"show-n",
                });
            }
            //window.location.reload();
        });
    }
    
    changeFileHandler = async (e) => {
        console.log(e);
        this.setState({
            loadFile : e.target.files[0]
        });  
        
        var check = verifyext(e.target.files[0].name);
        //console.log("Check ", check)
        if(check == true)
        {
            if(e.target.files[0].type == '' ||e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
            {
                // console.log(fileData);
                // console.log(typeof(fileData))
                
            }
            else{
                e.target.value = null;
                this.setState({
                    uploadFile:'',
                    show: true, 
                    basicType:'danger', 
                    basicTitle:'Please upload file having extensions .csv only.',
                });
            }
        }
        else{
            e.target.value = null;
            this.setState({
                uploadFile:'',
                show: true, 
                basicType:'danger', 
                basicTitle:'Please upload file having extensions .csv only.',
            });
        }
        
    }
    
    uploadBulkFormHandler(event){
        event.preventDefault();
        var flag = 0;
        let saveData = new FormData();
        if(flag == 0)
        {      
            saveData.append("loadFile", this.state.loadFile);
            var notify_email = $("#notify_email").val();
            saveData.append("notify_email", notify_email);
            saveData.append("module_type", 1);
            saveData.append("data_type", this.state.data_type);
            saveData.append("material_configurations", this.state.material_configurations)
            let location = this.state.location;
            saveData.append("location_code", location.value)
            saveData.append("location_name", location.label)
            saveData.append("user_name", localStorage.getItem('username'))
            saveData.append("email", localStorage.getItem('email'))
            console.log(saveData,saveData);
            this.setState({
                loadshow:'show-m',
                overly:'show-m',
            });
            redirectURL.post("/dispatch/generateLoad",saveData,{
                headers:{
                    'content-type': 'multipart/form-data',
                    'Access-Control-Allow-Origin':"*",
                    'Access-Control-Allow-Methods':'POST',
                    'Access-Control-Allow-Headers': 'Origin, Content-Type'
                }
            }).then((response)=>{
                console.log(response,"responseFromProcess")
                var statusCode = response.data.status;
                if(statusCode == 8)
                {
                    this.setState({
                        processErrMsg : response.data.message,
                        processErrShow : 1,
                        transactionId : response.data.transactionId,
                        droppedList : response.data.droppedList,
                        statusCode : statusCode,
                        loadshow:'show-n',
                        overly:'show-n',
                        overlayBlock:"show-n",
                    });
                }
                else if(statusCode > 3 && statusCode != 8)
                {
                    if(statusCode == 11)
                    {
                        var processMsg = "Use the standard input data file template for uploading loads";
                    }
                    else
                    {
                        var processMsg = response.data.message;
                    }
                    this.setState({
                        processErrMsg : response.data.message,
                        processErrShow : 1,
                        transactionId : response.data.transactionId,
                        statusCode : statusCode,
                        loadshow:'show-n',
                        overly:'show-n',
                        overlayBlock:"show-n",
                    });
                }
                else
                {
                    this.checkTransactionStatus(response.data.transactionId);
                    // this.setState({                    
                    //     basicTitle : response.data.message,
                    //     basicType : "success",
                    //     show : true,
                    //     loadshow:'show-n',
                    //     overly:'show-n',
                    //     showSlideBlockUpload:"",
                    // });
                }
                //window.location.reload();
            });
        }
        else
        {
            this.setState({
                basicTitle : "Please upload the file",
                basicType : "warning",
                show : true,
            })
        }
    };

    checkTransactionStatus = (transactionId) =>{
        var query = {
            transaction_id : transactionId
        }
        redirectURL.post("/dispatch/checkTransactionStatus",query).then((response)=>{
            console.log(response,"responseFromcheckTransactionStatus")
            var statusCode = response.data.status;
            if(statusCode == 8)
            {
                this.setState({
                    processErrMsg : response.data.message,
                    processErrShow : 1,
                    transactionId : response.data.transactionId,
                    droppedList : response.data.droppedList,
                    statusCode : statusCode,
                    loadshow:'show-n',
                    overly:'show-n',
                    overlayBlock:"show-n",
                });
            }
            else if(statusCode > 3 && statusCode != 8)
            {
                this.setState({
                    processErrMsg : response.data.message,
                    processErrShow : 1,
                    transactionId : response.data.transactionId,
                    statusCode : statusCode,
                    loadshow:'show-n',
                    overly:'show-n',
                    overlayBlock:"show-n",
                });
            }
            else if(statusCode == 0)
            {
                var checkCount = this.state.checkCount;
                var updatedCnt = parseInt(checkCount)+1;
                this.setState({
                    checkCount : updatedCnt
                });
                console.log(updatedCnt,"checkCount")
                if(updatedCnt < 3)
                {
                    this.chkStatus(response.data.transactionId);
                }
                else
                {
                    this.setState({                    
                        basicTitle : "File is processing",
                        basicType : "success",
                        show : true,
                        loadshow:'show-n',
                        overly:'show-n',
                        overlayBlock:"show-n",
                        showSlideBlockUpload:"",
                        loadFile:''
                    })
                    $("#uploadFile").val("");
                    $("#notify_email").val("");
                }
            }
            else
            {
                this.setState({                    
                    basicTitle : response.data.message,
                    basicType : "success",
                    show : true,
                    loadshow:'show-n',
                    overly:'show-n',
                    overlayBlock:"show-n",
                    showSlideBlockUpload:"",
                });
                this.getDispatchPlanData()
            }
        });
    }

    chkStatus = (transactionId) => {
        this.checkTransactionStatus(transactionId);
    }

    viewPlanDetails = async(propdata) =>{
        console.log("propdata , ",propdata)
        if(propdata.data.data_processed == 1 || propdata.data.data_processed == 2 || propdata.data.data_processed == 3){
            if(propdata.colDef.field == "transaction_details")
            {
                let data_type = this.state.data_type;
                let location = this.state.location.value;
                let qry = {
                    dispatch_planning_type : data_type,
                    plant_location_code : location
                }
                localStorage.setItem('qry',JSON.stringify(qry))
                
                var data = propdata.data;
                let basestring = data.transaction_id;
                let encryptedstring = window.btoa(basestring);
                window.location.href = '/viewdispatchplandetails?'+encryptedstring;
            }
        }
    }

    viewOrderDetails = async(propdata) =>{
        var data = propdata.data;
        var order_id = data.order_id;
        var tool_config = data.tool_config;
        this.setState({
            tool_config : tool_config
        })
        var params = {
            order_id : order_id
        }
        redirectURL.post('dispatch/getTransactionInputDetails',params)
        .then(async response =>{
            var inputDetails = response.data;
            this.setState({
                inputDetails:inputDetails,
                showSlideBlockUpload:"slide45",
                overlayBlock:"show-m"
            })
        })
    }

    locationChanged = async(location) =>{
        await this.setState({
            location : location
        })
        // await this.getDispatchPlanData()
    }

    viewHistoryDetails = async(row) => { 
        // console.log(row,"row")
        var plannedcolsDefs1 = [
            {
                headerName: "Plan Id",
                field: "transaction_id",
                width:180,
                filter: true,
                resizable: true,
                editable:false
            },
            {
                headerName: "Date Created",
                field: "createDate",
                width:170,
                filter: true,
                resizable: true,
                editable:false,
                valueGetter:function(params){
                    if(params.data.createdon != ''){
                        return getHyphenDDMMMYYYYHHMM(params.data.createDate);
                    }
                    else{
                        return "";
                    }
                },
            },
            {
                headerName: "Status",
                field: "data_processed",
                width:200,
                filter: true,
                resizable: true,
                editable:false,
                valueGetter:function(params){
                    if(params.data.data_processed == 0)
                    {
                        return "Processing";
                    }
                    else if(params.data.data_processed == 1)
                    {
                        return "SOLVER_COMPLETE";
                    }
                    else if(params.data.data_processed == 2)
                    {
                        return "SOLVER_RECO_PROCESSED";
                    }
                    else if(params.data.data_processed == 3)
                    {
                        return "SOLVER_SOB_DONE";
                    }
                    else if(params.data.data_processed == 4)
                    {
                        return "SOLVER_INVALID_INPUT_DEALER_LOCATION";
                    }
                    else if(params.data.data_processed == 5)
                    {
                        return "SOLVER_INVALID_INPUT_MATERIALS";
                    }
                    else if(params.data.data_processed == 6)
                    {
                        return "SOLVER_INVALID_INPUT_DEALER_LOCATION_AND_MATERIALS";
                    }
                    else if(params.data.data_processed == 7)
                    {
                        return "SOLVER_INPUT_DATA_NOT_VALID";
                    }
                    else if(params.data.data_processed == 8)
                    {
                        return "SOLVER_FIX_DROPED_DEALERS";
                    }
                    else if(params.data.data_processed == 9)
                    {
                        return "SOLVER_VEHICLE_DETAILS_MISSING";
                    }
                    else if(params.data.data_processed == 10)
                    {
                        return "SOLVER_INPUT_FILE_DATA_ERROR";
                    }
                    else if(params.data.data_processed == 11)
                    {
                        return "SOLVER_INVALID_FILE_TEMPLATE";
                    }
                    else if(params.data.data_processed == 12)
                    {
                        return "PROCESS_TIMED_OUT";
                    }
                    else if(params.data.data_processed == 13)
                    {
                        return "SOLVER_SOB_FAIL";
                    }
                    else if(params.data.data_processed == 14)
                    {
                        return "SOLVER_RECO_FAILED";
                    }
                    else if(params.data.data_processed == 15)
                    {
                        return "SOLVER_NO_TOOL_CONFIG";
                    }
                    else if(params.data.data_processed == 16)
                    {
                        return "SOLVER_INVALID_TOOL_CONFIG";
                    }
                }
            },
            {
                headerName: "Optimization Type",
                field: "opti_type",
                width:150,
                filter: true,
                resizable: true,
                editable:false,
                valueGetter:function(params){
                    if(params.data.opti_type == 0 || params.data.opti_type == "0")
                    {
                        return "Optimize by Distance";
                    }
                    else if(params.data.opti_type == 1 || params.data.opti_type == "1"){
                        return "Optimize by Time";
                    }
                }
            },
            // {
            //     headerName: "Plan Distance (KM)",
            //     field: "total_plan_distance_km",
            //     width:200,
            //     filter: true,
            //     resizable: true,
            //     editable:false
            // },
            // {
            //     headerName: "Plan Time (MINS)",
            //     field: "total_plan_time_mins",
            //     width:200,
            //     filter: true,
            //     resizable: true,
            //     editable:false
            // },
            {
                headerName: "No. of Trucks Planned",
                field: "no_of_vehicles_planned",
                width:180,
                filter: true,
                resizable: true,
                editable:false
            },
            {
                headerName: "Non-Pallet Load (lbs)",
                field: "planned_load_cmt",
                width:180,
                filter: true,
                resizable: true,
                editable:false
            },
            {
                headerName: "Pallet Load (lbs)",
                field: "unplanned_load_cmt",
                width:150,
                filter: true,
                resizable: true,
                editable:false
            },
            // {
            //     headerName: "No. of Passengers Planned",
            //     field: "planned_load_cmt",
            //     width:150,
            //     filter: true,
            //     resizable: true,
            //     editable:false
            // },
            // {
            //     headerName: "No. of Passengers Ignored",
            //     field: "unplanned_load_cmt",
            //     width:150,
            //     filter: true,
            //     resizable: true,
            //     editable:false
            // },
            // {
            //     headerName: "Remarks",
            //     field: "remarks",
            //     width:200,
            //     filter: true,
            //     resizable: true,
            //     editable:false,
            //     valueGetter:function(params){
            //         if(params.data.remarks !=undefined && params.data.remarks !="")
            //         {
            //             return params.data.remarks;
            //         }
            //         else
            //         {
            //             return "N/A";
            //         }
            //     }
            // },
            {
                pinned:"left",
                headerName : "View Details",
                field:"transaction_details",
                width:150,
                resizable : true,
                editable : false,
                // params:{buttonName:'View',iconName:'fa fa-eye',
                // onClickFunction:this.viewPlanDetails},
                cellRenderer:function(params){
                    // console.log(":params ", params.data)
                    if(params.data.data_processed == 1 || params.data.data_processed == 2 || params.data.data_processed == 3)
                    {
                        return <StaticButton/>
                    }
                    else
                    { 
                        return <NAButton/>
                    }
                },
            }
            
        ]
        await this.setState({                        
            detailCellRendererParams:{
                detailGridOptions: {
                    columnDefs: plannedcolsDefs1,
                    overlayNoRowsTemplate: 'No rows to show',
                    onCellClicked:this.viewPlanDetails,
                    height: 200,
                },
                getDetailRowData:async function(param) {
                    param.successCallback([]);
                    await redirectURL.post("/dispatch/getPlanRunHistory",{
                        order_id : row.data.order_id
                    }).then(async (response) =>{
                        param.successCallback(response.data);							
                    }).catch(function(error){
                        console.log(error);
                    })                            
                },
                masterDetail: true
            }
        });
        if(row.colDef.field == "run_history")
        {
            row.node.setExpanded(!row.node.expanded);
        }
        else{    
            row.node.setExpanded(false);
        }
    }
    planNewDispatch = (row) => {
        let basestring = row.data.order_id;
        let encryptedstring = window.btoa(basestring);
        // window.location.href="/plannewdispatch?"+encryptedstring; 

        var selectDate = moment.parseZone(row.data.order_date).format("DD-MM-YYYY");
        let basestringDat = selectDate;
        let encryptedstringDate = window.btoa(basestringDat);
        window.location.href="/plannewdispatch?"+encryptedstring;
    }
    
    slaChanged(sla){
        this.setState({sla})
    }
    render() {
        const {t} = this.props
        var tool_config = this.state.tool_config;
        var material_configuration_chk = 0;
        if(tool_config !=undefined && tool_config !="")
        {
            material_configuration_chk = tool_config[0].material_configuration;
        }
        var plannedcolsDefs1 = [
            {
                headerName: "Plan Id",
                field: "transaction_id",
                width:180,
                filter: true,
                resizable: true,
                editable:false
            },
            {
                headerName: "Date Created",
                field: "createDate",
                width:170,
                filter: true,
                resizable: true,
                editable:false,
                valueGetter:function(params){
                    if(params.data.createdon != ''){
                        return getHyphenDDMMMYYYYHHMM(params.data.createDate);
                    }
                    else{
                        return "";
                    }
                },
            },
            {
                headerName: "Order Date",
                field: "order_date",
                width:170,
                filter: true,
                resizable: true,
                editable:false,
                valueGetter:function(params){
                    try {
                        if(params.data.order_date != ''){
                            return getHyphenDDMMMYYYY(params.data.order_date);
                        }
                        else{
                            return "";
                        }
                    } catch (error) {
                        
                    }
                    
                },
            },
            {
                headerName: "Status",
                field: "data_processed",
                width:200,
                filter: true,
                resizable: true,
                editable:false,
                valueGetter:function(params){
                    if(params.data.data_processed == 0)
                    {
                        return "Processing";
                    }
                    else if(params.data.data_processed == 1)
                    {
                        return "SOLVER_COMPLETE";
                    }
                    else if(params.data.data_processed == 2)
                    {
                        return "SOLVER_RECO_PROCESSED";
                    }
                    else if(params.data.data_processed == 3)
                    {
                        return "SOLVER_SOB_DONE";
                    }
                    else if(params.data.data_processed == 4)
                    {
                        return "SOLVER_INVALID_INPUT_DEALER_LOCATION";
                    }
                    else if(params.data.data_processed == 5)
                    {
                        return "SOLVER_INVALID_INPUT_MATERIALS";
                    }
                    else if(params.data.data_processed == 6)
                    {
                        return "SOLVER_INVALID_INPUT_DEALER_LOCATION_AND_MATERIALS";
                    }
                    else if(params.data.data_processed == 7)
                    {
                        return "SOLVER_INPUT_DATA_NOT_VALID";
                    }
                    else if(params.data.data_processed == 8)
                    {
                        return "SOLVER_FIX_DROPED_DEALERS";
                    }
                    else if(params.data.data_processed == 9)
                    {
                        return "SOLVER_VEHICLE_DETAILS_MISSING";
                    }
                    else if(params.data.data_processed == 10)
                    {
                        return "SOLVER_INPUT_FILE_DATA_ERROR";
                    }
                    else if(params.data.data_processed == 11)
                    {
                        return "SOLVER_INVALID_FILE_TEMPLATE";
                    }
                    else if(params.data.data_processed == 12)
                    {
                        return "PROCESS_TIMED_OUT";
                    }
                    else if(params.data.data_processed == 13)
                    {
                        return "SOLVER_SOB_FAIL";
                    }
                    else if(params.data.data_processed == 14)
                    {
                        return "SOLVER_RECO_FAILED";
                    }
                    else if(params.data.data_processed == 15)
                    {
                        return "SOLVER_NO_TOOL_CONFIG";
                    }
                    else if(params.data.data_processed == 16)
                    {
                        return "SOLVER_INVALID_TOOL_CONFIG";
                    }
                }
            },
            {
                headerName: "Optimization Type",
                field: "opti_type",
                width:150,
                filter: true,
                resizable: true,
                editable:false,
                valueGetter:function(params){
                    if(params.data.opti_type == 0 || params.data.opti_type == "0")
                    {
                        return "Optimize by Distance";
                    }
                    else if(params.data.opti_type == 1 || params.data.opti_type == "1"){
                        return "Optimize by Time";
                    }
                }
            },
            {
                headerName: "Total Planning Distance (KM)",
                field: "total_plan_distance_km",
                width:200,
                filter: true,
                resizable: true,
                editable:false
            },
            {
                headerName: "Total Planning Time (MINS)",
                field: "total_plan_time_mins",
                width:200,
                filter: true,
                resizable: true,
                editable:false
            },
            // {
            //     headerName: "No. of Trucks Planned",
            //     field: "no_of_vehicles_planned",
            //     width:180,
            //     filter: true,
            //     resizable: true,
            //     editable:false
            // },
            // {
            //     headerName: "Non-Pallet Load (lbs)",
            //     field: "planned_load_cmt",
            //     width:180,
            //     filter: true,
            //     resizable: true,
            //     editable:false,
            //     valueGetter:function(params){
            //         try {
            //             if(params.data.planned_load_cmt !== "" && params.data.planned_load_cmt !== undefined)
            //             {
            //                 return Math.round(params.data.planned_load_cmt);
            //             }
            //             else{
            //                 return "";
            //             }
            //         } catch (error) {
                        
            //         }
            //     }
            // },
            // {
            //     headerName: "Pallet Load (lbs)",
            //     field: "unplanned_load_cmt",
            //     width:150,
            //     filter: true,
            //     resizable: true,
            //     editable:false,
            //     valueGetter:function(params){
            //         try {
            //             if(params.data.unplanned_load_cmt !== "" && params.data.unplanned_load_cmt !== undefined)
            //             {
            //                 return Math.round(params.data.unplanned_load_cmt);
            //             }
            //             else{
            //                 return "";
            //             }
            //         } catch (error) {
                        
            //         }
            //     }
            // },
            
            {
                pinned:"left",
                headerName : "View Details",
                field:"transaction_details",
                width:150,
                resizable : true,
                editable : false,
                params:{buttonName: t('view'),iconName:'fa fa-eye',
                onClickFunction:this.viewPlanDetails},
                cellRenderer:function(params){
                    // console.log(":params ", params.data)
                    if(params.data.data_processed == 1 || params.data.data_processed == 2 || params.data.data_processed == 3)
                    {
                        return <StaticButton/>
                    }
                    else
                    { 
                        return <NAButton/>
                    }
                },
            }
            
        ]
        if(this.state.material_configurations == 1)
        {
            var templatePath = require('../../assets/json/input_data_with_material_config_template.csv');
        }
        else
        {
            var templatePath = require('../../assets/json/input_data_template.csv');
        }
        return (
            <div class="container-fluid">
                <div className={"animatenow "+(this.state.showanimate)} style={{textAlign:"center"}}>
                    <span className="pp-h">Processing Plan. Please wait.....</span>
                    <img src={require("../../assets/images/r.gif")} style={{width:"85vh"}} />
                </div>
                <div className={'lodr '+(this.state.showloader)}>
                    <div className="loaderdiv">
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                    </div>
                    <div className="ldrtext">Loading....</div>
                </div>
                 <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                > 
              </SweetAlert>
              {this.state.alert}
                <div class="row mb-20p">
                    <div className="col-sm-12">
                        <h5 className='fbold'>
                            Summary
                            
                                <a href={"/dipatchplanhistory?outbound"}  style={{float:"right"}} className="btn btn-info mt-30p">Back</a>
                        </h5>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">
                        <div className="form-group ">
                            <label className="col-form-label f12">{Constant.MASTER_VEHICLE_DATA_CONFIGURATION_BOUND_LOCATION}</label>
                            <Select
                                placeholder={"Select"}
                                onChange={this.locationChanged}
                                name="location"
                                value={this.state.location}
                                options={this.state.locationNames}
                            />
                        </div>
                    </div>
                    
                    <div className="col-md-2">
                        <div className="form-group" >
                            <label className="col-form-label f12">{Constant.MASTER_VEHICLE_DATA_CONFIGURATION_NEXT_DAY}</label>
                            <Select
                                placeholder={"Select"}
                                onChange={this.slaChanged.bind(this)}
                                name="sla"
                                value={this.state.sla}
                                options={[{value:1,label:"SLA 24hrs"}, {value:2,label:"SLA 48hrs"}]}
                            />

                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="form-group ">
                            <label className="col-form-label f12">{Constant.MASTER_VEHICLE_DATA_CONFIGURATION_FROM_DATE}</label>
                            <input type="text" className="form-control datepicker" name="fromDate" id="fromDate" autoComplete='off'  readonly />
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="form-group ">
                            <label className="col-form-label f12">{Constant.MASTER_VEHICLE_DATA_CONFIGURATION_TO_DATE}</label>
                            <input type="text" className="form-control datepicker" name="toDate" id="toDate" autoComplete='off'  readonly />
                        </div>
                    </div>
                    <div className="col-md-2">
                        <button type="button" onClick={this.getDispatchPlanData} className="btn btn-warning mt-30p">Get Data</button>
                    </div>
                </div>
                <div class="row mb-20p">
                    <div className={"mxheit col-sm-12 "} >
                        {this.state.rowData.length > 0?
                        
                        <div id="myGrid" style={{width:"100%",height:"60vh"}} className={"ag-theme-balham"}>    
                            <AgGridReact
                                // modules={this.state.modules}
                                columnDefs={plannedcolsDefs1}
                                defaultColDef={this.state.defaultColDef}
                                rowData={this.state.rowData}
                                enableCharts={false}
                                onGridReady={this.onGridReady}
                                onGridState={this.onGridState}
                                frameworkComponents={this.state.frameworkComponents}
                                statusBar={this.state.statusBar}
                                sideBar={this.state.sideBar}
                                stopEditingWhenGridLosesFocus= {true}
                                paginationPageSize={this.state.paginationPageSize}
                                pagination={true}
                                gridOptions={{
                                    context: { componentParent: this }
                                }}
                                enableRangeSelection= {true}
                                // enableCellContextMenu={true}
                                allowContextMenuWithControlKey={true}
                                // suppressContextMenu={false}
                                masterDetail={true}
                                detailCellRendererParams={this.state.detailCellRendererParams}
                                rowClassRules={this.state.rowClassRules}	
                                onCellClicked={this.viewPlanDetails.bind(this)}						
                            />
                        </div>
                        :""}
                    </div>
                </div>
                
                <div className={"overlay-block "+(this.state.overlayBlock)} onClick={this.hideSlideBlock}></div>
                
                <div className={"loader "+this.state.loadshow}></div>
                <div className={"overlay-part "+(this.state.overly)} onClick={this.hideSlideBlock.bind(this)}></div>
            </div>
        )
    }
}

export default withTranslation()(DispatchPlanSummary)
$( function() {
    $( "#fromDate" ).datepicker({
        dateFormat:"dd-mm-yy",
        changeYear:true
    });
    $( "#toDate" ).datepicker({
        dateFormat:"dd-mm-yy",
        changeYear:true,
        minDate:$("#fromDate").val()
    });
} );


function GetSortAscOrder(prop) {    
    return function(a, b) {    
        if (a[prop] > b[prop]) {    
            return 1;    
        } else if (a[prop] < b[prop]) {    
            return -1;    
        }    
        return 0;    
    }    
} 

function GetSortDescOrder(prop) {    
    return function(a, b) {    
        if (a[prop] < b[prop]) {    
            return 1;    
        } else if (a[prop] > b[prop]) {    
            return -1;    
        }    
        return 0;    
    }    
} 
function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}