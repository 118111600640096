
import React, { Component } from 'react';
import { useTranslation } from 'react-i18next';

 
const StaticButton = (props) => {
    const { t } = useTranslation();
    return (
        
        <div>
           
        <button className='btn btn-danger grid-btn' style={{border:"0px"}}>
            <i className="fa fa-eye"></i> {t('view')}
        </button>
        
    </div>
    );
};

export default StaticButton;
