import React, { Component } from 'react';
import { RiDeleteBin6Fill } from "react-icons/ri";
 
const DeleteBtn = (props) => {
    const handleOnClick = () => {
        props.colDef.params.onClickFunction(props, true);
    };
  
    return (
        <div>
            <button type="button" className="btn grid-btn" onClick={handleOnClick} style={{ boxShadow: "none", color: "#4a4a4a" }}>
               <RiDeleteBin6Fill className='f16' /> 
            </button>        
        </div>
    );
};

export default DeleteBtn;