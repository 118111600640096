import React,{Component} from "react";
import {AllModules} from '@ag-grid-enterprise/all-modules';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
import 'ag-grid-enterprise';
import Constant from "../common/constants";
import Modal from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import { withTranslation } from "react-i18next";
import redirectURL from '../redirectURL';
import Select from 'react-select';
import SweetAlert from 'react-bootstrap-sweetalert';
import GridButton from './gridButton';
import getToolConfiguration from "./commonFunctions";
import { logFormChanges  } from "./auditLogfunction";

 class FixedVehicleCost extends Component{
    constructor(props){
        super(props);
        this.state={
            modules : AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
                resizable:true,
                minWidth:100,
                initialWidth: 200,
                wrapHeaderText: true,
                autoHeaderHeight: true,
            },
            frameworkComponents:{
                GridButton:GridButton,
            },
            rowData:[],
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
			detailCellRendererParams:{},
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:100,
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            animateRows: true,
            openMaterialConfigurationModel:false,            
            show:false,
            basicTitle:"",
            basicType:"default",
            data_type:'',
            location:this.props.plantLocation,
            vehicle_type: '',
            vehicleTypeList: [],
            fixed_vehicle_cost:'',
            rowId:'',
            previousData:""
        }
        
    }

    async componentDidMount(){
        
        var curl = window.location.search;
        var ul= curl.split("?");
        // console.log("ul ",ul)
        // let url_type = window.location.href
        let url_type = ul[parseInt(ul.length)-1];
        // console.log("url_type ", url_type)
        // let path_type = url_type.split('/')
        let path_type = url_type
        let data_type
        // let path = path_type[path_type.length-1]
        let path =url_type

        if (path == 'inbound'){
            data_type = 1
        }else{
            data_type = 2
        }
        // const {plantLocation} = this.props
        await this.setState({
            data_type : data_type,
        // location:plantLocation
        })
        let params = {
            data_type : data_type,
            location_code: this.props.plantLocation.value
        }
        let toolparameters = {
            data_type : data_type,
            plant_code : this.props.plantLocation.value
        }
        await getToolConfiguration(toolparameters)
        await this.getVehiclesData(params)
        await this.getFixedVehicleCostData(params)
    }
    componentWillReceiveProps(newprops)
    {
        if(newprops.plantLocation !== this.state.location)
        {
            var curl = window.location.search;
            var ul= curl.split("?");
            // console.log("ul ",ul)
            // let url_type = window.location.href
            let url_type = ul[parseInt(ul.length)-1];
            // console.log("url_type ", url_type)
            // let path_type = url_type.split('/')
            let path_type = url_type
            let data_type
            // let path = path_type[path_type.length-1]
            let path =url_type

            if (path == 'inbound'){
                data_type = 1
            }else{
                data_type = 2
            }
            // console.log("location ", newprops.plantLocation)
            this.setState({
                location:newprops.plantLocation
            })
            let params = {
                data_type : data_type,
                location_code: newprops.plantLocation.value
            }
            this.getVehiclesData(params)
        }
    }

    getVehiclesData = (params) =>{
        let vehicleTypeList = []
        redirectURL.post('master/getVehiclesData',params)
        .then(async response =>{
            if(response.data.status == 'success'){
                let vehiclesData = response.data.vehiclesData
                if(vehiclesData.length > 0){
                    vehiclesData.map(item =>{
                        vehicleTypeList.push({ value : item.vehicle_type,
                            label : item.vehicle_type })
                    })
                    await this.setState({
                        vehicleTypeList : vehicleTypeList,
                        vehicle_type : vehicleTypeList[0]
                    })
                }
        }
        })
    }

    getFixedVehicleCostData = (params) =>{
        redirectURL.post('master/getFixedVehicleCostData',params)
        .then(response =>{
            if(response.data.status == 'success'){

                this.setState({
                    rowData:response.data.vehicleFixedCostData
                })
            }
        })
    }


    //function names need to be updated
    openModelForMaterialConfiguration = () =>{
        this.setState(prevState =>({
            openMaterialConfigurationModel :!prevState.openMaterialConfigurationModel
        }))
    }

    handelMaterialConfigurationModelCancel = ()=>{
        this.setState(prevState =>({
            openMaterialConfigurationModel :!prevState.openMaterialConfigurationModel,
            fixed_vehicle_cost:'',
            vehicle_type: '',
            rowId:'',
            
        }))

    }

    closeAlert = async() => {
        await this.setState({
            show: false
        });
	}
    
    materialDetailsInputFields = (e) =>{
        let name = e.target.name
        let value = e.target.value
        this.setState({ [name] : value })
    }

    saveFixedVehicleCostDetails = (params)=>{
        let data_type = this.state.data_type
        redirectURL.post('master/saveFixedVehicleCostDetails',params)
        .then(response =>{
            if(response.data.status == 'success'){
                let param = {
                    data_type : data_type
                }
                this.getFixedVehicleCostData(param)
                this.setState({
                        openMaterialConfigurationModel:false,
                        fixed_vehicle_cost:'',
                        vehicle_type: '',
                    })
            }else{
                this.setState({
                    show:true,
                    basicTitle:"Failed to save the data",
                    basicType:"danger"
                })
            }
        })
    }


    updateFixedVehicleCostDetails = (params)=>{
        let data_type = this.state.data_type
        redirectURL.post('master/updateFixedVehicleCostDetails',params)
        .then(response =>{
            if(response.data.status == 'success'){
                let param = {
                    data_type : data_type
                }
                let logParams = {
                    "user_name": localStorage.getItem("username"),
                    "useremail": localStorage.getItem("email"),
                    "client": localStorage.getItem("client"),
                    "screen": "Freight configurations - Vehicle Fixed Cost ($)",
                    "location_code":this.state.location.value,
                    "activity":{}
                }
                let previousData = this.state.previousData
                logFormChanges(logParams,params,previousData)

                this.getFixedVehicleCostData(param)
                this.setState({
                        openMaterialConfigurationModel:false,
                        fixed_vehicle_cost:'',
                        vehicle_type: '',
                        rowId:'',
                        previousData:""
                    })
            }else{
                this.setState({
                    show:true,
                    basicTitle:"Failed to save the data",
                    basicType:"danger"
                })
            }
        })
    }


    formMaterialDetails = (e)=>{
        e.preventDefault();
        let fixed_vehicle_cost = this.state.fixed_vehicle_cost;
        let vehicle_type = this.state.vehicle_type;
        let data_type = this.state.data_type;
        let location = this.state.location;
        let toolConfig = JSON.parse(localStorage.getItem('tlcfg'))
        let rowId = this.state.rowId;

        let params = {
            vehicle_type : vehicle_type.value,
            fixed_vehicle_cost : parseInt(fixed_vehicle_cost),
            data_type : data_type,
            location_code : location.value,
            location_name : location.label,
        }

        if(rowId != ''){

            params.vehicleCost_id = rowId
            
                this.updateFixedVehicleCostDetails(params)
            

        }else{
            if(location.value != null && vehicle_type.value != null){
                this.saveFixedVehicleCostDetails(params)
            }else{
                if(vehicle_type.value == null){
                    this.setState({
                        show:true,
                        basicTitle:"Please Select Vehicle",
                        basicType:"danger"
                    })
                }else{
                this.setState({
                    show:true,
                    basicTitle:"Please add "+ Constant.MASTER_VEHICLE_DATA_CONFIGURATION_BOUND_LOCATION + " First",
                    basicType:"danger"
                })
                }
            }
        }
    }

    editMaterialConfiguration = async(propdata) =>{
        let data = propdata.data
        var previousData={
            "vehicle_type" : data.vehicle_type,
            "fixed_vehicle_cost" : data.fixed_vehicle_cost,            
        }
        await this.setState({
            "vehicle_type" : {label:data.vehicle_type,value:data.vehicle_type},
            "fixed_vehicle_cost" : data.fixed_vehicle_cost,
            "rowId" : data.vehicleCost_id,
            previousData:previousData
        })
        this.openModelForMaterialConfiguration()
    }

    vehicleTypeChanged = (vehicle_type) =>{
        this.setState({
            vehicle_type : vehicle_type
        })
    }

    deleteFixedVehicleCostDetails = (propData) =>{
        let data = propData.data
        let data_type = this.state.data_type
        
        let params = {
            delete_id : data._id
        }
        redirectURL.post('master/deleteFixedVehicleCostDetails',params)
        .then(response =>{
            if(response.data.status == 'success'){
                let param = {
                    data_type : data_type
                }
                this.getFixedVehicleCostData(param)
          }else{
            this.setState({
                show:true,
                basicTitle:"Failed to delete the data",
                basicType:"danger"
            })
          }
        })
    }


    render(){
        const {t} = this.props
        var colsDefs = [
            {
                headerName: t('vehicle_type'),
                field:"vehicle_type",
                width:"120"
            },
            {
                headerName: t('fixed_vehicle_cost_title'),
                field:"fixed_vehicle_cost",
                width:"130"
            },
            {
                headerName: t('actions'),
                field:"cluster",
                width:"80",
                pinned:'left',
                params:{buttonName: t('edit'),
                        onClickFunction:this.editMaterialConfiguration},
                cellRendererSelector:function(params){
                    
                    var rendComponent = {
                        component: "GridButton"
                    };
                    return rendComponent
                
                },
            },
            {
                headerName:"",
                field:"cluster",
                width:"80",
                pinned:'left',
                params:{buttonName: t('delete'),iconName:"fa fa-trash",
                        onClickFunction:this.deleteFixedVehicleCostDetails},
                cellRendererSelector:function(params){
                    
                    var rendComponent = {
                        component: "GridButton"
                    };
                    return rendComponent
                
                },
            },
        ]

        let toolConfig = JSON.parse(localStorage.getItem('tlcfg'))
        let data = this.state.rowData
        let locValue = this.state.location.value
         
        let filteredData = data.filter(item => item.location_code == locValue)

        return(
            <>
            <SweetAlert
                show={this.state.show}
                type={this.state.basicType}
                title={this.state.basicTitle}
                onConfirm={this.closeAlert}>
            </SweetAlert>
                
                <div class="row mb-20p pt-20p">
                <div className="col-sm-12">
                                    
                        <h5 className='fbold  d-flex justify-content-between' style={{marginLeft : 10}}>
                            <span>{t('fixed_vehicle_cost_title')}</span>
                            <button
                                    type="button"
                                    onClick={this.openModelForMaterialConfiguration}
                                    className="btn ml-10p"    
                                    style={{ backgroundColor: "#f0f0f0", color: "black", fontWeight: 600 }}                       
                                    >
                                     {t('add_fixed_vehicle_cost')}
                                </button>

                        </h5>
                                                   

                        <div id="myGrid" style={{width:"100%",height:"70vh"}} className="col-sm-12 ag-theme-balham dropdown">
                        <AgGridReact
                        modules={this.state.modules} 
                            rowData={filteredData}
                            columnDefs={colsDefs}
                            gridOptions={{context:{componentParent:this}}}
                            defaultColDef={this.state.defaultColDef}
                            frameworkComponents={this.state.frameworkComponents}
                            paginationPageSize={this.state.paginationPageSize}
                            pagination={true}
                            statusBar={this.state.statusBar}
						    sideBar={this.state.sideBar}
						    enableRangeSelection={true}
                            
                        />
                        </div>
                    </div>
                </div>

                <Modal closeOnOverlayClick={false} open={this.state.openMaterialConfigurationModel} onClose={this.handelMaterialConfigurationModelCancel} 
                    classNames={{modal: 'pickupdrop-modelClass'}} >
                        <div className="col-sm-12">
                        <div>
                            <h4>{t('freight_details')}</h4>
                        </div>
                    <form className="theme-form" onSubmit={this.formMaterialDetails}>

                        <div className="row number">
                        <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{t('pickup_point')}</label>
                                    <Select
                                        isDisabled
                                        name="location"
                                        value={this.state.location}
                                         />
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{t('vehicle_type')}</label>
                                    <Select
                                        placeholder={"Select"}
                                        onChange={this.vehicleTypeChanged}
                                        name="vehicle_type"
                                        value={this.state.vehicle_type}
                                        options={this.state.vehicleTypeList} 
                                        />

                                </div>
                            </div>

                            <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{t('fixed_vehicle_cost_title')} </label>
                                    <input required type="number" min='0'  className="forminp form-control" id="fixed_vehicle_cost" name="fixed_vehicle_cost" value={this.state.fixed_vehicle_cost} 
                                    onChange={this.materialDetailsInputFields}
                                     placeholder="" autoComplete="off" />
                                    
                                </div>
                            </div>
                           

                        </div>
                        <div className="form-group">
                            <button type="submit" className="btn tbtn float-right save-btn" id="saveGeo">{t('save')}</button>
                            <button type='button' className="btn tbtn float-right cancel-btn" onClick={this.handelMaterialConfigurationModelCancel}>{t('cancel')}</button>	
                        </div>
                    </form>
                </div>
                    </Modal>
            </>
        )
    }
}

export default withTranslation()(FixedVehicleCost)