import React, { Component } from "react";
import i18n from '../../i18n.js'
import redirectURL from "../redirectURL";
import PieChartwithpercentage from "./pieChart2";
import { withTranslation } from "react-i18next";
import StackedColumnPercentChart from "./stackedChartPercentage";
import { Audio } from 'react-loader-spinner'

 class ChartsForPlanManagement extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      monthlydata: [],
      weeklydata: [],
      weeklyProgressData: [],
      drilldownseries: [],
      Xcategories:[],
      loadingData:true,
    };
  }

  componentDidMount() {
    var location = this.props.location
    console.log(location.value)
    var params = {
      plant_code : location.value
    }
    this.fetchData(params)
    
  }

  componentDidUpdate(prevProps) {
    // console.log(this.props.location.value)

    if (this.props.location.value !== prevProps.location.value) {
      var params = {plant_code : this.props.location.value}
      this.fetchData(params);
    }
  }

  fetchData = async (params) => {
    await this.setState({
      loadingData:true
    })
    var last30daysData;
    var lastOneWeekData;
    redirectURL
      .post("/plandash/getlast30DaysPlansEditStats", params)
      .then(async (resp) => {
        last30daysData = resp.data;
        // console.log(last30daysData, "last30daysData");
        var monthlydata = [];
        monthlydata = Object.entries(last30daysData).map(([key, value]) => ({
          name: key,
          y: value,
        }));
        this.setState({
          monthlydata,
        });
      })
      .catch((err) => {
        var errDetails = {
          url: "/plandash/getlast30DaysPlansEditStats",
          screen: "",
          err,
        };
        redirectURL.post("/master/logErrorsForApiCalls", errDetails);
      });

    redirectURL
      .post("/plandash/getlast7DaysPlanEditStats", params)
      .then(async (resp) => {
        lastOneWeekData = resp.data;
        // console.log(lastOneWeekData,"lastOneWeekData");
        var weeklydata = [];
        weeklydata = Object.entries(lastOneWeekData).map(([key, value]) => ({
          name: key,
          y: value,
        }));
        this.setState({
          weeklydata,
        });
      })
      .catch((err) => {
        var errDetails = {
          url: "/master/getlast7DaysPlanEditStats",
          screen: "",
          err,
        };
        redirectURL.post("/master/logErrorsForApiCalls", errDetails);
      });

    redirectURL
      .post("/plandash/get7weeksdata", params)
      .then(async (resp) => {
        // console.log(resp.data, "response for weeks");
        // var data=resp.data
        // var series=[]
        // var drillDownSeries=[]
        // data.map((rec)=>{
        //   var details={}
        //   details.name=rec.name
        //   details.drilldown=rec.name
        //   var percentage=(parseFloat((rec.plansEdited/rec.totalPlans)*100)).toFixed(2)
        //   details.y=percentage
        //   series.push(details)
        //   var drilldetails={}
        //   drilldetails.name=rec.name
        //   drilldetails.id=rec.name
        //   var weekData=[]
        //   rec.weekData.map((item)=>{
        //     weekData.push(item.name)
        //     var value=

        //   })
        // })
        var weeklyProgressData = [];
        let totalPlansData = resp.data.map((item) => item.data["Unedited"]);
        let plansEditedData = resp.data.map((item) => item.data["Edited"]);
        let removedtripsData = resp.data.map((item) => item.data["Removed"]);
        let Xcategories = resp.data.map((item) => item.name);       
        weeklyProgressData.push({ name: i18n.t('Removed'), data: removedtripsData });
        weeklyProgressData.push({
          name: "Edited",
          data: plansEditedData,
        });
        weeklyProgressData.push({ name: i18n.t('Unedited'), data: totalPlansData });

        // resp.data.map()
        this.setState({
          weeklyProgressData,
          Xcategories,
          loadingData:false,
        });
        // console.log(weeklyProgressData, "weeklyprogressdata");
        let dayWiseData = resp.data.map((each) => each.daywisePlans);
        // console.log(dayWiseData, "daywisedata");
        dayWiseData.map((each) => {
          var newData = [];
          let totalPlansData = each.map((item) => item.data["Unedited"]);
          let plansEditedData = each.map((item) => item.data["Edited"]);
          let plansRemoved = each.map((item) => item.data["Removed"])          
          newData.push({ name: i18n.t('Removed'), data: plansRemoved });          
          newData.push({ name: i18n.t('Edited'), data: plansEditedData });
          newData.push({ name: i18n.t('Unedited'), data: totalPlansData });
          each.data = newData;
        });
        // console.log(dayWiseData, "edited");
        // this.setState({
        //   drilldownseries: dayWiseData,
        // });
        await this.setState({
          loadingData:false
        })
      })
      .catch((err) => {
        var errDetails = {
          url: "/plandash/get7weeksdata",
          screen: "",
          err,
        };
        redirectURL.post("/master/logErrorsForApiCalls", errDetails);
      });
  }

  render() {
    const {t} = this.props
    return (
      <>
      { this.state.loadingData  ? 
        (
          <div className="col-xl-12 col-lg-12 col-md-12 d-flex-row-center" style={{backgroundColor:"whitesmoke", height:"35vh"}}>
            <Audio
            height="100"
            width="100"
            color="#4ca688"
            ariaLabel="audio-loading"
            wrapperStyle={{}}
            wrapperClass="wrapper-class"
            visible={true}
            />
            <br/>
            {/* <p className="text-center" style={{color:"#4ca688"}}>Loading...</p> */}
          </div>
        ):(
        <div className="container">
          <div className="row d-flex-row-center p-0 m-0" style={{backgroundColor:"white", height:"40vh", width:"97%" }}>
          <div className="col-xl-3 col-lg-3 p-0 m-0" >
            <PieChartwithpercentage
              title={t('last_30_days_trips_modification_details')}
              subtitle={""}
              context={this}
              data={this.state.monthlydata}
            />
          </div>
          <div className="col-xl-3 col-lg-3 p-0 m-0">
          <PieChartwithpercentage
              title={t('last_7_days_trips_modification_details')}
              subtitle={""}
              context={this}
              data={this.state.weeklydata}
            />
          </div>            
            <div className="col-xl-6 col-lg-6 col-md-12 p-0 m-0" style={{minHeight:"30vh"}}>
              <StackedColumnPercentChart
                title={t('planning_progress_past_7_weeks')}
                subtitle={" "}
                context={this}
                series={this.state.weeklyProgressData}
                categories = {this.state.Xcategories}
                drilldownseries={this.state.drilldownseries}
              />
          </div>   
          
        </div>
        </div>)
      }
      </>      
    );
  }
}

export default withTranslation()(ChartsForPlanManagement)
