import React,{Component} from "react";
import {AllModules} from '@ag-grid-enterprise/all-modules';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
import 'ag-grid-enterprise';
import { withTranslation } from 'react-i18next';
import Constant from "../common/constants";
import Modal from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import redirectURL from '../redirectURL';
import Select from 'react-select';
import SweetAlert from 'react-bootstrap-sweetalert';
import GridButton from '../optirun-admin/gridButton';
import TimeRangePicker from '@wojtekmaj/react-timerange-picker';
import getToolConfiguration from "../optirun-admin/commonFunctions";

 class VehiclesForecastData extends Component{
    constructor(props){
        super(props);
        this.state={
            modules : AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
                resizable:true,
                minWidth:100,
                initialWidth: 200,
                wrapHeaderText: true,
                autoHeaderHeight: true,
            },
            frameworkComponents:{
                GridButton:GridButton,
            },
            rowData:[],
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
			detailCellRendererParams:{},
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:100,
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            animateRows: true,
            openVehicleConfigurationModel:false,
            vehicle_type:'',
            vehicle_name:'',
            vehicle_priority:"Owned",
            min_volume_cmt:'',
            max_volume_cmt:'',
            max_volume_m3:'',
            min_volume_m3:'',
            vehicle_dimensions_width_mm:'',
            vehicle_dimensions_depth_mm:'',
            vehicle_dimensions_height_mm:'',
            vehicle_min_weight:'',
            vehicle_max_weight:'',
            max_no_of_nodes:'',
            variable_vehicle_cost_expandable:[],
            show:false,
            basicTitle:"",
            basicType:"default",
            showAvailablityTable:false,
            data_type:'',
            location:'',
            max_distance:'',
            vehicle_availability_time:['00:00','23:59'],
            break_time:['00:00','23:59'],
            locationNames:[],
            min_load_per_delivery:'',
            // vehicle_capacity:'',
            vehicle_id:'',
            vehicle_type_count:0,
            openVehiclePriorityConfigurationModel:false,
            vehicle_priority_order_owned:1,
            vehicle_priority_order_contract:2,
            vehicle_priority_order_market:3,
            monthly_min_planning_distance:3000,
            clickonEdit:false,
            cost_per_km:'',
        }
        
    }

   async componentDidMount(){
        var curl = window.location.search;
        var ul= curl.split("?");
        // console.log("ul ",ul)
        // let url_type = window.location.href
        let url_type = ul[parseInt(ul.length)-1];
        // console.log("url_type ", url_type)
        // let path_type = url_type.split('/')
        let path_type = url_type
        let data_type
        // let path = path_type[path_type.length-1]
        let path =url_type

        if (path_type == 'inbound'){
            data_type = 1
            this.setState({data_type : data_type})
        }else{
            data_type = 2
            this.setState({data_type : data_type})
        }
        let params = {
            data_type : data_type
        }
        // await getToolConfiguration(params)
        await this.getVehiclesData(params)
        await this.boundTypeLocations(params)
        await this.getVehiclePriorityOrder(params)
    }

    getVehiclesData = (params) =>{
        redirectURL.post('forecastvehicle/getVehiclesForecastData',params)
        .then(async response =>{
            if(response.data.status == 'success'){
                let vehiclesData = response.data.vehiclesData
                await vehiclesData.map(item => {
                    if(item.vehicle_capacity){
                    //    item.vehicle_capacity= Math.round((item.vehicle_capacity/1000000000)*100)/100
                    item.vehicle_capacity = item.vehicle_capacity/1000;
                    item.vehicle_dimensions_width_mm = item.vehicle_dimensions_width_mm/1000;
                    }
                })
            this.setState({
                rowData:vehiclesData
            })
        }
        })
    }

    openModelForVehicleConfiguration =async () =>{
        // console.log(this.state.clickonEdit)
        var activityMsg=''
        if (this.state.clickonEdit){
            activityMsg='Clicked on Edit button in the aggrid'
            await this.setState({clickonEdit:false})
        }else{
            activityMsg='clicked on Add Vehicle Button'
            
        }
        let logParams = {
            "location_code" : this.state.location.value,
            "location_name" : this.state.location.label,
            "user_name" : localStorage.getItem("username"),
            "useremail" : localStorage.getItem("email"),
            "client" : localStorage.getItem("client"),
            "screen" : "Vehicle Forecast Configurations",
            "activity" : activityMsg,
            "event" : "Forecast Vehicle Type Configuration Modal Opened",
            "data_type" : this.state.data_type,
        }
    
        redirectURL.post("/master/loguserUpdatesorChanges",logParams)
        this.setState(prevState =>({
            openVehicleConfigurationModel :!prevState.openVehicleConfigurationModel
        }))
    }

    handelVehiclePriorityConfigurationModel = () =>{
        this.setState(prevState =>({
            openVehiclePriorityConfigurationModel :!prevState.openVehiclePriorityConfigurationModel
        }))
    }
    handelVehiclePriorityConfigurationModelCancel = ()=>{
        this.setState(prevState =>({
            openVehiclePriorityConfigurationModel :!prevState.openVehiclePriorityConfigurationModel,
        }))
    }

    saveVehiclePriorityOrder = () =>{
        // location specific ??
        let vehicle_priority_order_owned = this.state.vehicle_priority_order_owned;
        let vehicle_priority_order_contract = this.state.vehicle_priority_order_contract;
        let vehicle_priority_order_market = this.state.vehicle_priority_order_market;
        let data_type = this.state.data_type
        let params = {
            owned : parseInt(vehicle_priority_order_owned),
            contract : parseInt(vehicle_priority_order_contract),
            market : parseInt(vehicle_priority_order_market),
            data_type : data_type
        }
        redirectURL.post('master/saveVehiclePriorityOrder',params)
        .then(response =>{
            if(response.data.status == 'success'){
                this.setState({
                    openVehiclePriorityConfigurationModel:false
                })

            }else{
                this.setState({
                    show:true,
                    basicTitle:"Data Not Saved successfully",
                    basicType:"danger"
                })
            }
        })
    }

    getVehiclePriorityOrder = (params)=>{
        let vehicle_priority_order_owned = this.state.vehicle_priority_order_owned;
        let vehicle_priority_order_contract = this.state.vehicle_priority_order_contract;
        let vehicle_priority_order_market = this.state.vehicle_priority_order_market;
        redirectURL.post('master/getVehiclePriorityOrder',params)
        .then(response =>{
            if(response.data.status == 'success'){
                try {
                    let vehiclePriorityData = response.data.vehiclePriorityData
                    vehicle_priority_order_owned = vehiclePriorityData[0].owned
                    vehicle_priority_order_contract = vehiclePriorityData[0].contract
                    vehicle_priority_order_market = vehiclePriorityData[0].market
                    this.setState({
                        vehicle_priority_order_owned,
                        vehicle_priority_order_contract,
                        vehicle_priority_order_market
                    })
                } catch (error) {
                    
                }
               
            }
        })

    }

    handelVehicleConfigurationModelCancel = ()=>{
        let logParams = {
            "location_code" : this.state.location.value,
            "location_name" : this.state.location.label,
            "user_name" : localStorage.getItem("username"),
            "useremail" : localStorage.getItem("email"),
            "client" : localStorage.getItem("client"),
            "screen" : "Vehicle Forecast Configurations",
            "activity" : 'clicked on Cancel Button in the modal',
            "event" : "Modal is closed",
            "data_type" : this.state.data_type,
        }
      
        redirectURL.post("/master/loguserUpdatesorChanges",logParams)
        this.setState(prevState =>({
            openVehicleConfigurationModel :!prevState.openVehicleConfigurationModel,
            vehicle_type:'',
            vehicle_name:'',
            vehicle_priority:"Owned",
            min_volume_cmt:'',
            max_volume_cmt:'',
            max_volume_m3:'',
            min_volume_m3:'',
            vehicle_dimensions_width_mm:'',
            vehicle_dimensions_depth_mm:'',
            vehicle_dimensions_height_mm:'',
            vehicle_min_weight:'',
            vehicle_max_weight:'',
            max_no_of_nodes:'',
            min_load_per_delivery:'',
            // vehicle_capacity:'',
            vehicle_availability_time:['00:00','23:59'],
            break_time:['00:00','23:59'],
            max_distance:'',
            vehicle_id : '',
            vehicle_type_count:0
            
        }))

    }

    // priorityOptions() {
    //     var items = []
    //     items.push({
    //     {
    //         label: "Owned"
    //         value: "owned",
    //     }, {
    //         value: "contract",
    //         label: "Contract"
    //     },{
    //         value: "market",
    //         label: "Market"
    //     }
    //     )
    //     return items;
    // }

    closeAlert = async() => {
        await this.setState({
            show: false
        });
	}

    handleAvailabilityTime = (val) => {
    	this.setState({vehicle_availability_time:val});
    }

    handleBreakTime = (val) => {
    	this.setState({break_time:val});
    }
    
    vehicleConfigurationInputFields = (e) =>{
        let name = e.target.name
        let value = e.target.value

        if(name == "vehicle_type"){
            value = value.toUpperCase()
        }
        this.setState({ [name] : value })
    }

    // vehiclePriority = (vehicle_priority) =>{
    //     console.log(vehicle_priority)
    //     this.setState({
    //         vehicle_priority : vehicle_priority
    //     })
    // }

    locationChanged = (location) =>{
        this.setState({
            location : location
        })
    }

    boundTypeLocations = async(param) => {

      let location_access = JSON.parse(localStorage.getItem("location_access"))

      let locationNames = this.state.locationNames;
      
      await redirectURL.post('master/getBoundTypeLocations',param)
        .then(async (response) =>{
            if(response.data.status == 'success'){
                let boundLocations = response.data.boundLocations
                if(boundLocations.length > 0){
                    boundLocations.map(item =>{
                        if(location_access != null){
                            if(location_access.includes(item.location_code)){
                                locationNames.push({ value : item.location_code,
                                                    label : item.location_name })

                                }
                        }else{
                            locationNames.push({ value : item.location_code,
                                                label : item.location_name })
                        }
                        
                    })
                    
                    if(locationNames.length>0){
                        await this.setState({
                                    locationNames : locationNames,
                                    location : locationNames[0]
                                })
                    }else{
                        await this.setState({
                            locationNames : [{label:'Please Add Data',value:'no_data'}],
                            location : [{label:'Please Add Data',value:'no_data'}]
                        })
                    }
                }
            }
        })
        .catch(function (error) {
            var errDetails={
                url:"master/getBoundTypeLocations",
                screen:'Vehicle Forecast Configurations',
              error,
              }
            redirectURL.post("/master/logErrorsForApiCalls",errDetails)
            
        })
    
    }


    validateTimeRange = (from_time, to_time) =>{
        let frm_hr = from_time.split(':')
            let to_hr = to_time.split(':')
            
            if(from_time == to_time){
                this.setState({
                    show:true,
                    basicTitle:"Please Input Time Appropriately",
                    basicType:"danger"
                })
            }
            else if(frm_hr[0] > to_hr[0]){
                this.setState({
                    show:true,
                    basicTitle:"Please Input Time Appropriately",
                    basicType:"danger"
                })
            }else if(frm_hr[0] == to_hr[0]){
                if(frm_hr[1] > to_hr[1]){
                    this.setState({
                        show:true,
                        basicTitle:"Please Input Time Appropriately",
                        basicType:"danger"
                    })
                }else{
                    return true
                }

            }else{
                return true
            }

    }



    saveVehicleConfigurationData = (params)=>{
        let data_type = this.state.data_type
        let logParams = {
            "location_code" : this.state.location.value,
            "location_name" : this.state.location.label,
            "user_name" : localStorage.getItem("username"),
            "useremail" : localStorage.getItem("email"),
            "client" : localStorage.getItem("client"),
            "screen" : "Vehicle Forecast Configurations",
            "activity" : 'Forecast Vehicle Details Entered',
            "event" : "Forecast Vehicle Type Configurations Entered",
            "data_type" : this.state.data_type,
        }
        
        redirectURL.post("/master/logUserUpdateOrSaveDataActivities",logParams)
        .then(()=>{})
        .catch(function (error) {
            var errDetails={
                url:"/master/logUserUpdateOrSaveDataActivities",
                screen:'Vehicle Forecast Configurations',
              error,
              }
            redirectURL.post("/master/logErrorsForApiCalls",errDetails)
            
        })
    
        
        redirectURL.post('forecastvehicle/saveVehicleForecastConfiguration',params)
        .then(response =>{
                    
            if(response.data.status == 'success'){
                let param = {
                    data_type : data_type
                }
                this.getVehiclesData(param)
                this.setState({openVehicleConfigurationModel:false,
                            vehicle_type:'',
                            vehicle_name:'',
                            vehicle_priority:"Owned",
                            min_volume_cmt:'',
                            max_volume_cmt:'',
                            max_volume_m3:'',
                            min_volume_m3:'',
                            vehicle_dimensions_width_mm:'',
                            vehicle_dimensions_depth_mm:'',
                            vehicle_dimensions_height_mm:'',
                            vehicle_min_weight:'',
                            vehicle_max_weight:'',
                            min_load_per_delivery:'',
                            vehicle_availability_time:['00:00','23:59'],
                            break_time:['00:00','23:59'],
                            max_distance:'',
                            max_no_of_nodes:'',
                            // vehicle_capacity:'',
                            vehicle_type_count:0

                        })
                    }else{
                        if(response.data.status == 'failed'){
                            this.setState({
                                show:true,
                                basicTitle:response.data.message,
                                basicType:"danger"
                            })
                        }else{
                            this.setState({
                                show:true,
                                basicTitle:"Data Not Saved successfully",
                                basicType:"danger"
                            })
                        }
                    }

                })
                .catch(function (error) {
                    var errDetails={
                        url:"forecastvehicle/saveVehicleForecastConfiguration",
                        screen:'Vehicle Forecast Configurations',
                      error,
                      }
                    redirectURL.post("/master/logErrorsForApiCalls",errDetails)
                    
                })
            
    }


    formVehicleConfiguration = (e)=>{
        e.preventDefault();
        let vehicle_type = this.state.vehicle_type;
        let vehicle_name = this.state.vehicle_name;
        let vehicle_priority = this.state.vehicle_priority;
        let min_volume_cmt = this.state.min_volume_cmt;
        let max_volume_cmt = this.state.max_volume_cmt;
        let max_volume_m3 = this.state.max_volume_m3;
        var min_volume_m3 = this.state.min_volume_m3;
        let vehicle_dimensions_width_mm = (this.state.vehicle_dimensions_width_mm)*1000;
        let vehicle_dimensions_depth_mm = 1;
        let vehicle_dimensions_height_mm = 1;
        let vehicle_min_weight = this.state.vehicle_min_weight;
        let vehicle_max_weight = this.state.vehicle_max_weight;
        let max_no_of_nodes = 1;
        let min_load_per_delivery = 1;
        let data_type = this.state.data_type;
        let location = this.state.location;
        let max_distance = this.state.max_distance;
        let vehicle_availability_time = this.state.vehicle_availability_time;
        let break_time = this.state.break_time;
        // let vehicle_capacity = this.state.vehicle_capacity;
        let vehicle_id = this.state.vehicle_id;
        let vehicle_type_count = this.state.vehicle_type_count
        var monthly_min_planning_distance = this.state.monthly_min_planning_distance;
        let cost_per_km = this.state.cost_per_km;

        
        let vehicle_capacity = (vehicle_dimensions_width_mm * 1 *  1 );

        let toolConfig = JSON.parse(localStorage.getItem('tlcfg'))
        let break_time_from,break_time_to,vehicle_availability_from,vehicle_availability_to
        var driver_break_time = this.state.driver_break_time;

        
        if(max_distance == '' || toolConfig[0].distance_preferences == 0){
            max_distance = 100000
        }
        if(max_no_of_nodes == '' || toolConfig[0].clubbing_limits == 0){
            max_no_of_nodes = 1
        }
        if(min_load_per_delivery == '' || toolConfig[0].min_load_preferences == 0){
            min_load_per_delivery = 1
        }
        if(max_volume_cmt == '' || toolConfig[0].truck_min_max_configuration_preferences == 0){
            // let metricValue = Math.round((vehicle_capacity/1000000000))
            max_volume_cmt = parseInt(max_volume_cmt)
        }
        if(min_volume_cmt == '' || toolConfig[0].truck_min_max_configuration_preferences == 0){
            min_volume_cmt = 1
        }
        if (break_time[0]==null){
            
            break_time_from = '00:00';
            break_time_to = '23:59';
        }else{
            break_time_from = break_time[0];
            break_time_to = break_time[1]
            
        }
        if (vehicle_availability_time[0]==null){
            vehicle_availability_from = '00:00';
            vehicle_availability_to = '23:59';
        }else{
            vehicle_availability_from = vehicle_availability_time[0];
            vehicle_availability_to = vehicle_availability_time[1]
        }
  
        let params = {
            vehicle_type : vehicle_type,
            vehicle_name : vehicle_name,
            vehicle_priority : vehicle_priority,
            min_volume_cmt : parseFloat(min_volume_cmt),
            max_volume_cmt : parseFloat(max_volume_cmt),
            max_volume_m3:parseFloat(max_volume_m3),
            min_volume_m3:parseFloat(min_volume_m3),
            vehicle_dimensions_width_mm : parseFloat(vehicle_dimensions_width_mm),
            vehicle_dimensions_depth_mm : parseFloat(vehicle_dimensions_depth_mm),
            vehicle_dimensions_height_mm : parseFloat(vehicle_dimensions_height_mm),
            vehicle_min_weight : parseFloat(vehicle_min_weight),
            vehicle_max_weight : parseFloat(vehicle_max_weight),
            max_no_of_nodes : parseInt(max_no_of_nodes),
            data_type : data_type,
            location_code : location.value,
            location_name : location.label,
            max_distance : parseInt(max_distance),
            min_load_per_delivery : parseInt(min_load_per_delivery),
            break_time_from : break_time_from,
            break_time_to : break_time_to,
            driver_break_time:driver_break_time,
            vehicle_availability_from : vehicle_availability_from,
            vehicle_availability_to : vehicle_availability_to,
            vehicle_capacity : parseFloat(vehicle_capacity),
            vehicle_type_count : parseInt(vehicle_type_count),
            monthly_min_planning_distance:monthly_min_planning_distance,
            cost_per_km:cost_per_km,
            
        }
        // console.log("params ", params)
        if(vehicle_id != ''){

            params.vehicle_id = vehicle_id
            if(toolConfig[0].vehicle_operational_times_type == 1 && toolConfig[0].vehicle_operational_times == 1){
            

                let vehicle_time = this.validateTimeRange(vehicle_availability_time[0],vehicle_availability_time[1])
                let break_times = this.validateTimeRange(break_time[0],break_time[1])
    
                if(vehicle_time && break_times){
    
                    this.updateVehicleConfigurationData(params)  
                }
    
            }else{
                this.updateVehicleConfigurationData(params)
            }

        }else{

            if(location.value != null){

                if(toolConfig[0].vehicle_operational_times_type == 1 && toolConfig[0].vehicle_operational_times == 1){
                    

                    let vehicle_time = this.validateTimeRange(vehicle_availability_time[0],vehicle_availability_time[1])
                    let break_times = this.validateTimeRange(break_time[0],break_time[1])

                    if(vehicle_time && break_times){

                        this.saveVehicleConfigurationData(params)  
                    }

                }else{
                    this.saveVehicleConfigurationData(params)
                }
            }else{
                this.setState({
                    show:true,
                    basicTitle:"Please add "+ Constant.MASTER_VEHICLE_DATA_CONFIGURATION_BOUND_LOCATION + " First",
                    basicType:"danger"
                })
            }


        }

    }


    updateVehicleConfiguration = async(propdata) =>{
        let data = propdata.data

            await this.setState({
                vehicle_type:data.vehicle_type,
                vehicle_name:data.vehicle_name,
                vehicle_priority:data.vehicle_priority,
                min_volume_cmt:data.min_volume_cmt,
                max_volume_cmt:data.max_volume_cmt,
                max_volume_m3:data.max_volume_m3,
                min_volume_m3:data.min_volume_m3,
                vehicle_dimensions_width_mm:data.vehicle_dimensions_width_mm,
                vehicle_dimensions_depth_mm:data.vehicle_dimensions_depth_mm,
                vehicle_dimensions_height_mm:data.vehicle_dimensions_height_mm,
                vehicle_min_weight:data.vehicle_min_weight,
                vehicle_max_weight:data.vehicle_max_weight,
                max_no_of_nodes:data.max_no_of_nodes,
                min_load_per_delivery:data.min_load_per_delivery,
                vehicle_availability_time:[data.vehicle_availability_from,data.vehicle_availability_to],
                break_time:[data.break_time_from,data.break_time_to],
                driver_break_time:data.driver_break_time,
                max_distance:data.max_distance,
                // vehicle_capacity:data.vehicle_capacity,
                monthly_min_planning_distance:data.monthly_min_planning_distance,
                vehicle_id:data.vehicle_id,
                vehicle_type_count:data.vehicle_type_count,
                cost_per_km:data.cost_per_km
            })
            await this.setState({clickonEdit:true})
            this.openModelForVehicleConfiguration()
    
    }

    updateVehicleConfigurationData = (params) =>{
        // console.log("params ", params)
        let data_type = this.state.data_type
        // console.log(this.state.location)
        
        let logParams = {
            "location_code" : this.state.location.value,
            "location_name" : this.state.location.label,
            "user_name" : localStorage.getItem("username"),
            "useremail" : localStorage.getItem("email"),
            "client" : localStorage.getItem("client"),
            "screen" : "Vehicle Forecast Configurations",
            "activity" : 'Forecast Vehicle Details Entered',
            "event" : "Forecast Vehicle Type Configurations Edited",
            "data_type" : this.state.data_type,
        }
        
        redirectURL.post("/master/logUserUpdateOrSaveDataActivities",logParams)
        .then(()=>{})
        .catch(function (error) {
            var errDetails={
                url:"/forecastvehicle/logUserUpdateOrSaveDataActivities",
                screen:'Forecast Vehicle Configurations',
              error,
              }
            redirectURL.post("/master/logErrorsForApiCalls",errDetails)
            
        })
    
        
        redirectURL.post('forecastvehicle/updateVehicleForecastConfigurationData',params)
        .then(response =>{
                            
            if(response.data.status == 'success'){
                let param = {
                    data_type : data_type
                }
                this.getVehiclesData(param)
                this.setState({openVehicleConfigurationModel:false,
                            vehicle_type:'',
                            vehicle_name:'',
                            vehicle_priority:"Owned",
                            min_volume_cmt:'',
                            max_volume_cmt:'',
                            max_volume_m3:'',
                            min_volume_m3:'',
                            vehicle_dimensions_width_mm:'',
                            vehicle_dimensions_depth_mm:'',
                            vehicle_dimensions_height_mm:'',
                            vehicle_min_weight:'',
                            vehicle_max_weight:'',
                            max_no_of_nodes:'',
                            min_load_per_delivery:'',
                            vehicle_availability_time:['00:00','23:59'],
                            break_time:['00:00','23:59'],
                            max_distance:'',
                            // vehicle_capacity:'',
                            vehicle_id:'',
                            vehicle_type_count:0

                        })
                    }else{
                        if(response.data.status == 'failed'){
                            this.setState({
                                show:true,
                                basicTitle:response.data.message,
                                basicType:"danger"
                            })
                        }else{
                            this.setState({
                                show:true,
                                basicTitle:"Data Not Saved successfully",
                                basicType:"danger"
                            })
                        }
                    }

                })
                .catch(function (error) {
                    var errDetails={
                        url:"forecastvehicle/updateVehicleForecastConfigurationData",
                        screen:'Vehicle Forecast Configurations',
                      error,
                      }
                    redirectURL.post("/master/logErrorsForApiCalls",errDetails)
                    
                })
            
    }

    deleteVehicleConfiguration = (propData) =>{
        let data = propData.data
        let data_type = this.state.data_type
        
        let params = {
            delete_id : data._id,
            vehicle_type : data.vehicle_type,
            data_type : data_type
        }
        
        redirectURL.post('forecastvehicle/deleteVehicleForecastConfiguration',params)
        .then(response =>{
            if(response.data.status == 'success'){
                let param = {
                    data_type : data_type
                }
                this.getVehiclesData(param)
          }else{
            this.setState({
                show:true,
                basicTitle:"Failed to delete the data",
                basicType:"danger"
            })
          }
        })
        .catch(function (error) {
            var errDetails={
                url:"forecastvehicle/deleteVehicleForecastConfiguration",
                screen:'Vehicle Forecast Configurations',
              error,
              }
            redirectURL.post("/master/logErrorsForApiCalls",errDetails)
            
        })
    
    }

    onChangeDriverBreakTime(event){
        var value = event.target.value;
        const isValidInput = /^[\d:]{0,2}(:[\d:]*)?$/.test(value);
        if(isValidInput === true)
        {
            if(value.length <= 5)
            {
                this.setState({
                    driver_break_time: value,
                    isValidInput: isValidInput
                });
            }
        }
    }

    importExistingVehicleDetails = () =>{
        
        let data_type = this.state.data_type;
        let params = {
            location_code : this.state.location.value,
            data_type : data_type
        }

        redirectURL.post('forecastvehicle/importExistingVehicleDetails',params)
        .then(response =>{
            if(response.data.status == 'success'){
                let param = {
                    data_type : data_type
                }
                this.getVehiclesData(param)
            }
        })
    }

    onChangeVehicleType = (e) => {
        let name = e.target.name
        let value = e.target.value
        var regex=/^[a-zA-Z0-9_]+$/
        var out=regex.test(value)
        if(name == "vehicle_type"){
            value = value.toUpperCase()
        }
        if(out){
        this.setState({ [name] : value })}
    }

    onChangeVehicleName = (e) => {
        let name = e.target.name
        let value = e.target.value
        var regex = /^[a-zA-Z" "]+$/
        var out = regex.test(value)
        if (out){
            this.setState({ [name] : value})
        }
     }

     checkNotEqualstoZero = (e) => {
        let name = e.target.name 
        let value = e.target.value 
        var regex =/^(?!0)\d*$/
        var out = regex.test(value)
        console.log(out)
        if (out) {
            this.setState({[name] : value})
        }
     }

     onBlurVehicleMinWeight = (e) => {
        let vehicleMaxWeight = this.state.vehicle_max_weight 
        let name = e.target.name
        let value = e.target.value 
        if ( vehicleMaxWeight !== "" && vehicleMaxWeight <  value){
            this.setState({
                show:true,
                basicTitle:"Vehicle Max Weight should be greater than Vehicle Min Weight",
                basicType:"danger"
            })
        }
     }

     onBlurVehicleMaxWeight = (e) => {
        let vehicleMinWeight = this.state.vehicle_min_weight 
        let name = e.target.name
        let value = e.target.value 
        if ( vehicleMinWeight > value){
            this.setState({
                show:true,
                basicTitle:"Vehicle Max Weight should be greater than Vehicle Min Weight",
                basicType:"danger"
            })
        }
     }

     onBlurMinVolume = (e) => {
        let vehicleMaxVolume = this.state.max_volume_m3
        let name = e.target.name
        let value = e.target.value 
        if (vehicleMaxVolume < value  && vehicleMaxVolume !== "") {
            this.setState({
                show:true,
                basicTitle:"Max volume m3  should be greater than min volume m3",
                basicType:"danger"
            })
        }
     }

     onBlurMaxVolume = (e) => {
        let vehicleMinVolume = this.state.min_volume_m3
        let name = e.target.name
        let value = e.target.value 
        if ( vehicleMinVolume > value){
            this.setState({
                show:true,
                basicTitle:"Max volume m3  should be greater than min volume m3",
                basicType:"danger"
            })
        }
     }


    render(){
        const {t} = this.props
        let data = this.state.rowData
        let locValue = this.state.location.value   
        let filteredData = data.filter(item => item.location_code == locValue)

        let vehicleTypeCnt = 4;
        let truck_min_max = 0;
        let truckPriorities = 0;
        let distancePreferences = 0;
        let operationalTimes = 0;
        let operationalTimesType = 0;
        let clubbingLimits = 0;
        let minLoad = 0;
        let vehicleAvailability = 0;
        let vehicleMinMaxWeight = 0;
        let vehicleAvailabilityType =0;
        var monthly_distance_preferences= 0;

        let toolConfig = JSON.parse(localStorage.getItem('tlcfg'))
        if(toolConfig !=undefined && toolConfig !="")
        {
            if(toolConfig.length > 0)
            {
                vehicleTypeCnt = toolConfig[0].max_no_of_trucks;
                truck_min_max = toolConfig[0].truck_min_max_configuration_preferences;
                truckPriorities = toolConfig[0].truck_priorities;
                distancePreferences = toolConfig[0].distance_preferences;
                operationalTimes = toolConfig[0].vehicle_operational_times;
                operationalTimesType = toolConfig[0].vehicle_operational_times_type;
                clubbingLimits = toolConfig[0].clubbing_limits;
                minLoad = toolConfig[0].min_load_preferences;
                vehicleAvailability = toolConfig[0].vehicle_availability;
                vehicleAvailabilityType = toolConfig[0].vehicle_availability_type;
                monthly_distance_preferences = toolConfig[0].monthly_distance_preferences;
                vehicleMinMaxWeight = toolConfig[0].truck_weight_min_max_preferences;
            }
        }


        // var colsDefs = [
        //     {
        //         headerName:"Vehicle Type",
        //         field:"vehicle_type",
        //         width:"200"
        //     },
        //     {
        //         headerName:"Vehicle Name",
        //         field:"vehicle_name",
        //         width:"100"
        //     },
        //     {
        //         headerName:"Cost per Km",
        //         field:"cost_per_km",
        //         width:"100"
        //     },
        //     // {
        //     //     headerName:"No of Vehicles",
        //     //     field:"no_of_vehicles",
        //     //     width:"80",
        //         // initialWidth:"80",
        //         // editable:true,
        //     // },
        //     // {
        //     //     headerName:"Vehicle Operational(from)",
        //     //     field:"vehicle_availability_from",
        //     //     width:"150",
        //     //     // initialWidth:"80",
        //     //     // editable:true,
        //     //     // cellEditorPopup: true,
        //     //     // cellEditor: 'agSelectCellEditor',
        //     //     // cellEditorParams: {
        //     //     // values: Constant.TIMER_VALUES,
        //     //     // }
        //     // },
        //     // {
        //     //     headerName:"Vehicle Operational(to)",
        //     //     field:"vehicle_availability_to",
        //     //     width:"140"
        //     //     // initialWidth:"80",
        //     //     // editable:true,
        //     //     // cellEditorPopup: true,
        //     //     // cellEditor: 'agSelectCellEditor',
        //     //     // cellEditorParams: {
        //     //     // values: Constant.TIMER_VALUES,
        //     //     // }
        //     // },
        //     {
        //         headerName:"Driver Break Time(from)",
        //         field:"driver_break_time",
        //         width:"110"
        //     },
        //     // {
        //     //     headerName:"Driver Break Time(from)",
        //     //     field:"break_time_from",
        //     //     width:"110",
        //     //     // initialWidth:"80",
        //     //     // editable:true,
        //     //     // cellEditorPopup: true,
        //     //     // cellEditor: 'agSelectCellEditor',
        //     //     // cellEditorParams: {
        //     //     // values: Constant.TIMER_VALUES,
        //     //     // }
        //     // },
        //     // {
        //     //     headerName:"Driver Break Time(to) ",
        //     //     field:"break_time_to",
        //     //     width:"100",
        //     //     // initialWidth:"80",
        //     //     // role:"listbox",
        //     //     // editable:true,
        //     //     // cellEditorPopup: true,
        //     //     // cellEditor: 'agSelectCellEditor',
        //     //     // cellEditorParams: {
        //     //     // values: Constant.TIMER_VALUES,
        //     //     // }
        //     // },
        //     // {
        //     //     headerName:"Min Seating Capacity",
        //     //     field:"min_volume_cmt",
        //     //     width:"120"
        //     // },
        //     // {
        //     //     headerName:"Max Seating Capacity",
        //     //     field:"max_volume_cmt",
        //     //     width:"120"
        //     // },
        //     // {
        //     //     headerName:"Vehicle Seating Capacity",
        //     //     field:"vehicle_dimensions_width_mm",
        //     //     width:"160"
        //     // },
        //     // {
        //     //     headerName:"Vehicle Height (mm)",
        //     //     field:"vehicle_dimensions_height_mm",
        //     //     width:"125"
        //     // },
        //     // {
        //     //     headerName:"Vehicle Depth (mm)",
        //     //     field:"vehicle_dimensions_depth_mm",
        //     //     width:"120"
        //     // },
        //     {
        //         headerName:"Vehicle Min Weight (Kgs)",
        //         field:"vehicle_min_weight",
        //         width:"120"
        //     },
        //     {
        //         headerName:"Vehicle Max Weight (Kgs)",
        //         field:"vehicle_max_weight",
        //         width:"120"
        //     },
        //     {
        //         headerName:"Vehicle Min Volume (M3)",
        //         field:"min_volume_m3",
        //         width:"130"
        //     },
        //     {
        //         headerName:"Vehicle Max Volume (M3)",
        //         field:"max_volume_m3",
        //         width:"130"
        //     },
        //     // {
        //     //     headerName:"Vehicle Capacity (CMT)",
        //     //     field:"vehicle_capacity",
        //     //     width:"135"
        //     // },
        //     // {
        //     //     headerName:"Min Load Per Delivery",
        //     //     field:"min_load_per_delivery",
        //     //     width:"140"
        //     // },
        //     {
        //         headerName:"Max Distance (Kms)",
        //         field:"max_distance",
        //         width:"120"
        //     },
        //     {
        //         headerName:"Monthly Min Planning Distance (Kms)",
        //         field:"monthly_min_planning_distance",
        //         hide:(monthly_distance_preferences === 1?false:true),
        //         width:150
        //     },
            
        //     {
        //         headerName:"Vehicle Priority",
        //         field:"vehicle_priority",
        //         width:"100"
        //     },
        //     // {
        //     //     headerName:"Variable Vehicle Cost",
        //     //     field:"variable_vehicle_cost_expandable",
        //     //     width:"120"
        //     // },
        //     // {
        //     //     headerName:"Variable Vehicle Cost",
        //     //     field:"cluster",
        //     //     width:"120",
        //     //     pinned:'left',
        //     //     params:{buttonName:'Variable Cost',
        //     //             onClickFunction:this.variableCostFunction},
        //     //     cellRendererSelector:function(params){
                    
        //     //         var rendComponent = {
        //     //             component: "GridButton"
        //     //         };
        //     //         return rendComponent
                
        //     //     },
        //     // },
        //     {
        //         headerName:"Actions",
        //         field:"cluster",
        //         width:"20",
        //         pinned:'left',
        //         params:{buttonName:'Edit',
        //                 onClickFunction:this.updateVehicleConfiguration},
        //         cellRendererSelector:function(params){
                    
        //             var rendComponent = {
        //                 component: "GridButton"
        //             };
        //             return rendComponent
                
        //         },
        //     },
        //     // {
        //     //     headerName:"",
        //     //     field:"cluster",
        //     //     width:"80",
        //     //     pinned:'left',
        //     //     params:{buttonName:'Delete',iconName:"fa fa-trash",
        //     //             onClickFunction:this.deleteVehicleConfiguration},
        //     //     cellRendererSelector:function(params){
                    
        //     //         var rendComponent = {
        //     //             component: "GridButton"
        //     //         };
        //     //         return rendComponent
                
        //     //     },
        //     // },
        // ]

        var colsDefs = [
            {
                headerName: t('vehicle_type'),
                field: "vehicle_type",
                width: "200"
            },
            {
                headerName: t('vehicle_name'),
                field: "vehicle_name",
                width: "100"
            },
            {
                headerName: t('cost_per_km'),
                field: "cost_per_km",
                width: "100"
            },
            {
                headerName: t('no_of_vehicles'),
                field: "no_of_vehicles",
                width: "80",
                initialWidth: "80",
                editable: true
            },
            {
                headerName: t('vehicle_operational_from'),
                field: "vehicle_availability_from",
                width: "150",
                editable: true,
                cellEditorPopup: true,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: Constant.TIMER_VALUES,
                }
            },
            {
                headerName: t('vehicle_operational_to'),
                field: "vehicle_availability_to",
                width: "140",
                editable: true,
                cellEditorPopup: true,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: Constant.TIMER_VALUES,
                }
            },
            {
                headerName: t('driver_break_time_from'),
                field: "driver_break_time",
                width: "110"
            },
            {
                headerName: t('driver_break_time_to'),
                field: "break_time_to",
                width: "100",
                editable: true,
                cellEditorPopup: true,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: Constant.TIMER_VALUES,
                }
            },
            {
                headerName: t('min_seating_capacity'),
                field: "min_volume_cmt",
                width: "120"
            },
            {
                headerName: t('max_seating_capacity'),
                field: "max_volume_cmt",
                width: "120"
            },
            {
                headerName: t('vehicle_seating_capacity'),
                field: "vehicle_dimensions_width_mm",
                width: "160"
            },
            {
                headerName: t('vehicle_height_mm'),
                field: "vehicle_dimensions_height_mm",
                width: "125"
            },
            {
                headerName: t('vehicle_depth_mm'),
                field: "vehicle_dimensions_depth_mm",
                width: "120"
            },
            {
                headerName: t('vehicle_min_weight_kgs'),
                field: "vehicle_min_weight",
                width: "120"
            },
            {
                headerName: t('vehicle_max_weight_kgs'),
                field: "vehicle_max_weight",
                width: "120"
            },
            {
                headerName: t('vehicle_min_volume_m3'),
                field: "min_volume_m3",
                width: "130"
            },
            {
                headerName: t('vehicle_max_volume_m3'),
                field: "max_volume_m3",
                width: "130"
            },
            {
                headerName: t('vehicle_capacity_cmt'),
                field: "vehicle_capacity",
                width: "135"
            },
            {
                headerName: t('min_load_per_delivery'),
                field: "min_load_per_delivery",
                width: "140"
            },
            {
                headerName: t('max_distance_kms'),
                field: "max_distance",
                width: "120"
            },
            {
                headerName: t('monthly_min_planning_distance_kms'),
                field: "monthly_min_planning_distance",
                hide: (monthly_distance_preferences === 1 ? false : true),
                width: 150
            },
            {
                headerName: t('vehicle_priority'),
                field: "vehicle_priority",
                width: "100"
            },
            {
                headerName: t('variable_vehicle_cost'),
                field: "variable_vehicle_cost_expandable",
                width: "120"
            },
            {
                headerName: t('actions'),
                field: "cluster",
                width: "140",
                pinned: 'left',
                params: { buttonName: t('edit'), onClickFunction: this.updateVehicleConfiguration },
                cellRendererSelector: function (params) {
                    var rendComponent = {
                        component: "GridButton"
                    };
                    return rendComponent;
                },
            },
            {
                headerName: t('delete'),
                field: "cluster",
                width: "140",
                pinned: 'left',
                params: { buttonName: t('delete'), iconName: "fa fa-trash", onClickFunction: this.deleteVehicleConfiguration },
                cellRendererSelector: function (params) {
                    var rendComponent = {
                        component: "GridButton"
                    };
                    return rendComponent;
                },
            },
        ];
        
        // if(this.state.data_type == 1){
        //     colsDefs.push({
        //         headerName:"Suppliers / Vendor Clubbing Limits" ,
        //         field:"max_no_of_nodes",
        //         width:"300"
        //     });
        // }else{
        //     colsDefs.push({
        //         headerName:"Consignees / Dealers Clubbing Limits" ,
        //         field:"max_no_of_nodes",
        //         width:"300"
        //     });
        // }
        if (localStorage.getItem("email") === 'deletedataaccess@enmovil.in'){
            colsDefs.push({
                    headerName:"",
                    field:"cluster",
                    width:"80",
                    pinned:'left',
                    params:{buttonName:'Delete',iconName:"fa fa-trash",
                            onClickFunction:this.deleteVehicleConfiguration},
                    cellRendererSelector:function(params){
                        
                        var rendComponent = {
                            component: "GridButton"
                        };
                        return rendComponent
                    
                    },
                },)
        }
       

        return(
            <div className="">
            <SweetAlert
                show={this.state.show}
                type={this.state.basicType}
                title={this.state.basicTitle}
                onConfirm={this.closeAlert}>
            </SweetAlert>
            {/* <div class="row mb-20p">
                    <div className="col-sm-12">
                        <h5 className='fbold'>
                            <span>{Constant.MASTER_VEHICLE_DATA_HEADER_NAME}</span>
                                    <div className='float-right col-sm-4'>
                                    <a href="javascript:;" onClick={this.openModelForPickupDrop} className="f18 float-right themefont fbold mr-10p mb-10p">+ Add pickup/drop locations</a>
                                    
                                    <span className="float-right">
                                    <a href="javascript:;"  onClick={this.openUploadOptionForPickupDrop} className="btn btn-warning f12 mr-10p">+ Upload pickup/drop Locations Data</a>
                                    </span>
                                    </div>    
                        </h5>
                    </div>
                </div> */}
                
                <div class="row mb-20p pt-20p">
                <div className="col-sm-12 d-flex">
                <div className= {this.state.showAvailablityTable ? "col-sm-7 " : "col-sm-12 " + "  d-inline"}>
                    
                        <h5 className='fbold d-flex justify-content-between'>
                            <span>{t('vehicle_forecast_configurations')}</span>
                            {/* {vehicleTypeCnt > this.state.rowData.length ? */}
                            <div className='float-right'>
                            <a href="javascript:;" onClick={this.openModelForVehicleConfiguration} className="f12 btn btn-default float-right themefont fbold mr-10p mb-10p">{t('add_vehicle_type')}</a>
                            {/* <span className="float-right">
                            <a href="javascript:;"  onClick={this.openUploadOptionForPickupDrop} className="btn btn-warning f12 mr-10p">{Constant.MASTER_VEHICLE_DATA_CONFIGURATION_BUTTON_NAME}</a>
                            </span> */}
                            {/* </div> 
                            : <div className='float-right'>
                            <a href="javascript:;" data-toggle="tooltip" title="Max-Limit Reached" className="f12 btn btn-default float-right themefont fbold mr-10p mb-10p">{Constant.MASTER_VEHICLE_DATA_CONFIGURATION_BUTTON_NAME}</a> */}
                            
                            {/* <span className="float-right">
                            <a href="javascript:;"  onClick={this.openUploadOptionForPickupDrop} className="btn btn-warning f12 mr-10p">{Constant.MASTER_VEHICLE_DATA_CONFIGURATION_BUTTON_NAME}</a>
                            </span> */}
                            </div>

                        </h5>
                            {truckPriorities ?
                            <span className="float-right">
                            <a href="javascript:;"  onClick={this.handelVehiclePriorityConfigurationModel} className="btn btn-warning f12 mr-10p">{t('set_vehicle_priority')}</a>
                            </span>
                            :<div/>}
                            <span className="float-right">
                            <a href="javascript:;"  onClick={this.importExistingVehicleDetails} className="btn btn-warning f12 mr-10p">{t('import_existing_vehicle_details')}</a>
                            </span>
                        <div className="col-md-2 col-sm-12">
                            <div className="form-group ">
                                <label className="col-form-label f12">{t('pickup_point')}</label>
                                <Select
                                    placeholder={"Select"}
                                    onChange={this.locationChanged}
                                    name="location"
                                    value={this.state.location}
                                    options={this.state.locationNames} 
                                    />

                            </div>
                        </div>

                        <div id="myGrid" style={{width:"100%",height:"70vh"}} className="col-sm-12 ag-theme-balham dropdown">
                        <AgGridReact
                        // modules={this.state.modules} 
                            rowData={filteredData}
                            columnDefs={colsDefs}
                            gridOptions={{context:{componentParent:this}}}
                            defaultColDef={this.state.defaultColDef}
                            frameworkComponents={this.state.frameworkComponents}
                            paginationPageSize={this.state.paginationPageSize}
                            pagination={true}
                        />
                        </div>
                        
                    </div>

                    </div>
                </div>

                <Modal closeOnOverlayClick={false} open={this.state.openVehicleConfigurationModel} onClose={this.handelVehicleConfigurationModelCancel} 
                    classNames={{modal: 'pickupdrop-modelClass'}} >
                        <div className="col-sm-12">
                            <div>
                                <h4>{t('vehicle_type_configuration')}</h4>
                            </div>
                    <form className="theme-form" onSubmit={this.formVehicleConfiguration}>

                        <div className="row number">
                        <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{t('pickup_point')}</label>
                                    <Select
                                        isDisabled
                                        name="location"
                                        value={this.state.location}
                                         />
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{t('vehicle_type')} <span className="redColor">*</span></label>
                                    <input required type="text" className="forminp form-control" id="vehicle_type" name="vehicle_type" value={this.state.vehicle_type} 
                                    onChange={this.onChangeVehicleType}
                                     placeholder="" autoComplete="off" />
                                    
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{t('vehicle_type_name')}  <span className="redColor">*</span></label>
                                    <input required type="text" className="forminp form-control" name="vehicle_name" value={this.state.vehicle_name}
                                     onChange={this.onChangeVehicleName} 
                                      placeholder="" autoComplete="off" />
                                                                          
                                </div>
                            </div>
                            
                            <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label htmlFor="vehicle_priority" className="col-form-label f12">{t('vehicle_priority')}</label>
                                    {/* <Select
                                        placeholder={"Select"}
                                        onChange={this.vehiclePriority}
                                        className={""}
                                        name="vehicle_priority"
                                        value={this.state.vehicle_priority}
                                        options={this.priorityOptions()} 
                                        isDisabled={toolConfig[0].truck_priorities ? false : true}
                                        
                                        /> */}

                                    <select name="vehicle_priority" id="vehicle_priority" value = {this.state.vehicle_priority}
                                        required={truckPriorities ? true : false}
                                        disabled={truckPriorities ? false : true}
                                        className={truckPriorities ? "forminp form-control"  : "forminp form-control disable-field"}
                                        onChange={this.vehicleConfigurationInputFields}>
                                        {/* <option value="" disabled selected>Select</option> */}
                                        <option value="Owned">{t('owned')}</option>
                                        <option value="Contract">{t('contract')}</option>
                                        <option value="Market">{t('market')}</option>
                                    </select>
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{t('min_volume_cft')}  <span className="redColor">*</span></label>
                                    <input type="text" 
                                    name="min_volume_m3" id="min_volume_m3" value={this.state.min_volume_m3} 
                                    onChange={this.checkNotEqualstoZero}  
                                    onBlur={this.onBlurMinVolume}
                                    placeholder="" autoComplete="off"
                                    className= { "forminp form-control" }
                                    
                                    />           
                                </div>
                            </div>

                            

                            <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{t('max_volume_cft')}  <span className="redColor">*</span></label>
                                    <input type="text"
                                    name="max_volume_m3" value={this.state.max_volume_m3} 
                                    onChange={this.checkNotEqualstoZero} autoComplete='off'
                                    onBlur={this.onBlurMaxVolume}
                                    required = {truck_min_max ? true : false }
                                    className= { truck_min_max ? "forminp form-control"  : "forminp form-control disable-field"}
                                    />
                                </div>
                            </div>


                            {/* <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{Constant.MASTER_VEHICLE_DATA_CONFIGURATION_VEHICLE_DIMENSIONS}</label>
                                    <div className="d-flex justify-content-between number">
                                    /* <div className="col-md-4 col-sm-4 d-flex control-padding"> //
                                        /* <label className="col-form-label f14 d-inline font-bold">W</label> //
                                        <input required type="number" min = '0' className="forminp form-control d-inline control-padding-right" id="width" name="vehicle_dimensions_width_mm" value={this.state.vehicle_dimensions_width_mm} 
                                    onChange={this.vehicleConfigurationInputFields}
                                     placeholder="width" autoComplete="off" />
                                    /* </div> //

                                    /* <div className="col-md-4 col-sm-4 d-flex control-padding">
                                        <label className="col-form-label f14 d-inline font-bold">H</label>
                                        <input required type="number" min = '0' className="forminp form-control d-inline control-padding-right" id="height" name="vehicle_dimensions_height_mm" value={this.state.vehicle_dimensions_height_mm} 
                                    onChange={this.vehicleConfigurationInputFields}
                                     placeholder="height" autoComplete="off" />
                                    </div>

                                    <div className="col-md-3 col-sm-4 d-flex control-padding">
                                        <label className="col-form-label f14 d-inline font-bold">D</label>
                                        <input required type="number" min = '0' className="forminp form-control d-inline control-padding-right" id="depth" name="vehicle_dimensions_depth_mm" value={this.state.vehicle_dimensions_depth_mm} 
                                    onChange={this.vehicleConfigurationInputFields}
                                     placeholder="depth" autoComplete="off" />
                                    </div> //
                                    </div>
                                    
                                </div>
                            </div> 
                            */}
                            <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{t('vehicle_min_weight')}  <span className="redColor">*</span></label>
                                    <input type="text" id="vehicle_min_weight" name="vehicle_min_weight" value={this.state.vehicle_min_weight} 
                                    onChange={this.checkNotEqualstoZero}
                                    onBlur={this.onBlurVehicleMinWeight}
                                    required = {vehicleMinMaxWeight ? true : false }
                                    className= { vehicleMinMaxWeight ? "forminp form-control"  : "forminp form-control disable-field"}
                                    disabled = {vehicleMinMaxWeight ? false : true }
                                     placeholder="" autoComplete="off" />
                                    
                                </div>
                            </div> 

                          <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{t('vehicle_max_weight')}  <span className="redColor">*</span></label>
                                    <input type="text" id="vehicle_max_weight" name="vehicle_max_weight" value={this.state.vehicle_max_weight} 
                                    onChange={this.checkNotEqualstoZero}
                                    onBlur={this.onBlurVehicleMaxWeight}
                                    required = {vehicleMinMaxWeight ? true : false }
                                    className= { vehicleMinMaxWeight ? "forminp form-control"  : "forminp form-control disable-field"}
                                    disabled = {vehicleMinMaxWeight ? false : true }
                                     placeholder="" autoComplete="off" />
                                </div>
                            </div>

                            {/* <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{Constant.MASTER_VEHICLE_DATA_CONFIGURATION_VEHICLE_CAPACITY} </label>
                                    <input required type="number" min = '0' className="forminp form-control" id="vehicle_capacity" name="vehicle_capacity" value={this.state.vehicle_capacity} 
                                    onChange={this.vehicleConfigurationInputFields}
                                     placeholder="" autoComplete="off" />
                                </div>
                            </div> */}

                            <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{t('max_distance_per_trip')} <span className="redColor">*</span></label>
                                    <input type="number" min = '1' 
                                    required = {distancePreferences ? true : false }
                                    className= { distancePreferences ? "forminp form-control"  : "forminp form-control disable-field"}
                                    placeholder = { distancePreferences ? "Kms" : "Maximum" }
                                    disabled = {distancePreferences ? false : true }
                                    id="max_distance" name="max_distance" value={this.state.max_distance} 
                                    onChange={this.checkNotEqualstoZero}
                                    autoComplete="off" />
                                    
                                </div>
                            </div>

                            
                            
                            {/* <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{Constant.MASTER_VEHICLE_DATA_CONFIGURATION_VEHICLE_AVAILABILITY_TIME}</label>
                                    { operationalTimes ? 
                                      operationalTimesType == 1 ? 
                                    <TimeRangePicker  onChange={this.handleAvailabilityTime} value={this.state.vehicle_availability_time} rangeDivider={'to'} 
                                    required={true} 
                                    className =  "forminp form-control vehicle-time"
                                    clearIcon={null} clockIcon={null} openClockOnFocus={false}/>
                                    : operationalTimesType == 2 ? 
                                    <TimeRangePicker  rangeDivider={'to'} value={this.state.vehicle_availability_time} className =  "forminp form-control vehicle-time disable-field-time" disabled = {true} clearIcon={null} clockIcon={null} openClockOnFocus={false}/>
                                    :<TimeRangePicker  rangeDivider={'to'} value={this.state.vehicle_availability_time} className =  "forminp form-control vehicle-time disable-field-time" disabled = {true} clearIcon={null} clockIcon={null} openClockOnFocus={false}/>
                                    :<TimeRangePicker  rangeDivider={'to'} value={this.state.vehicle_availability_time} className =  "forminp form-control vehicle-time disable-field-time" disabled = {true} clearIcon={null} clockIcon={null} openClockOnFocus={false}/>
                                    }
                                </div>
                            </div>

                            
                             */}
                            

                            {/* <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    {this.state.data_type == 1 ? 
                                    <label className="col-form-label f12">{Constant.MASTER_VEHICLE_DATA_CONFIGURATION_VEHICLE_MAX_DELIVERY_INBOUND}</label>
                                    : <label className="col-form-label f12">{Constant.MASTER_VEHICLE_DATA_CONFIGURATION_VEHICLE_MAX_DELIVERY_OUTBOUND}</label>}
                                    <input type="number" min = '1' id="max_no_of_nodes" name="max_no_of_nodes" value={this.state.max_no_of_nodes} 
                                        onChange={this.vehicleConfigurationInputFields}
                                        placeholder= {clubbingLimits ? "" : 'Maximum'} autoComplete="off" 
                                        required = {clubbingLimits ? true : false }
                                        className= { clubbingLimits ? "forminp form-control"  : "forminp form-control disable-field"}
                                        disabled = {clubbingLimits ? false : true } 
                                    />
                                    
                                </div>
                            </div> */}

                            {/* <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{Constant.MASTER_VEHICLE_DATA_CONFIGURATION_VEHICLE_MIN_LOAD}</label>
                                    <input type="text" id="min_load_per_delivery" name="min_load_per_delivery" value={this.state.min_load_per_delivery} 
                                        onChange={this.vehicleConfigurationInputFields}
                                        placeholder="" autoComplete="off" 
                                        required = {minLoad ? true : false }
                                        className= { minLoad ? "forminp form-control"  : "forminp form-control disable-field"}
                                        disabled = {minLoad ? false : true}
                                     />
                                    
                                </div>
                            </div> */}
                            {/* {vehicleAvailability == 0 ?
                            <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">Vehicle Type Count</label>
                                    <input type="number" min = '1' id="vehicle_type_count" name="vehicle_type_count" value={this.state.vehicle_type_count} 
                                        onChange={this.vehicleConfigurationInputFields}
                                        placeholder="" autoComplete="off" 
                                        required = {true}
                                        className=  "forminp form-control"
                                    />
                                    
                                </div>
                            </div>
                            :<div/>} */}

                            {vehicleAvailability == 1 && vehicleAvailabilityType == 1 ?
                            <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{t('vehicle_type_count')} </label>
                                    <input type="number" min = '1' id="vehicle_type_count" name="vehicle_type_count" value={this.state.vehicle_type_count} 
                                        onChange={this.vehicleConfigurationInputFields}
                                        placeholder="" autoComplete="off" 
                                        required = {true}
                                        className=  "forminp form-control"
                                    />
                                    
                                </div>
                            </div>
                            :<div/>}
                            {monthly_distance_preferences === 1?

                                <div className="col-md-4 col-sm-12">
                                    <div className="form-group ">
                                        <label className="col-form-label f12">{t('monthly_min_planning_distance_kms')} <span className="redColor">*</span></label>
                                        <input type="number" min = '1' id="monthly_min_planning_distance" name="monthly_min_planning_distance" value={this.state.monthly_min_planning_distance} 
                                            onChange={this.checkNotEqualstoZero}
                                            placeholder="" autoComplete="off" 
                                            required = {true}
                                            className=  "forminp form-control"
                                        />
                                        
                                    </div>
                                </div>
                                :""}
                             <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{t('driver_break_time_per_day')} <span className="redColor">*</span></label>
                                    <input type="text" name="driver_break_time" id="driver_break_time" value={this.state.driver_break_time} onChange={this.onChangeDriverBreakTime.bind(this)} className="forminp form-control" />
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{t('cost_per_km')}  <span className="redColor">*</span></label>
                                    <input type="number" name="cost_per_km" id="cost_per_km" value={this.state.cost_per_km} onChange={this.vehicleConfigurationInputFields} className="forminp form-control" />
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <button type="submit" className="btn tbtn float-right save-btn" id="saveGeo">{t('save')}</button>
                            <button type='button' className="btn tbtn float-right cancel-btn" style={{minWidth: "fit-content"}} onClick={this.handelVehicleConfigurationModelCancel}>{t('cancel')}</button>	
                        </div>
                    </form>
                </div>
                </Modal>

                <Modal closeOnOverlayClick={false} open={this.state.openVehiclePriorityConfigurationModel} onClose={this.handelVehiclePriorityConfigurationModelCancel} 
                    classNames={{modal: 'vehiclepriority-modelClass'}} >
                        <div className="col-sm-12">

                            <div className="mb-10p">
                                <h4>{t('set_vehicle_priority')}</h4>
                            </div>
                        <div className="col-md-12 col-sm-12 d-flex justify-content-around">
                            <h5 className="col-md-6 d-flex justify-content-center align-self-center fw-600">Owned</h5>
                                <div className="form-group col-md-6">
                                    
                                    <select name="vehicle_priority_order_owned" id="vehicle_priority_order_owned" value = {this.state.vehicle_priority_order_owned}
                                        className= "forminp form-control" onChange={this.vehicleConfigurationInputFields}>
                                        
                                        <option value="1">{t('high')}</option>
                                        <option value="2">{t('medium')}</option>
                                        <option value="3">{t('low')}</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-12 col-sm-12 ">
                            <h5 className="col-md-6 d-flex justify-content-center align-self-center fw-600">Contract</h5>
                                <div className="form-group col-md-6">
                                    
                                    <select name="vehicle_priority_order_contract" id="vehicle_priority_order_contract" value = {this.state.vehicle_priority_order_contract}
                                        className= "forminp form-control" onChange={this.vehicleConfigurationInputFields}>
                                        
                                        <option value="1">{t('high')}</option>
                                        <option value="2">{t('medium')}</option>
                                        <option value="3">{t('low')}</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-12 col-sm-12 ">
                            <h5 className="col-md-6 d-flex justify-content-center align-self-center fw-600">Market</h5>
                                <div className="form-group col-md-6">
                                    
                                    <select name="vehicle_priority_order_market" id="vehicle_priority_order_market" value = {this.state.vehicle_priority_order_market}
                                        className= "forminp form-control" onChange={this.vehicleConfigurationInputFields}>
                                        
                                        <option value="1">{t('high')}</option>
                                        <option value="2">{t('medium')}</option>
                                        <option value="3">{t('low')}</option>
                                    </select>
                                </div>
                            </div>

                            <div className="form-group">
                            <button type="button" onClick={this.saveVehiclePriorityOrder} className="btn tbtn float-right save-btn" >{t('save')}</button>
                            <button type='button' className="btn tbtn float-right cancel-btn" style={{minWidth: "fit-content"}} onClick={this.handelVehiclePriorityConfigurationModelCancel}>{t('cancel')}</button>	
                        </div>
                            </div>
                            </Modal>
            </div>
        )
    }
}
export default withTranslation()(VehiclesForecastData)