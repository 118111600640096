import React, {Component} from 'react';

import { getDDMMYYYY, getDDMMYYYYHHMMSS, getDDMMYYYYHHMM } from '../common/utils'; 

import Modal from 'react-responsive-modal';



import $ from 'jquery';

var infoBox = require('../common/google-infowindow');
var moment = require('moment');

var drawingManager;
var selectedShape;
var colors = ['#1D9567', '#1692A5', '#F4D749','#F73E3E', '#B7B3B3' ];
var selectedColor;
var colorButtons = {};
var fencingLength=0;
var mapZoom=12;
var coordinates=[];
var viewMarkersArr=[];
var coords_copy = [];
var geoFencingArea;
var markersArray;
var map;
var route_deviation_polyline_dict = {1: "route_deviation_polyline_1",2: "route_deviation_polyline_2",3: "route_deviation_polyline_3"}
var pathcolor = ["#157254","blue","red"]
class DrawMapDisptachPallet extends Component {
	constructor(props){
		super(props);
		this.state = {
			mapinfo:'',
			tolls : [],
			proptruckno:'',
			defTransitCoords:'',
			activeMarker: {},
			selectedPlace: {},
			dealerCode:'',
			timelinesmarkers:[],
			viewtimelinemarkers:false,
			viewgoogleroutes:true,
			googleroutepath:[],
			loadshow:'show-n',
			open: false,
			defaultColDef: {
				sortable: true,
			  filter: true,
			  resizable: true

			},
			rowData: null,
			  
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			overlayNoRowsTemplate: "",
			frameworkComponents: {

            },

			animateRows: true,
			debug: true,
			showToolPanel: false,
		  uppressAggFuncInHeader: true,
			  
		  childconfs: [],
		  childrow:[],
		  rowModelType: "serverSide",
		  paginationPageSize:50,
		  cacheBlockSize: 10,
		  maxBlocksInCache: 1,
		  showMarkers:0,
		  statusBar: {
			  statusPanels: [
				  {
				  statusPanel: "agTotalAndFilteredRowCountComponent",
				  align: "left"
				  },
				  {
				  statusPanel: "agTotalRowCountComponent",
				  align: "center"
				  },
				  { statusPanel: "agFilteredRowCountComponent" },
				  { statusPanel: "agSelectedRowCountComponent" },
				  { statusPanel: "agAggregationComponent" }
			  ]
		  },
		  sideBar: {
			  toolPanels: [
				  {
				  id: "columns",
				  labelDefault: "Columns",
				  labelKey: "columns",
				  iconKey: "columns",
				  toolPanel: "agColumnsToolPanel"
				  },
				  {
				  id: "filters",
				  labelDefault: "Filters",
				  labelKey: "filters",
				  iconKey: "filter",
				  toolPanel: "agFiltersToolPanel"
				  }
			  ]
		  },
		  detailCellRendererParams:{},
		  rowClassRules: {
			  "highlitRow": "data.transit_delay == 1",
			  "green":"data.status >= 3"

		  },
		  data_type : "",
		  selectedorigin:"",
		  selecteddestination:"",
		  returnTripsDashboardRowData: [],
		  uncheckls:[],
		  viewcount:0
		};
	}
	componentDidMount(){
		// map.setMap(null);
		if(this.props.trip_gps_data !== "" && this.props.trip_gps_data !== undefined)
		{
			var trip_gpsarr = this.props.trip_gps_data;
		}
		else{
			var trip_gpsarr = []
		}
		var uncheckls = [];
		var colors = ["#012071","#E01146","#054ECF","#AB7502","#4D8E05","#017163","#70CF05","#CFCC05","#7CE011","#11E0A1","#055BCF","#CF0505","#CF8905","#CFCC05","#05CF9B","#05CF0E","#05B3CF", "#6705CF","#B705CF","#CF057F","#CF05BD","#CF0564","#CF0505","#4C7101","#017141","#014F71","#713601","#470171","#710167","#2CA3A5","#A3A52C","#1169E0","#9B11E0","#E011D3","#E0117F"]
		var sn=0;
		if(trip_gpsarr.length > 0)
		{
			console.log(trip_gpsarr.length,"trip_gpsarr.length")
			var sqs = groupBy(trip_gpsarr, rdata => rdata.trip_no);
			try {
				sqs.forEach((itm,keys) => {
					uncheckls.push({id:keys,label:itm[0].vehicle_no,isChecked:true, colorCode:colors[sn]})
					sn = sn+1
				})
			} catch (error) {
				
			}
		}
		this.setState({
			uncheckls:uncheckls,
			viewcount:this.props.viewtype
		})
		setTimeout(() => {
			this.renderMap()
		},300)

	}
	componentWillReceiveProps(newprops){
		// map.setMap(null);
		if(newprops.trip_gps_data !== "" && newprops.trip_gps_data !== undefined)
		{
			var trip_gpsarr = newprops.trip_gps_data;
		}
		else{
			var trip_gpsarr = []
		}
		var uncheckls = [];
		var colors = ["#012071","#E01146","#054ECF","#AB7502","#4D8E05","#017163","#70CF05","#CFCC05","#7CE011","#11E0A1","#055BCF","#CF0505","#CF8905","#CFCC05","#05CF9B","#05CF0E","#05B3CF", "#6705CF","#B705CF","#CF057F","#CF05BD","#CF0564","#CF0505","#4C7101","#017141","#014F71","#713601","#470171","#710167","#2CA3A5","#A3A52C","#1169E0","#9B11E0","#E011D3","#E0117F"]
		var sn=0;
		if(trip_gpsarr.length > 0)
		{
			// console.log(trip_gpsarr.length,"trip_gpsarr.length--1")
			var sqs = groupBy(trip_gpsarr, rdata => rdata.trip_no);
			try {
				sqs.forEach((itm,keys) => {
					uncheckls.push({id:keys,label:itm[0].vehicle_no,isChecked:true, colorCode:colors[sn]})
					sn = sn+1
				})
			} catch (error) {
				
			}
		}
		this.setState({
			uncheckls:uncheckls
		})

		if(newprops.truckno != this.state.proptruckno)
		{
			// this.renderMap()
			this.setState({
				proptruckno:newprops.truckno
			})
		}
		// if(newprops.selectedorigin != this.state.selectedorigin && newprops.selecteddestination != this.state.selecteddestination)
		// {
			setTimeout(() => {
				this.renderMap()
			},300)
		// }

		this.setState({
			viewcount:newprops.viewtype
		})
		
		
	}
    renderMap = () => {   
		
		
		loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyDxzGDgeuoSs15Y8z-6EFGt2a2QjjPHF3c&libraries=places,drawing&callback=initMap");
		window.initMap = this.initMap
	}
	
	onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	
	   //    ChartMenu.prototype.saveChart = function (e) {
	// 	   var event = {
	// 	      data: this.gridOptionsWrapper.getApi(),
	// 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
	// 	    };
	// 	   //console.log("Testing", event);
	// 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
	// 	   this.dispatchEvent(event);
	// 	   var chrtdata = event.data.chartService.rangeController.cellRanges;
		   
	//    };
	   //console.log("Deptcode " ,this.state.deptcode)
	    if(this.state.deptcode == 'LOG-PRT')
	    {
			this.gridColumnApi.setColumnsVisible(["nightdriving_exception"], false);
			this.gridColumnApi.setColumnsVisible(["loading_delay_exception"], false);
		}
		if(this.state.deptcode == 'SNDG')
		{
			this.gridColumnApi.setColumnsVisible(["nightdriving_exception"], true);
			this.gridColumnApi.setColumnsVisible(["loading_delay_exception"], true);
		}

		
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		   
		   
	}

	handleCheckboxChange = (event) => {
		const { uncheckls } = this.state;
		// console.log("unchecklsasd ", uncheckls)
		const target = event.target;
		// console.log("target ", target.value)
		const index = uncheckls.findIndex(option => parseInt(option.id) === parseInt(target.value));
		// console.log("index ", index)
		// console.log("uncheckls", uncheckls[index].isChecked)
		uncheckls[index].isChecked = !uncheckls[index].isChecked;
		this.setState({ uncheckls: uncheckls });
		this.renderMap();
	}
	

	initMap = () => {
		
    //console.log("Yes Here")
    //console.log("mapFor ",this.props.mapFor)
    //console.log("mapinfo ",this.props.mapinfo)
		try{
			var currentwindow;
			var dealer='';
			var consigner='';
			this.setState({
				dealerCode:this.props.consigneecoords
			});
			var routeinfo ;
			if(this.props.plant_loc_data !== "" && this.props.plant_loc_data !== undefined)
			{
				var plant_loc_data = this.props.plant_loc_data;
			}
			else{
				var plant_loc_data = []
			}
			
			if(this.props.trip_gps_data !== "" && this.props.trip_gps_data !== undefined)
			{
				var trip_gpsarr = this.props.trip_gps_data;
			}
			else{
				var trip_gpsarr = []
			}
			if(this.props.trip_nodes_data !== "" && this.props.trip_nodes_data !== undefined)
			{
				var trip_nodes_data = this.props.trip_nodes_data;
			}
			else{
				var trip_nodes_data = []
			}
			var trip_gps = [];
			
			if(this.props.palletLoadLocations !== "" && this.props.palletLoadLocations !== undefined)
			{
				var palletLoadLocations = this.props.palletLoadLocations;
			}
			else{
				var palletLoadLocations = [];
			}

			if(palletLoadLocations.length > 0)
			{
				var lt=palletLoadLocations[0].demand_node_latitude;
				var ln=palletLoadLocations[0].demand_node_longitude;
			}
			else{
				var lt=22.1715379;
				var ln=79.0729421;
			}
			// console.log("palletLoadLocations ",palletLoadLocations)
			var mapOptions = {
				zoom: 11,
				zoomControl: true,
				mapTypeControl: true,
				scaleControl: true,
				streetViewControl: true,
				rotateControl: true,
				fullscreenControl: true,
				labels:true,
				mapTypeControlOptions: {
					mapTypeIds: ['roadmap'],
				},
				center: new window.google.maps.LatLng(lt,ln),
				mapTypeId: window.google.maps.MapTypeId.ROADMAP
			};
					
			map = new window.google.maps.Map(document.getElementById('mapPallet'),
				mapOptions);

			var bounds = new window.google.maps.LatLngBounds();
			bounds.extend(new window.google.maps.LatLng(lt,ln));
				// Create our info window content
			var currentinfowindow = null;
			
			var arr = trip_gps;
			// console.log("trip_gps ", arr)
			var infowindow = new window.google.maps.InfoWindow();
			var markers= []
			if(arr.length > 0)
			{
				var line=new window.google.maps.Polyline(
				{
					map:map,
					strokeColor: '#157254',
					strokeOpacity: 1.0,
					strokeWeight: 3,
					icons: [{
							icon: {
									path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
									strokeColor:'#ff0000',
									fillColor:'#157254',
									fillOpacity:1,
									strokeWeight: 2
									},
							repeat:'100px',
							path:[]
						}]
					}); 
				for (let i = 0; i < arr.length; i++) {
				
					var path=line.getPath().getArray();
					let latLng=new window.google.maps.LatLng(arr[i].latitude,arr[i].longitude);
					path.push(latLng);
					line.setPath(path);

				}
				
			}
			var uncheckls = this.state.uncheckls;
			// console.log("uncheckls ", uncheckls)
			var unckitems = []
			if(uncheckls.length > 0)
			{
				uncheckls.map((it) => {
					if(it.isChecked === false)
					{
						unckitems.push(it.id)
					}
					
				})
			}
			
			// if(trip_gpsarr.length > 0)
			// {
			// 	var trip_gpsarrli = []
			// 	var ratio = 30;
			// 	trip_gpsarrli = trip_gpsarr.filter(function (value, index, darr) {
			// 		return (index % ratio == 0);
			// 	} );
			// 	var colors = ["#012071","#E01146","#054ECF","#AB7502","#4D8E05","#017163","#70CF05","#CFCC05","#7CE011","#11E0A1","#055BCF","#CF0505","#CF8905","#CFCC05","#05CF9B","#05CF0E","#05B3CF", "#6705CF","#B705CF","#CF057F","#CF05BD","#CF0564","#CF0505","#4C7101","#017141","#014F71","#713601","#470171","#710167","#2CA3A5","#A3A52C","#1169E0","#9B11E0","#E011D3","#E0117F"]
		
			// 	var sqs = groupBy(trip_gpsarrli, rdata => rdata.trip_no);
			// 	try {
			// 		var sn = 0;
					
			// 		sqs.forEach((itmlist,keys) => {
			// 			if(unckitems.length > 0)
			// 			{
			// 				// console.log("uncheckls.indexOf(keys) ", unckitems.indexOf(keys))
			// 				if(unckitems.indexOf(keys) >= 0)
			// 				{
								
			// 				}
			// 				else{
			// 					var line=new window.google.maps.Polyline(
			// 						{
			// 							map:map,
			// 							// strokeColor: '#157254',
			// 							strokeColor: colors[sn],
			// 							strokeOpacity: 1.0,
			// 							strokeWeight: 3,
			// 							icons: [{
			// 									icon: {
			// 											path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
			// 											strokeColor:'#ff0000',
			// 											fillColor:'#157254',
			// 											fillOpacity:1,
			// 											strokeWeight: 2
			// 											},
			// 									repeat:'100px',
			// 									path:[]
			// 								}]
			// 							});
			// 						if(itmlist.length > 0)
			// 						{
			// 							for (let i = 0; i < itmlist.length; i++) {
							
			// 								var path=line.getPath().getArray();
			// 								let latLng=new window.google.maps.LatLng(itmlist[i].latitude,itmlist[i].longitude);
			// 								path.push(latLng);
			// 								line.setPath(path);
						
			// 							}
			// 						}
			// 				}
			// 			}
			// 			else{
			// 				var line=new window.google.maps.Polyline(
			// 				{
			// 					map:map,
			// 					// strokeColor: '#157254',
			// 					strokeColor: colors[sn],
			// 					strokeOpacity: 1.0,
			// 					strokeWeight: 3,
			// 					icons: [{
			// 							icon: {
			// 									path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
			// 									strokeColor:'#ff0000',
			// 									fillColor:'#157254',
			// 									fillOpacity:1,
			// 									strokeWeight: 2
			// 									},
			// 							repeat:'100px',
			// 							path:[]
			// 						}]
			// 					});
			// 				if(itmlist.length > 0)
			// 				{
			// 					for (let i = 0; i < itmlist.length; i++) {
					
			// 						var path=line.getPath().getArray();
			// 						let latLng=new window.google.maps.LatLng(itmlist[i].latitude,itmlist[i].longitude);
			// 						path.push(latLng);
			// 						line.setPath(path);
				
			// 					}
			// 				}
			// 			}
			// 			sn = sn+1;
			// 		})
			// 	} catch (error) {
					
			// 	}
			// }
			var infowindow = new window.google.maps.InfoWindow();
			// if(plant_loc_data.length > 0)
			// {
			// 	for (var l = 0; l < plant_loc_data.length; l++) {
			// 		// console.log(plant_loc_data[l])
			// 		bounds.extend(new window.google.maps.LatLng(parseFloat(plant_loc_data[l].latitude), parseFloat(plant_loc_data[l].longitude)));
			// 		var marker = new window.google.maps.Marker({
			// 			position: new window.google.maps.LatLng(parseFloat(plant_loc_data[l].latitude), parseFloat(plant_loc_data[l].longitude)),
			// 			content:plant_loc_data[l],
			// 			title:plant_loc_data[l].location_label,
			// 			icon:require('../../assets/images/business.png'),
			// 			map: map,
			// 		});
			// 		addMarker(new window.google.maps.LatLng(parseFloat(plant_loc_data[l].latitude), parseFloat(plant_loc_data[l].longitude)),plant_loc_data[l].location_label.toString(),plant_loc_data[l]);
			// 		window.google.maps.event.addListener(marker, 'click', (function(marker) {
			// 			return function() {
			// 			var contentarr = []
			// 			//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
			// 			var header = marker.content.location_code
			// 			contentarr.push({"key":"Location", "value":marker.content.location_name})
			// 			if(marker.content.distance_from_pickup !=undefined && marker.content.distance_from_pickup !="undefined")
			// 			{
			// 				contentarr.push({"key":"Distance / Time From Pickup Location", "value":marker.content.distance_from_pickup+" Kms / "+marker.content.time_from_pickup+" Mins"})
			// 				contentarr.push({"key":"Distance / Time From Previous Drop Point", "value":marker.content.distance_from_prev_drop+" Kms/"+marker.content.time_from_prev_drop+" Mins"})
			// 			}
						
			// 			var contentString = infoBox(marker.icon, header, contentarr,'')
							
			// 			infowindow.setContent(contentString);
			// 			currentwindow = infowindow;
			// 			infowindow.open(map, marker);
			// 			// console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
			// 			}
			// 		})(marker));
			// 	}

			// }
			// if(trip_nodes_data.length > 0)
			// {
			// 	if(trip_nodes_data.length > 0)
			// 	{
			// 		var sqst = groupBy(trip_nodes_data, rdata => rdata.trip_no);
			// 		try {
			// 			var sna = 0;
			// 			sqst.forEach((values,kys) => {
			// 				if(unckitems.length > 0)
			// 				{
			// 					// console.log("uncheckls.indexOf(keys) ", unckitems.indexOf(keys))
			// 					if(unckitems.indexOf(kys) >= 0)
			// 					{
									
			// 					}
			// 					else{
										
			// 						if(values.length > 0){
			// 							for (var l = 0; l < values.length; l++) {
			// 								if(values[l].location_label.toString() !== undefined)
			// 								{
			// 									var lblText = values[l].location_label.toString();
			// 								}
			// 								else{
			// 									var lblText = "";
			// 								}
			// 								bounds.extend(new window.google.maps.LatLng(parseFloat(values[l].latitude), parseFloat(values[l].longitude)));
			// 								var icon = {

			// 								path: "M0-10c-9.8 0-17.7 7.8-17.7 8.4 0 7.5 8.7 15.6 8.7 15.6s17.7-15.4 17.7-30.6c0-9.6-7.9-17.4-17.7-17.4z",
			// 								fillColor: colors[sna],
			// 								fillOpacity: 1,
			// 								anchor: new window.google.maps.Point(0,0),
			// 								strokeWeight: 0,
			// 								scale: 0.8
			// 							}										  
			// 								var marker = new window.google.maps.Marker({
			// 									position: new window.google.maps.LatLng(parseFloat(values[l].latitude), parseFloat(values[l].longitude)),
			// 									// icon:require('../../assets/images/business.png'),
			// 									content:values[l],
			// 									icon:icon,
			// 									title:values[l].location_label,
			// 									map: map,
			// 								});
			// 								addMarker(new window.google.maps.LatLng(parseFloat(values[l].latitude), parseFloat(values[l].longitude)),lblText,values[l], colors[sna]);
											
										
			// 							}
			// 						}
			// 					}
			// 				}
			// 				else{
			// 					if(values.length > 0){
			// 						for (var l = 0; l < values.length; l++) {
			// 							if(values[l].location_label.toString() !== undefined)
			// 							{
			// 								var lblText = values[l].location_label.toString();
			// 							}
			// 							else{
			// 								var lblText = "";
			// 							}
			// 							bounds.extend(new window.google.maps.LatLng(parseFloat(values[l].latitude), parseFloat(values[l].longitude)));
			// 							var icon = {

			// 								path: "M0-20c-9.8 0-17.7 7.8-17.7 17.4 0 15.5 17.7 30.6 17.7 30.6s17.7-15.4 17.7-30.6c0-9.6-7.9-17.4-17.7-17.4z",
			// 								fillColor: colors[sna],
			// 								fillOpacity: 1,
			// 								anchor: new window.google.maps.Point(0,0),
			// 								strokeWeight: 0,
			// 								scale: 0.8
			// 							}
			// 							var marker = new window.google.maps.Marker({
			// 								position: new window.google.maps.LatLng(parseFloat(values[l].latitude), parseFloat(values[l].longitude)),
			// 								icon:icon,
			// 								content:values[l],
			// 								title:values[l].location_label.toString(),
			// 								map: map,
			// 							});
			// 							addMarker(new window.google.maps.LatLng(parseFloat(values[l].latitude), parseFloat(values[l].longitude)),lblText,values[l], colors[sna]);
										
									
			// 						}
			// 					}
			// 				}
			// 				sna = sna+1;
			// 			})
			// 		}
			// 		catch(e){

			// 		}
			// 	}
			// 	// for (var l = 0; l < trip_nodes_data.length; l++) {
			// 	// 	bounds.extend(new window.google.maps.LatLng(parseFloat(trip_nodes_data[l].latitude), parseFloat(trip_nodes_data[l].longitude)));
			// 	// 	var marker = new window.google.maps.Marker({
			// 	// 		position: new window.google.maps.LatLng(parseFloat(trip_nodes_data[l].latitude), parseFloat(trip_nodes_data[l].longitude)),
			// 	// 		// icon:require('../../assets/images/business.png'),
			// 	// 		content:trip_nodes_data[l],
			// 	// 		title:trip_nodes_data[l].location_label,
			// 	// 		map: map,
			// 	// 	});
			// 	// 	addMarker(new window.google.maps.LatLng(parseFloat(trip_nodes_data[l].latitude), parseFloat(trip_nodes_data[l].longitude)),trip_nodes_data[l].location_label,trip_nodes_data[l]);
					
				
			// 	// }

			// }
			// function addMarkerPlant(location,lbl,item) {
			// 	var marker = new window.google.maps.Marker({
			// 		// color:"#ffffff",
			// 		// fontSize:"20px",
			// 		position: location,
			// 		label:lbl,
			// 		icon: require('../../assets/images/business.png'),
			// 		map: map
			// 	});
				
			// 	markers.push(marker);
			// 	var infowindow = new window.google.maps.InfoWindow({
			// 		maxWidth: 350
			// 	});

			// 	marker.addListener('click', function() {
			// 		infowindow.open(map, marker);
			// 	});
			// 	//oms.addMarker(marker);
			// }	
			// function addMarker(location,lbl,item,color) {
			// 	var icon = {

			// 								path: "M0-20c-9.8 0-17.7 7.8-17.7 17.4 0 15.5 17.7 30.6 17.7 30.6s17.7-15.4 17.7-30.6c0-9.6-7.9-17.4-17.7-17.4z",
			// 								fillColor: color,
			// 								fillOpacity: 1,
			// 								anchor: new window.google.maps.Point(0,0),
			// 								strokeWeight: 0,
			// 								scale: 0.8
			// 							}
			// 	var marker = new window.google.maps.Marker({
			// 		// color:"#ffffff",
			// 		// fontSize:"20px",
			// 		position: location,
			// 		label:{text:lbl,color:"#ffffff",fontSize:"15px"},
			// 		icon: icon,
			// 		map: map
			// 	});
				
			// 	markers.push(marker);
			// 	var infowindow = new window.google.maps.InfoWindow({
			// 		maxWidth: 350
			// 	});

			// 	marker.addListener('click', function() {
			// 		var contentarr = []
			// 		var header = item.location_code
			// 		contentarr.push({"key":"Location", "value":item.location_name})
			// 		if(item.distance_from_pickup !=undefined && item.distance_from_pickup !="undefined")
			// 		{
			// 			contentarr.push({"key":"Distance / Time From Pickup Location", "value":item.distance_from_pickup+" Kms / "+item.time_from_pickup+" Mins"})
			// 			contentarr.push({"key":"Distance / Time From Previous Drop Point", "value":item.distance_from_prev_drop+" Kms/"+item.time_from_prev_drop+" Mins"})
			// 		}
					
			// 		var contentString = infoBox("", header, contentarr,'')
						
			// 		infowindow.setContent(contentString);
			// 		currentwindow = infowindow;
			// 		infowindow.open(map, marker);
			// 	});

			// }	

			try {
				// palletLoadLocations
				if(palletLoadLocations.length > 0)
				{
					for (var l = 0; l < palletLoadLocations.length; l++) {
						// console.log(palletLoadLocations[l].value)
						if(palletLoadLocations[l].name === 'plant' && this.props.map_for==='vehicleLocation'){
							bounds.extend(new window.google.maps.LatLng(parseFloat(palletLoadLocations[l].latitude), parseFloat(palletLoadLocations[l].longitude)));
							var marker = new window.google.maps.Marker({
								position: new window.google.maps.LatLng(parseFloat(palletLoadLocations[l].latitude), parseFloat(palletLoadLocations[l].longitude)),
								content:palletLoadLocations[l],
								title:palletLoadLocations[l].location_label,
								icon:require('../../assets/images/business.png'),
								map: map,
							});
	
							window.google.maps.event.addListener(marker, 'click', (function(marker) {
								return function() {
								var contentarr = []
								//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
								var header = 'Details'
								contentarr.push({"key":"Location", "value":marker.content.plant_name})
								// contentarr.push({"key":"Quantity", "value":marker.content.quantity})
								// contentarr.push({"key":"Weight", "value":marker.content.demand_weight})
								// contentarr.push({"key":"Vehicle", "value":marker.content.vehicle_no})
								// contentarr.push({"key":"Demand (lbs)", "value":(marker.content.demand !== "" && marker.content.demand !== undefined)?marker.content.demand:0})
								
								var contentString = infoBox(marker.icon, header, contentarr,'')
									
								infowindow.setContent(contentString);
								currentwindow = infowindow;
								infowindow.open(map, marker);
								// console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
								}
							})(marker));

						}else if (this.props.map_for==='vehicleLocation'){
						bounds.extend(new window.google.maps.LatLng(parseFloat(palletLoadLocations[l].last_packet_latitude), parseFloat(palletLoadLocations[l].last_packet_longitude)));
						var marker = new window.google.maps.Marker({
							position: new window.google.maps.LatLng(parseFloat(palletLoadLocations[l].last_packet_latitude), parseFloat(palletLoadLocations[l].last_packet_longitude)),
							content:palletLoadLocations[l],
							title:palletLoadLocations[l].location_label,
							icon:require('../../assets/images/red-truck.png'),
							map: map,
						});
						
						// console.log(palletLoadLocations[l],'lllg')
						window.google.maps.event.addListener(marker, 'click', (function(marker) {
							return function() {
							var contentarr = []
							//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
							// var header = marker.content.demand_node_location
							var header='Details'
							contentarr.push({"key":"Veh No", "value":marker.content.vehicle_no})
							// contentarr.push({"key":"Quantity", "value":marker.content.quantity})
							// contentarr.push({"key":"Weight", "value":marker.content.demand_weight})
							// contentarr.push({"key":"Vehicle", "value":marker.content.vehicle_no})
							// contentarr.push({"key":"Demand (lbs)", "value":(marker.content.demand !== "" && marker.content.demand !== undefined)?marker.content.demand:0})
							
							var contentString = infoBox(marker.icon, header, contentarr,'')
								
							infowindow.setContent(contentString);
							currentwindow = infowindow;
							infowindow.open(map, marker);
							// console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
							}
						})(marker));
						}else{
							bounds.extend(new window.google.maps.LatLng(parseFloat(palletLoadLocations[l].demand_node_latitude), parseFloat(palletLoadLocations[l].demand_node_longitude)));
							var marker = new window.google.maps.Marker({
								position: new window.google.maps.LatLng(parseFloat(palletLoadLocations[l].demand_node_latitude), parseFloat(palletLoadLocations[l].demand_node_longitude)),
								content:palletLoadLocations[l],
								title:palletLoadLocations[l].location_label,
								icon:require('../../assets/images/pin.png'),
								map: map,
							});
							
	
							window.google.maps.event.addListener(marker, 'click', (function(marker) {
								return function() {
								var contentarr = []
								//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
								var header = marker.content.demand_node_location
								contentarr.push({"key":"Location", "value":marker.content.demand_node_location})
								contentarr.push({"key":"Quantity", "value":marker.content.quantity})
								contentarr.push({"key":"Weight", "value":marker.content.demand_weight})
								// contentarr.push({"key":"Vehicle", "value":marker.content.vehicle_no})
								// contentarr.push({"key":"Demand (lbs)", "value":(marker.content.demand !== "" && marker.content.demand !== undefined)?marker.content.demand:0})
								
								var contentString = infoBox(marker.icon, header, contentarr,'')
									
								infowindow.setContent(contentString);
								currentwindow = infowindow;
								infowindow.open(map, marker);
								// console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
								}
							})(marker));
						}
					}

				}
			} catch (error) {
				
			}

			map.fitBounds(bounds)
		}
		catch(e){
			console.log("Error ",e)
		}
	}


	onOpenModal = () => {
		this.setState({ open: true });
	 };
	
	 onCloseModal = () => {
	   this.setState({ open: false });
	 };

render () {
 const modalStyles  = {
              width:'500px !important',
      		}
	  const { open } = this.state;

	var options = this.state.uncheckls;
	// console.log(this.props,'props pallet')
    return (
           
			<div className="col-xl-12 col-md-12">
				{/* {console.log("this.steae.viewcount ", this.state.viewcount)} */}
				{(this.state.viewcount === 0)?
					(options.map(option => (
						<label style={{margin:"10px","backgroundColor":(option.colorCode),padding:"5px 10px",color:"#FFFFFF"}} key={option.id}>
							<input
							type="checkbox"
							value={option.id}
							checked={option.isChecked}
							onChange={this.handleCheckboxChange}
							/>
							&nbsp;{option.label}
						</label>
						)))
				:""}
				
				
				<div id="mapPallet" className="loadSummaryMap" style={{width:'100%',height:"85vh"}}></div>
	
			</div>
        )
    }
}

function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function timeConvert(n) {
	var num = n;
	var hours = (num / (3600));
	var rhours = parseInt(hours);
	var minutes = (num-(rhours*3600)) / (60);
	var rminutes = Math.round(minutes);
	return rhours + " hour(s) and " + rminutes + " minute(s).";
}   

function intervals(startString, endString) {
    var start = moment(startString, 'DD-MM-YYYY HH:mm:ss');
    var end = moment(endString, 'DD-MM-YYYY HH:mm:ss');

    start.minutes(Math.ceil(start.minutes() / 15) * 15);

    var result = [];

    var current = moment(start);

    while (current <= end) {
        result.push(current.format('DD-MM-YYYY HH:mm'));
        current.add(15, 'minutes');
    }

    return result;
}


function secondsToString(seconds)
{
	var numdays = Math.floor(seconds / 86400);
	var numhours = Math.floor((seconds % 86400) / 3600);
	var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
	var numseconds = ((seconds % 86400) % 3600) % 60;
	return numdays + " days " + numhours + " hours " + numminutes + " minutes ";
}


function getPolygonCoords()
{
	
	 var len = geoFencingArea.getPath().getLength();
	 console.log(len);
	 var htmlStr = "";
	 coordinates=[];
	 for (var i = 0; i < len; i++) {
		// var latlngd = {"lat":parseFloat(each_lat_lng[0]),"lng":parseFloat(each_lat_lng[1])};
		// coordinates.push()
		 var strlatlng = geoFencingArea.getPath().getAt(i).toUrlValue(5);
		 var spliting = strlatlng.split(",");
		 var latlngd = {"lat":parseFloat(spliting[0]),"lng":parseFloat(spliting[1])};
		 coordinates.push(latlngd);
	  // htmlStr += "new google.maps.LatLng(" + geoFencingArea.getPath().getAt(i).toUrlValue(5) + "), ";
	   //Use this one instead if you want to get rid of the wrap > new google.maps.LatLng(),
	   //htmlStr += "" + geoFencingArea.getPath().getAt(i).toUrlValue(5);
	 }
	// console.log("htmlStr", coordinates);
	 
}
function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}
function GetSortAscOrder(prop) {    
    return function(a, b) {    
        if (a[prop] > b[prop]) {    
            return 1;    
        } else if (a[prop] < b[prop]) {    
            return -1;    
        }    
        return 0;    
    }    
} 
function generateRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}
export default DrawMapDisptachPallet;