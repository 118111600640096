import React, { Component } from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import drilldown from 'highcharts-drilldown';
import { withTranslation } from 'react-i18next';

drilldown(Highcharts);
 

(function (H) {
    const animateSVGPath = (svgElem, animation, callback = void 0) => {
        if (svgElem && svgElem.element) {
        const length = svgElem.element.getTotalLength();
        svgElem.attr({
            'stroke-dasharray': length,
            'stroke-dashoffset': length,
            opacity: 1
        });
        svgElem.animate({
            'stroke-dashoffset': 0
        }, animation, callback);
    } else {
        // console.log('svgElem or svgElem.element is undefined');
    }
    };

    H.seriesTypes.line.prototype.animate = function (init) {
        const series = this,
            animation = H.animObject(series.options.animation);
        if (!init) {
            animateSVGPath(series.graph, animation);
        }
    };

    H.addEvent(H.Axis, 'afterRender', function () {
        const axis = this,
            chart = axis.chart,
            animation = H.animObject(chart.renderer.globalAnimation);
        if (axis.axisGroup) {
        axis.axisGroup
            .attr({
                opacity: 0,
                rotation: -3,
                scaleY: 0.9
            })
            .animate({
                opacity: 1,
                rotation: 0,
                scaleY: 1
            }, animation);
        }
        if (axis.labelGroup) {
        if (axis.horiz) {
            axis.labelGroup
                .attr({
                    opacity: 0,
                    rotation: 3,
                    scaleY: 0.5
                })
                .animate({
                    opacity: 1,
                    rotation: 0,
                    scaleY: 1
                }, animation);
        } else {
            axis.labelGroup
                .attr({
                    opacity: 0,
                    rotation: 3,
                    scaleX: -0.5
                })
                .animate({
                    opacity: 1,
                    rotation: 0,
                    scaleX: 1
                }, animation);
        }}

        if (axis.plotLinesAndBands) {
            axis.plotLinesAndBands.forEach(plotLine => {
                if (plotLine.svgElem) {
                const animation = H.animObject(plotLine.options.animation);
                if (plotLine.label) {
                plotLine.label.attr({ opacity: 0 });
                }
                animateSVGPath(plotLine.svgElem, animation, () => {
                    if (plotLine.label && plotLine.label.animate) {
                    plotLine.label.animate({ opacity: 1 });
                    }
                });
            
            }
            });
        }
    });
})(Highcharts);

 class MultiAxisChart extends Component {
    constructor(props) {
        super(props);
       
    }
    
    

    render() {
        const {t} = this.props
        const dStyles = {
            width: '100%',
            height: '100%',
        };
// console.log(this.props)
        let originalloadDataArr = this.props.originalloadDataArr
        let originalin416Arr = this.props.originalin416Arr
        let originalin436Arr = this.props.originalin436Arr
        let originalplanningPercentArr = this.props.originalplanningPercentArr
        let maxCap = this.props.maxCap
        const highchartoptions = {
            chart: {
                type: this.props.chartType || 'spline',
            },
            title: {
                text: this.props.title || "Multi-Axis Example",
            },
            // subtitle: {
            //     text: 'Source: World Bank',
            // },
            tooltip: {
                shared: true,
                formatter: function () {
                    let tooltipText = `<b>${t('month')}: ${this.x}</b><br/>`;
        
                    // Loop through each point in the shared tooltip
                    this.points.forEach(point => {
                        let originalValue;
                        
                        // Find the original value for each series
                        if (point.series.index === 0) {
                            originalValue = originalloadDataArr[point.point.index];
                        } else if (point.series.index === 1) {
                            originalValue = originalin416Arr[point.point.index];
                        } else if (point.series.index === 2) {
                            originalValue = originalin436Arr[point.point.index];
                        } else if (point.series.index === 3) {
                            originalValue = originalplanningPercentArr[point.point.index];
                        } else if (point.series.index === 4) {
                            originalValue = maxCap[point.point.index];
                        }
        
                        // Add each series' name and its original value
                        tooltipText += `<span style="color:${point.series.color}">\u25CF</span> 
                                        ${point.series.name}: ${originalValue}</b><br/>`;
                    });
        
                    return tooltipText;
                }
            },
            xAxis: {
                categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June','July','Aug','Sep','Oct','Nov','Dec'],
                title: {
                    text: 'Month'
                }
            },
            yAxis: [
                {
                    // type: 'logarithmic',
                    title: {
                        text: '%',
                    },
                    // plotLines: [
                    //     {
                    //         color: 'black',
                    //         width: 2,
                    //         value: 10, // Example value
                    //         animation: {
                    //             duration: 1000,
                    //             defer: 4000,
                    //         },
                    //         label: {
                    //             text: 'Max Inflation',
                    //             align: 'right',
                    //             x: -20,
                    //         },
                    //     },
                    // ],
                },
                // {
                //     title: {
                //         text: 'Vehicles',
                //     },
                // },
                // {
                //     opposite: true,
                //     title: {
                //         text: 'IN436',
                //     },
                // },
                // {
                //     opposite: true,
                //     title: {
                //         text: 'Planning (%)',
                //     },
                // },
            ],
            series: [
                {
                    // yAxis: 0,
                    name: t('load'),
                    data: this.props.normalloadDataArr || [],
                    // data:[2.5, 3.0, 2.8, 3.2, 2.9, 3.1],
                    animation: { defer: 500 },
                },
                {
                    // yAxis: 1,
                    name: 'IN416',
                    data: this.props.normalin416Arr || [],
                    // data:[ 80, 65, 85, 90, 50],
                    animation: { defer: 1000 },
                },
                {
                    // yAxis: 1,
                    name: 'IN436',
                    data: this.props.normalin436Arr || [],
                    // data:[7,7,7,7,7,7,7,7],
                    animation: { defer: 1500 },
                },
                {
                    // yAxis: 2,
                    name: t('serviceability'),
                    data: this.props.normalplanningPercentArr || [],
                    // data:[1200, 1220, 1235, 1245, 1250, 1260],
                    animation: { defer: 2000 },
                },
                {
                    type: 'spline',
                    name: t('bar'),
                    data: this.props.maxCap || [],
                    // data:[70,70,70,70,70,70,70,70,],
                    animation: { defer: 2500 },
                    color:'#0075ff',
                    lineWidth: 4,
                },
            ],
            plotOptions: {
                series: {
                    animation: {
                        duration: 1000,
                    },
                    marker: {
                        enabled: false,
                    },
                    lineWidth: 2,
                },
            },
            responsive: {
                rules: [
                    {
                        condition: {
                            maxWidth: 500,
                        },
                        chartOptions: {
                            yAxis: [
                                {
                                    tickAmount: 2,
                                    title: {
                                        x: 15,
                                        reserveSpace: false,
                                    },
                                },
                                {
                                    tickAmount: 2,
                                    title: {
                                        x: 20,
                                        reserveSpace: false,
                                    },
                                },
                                {
                                    tickAmount: 2,
                                    title: {
                                        x: -20,
                                        reserveSpace: false,
                                    },
                                },
                                {
                                    tickAmount: 2,
                                    title: {
                                        x: -20,
                                        reserveSpace: false,
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
            credits: false,
        };

        return (
            <HighchartsReact
                containerProps={{ style: { width: '100%', height: '100%' } }}
                highcharts={Highcharts}
                options={highchartoptions}
                style={dStyles}
            />
        );
    }
}

export default withTranslation()(MultiAxisChart)
