import React,{Component, createRef } from "react";
import {AllModules} from '@ag-grid-enterprise/all-modules';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
import 'ag-grid-enterprise';
import Constant from "../common/constants";
import 'react-responsive-modal/styles.css';
import redirectURL from '../redirectURL';
import Select from 'react-select';
import GridButton from "../optirun-admin/gridButton";
import {
  getHyphenDDMMMYYYYHHMM,
  getHyphenYYYYMMDDHHMMSS,
} from "../common/utils";
import SweetAlert from 'react-bootstrap-sweetalert';
import TimeRangePicker from '@wojtekmaj/react-timerange-picker';
import NAButton from "../optirun-admin/naButton";
import { withTranslation } from "react-i18next";
// import getToolConfiguration from "./commonFunctions";
// import { logFormChanges } from "./auditLogfunction";
var moment = require('moment');
 class VechicleCheckList extends Component{
    constructor(props){
        var sdate = moment.parseZone().subtract(1, 'months').format('YYYY-MM-DD');
		var edate = moment.parseZone().format('YYYY-MM-DD');
        super(props);
        this.sliderRef = createRef();
        this.state={
            modules : AllModules,
            securityRequirementDetails: [],
            qualityRequirementDetails: [],
            showRequirementSlider: "sidebarcls",
            vehiclesAvailForQuality:true,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
                resizable:true,
                minWidth:100,
                initialWidth: 200,
                wrapHeaderText: true,
                autoHeaderHeight: true,
            },
            frameworkComponents:{
                GridButton:GridButton,
                NAButton: NAButton
            },
            rowData:[],
            locationsHasChecklistRecords: [],
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
			detailCellRendererParams:{},
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:100,
            startDate: moment.parseZone().subtract(1, 'months').format('YYYY-MM-DDTHH:mm:ss'),
			endDate: moment.parseZone().format('YYYY-MM-DDTHH:mm:ss'),
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            animateRows: true,
            openVehicleConfigurationModel:false,
            toggleAddVehicleCheckListBtn: true,
            vehicle_type:'',
            vehicle_name:'',
            vehicle_priority:"Owned",
            min_volume_cmt:0,
            max_volume_cmt:0,
            max_volume_m3:0,
            min_volume_m3:0,
            maximum_unloading_time:'',
            unloading_time_per_case:'',
            vehicle_dimensions_width_mm:'',
            vehicle_dimensions_depth_mm:'',
            vehicle_dimensions_height_mm:'',
            vehicle_min_weight:'',
            vehicle_max_weight:'',
            max_no_of_nodes:'',
            setAddVehilceTypeDisable: false,
            variable_vehicle_cost_expandable:[],
            vechicleCheckListRecords: [],
            show:false,
            basicTitle:"",
            basicType:"default",
            showAvailablityTable:false,
            data_type:'',
            location:'',
            max_distance:'',
            vehicle_availability_time:['00:00','23:59'],
            break_time:['00:00','23:59'],
            locationNames:[],
            locationList: [],
            min_load_per_delivery:'',
            // vehicle_capacity:'',
            vehicle_id:'',
            fuelType:'Petrol',
            average_payload:'',
            mileage:'',
            temperature_controlled:"Yes",
            load_characteristics:"Light",
            glec_vehicle_type:"0.0 - 3.5 t GVW",
            vehicle_type_count:0,
            openVehiclePriorityConfigurationModel:false,
            vehicle_priority_order_owned:1,
            vehicle_priority_order_contract:2,
            vehicle_priority_order_market:3,
            monthly_min_planning_distance:"",
            clickonEdit:false,
            c: '',
            vehicleTypesList:[],
            form_type:""
        }
        
		this.onChangeStartDate = this.onChangeStartDate.bind(this)
		this.onChangeEndDate = this.onChangeEndDate.bind(this)
    }

   async componentDidMount(){
        var curl = window.location.search;
        var ul= curl.split("?");
        // console.log("ul ",ul)
        // let url_type = window.location.href
        let url_type = ul[parseInt(ul.length)-1];
        // console.log("url_type ", url_type)
        // let path_type = url_type.split('/')
        let path_type = url_type
        let data_type
        // let path = path_type[path_type.length-1]
        let path =url_type

        if (path_type == 'inbound'){
            data_type = 1
            this.setState({data_type : data_type})
        }else{
            data_type = 2
            this.setState({data_type : data_type})
        }
        let params = {
            data_type : data_type
        }
        this.setState({
            setAddVehilceTypeDisable: true
        })
        await this.enableCheckListForLocation()
        await this.getLocationWithVehicleChecklistEnabled()
        await this.boundTypeLocations(params)

        let location = {location_code:"LK1SLK02"}
        await this.isAvailVehicleCheckListForLocation(location);
        await this.onGetVehicleCheckListFromHistory()
        let vehiclesAvailable= {
            location_code: location.location_code,
            data_type: localStorage.getItem('data_type')
        }
        let role = localStorage.getItem("user_type")
        if(role === "quality"){
            this.fetchVehiclesForQualityCheck(vehiclesAvailable)
        }
        if (localStorage.getItem("qualityAssignQry") !== null) {
            // Remove the item from localStorage
            localStorage.removeItem("qualityAssignQry");
        }
    }


    closeAlert = async() => {
        await this.setState({
            show: false
        });
	}
    onChangeStartDate(event) {
		var pre_sdate=this.state.startDate
        let d = new Date(event.target.value);
		this.setState({
			pre_sdate,
			startDate: getHyphenYYYYMMDDHHMMSS(d)
		})
	}
	onChangeEndDate(event) {
		var pre_edate=this.state.endDate
        let d = new Date(event.target.value);
		this.setState({
			pre_edate,
			endDate: getHyphenYYYYMMDDHHMMSS(d)
		})
	}
    handleAvailabilityTime = (val) => {
    	this.setState({vehicle_availability_time:val});
    }
    fetchVehiclesForQualityCheck = async (arg) => {
        await redirectURL.post("master/fetchVehiclesForQualityCheck", arg)
        .then((resp)=>{
            if(resp.data.status === "success"){
                let records = resp.data.records;
                let vehicleOptions = []
                let duplicateOptions = []
                let vehicleSet = new Set();
                let x = records.forEach((e)=>{
                    if(vehicleSet.has(e)){
                        duplicateOptions.push(e)
                    }else{
                        vehicleOptions.push({value: e, label: e})
                        vehicleSet.add(e);
                    }
                })
                if(vehicleOptions.length === 0){
                    this.setState({
                        vehiclesAvailForQuality: false
                    })
                }
                
                
            }
        })
        .catch(function (error) {
            var errDetails={
                url:"master/fetchVehiclesForQualityCheck",
                screen:'Requirements',
              error,
              }
            redirectURL.post("/master/logErrorsForApiCalls",errDetails)
        })
    }

    handleBreakTime = (val) => {
    	this.setState({break_time:val});
    }

    onChangeVehicleType = (e) => {
        let name = e.target.name
        let value = e.target.value
        var regex=/^[a-zA-Z]?[a-zA-Z0-9_]*$/
        var out=regex.test(value)
        if(name == "vehicle_type"){
            value = value.toUpperCase()
        }
        if(value !==''){
        if(out){
        this.setState({ [name] : value })
        }
        }else{
            this.setState({ [name] : value })
        }
    }

    onChangeVehicleName = (e) => {
        let name = e.target.name
        let value = e.target.value
        var regex = /^[a-zA-Z0-9_ ]*$/
        // var regex = /^[a-zA-Z]?[a-zA-Z" "]*$/
        var out = regex.test(value)
        if (out){
            let modifiedValue = value.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
            this.setState({ [name] : modifiedValue})
        }
     }

     checkNotEqualstoZero = (e) => {
        let name = e.target.name 
        let value = e.target.value 
        var regex =/^(?!0)\d*(\.\d+)?$/
        var out = regex.test(value)
        if (out) {
            this.setState({[name] : value})
        }
     }

     onChangeMinVolumeAndWeight = (e) => {
        let name = e.target.name
        let value = e.target.value
        var regex = /^(?:[0-9]+)?(?:\.[0-9]+)?$/
        var out = regex.test(value)
        if (out) {
            this.setState({[name] : value})
        }
     }

  

    locationChanged =async (location) =>{
        this.setState({
            location : location
        })
    }
    getLocationWithVehicleChecklistEnabled = async () => {
        let params = {
            data_type: localStorage.getItem('data_type')
        }
        await redirectURL.post("master/getPlantwiseToolConfig", params)
            .then((resp)=>{
                let getPlantCode = resp.data.filter((e)=>{
                    if((e.hasOwnProperty === "vehicle_checklist") && e.vehicle_checklist === 1 ){
                        return e.plant_code
                    }
                })
                this.setState({
                    plantCodeFrom_PW: getPlantCode
                })
            })
            .catch(function (error) {
                var errDetails={
                    url:"master/getPlantwiseToolConfig",
                    screen:'Requirements',
                  error,
                  }
                redirectURL.post("/master/logErrorsForApiCalls",errDetails)
            })
    }
    boundTypeLocations = async(param) => {

      let location_access = JSON.parse(localStorage.getItem("location_access"))
      let locationList = [];

        
        await redirectURL.post('master/getBoundTypeLocations',param)
        .then(async (response) =>{
            if(response.data.status == 'success'){
                let boundLocations = response.data.boundLocations
                if(boundLocations.length > 0){
                    boundLocations.map(item => {
                        if(location_access != null){
                            for (const code of location_access ){
                                if(code === item.location_code){
                                    locationList.push({ value : item.location_code, label : item.location_name })
                                }
                            }
                        } else {
                            locationList.push({ value : item.location_code, label : item.location_name })
                        }
                    })
                    if(locationList.length>0){
                        await this.setState({ locationList : locationList }, ()=>{
                            if(this.state.locationsHasChecklistRecords.length > 0){
                                let z = this.state.locationsHasChecklistRecords.map(item => {
                                        const match = locationList.find((e)=> e.value === item.plant_code)
                                        return match? {value: item.plant_code, label: match.label}: null;
                                })
                                this.setState({
                                    locationNames: z,
                                    location:z[0]
                                })
                            }
                        })
                    }
                }
            }
        })
        .catch(function (error) {
            var errDetails={
                url:"master/getBoundTypeLocations",
                screen:'Vehicle Forecast Configurations',
              error,
              }
            redirectURL.post("/master/logErrorsForApiCalls",errDetails)
            
        })
        
    }
    
    enableCheckListForLocation = async(param) =>{
        await redirectURL.post("master/checkLocationForCheckListEnable")
        .then(async(resp) => {
            if(resp.data.status === "success"){
                this.setState({
                    locationsHasChecklistRecords: resp.data.records,
                },()=>{
                    // console.log(this.state.locationsHasChecklistRecords,"checklist")
                })
            }
        })
        .catch(function (error) {
            var errDetails={
                url:"master/checkLocationForCheckListEnable",
                screen:'Vehicle Checklist',
              error,
              }
            redirectURL.post("/master/logErrorsForApiCalls",errDetails)
            
        })
    }
    isAvailVehicleCheckListForLocation = async(param) => {
        await redirectURL.post('master/getVehicleCheckListDetails',param)
        .then(async (response) =>{
            if(response.data.status == 'success'){
                this.setState({
                    toggleAddVehicleCheckListBtn: false
                })
            } else if(response.data.status === "failure"){
                this.setState({
                    toggleAddVehicleCheckListBtn: true
                })
            }
        })
        .catch(function (error) {
            var errDetails={
                url:"master/getVehicleCheckListDetails",
                screen:'Vehicle Checklist',
              error,
              }
            redirectURL.post("/master/logErrorsForApiCalls",errDetails)
            
        })
    }

   

    



    





    

    
    redirectToaddRequirement = (e) => {
        let logParams = {
            // "location_code" : this.state.location.value,
            // "location_name" : this.state.location.label,
            "user_name" : localStorage.getItem("username"),
            "useremail" : localStorage.getItem("email"),
            "client" : localStorage.getItem("client"),
            "role" : localStorage.getItem("role"),
            "screen" : "Vehicle Checklist Page",
            "activity" : 'clicked on Requirements',
            "event" : "Setting Requruirements To Location",
            // "data_type" : this.state.data_type,
        }
    
        redirectURL.post("/master/loguserUpdatesorChanges",logParams)
        e.preventDefault();
        let qry = {
            dispatch_planning_type : this.state.data_type,
            plant_location_code : this.state.location.value,
            location_city: this.state.location.label,
            stage: 0
        }
        // console.log(qry, "at stage 1");
        localStorage.setItem('assignqry',JSON.stringify(qry))
        const newUrl = `${window.location.origin}/requirements`;
        window.location.href = newUrl;
        
    }
    redirectToAddVehicleChecklist = (e) => {
        if(!this.state.toggleAddVehicleCheckListBtn){
            let logParams = {
                // "location_code" : this.state.location.value,
                // "location_name" : this.state.location.label,
                "user_name" : localStorage.getItem("username"),
                "useremail" : localStorage.getItem("email"),
                "client" : localStorage.getItem("client"),
                "role" : localStorage.getItem("role"),
                "screen" : "Vehicle Checklist Page",
                "activity" : 'clicked on Add Vehicle Checklist',
                "event" : "Redirectling to Add Checklists",
                // "data_type" : this.state.data_type,
            }      
            redirectURL.post("/master/loguserUpdatesorChanges",logParams)
            e.preventDefault();
            let role = localStorage.getItem("user_type")
            if(role === "security" || role==="admin" || role==="user" || role === "USER"){
                let qry = {
                    dispatch_planning_type : this.state.data_type,
                    plant_location_code : this.state.location.value,
                    location_city: this.state.location.label,
                    stage: 0
                }
                // console.log(qry, "at stage 1");
                localStorage.setItem('assignqry',JSON.stringify(qry))
            }
            let newUrl = `${window.location.origin}/assign_requirement_to_vehicle`;
            window.location.href = newUrl;

        }
    }
    onGetVehicleCheckListFromHistory = async (e) => {
        let logParams = {
            // "location_code" : this.state.location.value,
            // "location_name" : this.state.location.label,
            "user_name" : localStorage.getItem("username"),
            "useremail" : localStorage.getItem("email"),
            "client" : localStorage.getItem("client"),
            "role" : localStorage.getItem("role"),
            "screen" : "Vehicle Checklist Page",
            "activity" : 'clicked on Get Data',
            "event" : "Fetching Checklists",
            // "data_type" : this.state.data_type,
        }

        var {startDate,endDate}=this.state
        var check=startDate < endDate
        if(!check) {
            this.setState({
                show:true,
                basicTitle:'Start Date Should be Less than End Date',
                basicType:"danger",
                loadshow:'show-n'
            })
        } else {
            redirectURL.post("/master/loguserUpdatesorChanges",logParams)
            this.setState({
                overly: 'show-m',
                loadshow: "show-m"
            })
            // e.preventDefault()
            let params = {
                location_code: this.state.location.value,
                assessmentStartDate: this.state.startDate,
                assessmentEndDate: this.state.endDate
            }
            // console.log(params,"params")
            await redirectURL.post("master/fetchVehicleCheckListFromHistoryPeriod", params)
            .then(async(resp) => {
                if(resp.data.status === "success"){
                    console.log(resp.data.records, "gfgfgfgfgfgfgfgfg")
                    if(localStorage.getItem("user_type")==="quality"){
                        let filteredQualityRecords = resp.data.records.filter((e)=> {
                            if(e.stage === 1 || e.stage === 2){
                                if(e.approved !== "No" || e.quality_approved){
                                    return true
                                }
                            }
                        })
                        console.log(filteredQualityRecords,"filtered")
                        this.setState({
                            vechicleCheckListRecords: filteredQualityRecords,
                            overly: 'show-n',
                            loadshow: "show-n",
                        })
                    }else{
                        this.setState({
                            vechicleCheckListRecords: resp.data.records,
                            overly: 'show-n',
                            loadshow: "show-n",
                        })
                    }
                }
            })
            .catch(function (error) {
                var errDetails={
                    url:"master/onGetVehicleCheckListFromHistory",
                    screen:'Vehicle Checklist',
                  error,
                  }
                redirectURL.post("/master/logErrorsForApiCalls",errDetails)
                
            })
        }
    }
    hideSlideBlock = () => {
        this.setState({
          showSlideBlockUpload: "",
          overly: "show-n",
          showRequirementSlider: ''
        });
    
        // let logParams = {
        //   location_code: this.state.location.value,
        //   location_name: this.state.location.label,
        //   user_name: localStorage.getItem("username"),
        //   useremail: localStorage.getItem("email"),
        //   client: localStorage.getItem("client"),
        //   screen: "Consolidated Run Plan ",
        //   activity: "Clicked on Cancel Button",
        //   event: "Closed Upload File Slider",
        //   data_type: this.state.data_type,
        // };
    
        // redirectURL.post("/master/loguserUpdatesorChanges", logParams);
      };
    viewOrderDetails = async (params) => {  
        let logParams = {
            // "location_code" : this.state.location.value,
            // "location_name" : this.state.location.label,
            "user_name" : localStorage.getItem("username"),
            "useremail" : localStorage.getItem("email"),
            "client" : localStorage.getItem("client"),
            "role" : localStorage.getItem("role"),
            "screen" : "Vehicle Checklist Page",
            "activity" : 'Opening Slider to View Requirements',
            "event" : `Opening Slider to View ${params.data.assessedBy} Requirements`,
            // "data_type" : this.state.data_type,
        }
    
        redirectURL.post("/master/loguserUpdatesorChanges",logParams)  
        // console.log(params,"paramssss")
        let requirements = []
        let x = params.data.securityCheckList;
        let y = params.data.qualityCheckList;
        this.setState({
            securityRequirementDetails: x,
            qualityRequirementDetails: y,
            showRequirementSlider: "slide45",
            overly: "show-m"
        },()=>{
            if (this.sliderRef.current) {
                this.sliderRef.current.scrollTop = 0;
            }
        })    
    };
    assignQualityChecksToVehicle = async (params) => {
        // console.log(params,"assign quality params")
        let logParams = {
            // "location_code" : this.state.location.value,
            // "location_name" : this.state.location.label,
            "user_name" : localStorage.getItem("username"),
            "useremail" : localStorage.getItem("email"),
            "client" : localStorage.getItem("client"),
            "role" : localStorage.getItem("role"),
            "screen" : "Vehicle Checklist Page",
            "activity" : 'Opening Slider to View Requirements',
            "event" : `Opening Slider to View ${params.data.assessedBy} Requirements`,
            // "data_type" : this.state.data_type,
        }    
        let qry = {
            dispatch_planning_type : this.state.data_type,
            plant_location_code : this.state.location.value,
            location_city: this.state.location.label,
            stage: 0
        }
        // console.log(qry, "at stage 1");
        localStorage.setItem('assignqry',JSON.stringify(qry))
        redirectURL.post("/master/loguserUpdatesorChanges",logParams) 
        let qualityQry = {
            dateOfAssessment: params.data.dateOfAssessment,
            stage: params.data.stage,
            vehicle_no: params.data.vehicle_no
        }
        localStorage.setItem("qualityAssignQry",JSON.stringify(qualityQry))
        const newUrl = `${window.location.origin}/assign_requirement_to_vehicle`;
        window.location.href = newUrl;
    }
    getCurrentDateTime =() => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // January is 0
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    return `${year}-${month}-${day}T${hours}:${minutes}`;
    }
    render(){
        const {t} = this.props;

        let data = this.state.rowData
        let locValue = this.state.location.value 
        let  filteredData;
        if(!locValue){
            filteredData = this.state.rowData
        }  else{
            filteredData = data.filter(item => item.location_code == locValue)
        }


        let toolConfig = JSON.parse(localStorage.getItem('tlcfg'))
        

        // var securityColDefs = [
        //     {
        //         headerName: "Requirement",
        //         field: "requirement",
        //         width:420,
        //         filter: true,
        //         resizable: true,
        //         editable:false,
        //         valueGetter: (params) => params.data.requirementDetails.requirement
        //     },
        //     {
        //         headerName: "Satisfied",
        //         field: "isSatisfied",
        //         width: "120",
        //         filter: 'agSetColumnFilter',
        //         valueGetter: (params) => {
        //             if (params.data.isSatisfied) {
        //                 return "Yes"
        //             } else {
        //                 return "No"
        //             }
        //         }
        //     },  
        //     // {
        //     //     headerName: "Requirement Set Date",
        //     //     field: "requirement_set_date",
        //     //     width:140,
        //     //     filter: true,
        //     //     resizable: true,
        //     //     editable:false,
        //     //     valueGetter:function(params){
        //     //         if(params.data.requirement_set_date != ''){
        //     //             return getHyphenDDMMMYYYYHHMM(params.data.requirement_set_date);
        //     //         }
        //     //         else{
        //     //             return "";
        //     //         }
        //     //     },
        //     // },          
        // ]
        // var qualityColDefs = [
        //     {
        //         headerName: "Requirement",
        //         field: "requirement",
        //         width:420,
        //         filter: true,
        //         resizable: true,
        //         editable:false,
        //         valueGetter: (params) => params.data.requirementDetails.requirement
        //     },
        //     {
        //         headerName: "Satisfied",
        //         field: "isSatisfied",
        //         width: "120",
        //         filter: 'agSetColumnFilter',
        //         valueGetter: (params) => {
        //             if (params.data.isSatisfied) {
        //                 return "Yes"
        //             } else {
        //                 return "No"
        //             }
        //         }
        //     },
        //     // {
        //     //     headerName: "Requirement Set Date",
        //     //     field: "requirement_set_date",
        //     //     width:140,
        //     //     filter: true,
        //     //     resizable: true,
        //     //     editable:false,
        //     //     valueGetter:function(params){
        //     //         if(params.data.createdon != ''){
        //     //             return getHyphenDDMMMYYYYHHMM(params.data.requirement_set_date);
        //     //         }
        //     //         else{
        //     //             return "";
        //     //         }
        //     //     },
        //     // },
        // ]
        // var colsDefs = [
        //     {
        //         headerName: "View",
        //         field: "view_history",
        //         width: 140,
        //         resizable : true,
        //         editable : false,
        //         params: {
        //           buttonName: "View",
        //           iconName: "fa fa-eye",
        //           buttonCls: "btn btn-info",
        //           onClickFunction: this.viewOrderDetails,
        //         },
        //         cellRendererSelector: function (params) {
        //           var rendComponent = {
        //             component: "GridButton",
        //           };
        //           return rendComponent;
        //         },
        //         pinned:"left"
        //     },
        //     {
        //         headerName: "Location Code",
        //         field: "location_code",
        //         width: 140,
        //         pinned:"left"
        //     },
        //     {
        //         headerName: "Vehicle No",
        //         field: "vehicle_no",
        //         width: 180,
        //         pinned:"left"
        //     },
        //     {
        //         headerName: "Stage",
        //         field: "stage",
        //         width: 180,
        //         pinned:"left",
        //         valueGetter: (params) => {
        //             if (params.data.stage === 2) {
        //                 return params.data.quality_approved === "No" ? "Quality Rejected" : "Requirements Set";
        //             }
        //             if (params.data.stage === 1){
        //                 return params.data.approved === "No" ? "Security Rejected" : "Security Accepted";
        //             }
        //         }
        //     },
        //     {
        //         headerName: "Assessment Date",
        //         field: "dateOfAssessment",
        //         width: 160,
        //         valueGetter: (params) => {
        //             if (
        //             params.data.dateOfAssessment !== "" &&
        //             params.data.dateOfAssessment !== undefined
        //             ) {
        //             return getHyphenDDMMMYYYYHHMM(params.data.dateOfAssessment);
        //             } else {
        //             return "N/A";
        //             }
        //         },
        //         pinned:"left"
        //     }         
        // ]
        var colsDefs = [
            {
                headerName: t('view'),
                field: "view_history",
                width: 140,
                resizable : true,
                editable : false,
                params: {
                  buttonName: t('view'),
                  iconName: "fa fa-eye",
                  buttonCls: "btn btn-info",
                  onClickFunction: this.viewOrderDetails,
                },
                cellRendererSelector: function (params) {
                  var rendComponent = {
                    component: "GridButton",
                  };
                  return rendComponent;
                },
                pinned: "left"
            },
            {
                headerName: t('location_code'),
                field: "location_code",
                width: 140,
                pinned: "left"
            },
            {
                headerName: t('vehicle_no'),
                field: "vehicle_no",
                width: 180,
                pinned: "left"
            },
            {
                headerName: t('stage'),
                field: "stage",
                width: 180,
                pinned: "left",
                valueGetter: (params) => {
                    if (params.data.stage === 2) {
                        return params.data.quality_approved === "No" ? t('quality_rejected') : t('requirements_set');
                    }
                    if (params.data.stage === 1) {
                        return params.data.approved === "No" ? t('security_rejected') : t('security_accepted');
                    }
                }
            },
            {
                headerName: t('assessment_date'),
                field: "dateOfAssessment",
                width: 160,
                valueGetter: (params) => {
                    if (params.data.dateOfAssessment !== "" && params.data.dateOfAssessment !== undefined) {
                        return getHyphenDDMMMYYYYHHMM(params.data.dateOfAssessment);
                    } else {
                        return "N/A";
                    }
                },
                pinned: "left"
            }
        ];
        var qualityColDefs = [
            {
                headerName: t('requirement'),
                field: "requirement",
                width: 420,
                filter: true,
                resizable: true,
                editable: false,
                valueGetter: (params) => params.data.requirementDetails.requirement
            },
            {
                headerName: t('satisfied'),
                field: "isSatisfied",
                width: "120",
                filter: 'agSetColumnFilter',
                valueGetter: (params) => {
                    if (params.data.isSatisfied) {
                        return t('yes');
                    } else {
                        return t('no');
                    }
                }
            },
            // {
            //     headerName: t('requirement_set_date'),
            //     field: "requirement_set_date",
            //     width: 140,
            //     filter: true,
            //     resizable: true,
            //     editable: false,
            //     valueGetter: function(params){
            //         if (params.data.requirement_set_date !== '') {
            //             return getHyphenDDMMMYYYYHHMM(params.data.requirement_set_date);
            //         } else {
            //             return "";
            //         }
            //     },
            // },
        ];
        var securityColDefs = [
            {
                headerName: t('requirement'),
                field: "requirement",
                width: 420,
                filter: true,
                resizable: true,
                editable: false,
                valueGetter: (params) => params.data.requirementDetails.requirement
            },
            {
                headerName: t('satisfied'),
                field: "isSatisfied",
                width: "120",
                filter: 'agSetColumnFilter',
                valueGetter: (params) => {
                    if (params.data.isSatisfied) {
                        return t('yes');
                    } else {
                        return t('no');
                    }
                }
            },
            // {
            //     headerName: t('requirement_set_date'),
            //     field: "requirement_set_date",
            //     width: 140,
            //     filter: true,
            //     resizable: true,
            //     editable: false,
            //     valueGetter: function(params){
            //         if (params.data.requirement_set_date !== '') {
            //             return getHyphenDDMMMYYYYHHMM(params.data.requirement_set_date);
            //         } else {
            //             return "";
            //         }
            //     },
            // },
        ];
                        
        if( 
            localStorage.getItem("user_type") === "admin" 
            || 
            localStorage.getItem("user_type") === "user" 
            || 
            localStorage.getItem("user_type") === "USER"
            ||
            localStorage.getItem("user_type") === "quality"
        ){
            let x = {
                headerName: t('assign_quality_checks'),
                field: "assign_quality",
                width: 200,
                pinned: "left",
                resizable : true,
                editable : false,
                pinned:"left",
                params: {
                  buttonName: t('quality_checks'),
                  iconName: "fa fa-plus",
                  buttonCls: "btn btn-warning",
                  onClickFunction: this.assignQualityChecksToVehicle,
                },
                cellRendererSelector: function (params) {
                    // console.log(params,"paramssss")
    
                    var rendComponent=''
                    if ((params.data.approved === "No" && params.data.approved_by === "security") || params.data.hasOwnProperty("qualityCheckList")) {
                        rendComponent = {
                            component: "NAButton",
                        };
                        return rendComponent;
                    } else {
                        rendComponent = {
                            component: "GridButton",
                        };
                        return rendComponent;
                    }
                },
            }
            colsDefs = [x, ...colsDefs];
        }
        if(localStorage.getItem("user_type") === "security" || localStorage.getItem("user_type") === "admin" || localStorage.getItem("user_type") === "USER"){
            let security = {
                headerName: t('security'),
                headerClass:["Securitycellstylegrid","SecuritycellTopHeader"],
                headerComponentParams: { suppressHtmlEntity: true },
                children: 
                // [
                //     { field: 'assessedBy', headerName: "Assessed By", headerClass: "Securitycellstylegrid",cellClass: 'Securitycellstylegrid' },
                //     { field: 'conclusion', headerName: "Security Conclusion", headerClass: "Securitycellstylegrid",cellClass: 'Securitycellstylegrid' },
                //     { field: 'comments', headerName: "Security Comments", headerClass: "Securitycellstylegrid",cellClass: 'Securitycellstylegrid' },
                //     { field: 'approved', width:"160", headerName:"Security Approved", headerClass: "Securitycellstylegrid",cellClass: 'Securitycellstylegrid' },
                //     { 
                //         headerName: 'Security Date Of Assessment',
                //         field: 'security_dateOfAssessment',
                //         width: 230,
                //         cellClass: 'Securitycellstylegrid',
                //         headerClass: "Securitycellstylegrid" ,
                //         valueGetter: (params) => {
                //             if (params.data.dateOfAssessment!== "" && params.data.dateOfAssessment!== undefined) {
                //                 return getHyphenDDMMMYYYYHHMM(params.data.dateOfAssessment);
                //             } else {
                //                 return "N/A";
                //             }
                //         }
                //     }
                // ]
                [
                    { 
                        field: 'assessedBy', 
                        headerName: t('assessed_by'), 
                        headerClass: "Securitycellstylegrid", 
                        cellClass: 'Securitycellstylegrid' 
                    },
                    { 
                        field: 'conclusion', 
                        headerName: t('security_conclusion'), 
                        headerClass: "Securitycellstylegrid", 
                        cellClass: 'Securitycellstylegrid' 
                    },
                    { 
                        field: 'comments', 
                        headerName: t('security_comments'), 
                        headerClass: "Securitycellstylegrid", 
                        cellClass: 'Securitycellstylegrid' 
                    },
                    { 
                        field: 'approved', 
                        width: "160", 
                        headerName: t('security_approved'), 
                        headerClass: "Securitycellstylegrid", 
                        cellClass: 'Securitycellstylegrid' 
                    },
                    { 
                        headerName: t('security_date_of_assessment'),
                        field: 'security_dateOfAssessment',
                        width: 230,
                        cellClass: 'Securitycellstylegrid',
                        headerClass: "Securitycellstylegrid",
                        valueGetter: (params) => {
                            if (params.data.dateOfAssessment !== "" && params.data.dateOfAssessment !== undefined) {
                                return getHyphenDDMMMYYYYHHMM(params.data.dateOfAssessment);
                            } else {
                                return "N/A";
                            }
                        }
                    }
                ]
            }
            colsDefs = [...colsDefs, security]
        }
        if(localStorage.getItem("user_type") === "quality" || localStorage.getItem("user_type") === "admin" || localStorage.getItem("user_type") === "USER" ){
            let quality =  {
                headerName: t('quality'),
                headerClass:["Qualitycellstylegrid","QualitycellTopHeader"],
                headerComponentParams: { suppressHtmlEntity: true },
                children: 
                // [
                //     { field: 'quality_assessedBy',  headerName: "Assessed By", headerClass: "Qualitycellstylegrid",cellClass: 'Qualitycellstylegrid' },
                //     { field: 'quality_conclusion', headerName: "Quality Conclusion", headerClass: "Qualitycellstylegrid",cellClass: 'Qualitycellstylegrid' },
                //     { field: 'quality_comments', headerName: "Quality Comments", headerClass: "Qualitycellstylegrid",cellClass: 'Qualitycellstylegrid' },
                //     { field: 'quality_approved', width:"160" , headerName: "Quality Approved", headerClass: "Qualitycellstylegrid",cellClass: 'Qualitycellstylegrid' },
                //     { 
                //         field: 'quality_dateOfAssessment',
                //         width: 230,
                //         headerClass: "Qualitycellstylegrid",
                //         cellClass: 'Qualitycellstylegrid',
                //         headerName: "Quality Date of Assessment",
                //         valueGetter: (params) => {
                //             if (params.data.quality_dateOfAssessment!== "" && params.data.quality_dateOfAssessment!== undefined) {
                //                 return getHyphenDDMMMYYYYHHMM(params.data.quality_dateOfAssessment);
                //             } else {
                //                 return "N/A";
                //             }
                //         }
                //     }
                // ]
                [
                    { 
                        field: 'quality_assessedBy',  
                        headerName: t('assessed_by'), 
                        headerClass: "Qualitycellstylegrid", 
                        cellClass: 'Qualitycellstylegrid' 
                    },
                    { 
                        field: 'quality_conclusion', 
                        headerName: t('quality_conclusion'), 
                        headerClass: "Qualitycellstylegrid", 
                        cellClass: 'Qualitycellstylegrid' 
                    },
                    { 
                        field: 'quality_comments', 
                        headerName: t('quality_comments'), 
                        headerClass: "Qualitycellstylegrid", 
                        cellClass: 'Qualitycellstylegrid' 
                    },
                    { 
                        field: 'quality_approved', 
                        width: "160", 
                        headerName: t('quality_approved'), 
                        headerClass: "Qualitycellstylegrid", 
                        cellClass: 'Qualitycellstylegrid' 
                    },
                    { 
                        field: 'quality_dateOfAssessment', 
                        width: 230, 
                        headerClass: "Qualitycellstylegrid", 
                        cellClass: 'Qualitycellstylegrid', 
                        headerName: t('quality_date_of_assessment'), 
                        valueGetter: (params) => {
                            if (params.data.quality_dateOfAssessment !== "" && params.data.quality_dateOfAssessment !== undefined) {
                                return getHyphenDDMMMYYYYHHMM(params.data.quality_dateOfAssessment);
                            } else {
                                return "N/A";
                            }
                        }
                    }
                ]
                
            }
            colsDefs = [...colsDefs, quality]
        }
        let location_access = JSON.parse(localStorage.getItem("location_access"))
        let role = localStorage.getItem("user_type")
        var userMailCheck= location_access ? (localStorage.getItem('role')==='admin' || localStorage.getItem('user_type') === "admin" || localStorage.getItem('user_type') === "USER") ? "show-m" : 'show-n': (localStorage.getItem('role')==='admin' || localStorage.getItem('user_type') === "admin" || localStorage.getItem('user_type') === "USER" ) ? "show-m" : 'show-n'
        var roleCheck = location_access ? (localStorage.getItem("role") === "admin" || localStorage.getItem("user_type") === "admin" || localStorage.getItem("user_type") === "USER" || localStorage.getItem("user_type") === "security" ) ? "show-m" : "show-n": (localStorage.getItem('role')==='admin' || localStorage.getItem('user_type') === "admin" || localStorage.getItem('user_type') === "USER" ) ? "show-m" : 'show-n'
        return(
            <div className="">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}>
                </SweetAlert>
                <div className="row mb-5p ">
                    <div className="col-sm-12">
                        <div className="col-sm-6">
                            <h5 className='fbold '>
                                <span>{t('vehicle_checklist_for_transportation')}</span>                            
                            </h5>
                        </div>
                        <div className="col-sm-6" style={{display:"flex",justifyContent:"end"}}>                            
                            <span className={userMailCheck}>
                                <a href="javascript:;" onClick={this.redirectToaddRequirement} className="btn btn-warning f12 mr-10p">+ {t('add_requirements')}</a>
                            </span>
                            <span className={roleCheck}>
                                <a href="javascript:;" style={{cursor: this.state.toggleAddVehicleCheckListBtn ? 'not-allowed' : 'pointer', opacity: this.state.toggleAddVehicleCheckListBtn ? 0.5 : 1}}  onClick={this.redirectToAddVehicleChecklist} className="btn btn-warning f12 mr-10p">+ {t('add_vehicle_checklist')}</a>                                    
                            </span>
                        </div>
                    </div>
                    <div className="col-sm-12 pt-5p">                        
                        <div className="col-md-2 col-sm-12">
                            <div className="form-group ">
                                <label className="col-form-label f12">{t('pickup_point')}</label>
                                <Select
                                    placeholder={"Select"}
                                    onChange={this.locationChanged}
                                    name="location"
                                    value={this.state.location}
                                    options={this.state.locationNames} />
                            </div>
                        </div>
                        <div className="col-md-3 form-control-custom">
                            <div className="form-group">
                                <label className="col-form-label f12">{t('assessment_start_date')}</label>
                                <input
                                    type="datetime-local"
                                    className="form-control"
                                    name="startData"
                                    value={this.state.startDate}
                                    onChange={this.onChangeStartDate}
                                    placeholder="End Date"
                                    id="startData"
                                    autoComplete="off"
                                    onKeyDown={(e) => e.preventDefault()}
                                    max={this.getCurrentDateTime()}
                                    readonly
                                />
                            </div>
                        </div>
                        <div className="col-md-3 form-control-custom">
                            <div className="form-group">
                                <label className="col-form-label f12">{t('assessment_end_date')}</label>
                                <input
                                    type="datetime-local"
                                    className="form-control"
                                    name="endDate"
                                    id="endDate"
                                    autoComplete="off"
                                    onKeyDown={(e) => e.preventDefault()}
                                    max={this.getCurrentDateTime()}
                                    onChange={this.onChangeEndDate}
                                    value={this.state.endDate}
                                    readonly
                                />
                            </div>
                        </div>
                        <div className="col-md-2">
                            <button type="button" className="btn btn-warning mt-35px" onClick={this.onGetVehicleCheckListFromHistory}>{t('get_data')}</button>
                        </div>
                    </div>
                </div>
                    
                <div class="row mb-20p pt-2p">
                    <div className="col-sm-12 d-flex">
                        <div className= {this.state.showAvailablityTable ? "col-sm-7 " : "col-sm-12 " + "  d-inline"}>
                            <div id="myGrid" style={{width:"100%",height:"70vh", padding:"0px"}} className="col-sm-12 ag-theme-balham dropdown">
                                <AgGridReact
                                    rowData={this.state.vechicleCheckListRecords}
                                    columnDefs={colsDefs}
                                    gridOptions={{context:{componentParent:this}}}
                                    defaultColDef={this.state.defaultColDef}
                                    frameworkComponents={this.state.frameworkComponents}
                                    paginationPageSize={this.state.paginationPageSize}
                                    pagination={true}
                                    enableRangeSelection = {true}
                                    enableCellContextMenu={true}
                                    masterDetail={true}
                                    detailCellRendererParams={this.state.detailCellRendererParams}
                                    statusBar={this.state.statusBar}
                                    sideBar = {this.state.sideBar}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"sliderBlock2 " + this.state.showRequirementSlider} style={{padding:"20px"}} ref={this.sliderRef}>
                    <div>
                        {
                            (role === "security" || role === "admin" || role === "USER") ?                                 
                                <div className="col-sm-12">
                                <h5 className="crd-bg p-10p">Security Requirements</h5>
                                <div
                                id="myGrid2"
                                style={{ width: "100%", height: "400px" }}
                                className="col-sm-12 ag-theme-balham dropdown"
                                >
                                <AgGridReact
                                    // modules={this.state.modules}
                                    rowData={this.state.securityRequirementDetails}
                                    columnDefs={securityColDefs}
                                    gridOptions={{ context: { componentParent: this } }}
                                    defaultColDef={this.state.defaultColDef}
                                    frameworkComponents={this.state.frameworkComponents}
                                    paginationPageSize={this.state.paginationPageSize}
                                    pagination={true}
                                    enableRangeSelection={true}
                                />
                                </div>
                                </div>
                            : ""
                        }
                    </div>
                    <br />
                    {this.state.vechicleCheckListRecords.some( (e) => e.hasOwnProperty("qualityCheckList")) ?
                        (role === "quality" || role === "admin" || role === "USER") ?                         
                            <div className="col-sm-12" style={{paddingTop:"40px"}}>
                                <h5 className="crd-bg p-10p">Quality Requirements</h5>
                                <div
                                id="myGrid2"
                                style={{ width: "100%", height: "400px" }}
                                className="col-sm-12 ag-theme-balham dropdown"
                                >
                                    <AgGridReact
                                        // modules={this.state.modules}
                                        rowData={this.state.qualityRequirementDetails}
                                        columnDefs={qualityColDefs}
                                        gridOptions={{ context: { componentParent: this } }}
                                        defaultColDef={this.state.defaultColDef}
                                        frameworkComponents={this.state.frameworkComponents}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        enableRangeSelection={true}
                                    />
                                </div>
                            </div>
                        :""
                    :""}
                </div>
                <div className={"overlay-part " + this.state.overly} onClick={this.hideSlideBlock.bind(this)}></div>
            </div>
        )
    }
}

export default withTranslation()(VechicleCheckList)