import React, { useState, useEffect } from "react";
import Modal from "react-responsive-modal";
import { useTranslation } from 'react-i18next';

import ScrollBarForForecast from "./scrollBarInforeCast";
import { Form } from "react-bootstrap";
import { event } from "jquery";

const VehicleTypesModal = (props) => {
  const { t } = useTranslation();

  var { openVTM, onCloseVTM, vehicleTypeList,saveVehicleData } = props;
  var [value, setValue] = useState(4); // Initialize value
  const [autoSelect, setautoSelect] = useState(false)

  const handleChange = async (event,item) => {
    await setValue(parseInt(event.target.value)); // Update value when slider is moved
    props.data.value = parseInt(event.target.value);
  };
  var min = 3,
    max = 7;

   const onclickSave=(event)=>{
      event.preventDefault()
      saveVehicleData()
    }

    const handleautoSelect = (e) =>{
      setautoSelect(!autoSelect)
    }

  return (
    <>
      <Modal
        closeOnOverlayClick={false}
        open={openVTM}
        onClose={onCloseVTM}
        classNames={{ modal: 'pickupdrop-modelClass' }}
        >
        <div className="col-md-12 container-fluid">
          <form
            className="theme-form row mt-0"
            id="config"
            onSubmit={onclickSave}
          >
            <h5 className="d-flex text-align ml-25p" style={{fontWeight:'bold',textAlign:'center',color:"black"}}>{t('no_of_vehicles')}</h5>
            <input type="checkbox" style={{marginLeft:'160px'}} id="autoSelect" checked = {autoSelect ? true : false} name="autoSelect" onClick={handleautoSelect}/>
            <label style={{marginTop:'10px'}} className="fbold f16" for="autoSelect">&nbsp;&nbsp;{t('auto_select')}&nbsp;&nbsp;&nbsp;&nbsp;</label> 
            {vehicleTypeList.map((item) => {
              if(!autoSelect){
              return (
                <div className="m-5p col-md-12">
                  <ScrollBarForForecast data={item} />
                </div>
              );}else{
                return '';
              }
            })}
            <div className="form-group col-sm-12">
                <button type="submit" className="btn tbtn float-right save-btn" id="saveGeo">{t('save')}</button>
                <button type="button" className="btn tbtn float-right cancel-btn" onClick={onCloseVTM}>{t('cancel')}</button>	
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default VehicleTypesModal;
