
import React, { Component } from 'react';
// import Constant from "../common/constants";
import MarkerClusterer from "@google/markerclusterer";
var infoBox = require('../../common/google-infowindow');

var map
var overlay;
var infowindow = [];
var markersArraylist = [];
var infowindows = []
// var infowindowArray = [];


export default class VehicleStatusMap extends Component {
	constructor(props) {
		super(props);
		this.state = {
			previousData: []
		}
	}

	componentDidMount() {
		console.log("hey")
		this.renderMapKey()
		this.renderMap();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.data !== this.props.data) {
			console.log("holy")
			this.renderMapKey()
			this.renderMap()

			//   console.log('Previous value of someProp:', prevProps.radius);
		}
	}

	renderMapKey = () => {
		loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyC6cJAlLsrTTgm5Ck795Vg6X_EToa7FG-A&libraries=places,drawing&callback=initMap");
	}
	renderMap = () => {
		window.initMap = this.initMap
	}


	initMap = () => {
		this.setState({ previousData: this.props.data })
		var centerOfIndia = { lat: 39.8097343, lng: -98.5556199 };
		var mapOptions = {
			zoom: 6,
			zoomControl: true,
			mapTypeControl: true,
			scaleControl: true,
			streetViewControl: true,
			rotateControl: true,
			fullscreenControl: true,
			labels: true,
			mapTypeControlOptions: {
				mapTypeIds: ['satellite', 'roadmap'],
			},
			center: centerOfIndia,
			mapTypeId: window.google.maps.MapTypeId.ROADMAP,
			styles: [

				{
					"featureType": "administrative",
					"elementType": "labels.text.fill",
					"stylers": [
						{
							"color": "#444444"
						}
					]
				},
				{
					"featureType": "landscape",
					"elementType": "all",
					"stylers": [
						{
							"saturation": -100
						},
						{
							"lightness": 65
						},
						{
							"visibility": "on"
						}
					]
				},

				{
					"featureType": "landscape.man_made",
					"elementType": "all",
					"stylers": [
						{
							"visibility": "on"
						}
					]
				},
				{
					"featureType": "landscape.man_made",
					"elementType": "geometry",
					"stylers": [
						{
							"visibility": "on"
						}
					]
				},
				{
					"featureType": "landscape.man_made",
					"elementType": "geometry.fill",
					"stylers": [
						{
							"visibility": "on"
						}
					]
				},
				{
					"featureType": "landscape.man_made",
					"elementType": "geometry.stroke",
					"stylers": [
						{
							"color": "#c1c2c2"
						},
						{
							"visibility": "on"
						}
					]
				},
				{
					"featureType": "poi.park",
					"elementType": "all",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "poi",
					"elementType": "labels",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "road",
					"elementType": "all",
					"stylers": [
						{
							"saturation": -100
						},
						{
							"lightness": 45
						}
					]
				},
				{
					"featureType": "road.highway",
					"elementType": "all",
					"stylers": [
						{
							"visibility": "simplified"
						}
					]
				},
				{
					"featureType": "road.arterial",
					"elementType": "labels.icon",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "transit",
					"elementType": "all",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
			],
		};
		try {
			map = new window.google.maps.Map(document.getElementById('pickupDrop_Locations_map'), mapOptions);
		} catch (e) { }


		var bounds = new window.google.maps.LatLngBounds();
		this.maploadData();

		// const iconBase = require("../../assets/images/");
		// const icons = {
		// 	plant: {
		// 	name: "Pickup",
		// 	icon: require("../../../assets/images/plant-location.png"),
		// 	},
		// 	supplier: {
		// 	name: "Drop",
		// 	icon: require("../../../assets/images/customer_pin_38.png"),
		// 	},
		// 	// both: {
		// 	// name: "Both",
		// 	// icon: require("../../assets/images/business.png"),
		// 	// },

		// };

		// var legend = document.getElementById("legend");
		// legend.innerHTML = '';
		// for (var key in icons) {
		// 	var type = icons[key];
		// 	var name = type.name;
		// 	var icon = type.icon;
		// 	var div = document.createElement("div");

		// 	div.innerHTML = '<img class="legend-height" src="' + icon + '"> ' + name;
		// 	legend.appendChild(div);
		// }

		// map.controls[window.google.maps.ControlPosition.TOP_RIGHT].push(legend);

		overlay = new window.google.maps.OverlayView();
		overlay.draw = function () { };
		overlay.setMap(map);

		window.google.maps.event.addListener(map, 'click', (function () {
			closeAllInfoWindows()
		}))
	}
	// infoWindow = new window.google.maps.InfoWindow();
	maploadData = () => {
		let data_type = this.state.data_type

		for (let i = 0; i < markersArraylist.length; i++) {
			markersArraylist[i].setMap(null);
		}

		var locations = this.props.data;
		if (typeof (locations) == 'string') {
			locations = JSON.parse(locations)
		}

		// var lt=17.432143; 
		// var ln=78.374427;
		var lt = 39.8097343;
		var ln = -98.5556199;

		var bounds = new window.google.maps.LatLngBounds();
		bounds.extend(new window.google.maps.LatLng((lt), (ln)));

		try {
			// var infowindow = new window.google.maps.InfoWindow();

			if (locations.length > 0) {
				var truckMarkers = [];
				var plantMarkers = [];
				var dealerMarkers = [];

				var image = require('../../../assets/images/plant-location.png');
				var iconType = ""
				var markers = []

				locations.map(function (e, index) {
					if (e.vehicle_current_status == 1) {
						image = require('../../../assets/images/inside-truck.png');
						iconType = "truck"
						truckMarkers.push(createMarker(e, image, iconType, "Inside DC"));
					} else if (e.vehicle_current_status == 2) {
						image = require('../../../assets/images/intransit-truck.png');
						iconType = "truck"
						truckMarkers.push(createMarker(e, image, iconType, "In-Transit to Customer"));
					} else if (e.vehicle_current_status == 3) {
						image = require('../../../assets/images/near-truck.png');
						iconType = "truck"
						truckMarkers.push(createMarker(e, image, iconType, "Near Customer"));
					} else if (e.vehicle_current_status == 4) {
						image = require('../../../assets/images/return-truck.png');
						iconType = "truck"
						truckMarkers.push(createMarker(e, image, iconType, "Return to DC"));
					} else if (e.vehicle_current_status == 5) {
						image = require('../../../assets/images/empty-truck.png');
						iconType = "truck"
						truckMarkers.push(createMarker(e, image, iconType, "Empty Outside DC"));
					} else if (e.vehicle_current_status == 6) {
						image = require('../../../assets/images/unplanned-truck.png');
						iconType = "truck"
						truckMarkers.push(createMarker(e, image, iconType, ""));
					} else if (e.location_type == 1) {
						image = require('../../../assets/images/plant-location.png');
						iconType = "plant"
						plantMarkers.push(createMarker(e, image, iconType, ""));
					} else if (e.location_type == 2) {
						image = require('../../../assets/images/customer_pin_38.png');
						iconType = "customer"
						dealerMarkers.push(createMarker(e, image, iconType, ""));
					}
					bounds.extend(new window.google.maps.LatLng((e.latitude), (e.longitude)));
					// var marker = new window.google.maps.Marker({
					//     position: new window.google.maps.LatLng((e.latitude), (e.longitude)),
					//     icon: image,
					//     map: map,	
					// 	iconType: iconType
					// });
					// markers.push(marker)
				})
				// function createMarker(e, image,iconType) {
				// 	return new window.google.maps.Marker({
				// 		position: new window.google.maps.LatLng(e.latitude, e.longitude),
				// 		icon: image,
				// 		map: map,
				// 		iconType: iconType
				// 	});
				// }

				// console.log(markers.length, "markers")
				console.log(truckMarkers, plantMarkers, dealerMarkers, "werty")
				function createMarker(e, image, iconType, status) {
					let marker = new window.google.maps.Marker({
						position: new window.google.maps.LatLng(e.latitude, e.longitude),
						icon: image,
						map: map,
						iconType: iconType
					});
					let infowindow = new window.google.maps.InfoWindow();

					window.google.maps.event.addListener(marker, 'click', (function(marker) {
                        return function() {
                            
                            var contentarr = []
                        
                        var header = ""
						console.log(status, "status")
						if(iconType == "truck"){
							header = e.vehicle_no
							contentarr.push({"key":"Vehicle Status :", "value": status})
							contentarr.push({"key":"DC Attached to :", "value": e.plant_name})
							contentarr.push({"key":"Transporter Name :", "value": e.transporter_name})
                            contentarr.push({"key":"Location Address : ", "value":e.address})
						}else if(iconType == "customer"){
							header = e.location_name
							contentarr.push({"key":"Location Code : ", "value":e.location_code})
							contentarr.push({"key":"Location City : ", "value":e.location_city})
							contentarr.push({"key":"Location Address : ", "value":e.location_address})
						}else if(iconType == "plant"){
							header = e.location_name
							contentarr.push({"key":"Location Code : ", "value":e.location_code})
							contentarr.push({"key":"Location Address : ", "value":e.location_address})
						}
                        // contentarr.push({"key":"Start Time", "value":e.from_time})
                        // contentarr.push({"key":"End Time", "value":e.to_time})
                        var contentString = infoBox(marker.icon, header, contentarr,'')
                                                
                        infowindow.setContent(contentString);
                        infowindows.push(infowindow);
                        
                        infowindow.open(map, marker);
                        
                        }
                    })(marker));
					return marker;
				}

				console.log(markers.length, "markers")

				var truckCluster = new MarkerClusterer(map, truckMarkers, {
					imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
					styles: [{
						width: '59',
						height: '59',
						url: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m1.png',
						textColor: 'black',  // Color of text shown in the cluster icon
						textSize: 15  // Size of text shown in the cluster icon
					}]
					, minimumClusterSize: 2 // Adjust this value as needed
				});

				var plantCluster = new MarkerClusterer(map, plantMarkers, {
					imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
					styles: [{
						width: '70',
						height: '70',
						url: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m3.png',
						textColor: 'black',  // Color of text shown in the cluster icon
						textSize: 15  // Size of text shown in the cluster icon
					}]
					, minimumClusterSize: 2 // Adjust this value as needed

				});

				var dealerCluster = new MarkerClusterer(map, dealerMarkers, {
					imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
					styles: [{
						width: '59',
						height: '59',
						url: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m2.png',
						textColor: 'black',  // Color of text shown in the cluster icon
						textSize: 15  // Size of text shown in the cluster icon
					}]
					, minimumClusterSize: 2 // Adjust this value as needed
				});
				// var markerCluster = new MarkerClusterer(map, markers,
				// { imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m' });
			}
			map.fitBounds(bounds)
			window.google.maps.event.addListenerOnce(map, 'bounds_changed', function () {
				map.setZoom(4);  // Set your desired zoom level here
			});
		} catch (e) { }

	}

	updateMap = () => {
		let previousData = this.state.data
		this.renderMap()

	}

	render() {

		this.updateMap()
		return (
			<div className=''>
				<>
					<div id="pickupDrop_Locations_map" style={{ width: '100%', height: "450px", border: "2px solid black" }}></div>
					<div id="legend" className='d-flex' style={{ color: '#000' }}></div>
				</>
			</div>
		);
	}
};

function loadScript(url) {
	var index = window.document.getElementsByTagName("script")[0]
	var script = window.document.createElement("script")
	script.src = url
	script.async = true
	script.defer = true
	index.parentNode.insertBefore(script, index)
}


function closeAllInfoWindows() {
	for (var i = 0; i < infowindows.length; i++) {
		infowindows[i].close();
	}
}