import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const Countdown = ({ duration, onTimeout, timerKey }) => {
  const {t} = useTranslation()
    const [remainingSeconds, setRemainingSeconds] = useState(() => {
      const storedRemainingSeconds = localStorage.getItem(timerKey);
      return storedRemainingSeconds !== null ? parseInt(storedRemainingSeconds) : duration;
    });
  
    useEffect(() => {
      localStorage.setItem(timerKey, remainingSeconds.toString());
  
      if (remainingSeconds > 0) {
        const intervalId = setInterval(() => {
          setRemainingSeconds((prevSeconds) => {
            if (prevSeconds > 0) {
              return prevSeconds - 1;
            } else {
              clearInterval(intervalId);
              onTimeout();
              return 0;
            }
          });
        }, 1000);
  
        return () => {
          clearInterval(intervalId);
        };
      }
    }, [remainingSeconds, onTimeout, timerKey]);
  
    const minutes = Math.floor(remainingSeconds / 60);
    const seconds = remainingSeconds % 60;
  
    return (
      <div>
        <p className='timer-font'>{t('estimated_time')}: {minutes.toString().padStart(2, '0')}:{seconds.toString().padStart(2, '0')}</p>
      </div>
    );
  };

  export default Countdown;