// import React, { Component } from 'react';
// import { useTranslation } from 'react-i18next';
 
// const vehicleAssigned = (props) => {
//     const { t } = useTranslation(); 
//     return (
//         <div style={{"textAlign":"center"}}>           
//             {t('vehicle_assigned')}          
//         </div>
//     );
// };

// export default vehicleAssigned;



import React from 'react';
import { useTranslation } from 'react-i18next';

const VehicleAssigned = (props) => {
    const { t } = useTranslation(); 
    return (
        <div style={{ textAlign: "center" }}>           
            {t('vehicle_assigned')}          
        </div>
    );
};

export default VehicleAssigned;
