import React from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-responsive-modal";
import PWConfigL1 from "./plantWConfigL1";


 function ConfigSummary(props) {
  const { t } = useTranslation();
  var { openConfigmd } = props;

  var { handleOnClose, data } = props;

  var uppKeys = [
    "Prioritize Local Loads",
    "City Based Entry Restriction",
    "Follow Telescopic Routes",
    "Consider Vehicle Break Times (Night Driving)",
    "Consider Dropping Distant Smaller Loads",
    "Consider Holiday Restrictions",
    "Consider Realtime Vehicle Available Times",
    "Plan Spot Vehicles",
    "Consider Transporter SOB",
  ];

  var lower1 = [
    "Use Dynamic Planning Window",
    "Number of Days to Use Planning Window",
    "Optimization Type*",
  ];

  var lower2 = [
    "Use Volume for Vehicle Fill Rate (VFR)",
    "Use Weight for Vehicle Fill Rate (VFR)",
    "Min VFR Per Vehicle Type",
    "Min VFR Per Spot Vehicle",
    "MOQ for Planning",
  ];
  var lower3 = [
    "Number of Loading Docks",
    "Max No of Customers Per Vehicle",
    "Max Local No of Customers Per Vehicle",
    "Max Upcountry No of Customers Per Vehicle",
    "Prioritize Same Dealers First in a Vehicle",
    "Prioritize Multi City Dealers First in a Vehicle",
  ];

  var uppOpt = {},
    lowOpt1 = {},
    lowOpt2 = {},
    lowOpt3 = {};
  // console.log(Object.keys(configs,'len'))
  var configs = {};
  if (data.length) {
    configs = data[0];
  } else {
    openConfigmd = false;
  }
  if (Object.keys(configs).length > 0) {
    uppKeys = uppKeys.filter((key) => configs.hasOwnProperty(key));
    uppKeys.map((item) => {
      uppOpt[item] = configs[item];
      uppOpt[item].disabled = true;
      return "";
    });
    lower1 = lower1.filter((key) => configs.hasOwnProperty(key));
    lower1.map((item) => {
      lowOpt1[item] = configs[item];
      lowOpt1[item].disabled = true;
      if (item === "Optimization Type*") {
        lowOpt1["Optimization Type*"]["Cost Optimization"].disabled = true;
        lowOpt1["Optimization Type*"][
          "Weight based optimization"
        ].disabled = true;
        lowOpt1["Optimization Type*"][
          "Distance based optimization"
        ].disabled = true;
        lowOpt1["Optimization Type*"][
          "Time based optimization"
        ].disabled = true;
      }
      return "";
    });
    lower2 = lower2.filter((key) => configs.hasOwnProperty(key));
    lower2.map((item) => {
      lowOpt2[item] = configs[item];
      lowOpt2[item].disabled = true;
      return "";
    });
    lower3 = lower3.filter((key) => configs.hasOwnProperty(key));
    lower3.map((item) => {
      lowOpt3[item] = configs[item];
      lowOpt3[item].disabled = true;
      return "";
    });
  }
  return (
    <>
      {/* <Modal
        closeOnOverlayClick={false}
        open={openConfigmd}
        onClose={handleOnClose}
        className={{ modal: "config-modelClass" }}
      > */}
        <div className="col-sm-12">
          <form
            className="theme-form mt-0"
            id="config"
            // onSubmit={this.savePlantConfigs}
          >
            <div className="row">
              <div className="col-sm-12">
                <div className=" cfg-card-t">
                <h6 className="">
                  {t('general_settings')}
                </h6>
                  {Object.keys(uppOpt).length > 0 ? (
                    <div
                      className="d-flex flex-row mt-10p"
                      style={{ marginTop: "10px" }}
                    >
                      <PWConfigL1 data={uppOpt} colLength={4} />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            
            <div className="row mt-25p">
              <div className="col-sm-4">
                <div className=" cfg-card">
                <h6>
                    {t('planning_optimization_settings')}
                  </h6>
                  {Object.keys(lowOpt1).length > 0 ? (
                    <PWConfigL1
                      screen={"summary"}
                      data={lowOpt1}
                      colLength={12}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
              
              <div className="col-sm-4 ">
                <div className=" cfg-card">
                    <h6>
                      {t('vehicle_fill_rate_vfr_settings')}
                    </h6>
                    <div style={{ padding: "0", margin: "0" }}>
                      {Object.keys(lowOpt2).length > 0 ? (
                        <PWConfigL1 data={lowOpt2} />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
              </div>
                
              <div className="col-sm-4 ">
                <div className=" cfg-card">
                    <h6>
                      {t('load_planning_settings')}
                    </h6>
                    <div className="mt-0" style={{ padding: "0", margin: "0" }}>
                      {Object.keys(lowOpt3).length > 0 ? (
                        <PWConfigL1 data={lowOpt3} />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
              </div>
            </div>
          </form>
        </div>
      {/* </Modal> */}
    </>
  );
}

export default ConfigSummary