import React,{Component} from "react";
import Constant from "../common/constants";
import { withTranslation } from "react-i18next";
import redirectURL from '../redirectURL';
import Select from 'react-select';
import getToolConfiguration from "./commonFunctions";
import ZoneMapping from './zonemapping';
import Zones from './zones';




 class ZoneConfiguration extends Component{
    constructor(props){
        super(props);
        this.state={
            locationNames:[],
            location:'',
            zoneMappingTab:"",
            zonesTab : "activet",
            zoneMappingData: false,
            zonesData:true,
        }
        
    }

    async componentDidMount(){
        
        var curl = window.location.search;
        var ul= curl.split("?");
        let url_type = ul[parseInt(ul.length)-1];
        let data_type
        let path =url_type

        if (path == 'inbound'){
            data_type = 1
        }else{
            data_type = 2
        }
        await this.setState({data_type : data_type})

        let params = {
            data_type : data_type
        }
        let toolparameters = {
            data_type : data_type
        }

        await getToolConfiguration(toolparameters)
        this.toolConfigurationSetup()
        await this.boundTypeLocations(params)
    }

    boundTypeLocations = async(param) => {

        let location_access = JSON.parse(localStorage.getItem("location_access"))

        let locationNames = this.state.locationNames;
        await redirectURL.post('master/getBoundTypeLocations',param)
          .then(async (response) =>{
              if(response.data.status == 'success'){
                  let boundLocations = response.data.boundLocations
                  if(boundLocations.length > 0){
                      boundLocations.map(item =>{
                        if(location_access != null){
                            if(location_access.includes(item.location_code)){
                                locationNames.push({ value : item.location_code,
                                                    label : item.location_name })

                                }
                            }else{
                                locationNames.push({ value : item.location_code,
                                                    label : item.location_name })
                            }
                      })
                      
                      if(locationNames.length>0){
                        await this.setState({
                                    locationNames : locationNames,
                                    location : locationNames[0]
                                })
                    }else{
                        await this.setState({
                            locationNames : [{label:'Please Add Data',value:'no_data'}],
                            location : [{label:'Please Add Data',value:'no_data'}]
                        })
                    }
                  }
              }
          })
          
      }

    locationChanged = (location) =>{
        this.setState({
            location : location
        })
        getToolConfiguration({plant_code:location.value})
        this.toolConfigurationSetup()
        let zoneMappingTab = "";
        let zonesTab = "activet";
        let zoneMappingData = false;
        let zonesData = true;
        this.setState({
            zoneMappingTab:zoneMappingTab,
            zonesTab:zonesTab,
            zoneMappingData:zoneMappingData,
            zonesData:zonesData,
        })
        let logParams = {
            "location_code" : this.state.location.value,
            "location_name" : this.state.location.label,
            "user_name" : localStorage.getItem("username"),
            "useremail" : localStorage.getItem("email"),
            "client" : localStorage.getItem("client"),
            "screen" : "Zone Configurations Screen ",
            "activity" : `Clicked on Plant Dropdown`,
            "event" : `Selected ${this.state.location.label} Plant`,
            "data_type" : this.state.data_type,
        }        
        redirectURL.post("/master/loguserUpdatesorChanges",logParams)
    }


    onClickTab = (pageType) => {

        if(pageType == "zoneMappingData")
        {
            var zoneMappingTab = "activet";
            var zonesTab = "";
            var zoneMappingData = true;
            var zonesData = false;
            
        }
        else if(pageType == "zonesData")
        {
            var zoneMappingTab = "";
            var zonesTab = "activet";
            var zoneMappingData = false;
            var zonesData = true;
        }
        this.setState({
            zoneMappingTab:zoneMappingTab,
            zonesTab:zonesTab,
            zoneMappingData:zoneMappingData,
            zonesData:zonesData,
            
        })
        let logParams = {
            "location_code" : this.state.location.value,
            "location_name" : this.state.location.label,
            "user_name" : localStorage.getItem("username"),
            "useremail" : localStorage.getItem("email"),
            "client" : localStorage.getItem("client"),
            "screen" : "Zone Configurations Screen ",
            "activity" : `Clicked on ${pageType} tab`,
            "event" : `Clicked on ${pageType} tab`,
            "data_type" : this.state.data_type,
        }        
        redirectURL.post("/master/loguserUpdatesorChanges",logParams)
    }


    toolConfigurationSetup = () =>{
        
        let toolConfig = JSON.parse(localStorage.getItem('tlcfg'))
        
        var plant_wise_transporter_share_of_business = 0;

        if(toolConfig !=undefined && toolConfig !="")
        {
            if(toolConfig.length > 0)
            {
                plant_wise_transporter_share_of_business = toolConfig[0].transporter_share_of_business;
            }
    
            if(plant_wise_transporter_share_of_business == 1)
            {
                this.setState({zonesTab : "activet",
                            zonesData:true})
            }
            // else if(plant_wise_freight_vehicle_type_variable_cost == 1)
            // {
            //     this.setState({vehicleLevelCostTab : "activet",
            //                     vehicleLevelCostData:true})
            // }
            
        }
    }

    render(){
        const {t} = this.props
        const {location,locationNames,zoneMappingTab,zonesTab,zoneMappingData,zonesData}=this.state
        return(
            <div className="">
                <div className="row mb-5p pt-3 ml-1">
                    <div className="col-sm-12">
                        
                            <h5 className='fbold  d-flex justify-content-between'>
                                <span>{t('zone_configurations')}</span>
                            </h5>
                        
                    </div>
                </div> 
                
                <div className="w-full d-flex flex-column justify-content-start">
                    <div className="col-md-2 col-sm-12">
                        <div className="form-group ">
                            <label className="col-form-label f12">{t('pickup_point')}</label>
                            <Select
                                placeholder={"Select"}
                                onChange={this.locationChanged}
                                name="location"
                                value={location}
                                options={locationNames} />

                        </div>
                    </div>
                    <div className="col-md-12 col-sm-12 mt-3 pl-0 ml-2 mb-3" style={{ borderBottom: "1px solid #ccc" }}>
                        <ul className="d-tabs">
                            
                            <li onClick={this.onClickTab.bind(this,"zonesData")} className={"lttabs "+(zonesTab)}>
                                <button type="button" className="btn">{t('zones')}</button>
                            </li>
                            
                            <li onClick={this.onClickTab.bind(this,"zoneMappingData")} className={"lttabs "+(zoneMappingTab)}>
                                <button type="button" className="btn">{t('zone_mapping')}</button>
                            </li>

                        </ul>
                    </div>
                </div>
                <div className="row mb-20p">

                    
                </div>

                    {zoneMappingData ?  location != '' ? <ZoneMapping plantLocation={location}/> :'': ''}
                    {zonesData ? location != '' ? <Zones plantLocation={location}/> :'': ''}
            </div> 
        )
    }
}
export default withTranslation()(ZoneConfiguration)