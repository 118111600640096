import React,{Component} from "react";
import {AllModules} from '@ag-grid-enterprise/all-modules';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
import 'ag-grid-enterprise';
import Constant from "../common/constants";
import { withTranslation } from "react-i18next";
import Modal from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import redirectURL from '../redirectURL';
import Select from 'react-select';
import SweetAlert from 'react-bootstrap-sweetalert';
import GridButton from './gridButton';
import NAButton from "./naButton";
import DeletetxtButton from "./deletetxtbtn";
import getToolConfiguration from "./commonFunctions";
import { logFormChanges  } from "./auditLogfunction";


var moment = require("moment");

 class Vehiclecostcustomerspecific extends Component{
    constructor(props){
        super(props);
        this.state={
            modules : AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
                resizable:true,
                minWidth:100,
                initialWidth: 200,
                wrapHeaderText: true,
                autoHeaderHeight: true,
            },
            frameworkComponents:{
                GridButton:GridButton,
                NAButton:NAButton,
                DeletetxtButton
            },
            rowData:[],
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
			detailCellRendererParams:{},
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:100,
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            animateRows: true,
            openMaterialConfigurationModel:false,            
            show:false,
            basicTitle:"",
            basicType:"default",
            data_type:'',
            location:this.props.plantLocation,
            vehicle_type: '',
            vehicleTypeList: [],
            fixed_vehicle_cost:'',
            rowId:'',
            previousData:"",
            transporterList: [],
            transporter_name: '',
            validity_to:'',
            validity_from:'',
            cost_per_km_after_SOB:'',
            cost_per_km_below_SOB:'',
            start_date:moment.parseZone().subtract(1, "months").utcOffset("+05:30").format('YYYY-MM-DD'),
            end_date:moment.parseZone().add(1,"months").utcOffset("+05:30").format('YYYY-MM-DD'),
        }
        
    }

    async componentDidMount(){
        
        var curl = window.location.search;
        var ul= curl.split("?");
        // console.log("ul ",ul)
        // let url_type = window.location.href
        let url_type = ul[parseInt(ul.length)-1];
        // console.log("url_type ", url_type)
        // let path_type = url_type.split('/')
        let path_type = url_type
        let data_type
        // let path = path_type[path_type.length-1]
        let path =url_type

        if (path == 'inbound'){
            data_type = 1
        }else{
            data_type = 2
        }
        // const {plantLocation} = this.props
        await this.setState({
            data_type : data_type,
        // location:plantLocation
        })
        let params = {
            data_type : data_type,
            location_code: this.props.plantLocation.value
        }
        let toolparameters = {
            data_type : data_type,
            plant_code : this.props.plantLocation.value
        }
        await getToolConfiguration(toolparameters)
        await this.getVehiclesData(params)
        await this.getCustomerSpecificVehicleCostData(params)
        await this.getTransporterDetails(params)
    }
    componentWillReceiveProps(newprops)
    {
        if(newprops.plantLocation !== this.state.location)
        {
            var curl = window.location.search;
            var ul= curl.split("?");
            // console.log("ul ",ul)
            // let url_type = window.location.href
            let url_type = ul[parseInt(ul.length)-1];
            // console.log("url_type ", url_type)
            // let path_type = url_type.split('/')
            let path_type = url_type
            let data_type
            // let path = path_type[path_type.length-1]
            let path =url_type

            if (path == 'inbound'){
                data_type = 1
            }else{
                data_type = 2
            }
            // console.log("location ", newprops.plantLocation)
            this.setState({
                location:newprops.plantLocation
            })
            let params = {
                data_type : data_type,
                location_code: newprops.plantLocation.value
            }
            this.getVehiclesData(params)
            this.getTransporterDetails(params)
        }
    }

    getVehiclesData = (params) =>{
        let vehicleTypeList = []
        redirectURL.post('master/getVehiclesData',params)
        .then(async response =>{
            if(response.data.status == 'success'){
                let vehiclesData = response.data.vehiclesData
                if(vehiclesData.length > 0){
                    vehiclesData.map(item =>{
                        vehicleTypeList.push({ value : item.vehicle_type,
                            label : item.vehicle_type })
                    })
                    await this.setState({
                        vehicleTypeList : vehicleTypeList,
                        vehicle_type : vehicleTypeList[0]
                    })
                }
        }
        })
    }

    getCustomerSpecificVehicleCostData = (params) =>{
        params.validity_from = this.state.start_date;
        params.validity_to = this.state.end_date;
        redirectURL.post('master/getCustomerSpecificVehicleCostData',params)
        .then(response =>{
            if(response.data.status == 'success'){

                this.setState({
                    rowData:response.data.vehicleCostData
                })
            }
        })
    }


    //function names need to be updated
    openModelForMaterialConfiguration = () =>{
        this.setState(prevState =>({
            openMaterialConfigurationModel :!prevState.openMaterialConfigurationModel
        }))
    }

    handelMaterialConfigurationModelCancel = ()=>{
        this.setState(prevState =>({
            openMaterialConfigurationModel :!prevState.openMaterialConfigurationModel,
            vehicle_type: '',
            rowId:'',
            transporter_name: '',
            validity_to:'',
            validity_from:'',
            cost_per_km_after_SOB:'',
            cost_per_km_below_SOB:'',
            
        }))

    }

    closeAlert = async() => {
        await this.setState({
            show: false
        });
	}
    
    materialDetailsInputFields = (e) =>{
        let name = e.target.name
        let value = e.target.value
        this.setState({ [name] : value })
    }

    saveCustomerSpecificVehicleCostDetails = (params)=>{
        let data_type = this.state.data_type
        redirectURL.post('master/saveCustomerSpecificVehicleCostDetails',params)
        .then(response =>{
            if(response.data.status == 'success'){
                let param = {
                    data_type : data_type
                }
                this.getCustomerSpecificVehicleCostData(param)
                this.setState({
                        openMaterialConfigurationModel:false,
                        transporter_name: '',
                        validity_to:'',
                        validity_from:'',
                        cost_per_km_after_SOB:'',
                        cost_per_km_below_SOB:'',
                        vehicle_type: '',
                    })
            }else{
                this.setState({
                    show:true,
                    basicTitle:"Failed to save the data",
                    basicType:"danger"
                })
            }
        })
    }


    updateCustomerSpecificVehicleCostDetails = (params)=>{
        let data_type = this.state.data_type
        redirectURL.post('master/updateCustomerSpecificVehicleCostDetails',params)
        .then(response =>{
            if(response.data.status == 'success'){
                let param = {
                    data_type : data_type
                }
                let logParams = {
                    "user_name": localStorage.getItem("username"),
                    "useremail": localStorage.getItem("email"),
                    "client": localStorage.getItem("client"),
                    "screen": "Freight configurations - Vehicle Cost",
                    "location_code":this.state.location.value,
                    "activity":{}
                }
                let previousData = this.state.previousData
                logFormChanges(logParams,params,previousData)

                this.getCustomerSpecificVehicleCostData(param)
                this.setState({
                        openMaterialConfigurationModel:false,
                        transporter_name: '',
                        validity_to:'',
                        validity_from:'',
                        cost_per_km_after_SOB:'',
                        cost_per_km_below_SOB:'',
                        vehicle_type: '',
                        rowId:'',
                        previousData:""
                    })
            }else{
                this.setState({
                    show:true,
                    basicTitle:"Failed to save the data",
                    basicType:"danger"
                })
            }
        })
    }


    formMaterialDetails = (e)=>{
        e.preventDefault();
        let transporter_name = this.state.transporter_name;
        let validity_to = this.state.validity_to;
        let validity_from = this.state.validity_from;
        let cost_per_km_after_SOB = this.state.cost_per_km_after_SOB;
        let cost_per_km_below_SOB = this.state.cost_per_km_below_SOB;
        let vehicle_type = this.state.vehicle_type;
        let data_type = this.state.data_type;
        let location = this.state.location;
        let toolConfig = JSON.parse(localStorage.getItem('tlcfg'))
        let rowId = this.state.rowId;

        let params = {
            location_code : location.value, 
            vehicle_type : vehicle_type.value,
            transporter_name: transporter_name.label,
            transporter_code: transporter_name.value,
            cost_per_km_below_SOB:parseFloat(cost_per_km_below_SOB),
            cost_per_km_after_SOB:parseFloat(cost_per_km_after_SOB),
            validity_from:validity_from,
            validity_to:validity_to,
            data_type : data_type,
            location_name : location.label,
        }

        if(rowId != ''){

            params.vehicleSpecificCost_id = rowId
            
                this.updateCustomerSpecificVehicleCostDetails(params)
            

        }else{
            
            if(location.value != null && vehicle_type.value != null){
                if (this.state.validity_from > this.state.validity_to) {
                    this.setState({
                      show: true,
                      basicTitle: "Start Date should be less than End Date",
                      basicType: "danger",
                    });
                    // return;
                  }else{
                this.saveCustomerSpecificVehicleCostDetails(params)
                  }
            }else{
                if(vehicle_type.value == null){
                    this.setState({
                        show:true,
                        basicTitle:"Please Select Vehicle",
                        basicType:"danger"
                    })
                }else{
                this.setState({
                    show:true,
                    basicTitle:"Please add "+ Constant.MASTER_VEHICLE_DATA_CONFIGURATION_BOUND_LOCATION + " First",
                    basicType:"danger"
                })
                }
            }
        }
    }

    editMaterialConfiguration = async(propdata) =>{
        let data = propdata.data
        var previousData={
            "vehicle_type" : data.vehicle_type,  
            transporter_name: data.transporter_name,
            validity_to: data.validity_to,
            validity_from: data.validity_from,
            cost_per_km_after_SOB: data.cost_per_km_after_SOB,
            cost_per_km_below_SOB: data.cost_per_km_below_SOB,
        }
        await this.setState({
            "vehicle_type" : {label:data.vehicle_type,value:data.vehicle_type},
            transporter_name: {label:data.transporter_name,value:data.transporter_code},
            validity_to: moment.parseZone(data.validity_to).utcOffset("+05:30").format('YYYY-MM-DD'),
            validity_from: moment.parseZone(data.validity_from).utcOffset("+05:30").format('YYYY-MM-DD'),
            cost_per_km_after_SOB: data.cost_per_km_after_SOB,
            cost_per_km_below_SOB: data.cost_per_km_below_SOB,
            "rowId" : data.vehicleSpecificCost_id,
            previousData:previousData
        })
        this.openModelForMaterialConfiguration()
    }

    vehicleTypeChanged = (vehicle_type) =>{
        this.setState({
            vehicle_type : vehicle_type
        })
    }

    deleteCustomerSpecificVehicleCostDetails = (propData) =>{
        let data = propData.data
        let data_type = this.state.data_type
        
        var cnf = window.confirm("Do you want to proceed?");
        if (cnf) {
        let params = {
            delete_id : data._id
        }
        redirectURL.post('master/deleteCustomerSpecificVehicleCostDetails',params)
        .then(response =>{
            if(response.data.status == 'success'){
                let param = {
                    data_type : data_type
                }
                this.getCustomerSpecificVehicleCostData(param)
          }else{
            this.setState({
                show:true,
                basicTitle:"Failed to delete the data",
                basicType:"danger"
            })
          }
        })
    }
    }

    transporterChanged = (transporter_name) =>{
        this.setState({
            transporter_name : transporter_name
        })
    }

    getTransporterDetails = async(param) => {
        let transporterList = [];
        let duplicateTransporterList = [];
        await redirectURL.post('master/getTransporterDetails',param)
          .then(async (response) =>{
            // console.log("response.data ", response.data)
              if(response.data.status == 'success'){
                  let transporters = response.data.transporterDetailsData

                  if(transporters.length > 0){
                    transporters.map(item =>{
                        transporterList.push({ value : item.transporter_code, label : item.transporter_name })
                    })
                    // console.log(transporterList,"transporterList")
                    let toolConfig = JSON.parse(localStorage.getItem('tlcfg'))
                    let transporter_name = ''
                    // if(toolConfig[0].plant_wise_transporter_configuration == 0){
                    //   transporter_name = {label : 'LSP', value : 'LSP'};
                    // }                 
              await this.setState({
                    transporterList : transporterList,
                    transporter_name : transporter_name
                    })
                }else{
                    let toolConfig = JSON.parse(localStorage.getItem('tlcfg'))
                    if(toolConfig[0].plant_wise_transporter_configuration == 0){
                      this.setState({transporter_name : {label : 'LSP', value : 'LSP'}});
                    }else{
                        this.setState({
                            transporterList:[],
                            transporter_name : null })
                    }
                }
            }
          })
          .catch(function (error) {
            var errDetails={
                url:"master/getTransporterDetails",
                screen:'Freight Configurations',
              error,
              }
            redirectURL.post("/master/logErrorsForApiCalls",errDetails)
            
        })
          
      }

      onClickGetData = async () => {
        if (this.state.start_date > this.state.end_date) {
          this.setState({
            show: true,
            basicTitle: "Start Date should be less than End Date",
            basicType: "danger",
          });
          return;
        }
        let param = {
            data_type : this.state.data_type
        }
        await this.getCustomerSpecificVehicleCostData(param);
      };


    render(){
        const {t} = this.props
        // var colsDefs = [
        //     {
        //         headerName: this('vehilce_type'),
        //         field:"vehicle_type",
        //         width:"120",
        //         cellClass:function(params){
        //             try {
        //                 if(params.data.status != undefined ){
        //                   if(params.data.status == 0){
        //                     return "redbg";
        //                   }
        //                 }
        //             } catch (error) {
                        
        //             }
        //         }
        //     },
        //     {
        //         headerName:"Transporter",
        //         field:"transporter_name",
        //         width:"130",
        //         cellClass:function(params){
        //             try {
        //                 if(params.data.status != undefined ){
        //                   if(params.data.status == 0){
        //                     return "redbg";
        //                   }
        //                 }
        //             } catch (error) {
                        
        //             }
        //         }
        //     },
        //     {
        //         headerName:"Cost Per Miles (Below SOB)",
        //         field:"cost_per_km_below_SOB",
        //         width:"130",
        //         cellClass:function(params){
        //             try {
        //                 if(params.data.status != undefined ){
        //                   if(params.data.status == 0){
        //                     return "redbg";
        //                   }
        //                 }
        //             } catch (error) {
                        
        //             }
        //         }
        //     },
        //     {
        //         headerName:"Cost Per Miles (After SOB)",
        //         field:"cost_per_km_after_SOB",
        //         width:"130",
        //         cellClass:function(params){
        //             try {
        //                 if(params.data.status != undefined ){
        //                   if(params.data.status == 0){
        //                     return "redbg";
        //                   }
        //                 }
        //             } catch (error) {
                        
        //             }
        //         }
        //     },
        //     {
        //         headerName:"Validity From",
        //         field:"validity_from",
        //         width:"130",
        //         valueGetter:function(params){
        //             if(params.data.validity_from)
        //             {
        //                 return moment.parseZone(params.data.validity_from).format("YYYY-MM-DD")
        //             }
        //         },
        //         cellClass:function(params){
        //             try {
        //                 if(params.data.status != undefined ){
        //                   if(params.data.status == 0){
        //                     return "redbg";
        //                   }
        //                 }
        //             } catch (error) {
                        
        //             }
        //         }
        //     },
        //     {
        //         headerName:"Validity To",
        //         field:"validity_to",
        //         width:"130",
        //         valueGetter:function(params){
        //             if(params.data.validity_to)
        //             {
        //                 return moment.parseZone(params.data.validity_to).format("YYYY-MM-DD")
        //             }
        //         },
        //         cellClass:function(params){
        //             try {
        //                 if(params.data.status != undefined ){
        //                   if(params.data.status == 0){
        //                     return "redbg";
        //                   }
        //                 }
        //             } catch (error) {
                        
        //             }
        //         }
        //     },
        //     // {
        //     //     headerName:"Actions",
        //     //     field:"cluster",
        //     //     width:"80",
        //     //     pinned:'left',
        //     //     params:{buttonName:'Edit',
        //     //             onClickFunction:this.editMaterialConfiguration},
        //     //     cellRendererSelector:function(params){
        //     //         if(params.data.status != undefined){
        //     //             if(params.data.status == 0){
        //     //                 var rendComponent = {
        //     //                     component: "NAButton"
        //     //                 };
        //     //             }else{
        //     //                 var rendComponent = {
        //     //                     component: "GridButton"
        //     //                 };
        //     //             }
        //     //         }
                    
        //     //         return rendComponent
                
        //     //     },
        //     //     cellClass:function(params){
        //     //         try {
        //     //             if(params.data.status != undefined ){
        //     //               if(params.data.status == 0){
        //     //                 return "redbg";
        //     //               }
        //     //             }
        //     //         } catch (error) {
                        
        //     //         }
        //     //     }
        //     // },
        //     {
        //         headerName:"",
        //         field:"cluster",
        //         width:"80",
        //         pinned:'left',
        //         params:{buttonName:'Delete',iconName:"fa fa-trash",
        //                 onClickFunction:this.deleteCustomerSpecificVehicleCostDetails},
        //         cellRendererSelector:function(params){
                    
        //             if(params.data.status != undefined){
        //                 if(params.data.status == 0){
        //                     var rendComponent = {
        //                         component: "DeletetxtButton"
        //                     };
        //                 }else{
        //                     var rendComponent = {
        //                         component: "GridButton"
        //                     };
        //                 }
        //             }
        //             return rendComponent
                
        //         },
        //         cellClass:function(params){
        //             try {
        //                 if(params.data.status != undefined ){
        //                   if(params.data.status == 0){
        //                     return "redbg";
        //                   }
        //                 }
        //             } catch (error) {
                        
        //             }
        //         }
        //     },
        // ]

        var colsDefs = [
            {
                headerName: t('vehicle_type'),
                field: "vehicle_type",
                width: "120",
                cellClass: function (params) {
                    try {
                        if (params.data.status != undefined) {
                            if (params.data.status == 0) {
                                return "redbg";
                            }
                        }
                    } catch (error) {
        
                    }
                }
            },
            {
                headerName: t('transporter'),
                field: "transporter_name",
                width: "130",
                cellClass: function (params) {
                    try {
                        if (params.data.status != undefined) {
                            if (params.data.status == 0) {
                                return "redbg";
                            }
                        }
                    } catch (error) {
        
                    }
                }
            },
            {
                headerName: t('cost_per_mile_below_sob'),
                field: "cost_per_km_below_SOB",
                width: "130",
                cellClass: function (params) {
                    try {
                        if (params.data.status != undefined) {
                            if (params.data.status == 0) {
                                return "redbg";
                            }
                        }
                    } catch (error) {
        
                    }
                }
            },
            {
                headerName: t('cost_per_mile_after_sob'),
                field: "cost_per_km_after_SOB",
                width: "130",
                cellClass: function (params) {
                    try {
                        if (params.data.status != undefined) {
                            if (params.data.status == 0) {
                                return "redbg";
                            }
                        }
                    } catch (error) {
        
                    }
                }
            },
            {
                headerName: t('validity_from'),
                field: "validity_from",
                width: "130",
                valueGetter: function (params) {
                    if (params.data.validity_from) {
                        return moment.parseZone(params.data.validity_from).format("YYYY-MM-DD")
                    }
                },
                cellClass: function (params) {
                    try {
                        if (params.data.status != undefined) {
                            if (params.data.status == 0) {
                                return "redbg";
                            }
                        }
                    } catch (error) {
        
                    }
                }
            },
            {
                headerName: t('validity_to'),
                field: "validity_to",
                width: "130",
                valueGetter: function (params) {
                    if (params.data.validity_to) {
                        return moment.parseZone(params.data.validity_to).format("YYYY-MM-DD")
                    }
                },
                cellClass: function (params) {
                    try {
                        if (params.data.status != undefined) {
                            if (params.data.status == 0) {
                                return "redbg";
                            }
                        }
                    } catch (error) {
        
                    }
                }
            },
            {
                headerName: "",
                field: "cluster",
                width: "80",
                pinned: 'left',
                params: {
                    buttonName: t('delete'), iconName: "fa fa-trash",
                    onClickFunction: this.deleteCustomerSpecificVehicleCostDetails
                },
                cellRendererSelector: function (params) {
                    if (params.data.status != undefined) {
                        if (params.data.status == 0) {
                            var rendComponent = {
                                component: "DeletetxtButton"
                            };
                        } else {
                            var rendComponent = {
                                component: "GridButton"
                            };
                        }
                    }
                    return rendComponent
                },
                cellClass: function (params) {
                    try {
                        if (params.data.status != undefined) {
                            if (params.data.status == 0) {
                                return "redbg";
                            }
                        }
                    } catch (error) {
        
                    }
                }
            }
        ]
        


        let toolConfig = JSON.parse(localStorage.getItem('tlcfg'))
        let data = this.state.rowData
        let locValue = this.state.location.value
         
        let filteredData = data.filter(item => item.location_code == locValue)

        return(
            <>
            <SweetAlert
                show={this.state.show}
                type={this.state.basicType}
                title={this.state.basicTitle}
                onConfirm={this.closeAlert}>
            </SweetAlert>   
                
                <div class="row mb-20p pt-20p">
                <div className="col-sm-12">
                     

                    <h5 className='fbold  d-flex justify-content-between' style={{marginLeft : 10}}>
                            <span>{t('cost_details')}</span>
                                <button
                                    type="button"
                                    onClick={this.openModelForMaterialConfiguration}
                                    className="btn ml-10p"    
                                    style={{ backgroundColor: "#f0f0f0", color: "black", fontWeight: 600 }}                       
                                    >
                                     {t('add_vehicle_cost')}
                                </button>

                        </h5>
                                    
                        <div className='fbold d-flex ml-3 '>
                            {/* <span>Vehicle Cost</span> */}
                            <label className="col-form-label f12">{t('validity_from')} </label>
                            <div className="col-md-2 col-sm-12">
                            
                                <div className="form-group ">
                                    
                                    <input type="date" 
                                        className= "forminp form-control"
                                        required 
                                        name="start_date" 
                                        value={this.state.start_date}
                                        onChange={this.materialDetailsInputFields} 
                                        placeholder="" autoComplete="off"
                                        readonly
                                        onKeyDown={(e) => e.preventDefault()}
                                         />
                                                                          
                                </div>
                            </div>
                            <label className="col-form-label f12">{t('validity_to')} </label>
                            <div className="col-md-2 col-sm-12">
                            
                                <div className="form-group ">
                                    
                                    <input type="date" 
                                        className= "forminp form-control"
                                        required 
                                        name="end_date" 
                                        value={this.state.end_date}
                                        onChange={this.materialDetailsInputFields} 
                                        placeholder="" autoComplete="off"
                                        readonly
                                        onKeyDown={(e) => e.preventDefault()}
                                         />
                                                                          
                                </div>
                            </div>
                            <div className="col-md-2">
                                <button
                                type="button"
                                onClick={this.onClickGetData}
                                className="btn btn-warning"
                                >
                                {t('get_data')}
                                </button>
                            </div>
                                {/* <div className='col d-flex justify-content-end'>
                                    <a href="javascript:;" onClick={this.openModelForMaterialConfiguration} className="f18 btn btn-default float-right themefont fbold mr-10p mb-10p">hello</a>
                                </div>  */}

                        </div>
                                                   
                        <div id="myGrid" style={{width:"100%",height:"70vh"}} className="col-sm-12 ag-theme-balham dropdown">
                        <AgGridReact
                            modules={this.state.modules} 
                            rowData={filteredData}
                            columnDefs={colsDefs}
                            gridOptions={{context:{componentParent:this}}}
                            defaultColDef={this.state.defaultColDef}
                            frameworkComponents={this.state.frameworkComponents}
                            paginationPageSize={this.state.paginationPageSize}
                            pagination={true}
                            statusBar={this.state.statusBar}
						    sideBar={this.state.sideBar}
						    enableRangeSelection={true}
                        />
                        </div>
                    </div>
                </div>

                <Modal closeOnOverlayClick={false} open={this.state.openMaterialConfigurationModel} onClose={this.handelMaterialConfigurationModelCancel} 
                    classNames={{modal: 'pickupdrop-modelClass'}} >
                        <div className="col-sm-12">
                        <div>
                            <h4>{t('cost_details')}</h4>
                        </div>
                    <form className="theme-form" onSubmit={this.formMaterialDetails}>

                        <div className="row number">
                        <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{t('pickup_point')}</label>
                                    <Select
                                        isDisabled
                                        name="location"
                                        value={this.state.location}
                                         />
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{t('vehicle_type')}</label>
                                    <Select
                                        placeholder={"Select"}
                                        onChange={this.vehicleTypeChanged}
                                        name="vehicle_type"
                                        value={this.state.vehicle_type}
                                        options={this.state.vehicleTypeList} 
                                        />

                                </div>
                            </div>

                            <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{t('transporter_name')}e</label>
                                    {/* <input type="text" className="forminp form-control" name="transporter_name" value={this.state.transporter_name} 
                                    onChange={this.vehicleConfigurationInputFields} autoComplete='off' 
                                    /> */}
                                    <Select
                                    required
                                    placeholder={"Select"}
                                    onChange={this.transporterChanged}
                                    name="transporter_name"
                                    value={this.state.transporter_name}
                                    options={this.state.transporterList}
                                    />
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{t('cost_per_mile_below_sob')} </label>
                                    <input required type="number" min='0' step={"0.01"}  className="forminp form-control" id="cost_per_km_below_SOB" name="cost_per_km_below_SOB" 
                                    value={this.state.cost_per_km_below_SOB} 
                                    onChange={this.materialDetailsInputFields}
                                     placeholder="" autoComplete="off" />
                                    
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{t('cost_per_mile_after_sob')} </label>
                                    <input required type="number" min='0' step={"0.01"}  className="forminp form-control" id="cost_per_km_after_SOB" name="cost_per_km_after_SOB" 
                                    value={this.state.cost_per_km_after_SOB}
                                    onChange={this.materialDetailsInputFields}
                                    placeholder="" autoComplete="off" />
                                    
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{t('validity_from')} </label>
                                    <input type="date" 
                                        className= "forminp form-control"
                                        required 
                                        name="validity_from" 
                                        value={this.state.validity_from}
                                        onChange={this.materialDetailsInputFields} 
                                        placeholder="" autoComplete="off"
                                        readonly
                                        onKeyDown={(e) => e.preventDefault()}
                                         />
                                                                          
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{t('validity_to')} </label>
                                    <input type="date" 
                                        className= "forminp form-control"
                                        required 
                                        name="validity_to" 
                                        value={this.state.validity_to}
                                        onChange={this.materialDetailsInputFields} 
                                        placeholder="" autoComplete="off"
                                        readonly
                                        onKeyDown={(e) => e.preventDefault()}
                                         />
                                                                          
                                </div>
                            </div>

                           

                        </div>
                        <div className="form-group">
                            <button type="submit" className="btn tbtn float-right save-btn" id="saveGeo">{t('save')}</button>
                            <button type='button' className="btn tbtn float-right cancel-btn" onClick={this.handelMaterialConfigurationModelCancel}>{t('cancel')}</button>	
                        </div>
                    </form>
                </div>
                    </Modal>
            </>
        )
    }
}

export default withTranslation()(Vehiclecostcustomerspecific)