import React, { Component } from "react";
import Constant from "../../common/constants";
import Select from "react-select";
import redirectURL from "../../redirectURL";
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-balham.css"; // Optional theme CSS
import "ag-grid-enterprise";
import SweetAlert from 'react-bootstrap-sweetalert';
import BarChart from "./BarChart";
import CountUp from 'react-countup';

var moment = require("moment");

const data = [{ tot: 1, spot: 2, reg: 3 }];

export default class DailySummaryCharts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locationNames: [],
      location: "",
      invoicedate: moment.parseZone().utcOffset("+05:30").format("YYYY-MM-DD"),
      dailychartsTab: "activet",
      dailydataTab: "",
      dailychartsData: true,
      dailyData: false,
      rowdata: [],
      chartData: [],
      show: false,
			basicTitle: '',
			basicType: "default",
			loadshow: 'show-n',
			overly: 'show-n',
      overlyRing:'show-n',
      TplannedPercentage: 0,
      Ttripcost: 0,
      TassignedTripCount: 0,
      TassignedVFR: 0,
      TplanTripCount: 0,
      TplannedVFR: 0,
      TassignedTripDistance: 0,
      TplannedTripDistance: 0
    };
  }

  async componentDidMount() {
    let url_type = window.location.href;
    // console.log(url_type,"url_type")
    let path_type = url_type.split("?");
    // console.log(path_type,"path_type")
    let data_type;
    let path = path_type[path_type.length - 1];
    if (path == "inbound") {
      data_type = 1;
    } else {
      data_type = 2;
    }
    let params = {
      data_type: data_type,
    };
    await this.boundTypeLocations(params);
    await this.getDataForNewDashboard();
  }
  getDataForNewDashboard() {
    redirectURL
      .post("dispatch/getDataForNewDashboard", {
        // invoice_date: "2024-07-08",
        location: this.state.location.value,
        invoice_date: this.state.invoicedate,
      })
      .then((response) => {
        console.log("first", response.data);
        this.setState({ chartData: response.data });
      });
  }
  getoutputDetailsForGrid() {
    let gridParams = {
      createdDate: this.state.invoicedate
    }
    if(this.state.location.value !== "All"){
      gridParams.location = this.state.location.value
    }
    console.log("gridParams", gridParams);
    redirectURL
      .post("dispatch/getoutputDetailsForGrid", gridParams)
      .then((response) => {
        console.log("grid data", response.data);
        if(response.data.length === 0){
          this.setState({
            basicTitle: "No rows to show",
            basicType: "danger",
            show: true,
            rowdata : response.data
          })
        }else {
          this.setState({
            rowdata: response.data
          })
        }
      });
  }
  boundTypeLocations = async (param) => {
    let location_access = JSON.parse(localStorage.getItem("location_access"));
    let locationNames = [{label:"All", value:"All"}];

    await redirectURL
      .post("master/getBoundTypeLocations", param)
      .then(async (response) => {
        if (response.data.status == "success") {
          let boundLocations = response.data.boundLocations;
          // locationNames.push({ value : "",
          //     label : "Select" })
          if (boundLocations.length > 0) {
            boundLocations.map((item) => {
              if (location_access != null) {
                if (location_access.includes(item.location_code)) {
                  locationNames.push({
                    value: item.location_code,
                    label: item.location_name,
                  });
                }
              } else {
                locationNames.push({
                  value: item.location_code,
                  label: item.location_name,
                });
              }
            });

            if (locationNames.length > 0) {
              await this.setState({
                locationNames: locationNames,
                location: locationNames[0],
              });
            } else {
              await this.setState({
                locationNames: [{ label: "Please Add Data", value: "no_data" }],
                location: [{ label: "Please Add Data", value: "no_data" }],
              });
            }
          }
        }
      })
      .catch(function (error) {
        var errDetails = {
          url: "master/getBoundTypeLocations",
          screen: "Summary Dashboard",
          error,
        };
        redirectURL.post("/master/logErrorsForApiCalls", errDetails);
      });
  };

  locationChanged = async (location) => {
    await this.setState({
      location: location,
    });
  };
  getCurrentDateTime() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0"); // January is 0
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  materialDetailsInputFields = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState({ [name]: value });
  };

  onClickGetData = async () => {
    if(this.state.dailyData === true){
      await this.getoutputDetailsForGrid();
    }else {
      await this.getDataForNewDashboard();
    }
  };
  onClickHideAll() {
		this.setState({
			loadshow: 'show-n',
			overly: 'show-n',
		});

	}

	hideSlideBlock = () =>{
        this.setState({
            overlyRing:'show-n',
            overly: 'show-n',
            overlayBlock:'show-n'
        })
    }

	closeAlert = () => {
		this.setState({
			show: false,
			loadshow: 'show-n',
			overlyRing:'show-n',
		});
		// window.location.reload()
	}


  onClickTab = async (pageType) => {
    var tabName = pageType;
    if (pageType == "dailychartsData") {
      var dailychartsTab = "activet";
      var dailydataTab = "";
      var dailychartsData = true;
      var dailyData = false;
      await this.getDataForNewDashboard();

    } else if (pageType == "dailyData") {
      var dailychartsTab = "";
      var dailydataTab = "activet";
      var dailychartsData = false;
      var dailyData = true;
      await this.getoutputDetailsForGrid();
    }
    this.setState({
      dailychartsTab: dailychartsTab,
      dailydataTab: dailydataTab,

      dailychartsData: dailychartsData,
      dailyData: dailyData,
    });
    let logParams = {
      location_code: this.state.location.value,
      location_name: this.state.location.label,
      user_name: localStorage.getItem("username"),
      useremail: localStorage.getItem("email"),
      client: localStorage.getItem("client"),
      screen: "Summary charts screen ",
      activity: `Clicked on  ${tabName} tab`,
      event: `Navigated to ${tabName} screen `,
      data_type: this.state.data_type,
    };
    redirectURL.post("/master/loguserUpdatesorChanges", logParams);
  };

  render() {
    const { plantLocation } = this.props;

    var { chartData } = this.state;
    var categories = [];
    var plandTripDist = { dedicated: [], spot: [], total: [] },
      asgndTripDist = { dedicated: [], spot: [], total: [] },
      plndVfr = { dedicated: [], spot: [], total: [] },
      plndTripCount = { dedicated: [], spot: [], total: [] },
      tripCost = { dedicated: [], spot: [], total: [] },
      plangPercentage = { dedicated: [], spot: [], total: [] },
      asgndTripVfr = { dedicated: [], spot: [], total: [] },
      assgnTripCount = { dedicated: [], spot: [], total: [] };
      let TplanTripCount = 0;
      let TassignedTripCount = 0;
      let TplannedVFR = 0;
      let TassignedVFR = 0;
      let TplannedTripDistance = 0;
      let TassignedTripDistance = 0;
      let TplannedPercentage = 0;
      let Ttripcost = 0;
    if (chartData.length > 0) {
      console.log(chartData,"chartDataa")
      chartData.map((item) => {
        var plantName = Object.keys(item)[0];
        categories.push(plantName);
        // console.log("first", Object.keys(item));
        plandTripDist.dedicated.push(
          item[plantName].planned_trip_distance.dedicated_distance
        );
        plandTripDist.spot.push(
          item[plantName].planned_trip_distance.spot_planned_distance
        );


        plandTripDist.total.push(
          item[plantName].planned_trip_distance.total_distance
        );
        TplannedTripDistance += item[plantName].planned_trip_distance.total_distance;


        assgnTripCount.dedicated.push(
          item[plantName].assigned_trip_count.dedicated_count
        );
        assgnTripCount.spot.push(
          item[plantName].assigned_trip_count.spot_count
        );



        assgnTripCount.total.push(
          item[plantName].assigned_trip_count.overall_count
        );
        TassignedTripDistance += item[plantName].assigned_trip_count.overall_count;



        plndTripCount.dedicated.push(
          item[plantName].planned_trips_count.dedicated_count
        );
        plndTripCount.spot.push(item[plantName].planned_trips_count.spot_count);


        plndTripCount.total.push(
          item[plantName].planned_trips_count.overall_count
        );
        TplanTripCount += item[plantName].planned_trips_count.overall_count;


        asgndTripDist.dedicated.push(
          item[plantName].assigned_trip_distance.dedicated_distance
        );
        asgndTripDist.spot.push(
          item[plantName].assigned_trip_distance.spot_distance
        );


        asgndTripDist.total.push(
          item[plantName].assigned_trip_distance.total_distance
        );
        TassignedTripDistance += item[plantName].assigned_trip_distance.total_distance;


        asgndTripVfr.dedicated.push(item[plantName].assigned_vfr.dedicated_vfr);
        asgndTripVfr.spot.push(item[plantName].assigned_vfr.spot_vrf);

        asgndTripVfr.total.push(item[plantName].assigned_vfr.overal_vfr);
        TassignedVFR += item[plantName].assigned_vfr.overal_vfr;


        plndVfr.dedicated.push(item[plantName].planned_vfr.dedicated_vfr);
        plndVfr.spot.push(item[plantName].planned_vfr.spot_vfr);

        plndVfr.total.push(item[plantName].planned_vfr.overal_vfr);
        TplannedVFR += item[plantName].planned_vfr.overal_vfr;


        tripCost.dedicated.push(item[plantName].trip_cost.dedicated_cost);
        tripCost.spot.push(item[plantName].trip_cost.spot_cost);

        tripCost.total.push(item[plantName].trip_cost.overall_cost);
        Ttripcost += item[plantName].trip_cost.overall_cost;

        plangPercentage.dedicated.push(
          parseFloat(item[plantName].planning_percentage.dedicated_planning_percentage.toFixed(2))
        );
        plangPercentage.spot.push(
          parseFloat(item[plantName].planning_percentage.spot_planning_percentaeg.toFixed(2))
        );


        plangPercentage.total.push(
          parseFloat(item[plantName].planning_percentage.overall_planning_percentage.toFixed(2))
        );
        TplannedPercentage += item[plantName].planning_percentage.overall_planning_percentage;
        return true;
      });
    }
    
    var plandTripDistSeries = [
      { name: "Total", data: plandTripDist.total },
      { name: "Dedicated", data: plandTripDist.dedicated },
      { name: "Spot", data: plandTripDist.spot },
    ];

    var plangPercentageSeries = [
      // { name: "Total", data: plangPercentage.total },
      { name: "Dedicated", data: plangPercentage.dedicated },
      { name: "Spot", data: plangPercentage.spot },
    ];

    var tripCostSeries = [
      { name: "Total", data: tripCost.total },
      { name: "Dedicated", data: tripCost.dedicated },
      { name: "Spot", data: tripCost.spot },
    ];

    var asgnddTripCountSeries = [
      { name: "Total", data: assgnTripCount.total },
      { name: "Dedicated", data: assgnTripCount.dedicated },
      { name: "Spot", data: assgnTripCount.spot },
    ];

    var plndTripCountSerires = [
      { name: "Total", data: plndTripCount.total },
      { name: "Dedicated", data: plndTripCount.dedicated },
      { name: "Spot", data: plndTripCount.spot },
    ];

    var plndVfrSeries = [
      { name: "Total", data: plndVfr.total },
      { name: "Dedicated", data: plndVfr.dedicated },
      { name: "Spot", data: plndVfr.spot },
    ];

    var asgndTripDistSeries = [
      { name: "Total", data: asgndTripDist.total },
      { name: "Dedicated", data: asgndTripDist.dedicated },
      { name: "Spot", data: asgndTripDist.spot },
    ];

    var asgndVfrSeries = [
      { name: "Total", data: asgndTripVfr.total },
      { name: "Dedicated", data: asgndTripVfr.dedicated },
      { name: "Spot", data: asgndTripVfr.spot },
    ];
    // console.log("first", categories, plandTripDist);
    var planSummarycolsDefs = [
      {
        headerName: "Trip No",
        field: "trip_no",
        width: "90",
      },
      {
        headerName: "Planned Day",
        headerTooltip: "Planned Day",
        field: "drop_node_total_time_day",
        width: "160",
        valueGetter: function (params) {
          try {
            if (
              params.data.drop_node_total_time_day !== "" &&
              params.data.drop_node_total_time_day !== undefined
            ) {
              var a = parseInt(params.data.drop_node_total_time_day);
              return "Day - " + (a + 1);
            } else {
              return params.data.drop_node_total_time_day;
            }
          } catch (error) {}
        },
      },
      {
        headerName: "Vehicle No",
        field: "vehicle_no",
        width: "120",
        cellRendererSelector: function (params) {
          try {
            var rendComponent = {
              component: "RepeatIcon",
            };
            return rendComponent;
          } catch (error) {}
        },
      },
      {
        headerName: "Vehicle Type",
        field: "vehicle_type",
        width: "150",
      },
      {
        headerName: "Cost ($)",
        field: "cost",
        width: "100",
        //   hide:showCost
      },
      {
        headerName: "Drop Sequence",
        headerTooltip: "Drop Sequence",
        field: "drop_sequence",
        width: "160",
      },

      {
        headerName: "Order Number",
        headerTooltip: "Order Number",
        field: "order_number",
        width: "160",
      },
      {
        headerName: "Invoicing Date",
        headerTooltip: "Invoicing Date",
        field: "invoicing_date",
        width: "170",
      },
      // {
      //     headerName:"Transaporter Code",
      //     field:"transporter_code",
      //     width:"150"
      // },
      // {
      //     headerName:"Transaporter Name",
      //     field:"transporter_name",
      //     width:"150"
      // },
      // {
      //     headerName:"Route Mode",
      //     field:"route_mode",
      //     width:"120"
      // },
      {
        headerName: "Pick Up Location (Source)",
        field: "pickup_node",
        width: "200",
      },
      {
        headerName: "Drop Location Code (Destination)",
        headerTooltip: "Drop Location Code (Destination)",
        field: "drop_node",
        width: "230",
      },
      {
        headerName: "Drop Location Name (Destination)",
        headerTooltip: "Drop Location Name (Destination)",
        field: "drop_node_name",
        width: "250",
      },
      {
        headerName: "Destination Location City",
        headerTooltip: "Destination Location City",
        field: "demand_node_location",
        width: "240",
      },
      {
        headerName: "Destination Location State",
        headerTooltip: "Destination Location State",
        field: "demand_node_state",
        width: "250",
      },
      // {
      //     headerName:"Vehicle Max Volume(M³)",
      //     field:"vehicle_max_volume",
      //     width:160
      // },
      // {
      //     headerName:"Vehicle Max Weight(Pounds)",
      //     field:"vehicle_max_weight",
      //     width:160
      // },
      {
        headerName: "Quantity",
        headerTooltip: "Quantity",
        field: "quantity",
        width: "120",
        valueGetter: (params) => {
          try {
            if (
              params.data.quantity !== undefined &&
              params.data.quantity !== ""
            ) {
              return params.data.quantity.toFixed(2);
            } else {
              return "N/A";
            }
          } catch {}
        },
      },
      {
        headerName: "Volume (CFT)",
        headerTooltip: "Volume (CFT)",
        field: "demand_volume",
        width: "140",
      },
      {
        headerName: "Weight (Pounds)",
        headerTooltip: "Weight (Pounds)",
        field: "demand_weight",
        width: "120",
      },
      // {
      //     headerName:"Distance From Pickup Location (Kms)",
      //     headerTooltip:"Distance From Pickup Location (Kms)",
      //     field:"distance",
      //     width:"200",
      // },

      {
        headerName: "Distance To Destination (Miles)",
        headerTooltip: "Distance To Destination (Miles)",
        field: "distance",
        width: "240",
        valueGetter: (params) => {
          if (params.data.distance != "" && params.data.distance != undefined && params.data.distance !== null) {
              return (params.data.distance*0.621).toFixed(0);
          }
        },
      },
      {
        headerName: "Expected Delivery Date",
        field: "delivery_date",
        width: "180",
      },
      // {
      //     headerName:"Time From Pickup Location (HH:MM)",
      //     headerTooltip:"Time From Pickup Location (HH:MM)",
      //     field:"drop_node_total_time_hrs",
      //     width:"200",
      // },
      {
        headerName: "Time To Destination",
        headerTooltip: "Time To Destination",
        field: "drop_node_total_time_hrs",
        width: "150",
      },
      {
        headerName: "Transit Time (HH:MM)",
        headerTooltip: "Transit Time (HH:MM)",
        field: "drop_node_transit_time_hrs",
        width: "150",
      },
      {
        headerName: "Loading Time (HH:MM)",
        headerTooltip: "Loading Time (HH:MM)",
        field: "drop_node_loading_time_mins",
        width: "150",
      },
      {
        headerName: "Unloading Time (HH:MM)",
        headerTooltip: "Unloading Time (HH:MM)",
        field: "drop_node_unloading_time_mins",
        width: "170",
      },
      // {
      //     headerName:"Distance From Previous Location (Kms)",
      //     field:"distance_from_prev_location",
      //     width:"200",
      // },
      // {
      //     headerName:"Time From Previous Location (HH:MM)",
      //     field:"time_from_prev_location",
      //     width:"200",
      // },
      // {
      //     headerName:"Drop Location City",
      //     field:"demand_node_location",
      //     width:"120",
      // },
      // {
      //     headerName:"Zone",
      //     field:"zone",
      //     width:"100"
      // },
      // {
      //     headerName:"Drop Location (Destination)",
      //     field:"drop_node",
      //     width:"150"
      // },
      // {
      //     headerName:"Vehicle Volume",
      //     field:"vehicle_volume",
      //     width:"120"
      // },
      // {
      //     headerName:"Vehicle Priority",
      //     field:"vehicle_priority",
      //     width:"120"
      // },
    ];

    return (
      <>
      
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					onConfirm={this.closeAlert}
				>
				</SweetAlert>
        <div className="row">
          <div className="col-sm-2 d-flex d-inline mtop-30p align-items-center">
            <ul className="d-tabs">
              <li
                onClick={this.onClickTab.bind(this, "dailychartsData")}
                className={"lttabs " + this.state.dailychartsTab}
              >
                <button type="button" className="btn mt-30p">
                  Summary{" "}
                </button>
              </li>

              <li
                onClick={this.onClickTab.bind(this, "dailyData")}
                className={"lttabs " + this.state.dailydataTab}
              >
                <button type="button" className="btn">
                  Data
                </button>
              </li>
            </ul>
          </div>
          <div className="col-md-2">
            <div className="form-group ">
              <label className="col-form-label f12">
                {Constant.MASTER_VEHICLE_DATA_CONFIGURATION_BOUND_LOCATION}
              </label>
              <Select
                placeholder={"Select"}
                onChange={this.locationChanged}
                name="location"
                value={this.state.location}
                options={this.state.locationNames}
              />
            </div>
          </div>
          {/* <div className="col-md-2">
                    <div className="form-group ">
                        <label className="col-form-label f12">{Constant.DISPATCH_PLAN_DATA_CONFIGURATION_SELECTE_DATE}</label>
                        <input type="date" className="form-control" name="selectDate" id="selectDate" autoComplete='off' 
                        onKeyDown={(e) => e.preventDefault()}  
                        max={this.getCurrentDateTime()} 
                        readonly />
                    </div>
                </div> */}
          <div className="col-md-2">
            <div className="form-group ">
              <label className="col-form-label f12">Date Created</label>
              <input
                type="date"
                className="form-control"
                autoComplete="off"
                name="invoicedate"
                value={this.state.invoicedate}
                onChange={this.materialDetailsInputFields}
                onKeyDown={(e) => e.preventDefault()}
                max={this.getCurrentDateTime()}
                readonly
              />
            </div>
          </div>
          <div className="col-md-2">
            <button
              type="button"
              onClick={this.onClickGetData}
              className="btn btn-warning mt-30px"
              style={{ marginTop: "35px" }}
            >
              Get Data
            </button>
          </div>
        </div>
        {
          this.state.dailychartsData === true ? 
          
        <div className="row">
        <div className="row counters-row">

        <div className="counter-column">
            <div className="o-hidden card inner-con">
                <div className={"bg b-r-4 card-body p-3 "}>
                    <div className="media static-top-widget d-flex">
                        <div className="align-self-center text-center">
                            <i className={`icofont icofont-truck f32 blackfont`}></i>
                        </div>
                        <div className="media-body" style={{ padding:"10px"}}>
                            <h4 className="counter mb-0 f28 d-flex justify-content-center" style={{ fontFamily: "Poppins-Regular" }}>
                                <CountUp end={(TplanTripCount != '') ? TplanTripCount : 0} decimals={2} />
                            </h4>
                            <span className="m-0 d-flex justify-content-center" style={{minHeight: "40px"}}>Total Planned Trip</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="counter-column">
            <div class="o-hidden card inner-con">
                <div class={"bg b-r-4 card-body p-3 "}>
                    <div class="media static-top-widget d-flex">
                        <div class="align-self-center text-center">
                            <i className={"icofont icofont-clock-time f32 "}></i>
                        </div>
                        <div class="media-body" style={{ padding:"10px"}}>
                                <h4 class="counter mb-0 f28 d-flex justify-content-center" style={{ fontFamily: "Poppins-Regular" }}><span>
                                    <CountUp end={(TassignedTripCount != '') ? TassignedTripCount : 0} decimals={2}/>
                                </span></h4>
                                <span class="ml-3 d-flex justify-content-center" style={{minWidth:"100px", minHeight: "40px"}}>Total Assigned Trip</span>
                        </div>
                </div>
                </div>
            </div>
        </div>

        <div className="counter-column">
            <div class="o-hidden card inner-con">
                <div class={"bg b-r-4 card-body p-3 "}>
                    <div class="media static-top-widget d-flex">
                        <div class="align-self-center text-center">
                            <i className={"icofont icofont-clock-time f32 "}></i>
                        </div>
                        <div class="media-body" style={{ padding:"10px" }}>
                            <h4 class="counter mb-0 f28 d-flex justify-content-center" style={{ fontFamily: "Poppins-Regular" }}><span>
                                <CountUp end={(TplannedVFR != '') ? TplannedVFR : 0} decimals={2}/>
                            </span></h4>
                            <span class="ml-1 d-flex justify-content-center" style={{textAlign:"center",minWidth:"120px", minHeight: "40px"}}>Total Planned VFR%</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="counter-column">
            <div class="o-hidden card inner-con">
                <div class={"bg b-r-4 card-body p-3 "}>
                    <div class="media static-top-widget d-flex">
                        <div class="align-self-center text-center">
                            <i className={"icofont icofont-clock-time f32 "}></i>
                        </div>
                        <div class="media-body" style={{ padding:"10px"}}>
                            <h4 class="counter mb-0 f28 d-flex justify-content-center" style={{ fontFamily: "Poppins-Regular" }}><span>
                                <CountUp end={(TassignedVFR != '') ? TassignedVFR : 0} decimals={2} />
                            </span></h4>
                            <span class="m-0 d-flex justify-content-center" style={{minHeight: "40px"}}>Total Assigned VFR%</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="counter-column">

            <div class="o-hidden card inner-con">
                <div class={"bg b-r-4 card-body p-3 "}>
                    <div class="media static-top-widget d-flex">
                        <div class="align-self-center text-center">
                            <i className={"icofont icofont-pause f32 "}></i>
                        </div>
                        <div class="media-body" style={{ padding:"10px" }}>
                            <h4 class="counter mb-0 f28 d-flex justify-content-center" style={{ fontFamily: "Poppins-Regular" }}><span>
                                <CountUp end={TplannedTripDistance ? TplannedTripDistance : 0} decimals={2}/>
                            </span></h4>
                            <span class="m-0 d-flex justify-content-center" style={{minHeight: "40px"}}>{`Total Planned Trip (kms)`}</span>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="counter-column">

            <div class="o-hidden card inner-con">
                <div class={"bg b-r-4 card-body p-3 "}>
                    <div class="media static-top-widget d-flex">
                        <div class="align-self-center text-center">
                            <i className={"icofont icofont-pause f32 "}></i>
                        </div>
                        <div class="media-body" style={{ padding:"10px" }}>
                            <h4 class="counter mb-0 f28 d-flex justify-content-center" style={{ fontFamily: "Poppins-Regular" }}><span>
                                <CountUp end={(TassignedTripDistance != '') ? TassignedTripDistance : 0} decimals={2}/>
                            </span></h4>
                            <span class="m-0 d-flex justify-content-center" style={{minHeight: "40px"}}>{`Total Assigned Trip (kms)`}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="counter-column">
            <div class="o-hidden card inner-con">
                <div class={"bg b-r-4 card-body p-3 "}>
                    <div class="media static-top-widget d-flex">
                        <div class="align-self-center text-center">
                            <i className={"icofont icofont-pause f32 "}></i>
                        </div>
                        <div class="media-body" style={{ padding:"10px" }}>
                            <h4 class="counter mb-0 f28 d-flex justify-content-center" style={{ fontFamily: "Poppins-Regular" }}><span>
                                <CountUp end={(TplannedPercentage != '') ? TplannedPercentage : 0} decimals={2}/>
                            </span></h4>
                            <span class="ml-1 d-flex justify-content-center" style={{textAlign:"center",minWidth:"120px", minHeight: "40px"}}>Total Planned Percentage %</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="counter-column">
            <div class="o-hidden card inner-con">
                <div class={"bg b-r-4 card-body p-3 "}>
                    <div class="media static-top-widget d-flex">
                        <div class="align-self-center text-center">
                            <i className={"icofont icofont-pause f32 "}></i>
                        </div>
                        <div class="media-body" style={{ padding:"10px" }}>
                            <h4 class="counter mb-0 f28 d-flex justify-content-center" style={{ fontFamily: "Poppins-Regular" }}><span>
                                <CountUp end={(Ttripcost != '') ? Ttripcost : 0} decimals={2}/>
                            </span></h4>
                            <span class="ml-1 d-flex justify-content-center" style={{textAlign:"center",minWidth:"120px", minHeight: "40px"}}>Total Trip Cost</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* Repeat for other counters */}
        </div>
      </div>
      : ""
        }
        <div
          className={
            "row mt-5p " + (this.state.dailyData === true ? "show-m" : "show-n")
          }
        >
          <div
            id="myGrid1"
            style={{ width: "100%", height: "70vh" }}
            className="col-sm-12 ag-theme-balham dropdown"
          >
            <AgGridReact
              // modules={this.state.modules}
              rowData={this.state.rowdata}
              columnDefs={planSummarycolsDefs}
              gridOptions={{ context: { componentParent: this } }}
              defaultColDef={this.state.defaultColDef}
              frameworkComponents={this.state.frameworkComponents}
              paginationPageSize={this.state.paginationPageSize}
              pagination={true}
              getRowStyle={this.getRowStyle}
              enableRangeSelection={true}
              sideBar={this.state.sideBar}
              statusBar={this.state.statusBar}
              enablePivot={true}
              enableCharts={true}
            />
          </div>
        </div>

        {
          this.state.dailychartsTab ? 
          <>
            <div className={"row mt-5" + ""}>
              <div className="col-md-6">
                <BarChart
                  categories={categories}
                  series={plndTripCountSerires}
                  title={"Planned Trip Count"}
                  yaxis={"Trip Count"}
                />
              </div>
              <div className="col-md-6">
                <BarChart
                  categories={categories}
                  series={asgnddTripCountSeries}
                  title={"Assigned Trip Count"}
                  yaxis={"Trip Count"}
                />
              </div>
            </div>

            <div className={"row mt-5" + ""}>
              <div className="col-md-6">
                <BarChart
                  categories={categories}
                  series={plndVfrSeries}
                  title={"Planned VFR%"}
                  yaxis={"VFR%"}
                />
              </div>
              <div className="col-md-6">
                <BarChart
                  categories={categories}
                  series={asgndVfrSeries}
                  title={"Assigned VFR%"}
                  yaxis={"VFR%"}
                />
              </div>
            </div>
            <div className={"row mt-5" + ""}>
              <div className="col-md-6">
                <BarChart
                  categories={categories}
                  series={plandTripDistSeries}
                  title={"Planned Trip Distance"}
                  suffix={"Miles"}
                  yaxis={"Trip Distance (Miles)"}
                />
              </div>
              <div className="col-md-6">
                <BarChart
                  categories={categories}
                  series={asgndTripDistSeries}
                  title={"Assigned Trip Distance "}
                  suffix={"Miles"}
                  yaxis={"Trip Distance (Miles)"}
                />
              </div>
            </div>

            <div className={"row mt-5" + ""}>
              <div className="col-md-6">
                <BarChart
                  categories={categories}
                  series={plangPercentageSeries}
                  title={"Planned Percentage %"}
                  yaxis={"Planning Percentage%"}
                />
              </div>
              <div className="col-md-6">
                <BarChart
                  categories={categories}
                  series={tripCostSeries}
                  title={"Trip Cost"}
                  yaxis={"Trip Cost (Rs)"}
                />
              </div>
            </div>
          </>
          : ""
        }
        <div className={"dataLoadpage " + (this.state.loadshow)}>
				</div>
				<div className={"dataLoadpageimg " + (this.state.loadshow)}>
					<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
				</div>
				<div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
				<div className={"slide-r " + (this.state.slideBar)} >
					{this.displayData}
				</div>

                <div className={"overlay-part "+(this.state.overlyRing)} onClick={this.hideSlideBlock.bind(this)}></div>
      </>
    );
  }
}
