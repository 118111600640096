import React, { Component } from "react";
import redirectURL from '../../redirectURL';
import SweetAlert from 'react-bootstrap-sweetalert';
import { withTranslation } from "react-i18next";
import Switch from "react-switch";
import Level1 from "./confingL1";

class ConfigurationsNew extends Component {
    constructor(props) {
        super(props)
        this.state = {
            records: {},
            dispatchChecked: true,
            show: false,
            basicType: '',
            basicTitle: '',
            dispatchInboundChecked: true,
            dispatchData: "show-m",
            dispatchOutboundChecked: true,
            dispatchConfigsInbound: "show-m",
            dispatchConfigsOutbound: "show-m",

        }
    }
    componentDidMount() {

        redirectURL.post("/master/getConfigureSetup")
            .then((response) => {
                this.setState({ records: response.data.records[0] })


            })
    }
    handleDispatchInboundChange = (checked) => {
        var dispatchConfigsInbound = "show-n";
        if (checked == true) {
            dispatchConfigsInbound = "";
            this.setState({
                dispatchInboundChecked: checked,
                dispatchConfigsInbound: dispatchConfigsInbound
            })
        } else {
            this.setState({
                dispatchInboundChecked: checked,
                dispatchConfigsInbound: dispatchConfigsInbound
            })
        }
    }
    handleDispatchOutboundChange = (checked) => {
        var dispatchConfigsOutbound = "show-n";
        if (checked == true) {
            dispatchConfigsOutbound = "";
            this.setState({
                dispatchOutboundChecked: checked,
                dispatchConfigsOutbound: dispatchConfigsOutbound
            })
        }
        else {
            this.setState({
                dispatchOutboundChecked: checked,
                dispatchConfigsOutbound: dispatchConfigsOutbound
            })
        }
    }
    handleDispatchChange = (checked) => {
        var dispatchConfigs = "show-n";
        var cnf = window.confirm("Configurations will be Disabled/Altered, Are you sure you want to proceed ?");
        if (cnf === true) {
            if (checked == true) {
                dispatchConfigs = "";
                this.setState({ dispatchChecked: checked, dispatchConfigs: dispatchConfigs });
            }
        
        this.setState({ dispatchChecked: checked, dispatchConfigs: dispatchConfigs });
        }
    }
    saveInboundConfigs = () => {
        var inboundRec= {...this.state.records.Inbound}
        var keys=Object.keys(inboundRec)
        keys.map((item1)=>{
            var data1=inboundRec[item1]
            // console.log(data1.value)
            if (data1.value==0){
                var keys1=Object.keys(data1)
                keys1.map((item2)=>{
                    if (item2 !== 'type' && item2 !== 'disabled' && item2 !== "value" && item2 !== 'name' && item2 !== 'id'){
                        var data2=data1[item2]
                        data2.value=0
                        var keys2=Object.keys(data2)
                        keys2.map((item3)=>{
                            if (item3 !== 'type' && item3 !== 'disabled' && item3 !== "value" && item3 !== 'name' && item3 !== 'id'){
                                var data3=data2[item3]
                                data3.value=0
                                // console.log(data3,'hello')
                                var keys3=Object.keys(data3)
                                keys3.map((item4)=>{
                                    if (item4 !== 'type' && item4 !== 'disabled' && item4 !== "value" && item4 !== 'name' && item4 !== 'id'){
                                        var data4=data3[item4]
                                        data4.value=0}
                                })
                            }
                        })

                    }

                })
            }else{
                var keys1=Object.keys(data1)
                keys1.map((item2)=>{
                    if (item2 !== 'type' && item2 !== 'disabled' && item2 !== "value" && item2 !== 'name' && item2 !== 'id'){
                        var data2=data1[item2]
                        if(data2.value==0){
                        var keys2=Object.keys(data2)
                        keys2.map((item3)=>{
                            if (item3 !== 'type' && item3 !== 'disabled' && item3 !== "value" && item3 !== 'name' && item3 !== 'id'){
                                var data3=data2[item3]
                                data3.value=0
                                var keys4=Object.keys(data3)
                                keys4.map((item4)=>{
                                    if (item4 !== 'type' && item4 !== 'disabled' && item4 !== "value" && item4 !== 'name' && item4 !== 'id'){
                                        var data4=data3[item4]
                                        data4.value=0}
                                })
                            }
                        })
                        }

                    }

                })
            }
        })
        var params={...inboundRec}
        params.name = 'inbound'
        params._id = this.state.records._id
        // console.log(params,'hello')
        redirectURL.post('master/saveconfigurations', params)
            .then((response) => {
                if (response.status === 200) {
                    this.setState({show:true,
                        basicTitle:"Data Saved successfully",
                        basicType:"success"})
                    // window.location.reload()
                }
            })
    }
    saveOutboundConfigs = () => {

        
        var outboundRec= {...this.state.records.Outbound}
        var keys=Object.keys(outboundRec)
        keys.map((item1)=>{
            var data1=outboundRec[item1]
            // console.log(data1.value)
            if (data1.value==0){
                var keys1=Object.keys(data1)
                keys1.map((item2)=>{
                    if (item2 !== 'type' && item2 !== 'disabled' && item2 !== "value" && item2 !== 'name' && item2 !== 'id'){
                        var data2=data1[item2]
                        data2.value=0
                        var keys2=Object.keys(data2)
                        keys2.map((item3)=>{
                            if (item3 !== 'type' && item3 !== 'disabled' && item3 !== "value" && item3 !== 'name' && item3 !== 'id'){
                                var data3=data2[item3]
                                data3.value=0
                                // console.log(data3,'hello')
                                var keys3=Object.keys(data3)
                                keys3.map((item4)=>{
                                    if (item4 !== 'type' && item4 !== 'disabled' && item4 !== "value" && item4 !== 'name' && item4 !== 'id'){
                                        var data4=data3[item4]
                                        data4.value=0}
                                })
                            }
                        })

                    }

                })
            }else{
                var keys1=Object.keys(data1)
                keys1.map((item2)=>{
                    if (item2 !== 'type' && item2 !== 'disabled' && item2 !== "value" && item2 !== 'name' && item2 !== 'id'){
                        var data2=data1[item2]
                        if(data2.value==0){
                        var keys2=Object.keys(data2)
                        keys2.map((item3)=>{
                            if (item3 !== 'type' && item3 !== 'disabled' && item3 !== "value" && item3 !== 'name' && item3 !== 'id'){
                                var data3=data2[item3]
                                data3.value=0
                                var keys4=Object.keys(data3)
                                keys4.map((item4)=>{
                                    if (item4 !== 'type' && item4 !== 'disabled' && item4 !== "value" && item4 !== 'name' && item4 !== 'id'){
                                        var data4=data3[item4]
                                        data4.value=0}
                                })
                            }
                        })
                        }

                    }

                })
            }
        })
        // console.log(outboundRec,'hello')
        var params={...outboundRec}
        params.name = 'outbound'
        params._id = this.state.records._id
        // console.log(params,'hello')
        redirectURL.post('master/saveconfigurations', params)
            .then((response) => {
                if (response.status === 200) {
                    this.setState({show:true,
                        basicTitle:"Data Saved successfully",
                        basicType:"success"})
                    // window.location.reload()
                }
            })
    }

    render() {
        const {t} = this.props
        const closeAlert = () => {
            this.setState({ show: false })
            window.location.reload();
        }
        var records = this.state.records
        return (
            <div className="">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={closeAlert}>
                </SweetAlert>
                <div className="row mb-20p pt-20p">
                    <div className="col-sm-12 d-flex">
                        <div className={"col-sm-12 " + "  d-inline"}>
                            <h5 className='fbold  d-flex justify-content-between'>
                                <span>{t('configurations')}</span>
                            </h5>
                        </div>
                    </div>
                </div>
                <div className="row mb-20p">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-body" style={{ minHeight: "90vh" }}>
                                {/* <ul className="d-tabs">
                                    <li className={"lttabs activet"}>
                                        <button type="button" className="btn">Dispatch Planning</button>
                                    </li>
                                </ul> */}
                                <div className={this.state.dispatchData}>
                                    <div class={"row col-sm-12 mt-3"}>
                                        <div className="col-sm-4">
                                            <label className="fw-800 mt-20per">{t('enable_disable_dispatch_planning_module')}</label>
                                        </div>
                                        <div className="col-sm-2">
                                            <Switch
                                                checked={this.state.dispatchChecked}
                                                onChange={this.handleDispatchChange}
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                                onColor="#0F58D6"
                                                onHandleColor="#d3dbdf"
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                        </div>
                                    </div>
                                    <div class={"row col-sm-12 mt-20p " + this.state.dispatchConfigs}>
                                        <div className="col-sm-6 brd-right">
                                            <div class={"row mt-20p"}>
                                                <div className="col-sm-7 txt-center">
                                                    <h5>{t('inbound')} </h5>
                                                </div>
                                                <div className="col-sm-5">
                                                    <label htmlFor="material-switch1">
                                                        <span style={{ "marginRight": "10px" }}>{t('enable_disable')}</span>
                                                        <Switch
                                                            checked={this.state.dispatchInboundChecked}
                                                            onChange={this.handleDispatchInboundChange}
                                                            onColor="#86d3ff"
                                                            onHandleColor="#2693e6"
                                                            handleDiameter={15}
                                                            uncheckedIcon={false}
                                                            checkedIcon={false}
                                                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                            height={10}
                                                            width={28}
                                                            className="react-switch"
                                                            id="material-switch1"
                                                        />
                                                    </label>
                                                </div>
                                            </div>
                                            <div class={this.state.dispatchConfigsInbound}>
                                                <div className="mr-9" style={{ display: 'block' }}>
                                                    {Object.keys(this.state.records).length > 0 ? <Level1 data={records.Inbound} /> : ''}
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <button type="button" className="btn btn-warning float-right" onClick={this.saveInboundConfigs}>{t('save_inbound_configurations')}</button>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>


                                        <div className="col-sm-6">
                                            <div class={"row mt-20p"}>
                                                <div className="col-sm-7 txt-center">
                                                    <h5>{t('outbound')} </h5>
                                                </div>
                                                <div className="col-sm-5">
                                                    <label htmlFor="material-switch">
                                                        <span style={{ "marginRight": "10px" }}>{t('enable_disable')}</span>
                                                        <Switch
                                                            checked={this.state.dispatchOutboundChecked}
                                                            onChange={this.handleDispatchOutboundChange}
                                                            onColor="#86d3ff"
                                                            onHandleColor="#2693e6"
                                                            handleDiameter={15}
                                                            uncheckedIcon={false}
                                                            checkedIcon={false}
                                                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                            height={10}
                                                            width={28}
                                                            className="react-switch"
                                                            id="material-switch"
                                                        />
                                                    </label>
                                                </div>
                                            </div>
                                            <div class={"col-sm-12 " + this.state.dispatchConfigsOutbound}>
                                                <div>
                                                    {Object.keys(this.state.records).length > 0 ? <Level1 data={records.Outbound} /> : ''}
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <button type="button" className="btn btn-danger float-right" onClick={this.saveOutboundConfigs}>{t('save_outbound_configurations')}</button>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default withTranslation()(ConfigurationsNew) 
