import React, { Component } from "react";
import { AllModules } from "@ag-grid-enterprise/all-modules";
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-balham.css"; // Optional theme CSS
import "ag-grid-enterprise";
import { withTranslation } from "react-i18next";
import Constant from "../common/constants";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import redirectURL from "../redirectURL";
import Select from "react-select";
import i18n from "../../i18n";
import SweetAlert from "react-bootstrap-sweetalert";
import GridButton from "../optirun-admin/gridButton";
import { IoMdRadio } from "react-icons/io";
// import TimeRangePicker from '@wojtekmaj/react-timerange-picker';
// import getToolConfiguration from "../optirun-admin/commonFunctions";

 class Requirements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modules: AllModules,
      errorMessage: "",
      defaultColDef: {
        sortable: true,
        filter: true,
        disableAddVehicleChecklist: false,
        editable: false,
        resizable: true,
        minWidth: 100,
        initialWidth: 200,
        wrapHeaderText: true,
        autoHeaderHeight: true,
      },
      frameworkComponents: {
        GridButton: GridButton,
      },
      rowData: [],
      categories: [{label:"Security", value:"Security"},{label:"Quality", value:"Quality"}],
      requirement_category: null,
      plantCodeFrom_PW: '',
      deleted_requirement_txt: "",
      openEditRequirementSlide: "",
      overlayBlock: "show-n",
      rowSelection: "multiple",
      rowModelhighchartoptionsType: "enterprise",
      rowGroupPanelShow: "always",
      detailCellRendererParams: {},
      statusBar: {
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left",
          },
          {
            statusPanel: "agTotalRowCountComponent",
            align: "center",
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" },
        ],
      },
      paginationPageSize: 100,
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
          },
          {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel",
          },
        ],
      },
      animateRows: true,
      openRequirementModel: false,
      vehicle_type: "",
      requirement_Txt: "",
      vehicle_name: "",
      vehicle_priority: "Owned",
      min_volume_cmt: "",
      max_volume_cmt: "",
      max_volume_m3: "",
      min_volume_m3: "",
      vehicle_dimensions_width_mm: "",
      vehicle_dimensions_depth_mm: "",
      vehicle_dimensions_height_mm: "",
      vehicle_min_weight: "",
      vehicle_max_weight: "",
      max_no_of_nodes: "",
      variable_vehicle_cost_expandable: [],
      old_requirement_Txt: "",
      edited_requirement_Txt: "",
      show: false,
      basicTitle: "",
      basicType: "default",
      showAvailablityTable: false,
      data_type: "",
      location: "",
      max_distance: "",
      vehicle_availability_time: ["00:00", "23:59"],
      break_time: ["00:00", "23:59"],
      locationNames: [],
      locationsHasChecklistRecords: [],
      min_load_per_delivery: "",
      // vehicle_capacity:'',
      vehicle_id: "",
      vehicle_type_count: 0,
      openVehiclePriorityConfigurationModel: false,
      vehicle_priority_order_owned: 1,
      vehicle_priority_order_contract: 2,
      vehicle_priority_order_market: 3,
      monthly_min_planning_distance: 3000,
      clickonEdit: false,
      cost_per_km: "",
      locationList: [],
      loadshow: "show-n",
    };
    this.onChangeTextArea = this.onChangeTextArea.bind(this);
    this.validateRequirement = this.validateRequirement.bind(this);
    this.onChangeEditedTextArea = this.onChangeEditedTextArea.bind(this);
    this.validateEditedRequirement = this.validateEditedRequirement.bind(this);
    // this.handleRequirementForm = this.handleRequirementForm.bind(this);
  }

  async componentDidMount() {
    this.setState({
      loadshow: "show-m",
      overlayBlock: "show-m",
    });
    var curl = window.location.search;
    var ul = curl.split("?");
    // let url_type = window.location.href
    let url_type = ul[parseInt(ul.length) - 1];
    // let path_type = url_type.split('/')
    let path_type = url_type;
    let data_type;
    // let path = path_type[path_type.length-1]
    let path = url_type;

    if (path_type == "inbound") {
      data_type = 1;
      this.setState({ data_type: data_type });
    } else {
      data_type = 2;
      this.setState({ data_type: data_type });
    }
    let params = {
      data_type: data_type,
    };
    await this.enableCheckListForLocation();    
    await this.getLocationWithVehicleChecklistEnabled()
    await this.boundTypeLocations(params);
    this.setState({
      loadshow: "show-n",
      overlayBlock: "show-n",
    });
  }
  setRequirmentsToLocation = async (arg) => {
    let params ={
        location_code: this.state.location.value,
        location_city: this.state.location.label,
        data_type: this.state.data_type,
        user_type: localStorage.getItem("user_type")
      };
    
    // console.log(params,"setting requirements to securty")
    await redirectURL
      .post("master/setRequirementsToLocation", params)
      .then((resp) => {
        if (resp.data.status === "success") {
          // console.log("succesfully added the requirements to the location");
        }
      })
      .catch(function (error) {
        var errDetails = {
          url: "master/setRequirementsToLocation",
          screen: "Requirements",
          error,
        };
        redirectURL.post("/master/logErrorsForApiCalls", errDetails);
      });
  };
  fetchRequirementsByLocation = async () => {
    let location = this.state.location.value;
    let params = { location_code: location, isDelete: false};
    // console.log(params, "fetching requirements")
    await redirectURL
      .post("master/fetchRequirementsByLocation", params)
      .then((resp) => {
        if (resp.data.status === "success") {
          // console.log("fetched requirements", resp.data.records)
          let records = resp.data.records
          this.setState({
            rowData: records.reverse(),
            loadshow: "show-n",
            overlayBlock: "show-n",
          });
        }
      })
      .catch(function (error) {
        var errDetails = {
          url: "master/fetchRequirementsByLocationmm",
          screen: "Requirements",
          error,
        };
        redirectURL.post("/master/logErrorsForApiCalls", errDetails);
      });
  };
  enableCheckListForLocation = async (param) => {
    await redirectURL
      .post("master/checkLocationForCheckListEnable")
      .then(async (resp) => {
        if (resp.data.status === "success") {
          this.setState(
            {
              locationsHasChecklistRecords: resp.data.records,
            },
            () => {
            //   console.log(this.state.locationsHasChecklistRecords, "checklist");
            }
          );
        }
      })
      .catch(function (error) {
        var errDetails = {
          url: "master/checkLocationForCheckListEnable",
          screen: "Vehicle Checklist",
          error,
        };
        redirectURL.post("/master/logErrorsForApiCalls", errDetails);
      });
  };
  redirectToAssignReqToVehicle = (e) => {
    let logParams = {
      // "location_code" : this.state.location.value,
      // "location_name" : this.state.location.label,
      user_name: localStorage.getItem("username"),
      useremail: localStorage.getItem("email"),
      client: localStorage.getItem("client"),
      role: localStorage.getItem("role"),
      screen: "Requirements Page",
      activity: "Redirecting to Vehicle Checklist",
      event: `Clicked on View Vehicle Checklist`,
      // "data_type" : this.state.data_type,
    };
    redirectURL.post("/master/loguserUpdatesorChanges", logParams);
    
    if(!this.state.disableAddVehicleChecklist){
      let data_type = this.state.data_type;
      let location = this.state.location.value;
      // let qry = {
      //   stage: 2
      // }
      // let existingQry = localStorage.getItem("assignqry");
      // let existingQryObj = JSON.parse(existingQry)
      // let mergedQry = { ...existingQryObj, ...qry };
      // let updateQry = JSON.stringify(mergedQry);
      // console.log(updateQry,"updateQry")
      // localStorage.setItem("assignqry", updateQry);
      const newUrl = `${window.location.origin}/assign_requirement_to_vehicle`;  
      window.location.href = newUrl;
    }
    // window.open (
    //     window.location.origin +
    //       "/vehicle_checklist"
    // );
  };
  openModelForAddingVehicleRequirements = async () => {
    let activityMsg = "Clicked on Add Requirement button";
    let logParams = {
      location_code: this.state.location.value,
      location_name: this.state.location.label,
      user_name: localStorage.getItem("username"),
      useremail: localStorage.getItem("email"),
      client: localStorage.getItem("client"),
      screen: "Requirements Page",
      activity: activityMsg,
      event: "Requirement Modal Opened",
      data_type: this.state.data_type,
    };
    redirectURL.post("/master/loguserUpdatesorChanges", logParams);
    this.setState((prevState) => ({
      openRequirementModel: !prevState.openRequirementModel,
    }));
  };
  redirectToVechicleCheckListScreen = () => {
    this.setState({
      show: true,
      basicTitle: i18n.t('redirecting_to_vehicle_checklist'),
      basicType: "success",
    },async ()=>{
      await setTimeout(async () => {
        const newUrl = `${window.location.origin}/vehicle_checklist`;  
          window.location.href = newUrl;
      }, 2000);
    })
  }
  openModelForEditingRequirement = async () => {
    let activityMsg = "Clicked on Edit Requirement button";
    let logParams = {
      location_code: this.state.location.value,
      location_name: this.state.location.label,
      user_name: localStorage.getItem("username"),
      useremail: localStorage.getItem("email"),
      client: localStorage.getItem("client"),
      screen: "Requirements",
      activity: activityMsg,
      event: "Edit Requirement Modal Opened",
      data_type: this.state.data_type,
    };
    redirectURL.post("/master/loguserUpdatesorChanges", logParams);
    this.setState((prevState) => ({
        overlayBlock: 'show-m',
        openEditRequirementSlide: "slide25",
    }));
  };
  
  hideSlideBlock = () => {
    this.setState((prevState) => ({
      overlayBlock: "show-n",
      openEditRequirementSlide:'',      
      errorMessage: ""
    }));
  };
  hideEditSlider = () => {
    this.setState({
      openEditRequirementSlide: "",
      overlayBlock: "show-n",
      errorMessage: ""
    });
  };
  handelRequirementModelCancel = () => {
    let logParams = {
      location_code: this.state.location.value,
      location_name: this.state.location.label,
      user_name: localStorage.getItem("username"),
      useremail: localStorage.getItem("email"),
      client: localStorage.getItem("client"),
      screen: "Vehicle Forecast Configurations",
      activity: "clicked on Cancel Button in the modal",
      event: "Modal is closed",
      data_type: this.state.data_type,
    };

    redirectURL.post("/master/loguserUpdatesorChanges", logParams);
    this.setState((prevState) => ({
      openRequirementModel: !prevState.openRequirementModel,
      requirement_Txt: "",
      errorMessage: "",
      requirement_category: null
    }));
  };
  closeAlert = async () => {
    await this.setState({
      show: false,
    });
  };
  locationChanged = (location) => {
    this.setState({location: location});
  };
  getLocationWithVehicleChecklistEnabled = async () => {
    let params = {
        data_type: localStorage.getItem('data_type')
    }
    await redirectURL.post("master/getPlantwiseToolConfig", params)
        .then((resp)=>{
            let getPlantCode = resp.data.filter((e)=>{
                if((e.hasOwnProperty === "vehicle_checklist") && e.vehicle_checklist === 1 ){
                    return e.plant_code
                }
            })
            this.setState({
                plantCodeFrom_PW: getPlantCode
            })
        })
        .catch(function (error) {
            var errDetails={
                url:"master/getPlantwiseToolConfig",
                screen:'Requirements',
              error,
              }
            redirectURL.post("/master/logErrorsForApiCalls",errDetails)
        })
}
  boundTypeLocations = async (param) => {
    let location_access = JSON.parse(localStorage.getItem("location_access"));

    let locationList = [];
    await redirectURL
      .post("master/getBoundTypeLocations", param)
      .then(async (response) => {
        if (response.data.status == "success") {
          // this.setState({
          //     loadshow:"show-n",
          //     overlayBlock:"show-n"
          // })
          let boundLocations = response.data.boundLocations;
          if (boundLocations.length > 0) {
            boundLocations.map((item) => {
              if (location_access != null) {
                for(const code of location_access){
                    if(code === item.location_code){
                        locationList.push({ value: item.location_code, label: item.location_name });
                    }
                }
              } else {
                locationList.push({ value : item.location_code, label : item.location_name })
              }
            });
            if (locationList.length > 0) {
              await this.setState({ locationList: locationList }, () => {
                if (this.state.locationsHasChecklistRecords.length > 0) {
                  let z = this.state.locationsHasChecklistRecords.map(
                    (item) => {
                      const match = locationList.find(
                        (e) => e.value === item.plant_code
                      );
                      return match
                        ? { value: item.plant_code, label: match.label }
                        : null;
                    }
                  );
                  this.setState(
                    {
                      locationNames: z,
                      location: z[0],
                    },
                    async () => {
                      await this.setRequirmentsToLocation();
                      await setTimeout(async () => {
                        await this.fetchRequirementsByLocation();
                      }, 1000);
                    }
                  );
                }
              });
            }
          }
        }
      })
      .catch(function (error) {
        var errDetails = {
          url: "master/getBoundTypeLocations",
          screen: "Vehicle Forecast Configurations",
          error,
        };
        redirectURL.post("/master/logErrorsForApiCalls", errDetails);
      });
  };
  handleRequirementForm = async (e) => {
    e.preventDefault();
    let logParams = {
      // "location_code" : this.state.location.value,
      // "location_name" : this.state.location.label,
      user_name: localStorage.getItem("username"),
      useremail: localStorage.getItem("email"),
      client: localStorage.getItem("client"),
      role: localStorage.getItem("role"),
      screen: "Requirements Page",
      activity: "Adding new requirement",
      event: `Clicked on save button in modal`,
      // "data_type" : this.state.data_type,
    };
    await redirectURL.post("/master/loguserUpdatesorChanges", logParams);
    this.setState({
      loadshow: "show-m",
      errorMessage:""
    });
    // console.log(this.state.requirement_category,"requirement_category")
    
    let params = {
      location_code: this.state.location.value,
      location_city: this.state.location.label,
      requirement: this.state.requirement_Txt.trim(),
      requirement_type: this.state.requirement_category,
      user: localStorage.getItem("user_type").trim(),
      data_type: 2
    };
    
    if(this.state.requirement_category === null){
      this.setState((prevState) => ({
        show: true,
        basicTitle: "Select Requirement Category",
        basicType:"danger",        
        loadshow: "show-n",
      }))
    } else {
      if(this.state.requirement_Txt.trim()!==""){
        await redirectURL.post("master/addRequirementToLocation", params)
        .then(async (resp) => {
          if (resp.data.status === "success") {
            this.setState((prevState) => ({
              show: true,
              basicTitle: resp.data.message,
              basicType: resp.data.status === "failed" ? "danger" : "success",
              loadshow: "show-n",
              openRequirementModel: !prevState.openRequirementModel,
              requirement_Txt: '',
              requirement_category: null,
              errorMessage: ''
            })); 
            await this.fetchRequirementsByLocation();
          }
        })
        .catch(function (error) {
          console.log(error, "error while adding new");
          var errDetails = {
            url: "master/addRequirementToLocation",
            screen: "Vehicle Forecast Configurations",
            error,
          };
          redirectURL.post("/master/logErrorsForApiCalls", errDetails);
        });
      } else {
        this.setState((prevState) => ({
          show: true,
          basicTitle: "Empty Requirement cannot be added",
          basicType: "danger",
          loadshow: "show-n",
          openRequirementModel: !prevState.openRequirementModel,
          requirement_Txt: '',
          requirement_category: null
        }))
      }
    }
  };
  submitEditFormHandler = async (e) => {
    e.preventDefault();
    this.setState({
      loadshow: "show-m",
      overlayBlock: "show-m",
      errorMessage:""
    });
    let location = this.state.location.value;
    
    let reqType = JSON.parse(localStorage.getItem("assignqry"))
    if(this.state.edited_requirement_Txt.trim() !== ""){
        let params = {
          location_code: location,
          isEdit: true,
          requirement: this.state.edited_requirement_Txt.trim(),
          old_requirement_Txt: this.state.old_requirement_Txt,
          user: localStorage.getItem("user_type").trim()
        }
      // console.log(params, "edited requirement")
        await redirectURL
        .post("master/editRequirementToLocation", params)
        .then(async (resp) => {
          if (resp.data.status === "success") {
            this.setState((prevState) => ({
              show: true,
              basicTitle: resp.data.message,
              basicType: resp.data.status === "failed" ? "danger" : "success",
              overlayBlock: "show-n",
              loadshow: "show-n",
              openEditRequirementSlide: "",
              requirement_Txt: "",
              errorMessage:""
            }), async ()=>{
              await this.fetchRequirementsByLocation();
            });
          } else {
            this.setState({
              errorMessage:""
            })
          }
        })
        .catch(function (error) {
          console.log(error, "error while adding new");
          var errDetails = {
            url: "master/addRequirementToLocation",
            screen: "Vehicle Forecast Configurations",
            error,
          };
          redirectURL.post("/master/logErrorsForApiCalls", errDetails);
        });
    } else {
      this.setState((prevState) => ({
        show: true,
        basicTitle: "Requirement Cannot be Empty",
        basicType: "danger",
        loadshow: "show-n",
        overlayBlock: "show-n",
        openEditRequirementSlide: "",
      }))
    }
  };
  updateRequirement = async (propdata) => {
    // console.log(propdata, "edit propData")
    this.setState({
      old_requirement_Txt: propdata.data.requirementDetails.requirement,
      edited_requirement_Txt: propdata.data.requirementDetails.requirement,
    });
    this.openModelForEditingRequirement();
  };
  deleteRequirement = async (propData) => {
    // console.log(propData,"delete")
    let logParams = {
      // "location_code" : this.state.location.value,
      // "location_name" : this.state.location.label,
      user_name: localStorage.getItem("username"),
      useremail: localStorage.getItem("email"),
      client: localStorage.getItem("client"),
      role: localStorage.getItem("role"),
      screen: "Requirements Page",
      activity: `Deleting ${propData.data.requirementDetails.requirement}`,
      event: `Clicked on Delete`,
      // "data_type" : this.state.data_type,
    };
    redirectURL.post("/master/loguserUpdatesorChanges", logParams);
    let confirmDelete = window.confirm("Are you sure you want to delete this record?");
    if (confirmDelete) {
          
      let location = this.state.location.value;
      this.setState(
        {
          deleted_requirement_txt: propData.data.requirementDetails.requirement,
        },
        async () => {
          let params = {
            location_code: location,
            isDelete: true,
            requirement: this.state.deleted_requirement_txt,
            user: localStorage.getItem("user_type").trim(),
          };
          
          // console.log(params,"params for deleting a requirement")
          await redirectURL
            .post("master/deleteRequirementToLocation", params)
            .then(async (resp) => {
              if (resp.data.status === "success") {
                this.setState((prevState) => ({
                  show: true,
                  basicTitle: resp.data.message,
                  basicType: resp.data.status === "failed" ? "danger" : "success",
                  deleted_requirement_txt: "",
                }),async ()=>{
                  await this.fetchRequirementsByLocation();
                });
              }
            })
            .catch(function (error) {
              console.log(error, "error while adding new");
              var errDetails = {
                url: "master/addRequirementToLocation",
                screen: "Vehicle Forecast Configurations",
                error,
              };
              redirectURL.post("/master/logErrorsForApiCalls", errDetails);
            });
        }
      );
    }
  };
  onChangeCategory = (requirement_category) => {
    this.setState({ requirement_category });
  };
  onChangeTextArea(event) {
    const value = event.target.value;
    // Validate input: Allow only alphanumeric characters and spaces
    const regex = /^[a-zA-Z0-9 .\/"':]*$/;

    if (regex.test(value)) {
      this.setState({ requirement_Txt: value, errorMessage: "" });
    } else {
      this.setState({
        errorMessage: "Only alphanumeric characters are allowed.",
      });
    }
  }
  onChangeEditedTextArea(event) {
    const value = event.target.value;
    // Validate input: Allow only alphanumeric characters and spaces
    const regex = /^[a-zA-Z0-9 .\/"':]*$/;

    if (regex.test(value)) {
      this.setState({ edited_requirement_Txt: value, errorMessage: "" });
    } else {
      this.setState({
        errorMessage: "Only alphanumeric characters are allowed.",
      });
    }
  }
  validateRequirement() {
    const { requirement_Txt } = this.state;
    if (requirement_Txt.trim() === "") {
      this.setState({ errorMessage: "This field is required." });
    } else {
      this.setState({ errorMessage: "" });
    }
    // else if (requirement_Txt.length > 35) {
    //     console.log("error raised");
    //     this.setState({ errorMessage: 'Input must not exceed 35 characters.' });
    //   }
  }
  validateEditedRequirement() {
    const { edited_requirement_Txt } = this.state;
    if (edited_requirement_Txt.trim() === "") {
      this.setState({ errorMessage: "This field is required." });
    } else {
      this.setState({ errorMessage: "" });
    }
  }

  render() {
    const {t} = this.props;
    var colsDefs = [
      {
        headerName: t('requirement'),
        field: "requirement",
        width: "400",
        valueGetter: (params) => params.data.requirementDetails.requirement,
      },
      {
        headerName: t('requirement_type'),
        field: "requirement_type",
        width: "160",
      },
    ];
    colsDefs.push(
      {
        headerName: t('actions'),
        field: "cluster",
        width: "20",
        pinned: "left",
        params: {
          buttonName: t('edit'),
          onClickFunction: this.updateRequirement,
        },
        cellRendererSelector: function (params) {
          var rendComponent = {
            component: "GridButton",
          };
          return rendComponent;
        },
      },
      {
        headerName: "",
        field: "cluster",
        width: "120",
        pinned: "left",
        params: {
          buttonName: t('delete'),
          iconName: "fa fa-trash",
          onClickFunction: this.deleteRequirement,
        },
        cellRendererSelector: function (params) {
          var rendComponent = {
            component: "GridButton",
          };
          return rendComponent;
        },
      }
    );

    var userMailCheck =
      localStorage.getItem("role") === "admin" ||
      localStorage.getItem("user_type") === "admin" || 
      localStorage.getItem("user_type") === "USER" ||
      localStorage.getItem("user_type") === "user"
        ? "show-m"
        : "show-n";
    return (
      <div className="">
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          onConfirm={this.closeAlert}
        ></SweetAlert>

        <div class="row mb-20p pt-20p">
          <div className="col-sm-12">
            <div className="col-sm-6">
              <h5 className="fbold">
                <span>{t('vehicle_checklist_for_transportation_requirements')}</span>
              </h5>
            </div>
            <div className="col-sm-6" style={{display:"flex",justifyContent:"end"}}>  
                  <span className={"float-right " + userMailCheck}>
                      <a href="javascript:;" onClick={this.openModelForAddingVehicleRequirements} className="btn btn-warning f12 mr-10p">
                        {t('add_requirements')}
                      </a>
                      <a href="javascript:;" onClick={this.redirectToVechicleCheckListScreen} className="btn btn-warning f12 mr-10p">
                        {t('back')}
                      </a>
                  </span>
            </div>
          </div>
          <div className="col-sm-12 d-flex">
            <div className={ this.state.showAvailablityTable ? "col-sm-7 ": "col-sm-12 " + "  d-inline" }>              
              <div className="col-md-2 col-sm-12">
                <div className="form-group ">
                  <label className="col-form-label f12">
                    {t('pickup_point')}
                  </label>
                  <Select
                    placeholder={"Select"}
                    onChange={this.locationChanged}
                    name="location"
                    value={this.state.location}
                    options={this.state.locationNames}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-20p pt-2p">
          <div className="col-sm-12 d-flex">
            <div
              className={
                this.state.showAvailablityTable
                  ? "col-sm-7 "
                  : "col-sm-12 " + "  d-inline"
              }
            >
              <div
                id="myGrid"
                style={{ maxWidth: "60%", height: "70vh" }}
                className="col-sm-12 ag-theme-balham dropdown"
              >
                <AgGridReact
                  rowData={this.state.rowData}
                  columnDefs={colsDefs}
                  gridOptions={{ context: { componentParent: this } }}
                  defaultColDef={this.state.defaultColDef}
                  frameworkComponents={this.state.frameworkComponents}
                  paginationPageSize={this.state.paginationPageSize}
                  pagination={true}
                />
              </div>
            </div>
          </div>
        </div>

        <Modal
          closeOnOverlayClick={false}
          open={this.state.openRequirementModel}
          onClose={this.handelRequirementModelCancel}
          classNames={{ modal: "pickupdrop-modelClass" }}
        >
          <div className="col-sm-12">
            <div>
              <h4>{t('add_requirements')}</h4>
            </div>
            <form className="theme-form" onSubmit={this.handleRequirementForm}>
              <div className="row number p-10p">
                <div className="col-sm-4 ml-auto">
                  <Select 
                      onChange={this.onChangeCategory}
                      placeholder="Requirement Type"
                      name="categories"
                      value={this.state.requirement_category}
                      options = {this.state.categories}
                  />
                </div>
                <div className="col-sm-12">
                  <div className="form-group">
                    <label className="col-form-label f12">
                      {t('requirement')} <span style={{color:"red"}} className="redColor">*</span>
                    </label>
                    <textarea
                      className="forminp form-control"
                      id="vehicle_type"
                      name="vehicle_type"
                      value={this.state.requirement_Txt}
                      onChange={this.onChangeTextArea}
                      placeholder=""
                      autoComplete="off"
                    ></textarea>
                    {this.state.errorMessage ? (
                      <p style={{ color: "red" }}>{this.state.errorMessage}</p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group">
                <button
                  type="submit"
                  className="btn tbtn float-right save-btn"
                  id="saveGeo"
                >
                  {t('save')}
                </button>
                <button
                  type="button"
                  className="btn tbtn float-right cancel-btn"
                  onClick={this.handelRequirementModelCancel}
                >
                  {t('cancel')}
                </button>
              </div>
            </form>
          </div>
        </Modal>
        <div className={"sliderBlock2 " + this.state.openEditRequirementSlide}>
          <div className="row">
            <div className="col-sm-12">
              <form id="bulkUploadForm" onSubmit={this.submitEditFormHandler}>
                <div className="row p-20p">
                  <div className="form-group col-sm-12 mb-20p">
                    <label style={{ color: "#000" }}>
                      {t('requirement')} <span style={{color:"red"}} className="redColor">*</span>
                    </label>
                    <textarea
                      className="forminp form-control"
                      id="edited_requirement_Txt"
                      name="edited_requirement_Txt"
                      value={this.state.edited_requirement_Txt}
                      onChange={this.onChangeEditedTextArea}
                      placeholder=""
                      autoComplete="off"
                    ></textarea>
                    {this.state.errorMessage ? (
                      <p style={{ color: "red" }}>{this.state.errorMessage}</p>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="form-group col-sm-12 mb-20p">
                    <button
                      type="submit"
                      id="bulkUploadBtn"
                      className="btn btn-danger"
                    >
                      {t('save')}
                    </button>
                    <button
                      type="button"
                      className="btn btn-default"
                      onClick={this.hideEditSlider}
                    >
                      {t('cancel')}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div
          className={"overlay-block " + this.state.overlayBlock}
          onClick={this.hideSlideBlock}
        ></div>
        <div className={"loader " + this.state.loadshow}></div>
      </div>
    );
  }
}

export default withTranslation()(Requirements)