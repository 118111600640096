
import React, { Component } from 'react';

 
const AssignVehicleBtn = (props) => {
    const handleOnClick = () => {
        props.context.componentParent.onClickShowAssignForm(props,true);
    };
  
    return (
        <div>
            <button type="button" className="btn grid-btn" style={{ backgroundColor: "#f0f0f0", color: "black", fontWeight: 600 }} onClick={handleOnClick}>Assign Vehicle</button>        
        </div>
    );
};

export default AssignVehicleBtn;
