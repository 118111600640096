import React,{Component} from "react";
import Constant from "../common/constants";
import redirectURL from '../redirectURL';
import { withTranslation } from "react-i18next";
import Select from 'react-select';
import getToolConfiguration from "./commonFunctions";
import TransporterMaster from './transporterMaster';
import TransporterOperations from './transporterOperations';




 class TransporterConfiguration extends Component{
    constructor(props){
        super(props);
        this.state={
            locationNames:[],
            disableAddTo: false,
            location:'',
            transporterMasterTab:"activet",
            transporterOperationsTab : "",
            transporterMasterData: true,
            transporterOperationsData:false,
        }
        
    }

    async componentDidMount(){
        
        var curl = window.location.search;
        var ul= curl.split("?");
        let url_type = ul[parseInt(ul.length)-1];
        let data_type
        let path =url_type

        if (path == 'inbound'){
            data_type = 1
        }else{
            data_type = 2
        }
        await this.setState({data_type : data_type})

        let params = {
            data_type : data_type
        }
        let toolparameters = {
            data_type : data_type
        }
        await getToolConfiguration(toolparameters)
        await this.boundTypeLocations(params)
        let toolConfig = JSON.parse(localStorage.getItem("tlcfg"))
        let transprtrConfig =  toolConfig[0].plant_wise_transporter_configuration ? false: true;
        // let disableAddTransporterButton = transprtrConfig;
        // this.setState({
        //     disableAddTo: disableAddTransporterButton
        // })
        this.getTransConfig()
    }

    boundTypeLocations = async(param) => {

        let location_access = JSON.parse(localStorage.getItem("location_access"))
  
        let locationNames = this.state.locationNames;
        await redirectURL.post('master/getBoundTypeLocations',param)
          .then(async (response) =>{
              if(response.data.status == 'success'){
                  let boundLocations = response.data.boundLocations
                  if(boundLocations.length > 0){
                      boundLocations.map(item =>{
                          if(location_access != null){
                              if(location_access.includes(item.location_code)){
                                  locationNames.push({ value : item.location_code,
                                                      label : item.location_name })
  
                                  }
                          }else{
                              locationNames.push({ value : item.location_code,
                                                  label : item.location_name })
                          }
                          
                      })
                      
                      if(locationNames.length>0){
                          await this.setState({
                                      locationNames : locationNames,
                                      location : locationNames[0]
                                  })
                      }else{
                          await this.setState({
                              locationNames : [{label:'Please Add Data',value:'no_data'}],
                              location : [{label:'Please Add Data',value:'no_data'}]
                          })
                      }
                  }
              }
          })
          
      }

    locationChanged =async (location) =>{
       await this.setState({
            location : location
        })
        
        await getToolConfiguration({plant_code:location.value})
        this.getTransConfig()
        let logParams = {
            "location_code" : this.state.location.value,
            "location_name" : this.state.location.label,
            "user_name" : localStorage.getItem("username"),
            "useremail" : localStorage.getItem("email"),
            "client" : localStorage.getItem("client"),
            "screen" : "Transporter Configurations",
            "activity" : `Clicked on pickup location Dropdown`,
            "event" : `Selected ${location.value} plant`,
            "data_type" : this.state.data_type,
        }        
        redirectURL.post("/master/loguserUpdatesorChanges",logParams)
    }
    async getTransConfig(){
        await redirectURL.post('master/getPlantwiseToolConfig',{plant_code:this.state.location.value})
        .then(async(response)=>{
            var transprtConfig=response.data[0].plant_wise_transporter_configuration
            if (transprtConfig===1){
                await this.setState({disableAddTo:true})
            }else if(transprtConfig===0){
                await this.setState({disableAddTo:false})
            }
    })

    }


    onClickTab = (pageType) => {
        
        var tabName =pageType;
        if(pageType == "transporterMasterData")
        {
            var transporterMasterTab = "activet";
            var transporterOperationsTab = "";
            var transporterMasterData = true;
            var transporterOperationsData = false;
            
        }
        else if(pageType == "transporterOperationsData")
        {
            var transporterMasterTab = "";
            var transporterOperationsTab = "activet";
            var transporterMasterData = false;
            var transporterOperationsData = true;
        }
        this.setState({
            transporterMasterTab:transporterMasterTab,
            transporterOperationsTab:transporterOperationsTab,
            transporterMasterData:transporterMasterData,
            transporterOperationsData:transporterOperationsData,
            
        })
        let logParams = {
            "location_code" : this.state.location.value,
            "location_name" : this.state.location.label,
            "user_name" : localStorage.getItem("username"),
            "useremail" : localStorage.getItem("email"),
            "client" : localStorage.getItem("client"),
            "screen" : "Transporter Configurations ",
            "activity" : `Clicked on  ${tabName} tab`,
            "event" : `Navigated to ${tabName} screen `,
            "data_type" : this.state.data_type,
        }        
        redirectURL.post("/master/loguserUpdatesorChanges",logParams)
    }


    render(){   
        const {t} = this.props;
        const {location,locationNames,transporterMasterTab,transporterOperationsTab,transporterMasterData,transporterOperationsData}=this.state
        return(
            <div className="">
            <div className="row mb-5p pt-3 pl-1">
                    <div className="col-sm-12">                        
                        <h5 className='fbold  d-flex justify-content-between'>
                            <span>{t('transporter_configurations')}</span>
                        </h5>                        
                    </div>
                </div> 
                <div className="w-full d-flex flex-column justify-content-start">
                    <div className="col-md-2 col-sm-12">
                        <div className="form-group ">
                            <label className="col-form-label f12">{t('pickup_point')}</label>
                            <Select
                                placeholder={"Select"}
                                onChange={this.locationChanged}
                                name="location"
                                value={location}
                                options={locationNames} />

                        </div>
                    </div>
                    <br/>
                    <div className="col-md-12 col-sm-12 mt-3 pl-0 ml-2 mb-3" style={{ borderBottom: "1px solid #ccc" }}>
                            <ul className="d-flex justify-content-start align-items-center m-0 pl-0" style={{ gap: "3px" }}>
                                
                                <li onClick={this.onClickTab.bind(this,"transporterMasterData")} className={"lttabs m-0 p-0 border-0 "+(transporterMasterTab)} style={{ outline: "none" }}>
                                    <button type="button" className="btn m-0 border-0" style={{ padding: "9px 15px", outline: "none" }}>{t('transporter_master')} </button>
                                </li>
                                
                                <li disabled={transporterOperationsData ? location != '' ? false: false: true} onClick={this.onClickTab.bind(this,"transporterOperationsData")} className={"lttabs m-0 p-0 border-0 "+(transporterOperationsTab)} style={{ outline: "none" }}>
                                    <button type="button" disabled={transporterOperationsData ? location != '' ? false: false: true} className="btn m-0 border-0" style={{ padding: "9px 15px", outline: "none" }}>{t('transporter_operations')}</button>
                                </li>

                            </ul>
                        </div>
                    </div>
                    {transporterMasterData ?  location != '' ? <TransporterMaster addTransporterbtn = {this.state.disableAddTo} plantLocation={location}/> :'': ''}
                    {/* {transporterOperationsData ? location != '' ? <TransporterOperations plantLocation={location}/> :'': ''} */}
            </div> 
        )
    }
}

export default withTranslation()(TransporterConfiguration)