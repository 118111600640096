// import React, { Component } from 'react';
// import axios from 'axios';
// import { Navigate } from 'react-router-dom';
// import withRouter from "../withRouter";
// import $ from "jquery";
// import Select from 'react-select';
// import Cookies from 'js-cookie';
// import { IoMdKey } from "react-icons/io";
// import { MdLogout } from "react-icons/md";
// import { AiOutlineQuestionCircle } from "react-icons/ai";
// import * as BsIcons from "react-icons/bs";
// import { FaUser } from "react-icons/fa";
// import { useTranslation } from 'react-i18next';

// const { t } = useTranslation();
// const { i18n } = useTranslation();

// const customStyles = {
//     control: (provided) => ({
//         ...provided,
//         backgroundColor: '#f1f1f1',
//         borderRadius: '10px',
//         color: '#555',
//         border: 'none',
//         fontWeight: "bold",
//         boxShadow: 'none',
//         '&:hover': {
//             border: '3px solid rgb(14, 88, 214)',
//         },
//         border: "1px solid lightgrey",
//         minWidth: "100px",
//         fontSize: "1vw"
//     }),
//     placeholder: (provided) => ({
//         ...provided,
//         color: '#555',
//         fontSize: "0.9vw"
//     }),
//     menu: (provided) => ({
//         ...provided,
//         borderRadius: '5px',
//         zIndex: 9999,
//         fontSize: "0.9vw"
//     }),
//     // option: (provided, state) => ({
//     //     ...provided,
//     //     backgroundColor: state.isSelected ? 'rgb(14, 88, 214)' : 'white',
//     //     borderRadius: "10px",
//     //     color: state.isSelected ? 'white' : 'black',
//     //     '&:hover': {
//     //         backgroundColor: 'lightblue',
//     //     },
//     //     fontWeight: "bold"
//     // }),
//     singleValue: (provided) => ({
//         ...provided,
//         color: 'blue',
//         fontSize: "0.9vw",
//         padding: "0px",
//         paddingLeft: "0px",
//         margin: "0px"
//     }),
// };

// class Navbar extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             dispatchDataTypecheck: 0,
//             dispatch_data_type: { "label": "Select Data Type", "value": "" },
//             popupVisible: false,
//             isMouseOverPopup: false,
//             isMouseOverTrigger: false,
//             language: null
//         };
//         this.popupRef = React.createRef();
//         this.triggerRef = React.createRef();
//     }

//     changeLanguage = (language) => {
//         i18n.changeLanguage(language);
//         // setTimeout(() => {
//         //     window.location.reload()
//         // }, 1000)
//     };

//     componentDidMount() {
//         if (localStorage.getItem("module_type") == 1 && localStorage.getItem("both_data_types_enabled_for_dispatch") == 1) {
//             var currentUrl = window.location.href;
//             var dataType = localStorage.getItem("data_type") == 1 ? "Inbound" : "Outbound";
//             if (!currentUrl.includes("viewdispatchplandetails")) {
//                 this.setState({
//                     dispatchDataTypecheck: 1,
//                     dispatch_data_type: { "label": dataType, "value": localStorage.getItem("data_type") }
//                 });
//             }
//         }

//         document.addEventListener('mousedown', this.handleClickOutside);
//     }

//     componentWillUnmount() {
//         document.removeEventListener('mousedown', this.handleClickOutside);
//     }

//     handleClickOutside = (event) => {
//         if (this.popupRef.current && !this.popupRef.current.contains(event.target) &&
//             this.triggerRef.current && !this.triggerRef.current.contains(event.target)) {
//             this.setState({ popupVisible: false });
//         }
//     };

//     handleMouseEnterTrigger = () => {
//         this.setState({ popupVisible: true, isMouseOverTrigger: true });
//     };

//     handleMouseLeaveTrigger = () => {
//         this.setState({ isMouseOverTrigger: false }, this.checkMouseLeave);
//     };

//     handleMouseEnterPopup = () => {
//         this.setState({ isMouseOverPopup: true });
//     };

//     handleMouseLeavePopup = () => {
//         this.setState({ isMouseOverPopup: false }, this.checkMouseLeave);
//     };

//     checkMouseLeave = () => {
//         if (!this.state.isMouseOverPopup && !this.state.isMouseOverTrigger) {
//             this.setState({ popupVisible: false });
//         }
//     };

//     logout = async (e) => {
//         e.preventDefault();
//         await localStorage.clear();
//         sessionStorage.setItem('ref', null);
//         await delete axios.defaults.headers.common['Authorization'];
//         this.setState({ redirectToReferrer: '' });
//         window.location.reload();
//     }

//     changeDispatchDataType = (event) => {
//         if (event.value !== undefined && event.value !== "") {
//             localStorage.setItem("data_type", event.value);
//             var pathend = event.value == 1 ? "inbound" : "outbound";
//             var path = "loadprocessrun?" + pathend;
//             window.location.href = path;
//         }
//     }

//     dispathTypeOptns = () => {
//         var optns = [
//             { "label": "Inbound", "value": 1 },
//             { "label": "Outbound", "value": 2 }
//         ];
//         return optns;
//     }

//     render() {
//         if (Cookies.get("token") === "" || Cookies.get("token") === undefined || Cookies.get("token") === null ||
//             localStorage.getItem("token") === "" || localStorage.getItem("token") === undefined || localStorage.getItem("token") === null) {
//             return <Navigate to={"/login"} push={true} />
//         }

//         var moduleTitle = "";
//         switch (localStorage.getItem("module_type")) {
//             case "1": moduleTitle = "Dispatch Planning"; break;
//             case "2": moduleTitle = "Stacking"; break;
//             case "3": moduleTitle = "Production Planning"; break;
//             case "4": moduleTitle = "Forecasting"; break;
//             case "5": moduleTitle = "Resource Scheduling"; break;
//             default: moduleTitle = ""; break;
//         }

//         var userManualPath = require('../../assets/json/Nestle_Optirun_User_ Manual V0.1.3.pdf');
//         return (
//             <div className="nav_menu">
//                 <div className="nav toggle" style={{ position: "relative", width: 400 }}>
//                     {localStorage.getItem("user_type") === "SERVICE" ?
//                         <a href={"/trips"} style={{ fontSize: "20px" }}>
//                             <img src={require("../../assets/images/enmovil.png")} alt="enmovil" style={{ width: "170px", height: "40px" }} />
//                             <span style={{ position: "absolute", top: 35, right: 165, color: "#222", fontWeight: "bold", fontSize: "12px" }}>Powered By<img src={require("../../assets/images/enmovil_logo.png")} style={{ width: 90, marginBottom: 5, marginLeft: 5 }}></img></span>
//                             {/* <span className="module-header-text fhttle">{moduleTitle}</span> */}
//                         </a>
//                         :
//                         <a href={"/"} style={{ fontSize: "20px" }}>
//                             <img src={require("../../assets/images/enmovil.png")} alt="enmovil" style={{ width: "170px", height: "40px" }} />
//                             <span style={{ position: "absolute", top: 35, right: 165, color: "#222", fontWeight: "bold", fontSize: "12px" }}>Powered By<img src={require("../../assets/images/enmovil_logo.png")} style={{ width: 90, marginBottom: 5, marginLeft: 5 }}></img></span>
//                             {/* <span className="module-header-text fhttle">{moduleTitle}</span> */}
//                         </a>
//                     }
//                 </div>
//                 <div className="col-sm-4"></div>
//                 {this.state.dispatchDataTypecheck == 1 ?
//                     <div className="col-sm-3">
//                         <Select
//                             placeholder={"Select"}
//                             onChange={this.changeDispatchDataType}
//                             name="location"
//                             id="selectoptns"
//                             className="mt-5p selectoptns"
//                             value={this.state.dispatch_data_type}
//                             options={this.dispathTypeOptns()}
//                         />
//                     </div>
//                     : ""}
//                 <nav className="nav navbar-nav">
//                     <ul className="navbar-right mb-0 mr-3">
//                     <div className="d-flex justify-content-end align-items-center" style={{ width: "20%", height: "100%", marginLeft: "80px" }}>
//                         <div className="mr-3" style={{minWidth: "150px", width: "230px"}}>
//                             <Select 
//                                value={this.state.language}
//                             options={
//                                 [
//                                     {label: "English", value: "en"},
//                                     {label: "French ( français )", value: "fr"},
//                                     {label: "Spanish ( español )", value: "es"},
//                                     {label: "Arabic ( العربية)", value: "ar"},
//                                     {label: "Telugu ( తెలుగు )", value: "te"},
//                                     {label: "Hindi ( हिंदी )", value: "hi"},
//                                     {label: "Japanese ( 日本語 )", value: "ja"},
//                                     {label: "Chinese ( 中文 )", value: "zh"},
//                                     {label: "Korean ( 한국어 )", value: "ko"},
//                                     {label: "Portuguese ( Português )", value: "pt"}
//                                 ]
//                             }
//                                onChange={(e) => {changeLanguage(e.value); setLanguage(e); onChangingLanguage(e)}}
//                                style={{backgroundColor: "#f1f1f1", borderRadius: "10px"}}
//                                styles={customStyles}
//                             />
//                         </div>
//                     </div>
//                         <div>
//                             <li className='tooltip-container float-right ml-3'>
//                                 <a href={userManualPath} download={userManualPath}>
//                                     <AiOutlineQuestionCircle size={23} style={{ marginRight: "8px", color: "#6e6c69" }} />
//                                     <span className="tooltip-help">Help</span>
//                                 </a>
//                             </li>
//                         </div>
//                         <div>
//                             <li className="nav-item ml-4">
//                                 <a href="/logout" className="user-profile">
//                                     <MdLogout size={22} style={{ fontSize: "20px", marginRight: "5px" }} />
//                                 </a>
//                             </li>

//                             <li
//                                 ref={this.triggerRef}
//                                 className="nav-item ml-2"
//                                 style={{ paddingLeft: "15px", cursor: "pointer" }}
//                                 onMouseEnter={this.handleMouseEnterTrigger}
//                                 onMouseLeave={this.handleMouseLeaveTrigger}
//                             >
//                                 <BsIcons.BsPersonCircle size={22} color={"gray"} />
//                                 {this.state.popupVisible && (
//                                     <div
//                                         ref={this.popupRef}
//                                         className={'popup p-3'}
//                                         onMouseEnter={this.handleMouseEnterPopup}
//                                         onMouseLeave={this.handleMouseLeavePopup}
//                                     >
//                                         <ol className='d-flex flex-column justify-content-center align-items-start pl-2'>
//                                             <div style={{ marginBottom: "7px" }}>
//                                                 <FaUser style={{ fontSize: "16px", marginRight: "9px", color: "#5A738E" }} />
//                                                 <span style={{ color: "#5A738E", marginTop: "2px" }}>{localStorage.getItem("username")}</span>
//                                             </div>
//                                             <li className='mb-1'>
//                                                 <a href="/reset">
//                                                     <IoMdKey style={{ fontSize: "20px", marginRight: "5px" }} />
//                                                     Change Password
//                                                 </a>
//                                             </li>
//                                             <li className='mr-3'>
//                                                 <a href="/logout">
//                                                     <MdLogout style={{ fontSize: "20px", marginRight: "6px" }} />
//                                                     Logout&nbsp;
//                                                 </a>
//                                             </li>
//                                         </ol>
//                                     </div>
//                                 )}
//                             </li>
//                         </div>
//                         {(localStorage.getItem("email") && localStorage.getItem("email").toLocaleLowerCase().includes("srmtech")) && (
//                             <li>
//                                 <img src={require("../../assets/images/srm_logo.png")} style={{ height: "35px" }} alt="SRM Tech Logo" />
//                             </li>
//                         )}
//                     </ul>
//                 </nav>
//             </div>
//         );
//     }
// }

// export default withRouter(Navbar);















import React, { Component } from 'react';
import axios from 'axios';
import { Navigate } from 'react-router-dom';
import withRouter from "../withRouter";
import $ from "jquery";
import Select from 'react-select';
import Cookies from 'js-cookie';
import { IoMdKey } from "react-icons/io";
import { MdLogout } from "react-icons/md";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import * as BsIcons from "react-icons/bs";
import { FaUser } from "react-icons/fa";
import { withTranslation } from 'react-i18next';
import redirectURL from '../redirectURL'

const customStyles = {
    control: (provided) => ({
        ...provided,
        backgroundColor: '#f1f1f1',
        borderRadius: '10px',
        color: '#555',
        border: 'none',
        fontWeight: "bold",
        boxShadow: 'none',
        '&:hover': {
            border: '3px solid rgb(14, 88, 214)',
        },
        border: "1px solid lightgrey",
        minWidth: "100px",
        fontSize: "1vw"
    }),
    placeholder: (provided) => ({
        ...provided,
        color: '#555',
        fontSize: "0.9vw"
    }),
    menu: (provided) => ({
        ...provided,
        borderRadius: '5px',
        zIndex: 9999,
        fontSize: "0.9vw"
    }),
    singleValue: (provided) => ({
        ...provided,
        color: 'blue',
        fontSize: "0.9vw",
        padding: "0px",
        paddingLeft: "0px",
        margin: "0px"
    }),
};

class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dispatchDataTypecheck: 0,
            dispatch_data_type: { "label": "Select Data Type", "value": "" },
            popupVisible: false,
            isMouseOverPopup: false,
            isMouseOverTrigger: false,
            language: null
        };
        this.popupRef = React.createRef();
        this.triggerRef = React.createRef();
    }

    changeLanguage = (language) => {
        console.log(language, 'language384');
        
        const { i18n } = this.props; // Access i18n from props
        if (i18n && typeof i18n.changeLanguage === 'function') {
            i18n.changeLanguage(language); // Change language using i18n
        } else {
            console.error("i18n is not available or changeLanguage is not a function");
        }
    };

    componentDidMount() {
        // console.log(JSON.parse(localStorage.getItem("language")), "language394");
        
        // this.setState({language: JSON.parse(localStorage.getItem("language"))})
        this.setState({language: !["undefined", "null", undefined, null].includes(localStorage.getItem("language")) ? JSON.parse(localStorage.getItem("language")) : { label: "English", value: "en" }})
        if (localStorage.getItem("module_type") === "1" && localStorage.getItem("both_data_types_enabled_for_dispatch") === "1") {
            const currentUrl = window.location.href;
            const dataType = localStorage.getItem("data_type") === "1" ? "Inbound" : "Outbound";
            if (!currentUrl.includes("viewdispatchplandetails")) {
                this.setState({
                    dispatchDataTypecheck: 1,
                    dispatch_data_type: { "label": dataType, "value": localStorage.getItem("data_type") }
                });
            }
        }

        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = (event) => {
        if (this.popupRef.current && !this.popupRef.current.contains(event.target) &&
            this.triggerRef.current && !this.triggerRef.current.contains(event.target)) {
            this.setState({ popupVisible: false });
        }
    };

    handleMouseEnterTrigger = () => {
        this.setState({ popupVisible: true, isMouseOverTrigger: true });
    };

    handleMouseLeaveTrigger = () => {
        this.setState({ isMouseOverTrigger: false }, this.checkMouseLeave);
    };

    handleMouseEnterPopup = () => {
        this.setState({ isMouseOverPopup: true });
    };

    handleMouseLeavePopup = () => {
        this.setState({ isMouseOverPopup: false }, this.checkMouseLeave);
    };

    checkMouseLeave = () => {
        if (!this.state.isMouseOverPopup && !this.state.isMouseOverTrigger) {
            this.setState({ popupVisible: false });
        }
    };

    logout = async (e) => {
        e.preventDefault();
        await localStorage.clear();
        sessionStorage.setItem('ref', null);
        await delete axios.defaults.headers.common['Authorization'];
        this.setState({ redirectToReferrer: '' });
        window.location.reload();
    }

    changeDispatchDataType = (event) => {
        if (event.value !== undefined && event.value !== "") {
            localStorage.setItem("data_type", event.value);
            const pathend = event.value === 1 ? "inbound" : "outbound";
            const path = "loadprocessrun?" + pathend;
            window.location.href = path;
        }
    }
    onChangingLanguage = async (e) => {
        const params = {
            unique_id: localStorage.getItem("unique_id"),
            organization: localStorage.getItem("organization"),
            language: e
        };
        console.log(('params', params));
        
        try {
            const response = await redirectURL.post("/stacking/updateLoginLanguageData", params);
            if (response.data.status === "success") {
                localStorage.setItem("language", JSON.stringify(e));

                // Check the current path and reload the page if necessary
                if (window.location.pathname !== "/overall-dashboard" && window.location.pathname !== "/configurations") {
                    setTimeout(() => {
                        window.location.reload();
                    }, 0);
                }
            }
        } catch (error) {
            console.error("Error updating language:", error);
        }
    };

    handleLanguageChange = (selectedOption) => {
        const language = selectedOption.value;
        console.log(language, 'language487');
        
        // Update the state and then call onChangingLanguage after the state is set
        this.setState({ language: selectedOption }, () => {
            // Change the language after the state is updated
            this.changeLanguage(language);

            // Call the async method to update language on the server
            this.onChangingLanguage(selectedOption);
        });
    };

    dispathTypeOptns = () => {
        const optns = [
            { "label": "Inbound", "value": 1 },
            { "label": "Outbound", "value": 2 }
        ];
        return optns;
    }

    render() {
        const { t } = this.props;
        
        if (Cookies.get("token") === "" || Cookies.get("token") === undefined || Cookies.get("token") === null ||
            localStorage.getItem("token") === "" || localStorage.getItem("token") === undefined || localStorage.getItem("token") === null) {
            return <Navigate to={"/login"} push={true} />
        }

        let moduleTitle = "";
        switch (localStorage.getItem("module_type")) {
            case "1": moduleTitle = "Dispatch Planning"; break;
            case "2": moduleTitle = "Stacking"; break;
            case "3": moduleTitle = "Production Planning"; break;
            case "4": moduleTitle = "Forecasting"; break;
            case "5": moduleTitle = "Resource Scheduling"; break;
            default: moduleTitle = ""; break;
        }

        var userManualPath = require('../../assets/json/Nestle_Optirun_User_ Manual V0.1.3.pdf');
        return (
            <div className="nav_menu">
                <div className="nav toggle" style={{ position: "relative", width: 400 }}>
                    {localStorage.getItem("user_type") === "SERVICE" ?
                        <a href={"/trips"} style={{ fontSize: "20px" }}>
                            <img src={require("../../assets/images/enmovil.png")} alt="enmovil" style={{ width: "170px", height: "40px" }} />
                            <span style={{ position: "absolute", top: 35, right: 165, color: "#222", fontWeight: "bold", fontSize: "12px" }}>
                                Powered By<img src={require("../../assets/images/enmovil_logo.png")} style={{ width: 90, marginBottom: 5, marginLeft: 5 }} alt="enmovil_logo" />
                            </span>
                        </a>
                        :
                        <a href={"/"} style={{ fontSize: "20px" }}>
                            <img src={require("../../assets/images/enmovil.png")} alt="enmovil" style={{ width: "170px", height: "40px" }} />
                            <span style={{ position: "absolute", top: 35, right: 165, color: "#222", fontWeight: "bold", fontSize: "12px" }}>
                                Powered By<img src={require("../../assets/images/enmovil_logo.png")} style={{ width: 90, marginBottom: 5, marginLeft: 5 }} alt="enmovil_logo" />
                            </span>
                        </a>
                    }
                </div>
                <div className="col-sm-4"></div>
                {this.state.dispatchDataTypecheck === 1 ?
                    <div className="col-sm-3">
                        <Select
                            placeholder={t('select')}
                            onChange={this.changeDispatchDataType}
                            name="location"
                            id="selectoptns"
                            className="mt-5p selectoptns"
                            value={this.state.dispatch_data_type}
                            options={this.dispathTypeOptns()}
                        />
                    </div>
                    : null}
                <nav className="nav navbar-nav">
                    <ul className="navbar-right mb-0 mr-3">
                        <div className="d-flex justify-content-end align-items-center" style={(localStorage.getItem("email") && localStorage.getItem("email").toLocaleLowerCase().includes("srmtech")) ? { height: "100%", marginRight: "10px" } : { height: "100%", marginLeft: "10px" }}>
                            <div className="mr-3" style={{ minWidth: "164px", width: "213px" }}>
                                <Select
                                    value={this.state.language}
                                    // options={[
                                    //     { label: "English", value: "en" },
                                    //     { label: "French ( français )", value: "fr" },
                                    //     { label: "Spanish ( español )", value: "es" },
                                    //     { label: "Arabic ( العربية)", value: "ar" },
                                    //     // { label: "Telugu ( తెలుగు )", value: "te" },
                                    //     // { label: "Hindi ( हिंदी )", value: "hi" },
                                    //     { label: "Japanese ( 日本語 )", value: "ja" },
                                    //     { label: "Chinese ( 中文 )", value: "zh" },
                                    //     { label: "Korean ( 한국어 )", value: "ko" },
                                    //     { label: "Portuguese ( Português )", value: "pt" }
                                    // ]}
                                    options={[
                                        { label: "Arabic ( العربية )", value: "ar" },
                                        { label: "Chinese ( 中文 )", value: "zh" },
                                        { label: "English", value: "en" },
                                        { label: "French ( français )", value: "fr" },
                                        { label: "Hindi ( हिंदी )", value: "hi" },
                                        { label: "Japanese ( 日本語 )", value: "ja" },
                                        { label: "Korean ( 한국어 )", value: "ko" },
                                        { label: "Portuguese ( Português )", value: "pt" },
                                        { label: "Spanish ( español )", value: "es" },
                                        { label: "Telugu ( తెలుగు )", value: "te" }
                                    ]}
                                    
                                    onChange={this.handleLanguageChange}
                                    styles={customStyles}
                                />
                            </div>
                        </div>
                        <li className='tooltip-container float-right ml-3'>
                            <a href={userManualPath} download={userManualPath}>
                                <AiOutlineQuestionCircle size={23} style={{ marginRight: "8px", color: "#6e6c69" }} />
                                <span className="tooltip-help">{t('help')}</span>
                            </a>
                        </li>
                        <li className="nav-item ml-4">
                            <a href="/logout" className="user-profile">
                                <MdLogout size={22} style={{ fontSize: "20px", marginRight: "5px" }} />
                            </a>
                        </li>
                        <li
                            ref={this.triggerRef}
                            className="nav-item ml-2"
                            style={{ paddingLeft: "15px", cursor: "pointer" }}
                            onMouseEnter={this.handleMouseEnterTrigger}
                            onMouseLeave={this.handleMouseLeaveTrigger}
                        >
                            <BsIcons.BsPersonCircle size={22} color={"gray"} />
                            {this.state.popupVisible && (
                                <div
                                    ref={this.popupRef}
                                    className={'popup p-3'}
                                    onMouseEnter={this.handleMouseEnterPopup}
                                    onMouseLeave={this.handleMouseLeavePopup}
                                >
                                    <ol className='d-flex flex-column justify-content-center align-items-start pl-2'>
                                        <div style={{ marginBottom: "7px" }}>
                                            <FaUser style={{ fontSize: "16px", marginRight: "9px", color: "#5A738E" }} />
                                            <span style={{ color: "#5A738E", marginTop: "2px" }}>{localStorage.getItem("username")}</span>
                                        </div>
                                        <li className='mb-1'>
                                            <a href="/reset">
                                                <IoMdKey style={{ fontSize: "20px", marginRight: "5px" }} />
                                                {t('change_password')}
                                            </a>
                                        </li>
                                        <li className='mr-3'>
                                            <a href="/logout">
                                                <MdLogout style={{ fontSize: "20px", marginRight: "6px" }} />
                                                {t('logout')}&nbsp;
                                            </a>
                                        </li>
                                    </ol>
                                </div>
                            )}
                        </li>
                        {localStorage.getItem("email") && localStorage.getItem("email").toLocaleLowerCase().includes("srmtech") ? (
                            <li>
                                <img src={require("../../assets/images/srm_logo.png")} style={{ height: "35px" }} alt="SRM Tech Logo" />
                            </li>
                        ) : localStorage.getItem("email") && localStorage.getItem("email").toLocaleLowerCase().includes("intuceo") ? (
                            <li>
                                <img src={require("../../assets/images/intuceo_logo.png")} style={{ height: "35px" }} alt="Intuceo Tech Logo" />
                            </li>
                        ) : ""}
                    </ul>
                </nav>
            </div>
        );
    }
}

export default withRouter(withTranslation()(Navbar));
