import React, { Component } from "react";
import { AllModules } from "@ag-grid-enterprise/all-modules";
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-balham.css"; // Optional theme CSS
import "ag-grid-enterprise";
import i18n from 'i18next';
import { withTranslation } from "react-i18next";
import Constant from "../common/constants";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import redirectURL from "../redirectURL";
import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert";
import GridButton from "../optirun-admin/gridButton";
import RepeatIcon from "../optirun-admin/repeatIcon";
import AssignVehicleBtn from "../optirun-admin/assignvehiclebtn";
import getToolConfiguration from "../optirun-admin/commonFunctions";
import { getHyphenDDMMMYYYYHHMM, getHyphenDDMMMYYYY, getDDMMYYYYHHMM, getDDMMMYYYYHHMMDefault } from "../common/utils";
import ColumnChart from "../optirun-admin/columnChart";
import PieChart from "../optirun-admin/pieChart";
import $ from "jquery";
import LoadSummaryDrawmap from "../optirun-admin/loadSummaryDrawmap.js";
import DrawMapDisptach from "../optirun-admin/drawmapDispatch";
import DrawMapDisptachPallet from "../optirun-admin/drawmapPalletDispatch";
import * as XLSX from "xlsx";
import NAButton from "../optirun-admin/naButton";
import RouteButton from "../optirun-admin/routeBtn";
import UpdateVehicleBtn from "../optirun-admin/updateVehicleBtn";
import RevokeVehicleBtn from "../optirun-admin/RevokeVehicleBtn";
import NewColumnChart from "./colChart.js";
import HeatmapChart from "./vehiclematrix.js";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faClock } from '@fortawesome/free-solid-svg-icons';
// import { faUser } from '@fortawesome/free-solid-svg-icons';
// import { faWeightHanging } from '@fortawesome/free-solid-svg-icons';
// import { faCube } from '@fortawesome/free-solid-svg-icons';
// import { faPercentage } from '@fortawesome/free-solid-svg-icons';
// import { faRoad } from '@fortawesome/free-solid-svg-icons';

// import Counters from "consignmenttruckscountersComponent";
var map;
var moment = require("moment");

 class ViewForecastDispatchPlanDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modules: AllModules,
      defaultColDef: {
        sortable: true,
        filter: true,
        editable: false,
        resizable: true,
        minWidth: 70,
        initialWidth: 200,
        wrapHeaderText: true,
        autoHeaderHeight: true,
      },
      frameworkComponents: {
        GridButton: GridButton,
        RepeatIcon: RepeatIcon,
        AssignVehicleBtn: AssignVehicleBtn,
        NAButton: NAButton,
        RouteButton: RouteButton,
        UpdateVehicleBtn: UpdateVehicleBtn,
        RevokeVehicleBtn: RevokeVehicleBtn
      },
      rowData: [],
      rowSelection: "multiple",
      rowModelhighchartoptionsType: "enterprise",
      rowGroupPanelShow: "always",
      detailCellRendererParams: {},
      statusBar: {
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left",
          },
          {
            statusPanel: "agTotalRowCountComponent",
            align: "center",
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" },
        ],
      },
      paginationPageSize: 20,
      detailCellRendererParams: {},
      rowClassRules: {
        //"highlitRow": "data.transit_delay == 1",
        //"green":"data.status >= 3"
      },
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
          },
          {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel",
          },
        ],
      },
      animateRows: true,
      openVehicleAvailabilityModel: false,
      openFreightConfigurationModel: false,
      show: false,
      basicTitle: "",
      basicType: "default",
      showAvailablityTable: false,
      data_type: 2,
      location: "",
      vehicle_type: { label: "Select Vehicle Type", value: "" },
      state_name: { label: "Select State", value: "" },
      city: "",
      fixed_vehicle_cost: "",
      driver_cost: "",
      maintenance_cost: "",
      per_cmt_cost: "",
      per_km_cost: "",
      rowId: "",
      locationNames: [],
      vehicle_types: [],
      state_names: [],
      transactionDetails: [],
      outputDetails: [],
      inputDetails: [],
      config: "",
      plannedTrucks: "",
      unPlannedTrucks: "",
      loadsDropped: "",
      inputInfo: "",
      planSummary: "",
      configData: "show-n",
      plannedTrucksData: "show-n",
      unPlannedTrucksData: "show-n",
      loadsDroppedData: "show-n",
      inputInfoData: "show-n",
      planSummaryData: "show-n",
      vehicle_type_config: [],
      material_configuration: [],
      freight_configuration: [],
      vehicle_availability_config: [],
      plannedData: [],
      unPlannedData: [],
      loadDroppedDetails: [],
      planningColumnChart: [],
      planningPieChart: [],
      summary: "activet",
      summaryData: "show-m",
      total_demand_cmt: 0,
      total_dropped_loads: 0,
      tool_config: "",
      overlayBlock: "show-n",
      showSlideBlockUpload: "sidebarcls",
      showSlideMap: "sidebarcls",
      showSlideMapLocations: "",
      sliderTitle: "",
      loadingLocation: [],
      mapType: 1,
      latLngArray: [],
      orderId: "",
      transactionId: "",
      trip_gps_data: [],
      plant_loc_data: [],
      trip_nodes_data: [],
      viewcount: 0,
      opti_type: "",
      run_type: "",
      palletLoadLocations: [],
      sla: 1,
      planningWeightColumnChart: [],
      total_demand_weight: 0,
      chartloader1: "show-m",
      chartloader2: "show-m",
      chartloader3: "show-m",
      chartloader4: "show-m",
      showAdditionalPlannedVehicles: 0,
      locationNameInHeader: "",
      allCustomersList: [],
      allOrdersList: [],
      filterCustomer: { value: "", label: "All" },
      filterOrder: { value: "", label: "All" },
      filterPlannedTucksData: 0,
      showSlideAssignVehicle: "",
      assignvehicle: { value: "", label: "Select" },
      vehicleLists: [],
      showAssignFields: "show-n",
      assignrowdata: [],
      tripinfo: [],
      assignVehicleloader: "show-n",
      planningQuantityColumnChart: [],
      selectedTrucks: "show-n",
      vehiclesSelectedData: [],
      selectedVehicle: "show-n",
      activeVehicleList: [],
      openReassignVehicleModel: false,
      shipmentIds: [],
      selectedShipmentId: "",
      forceClose_reason: "gps_issue",
      forceCloseVehicle: "",
      openUpdateVehicleModel: false,
      oldVehicleNO: "",
      oldVehTripNo: "",
      updateVehicleNo: false,
      assign_vehicle_type: "",
      assign_vehicle_list: [],
      total_customers: 0,
      plan_created_date: 0,
      total_tansit_time: 0,
      total_distance: 0,
      total_round_distance: 0,
      VFR: 0,
      adherence: 0,
      total_orders: 0,
      planned_orders: 0,
      loadshow: 'show-n',
      is_reload: '',
      filteredData: [],
      allPlanSummaryData: [],
      minVFR: 0,
      mapFor: '',
      total_planned_quantity: 0,
      total_given_quantity: 0,
      plannedRecords: [],
      un_planned_records: [],
      load_dropped_details: [],
      vehicleUtilizationData: "show-n",
      vehicleMetricsData: "show-n",
      vehicleUtilization: "",
      vehicleMetrics: "",
      forecasting_vehicles: [],
      total_plan_orders: 0,
      total_round_distance: 0,
      planning_percentage: 0,
      VFR_percentage: 0,
      total_transit_time: "",
      kpi_summary: {},
      kpi_summary_loaded: false,
      vehicle_utilization_matrix: {},
      showLoader: "show-n"
    };
  }

  async componentDidMount() {
    loadScript(
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyDxzGDgeuoSs15Y8z-6EFGt2a2QjjPHF3c&libraries=places,drawing&callback=initMapRoutes"
    );
    try {
      window.initMapRoutes = this.initMapRoutes;
      var curl = window.location.search;
      var ul = curl.split("?");
      let url_type = ul[parseInt(ul.length) - 1];
      var transactionId = url_type;
      let orderId;
      if (
        transactionId != undefined &&
        transactionId != "undefined" &&
        transactionId != ""
      ) {
        transactionId = window.atob(transactionId);
        // console.log('transactionId ', transactionId)
        let order = transactionId.split("-");
        orderId = order[0];
      }
      await this.setState({ transactionId: transactionId, orderId: orderId, showLoader: "show-m" });
      let params = {
        transactionId: transactionId,
      };
      // await this.getChartsData(transactionId, orderId);
      // console.log('hello')
      await this.getPlanDetails();
      // await this.getPlanOutputDetails();
    } catch (error) { }
    // await this.getDetailsOfFilteredData()
  }

  onClickBack = () => {
    let logParams = {
      "location_code": this.state.location.value,
      "location_name": this.state.location.label,
      "user_name": localStorage.getItem("username"),
      "useremail": localStorage.getItem("email"),
      "client": localStorage.getItem("client"),
      "screen": "Summary Dashboard",
      "activity": 'clicked on Back Button',
      "event": "Navigated to Summary Dashboard",
      "data_type": this.state.data_type,
    }

    redirectURL.post("/master/loguserUpdatesorChanges", logParams)
  }

  getVehiclesData(params) {

    redirectURL
      .post("/master/getMasterAvailabilityVehiclesData", params)
      .then(async (response) => {
        if (response.data.status === "success") {
          var vehiclesData = response.data.masterVehicleAvailabilityData;
          vehiclesData = vehiclesData.filter((item) => item.vehicle_breakdown !== 1)
          // console.log(vehiclesData)
          var vehicleLists = [];
          var filteredVehicles = [];
          filteredVehicles = vehiclesData;
          if (filteredVehicles.length > 0) {
            filteredVehicles.map((item) => {
              vehicleLists.push({
                value: item.vehicle_no,
                label: item.vehicle_no,
                type: item.vehicle_type,
              });
            });
          }

          // var vehicleLists = [];
          // var filteredVehicles = [];
          // redirectURL
          //   .post("/master/getActiveTripVehicles", params)
          //   .then(async (response) => {
          //     if (response.data.status === "success") {
          //       if (response.data.activeTripVehicles.length > 0) {
          //         // console.log(response.data.activeTripVehicles, "not not");
          //         filteredVehicles = vehiclesData.filter(
          //           (itm) =>
          //             !response.data.activeTripVehicles.some(
          //               (data) => data.truck_no == itm.vehicle_no
          //             )
          //         );
          //       } else {
          //         filteredVehicles = vehiclesData;
          //       }

          //       if (filteredVehicles.length > 0) {
          //         filteredVehicles.map((item) => {
          //           vehicleLists.push({
          //             value: item.vehicle_no,
          //             label: item.vehicle_no,
          //             type: item.vehicle_type,
          //           });
          //         });
          //       }
          //       // console.log(vehicleLists)
          vehicleLists.push({ value: "0", label: "Other", type: 'others' });
          await this.setState({
            vehicleLists: vehicleLists,
          });
          // console.log(vehicleLists)
          //     }
          //   });
        }
      })
      .catch(function (error) {
        var errDetails = {
          url: "/master/getMasterAvailabilityVehiclesData",
          error,
          screen: 'Summary Dashboard'
        }
        redirectURL.post("/master/logErrorsForApiCalls", errDetails)

      })

  }

  getAssignVehiclesNearPlant = (p) => {
    let location = p.location_code;
    let vehicleLists = [];

    redirectURL
      .post("/dispatch/getAssignVehiclesNearPlant")
      .then((response) => {
        if (response.data) {
          if (response.data.truck_data_for_dispatch_plan != undefined) {
            let data = JSON.parse(response.data.truck_data_for_dispatch_plan);
            if (data.length > 0) {
              let filterData = data.filter((itm) => itm.plant_code == location);
              // console.log(filterData, "dwww");
              // this.setState({assignableVehicles:filterData})
              if (filterData.length > 0) {
                filterData.map((item) => {
                  vehicleLists.push({
                    value: item.truck_no,
                    label: item.truck_no,
                    type: item.vehicle_type,
                  });
                });
              }
              vehicleLists.push({ value: "0", label: "Other", type: "others" });
              // console.log(vehicleLists)
              this.setState({
                vehicleLists: vehicleLists,
              });

              redirectURL
                .post("/master/getActiveTripVehicles", p)
                .then(async (response) => {
                  if (response.data.status === "success") {
                    let activeVehicleList = [];
                    let shipmentIds = [];
                    if (response.data.activeTripVehicles.length > 0) {
                      response.data.activeTripVehicles.map((itm) => {
                        activeVehicleList.push(itm.truck_no);
                        shipmentIds.push(itm);
                      });
                      this.setState({ activeVehicleList, shipmentIds });
                    }
                  }
                })
                .catch(function (error) {
                  var errDetails = {
                    url: "/master/getActiveTripVehicles",
                    error,
                    screen: 'Summary Dashboard'
                  }
                  redirectURL.post("/master/logErrorsForApiCalls", errDetails)

                })

            }
          }
        }
      })
      .catch(function (error) {
        var errDetails = {
          url: "/dispatch/getAssignVehiclesNearPlant",
          error,
          screen: 'Summary Dashboard'
        }
        redirectURL.post("/master/logErrorsForApiCalls", errDetails)

      })

  };

  getChartsData = (transactionId, orderId) => {
    var transactionId = transactionId;
    let qry = JSON.parse(localStorage.getItem("qry"));
    // console.log("qry ",qry)
    this.setState({
      planningColumnChart: [],
      planningModeChart: [],
      chartloader: "show-m",
      chartloader1: "show-m",
      chartloader2: "show-m",
      chartloader3: "show-m",
      chartloader4: "show-m",
    });
    try {
      var reqparams = new FormData();
      reqparams.append("transaction_id", transactionId);
      reqparams.append("order_id", orderId);
      reqparams.append("plant_location_code", qry.plant_location_code);
      reqparams.append("dispatch_planning_type", qry.dispatch_planning_type);

      // redirectURL.post("http://216.48.191.229:8007/dispatch_planning/transactionLevelChartsData",reqparams)

      redirectURL
        .post("dispatch/dispatchplandetails", reqparams)
        .then(async (response) => {
          // console.log("apiResponse ", response.data)
          let planData = JSON.parse(response.data.planning_chart_data);
          let planWeightData = JSON.parse(
            response.data.planning_chart_weight_data
          );
          let planQuantityData = JSON.parse(
            response.data.planning_chart_quantity_data
          );
          // let dropData = JSON.parse(response.data.dropped_reason_chart_data)
          // let total_dropped_loads = dropData[0].total_dropped_loads;
          let total_demand_cmt = planData[0].total_demand_volume;
          let total_demand_weight = planWeightData[0].total_demand_weight;
          let total_quantity_data =
            planQuantityData[0].planning_chart_quantity_data;
          let planningColumnChart = [
            {
              name: "Dispatch Load Volume Summary (M3)",
              colorByPoint: true,
              data: [
                {
                  name: "Total Loads Volume (M3)",
                  y: planData[0].total_demand_volume,
                },
                {
                  name: "Planned Loads Volume (M3)",
                  y: planData[0].planned_demand_volume,
                },
                {
                  name: "Unplanned Loads Volume (M3)",
                  y: planData[0].unplanned_demand_volume,
                },
                {
                  name: "Dropped Loads Volume (M3)",
                  y: planData[0].dropped_demand_volume,
                },
              ],
            },
          ];

          let planningWeightColumnChart = [
            {
              name: "Dispatch Load Weight Summary (Kgs)",
              colorByPoint: true,
              data: [
                {
                  name: "Total Loads Weight (Kgs)",
                  y: planWeightData[0].total_demand_weight,
                },
                {
                  name: "Planned Loads Weight (Kgs)",
                  y: planWeightData[0].planned_demand_weight,
                },
                {
                  name: "Unplanned Loads Weight (Kgs)",
                  y: planWeightData[0].unplanned_demand_weight,
                },
                {
                  name: "Dropped Loads Weight (Kgs)",
                  y: planWeightData[0].dropped_demand_weight,
                },
              ],
            },
          ];

          let planningQuantityColumnChart = [
            {
              name: "Dispatch Load Quantity Summary",
              colorByPoint: true,
              data: [
                {
                  name: "Total Quantity",
                  y: planQuantityData[0].total_quantity,
                },
                {
                  name: "Planned Quantity",
                  y: planQuantityData[0].planned_quantity,
                },
                {
                  name: "Unplanned Quantity",
                  y: planQuantityData[0].unplanned_quantity,
                },
                {
                  name: "Dropped Quantity",
                  y: planQuantityData[0].dropped_quantity,
                },
              ],
            },
          ];
          //    let planningPieChart = [{
          //     name:"Dropped",
          //     colorByPoint: true,
          //     data :  [
          //         {
          //          name:"Total Excluded Passengers",
          //          y: (dropData[0].total_dropped_loads)
          //         },{
          //          name:"Invalid Locations",
          //          y: (dropData[0].invalid_locations)
          //         },
          //     //     {
          //     //     name:"Invalid Materials",
          //     //     y: (dropData[0].invalid_materials)
          //     // }
          // ]
          //    }]

          await this.setState(
            {
              planningColumnChart: planningColumnChart,
              planningWeightColumnChart: planningWeightColumnChart,
              planningQuantityColumnChart: planningQuantityColumnChart,
              // planningPieChart:planningPieChart,
              // total_dropped_loads:total_dropped_loads,
              total_demand_cmt: total_demand_cmt,
              total_demand_weight: total_demand_weight,
              total_quantity_data: total_quantity_data,
              total_planned_quantity: planQuantityData[0].planned_quantity,
              total_given_quantity: planQuantityData[0].total_quantity
            },
            async () => {
              await setTimeout(() => {
                this.setState(
                  {
                    chartloader1: "show-n",
                  },
                  () => {
                    setTimeout(() => {
                      this.setState(
                        {
                          chartloader2: "show-n",
                        },
                        () => {
                          setTimeout(() => {
                            this.setState(
                              {
                                chartloader3: "show-n",
                              },
                              () => {
                                setTimeout(() => {
                                  this.setState({
                                    chartloader4: "show-n",
                                  });
                                }, 500);
                              }
                            );
                          }, 400);
                        }
                      );
                    }, 300);
                  }
                );
              }, 2500);
            }
          );
        })
        .catch(function (error) {
          var errDetails = {
            url: "dispatch/dispatchplandetails",
            screen: 'Summary Dashboard',
            error,

          }
          redirectURL.post("/master/logErrorsForApiCalls", errDetails)

        })

    } catch (e) { }
  };

  getPlanDetails = () => {
    let locationNameInHeader;
    var params = {
      transactionId: this.state.transactionId,
      // orderId: this.state.orderId
    };

    redirectURL
      .post("/forecastvehicle/getForecastDetails", params)
      .then(async (response) => {

        if (response.data.status === "success") {
          const data = response.data.planned_records;
          const groupedData = new Map();

          data.forEach(item => {
            const tripInfo = item.trip_no;
            // const date = `${tripInfo[0]}-${tripInfo[1]}-${tripInfo[2]}`;
            const date = item.execution_date;
            const trip = tripInfo;
            const key = `${date}-${trip}`;
            // console.log("hui")
            if (groupedData.has(key)) {
              const existingItem = groupedData.get(key);
              existingItem.quantity += item.quantity;
              existingItem.demand_volume += item.demand_volume;
              existingItem.demand_weight += item.demand_weight;
              existingItem.distance = item.distance;
              existingItem.no_of_drops = existingItem.groupedArray.length + 1;
              existingItem.no_of_customer = existingItem.groupedArray.length + 1;
              existingItem.groupedArray.push(item);
            } else {
              groupedData.set(key, {
                trip_no: key,
                quantity: item.quantity,
                demand_volume: item.demand_volume.toFixed(2),
                demand_weight: item.demand_weight.toFixed(2),
                distance: item.distance,
                no_of_drops: 1,
                no_of_customer: 1,
                groupedArray: [item],
                ...item
              });
            }
          });
          const combinedResult = Array.from(groupedData.values());
          console.log(combinedResult, "combinedResult")
          var total_orders = 0
          var total_round_distance = 0
          var finalVFR = 0
          var weight_utilization = 0
          var volume_utilization = 0
          var transit_time_hrs = 0
          var transit_time_min = 0
          var planning_percentage = 0
          combinedResult.map((each) => {
            total_orders += parseInt(each.no_of_drops)
            total_round_distance += parseInt(each.round_trip_distance)
            var time_veh = each.total_drop_node_transit_time_hrs.split(':')
            transit_time_hrs += parseInt(time_veh[0])
            transit_time_min += parseInt(time_veh[1])
            weight_utilization = parseFloat((
              (parseFloat(each.demand_weight) / parseFloat(each.vehicle_max_weight)) *
              100
            ))
            volume_utilization = parseFloat((
              (parseFloat(each.demand_volume) / parseFloat(each.vehicle_max_volume)) *
              100
            ))
            if (weight_utilization > volume_utilization) {
              finalVFR += weight_utilization
            } else {
              finalVFR += volume_utilization
            }
          })
          planning_percentage = total_orders / (total_orders + response.data.un_planned_records.length)
          planning_percentage = (planning_percentage * 100).toFixed(2);
          var VFR_percentage = (finalVFR / combinedResult.length).toFixed(2)
          if (transit_time_min > 60) {
            transit_time_hrs += parseInt(transit_time_min / 60)
            transit_time_min -= (parseInt(transit_time_min / 60) * 60)
          }
          if (transit_time_hrs < 10) {
            transit_time_hrs = '0' + transit_time_hrs
          }
          if (transit_time_min < 10) {
            transit_time_min = '0' + transit_time_min
          }
          console.log(response.data.planned_records, "total_orders")
          try {
            this.setState({
              inputDetails: response.data.records,
              showLoader: "show-n",
              vehiclesSelectedData: JSON.parse(response.data.config_records[0].vehicle_type_config),
              plannedRecords: response.data.planned_records,
              un_planned_records: response.data.un_planned_records,
              load_dropped_details: response.data.load_dropped_details,
              forecasting_vehicles: response.data.forecasting_vehicles,
              kpi_summary: response.data.kpi_summary.length != 0 && response.data.kpi_summary[0].kpi_summary,
              vehicle_utilization_matrix: response.data.kpi_summary.length != 0 && response.data.kpi_summary[0].vehicle_utilization_matrix,
              plan_summary_records: combinedResult,
              total_plan_orders: total_orders,
              total_round_distance: total_round_distance,
              planning_percentage: planning_percentage,
              VFR_percentage: VFR_percentage,
              total_transit_time: transit_time_hrs + ':' + transit_time_min,
              kpi_summary_loaded: true,
            });
          } catch (e) {

          }
        }
      })
      .catch(function (error) {
        var errDetails = {
          url: "forecastvehicle/getForecastDetails",
          screen: 'Vehicle Forecast Dashboard',
          error,

        }
        redirectURL.post("/master/logErrorsForApiCalls", errDetails)

      })

  };

  boundTypeLocations = async (param) => {
    let locationNames = this.state.locationNames;

    await redirectURL
      .post("master/getBoundTypeLocations", param)
      .then(async (response) => {
        if (response.data.status == "success") {
          let boundLocations = response.data.boundLocations;
          if (boundLocations.length > 0) {
            boundLocations.map((item) => {
              locationNames.push({
                value: item.location_code,
                label: item.location_name,
                latitude: item.latitude,
                longitude: item.longitude,
              });
            });
            await this.setState({
              locationNames: locationNames,
              location: locationNames[0],
            });
          }
        }
      })
      .catch(function (error) {
        var errDetails = {
          url: "master/getBoundTypeLocations",
          screen: 'Summary Dashboard',
          error,

        }
        redirectURL.post("/master/logErrorsForApiCalls", errDetails)

      })

  };

  getPlanOutputDetails = () => {
    var params = {
      transactionId: this.state.transactionId,
    };

    redirectURL
      .post("dispatch/getTransactionOutputDetails", params)
      .then(async (response) => {
        var outputDetails = this.state.plannedRecords;
        // console.log("outputDetails ", outputDetails)
        var plannedData = [];
        var unPlannedData = [];
        var allCustomersList = [];
        var allOrdersList = [];
        if (outputDetails.length > 0) {
          var plannedIndividualData = await outputDetails.filter(
            (od) => od.is_planned == 1
          );
          if (plannedIndividualData.length > 0) {
            try {
              plannedIndividualData = plannedIndividualData.sort(
                GetSortAscOrder("trip_no")
              );
              var groupPlannedData = groupBy(
                plannedIndividualData,
                (rdata) => rdata.trip_no
              );
              // groupPlannedData1.forEach((plannedVehicleData1, key) =>{
              //     plannedVehicleData1 = plannedVehicleData1.sort(GetSortAscOrder("trip_no"));
              //     var groupPlannedData = groupBy(plannedVehicleData1, rdata => rdata.trip_no);
              // console.log("groupPlannedData ", groupPlannedData)
              groupPlannedData.forEach((plannedVehicleData, k) => {
                var demand_volume = 0;
                var demand_weight = 0;
                var demand_quantity = 0;
                var distance = 0;
                var triptime = 0;
                var transitTime = 0;
                var unLoadingTime = [];
                var loadingTime = [];
                var totaltires = 0;
                var latLngArray = [];
                var customers = [];
                plannedVehicleData.map((pvd) => {
                  // console.log('pve,',pvd)
                  allCustomersList.push({
                    customer_code: pvd.drop_node,
                    customer_name: pvd.drop_node_name,
                  });
                  allOrdersList.push({ orderNo: pvd.order_number });

                  demand_volume =
                    parseFloat(demand_volume) + parseFloat(pvd.demand_volume);
                  demand_weight =
                    parseFloat(demand_weight) + parseFloat(pvd.demand_weight);
                  demand_quantity =
                    parseInt(demand_quantity) + parseInt(pvd.quantity);
                  distance = pvd.distance;
                  triptime = pvd.drop_node_total_time_hrs;
                  // transitTime = pvd.drop_node_transit_time_hrs;
                  loadingTime.push(pvd.drop_node_loading_time_mins);
                  unLoadingTime.push(pvd.drop_node_unloading_time_mins);
                  latLngArray.push({
                    lat: pvd.demand_node_latitude,
                    lng: pvd.demand_node_longitude,
                    drop_node: pvd.drop_node,
                    vehicle_type: pvd.vehicle_type,
                    demand_volume: pvd.demand_volume,
                    demand_weight: pvd.demand_weight,
                  });
                  totaltires = parseInt(totaltires) + parseInt(pvd.no_of_tires);
                  customers.push({ customer: pvd.drop_node });
                });
                var customerArray = [];
                let totuniqueObject = {};
                var totobjTitle;
                loadingTime = addTimes(loadingTime);
                unLoadingTime = addTimes(unLoadingTime);
                // Loop for the array elements
                for (let i in customers) {
                  // Extract the title
                  if (
                    customers[i]["customer"] !== "" &&
                    customers[i]["customer"] !== undefined
                  ) {
                    totobjTitle = customers[i]["customer"];

                    // Use the title as the index
                    totuniqueObject[totobjTitle] = customers[i];
                  }
                }
                // Loop to push unique object into array
                for (var a in totuniqueObject) {
                  customerArray.push(totuniqueObject[a]);
                }
                // console.log("customerArray ", customerArray)
                plannedData.push({
                  vehicle_no: plannedVehicleData[0].vehicle_no,
                  cluster: plannedVehicleData[0].cluster,
                  zone: plannedVehicleData[0].zone,
                  pickup_node: plannedVehicleData[0].pickup_node,
                  drop_node: plannedVehicleData[0].drop_node,
                  total_demand: parseFloat(demand_volume).toFixed(2),
                  total_weight: parseFloat(demand_weight).toFixed(2),
                  total_quantity: parseInt(demand_quantity),
                  distance: distance,
                  triptime: triptime,
                  route_no: plannedVehicleData[0].route_no,
                  is_available: plannedVehicleData[0].is_available,
                  vehicle_type: plannedVehicleData[0].vehicle_type,
                  vehicle_start_time_mins:
                    plannedVehicleData[0].vehicle_start_time_mins,
                  vehicle_loading_start_time:
                    plannedVehicleData[0].vehicle_loading_start_time,
                  vehicle_volume: plannedVehicleData[0].vehicle_volume,
                  vehicle_priority: plannedVehicleData[0].vehicle_priority,
                  transporter_code: plannedVehicleData[0].transporter_code,
                  transporter_name: plannedVehicleData[0].transporter_name,
                  route_mode: plannedVehicleData[0].route_mode,
                  order_date: plannedVehicleData[0].order_date,
                  no_of_tires: totaltires,
                  no_of_drops: plannedVehicleData.length,
                  no_of_customer: customerArray.length,
                  legs_data: plannedVehicleData,
                  latLngArray: latLngArray,
                  trip_no: plannedVehicleData[0].trip_no,
                  vehicle_max_volume: plannedVehicleData[0].vehicle_max_volume,
                  vehicle_max_weight: plannedVehicleData[0].vehicle_max_weight,
                  order_number: plannedVehicleData[0].order_number,
                  invoicing_date: plannedVehicleData[0].invoicing_date,
                  quantity: plannedVehicleData[0].quantity,
                  round_trip_distance:
                    plannedVehicleData[0].round_trip_distance,
                  arrival_time_at_first_dealer:
                    plannedVehicleData[0].arrival_time_at_first_dealer,
                  last_dealer_time_to_complete:
                    plannedVehicleData[0].last_dealer_time_to_complete,
                  return_time_to_plant_from_last_dealer:
                    plannedVehicleData[0].return_time_to_plant_from_last_dealer,
                  round_trip_time: plannedVehicleData[0].round_trip_time,
                  total_drop_node_transit_time_hrs:
                    plannedVehicleData[0].total_drop_node_transit_time_hrs,
                  // transitTime: transitTime,
                  total_plan_time_hrs:
                    plannedVehicleData[0].total_plan_time_hrs,
                  // loadingTime:loadingTime,
                  // unLoadingTime:unLoadingTime
                  loadingTime: plannedVehicleData[0].total_loading_time,
                  unLoadingTime: plannedVehicleData[0].total_unloading_time,
                  route_finalized: plannedVehicleData[0].route_finalized,
                  max_start_time: plannedVehicleData[0].max_start_time,
                });
                // console.log(plannedData, "planned data");




                var total_customers = 0;
                var total_turn_time = 0;
                var transit_time_min = 0
                var transit_time_hrs = 0
                var total_distance = 0
                var total_round_distance = 0
                var total_planned_orders = 0
                var weight_utilization = 0, volume_utilization = 0, finalVfr = 0

                plannedData.map((item) => {
                  total_planned_orders += item.no_of_drops;
                  total_customers += item.no_of_customer;
                  total_turn_time += item.round_trip_time;
                  var time_veh = item.total_drop_node_transit_time_hrs.split(':')
                  transit_time_hrs += parseInt(time_veh[0])
                  transit_time_min += parseInt(time_veh[1])
                  total_distance += item.distance
                  total_round_distance += item.round_trip_distance
                  weight_utilization = parseFloat((
                    (parseFloat(item.total_weight) / parseFloat(item.vehicle_max_weight)) *
                    100
                  ))
                  volume_utilization = parseFloat((
                    (parseFloat(item.total_demand) / parseFloat(item.vehicle_max_volume)) *
                    100
                  ))
                  if (weight_utilization > volume_utilization) {
                    finalVfr += weight_utilization
                  } else {
                    finalVfr += volume_utilization
                  }
                });

                if (transit_time_min > 60) {
                  transit_time_hrs += parseInt(transit_time_min / 60)
                  transit_time_min -= (parseInt(transit_time_min / 60) * 60)
                }

                var planned_data_len = plannedData.length;
                var VFR = (finalVfr / planned_data_len).toFixed(2)
                if (transit_time_hrs < 10) {
                  transit_time_hrs = '0' + transit_time_hrs
                }
                if (transit_time_min < 10) {
                  transit_time_min = '0' + transit_time_min
                }
                this.setState({
                  total_tansit_time: transit_time_hrs + ':' + transit_time_min,
                  total_distance: total_distance,
                  total_round_distance: parseInt(total_round_distance),
                  VFR,
                  planned_orders: total_planned_orders,
                  total_customers
                  // adherence:((total_planned_orders/total_orders)*100).toFixed(2)
                });
              });
              // });
            } catch (error) {
              // console.log(error, "errer");
            }
          }
          unPlannedData = await outputDetails.filter(
            (od) => od.is_planned != 1
          );

          var customerlistsdata = [{ value: "", label: "All" }];
          if (allCustomersList.length > 0) {
            var customernewArray = [];

            let totuniqueObject = {};
            var totobjTitle;

            for (let i in allCustomersList) {
              if (
                allCustomersList[i]["customer_code"] !== "" &&
                allCustomersList[i]["customer_code"] !== undefined
              ) {
                totobjTitle = allCustomersList[i]["customer_code"];
                totuniqueObject[totobjTitle] = allCustomersList[i];
              }
            }
            for (var a in totuniqueObject) {
              customernewArray.push(totuniqueObject[a]);
            }
            if (customernewArray.length > 0) {
              customernewArray.map((item) => {
                customerlistsdata.push({
                  value: item.customer_code,
                  label: item.customer_code + " - " + item.customer_name,
                });
              });
            }
          }

          var orderlistsdata = [{ value: "", label: "All" }];
          if (allOrdersList.length > 0) {
            var ordernewArray = [];

            let totuniqueObject = {};
            var totobjTitle;

            for (let i in allOrdersList) {
              if (
                allOrdersList[i]["orderNo"] !== "" &&
                allOrdersList[i]["orderNo"] !== undefined
              ) {
                totobjTitle = allOrdersList[i]["orderNo"];
                totuniqueObject[totobjTitle] = allOrdersList[i];
              }
            }
            for (var a in totuniqueObject) {
              ordernewArray.push(totuniqueObject[a]);
            }
            if (ordernewArray.length > 0) {
              ordernewArray.map((item) => {
                orderlistsdata.push({
                  value: item.orderNo,
                  label: item.orderNo,
                });
              });
            }
          }
        }
        console.log(unPlannedData, "unPlannedData")
        this.setState({
          outputDetails: response.data,
          plannedData: plannedData,
          unPlannedData: unPlannedData,
          allCustomersList: customerlistsdata,
          allOrdersList: orderlistsdata,
          filteredData: plannedData
        });
      })
      .catch(function (error) {
        var errDetails = {
          url: "dispatch/getTransactionOutputDetails",
          screen: 'Summary Dashboard',
          error,

        }
        redirectURL.post("/master/logErrorsForApiCalls", errDetails)

      })

  };

  //   getPlanInputDetails = () => {
  //     var params = {
  //       // transactionId : this.state.transactionId
  //       order_id: this.state.orderId,
  //       transaction_id: this.state.transactionId,
  //     };

  //     redirectURL
  //       .post("dispatch/getTransactionRequestInputDetails", params)

  //       .then(async (response) => {
  //         // console.log(response.data[0].created_on.split("T")[0]);
  //         var [year, mon, day] = response.data[0].created_on
  //           .split("T")[0]
  //           .split("-");

  //         var [hr, min] = response.data[0].created_on.split("T")[1].split(":");
  //         var date = day + "-" + mon + "-" + year + " " + hr + ":" + min;
  //         this.setState({
  //           created_on: date,
  //         });
  //         var inputDetails = response.data;
  //         // console.log(inputDetails.length)
  //         var validInputDetails = [];
  //         var invalidInputDetails = [];
  //         if (inputDetails.length > 0) {
  //           validInputDetails = await inputDetails.filter(
  //             (od) => od.invalid_data != 1
  //           );
  //           invalidInputDetails = await inputDetails.filter(
  //             (od) => od.invalid_data == 1
  //           );
  //         }

  // var total_orders=inputDetails.length
  // // console.log(total_orders)
  //         this.setState({
  //           inputDetails: validInputDetails,
  //           loadDroppedDetails: invalidInputDetails,
  //           total_orders

  //         });
  //       })
  //       .catch(function (error) {
  //         var errDetails={
  //           url:"dispatch/getTransactionRequestInputDetails",
  //           screen:'Summary Dashboard',
  //           error,

  //       }
  //         redirectURL.post("/master/logErrorsForApiCalls",errDetails)

  //     })

  //   };

  closeAlert = async () => {
    await this.setState({
      show: false,
    });
  };
  onClickTab = (pageType) => {
    if (pageType == "config") {
      var config = "activet";
      var plannedTrucks = "";
      var unPlannedTrucks = "";
      var loadsDropped = "";
      var inputInfo = "";
      var summary = "";
      var planSummary = "";
      var configData = "show-m";
      var plannedTrucksData = "show-n";
      var unPlannedTrucksData = "show-n";
      var loadsDroppedData = "show-n";
      var inputInfoData = "show-n";
      var summaryData = "show-n";
      var planSummaryData = "show-n";
      var selectedTrucks = "show-n";
      var selectedVehicle = "show-n";
      var vehicleUtilizationData = "show-n";
      var vehicleMetricsData = "show-n";
      var vehicleUtilization = "show-n";
      var vehicleMetrics = "show-n";
      var kpi_summary_loaded = false;
    } else if (pageType == "plannedTrucks") {
      var config = "";
      var plannedTrucks = "activet";
      var unPlannedTrucks = "";
      var loadsDropped = "";
      var inputInfo = "";
      var summary = "";
      var planSummary = "";
      var configData = "show-n";
      var plannedTrucksData = "show-m";
      var unPlannedTrucksData = "show-n";
      var loadsDroppedData = "show-n";
      var inputInfoData = "show-n";
      var summaryData = "show-n";
      var planSummaryData = "show-n";
      var selectedTrucks = "show-n";
      var selectedVehicle = "show-n";
      var vehicleUtilizationData = "show-n";
      var vehicleMetricsData = "show-n";
      var vehicleUtilization = "show-n";
      var vehicleMetrics = "show-n";
      var kpi_summary_loaded = false;
      // this.getDetailsOfFilteredData()
    } else if (pageType == "unPlannedTrucks") {
      var config = "";
      var plannedTrucks = "";
      var unPlannedTrucks = "activet";
      var loadsDropped = "";
      var inputInfo = "";
      var summary = "";
      var planSummary = "";
      var configData = "show-n";
      var plannedTrucksData = "show-n";
      var unPlannedTrucksData = "show-m";
      var loadsDroppedData = "show-n";
      var inputInfoData = "show-n";
      var summaryData = "show-n";
      var planSummaryData = "show-n";
      var selectedTrucks = "show-n";
      var selectedVehicle = "show-n";
      var vehicleUtilizationData = "show-n";
      var vehicleMetricsData = "show-n";
      var vehicleUtilization = "show-n";
      var vehicleMetrics = "show-n";
      var kpi_summary_loaded = false;
    } else if (pageType == "loadsDropped") {
      var config = "";
      var plannedTrucks = "";
      var unPlannedTrucks = "";
      var loadsDropped = "activet";
      var inputInfo = "";
      var summary = "";
      var planSummary = "";
      var configData = "show-n";
      var plannedTrucksData = "show-n";
      var unPlannedTrucksData = "show-n";
      var loadsDroppedData = "show-m";
      var inputInfoData = "show-n";
      var summaryData = "show-n";
      var planSummaryData = "show-n";
      var selectedTrucks = "show-n";
      var selectedVehicle = "show-n";
      var vehicleUtilizationData = "show-n";
      var vehicleMetricsData = "show-n";
      var vehicleUtilization = "show-n";
      var vehicleMetrics = "show-n";
      var kpi_summary_loaded = false;
    } else if (pageType == "inputInfo") {
      var config = "";
      var plannedTrucks = "";
      var unPlannedTrucks = "";
      var loadsDropped = "";
      var inputInfo = "activet";
      var summary = "";
      var planSummary = "";
      var configData = "show-n";
      var plannedTrucksData = "show-n";
      var unPlannedTrucksData = "show-n";
      var loadsDroppedData = "show-n";
      var inputInfoData = "show-m";
      var summaryData = "show-n";
      var planSummaryData = "show-n";
      var selectedTrucks = "show-n";
      var selectedVehicle = "show-n";
      var vehicleUtilizationData = "show-n";
      var vehicleMetricsData = "show-n";
      var vehicleUtilization = "show-n";
      var vehicleMetrics = "show-n";
      var kpi_summary_loaded = false;
    } else if (pageType == "summary") {
      var config = "";
      var plannedTrucks = "";
      var unPlannedTrucks = "";
      var loadsDropped = "";
      var inputInfo = "";
      var summary = "activet";
      var planSummary = "";
      var configData = "show-n";
      var plannedTrucksData = "show-n";
      var unPlannedTrucksData = "show-n";
      var loadsDroppedData = "show-n";
      var inputInfoData = "show-n";
      var summaryData = "show-m";
      var planSummaryData = "show-n";
      var selectedTrucks = "show-n";
      var selectedVehicle = "show-n";
      var vehicleUtilizationData = "show-n";
      var vehicleMetricsData = "show-n";
      var vehicleUtilization = "show-n";
      var vehicleMetrics = "show-n";
      var kpi_summary_loaded = true
    } else if (pageType == "planSummary") {
      var config = "";
      var plannedTrucks = "";
      var unPlannedTrucks = "";
      var loadsDropped = "";
      var inputInfo = "";
      var summary = "";
      var planSummary = "activet";
      var configData = "show-n";
      var plannedTrucksData = "show-n";
      var unPlannedTrucksData = "show-n";
      var loadsDroppedData = "show-n";
      var inputInfoData = "show-n";
      var summaryData = "show-n";
      var planSummaryData = "show-m";
      var selectedTrucks = "show-n";
      var selectedVehicle = "show-n";
      var vehicleUtilizationData = "show-n";
      var vehicleMetricsData = "show-n";
      var vehicleUtilization = "show-n";
      var vehicleMetrics = "show-n";
      var kpi_summary_loaded = false;
      // this.getDetailsOfFilteredData()
    } else if (pageType == "selectedTrucks") {
      var config = "";
      var plannedTrucks = "";
      var unPlannedTrucks = "";
      var loadsDropped = "";
      var inputInfo = "";
      var summary = "";
      var planSummary = "";
      var configData = "show-n";
      var plannedTrucksData = "show-n";
      var unPlannedTrucksData = "show-n";
      var loadsDroppedData = "show-n";
      var inputInfoData = "show-n";
      var summaryData = "show-n";
      var planSummaryData = "show-n";
      var selectedTrucks = "activet";
      var selectedVehicle = "show-m";
      var vehicleUtilizationData = "show-n";
      var vehicleMetricsData = "show-n";
      var vehicleUtilization = "show-n";
      var vehicleMetrics = "show-n";
      var kpi_summary_loaded = false;
    } else if (pageType == "vehicleUtilization") {
      var config = "";
      var plannedTrucks = "";
      var unPlannedTrucks = "";
      var loadsDropped = "";
      var inputInfo = "";
      var summary = "";
      var planSummary = "";
      var configData = "show-n";
      var plannedTrucksData = "show-n";
      var unPlannedTrucksData = "show-n";
      var loadsDroppedData = "show-n";
      var inputInfoData = "show-n";
      var summaryData = "show-n";
      var planSummaryData = "show-n";
      var selectedTrucks = "show-n";
      var selectedVehicle = "show-n";
      var vehicleUtilizationData = "show-m"
      var vehicleMetricsData = "show-n";
      var vehicleUtilization = "activet";
      var vehicleMetrics = "show-n";
      var kpi_summary_loaded = false;
    } else if (pageType == "vehicleMetrics") {
      var config = "";
      var plannedTrucks = "";
      var unPlannedTrucks = "";
      var loadsDropped = "";
      var inputInfo = "";
      var summary = "";
      var planSummary = "";
      var configData = "show-n";
      var plannedTrucksData = "show-n";
      var unPlannedTrucksData = "show-n";
      var loadsDroppedData = "show-n";
      var inputInfoData = "show-n";
      var summaryData = "show-n";
      var planSummaryData = "show-n";
      var selectedTrucks = "show-n";
      var selectedVehicle = "show-n";
      var vehicleUtilizationData = "show-n";
      var vehicleMetricsData = "show-m";
      var vehicleUtilization = "show-n";
      var vehicleMetrics = "activet";
      var kpi_summary_loaded = false;
    }
    this.setState({
      config: config,
      plannedTrucks: plannedTrucks,
      unPlannedTrucks: unPlannedTrucks,
      loadsDropped: loadsDropped,
      inputInfo: inputInfo,
      configData: configData,
      summary: summary,
      planSummary: planSummary,
      plannedTrucksData: plannedTrucksData,
      unPlannedTrucksData: unPlannedTrucksData,
      loadsDroppedData: loadsDroppedData,
      inputInfoData: inputInfoData,
      summaryData: summaryData,
      planSummaryData: planSummaryData,
      selectedTrucks,
      selectedVehicle,
      vehicleUtilizationData: vehicleUtilizationData,
      vehicleMetricsData: vehicleMetricsData,
      vehicleUtilization: vehicleUtilization,
      vehicleMetrics: vehicleMetrics,
      kpi_summary_loaded: kpi_summary_loaded
    });
  };

  hideSlideBlock = async () => {
    // console.log('hit')
    await this.setState({
      overlayBlock: "show-n",
      showSlideBlockUpload: "",
      showSlideAssignVehicle: "",
      showSlideMap: "",
      showSlideMapLocations: "",
      loadshow: "show-n",
      overly: "show-n",
      showAdditionalPlannedVehicles: 0,
      updateVehicleNo: false,
      assignvehicle: { value: "", label: "Select" },
      showAssignFields: "show-n",
      other_vehicle_no: "",
      mapFor: ''
    });
  };
  onRouteData = async (row) => {

    let logParams = {
      "location_code": this.state.location.value,
      "location_name": this.state.location.label,
      "user_name": localStorage.getItem("username"),
      "useremail": localStorage.getItem("email"),
      "client": localStorage.getItem("client"),
      "screen": "planned Summary ",
      "activity": 'clicked on Route Icon in aggrid',
      "event": "Slider opened with a map",
      "data_type": this.state.data_type,
    }

    redirectURL.post("/master/loguserUpdatesorChanges", logParams)
    await this.setState({
      overlayBlock: "show-m",
      tripinfo: [row.data],
      mapFor: 'viewRoute'
    });
    // console.log("allroutes ",this.state.mapFor)
    var loadingLocation = [];
    if (this.state.location != undefined && this.state.location != "") {
      loadingLocation.push({
        lat: this.state.location.latitude,
        lng: this.state.location.longitude,
      });
    }
    let qry = JSON.parse(localStorage.getItem("qry"));
    var params = {
      transaction_id: this.state.transactionId,
      dispatch_planning_type: qry.dispatch_planning_type,
      plant_location_code: qry.plant_location_code,
      trip_no: row.data.trip_no,
      return_route: 1,
    };
    if (row.data.is_available == 0) {
      params.return_route = 0;
    }

    if (this.state.orderId != undefined && this.state.orderId != '') {
      let plant = this.state.orderId.split('_')
      plant = plant[0]
      // console.log(plant,'plant') 
      params.plant_location_code = plant
    }

    redirectURL
      .post("/dispatch/fetchPlanRouteDetails", params)
      .then((response) => {
        // console.log("Response", response.data);
        if (
          response.data.plant_loc_data !== "" &&
          response.data.plant_loc_data !== undefined
        ) {
          var plantData = JSON.parse(response.data.plant_loc_data);
        } else {
          var plantData = [];
        }

        if (
          response.data.trip_gps_data !== "" &&
          response.data.trip_gps_data !== undefined
        ) {
          var trip_gps = JSON.parse(response.data.trip_gps_data);
        } else {
          var trip_gps = [];
        }
        // console.log("trip_gps ", trip_gps)
        if (
          response.data.trip_nodes_data !== "" &&
          response.data.trip_nodes_data !== undefined
        ) {
          var tripnodes = JSON.parse(response.data.trip_nodes_data);
        } else {
          var tripnodes = [];
        }
        // console.log("trip_gps ", trip_gps)
        // console.log("plantData ", plantData)
        // console.log("tripnodes ", tripnodes)
        if (row.data.is_available == 0) {
          this.setState({
            trip_gps_data: trip_gps,
            plant_loc_data: plantData,
            trip_nodes_data: tripnodes,
            // showSlideBlockUpload:"slide60",
            showSlideMap: "slide60",
            overlayBlock: "show-m",
            viewcount: 1,
            showAdditionalPlannedVehicles: 1,
          });
        } else {
          this.setState({
            trip_gps_data: trip_gps,
            plant_loc_data: plantData,
            trip_nodes_data: tripnodes,
            // showSlideBlockUpload:"slide60",
            showSlideMap: "slide60",
            overlayBlock: "show-m",
            viewcount: 1,
            showAdditionalPlannedVehicles: 0,
          });
        }
      })
      .catch(function (error) {
        var errDetails = {
          url: "/dispatch/fetchPlanRouteDetails",
          screen: 'Summary Dashboard',
          error,

        }
        redirectURL.post("/master/logErrorsForApiCalls", errDetails)

      })

    // this.setState({
    //     loadingLocation : loadingLocation,
    //     mapType : 1,
    //     latLngArray : row.data.latLngArray,
    //     showSlideBlockUpload:"slide45",
    //     overlayBlock:"show-m",
    //     sliderTitle : "Trip : "+row.data.trip_no+" Total Distance : "+row.data.distance+" Km"
    // })
  };
  onShowLegsData = async (row) => {
    let logParams = {
      "location_code": this.state.location.value,
      "location_name": this.state.location.label,
      "user_name": localStorage.getItem("username"),
      "useremail": localStorage.getItem("email"),
      "client": localStorage.getItem("client"),
      "screen": "Summary Dashboard",
      "activity": 'clicked on view Button in aggrid',
      "event": "another aggrid opened below the row",
      "data_type": this.state.data_type,
    }

    redirectURL.post("/master/loguserUpdatesorChanges", logParams)
    // var plannedcolsDefs1 = [
    //   // {
    //   //     headerName:"Trip No",
    //   //     field:"trip_no",
    //   //     width:"100",
    //   // },
    //   {
    //     headerName: "Planned Day",
    //     headerTooltip: "Planned Day",
    //     field: "drop_node_total_time_day",
    //     width: "90",
    //     valueGetter: function (params) {
    //       try {
    //         if (
    //           params.data.drop_node_total_time_day !== "" &&
    //           params.data.drop_node_total_time_day !== undefined
    //         ) {
    //           var a = parseInt(params.data.drop_node_total_time_day);
    //           return "Day - " + (a + 1);
    //         } else {
    //           return params.data.drop_node_total_time_day;
    //         }
    //       } catch (error) { }
    //     },
    //   },
    //   {
    //     headerName: "Drop Sequence",
    //     headerTooltip: "Drop Sequence",
    //     field: "drop_sequence",
    //     width: "100",
    //   },
    //   // {
    //   //     headerName:"Trip No",
    //   //     headerTooltip:"Trip No",
    //   //     field:"trip_no",
    //   //     width:"120",
    //   // },
    //   {
    //     headerName: "Drop Location Code (Destination)",
    //     headerTooltip: "Drop Location Code (Destination)",
    //     field: "drop_node",
    //     width: "150",
    //   },
    //   {
    //     headerName: "Drop Location Name (Destination)",
    //     headerTooltip: "Drop Location Name (Destination)",
    //     field: "drop_node_name",
    //     width: "150",
    //   },
    //   {
    //     headerName: "Destination Location City",
    //     headerTooltip: "Destination Location City",
    //     field: "demand_node_location",
    //     width: "120",
    //   },
    //   // {
    //   //     headerName:"Destination Location State",
    //   //     headerTooltip:"Destination Location State",
    //   //     field:"demand_node_state",
    //   //     width:"150",
    //   // },
    //   {
    //     headerName: "Order Number",
    //     headerTooltip: "Order Number",
    //     field: "order_number",
    //     width: "120",
    //   },
    //   {
    //     headerName: "Invoicing Date",
    //     headerTooltip: "Invoicing Date",
    //     field: "invoicing_date",
    //     width: "120",
    //   },
    //   // {
    //   //     headerName:"Vehicle No",
    //   //     field:"vehicle_no",
    //   //     width:"180"
    //   // },
    //   // {
    //   //     headerName:"Vehicle Type",
    //   //     field:"vehicle_type",
    //   //     width:"120"
    //   // },
    //   // {
    //   //     headerName:"Transaporter Code",
    //   //     field:"transporter_code",
    //   //     width:"150"
    //   // },
    //   // {
    //   //     headerName:"Transaporter Name",
    //   //     field:"transporter_name",
    //   //     width:"150"
    //   // },
    //   // {
    //   //     headerName:"Route Mode",
    //   //     field:"route_mode",
    //   //     width:"120"
    //   // },
    //   // {
    //   //     headerName:"Pick Up Location (Source)",
    //   //     field:"pickup_node",
    //   //     width:"150",
    //   // },
    //   // {
    //   //     headerName:"Vehicle Max Volume(M³)",
    //   //     field:"vehicle_max_volume",
    //   //     width:160
    //   // },
    //   // {
    //   //     headerName:"Vehicle Max Weight(Kgs)",
    //   //     field:"vehicle_max_weight",
    //   //     width:160
    //   // },
    //   {
    //     headerName: "Quantity",
    //     headerTooltip: "Quantity",
    //     field: "quantity",
    //     width: "90",
    //     valueGetter: (params) => {
    //       if(params.data.quantity != undefined && params.data.quantity != ''){
    //       return params.data.quantity.toFixed(2)
    //       }
    //     }
    //   },
    //   {
    //     headerName: "Weight (Kgs)",
    //     headerTooltip: "Weight (Kgs)",
    //     field: "demand_weight",
    //     width: "90",
    //   },
    //   {
    //     headerName: "Volume (M3)",
    //     headerTooltip: "Volume (M3)",
    //     field: "demand_volume",
    //     width: "90",
    //   },
    //   // {
    //   //     headerName:"Distance From Pickup Location (Kms)",
    //   //     headerTooltip:"Distance From Pickup Location (Kms)",
    //   //     field:"distance",
    //   //     width:"200",
    //   // },

    //   {
    //     headerName: "Distance To Destination (Kms)",
    //     headerTooltip: "Distance To Destination (Kms)",
    //     field: "distance",
    //     width: "150",
    //   },
    //   {
    //     headerName: "Expected Delivery Date",
    //     field: "delivery_date",
    //     width: "120",
    //   },
    //   // {
    //   //     headerName:"Time From Pickup Location (HH:MM)",
    //   //     headerTooltip:"Time From Pickup Location (HH:MM)",
    //   //     field:"drop_node_total_time_hrs",
    //   //     width:"200",
    //   // },
    //   {
    //     headerName: "Time To Destination",
    //     headerTooltip: "Time To Destination",
    //     field: "drop_node_total_time_hrs",
    //     width: "150",
    //   },
    //   {
    //     headerName: "Transit Time (HH:MM)",
    //     headerTooltip: "Transit Time (HH:MM)",
    //     field: "drop_node_transit_time_hrs",
    //     width: "120",
    //   },
    //   // {
    //   //     headerName:"Loading Time (HH:MM)",
    //   //     headerTooltip:"Loading Time (HH:MM)",
    //   //     field:"drop_node_loading_time_mins",
    //   //     width:"120",
    //   // },
    //   {
    //     headerName: "Unloading Time (HH:MM)",
    //     headerTooltip: "Unloading Time (HH:MM)",
    //     field: "drop_node_unloading_time_mins",
    //     width: "120",
    //   },
    //   // {
    //   //     headerName:"Distance From Previous Location (Kms)",
    //   //     field:"distance_from_prev_location",
    //   //     width:"200",
    //   // },
    //   // {
    //   //     headerName:"Time From Previous Location (HH:MM)",
    //   //     field:"time_from_prev_location",
    //   //     width:"200",
    //   // },
    //   // {
    //   //     headerName:"Drop Location City",
    //   //     field:"demand_node_location",
    //   //     width:"120",
    //   // },
    //   // {
    //   //     headerName:"Zone",
    //   //     field:"zone",
    //   //     width:"100"
    //   // },
    //   // {
    //   //     headerName:"Drop Location (Destination)",
    //   //     field:"drop_node",
    //   //     width:"150"
    //   // },
    //   // {
    //   //     headerName:"Vehicle Volume",
    //   //     field:"vehicle_volume",
    //   //     width:"120"
    //   // },
    //   // {
    //   //     headerName:"Vehicle Priority",
    //   //     field:"vehicle_priority",
    //   //     width:"120"
    //   // },
    // ];
    // console.log(row, "row")
    var plannedcolsDefs1 = [
      {
          headerName: i18n.t('planned_day'),
          headerTooltip: "Planned Day",
          field: "drop_node_total_time_day",
          width: "90",
          valueGetter: function (params) {
              try {
                  if (
                      params.data.drop_node_total_time_day !== "" &&
                      params.data.drop_node_total_time_day !== undefined
                  ) {
                      var a = parseInt(params.data.drop_node_total_time_day);
                      return "Day - " + (a + 1);
                  } else {
                      return params.data.drop_node_total_time_day;
                  }
              } catch (error) { }
          },
      },
      {
          headerName: i18n.t('drop_sequence'),
          headerTooltip: "Drop Sequence",
          field: "drop_sequence",
          width: "100",
      },
      {
          headerName: i18n.t('drop_location_code_destination'),
          headerTooltip: "Drop Location Code (Destination)",
          field: "drop_node",
          width: "150",
      },
      {
          headerName: i18n.t('drop_location_name_destination'),
          headerTooltip: "Drop Location Name (Destination)",
          field: "drop_node_name",
          width: "150",
      },
      {
          headerName: i18n.t('destination_location_city'),
          headerTooltip: "Destination Location City",
          field: "demand_node_location",
          width: "120",
      },
      {
          headerName: i18n.t('order_number'),
          headerTooltip: "Order Number",
          field: "order_number",
          width: "120",
      },
      {
          headerName: i18n.t('invoicing_date'),
          headerTooltip: "Invoicing Date",
          field: "invoicing_date",
          width: "120",
      },
      {
          headerName: i18n.t('quantity'),
          headerTooltip: "Quantity",
          field: "quantity",
          width: "90",
          valueGetter: (params) => {
              if (params.data.quantity != undefined && params.data.quantity != '') {
                  return params.data.quantity.toFixed(2);
              }
          }
      },
      {
          headerName: i18n.t('weight_kgs'),
          headerTooltip: "Weight (Kgs)",
          field: "demand_weight",
          width: "90",
      },
      {
          headerName: i18n.t('volume_m3'),
          headerTooltip: "Volume (M3)",
          field: "demand_volume",
          width: "90",
      },
      {
          headerName: i18n.t('distance_to_destination_kms'),
          headerTooltip: "Distance To Destination (Kms)",
          field: "distance",
          width: "150",
      },
      {
          headerName: i18n.t('expected_delivery_date'),
          field: "delivery_date",
          width: "120",
      },
      {
          headerName: i18n.t('time_to_destination'),
          headerTooltip: "Time To Destination",
          field: "drop_node_total_time_hrs",
          width: "150",
      },
      {
          headerName: i18n.t('transit_time_hh_mm'),
          headerTooltip: "Transit Time (HH:MM)",
          field: "drop_node_transit_time_hrs",
          width: "120",
      },
      {
          headerName: i18n.t('unloading_time_hh_mm'),
          headerTooltip: "Unloading Time (HH:MM)",
          field: "drop_node_unloading_time_mins",
          width: "120",
      }
  ];
  
    const rowData = row.data.groupedArray
    if (rowData) {
      await this.setState({
        detailCellRendererParams: {
          detailGridOptions: {
            columnDefs: plannedcolsDefs1,
            overlayNoRowsTemplate: "No rows to show",
            // defaultColDef: this.state.defaultColDef,
          },
          getDetailRowData: async function (param) {
            // console.log("param ", param.data)
            param.successCallback(rowData);
          },
          masterDetail: true,
        },
      });
      // console.log(propsdata);
      if (row.colDef.field == "details") {
        row.node.setExpanded(!row.node.expanded);
      } else {
        row.node.setExpanded(false);
      }
    } else {
      this.setState({
        detailCellRendererParams: {
          detailGridOptions: {
            columnDefs: plannedcolsDefs1,
          },
          getDetailRowData: function (params) {
            //console.log("Child ", params);
            params.successCallback([]);
          },
        },
      });
    }
  };
  onClickShowReloadedRoutesData() {
    let viewcount = this.state.viewcount;
    if (viewcount == 0) {

      let logParams = {
        "location_code": this.state.location.value,
        "location_name": this.state.location.label,
        "user_name": localStorage.getItem("username"),
        "useremail": localStorage.getItem("email"),
        "client": localStorage.getItem("client"),
        "screen": "Summary Dashboard",
        "activity": 'clicked on view planned additional vehicle routes Button',
        "event": "Slider Opened with a map",
        "data_type": this.state.data_type,
      }

      redirectURL.post("/master/loguserUpdatesorChanges", logParams)
      this.setState({
        // trip_gps_data: trip_gps,
        // plant_loc_data: plantData,
        // trip_nodes_data: tripnodes,
        // showSlideBlockUpload:"slide60",
        showAdditionalPlannedVehicles: 1,
        showSlideMap: "slide60",
        overlayBlock: "show-m",
        viewcount: 0,
      });
    } else {
      this.onClickShowRoutesData();
      this.setState({
        showAdditionalPlannedVehicles: 1,
      });
    }
  }

  getDetailsOfFilteredData = async () => {
    // console.log('HIT')

    let filteredData = [];
    let allPlanSummaryData = [];

    let data = this.state.plannedRecords;
    //  console.log(data,'data')
    data.map((item) => allPlanSummaryData.push(...item.legs_data));
    //  console.log(this.state.filterPlannedTucksData,'filterd 1')
    if (this.state.filterPlannedTucksData == 1) {
      let customer_code = this.state.filterCustomer.value;
      let orderNo = this.state.filterOrder.value;

      if (customer_code != "" && orderNo != "") {
        filteredData = data.filter((item) =>
          item.legs_data.some(
            (drops) =>
              drops.drop_node == customer_code && drops.order_number == orderNo
          )
        );
        //  console.log(filteredData, "new hit here");
      } else if (customer_code != "") {
        filteredData = data.filter((item) =>
          item.legs_data.some((drops) => drops.drop_node == customer_code)
        );
      } else if (orderNo != "") {
        filteredData = data.filter((item) =>
          item.legs_data.some((drops) => drops.order_number == orderNo)
        );
      }
    } else {
      filteredData = this.state.plannedRecords;
    }
    //  console.log(filteredData)
    await this.setState({ allPlanSummaryData, filteredData })
  }

  onClickShowRoutesData() {
    // console.log("this.state.transactionId ", this.state.transactionId)

    let logParams = {
      "location_code": this.state.location.value,
      "location_name": this.state.location.label,
      "user_name": localStorage.getItem("username"),
      "useremail": localStorage.getItem("email"),
      "client": localStorage.getItem("client"),
      "screen": "Summary Dashboard",
      "activity": 'clicked on view planned available vehicle routes Button',
      "event": "Slider Opened with a map",
      "data_type": this.state.data_type,
    }

    redirectURL.post("/master/loguserUpdatesorChanges", logParams)

    this.setState({
      overlayBlock: "show-m",
    });
    let qry = JSON.parse(localStorage.getItem("qry"));
    var params = {
      transaction_id: this.state.transactionId,
      dispatch_planning_type: qry.dispatch_planning_type,
      plant_location_code: qry.plant_location_code,
    };

    if (this.state.orderId != undefined && this.state.orderId != '') {

      let plant = this.state.orderId.split('_')
      plant = plant[0]
      // console.log(plant,'plant') 
      params.plant_location_code = plant
    }

    redirectURL
      .post("/dispatch/fetchPlanRouteDetails", params)
      .then((response) => {
        // console.log("Response", response.data);
        if (
          response.data.plant_loc_data !== "" &&
          response.data.plant_loc_data !== undefined
        ) {
          var plantData = JSON.parse(response.data.plant_loc_data);
        } else {
          var plantData = [];
        }

        if (
          response.data.trip_gps_data !== "" &&
          response.data.trip_gps_data !== undefined
        ) {
          var trip_gps = JSON.parse(response.data.trip_gps_data);
        } else {
          var trip_gps = [];
        }
        // console.log("trip_gps ", trip_gps)
        if (
          response.data.trip_nodes_data !== "" &&
          response.data.trip_nodes_data !== undefined
        ) {
          var tripnodes = JSON.parse(response.data.trip_nodes_data);
        } else {
          var tripnodes = [];
        }

        this.setState({
          trip_gps_data: trip_gps,
          plant_loc_data: plantData,
          trip_nodes_data: tripnodes,
          // showSlideBlockUpload:"slide60",
          showSlideMap: "slide60",
          overlayBlock: "show-m",
          viewcount: 0,
        });
      })
      .catch(function (error) {
        var errDetails = {
          url: "/dispatch/fetchPlanRouteDetails",
          screen: 'Summary Dashboard',
          error,

        }
        redirectURL.post("/master/logErrorsForApiCalls", errDetails)

      })

  }
  onClickShowLocationData() {

    let logParams = {
      "location_code": this.state.location.value,
      "location_name": this.state.location.label,
      "user_name": localStorage.getItem("username"),
      "useremail": localStorage.getItem("email"),
      "client": localStorage.getItem("client"),
      "screen": "Summary Dashboard",
      "activity": 'clicked on View Locations Button',
      "event": "Slider Opened witha a map",
      "data_type": this.state.data_type,
    }

    redirectURL.post("/master/loguserUpdatesorChanges", logParams)
    this.setState({
      overlayBlock: "show-m",
    });
    var unPlannedData = this.state.unPlannedData;
    this.setState({
      palletLoadLocations: unPlannedData,
      showSlideMapLocations: "slide60",
      overlayBlock: "show-m",
      viewcount: 0,
    });
  }

  initMapRoutes = () => {
    //console.log("Yes Here")
    //console.log("mapFor ",this.props.mapFor)
    //console.log("mapinfo ",this.props.mapinfo)
    // try{
    //     var currentwindow;
    //     var dealer='';
    //     var consigner='';
    //     this.setState({
    //         dealerCode:this.props.consigneecoords
    //     });
    //     var routeinfo ;
    //     if(this.state.plant_loc_data !== "" && this.state.plant_loc_data !== undefined)
    //     {
    //         var plant_loc_data = this.state.plant_loc_data;
    //     }
    //     else{
    //         var plant_loc_data = []
    //     }
    //     if(plant_loc_data.length > 0)
    //     {
    //         var lt=parseFloat(plant_loc_data[0].latitude);
    //         var ln=parseFloat(plant_loc_data[0].longitude);
    //     }
    //     else{
    //         var lt=28.4519751;
    //         var ln=77.0310713;
    //     }
    //     if(this.state.trip_gps_data !== "" && this.state.trip_gps_data !== undefined)
    //     {
    //         var trip_gps = this.state.trip_gps_data;
    //     }
    //     else{
    //         var trip_gps = []
    //     }
    //     if(this.state.trip_nodes_data !== "" && this.state.trip_nodes_data !== undefined)
    //     {
    //         var trip_nodes_data = this.state.trip_nodes_data;
    //     }
    //     else{
    //         var trip_nodes_data = []
    //     }
    //     var mapOptions = {
    //         zoom: 13,
    //         zoomControl: true,
    //         mapTypeControl: true,
    //         scaleControl: true,
    //         streetViewControl: true,
    //         rotateControl: true,
    //         fullscreenControl: true,
    //         labels:true,
    //         mapTypeControlOptions: {
    //             mapTypeIds: ['roadmap'],
    //         },
    //         center: new window.google.maps.LatLng(lt,ln),
    //         mapTypeId: window.google.maps.MapTypeId.ROADMAP
    //     };
    //     map = new window.google.maps.Map(document.getElementById('mapRoute'),
    //         mapOptions);
    //     var bounds = new window.google.maps.LatLngBounds();
    //     bounds.extend(new window.google.maps.LatLng(lt,ln));
    //         // Create our info window content
    //     var currentinfowindow = null;
    //     var arr = trip_gps;
    //     // console.log("trip_gps ", arr)
    //     var infowindow = new window.google.maps.InfoWindow();
    //     var markers= []
    //     if(arr.length > 0)
    //     {
    //         var line=new window.google.maps.Polyline(
    //         {
    //             map:map,
    //             strokeColor: '#157254',
    //             strokeOpacity: 1.0,
    //             strokeWeight: 2.5,
    //             icons: [{
    //                     icon: {
    //                             path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
    //                             strokeColor:'#ff0000',
    //                             fillColor:'#157254',
    //                             fillOpacity:1,
    //                             strokeWeight: 2
    //                             },
    //                     repeat:'100px',
    //                     path:[]
    //                 }]
    //             });
    //         for (let i = 0; i < arr.length; i++) {
    //             var path=line.getPath().getArray();
    //             let latLng=new window.google.maps.LatLng(parseFloat(arr[i].latitude),parseFloat(arr[i].longitude));
    //             path.push(latLng);
    //             line.setPath(path);
    //         }
    //     }
    //     if(plant_loc_data.length > 0)
    //     {
    //         for (var l = 0; l < plant_loc_data.length; l++) {
    //             bounds.extend(new window.google.maps.LatLng(parseFloat(plant_loc_data[l].latitude), parseFloat(plant_loc_data[l].longitude)));
    //             var marker = new window.google.maps.Marker({
    //                 position: new window.google.maps.LatLng(parseFloat(plant_loc_data[l].latitude), parseFloat(plant_loc_data[l].longitude)),
    //                 // icon:require('../../assets/images/business.png'),
    //                 title:plant_loc_data[l].location_label,
    //                 map: map,
    //             });
    //             addMarker(new window.google.maps.LatLng(parseFloat(plant_loc_data[l].latitude), parseFloat(plant_loc_data[l].longitude)),plant_loc_data[l].location_label);
    //         }
    //     }
    //     if(trip_nodes_data.length > 0)
    //     {
    //         for (var l = 0; l < trip_nodes_data.length; l++) {
    //             bounds.extend(new window.google.maps.LatLng(parseFloat(trip_nodes_data[l].latitude), parseFloat(trip_nodes_data[l].longitude)));
    //             var marker = new window.google.maps.Marker({
    //                 position: new window.google.maps.LatLng(parseFloat(trip_nodes_data[l].latitude), parseFloat(trip_nodes_data[l].longitude)),
    //                 // icon:require('../../assets/images/business.png'),
    //                 title:trip_nodes_data[l].location_label,
    //                 map: map,
    //             });
    //             addMarker(new window.google.maps.LatLng(parseFloat(trip_nodes_data[l].latitude), parseFloat(trip_nodes_data[l].longitude)),trip_nodes_data[l].location_label);
    //         }
    //     }
    //     function addMarkerPlant(location,lbl) {
    //         var marker = new window.google.maps.Marker({
    //             // color:"#ffffff",
    //             // fontSize:"20px",
    //             position: location,
    //             label:lbl,
    //             icon: require('../../assets/images/business.png'),
    //             map: map
    //         });
    //         markers.push(marker);
    //         var infowindow = new window.google.maps.InfoWindow({
    //             maxWidth: 350
    //         });
    //         marker.addListener('click', function() {
    //             infowindow.open(map, marker);
    //         });
    //         //oms.addMarker(marker);
    //     }
    //     function addMarker(location,lbl) {
    //         var marker = new window.google.maps.Marker({
    //             // color:"#ffffff",
    //             // fontSize:"20px",
    //             position: location,
    //             label:lbl,
    //             // icon: require('../../assets/images/business.png'),
    //             map: map
    //         });
    //         markers.push(marker);
    //         var infowindow = new window.google.maps.InfoWindow({
    //             maxWidth: 350
    //         });
    //         marker.addListener('click', function() {
    //             infowindow.open(map, marker);
    //         });
    //         //oms.addMarker(marker);
    //     }
    //     map.fitBounds(bounds)
    // }
    // catch(e){
    //     console.log("Error ",e)
    // }
  };

  onChangeFilterCustomer = async (filterCustomer) => {
    await this.setState({ filterCustomer });
  };
  onChangeFilterOrder = async (filterOrder) => {
    await this.setState({ filterOrder });
  };

  onClickFilterData = async () => {
    // console.log(this.state.filterOrder.value=="",'order',this.state.filterCustomer.value=="",'customffffer');
    // console.log(this.state.filterPlannedTucksData)
    let logParams = {
      "location_code": this.state.location.value,
      "location_name": this.state.location.label,
      "user_name": localStorage.getItem("username"),
      "useremail": localStorage.getItem("email"),
      "client": localStorage.getItem("client"),
      "screen": "planned Summary",
      "activity": 'clicked on Get Data Button',
      "event": "Data in aggrid filtered",
      "data_type": this.state.data_type,
    }

    redirectURL.post("/master/loguserUpdatesorChanges", logParams)
    if (
      this.state.filterOrder.value !== "" ||
      this.state.filterCustomer.value !== ""
    ) {
      // console.log(this.state.filterOrder.value,'order',this.state.filterCustomer.value,'customer');

      await this.setState({ filterPlannedTucksData: 1 });
    } else {
      await this.setState({ filterPlannedTucksData: 0 });
    }
    // this.getDetailsOfFilteredData()
  };


  onClickResetPlannedVehicleData = async () => {
    // console.log(this.state.location)
    let logParams = {
      "location_code": this.state.location.value,
      "location_name": this.state.location.label,
      "user_name": localStorage.getItem("username"),
      "useremail": localStorage.getItem("email"),
      "client": localStorage.getItem("client"),
      "screen": "Summary Dashboard",
      "activity": 'clicked on Reset Button',
      "event": "Customer and Order Number fields set to All",
      "data_type": this.state.data_type,
    }
    // console.log(logParams)

    redirectURL.post("/master/loguserUpdatesorChanges", logParams)
    // .then((res)=>{
    //   console.log(res)
    // })
    // .catch((err)=>{
    //   console.log(err)
    // })
    await this.setState({
      filterPlannedTucksData: 0,
      filterCustomer: { value: "", label: "All" },
      filterOrder: { value: "", label: "All" },
    });
    // this.getDetailsOfFilteredData()
  };
  onClickShowAssignForm(rownode) {
    // console.log("Rownode ", rownode.data);

    let logParamsWebPage = {
      "location_code": this.state.location.value,
      "location_name": this.state.location.label,
      "user_name": localStorage.getItem("username"),
      "useremail": localStorage.getItem("email"),
      "client": localStorage.getItem("client"),
      "screen": "Summary Dashboard",
      "activity": 'clicked on Assign Vehicle Button',
      "event": "Slider Opened with assign vehicle option",
      "data_type": this.state.data_type,
    }

    redirectURL.post("/master/loguserUpdatesorChanges", logParamsWebPage)


    var assign_vehicle_list = [];
    // console.log(this.state.vehicleLists)
    this.state.vehicleLists.filter((item) => {
      // if (item.type == rownode.data.vehicle_type ||  item.type == 'others') {
      assign_vehicle_list.push({
        value: item.value,
        label: item.label,
      });
      // }
    });
    // console.log(this.state.vehicleLists, "vehicle list");
    // console.log(assign_vehicle_list, "assign");
    // console.log(rownode.data.vehicle_type, "assign");
    this.setState({
      showSlideAssignVehicle: "slide30",
      overlayBlock: "show-m",
      assignrowdata: [rownode.data],
      assign_vehicle_list,
    });
  }
  onChangeAssignVehicle(assignvehicle) {
    this.setState({ assignvehicle }, () => {
      var vehicle_no = this.state.assignvehicle.value;
      if (vehicle_no !== "") {
        if (vehicle_no === "0") {
          this.setState({
            showAssignFields: "show-m",
          });
        } else {
          this.setState({
            showAssignFields: "show-n",
          });
        }
      } else {
        this.setState({
          showAssignFields: "show-n",
        });
      }
    });

    if (this.state.activeVehicleList.length > 0) {
      let shipList = this.state.shipmentIds;
      let selectedShipmentId;
      shipList.map((itm) => {
        if (itm.truck_no == assignvehicle.value) {
          selectedShipmentId = itm.shipment_id;
        }
      });

      // if (this.state.activeVehicleList.includes(assignvehicle.value)) {
      //   this.setState({
      //     openReassignVehicleModel: true,
      //     showSlideAssignVehicle: "",
      //     selectedShipmentId,
      //     forceCloseVehicle: assignvehicle.value,
      //   });
      // }
    }
  }

  changeHandler(event) {
    var name = event.target.name;
    var value = event.target.value;
    var pattern = /^[A-Z0-9]{9}$/;
    // var key=event.target.keyCode()
    // console.log(key)
    var symbols = "'\"~!@#$%^&*()_+=-_|}{][:;<>?/ .,";

    var key = value[value.length - 1];

    // if (pattern.test(value.toUpperCase())) {
    //     console.log(value,'valuw')
    // }
    // console.log('Clicked',!symbols.includes(key))
    if (!symbols.includes(key)) {
      this.setState({
        [name]: value.toUpperCase(),
      });
    }

    if (this.state.activeVehicleList.length > 0) {
      let shipList = this.state.shipmentIds;
      let selectedShipmentId;
      shipList.map((itm) => {
        if (itm.truck_no == value) {
          selectedShipmentId = itm.shipment_id;
        }
      });

      // if (this.state.activeVehicleList.includes(value)) {
      //   this.setState({
      //     openReassignVehicleModel: true,
      //     showSlideAssignVehicle: "",
      //     selectedShipmentId,
      //     forceCloseVehicle: value,
      //   });
      // }
    }
  }

  onClickAssignSelectedVehicle() {

    let clickdetails = {
      "location_code": this.state.location.value,
      "location_name": this.state.location.label,
      "user_name": localStorage.getItem("username"),
      "useremail": localStorage.getItem("email"),
      "client": localStorage.getItem("client"),
      "screen": "Summary Dashboard",
      "activity": 'clicked on Assigned a Vehicle',
      "event": "Assign Vehicle",
      "data_type": this.state.data_type,
    }
    // console.log(logParams)                                                              
    redirectURL.post("/master/loguserUpdatesorChanges", clickdetails)

    this.setState({ assignVehicleloader: "show-m" });
    var assignvehicle = this.state.assignvehicle.value;
    var transactionId = this.state.transactionId;
    var other_vehicle_no = this.state.other_vehicle_no;
    var orderId = this.state.orderId;
    var rowD = this.state.assignrowdata;
    // console.log(rowD);
    var oNumber = "";
    var trip_no = "";
    var plant_location_code = "";
    var viewPage = window.location.href;
    if (this.state.updateVehicleNo != true) {
      if (rowD.length > 0) {
        trip_no = rowD[0].trip_no;
        oNumber = rowD[0].order_number;
        plant_location_code = rowD[0].pickup_node;
      }
      if (
        assignvehicle !== "" &&
        transactionId !== "" &&
        transactionId !== undefined &&
        orderId !== "" &&
        orderId !== undefined
      ) {
        // console.log("assignvehicle ", assignvehicle)
        if (assignvehicle === "0") {
          var vNo = other_vehicle_no;
        } else {
          var vNo = assignvehicle;
        }
        if (vNo !== "") {
          var params = {
            vehicle_no: vNo,
            transaction_id: transactionId,
            order_id: orderId,
            trip_no: trip_no,
            oNumber: oNumber,
            application_link: viewPage,
            plant_location_code: plant_location_code,
          };



          // console.log('hit',this.state.location.value)  
          var updateVehicle = ''
          if (this.state.updateVehicleNo) {
            updateVehicle = 'Update Vehicle'
          } else {
            updateVehicle = 'Assign Vehicle'
          }

          let logParams = {
            "location_code": this.state.location.value,
            "location_name": this.state.location.label,
            "user_name": localStorage.getItem("username"),
            "useremail": localStorage.getItem("email"),
            "client": localStorage.getItem("client"),
            "screen": "Summary Dashboard",
            "activity": updateVehicle,
            "event": updateVehicle,
            "data_type": this.state.data_type,
          }

          redirectURL.post("/master/logUserUpdateOrSaveDataActivities", logParams)


          redirectURL
            .post("/dispatch/assignVehicleAndCreateShipment", params)
            .then((response) => {

              if (response.data.status.status == 'Success') {

                this.setState({
                  showSlideAssignVehicle: "",
                  overlayBlock: "show-n",
                  assignrowdata: [],
                  other_vehicle_no: "",
                  assignvehicle: { value: "", label: "Select" },
                  assignVehicleloader: "show-n",
                  showAssignFields: "show-n",
                  openReassignVehicleModel: false,
                  updateVehicleNo: false,
                });
                this.getPlanOutputDetails();
              } else if (response.data.status.status != 'Success') {
                this.setState({
                  show: true,
                  basicTitle: response.data.status.message,
                  basicType: "danger",
                  assignVehicleloader: "show-n",
                  showSlideAssignVehicle: "",
                  overlayBlock: "show-n",
                  assignrowdata: [],
                  other_vehicle_no: "",
                  assignvehicle: { value: "", label: "Select" },
                  assignVehicleloader: "show-n",
                  showAssignFields: "show-n",
                  openReassignVehicleModel: false,
                  updateVehicleNo: false,
                });
                this.getPlanOutputDetails();
              }
            })
            .catch(function (error) {
              var errDetails = {
                url: "/dispatch/assignVehicleAndCreateShipment",
                screen: 'Summary Dashboard',
                error,

              }
              redirectURL.post("/master/logErrorsForApiCalls", errDetails)

            })
        } else {
          this.setState({
            show: true,
            basicTitle: "Please choose vehicle",
            basicType: "danger",
            assignVehicleloader: "show-n",
          });
        }
      } else {
        if (assignvehicle === "") {
          this.setState({
            show: true,
            basicTitle: "Please choose vehicle",
            basicType: "danger",
            assignVehicleloader: "show-n",
          });
        } else {
          this.setState({
            show: true,
            basicTitle: "Please check transaction id and order id",
            basicType: "danger",
            assignVehicleloader: "show-n",
          });
        }
      }
    } else {
      let oldVehicleNO = this.state.oldVehicleNO;
      let oldVehTripNo = parseInt(this.state.oldVehTripNo);
      let planned_distance = 0

      if (rowD.length > 0) {
        planned_distance = rowD[0].round_trip_distance
      }

      if (
        assignvehicle !== "" &&
        transactionId !== "" &&
        transactionId !== undefined &&
        orderId !== "" &&
        orderId !== undefined
      ) {
        // console.log("assignvehicle ", assignvehicle)
        if (assignvehicle === "0") {
          var vNo = other_vehicle_no;
        } else {
          var vNo = assignvehicle;
        }
        if (vNo != undefined && vNo !== "") {
          var params2 = {
            new_vehicle_no: vNo,
            transaction_id: transactionId,
            order_id: orderId,
            trip_no: oldVehTripNo,
            old_vehicle_no: oldVehicleNO,
            planned_distance: planned_distance
          };

          redirectURL
            .post("/dispatch/updateVehicleNoOfAssignedVehicle", params2)
            .then((response) => {
              if (
                response.data.status == "Success" &&
                response.data.message == "success"
              ) {
                this.setState({
                  updateVehicleNo: false,
                  assignVehicleloader: "show-n",
                  showSlideAssignVehicle: "",
                  overlayBlock: "show-n",
                  showAssignFields: "show-n",
                  openReassignVehicleModel: false,
                });
                this.getPlanOutputDetails();
              } else {
                this.setState({
                  show: true,
                  basicTitle: response.data.message,
                  basicType: "danger",
                  assignVehicleloader: "show-n",
                  showSlideAssignVehicle: "",
                  overlayBlock: "show-n",
                  openReassignVehicleModel: false,
                  updateVehicleNo: false,
                });
              }
            })
            .catch(function (error) {
              var errDetails = {
                url: "/dispatch/updateVehicleNoOfAssignedVehicle",
                screen: 'Summary Dashboard',
                error,

              }
              redirectURL.post("/master/logErrorsForApiCalls", errDetails)

            })

        } else {
          this.setState({
            show: true,
            basicTitle: "Please choose vehicle",
            basicType: "danger",
            assignVehicleloader: "show-n",
          });
        }
      } else {
        this.setState({
          show: true,
          basicTitle: "Please check transaction id and order id",
          basicType: "danger",
          assignVehicleloader: "show-n",
        });
      }
    }
  }

  onClickDownloadPlannedData = () => {
    let logParams = {
      "location_code": this.state.location.value,
      "location_name": this.state.location.label,
      "user_name": localStorage.getItem("username"),
      "useremail": localStorage.getItem("email"),
      "client": localStorage.getItem("client"),
      "screen": "Planned Summary",
      "activity": 'clicked on Download Planned Data Button',
      "event": "Planned Data Dowloaded in Excel",
      "data_type": this.state.data_type,
    }

    redirectURL.post("/master/loguserUpdatesorChanges", logParams)
    // let planData = this.state.plan_summary_records;
    // let allData = [];
    // planData.map((item) => allData.push(...item));

    const data = this.state.plan_summary_records.map((item) => {
      if (item.drop_node_total_time_day == 0) {
        item.drop_node_total_time_day = "Day - 1";
      } else if (item.drop_node_total_time_day == 1) {
        item.drop_node_total_time_day = "Day - 2";
      } else if (item.drop_node_total_time_day == 2) {
        item.drop_node_total_time_day = "Day - 3";
      }
      return {
        "Trip No": item.trip_no,
        "Drop Sequence": item.drop_sequence,
        "Vehicle Type": item.vehicle_type,
        "Order Number": item.order_number,
        "Vehicle No": item.vehicle_no,
        "Invoicing Date": item.invoicing_date,
        Quantity: item.quantity,
        "Drop Location Code (Destination)": item.drop_node,
        "Drop Location Name (Destination)": item.drop_node_name,
        "Destination Location City": item.demand_node_location,
        "Volume (M3)": item.demand_volume,
        "Weight (Kgs)": item.demand_weight,
        "Planned Day": item.drop_node_total_time_day,
        "Max Dispatch Time (HH:MM)": item.vehicle_start_time_mins,
        "Distance To Destination (Kms)": item.distance,
        "Time To Destination": item.drop_node_total_time_hrs,
        "Delivery Date": item.delivery_date,
      };
    });
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const timestamp = moment.parseZone(new Date()).format("YYYY-MM-DD");

    const dataBlob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const fileName = `Planned Vehicles Data_${timestamp}.xlsx`;
    const downloadLink = document.createElement("a");

    downloadLink.href = window.URL.createObjectURL(dataBlob);
    downloadLink.download = fileName;
    downloadLink.click();
  };

  handelModelCancel = () => {
    this.setState({
      openReassignVehicleModel: false,
      overlayBlock: "show-n",
      updateVehicleNo: false,
      assignvehicle: { value: "", label: "Select" },
    });
  };

  handelUpdateModelCancel = () => {
    this.setState({
      openUpdateVehicleModel: false,
      overlayBlock: "show-n",
      updateVehicleNo: false,
    });
  };

  forceCloseAndReassign = () => {
    this.setState({ assignVehicleloader: "show-m" });

    var shipment_id = this.state.selectedShipmentId;
    var truck_no = this.state.forceCloseVehicle;
    var dept_code = "SNDG";
    // var force_closure_time = moment.parseZone().format("YYYY-MM-DD HH:mm:ss");
    var username = localStorage.getItem("username");
    var userEmail = localStorage.getItem("email");
    var others_Reason = "";
    var reasonforceclose = this.state.forceClose_reason;
    var params = {
      dept_code: dept_code,
      shipment_id: [shipment_id],
      truck_no: truck_no,
      username: username,
      userEmail: userEmail,
      reasonforceclose: reasonforceclose,
      others_reason: others_Reason,
    };

    redirectURL.post("/dispatch/forceCloseTrip", params)
      .then((response) => {
        if (response.data.status == "Success") {
          this.onClickAssignSelectedVehicle();
        } else {
          this.setState({
            show: true,
            basicTitle: "Error",
            basicType: "danger",
            assignVehicleloader: "show-n",
          });
        }
      })
      .catch(function (error) {
        var errDetails = {
          url: "/dispatch/forceCloseTrip",
          screen: 'Summary Dashboard',
          error,

        }
        redirectURL.post("/master/logErrorsForApiCalls", errDetails)

      })

  };

  forceCloseInputField = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState({ [name]: value });
  };

  onClickUpdateAssignVehicle = (props) => {

    let logParams = {
      "location_code": this.state.location.value,
      "location_name": this.state.location.label,
      "user_name": localStorage.getItem("username"),
      "useremail": localStorage.getItem("email"),
      "client": localStorage.getItem("client"),
      "screen": "Planned Summary",
      "activity": 'clicked on Update Vehicle Button',
      "event": "Modal Opened with Yes or No",
      "data_type": this.state.data_type,
    }

    redirectURL.post("/master/loguserUpdatesorChanges", logParams)

    var assign_vehicle_list = [];
    this.state.vehicleLists.filter((item) => {
      if (item.type == props.data.vehicle_type || item.type == 'others') {
        assign_vehicle_list.push({
          value: item.value,
          label: item.label,
        });
      }
    });

    this.setState({
      oldVehicleNO: props.data.vehicle_no,
      oldVehTripNo: props.data.trip_no,
      openUpdateVehicleModel: true,
      assign_vehicle_list,
      assignrowdata: [props.data],
    });
  };

  handleUpdateVehicle = () => {
    let logParams = {
      "location_code": this.state.location.value,
      "location_name": this.state.location.label,
      "user_name": localStorage.getItem("username"),
      "useremail": localStorage.getItem("email"),
      "client": localStorage.getItem("client"),
      "screen": "Summary Dashboard",
      "activity": 'clicked on yes Button in the modal',
      "event": "Slider opended with assign vehicle option",
      "data_type": this.state.data_type,
    }

    redirectURL.post("/master/loguserUpdatesorChanges", logParams)
    this.setState({
      showSlideAssignVehicle: "slide30",
      overlayBlock: "show-m",
      updateVehicleNo: true,
      openUpdateVehicleModel: false,
    });
  };

  onClickRevokeAssignVehicle = (row) => {

    // console.log(row.data,'da56a')
    let logParams = {
      "location_code": this.state.location.value,
      "location_name": this.state.location.label,
      "user_name": localStorage.getItem("username"),
      "useremail": localStorage.getItem("email"),
      "client": localStorage.getItem("client"),
      "screen": "Summary Dashboard",
      "activity": 'clicked on Revoke Button',
      "event": "Alert is opened with yer or no",
      "data_type": this.state.data_type,
    }

    redirectURL.post("/master/loguserUpdatesorChanges", logParams)

    let transaction_id = this.state.transactionId;
    let vehicle_no = row.data.vehicle_no;
    let trip_no = row.data.trip_no;
    let round_trip_distance = row.data.round_trip_distance

    var cnf = window.confirm("Are you sure you want to proceed?");

    if (cnf === true) {

      this.setState({
        loadshow: 'show-m',
      })

      let param = {
        transaction_id: transaction_id,
        vehicle_no: vehicle_no,
        trip_no: trip_no,
        round_trip_distance: round_trip_distance

      }

      let logParams = {
        "location_code": this.state.location.value,
        "location_name": this.state.location.label,
        "user_name": localStorage.getItem("username"),
        "useremail": localStorage.getItem("email"),
        "client": localStorage.getItem("client"),
        "screen": "Summary Dashboard",
        "activity": 'Vehicle Revoked',
        "event": "Vehicle Revoke",
        "data_type": this.state.data_type,
      }

      redirectURL.post("/master/logUserUpdateOrSaveDataActivities", logParams)

      let clickdetails = {
        "location_code": this.state.location.value,
        "location_name": this.state.location.label,
        "user_name": localStorage.getItem("username"),
        "useremail": localStorage.getItem("email"),
        "client": localStorage.getItem("client"),
        "screen": "Summary Dashboard",
        "activity": 'Vehicle Revoked',
        "event": "clicked on Revoke Vehicle",
        "data_type": this.state.data_type,
      }

      redirectURL.post("/master/loguserUpdatesorChanges", clickdetails)

      redirectURL.post('/dispatch/revokeVehicleAssignment', param)
        .then(resp => {
          if (resp.data.status == 'Success' && resp.data.message == "success") {
            this.getPlanOutputDetails();
            this.setState({
              loadshow: 'show-n',
            });
          } else {
            this.setState({
              show: true,
              basicTitle: "Error",
              basicType: "danger",
              loadshow: 'show-n',
            });
          }
        })
        .catch(function (error) {
          var errDetails = {
            url: "/dispatch/revokeVehicleAssignment",
            screen: 'Summary Dashboard',
            error,

          }
          redirectURL.post("/master/logErrorsForApiCalls", errDetails)

        })


    }

  }
  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

  };
  render() {
    const {t} = this.props
    // var vehicleTypecolsDefs = [
    //   {
    //     headerName: "Vehicle Type",
    //     field: "vehicle_type",
    //     width: "100",
    //   },
    //   {
    //     headerName: "Vehicle Name",
    //     field: "vehicle_name",
    //     width: "100",
    //   },
    //   {
    //     headerName: "Vehicle Priority",
    //     field: "vehicle_priority",
    //     width: "100",
    //   },
    //   {
    //     headerName: "Vehicle Availability (from)",
    //     field: "vehicle_availability_from",
    //     width: "150",
    //   },
    //   {
    //     headerName: "Vehicle Availability (to)",
    //     field: "vehicle_availability_to",
    //     width: "140",
    //   },
    //   {
    //     headerName: "Break Time (from)",
    //     field: "break_time_from",
    //     width: "110",
    //   },
    //   {
    //     headerName: "Break Time (to) ",
    //     field: "break_time_to",
    //     width: "100",
    //   },
    //   {
    //     headerName: "Min Volume (M3)",
    //     field: "min_volume_cmt",
    //     width: "120",
    //   },
    //   {
    //     headerName: "Max Volume (M3)",
    //     field: "max_volume_cmt",
    //     width: "120",
    //   },
    //   {
    //     headerName: "Vehicle Width (mm)",
    //     field: "vehicle_dimensions_width_mm",
    //     width: "120",
    //   },
    //   {
    //     headerName: "Vehicle Height (mm)",
    //     field: "vehicle_dimensions_height_mm",
    //     width: "125",
    //   },
    //   {
    //     headerName: "Vehicle Depth (mm)",
    //     field: "vehicle_dimensions_depth_mm",
    //     width: "120",
    //   },
    //   {
    //     headerName: "Vehicle Min Weight (Kgs)",
    //     field: "vehicle_min_weight",
    //     width: "145",
    //   },
    //   {
    //     headerName: "Vehicle Max Weight (Kgs)",
    //     field: "vehicle_max_weight",
    //     width: "145",
    //   },
    //   // {
    //   //     headerName:"Vehicle Capacity (CMT)",
    //   //     field:"vehicle_capacity",
    //   //     width:"135"
    //   // },
    //   {
    //     headerName: "No Of Deliveries",
    //     field: "max_no_of_nodes",
    //     width: "100",
    //   },
    //   {
    //     headerName: "Min Load Per Delivery",
    //     field: "min_load_per_delivery",
    //     width: "140",
    //   },
    //   {
    //     headerName: "Max Distance (Kms)",
    //     field: "max_distance",
    //     width: "120",
    //   },
    // ];
    var vehicleTypecolsDefs = [
      {
          headerName: t('vehicle_type'),
          field: "vehicle_type",
          width: "100",
      },
      {
          headerName: t('vehicle_name'),
          field: "vehicle_name",
          width: "100",
      },
      {
          headerName: t('vehicle_priority'),
          field: "vehicle_priority",
          width: "100",
      },
      {
          headerName: t('vehicle_availability_from'),
          field: "vehicle_availability_from",
          width: "150",
      },
      {
          headerName: t('vehicle_availability_to'),
          field: "vehicle_availability_to",
          width: "140",
      },
      {
          headerName: t('break_time_from'),
          field: "break_time_from",
          width: "110",
      },
      {
          headerName: t('break_time_to'),
          field: "break_time_to",
          width: "100",
      },
      {
          headerName: t('min_volume_m3'),
          field: "min_volume_cmt",
          width: "120",
      },
      {
          headerName: t('max_volume_m3'),
          field: "max_volume_cmt",
          width: "120",
      },
      {
          headerName: t('vehicle_width_mm'),
          field: "vehicle_dimensions_width_mm",
          width: "120",
      },
      {
          headerName: t('vehicle_height_mm'),
          field: "vehicle_dimensions_height_mm",
          width: "125",
      },
      {
          headerName: t('vehicle_depth_mm'),
          field: "vehicle_dimensions_depth_mm",
          width: "120",
      },
      {
          headerName: t('vehicle_min_weight_kgs'),
          field: "vehicle_min_weight",
          width: "145",
      },
      {
          headerName: t('vehicle_max_weight_kgs'),
          field: "vehicle_max_weight",
          width: "145",
      },
      {
          headerName: t('no_of_deliveries'),
          field: "max_no_of_nodes",
          width: "100",
      },
      {
          headerName: t('min_load_per_delivery'),
          field: "min_load_per_delivery",
          width: "140",
      },
      {
          headerName: t('max_distance_kms'),
          field: "max_distance",
          width: "120",
      }
  ];
  
    // var materialConfigcolsDefs = [
    //   {
    //     headerName: Constant.MATERIAL_CODE,
    //     field: "material_code",
    //     width: "120",
    //   },
    //   {
    //     headerName: Constant.MATERIAL_NAME,
    //     field: "material_name",
    //     width: "130",
    //   },
    //   {
    //     headerName: Constant.MATERIAL_TYPE,
    //     field: "material_type",
    //     width: "130",
    //   },
    //   {
    //     headerName: Constant.MATERIAL_WEIGHT,
    //     field: "material_weight",
    //     width: "150",
    //   },
    //   {
    //     headerName: Constant.MATERIAL_DIMENSIONS_DEPTH,
    //     field: "material_dimensions_depth_mm",
    //     width: "200",
    //   },
    //   {
    //     headerName: Constant.MATERIAL_DIMENSIONS_WIDTH,
    //     field: "material_dimensions_width_mm",
    //     width: "200",
    //   },
    //   {
    //     headerName: Constant.MATERIAL_DIMENSIONS_HEIGHT,
    //     field: "material_dimensions_height_mm",
    //     width: "200",
    //   },
    // ];
    var materialConfigcolsDefs = [
      {
        headerName: t('material_code'),
        field: "material_code",
        width: "120",
      },
      {
        headerName: t('material_name'),
        field: "material_name",
        width: "130",
      },
      {
        headerName: t('material_type'),
        field: "material_type",
        width: "130",
      },
      {
        headerName: t('material_weight'),
        field: "material_weight",
        width: "150",
      },
      {
        headerName: t('material_dimensions_depth'),
        field: "material_dimensions_depth_mm",
        width: "200",
      },
      {
        headerName: t('material_dimensions_width'),
        field: "material_dimensions_width_mm",
        width: "200",
      },
      {
        headerName: t('material_dimensions_height'),
        field: "material_dimensions_height_mm",
        width: "200",
      },
    ];
    
    // var freightConfigcolsDefs = [
    //   {
    //     headerName: Constant.FORM_VEHICLE_TYPES,
    //     field: "vehicle_type",
    //     width: "120",
    //   },
    //   {
    //     headerName: Constant.DEALER_FORM_STATE,
    //     field: "state",
    //     width: "130",
    //   },
    //   {
    //     headerName: Constant.DEALER_FORM_CITY,
    //     field: "city",
    //     width: "130",
    //   },
    //   {
    //     headerName: Constant.FREIGHT_FIXED_VEHICLE_COST,
    //     field: "fixed_vehicle_cost",
    //     width: "130",
    //   },
    //   {
    //     headerName: Constant.FREIGHT_DRIVER_COST,
    //     field: "driver_cost",
    //     width: "150",
    //   },
    //   {
    //     headerName: Constant.FREIGHT_MAINTENANCE_COST,
    //     field: "maintenance_cost",
    //     width: "200",
    //   },
    //   {
    //     headerName: Constant.FREIGHT_PER_CMT_COST,
    //     field: "per_cmt_cost",
    //     width: "200",
    //   },
    //   {
    //     headerName: Constant.FREIGHT_PER_KM_COST,
    //     field: "per_km_cost",
    //     width: "200",
    //   },
    // ];
    var freightConfigcolsDefs = [
      {
        headerName: t('vehicle_type'),
        field: "vehicle_type",
        width: "120",
      },
      {
        headerName: t('state'),
        field: "state",
        width: "130",
      },
      {
        headerName: t('city'),
        field: "city",
        width: "130",
      },
      {
        headerName: t('fixed_vehicle_cost'),
        field: "fixed_vehicle_cost",
        width: "130",
      },
      {
        headerName: t('driver_cost'),
        field: "driver_cost",
        width: "150",
      },
      {
        headerName: t('maintenance_cost'),
        field: "maintenance_cost",
        width: "200",
      },
      {
        headerName: t('per_cmt_cost'),
        field: "per_cmt_cost",
        width: "200",
      },
      {
        headerName: t('per_km_cost'),
        field: "per_km_cost",
        width: "200",
      },
    ];
    
    // var vehicleAvailabilityConfigcolsDefs = [
    //   {
    //     headerName: "Vehicle Type",
    //     field: "vehicle_type",
    //     width: "100",
    //   },
    //   {
    //     headerName: "Date",
    //     field: "vehicle_date",
    //     width: "100",
    //   },
    //   {
    //     headerName: "Vehicle Number",
    //     field: "vehicle_no",
    //     width: "100",
    //   },
    //   {
    //     headerName: "Vehicle Availability (from)",
    //     field: "vehicle_availability_from",
    //     width: "150",
    //   },
    //   {
    //     headerName: "Vehicle Availability (to)",
    //     field: "vehicle_availability_to",
    //     width: "140",
    //   },
    //   {
    //     headerName: "Break Time (from)",
    //     field: "break_time_from",
    //     width: "110",
    //   },
    //   {
    //     headerName: "Break Time (to) ",
    //     field: "break_time_to",
    //     width: "100",
    //   },
    //   {
    //     headerName: "Transporter Name",
    //     field: "transporter_name",
    //     width: "120",
    //   },
    //   {
    //     headerName: "Vehicle Priority",
    //     field: "vehicle_priority",
    //     width: "100",
    //   },
    // ];
    var vehicleAvailabilityConfigcolsDefs = [
      {
        headerName: t('vehicle_type'),
        field: "vehicle_type",
        width: "100",
      },
      {
        headerName: t('date'),
        field: "vehicle_date",
        width: "100",
      },
      {
        headerName: t('vehicle_number'),
        field: "vehicle_no",
        width: "100",
      },
      {
        headerName: t('vehicle_availability_from'),
        field: "vehicle_availability_from",
        width: "150",
      },
      {
        headerName: t('vehicle_availability_to'),
        field: "vehicle_availability_to",
        width: "140",
      },
      {
        headerName: t('break_time_from'),
        field: "break_time_from",
        width: "110",
      },
      {
        headerName: t('break_time_to'),
        field: "break_time_to",
        width: "100",
      },
      {
        headerName: t('transporter_name'),
        field: "transporter_name",
        width: "120",
      },
      {
        headerName: t('vehicle_priority'),
        field: "vehicle_priority",
        width: "100",
      },
    ];
    
    // var vehiclesSelectedDataDefs = [
    //   {
    //     headerName: "No. of Vehicles",
    //     field: "no_of_vehicles",
    //     width: "130",
    //   },
    //   {
    //     headerName: "Vehicle Type",
    //     field: "vehicle_type",
    //     width: "120",
    //   },
    //   {
    //     headerName: "Vehicle Name",
    //     field: "vehicle_name",
    //     width: "130",
    //   },
    //   {
    //     headerName: "Driver Break Time (from)",
    //     field: "driver_break_time",
    //     width: "150",
    //   },
    //   // {
    //   //   headerName: "Vehicle Min Weight (Kgs)",
    //   //   field: "vehicle_min_weight",
    //   //   width: "150",
    //   // },
    //   {
    //     headerName: "Vehicle Max Weight (Kgs)",
    //     field: "vehicle_max_weight",
    //     width: "130",
    //   }, {
    //     headerName: "Vehicle Min Volume (M3)",
    //     field: "min_volume_m3",
    //     width: "130",
    //   },
    //   {
    //     headerName: "Vehicle Max Volume (M3)",
    //     field: "max_volume_m3",
    //     width: "130",
    //   },
    //   {
    //     headerName: "Max Distance (Kms)",
    //     field: "max_distance",
    //     width: "130",
    //   },
    //   {
    //     headerName: "Monthly Min Planning Distance (Kms)",
    //     field: "monthly_min_planning_distance",
    //     width: "130",
    //   },
    //   {
    //     headerName: "Vehicle Priority",
    //     field: "vehicle_priority",
    //     width: "130",
    //   }
    // ];
    var vehiclesSelectedDataDefs = [
      {
        headerName: t('no_of_vehicles'),
        field: "no_of_vehicles",
        width: "130",
      },
      {
        headerName: t('vehicle_type'),
        field: "vehicle_type",
        width: "120",
      },
      {
        headerName: t('vehicle_name'),
        field: "vehicle_name",
        width: "130",
      },
      {
        headerName: t('driver_break_time_from'),
        field: "driver_break_time",
        width: "150",
      },
      {
        headerName: t('vehicle_max_weight_kgs'),
        field: "vehicle_max_weight",
        width: "130",
      },
      {
        headerName: t('vehicle_min_volume_m3'),
        field: "min_volume_m3",
        width: "130",
      },
      {
        headerName: t('vehicle_max_volume_m3'),
        field: "max_volume_m3",
        width: "130",
      },
      {
        headerName: t('max_distance_kms'),
        field: "max_distance",
        width: "130",
      },
      {
        headerName: t('monthly_min_planning_distance_kms'),
        field: "monthly_min_planning_distance",
        width: "130",
      },
      {
        headerName: t('vehicle_priority'),
        field: "vehicle_priority",
        width: "130",
      }
    ];
    
    // var plannedcolsDefs = [
    //   // {
    //   //   headerName: "",
    //   //   field: "_id",
    //   //   width: 50,
    //   //   filter: true,
    //   //   resizable: true,
    //   //   editable: false,
    //   //   params: {
    //   //     onClickFunction: this.onRouteData,
    //   //   },
    //   //   cellRendererSelector: function (params) {
    //   //     var rendComponent = {
    //   //       component: "RouteButton",
    //   //     };
    //   //     return rendComponent;
    //   //   },
    //   // },
    //   {
    //     headerName: "Details",
    //     field: "details",
    //     width: 100,
    //     filter: true,
    //     resizable: true,
    //     editable: false,
    //     params: {
    //       buttonName: "View",
    //       iconName: "fa fa-eye",
    //       onClickFunction: this.onShowLegsData,
    //     },
    //     cellRendererSelector: function (params) {
    //       var rendComponent = {
    //         component: "GridButton",
    //       };
    //       return rendComponent;
    //     },
    //   },

    //   // {
    //   //   headerName: "Assign Vehicle",
    //   //   field: "assign_vehicle",
    //   //   width: 160,
    //   //   filter: true,
    //   //   resizable: true,
    //   //   editable: false,
    //   //   cellRendererSelector: function (params) {
    //   //     try {
    //   //       if (
    //   //         params.data.vehicle_no == "Not Assigned" &&
    //   //         params.data.route_finalized != 1
    //   //       ) {
    //   //         if (params.data.transaction_update == 1) {
    //   //           var rendComponent = {
    //   //             component: "NAButton",
    //   //           };
    //   //           return rendComponent;
    //   //         } else {
    //   //           var rendComponent = {
    //   //             component: "AssignVehicleBtn",
    //   //           };
    //   //           return rendComponent;
    //   //         }
    //   //       } else if (params.data.route_finalized == 1) {
    //   //         var rendComponent = {
    //   //           component: "UpdateVehicleBtn",
    //   //         };
    //   //         return rendComponent;
    //   //       } else {
    //   //         var rendComponent = {
    //   //           component: "NAButton",
    //   //         };
    //   //         return rendComponent;
    //   //       }
    //   //     } catch (error) { }
    //   //   },
    //   // },
    //   // {
    //   //   headerName: "Revoke Assignment",
    //   //   field: "revoke_vehicle",
    //   //   width: 160,
    //   //   filter: true,
    //   //   resizable: true,
    //   //   editable: false,
    //   //   cellRendererSelector: function (params) {
    //   //     try {
    //   //       if (params.data.route_finalized == 1) {
    //   //         var rendComponent = {
    //   //           component: "RevokeVehicleBtn",
    //   //         };
    //   //         return rendComponent;
    //   //       } else {
    //   //         var rendComponent = {
    //   //           component: "NAButton",
    //   //         };
    //   //         return rendComponent;
    //   //       }
    //   //     } catch (error) { }
    //   //   },
    //   // },
    //   {
    //     headerName: "Date",
    //     headerTooltip: "Date",
    //     field: "execution_date",
    //     width: "90",
    //   },
    //   {
    //     headerName: "Trip No",
    //     headerTooltip: "Trip No",
    //     field: "trip_no",
    //     width: "90",
    //   },
    //   {
    //     headerName: "Vehicle No",
    //     headerTooltip: "Vehicle No",
    //     field: "vehicle_no",
    //     width: "120",
    //     cellRendererSelector: function (params) {
    //       try {
    //         var rendComponent = {
    //           component: "RepeatIcon",
    //         };
    //         return rendComponent;
    //       } catch (error) { }
    //     },
    //   },
    //   {
    //     headerName: "Vehicle Type",
    //     headerTooltip: "Vehicle Type",
    //     field: "vehicle_type",
    //     width: 80,
    //   },
    //   // {
    //   //     headerName:"Transaporter Code",
    //   //     field:"transporter_code",
    //   //     width:"150"
    //   // },
    //   // {
    //   //     headerName:"Transaporter Name",
    //   //     field:"transporter_name",
    //   //     width:"150"
    //   // },
    //   // {
    //   //     headerName:"Route Mode",
    //   //     field:"route_mode",
    //   //     width:"120"
    //   // },
    //   // {
    //   //     headerName:"Order Date",
    //   //     field:"order_date",
    //   //     width:"120",
    //   //     valueGetter:function(params){
    //   //         try {
    //   //             if(params.data.order_date !== "" && params.data.order_date !== undefined)
    //   //             {
    //   //                 return getHyphenDDMMMYYYY(params.data.order_date);
    //   //             }
    //   //             else{
    //   //                 return "";
    //   //             }
    //   //         } catch (error) {

    //   //         }
    //   //     }
    //   // },

    //   {
    //     headerName: "Transporter Name",
    //     headerTooltip: "Transporter Name",
    //     field: "transporter_name",
    //     width: "130",
    //   },
    //   // {
    //   //     headerName:"Pick Up Location (Source)",
    //   //     headerTooltip:"Pick Up Location (Source)",
    //   //     field:"pickup_node",
    //   //     width:"150",
    //   // },
    //   {
    //     headerName: "No Of Orders",
    //     headerTooltip: "No Of Orders",
    //     field: "no_of_drops",
    //     width: "90",
    //   },
    //   {
    //     headerName: "No Of Customers",
    //     headerTooltip: "No Of Customers",
    //     field: "no_of_customer",
    //     width: "100",
    //   },
    //   {
    //     headerName: "Total Quantity",
    //     headerTooltip: "Total Quantity",
    //     field: "quantity",
    //     width: "100",
    //   },
    //   {
    //     headerName: "Vehicle Utilization (%)",
    //     headerTooltip: "Vehicle Utilization (%)",
    //     field: "total_weight",
    //     width: "120",
    //     valueGetter: function (params) {
    //       try {
    //         if (
    //           params.data.demand_weight !== undefined &&
    //           params.data.demand_weight !== ""
    //         ) {
    //           return (Math.max(
    //             ((params.data.demand_weight / params.data.vehicle_max_weight) *
    //               100
    //             ).toFixed(2), ((params.data.demand_volume / params.data.vehicle_max_volume) *
    //               100
    //             ).toFixed(2)))
    //         } else {
    //           return "";
    //         }
    //       } catch (error) { }
    //     },
    //   },
    //   {
    //     headerName: "Vehicle Max Weight (Kgs)",
    //     headerTooltip: "Vehicle Max Weight (Kgs)",
    //     field: "vehicle_max_weight",
    //     width: 120,
    //   },
    //   {
    //     headerName: "Total Planned Weight (Kgs)",
    //     headerTooltip: "Total Planed Weight (Kgs)",
    //     field: "demand_weight",
    //     width: "120",
    //   },
    //   {
    //     headerName: "Onward Trip Distance (Kms)",
    //     headerTooltip: "Onward Trip Distance (Kms)",
    //     field: "distance",
    //     width: 130,
    //   },
    //   {
    //     headerName: "Round Trip (Kms)",
    //     headerTooltip: "Round Trip (Kms)",
    //     field: "round_trip_distance",
    //     width: 110,
    //   },
    //   {
    //     headerName: "Vehicle Max Volume (M3)",
    //     headerTooltip: "Vehicle Max Volume (M3)",
    //     field: "vehicle_max_volume",
    //     width: 120,
    //   },
    //   {
    //     headerName: "Total Planned Volume (M3)",
    //     headerTooltip: "Total Planed Volume (M3)",
    //     field: "demand_volume",
    //     width: "120",
    //   },

    //   // {
    //   //     headerName:"Warehouse (Source)",
    //   //     field:"pickup_node",
    //   //     width:"150",
    //   // },
    //   // {
    //   //     headerName:"Total No of Tires",
    //   //     field:"no_of_tires",
    //   //     width:"150",
    //   // },
    //   // {
    //   //     headerName:"Trip Weight (Tons)",
    //   //     field:"demand",
    //   //     width:140,
    //   //     valueGetter:function(params){
    //   //         try {
    //   //             if(params.data.demand !== "" && params.data.demand !== undefined)
    //   //             {
    //   //                 return Math.round(params.data.demand);
    //   //             }
    //   //             else{
    //   //                 return "";
    //   //             }
    //   //         } catch (error) {

    //   //         }
    //   //     }
    //   // },
    //   // {
    //   //   headerName: "Loading Start Time (HH:MM)",
    //   //   headerTooltip: "Loading Start Time",
    //   //   field: "vehicle_loading_start_time",
    //   //   width: 140,
    //   // },
    //   {
    //     headerName: "Max Dispatch Time (HH:MM)",
    //     headerTooltip: "Max Dispatch Time (HH:MM)",
    //     field: "max_start_time",
    //     width: 140,
    //     valueGetter: (par) => {
    //       return par.data.max_start_time
    //     }
    //   },
    //   {
    //     headerName: "Arrival At First Dealer (Start)",
    //     headerTooltip: "Arrival At First Dealer (Start)",
    //     field: "arrival_time_at_first_dealer",
    //     width: 130,
    //     valueGetter: function (params) {
    //       try {
    //         if (
    //           params.data.arrival_time_at_first_dealer !== "" &&
    //           params.data.arrival_time_at_first_dealer !== undefined
    //         ) {
    //           return params.data.arrival_time_at_first_dealer;
    //           // return getHyphenDDMMMYYYYHHMM(params.data.arrival_time_at_first_dealer);
    //         } else {
    //           return "";
    //         }
    //       } catch (error) { }
    //     },
    //   },
    //   {
    //     headerName: "Departure At Last Dealer (End)",
    //     headerTooltip: "Departure At Last Dealer (End)",
    //     field: "last_dealer_time_to_complete",
    //     width: 140,
    //     valueGetter: function (params) {
    //       try {
    //         if (
    //           params.data.last_dealer_time_to_complete !== "" &&
    //           params.data.last_dealer_time_to_complete !== undefined
    //         ) {
    //           return params.data.last_dealer_time_to_complete;
    //           // return getHyphenDDMMMYYYYHHMM(params.data.last_dealer_time_to_complete);
    //         } else {
    //           return "";
    //         }
    //       } catch (error) { }
    //     },
    //   },
    //   {
    //     headerName: "Return to Plant (HH:MM)",
    //     headerTooltip: "Return to Plant",
    //     field: "return_time_to_plant_from_last_dealer",
    //     width: 140,
    //     valueGetter: function (params) {
    //       try {
    //         if (
    //           params.data.return_time_to_plant_from_last_dealer !== "" &&
    //           params.data.return_time_to_plant_from_last_dealer !== undefined
    //         ) {
    //           // return getHyphenDDMMMYYYYHHMM(params.data.return_time_to_plant_from_last_dealer);
    //           return params.data.return_time_to_plant_from_last_dealer;
    //         } else {
    //           return "";
    //         }
    //       } catch (error) { }
    //     },
    //   },
    //   {
    //     headerName: "Total Planned Trip Time(Hrs)",
    //     headerTooltip: "Total Planned Trip Time",
    //     field: "total_plan_time_hrs",
    //     width: 130,
    //   },
    //   {
    //     headerName: "Total Round Trip Time(Hrs)",
    //     headerTooltip: "Total Round Trip Time",
    //     field: "round_trip_time",
    //     width: 130,
    //   },
    //   {
    //     headerName: "Total Transit Time (HH:MM)",
    //     headerTooltip: "Total Transit Time",
    //     field: "total_drop_node_transit_time_hrs",
    //     width: 130,
    //   },
    //   {
    //     headerName: "Total Loading Time (HH:MM)",
    //     headerTooltip: "Total Loading Time (HH:MM)",
    //     field: "total_loading_time",
    //     width: 130,
    //     valueGetter: function (params) {
    //       try {
    //         if (
    //           params.data.total_loading_time !== "" &&
    //           params.data.total_loading_time !== undefined
    //         ) {
    //           // return getHyphenDDMMMYYYYHHMM(params.data.loadingTime);
    //           return params.data.total_loading_time;
    //         } else {
    //           return "";
    //         }
    //       } catch (error) { }
    //     },
    //   },
    //   {
    //     headerName: "Total Unloading Time (HH:MM)",
    //     headerTooltip: "Total Unloading Time (HH:MM)",
    //     field: "total_unloading_time",
    //     width: 130,
    //     valueGetter: function (params) {
    //       try {
    //         if (
    //           params.data.total_unloading_time !== "" &&
    //           params.data.total_unloading_time !== undefined
    //         ) {
    //           // return getHyphenDDMMMYYYYHHMM(params.data.unLoadingTime);
    //           return params.data.total_unloading_time;
    //         } else {
    //           return "";
    //         }
    //       } catch (error) { }
    //     },
    //   },
    //   // {
    //   //     headerName:"Zone",
    //   //     field:"zone",
    //   //     width:"100"
    //   // },
    //   // {
    //   //     headerName:"Drop Location (Destination)",
    //   //     field:"drop_node",
    //   //     width:"150"
    //   // },
    //   // {
    //   //     headerName:"Route No",
    //   //     field:"route_no",
    //   //     width:"120",
    //   // },
    //   // {
    //   //     headerName:"Vehicle Volume",
    //   //     field:"vehicle_volume",
    //   //     width:"120"
    //   // },
    //   // {
    //   //     headerName:"Vehicle Priority",
    //   //     field:"vehicle_priority",
    //   //     width:140
    //   // },
    // ];
    var plannedcolsDefs = [
      {
        headerName: t('details'),
        field: "details",
        width: 100,
        filter: true,
        resizable: true,
        editable: false,
        params: {
          buttonName: "View",
          iconName: "fa fa-eye",
          onClickFunction: this.onShowLegsData,
        },
        cellRendererSelector: function (params) {
          var rendComponent = {
            component: "GridButton",
          };
          return rendComponent;
        },
      },
      {
        headerName: t('date'),
        field: "execution_date",
        width: "90",
      },
      {
        headerName: t('trip_no'),
        field: "trip_no",
        width: "90",
      },
      {
        headerName: t('vehicle_no'),
        field: "vehicle_no",
        width: "120",
        cellRendererSelector: function (params) {
          var rendComponent = {
            component: "RepeatIcon",
          };
          return rendComponent;
        },
      },
      {
        headerName: t('vehicle_type'),
        field: "vehicle_type",
        width: 80,
      },
      {
        headerName: t('transporter_name'),
        field: "transporter_name",
        width: "130",
      },
      {
        headerName: t('no_of_orders'),
        field: "no_of_drops",
        width: "90",
      },
      {
        headerName: t('no_of_customers'),
        field: "no_of_customer",
        width: "100",
      },
      {
        headerName: t('total_quantity'),
        field: "quantity",
        width: "100",
      },
      {
        headerName: t('vehicle_utilization_percent'),
        field: "total_weight",
        width: "120",
        valueGetter: function (params) {
          if (params.data.demand_weight !== undefined && params.data.demand_weight !== "") {
            return Math.max(
              ((params.data.demand_weight / params.data.vehicle_max_weight) * 100).toFixed(2),
              ((params.data.demand_volume / params.data.vehicle_max_volume) * 100).toFixed(2)
            );
          } else {
            return "";
          }
        },
      },
      {
        headerName: t('vehicle_max_weight_kgs'),
        field: "vehicle_max_weight",
        width: 120,
      },
      {
        headerName: t('total_planned_weight_kgs'),
        field: "demand_weight",
        width: "120",
      },
      {
        headerName: t('onward_trip_distance_kms'),
        field: "distance",
        width: 130,
      },
      {
        headerName: t('round_trip_distance_kms'),
        field: "round_trip_distance",
        width: 110,
      },
      {
        headerName: t('vehicle_max_volume_m3'),
        field: "vehicle_max_volume",
        width: 120,
      },
      {
        headerName: t('total_planned_volume_m3'),
        field: "demand_volume",
        width: "120",
      },
      {
        headerName: t('max_dispatch_time_hh_mm'),
        field: "max_start_time",
        width: 140,
        valueGetter: (par) => {
          return par.data.max_start_time;
        },
      },
      {
        headerName: t('arrival_at_first_dealer_start'),
        field: "arrival_time_at_first_dealer",
        width: 130,
        valueGetter: function (params) {
          if (params.data.arrival_time_at_first_dealer !== "" && params.data.arrival_time_at_first_dealer !== undefined) {
            return params.data.arrival_time_at_first_dealer;
          } else {
            return "";
          }
        },
      },
      {
        headerName: t('departure_at_last_dealer_end'),
        field: "last_dealer_time_to_complete",
        width: 140,
        valueGetter: function (params) {
          if (params.data.last_dealer_time_to_complete !== "" && params.data.last_dealer_time_to_complete !== undefined) {
            return params.data.last_dealer_time_to_complete;
          } else {
            return "";
          }
        },
      },
      {
        headerName: t('return_to_plant_hh_mm'),
        field: "return_time_to_plant_from_last_dealer",
        width: 140,
        valueGetter: function (params) {
          if (params.data.return_time_to_plant_from_last_dealer !== "" && params.data.return_time_to_plant_from_last_dealer !== undefined) {
            return params.data.return_time_to_plant_from_last_dealer;
          } else {
            return "";
          }
        },
      },
      {
        headerName: t('total_planned_trip_time_hrs'),
        field: "total_plan_time_hrs",
        width: 130,
      },
      {
        headerName: t('total_round_trip_time_hrs'),
        field: "round_trip_time",
        width: 130,
      },
      {
        headerName: t('total_transit_time_hh_mm'),
        field: "total_drop_node_transit_time_hrs",
        width: 130,
      },
      {
        headerName: t('total_loading_time_hh_mm'),
        field: "total_loading_time",
        width: 130,
        valueGetter: function (params) {
          if (params.data.total_loading_time !== "" && params.data.total_loading_time !== undefined) {
            return params.data.total_loading_time;
          } else {
            return "";
          }
        },
      },
      {
        headerName: t('total_unloading_time_hh_mm'),
        field: "total_unloading_time",
        width: 130,
        valueGetter: function (params) {
          if (params.data.total_unloading_time !== "" && params.data.total_unloading_time !== undefined) {
            return params.data.total_unloading_time;
          } else {
            return "";
          }
        },
      },
    ];
    
    // var unPlannedcolsDefs = [
    //   // {
    //   //     headerName:"Vehicle No",
    //   //     field:"vehicle_no",
    //   //     width:"100"
    //   // },
    //   // {
    //   //     headerName:"Cluster",
    //   //     field:"cluster",
    //   //     width:"100"
    //   // },
    //   // {
    //   //     headerName:"Zone",
    //   //     field:"zone",
    //   //     width:"100"
    //   // },
    //   // {
    //   //     headerName:"Pick Up Location (Source)",
    //   //     field:"pickup_node",
    //   //     width:"150",
    //   // },
    //   {
    //     headerName: "Drop Location Code",
    //     headerTooltip: "Drop Location Code",
    //     field: "drop_node",
    //     width: "150",
    //   },
    //   {
    //     headerName: "Drop Location Name",
    //     headerTooltip: "Drop Location Name",
    //     field: "drop_node_name",
    //     width: "180",
    //   },
    //   {
    //     headerName: "Drop Location City",
    //     headerTooltip: "Drop Location City",
    //     field: "demand_node_location",
    //     width: "150",
    //   },
    //   {
    //     headerName: "Order Number",
    //     field: "order_number",
    //     width: "120",
    //     valueGetter: (params) => {
    //       return params.data.order_number
    //     }
    //   },
    //   {
    //     headerName: "Demand Volume (M3)",
    //     headerTooltip: "Demand Volume (M3)",
    //     field: "demand_volume",
    //     width: "130",
    //   },
    //   {
    //     headerName: "Weight (Kgs)",
    //     headerTooltip: "Weight (Kgs)",
    //     field: "demand_weight",
    //     width: "120",
    //   },
    //   {
    //     headerName: "Quantity",
    //     headerTooltip: "Quantity",
    //     field: "quantity",
    //     width: "100",
    //     valueGetter: (params) => {
    //       if (params.data.quantity !== '' && params.data.quantity !== undefined) {
    //         return params.data.quantity.toFixed(2)
    //       } else {
    //         return 'N/A'
    //       }
    //     }
    //   },
    //   {
    //     headerName: "Delivery Date",
    //     field: "delivery_date",
    //     width: "120",
    //   },
    //   // {
    //   //     headerName:"Sales Order No",
    //   //     field:"so_number",
    //   //     width:"180",
    //   // },

    //   // {
    //   //     headerName:"Route No",
    //   //     field:"route_no",
    //   //     width:"120",
    //   // },
    //   // {
    //   //     headerName:"Vehicle Type",
    //   //     field:"vehicle_type",
    //   //     width:"120"
    //   // },
    //   // {
    //   //     headerName:"Vehicle Volume",
    //   //     field:"vehicle_volume",
    //   //     width:"120"
    //   // },
    //   // {
    //   //     headerName:"Vehicle Priority",
    //   //     field:"vehicle_priority",
    //   //     width:"120"
    //   // },
    //   // {
    //   //     headerName:"Transaporter Code",
    //   //     field:"transporter_code",
    //   //     width:"120"
    //   // },
    //   // {
    //   //     headerName:"Transaporter Name",
    //   //     field:"transporter_name",
    //   //     width:"120"
    //   // },
    //   // {
    //   //     headerName:"Route Mode",
    //   //     field:"route_mode",
    //   //     width:"120"
    //   // },
    // ];
    var unPlannedcolsDefs = [
      {
        headerName: t('drop_location_code'),
        field: "drop_node",
        width: "150",
      },
      {
        headerName: t('drop_location_name'),
        field: "drop_node_name",
        width: "180",
      },
      {
        headerName: t('drop_location_city'),
        field: "demand_node_location",
        width: "150",
      },
      {
        headerName: t('order_number'),
        field: "order_number",
        width: "120",
        valueGetter: (params) => {
          return params.data.order_number;
        }
      },
      {
        headerName: t('demand_volume_m3'),
        field: "demand_volume",
        width: "130",
      },
      {
        headerName: t('weight_kgs'),
        field: "demand_weight",
        width: "120",
      },
      {
        headerName: t('quantity'),
        field: "quantity",
        width: "100",
        valueGetter: (params) => {
          if (params.data.quantity !== '' && params.data.quantity !== undefined) {
            return params.data.quantity.toFixed(2);
          } else {
            return 'N/A';
          }
        }
      },
      {
        headerName: t('delivery_date'),
        field: "delivery_date",
        width: "120",
      },
    ];
    
    var tool_config = this.state.tool_config;
    var material_configuration_chk = 0;
    if (tool_config != undefined && tool_config != "") {
      material_configuration_chk = tool_config[0].material_configuration;
    }
    // var inputDetailscolsDefs = [
    //   {
    //     headerName: "Pickup Location (Source)",
    //     headerTooltip: "Pickup Location (Source)",
    //     field: "pickup_location_code",
    //     width: 140,
    //     filter: true,
    //     resizable: true,
    //     editable: false,
    //   },
    //   {
    //     headerName: "Drop Location Code (Destination)",
    //     headerTooltip: "Drop Location Code (Destination)",
    //     field: "drop_location_code",
    //     width: 160,
    //     filter: true,
    //     resizable: true,
    //     editable: false,
    //   },
    //   {
    //     headerName: "Drop Location Name (Destination)",
    //     headerTooltip: "Drop Location Name (Destination)",
    //     field: "drop_location_name",
    //     width: 150,
    //     filter: true,
    //     resizable: true,
    //     editable: false,
    //   },
    //   // {
    //   //   headerName: "Drop Location City",
    //   //   headerTooltip: "Drop Location City",
    //   //   field: "location_city",
    //   //   width: 140,
    //   //   filter: true,
    //   //   resizable: true,
    //   //   editable: false,
    //   // },
    //   // {
    //   //   headerName: "Week Off",
    //   //   field: "holiday",
    //   //   width: 100,
    //   //   filter: true,
    //   //   resizable: true,
    //   //   editable: false,
    //   // },
    //   // {
    //   //     headerName: "Destination State",
    //   //     field: "drop_location_state",
    //   //     width:200,
    //   //     filter: true,
    //   //     resizable: true,
    //   //     editable:false
    //   // },
    //   // {
    //   //     headerName: "Sales Order No",
    //   //     field: "so_number",
    //   //     width:200,
    //   //     filter: true,
    //   //     resizable: true,
    //   //     editable:false
    //   // },
    //   // {
    //   //     headerName: "No. of Tires",
    //   //     field: "no_of_tires",
    //   //     width:200,
    //   //     filter: true,
    //   //     resizable: true,
    //   //     editable:false
    //   // },
    //   {
    //     headerName: "Order Number",
    //     headerTooltip: "Order Number",
    //     field: "order_number",
    //     width: "130",
    //   },
    //   {
    //     headerName: "Invoicing Date",
    //     headerTooltip: "Invoicing Date",
    //     field: "invoicing_date",
    //     width: "120",
    //   },
    //   {
    //     headerName: "Quantity",
    //     headerTooltip: "Quantity",
    //     field: "quantity",
    //     width: "100",
    //   },
    //   {
    //     headerName: "Weight (Kgs)",
    //     headerTooltip: "Weight (Kgs)",
    //     field: "weight_tons",
    //     width: 120,
    //     filter: true,
    //     resizable: true,
    //     editable: false,
    //     valueGetter: function (params) {
    //       try {
    //         if (
    //           params.data.weight_tons !== "" &&
    //           params.data.weight_tons !== undefined
    //         ) {
    //           return Math.round(params.data.weight_tons * 100) / 100;
    //         } else {
    //           return "";
    //         }
    //       } catch (error) { }
    //     },
    //   },
    //   {
    //     headerName: "Demand Volume (M3)",
    //     headerTooltip: "Demand Volume (M3)",
    //     field: "demand_cmt",
    //     width: 120,
    //     filter: true,
    //     resizable: true,
    //     editable: false,
    //     valueGetter: function (params) {
    //       try {
    //         if (
    //           params.data.demand_cmt !== "" &&
    //           params.data.demand_cmt !== undefined
    //         ) {
    //           return Math.round(params.data.demand_cmt * 100) / 100;
    //         } else {
    //           return "";
    //         }
    //       } catch (error) { }
    //     },
    //   },
    //   {
    //     headerName: "Dealer Available From",
    //     headerTooltip: "Dealer Available From",
    //     field: "dealer_available_start",
    //     width: "130",
    //   },
    //   {
    //     headerName: "Dealer Available To",
    //     headerTooltip: "Dealer Available To",
    //     field: "dealer_available_end",
    //     width: "120",
    //   },
    //   {
    //     headerName: "Delivery Date",
    //     field: "delivery_date",
    //     width: "120",
    //   },
    //   // {
    //   //     headerName: "Order Date",
    //   //     field: "order_date",
    //   //     width:200,
    //   //     filter: true,
    //   //     resizable: true,
    //   //     editable:false,
    //   //     valueGetter:function(params){
    //   //         try {
    //   //             if(params.data.order_date !== "" && params.data.order_date !== undefined)
    //   //             {
    //   //                 return getHyphenDDMMMYYYY(params.data.order_date);
    //   //             }
    //   //             else{
    //   //                 return "";
    //   //             }
    //   //         } catch (error) {

    //   //         }
    //   //     }
    //   // }
    // ];
    // var forecastingVehiclescolsDefs = [
    //   {
    //     headerName: "Vehicle Type",
    //     field: "vehicle_type",
    //     width: 140,
    //     filter: true,
    //     resizable: true,
    //     editable: false,
    //   },
    //   {
    //     headerName: "Plant Code",
    //     field: "location_code",
    //     width: 140,
    //     filter: true,
    //     resizable: true,
    //     editable: false,
    //   },
    //   // {
    //   //   headerName: "Plant Name",
    //   //   field: "location_name",
    //   //   width: 140,
    //   //   filter: true,
    //   //   resizable: true,
    //   //   editable: false,
    //   // },
    //   {
    //     headerName: "Vehicle No",
    //     field: "vehicle_no",
    //     width: 140,
    //     filter: true,
    //     resizable: true,
    //     editable: false,
    //   },
    //   // {
    //   //   headerName: "Transporter Name",
    //   //   field: "transporter_name",
    //   //   width: 140,
    //   //   filter: true,
    //   //   resizable: true,
    //   //   editable: false,
    //   // },
    //   {
    //     headerName: "Monthly SOB (Km)",
    //     field: "monthly_min_planning_distance",
    //     width: 140,
    //     filter: true,
    //     resizable: true,
    //     editable: false,
    //   },
    //   {
    //     headerName: "Distance Travelled (Km)",
    //     field: "max_distance_per_day",
    //     width: 140,
    //     filter: true,
    //     resizable: true,
    //     editable: false,
    //     valueGetter: function (params) {
    //       try {
    //         if (
    //           params.data.max_distance_per_day !== "" &&
    //           params.data.max_distance_per_day !== undefined
    //         ) {
    //           return Math.round(parseFloat(params.data.max_distance_per_day) - parseFloat(params.data.remaining_distance) );
    //         } else {
    //           return "";
    //         }
    //       } catch (error) { }
    //     },
    //   },
    //   // {
    //   //   headerName: "Remaining Distance",
    //   //   field: "remaining_distance",
    //   //   width: 140,
    //   //   filter: true,
    //   //   resizable: true,
    //   //   editable: false,
    //   // },
    //   // {
    //   //   headerName: "Max Distance Per Day",
    //   //   field: "max_distance_per_day",
    //   //   width: 140,
    //   //   filter: true,
    //   //   resizable: true,
    //   //   editable: false,
    //   // }
    // ]

    var inputDetailscolsDefs = [
      {
        headerName: t('pickup_location_source'),
        headerTooltip: t('pickup_location_source'),
        field: "pickup_location_code",
        width: 140,
        filter: true,
        resizable: true,
        editable: false,
      },
      {
        headerName: t('drop_location_code_destination'),
        headerTooltip: t('drop_location_code_destination'),
        field: "drop_location_code",
        width: 160,
        filter: true,
        resizable: true,
        editable: false,
      },
      {
        headerName: t('drop_location_name_destination'),
        headerTooltip: t('drop_location_name_destination'),
        field: "drop_location_name",
        width: 150,
        filter: true,
        resizable: true,
        editable: false,
      },
      {
        headerName: t('order_number'),
        headerTooltip: t('order_number'),
        field: "order_number",
        width: "130",
      },
      {
        headerName: t('invoicing_date'),
        headerTooltip: t('invoicing_date'),
        field: "invoicing_date",
        width: "120",
      },
      {
        headerName: t('quantity'),
        headerTooltip: t('quantity'),
        field: "quantity",
        width: "100",
      },
      {
        headerName: t('weight_kgs'),
        headerTooltip: t('weight_kgs'),
        field: "weight_tons",
        width: 120,
        filter: true,
        resizable: true,
        editable: false,
        valueGetter: function (params) {
          try {
            if (
              params.data.weight_tons !== "" &&
              params.data.weight_tons !== undefined
            ) {
              return Math.round(params.data.weight_tons * 100) / 100;
            } else {
              return "";
            }
          } catch (error) {}
        },
      },
      {
        headerName: t('demand_volume_m3'),
        headerTooltip: t('demand_volume_m3'),
        field: "demand_cmt",
        width: 120,
        filter: true,
        resizable: true,
        editable: false,
        valueGetter: function (params) {
          try {
            if (
              params.data.demand_cmt !== "" &&
              params.data.demand_cmt !== undefined
            ) {
              return Math.round(params.data.demand_cmt * 100) / 100;
            } else {
              return "";
            }
          } catch (error) {}
        },
      },
      {
        headerName: t('dealer_available_from'),
        headerTooltip: t('dealer_available_from'),
        field: "dealer_available_start",
        width: "130",
      },
      {
        headerName: t('dealer_available_to'),
        headerTooltip: t('dealer_available_to'),
        field: "dealer_available_end",
        width: "120",
      },
      {
        headerName: t('delivery_date'),
        field: "delivery_date",
        width: "120",
      }
    ];
    

    var forecastingVehiclescolsDefs = [
      {
        headerName: t('vehicle_type'),
        field: "vehicle_type",
        width: 140,
        filter: true,
        resizable: true,
        editable: false,
      },
      {
        headerName: t('plant_code'),
        field: "location_code",
        width: 140,
        filter: true,
        resizable: true,
        editable: false,
      },
      {
        headerName: t('vehicle_no'),
        field: "vehicle_no",
        width: 140,
        filter: true,
        resizable: true,
        editable: false,
      },
      {
        headerName: t('monthly_sob_km'),
        field: "monthly_min_planning_distance",
        width: 140,
        filter: true,
        resizable: true,
        editable: false,
      },
      {
        headerName: t('distance_travelled_km'),
        field: "max_distance_per_day",
        width: 140,
        filter: true,
        resizable: true,
        editable: false,
        valueGetter: function (params) {
          try {
            if (
              params.data.max_distance_per_day !== "" &&
              params.data.max_distance_per_day !== undefined
            ) {
              return Math.round(
                parseFloat(params.data.max_distance_per_day) -
                parseFloat(params.data.remaining_distance)
              );
            } else {
              return "";
            }
          } catch (error) {}
        },
      },
    ];
    
    if (material_configuration_chk == 1) {
      inputDetailscolsDefs.push(
        {
          headerName: "Material",
          headerTooltip: "Material",
          field: "material_code",
          width: "250",
        },
        {
          headerName: "Quantity",
          headerTooltip: "Quantity",
          field: "quantity",
          width: "250",
        }
      );
    }
    // else
    // {
    //     inputDetailscolsDefs.push(
    //         {
    //             headerName:"No of Passengers",
    //             field:"demand_cmt",
    //             width:"250",
    //         }
    //     );
    // }

    // var planSummarycolsDefs = [
    //   {
    //     headerName: "Trip No",
    //     field: "trip_no",
    //     width: "90",
    //   },
    //   {
    //     headerName: "Planned Day",
    //     headerTooltip: "Planned Day",
    //     field: "drop_node_total_time_day",
    //     width: "110",
    //     valueGetter: function (params) {
    //       try {
    //         if (
    //           params.data.drop_node_total_time_day !== "" &&
    //           params.data.drop_node_total_time_day !== undefined
    //         ) {
    //           var a = parseInt(params.data.drop_node_total_time_day);
    //           return "Day - " + (a + 1);
    //         } else {
    //           return params.data.drop_node_total_time_day;
    //         }
    //       } catch (error) { }
    //     },
    //   },
    //   {
    //     headerName: "Vehicle No",
    //     field: "vehicle_no",
    //     width: "120",
    //     cellRendererSelector: function (params) {
    //       try {
    //         var rendComponent = {
    //           component: "RepeatIcon",
    //         };
    //         return rendComponent;
    //       } catch (error) { }
    //     },
    //   },
    //   {
    //     headerName: "Vehicle Type",
    //     field: "vehicle_type",
    //     width: "80",
    //   },
    //   {
    //     headerName: "Drop Sequence",
    //     headerTooltip: "Drop Sequence",
    //     field: "drop_sequence",
    //     width: "100",
    //   },

    //   {
    //     headerName: "Order Number",
    //     headerTooltip: "Order Number",
    //     field: "order_number",
    //     width: "120",
    //   },
    //   {
    //     headerName: "Invoicing Date",
    //     headerTooltip: "Invoicing Date",
    //     field: "invoicing_date",
    //     width: "100",
    //   },
    //   // {
    //   //     headerName:"Transaporter Code",
    //   //     field:"transporter_code",
    //   //     width:"150"
    //   // },
    //   // {
    //   //     headerName:"Transaporter Name",
    //   //     field:"transporter_name",
    //   //     width:"150"
    //   // },
    //   // {
    //   //     headerName:"Route Mode",
    //   //     field:"route_mode",
    //   //     width:"120"
    //   // },
    //   {
    //     headerName: "Pick Up Location (Source)",
    //     field: "pickup_node",
    //     width: "140",
    //   },
    //   {
    //     headerName: "Drop Location Code (Destination)",
    //     headerTooltip: "Drop Location Code (Destination)",
    //     field: "drop_node",
    //     width: "150",
    //   },
    //   {
    //     headerName: "Drop Location Name (Destination)",
    //     headerTooltip: "Drop Location Name (Destination)",
    //     field: "drop_node_name",
    //     width: "150",
    //   },
    //   {
    //     headerName: "Destination Location City",
    //     headerTooltip: "Destination Location City",
    //     field: "demand_node_location",
    //     width: "120",
    //   },
    //   {
    //     headerName: "Destination Location State",
    //     headerTooltip: "Destination Location State",
    //     field: "demand_node_state",
    //     width: "120",
    //   },
    //   // {
    //   //     headerName:"Vehicle Max Volume(M³)",
    //   //     field:"vehicle_max_volume",
    //   //     width:160
    //   // },
    //   // {
    //   //     headerName:"Vehicle Max Weight(Kgs)",
    //   //     field:"vehicle_max_weight",
    //   //     width:160
    //   // },
    //   {
    //     headerName: "Quantity",
    //     headerTooltip: "Quantity",
    //     field: "quantity",
    //     width: "90",
    //     valueGetter: (params) => {
    //       try {
    //         if (params.data.quantity !== undefined && params.data.quantity !== '') {
    //           return (params.data.quantity).toFixed(2)
    //         } else {
    //           return 'N/A'
    //         }
    //       } catch {

    //       }
    //     }
    //   },
    //   {
    //     headerName: "Volume (M3)",
    //     headerTooltip: "Volume (M3)",
    //     field: "demand_volume",
    //     width: "90",
    //   },
    //   {
    //     headerName: "Weight (Kgs)",
    //     headerTooltip: "Weight (Kgs)",
    //     field: "demand_weight",
    //     width: "90",
    //   },
    //   // {
    //   //     headerName:"Distance From Pickup Location (Kms)",
    //   //     headerTooltip:"Distance From Pickup Location (Kms)",
    //   //     field:"distance",
    //   //     width:"200",
    //   // },

    //   {
    //     headerName: "Distance To Destination (Kms)",
    //     headerTooltip: "Distance To Destination (Kms)",
    //     field: "distance",
    //     width: "150",
    //   },
    //   {
    //     headerName: "Expected Delivery Date",
    //     field: "delivery_date",
    //     width: "120",
    //   },
    //   // {
    //   //     headerName:"Time From Pickup Location (HH:MM)",
    //   //     headerTooltip:"Time From Pickup Location (HH:MM)",
    //   //     field:"drop_node_total_time_hrs",
    //   //     width:"200",
    //   // },
    //   {
    //     headerName: "Time To Destination",
    //     headerTooltip: "Time To Destination",
    //     field: "drop_node_total_time_hrs",
    //     width: "150",
    //   },
    //   {
    //     headerName: "Transit Time (HH:MM)",
    //     headerTooltip: "Transit Time (HH:MM)",
    //     field: "drop_node_transit_time_hrs",
    //     width: "120",
    //   },
    //   {
    //     headerName: "Loading Time (HH:MM)",
    //     headerTooltip: "Loading Time (HH:MM)",
    //     field: "drop_node_loading_time_mins",
    //     width: "120",
    //   },
    //   {
    //     headerName: "Unloading Time (HH:MM)",
    //     headerTooltip: "Unloading Time (HH:MM)",
    //     field: "drop_node_unloading_time_mins",
    //     width: "120",
    //   },
    //   // {
    //   //     headerName:"Distance From Previous Location (Kms)",
    //   //     field:"distance_from_prev_location",
    //   //     width:"200",
    //   // },
    //   // {
    //   //     headerName:"Time From Previous Location (HH:MM)",
    //   //     field:"time_from_prev_location",
    //   //     width:"200",
    //   // },
    //   // {
    //   //     headerName:"Drop Location City",
    //   //     field:"demand_node_location",
    //   //     width:"120",
    //   // },
    //   // {
    //   //     headerName:"Zone",
    //   //     field:"zone",
    //   //     width:"100"
    //   // },
    //   // {
    //   //     headerName:"Drop Location (Destination)",
    //   //     field:"drop_node",
    //   //     width:"150"
    //   // },
    //   // {
    //   //     headerName:"Vehicle Volume",
    //   //     field:"vehicle_volume",
    //   //     width:"120"
    //   // },
    //   // {
    //   //     headerName:"Vehicle Priority",
    //   //     field:"vehicle_priority",
    //   //     width:"120"
    //   // },
    // ];

    var planSummarycolsDefs = [
      {
        headerName: t('trip_no'),
        field: "trip_no",
        width: "90",
      },
      {
        headerName: t('planned_day'),
        headerTooltip: t('planned_day'),
        field: "drop_node_total_time_day",
        width: "110",
        valueGetter: function (params) {
          try {
            if (
              params.data.drop_node_total_time_day !== "" &&
              params.data.drop_node_total_time_day !== undefined
            ) {
              var a = parseInt(params.data.drop_node_total_time_day);
              return "Day - " + (a + 1);
            } else {
              return params.data.drop_node_total_time_day;
            }
          } catch (error) {}
        },
      },
      {
        headerName: t('vehicle_no'),
        field: "vehicle_no",
        width: "120",
        cellRendererSelector: function (params) {
          try {
            var rendComponent = {
              component: "RepeatIcon",
            };
            return rendComponent;
          } catch (error) {}
        },
      },
      {
        headerName: t('vehicle_type'),
        field: "vehicle_type",
        width: "80",
      },
      {
        headerName: t('drop_sequence'),
        headerTooltip: t('drop_sequence'),
        field: "drop_sequence",
        width: "100",
      },
      {
        headerName: t('order_number'),
        headerTooltip: t('order_number'),
        field: "order_number",
        width: "120",
      },
      {
        headerName: t('invoicing_date'),
        headerTooltip: t('invoicing_date'),
        field: "invoicing_date",
        width: "100",
      },
      {
        headerName: t('pickup_location_source'),
        field: "pickup_node",
        width: "140",
      },
      {
        headerName: t('drop_location_code_destination'),
        headerTooltip: t('drop_location_code_destination'),
        field: "drop_node",
        width: "150",
      },
      {
        headerName: t('drop_location_name_destination'),
        headerTooltip: t('drop_location_name_destination'),
        field: "drop_node_name",
        width: "150",
      },
      {
        headerName: t('destination_location_city'),
        headerTooltip: t('destination_location_city'),
        field: "demand_node_location",
        width: "120",
      },
      {
        headerName: t('destination_location_state'),
        headerTooltip: t('destination_location_state'),
        field: "demand_node_state",
        width: "120",
      },
      {
        headerName: t('quantity'),
        headerTooltip: t('quantity'),
        field: "quantity",
        width: "90",
        valueGetter: (params) => {
          try {
            if (params.data.quantity !== undefined && params.data.quantity !== '') {
              return (params.data.quantity).toFixed(2);
            } else {
              return 'N/A';
            }
          } catch {}
        }
      },
      {
        headerName: t('volume_m3'),
        headerTooltip: t('volume_m3'),
        field: "demand_volume",
        width: "90",
      },
      {
        headerName: t('weight_kgs'),
        headerTooltip: t('weight_kgs'),
        field: "demand_weight",
        width: "90",
      },
      {
        headerName: t('distance_to_destination_kms'),
        headerTooltip: t('distance_to_destination_kms'),
        field: "distance",
        width: "150",
      },
      {
        headerName: t('expected_delivery_date'),
        field: "delivery_date",
        width: "120",
      },
      {
        headerName: t('time_to_destination'),
        headerTooltip: t('time_to_destination'),
        field: "drop_node_total_time_hrs",
        width: "150",
      },
      {
        headerName: t('transit_time_hhmm'),
        headerTooltip: t('transit_time_hhmm'),
        field: "drop_node_transit_time_hrs",
        width: "120",
      },
      {
        headerName: t('loading_time_hhmm'),
        headerTooltip: t('loading_time_hhmm'),
        field: "drop_node_loading_time_mins",
        width: "120",
      },
      {
        headerName: t('unloading_time_hhmm'),
        headerTooltip: t('unloading_time_hhmm'),
        field: "drop_node_unloading_time_mins",
        width: "120",
      }
    ];

    
    // var loadsDroppedcolsDefs = [
    //   // {
    //   //     headerName:"Drop Location (Destination)",
    //   //     field:"drop_location_code",
    //   //     width:"250"
    //   // },
    //   // {
    //   //     headerName:"Pick Up Location (Source)",
    //   //     field:"pickup_node",
    //   //     width:"150",
    //   // },
    //   {
    //     headerName: "Drop Location Code (Destination)",
    //     headerTooltip: "Drop Location Code (Destination)",
    //     field: "drop_location_code",
    //     width: "150",
    //   },
    //   // {
    //   //   headerName: "Drop Location Name (Destination)",
    //   //   headerTooltip: "Drop Location Name (Destination)",
    //   //   field: "drop_location_name",
    //   //   width: "150",
    //   // },
    //   // {
    //   //   headerName: "Drop Location City",
    //   //   headerTooltip: "Drop Location City",
    //   //   field: "location_city",
    //   //   width: "150",
    //   // },
    //   {
    //     headerName: "Order Number",
    //     headerTooltip: "Order Number",
    //     field: "order_number",
    //     width: "150",
    //   },
    //   {
    //     headerName: "Demand Volume (M3)",
    //     headerTooltip: "Demand Volume (M3)",
    //     field: "demand_cmt",
    //     width: "120",
    //   },
    //   {
    //     headerName: "Weight (Kgs)",
    //     headerTooltip: "Weight (Kgs)",
    //     field: "weight_tons",
    //     width: "120",
    //   },
    //   {
    //     headerName: "Quantity",
    //     headerTooltip: "Quantity",
    //     field: "quantity",
    //     width: "100",
    //   },
    //   {
    //     headerName: "Dropped Reason",
    //     headerTooltip: "Dropped Reasong",
    //     field: "invalid_locations",
    //     width: "250",
    //     valueGetter: function (params) {
    //       try {
    //         if (
    //           params.data.invalid_location !== undefined &&
    //           params.data.invalid_location !== ""
    //         ) {
    //           if (params.data.invalid_location == 1) {
    //             return "Dealer Location Data Not In System";
    //           } else if (params.data.invalid_location == 2) {
    //             return "Delivery Date is less then Current Date";
    //           }
    //         } else {
    //           return "Missing Dealer Information";
    //         }
    //       } catch (error) { }
    //     },
    //   },
    //   {
    //     headerName: "Delivery Date",
    //     field: "delivery_date",
    //     width: "120",
    //   },
    // ];
    var loadsDroppedcolsDefs = [
      {
        headerName: t('drop_location_code_destination'),
        headerTooltip: "Drop Location Code (Destination)",
        field: "drop_location_code",
        width: "150",
      },
      {
        headerName: t('order_number'),
        headerTooltip: "Order Number",
        field: "order_number",
        width: "150",
      },
      {
        headerName: t('demand_volume_m3'),
        headerTooltip: "Demand Volume (M3)",
        field: "demand_cmt",
        width: "120",
      },
      {
        headerName: t('weight_kgs'),
        headerTooltip: "Weight (Kgs)",
        field: "weight_tons",
        width: "120",
      },
      {
        headerName: t('quantity'),
        headerTooltip: "Quantity",
        field: "quantity",
        width: "100",
      },
      {
        headerName: t('dropped_reason'),
        headerTooltip: "Dropped Reasong",
        field: "invalid_locations",
        width: "250",
        valueGetter: function (params) {
          try {
            if (
              params.data.invalid_location !== undefined &&
              params.data.invalid_location !== ""
            ) {
              if (params.data.invalid_location == 1) {
                return "Dealer Location Data Not In System";
              } else if (params.data.invalid_location == 2) {
                return "Delivery Date is less then Current Date";
              }
            } else {
              return "Missing Dealer Information";
            }
          } catch (error) {}
        },
      },
      {
        headerName: t('delivery_date'),
        headerTooltip: "Delivery Date",
        field: "delivery_date",
        width: "120",
      },
    ];
    
    if (material_configuration_chk == 1) {
      loadsDroppedcolsDefs.push(
        {
          headerName: t('material'),
          headerTooltip: "Material",
          field: "material_code",
          width: "250",
        },
        {
          headerName: t('invalid_material'),
          headerTooltip: "Invalid Material",
          field: "invalid_material",
          width: "250",
        }
      );
    } else {
    }
    var data_type = this.state.data_type;
    var urlType = "outbound";
    if (data_type == 1 || data_type == "1") {
      urlType = "inbound";
    }
    let availableVehiclesGpsData = [];
    let availableVehiclesNodeData = [];
    if (this.state.showAdditionalPlannedVehicles == 1) {
      availableVehiclesGpsData = this.state.trip_gps_data.filter(
        (item) => item.is_available == 0
      );
      availableVehiclesNodeData = this.state.trip_nodes_data.filter(
        (item) => item.is_available == 0
      );
    } else {
      availableVehiclesGpsData = this.state.trip_gps_data.filter(
        (item) => item.is_available == 1
      );
      availableVehiclesNodeData = this.state.trip_nodes_data.filter(
        (item) => item.is_available == 1
      );
    }
    // //
    //     let filteredData = [];
    //     let allPlanSummaryData = [];
    //     let data = this.state.plannedData;
    //     data.map((item) => allPlanSummaryData.push(...item.legs_data));
    //     if (this.state.filterPlannedTucksData == 1) {
    //       let customer_code = this.state.filterCustomer.value;
    //       let orderNo = this.state.filterOrder.value;
    //       // console.log("Hello");

    //       if (customer_code != "" && orderNo != "") {
    //         filteredData = data.filter((item) =>
    //           item.legs_data.some(
    //             (drops) =>
    //               drops.drop_node == customer_code && drops.order_number == orderNo
    //           )
    //         );
    //         console.log(filteredData, "new for1");
    //       } else if (customer_code != "") {
    //         filteredData = data.filter((item) =>
    //           item.legs_data.some((drops) => drops.drop_node == customer_code)
    //         );
    //       } else if (orderNo != "") {
    //         filteredData = data.filter((item) =>
    //           item.legs_data.some((drops) => drops.order_number == orderNo)
    //         );
    //       }
    //     } else {
    //       filteredData = this.state.plannedData;
    //     }

    //
    // var hasFlagOne = this.state.plannedData.some(function(item) {
    //   return item.route_finalized === 1;
    // });

    var { gpsVehiclesSelectedData } = this.state;
    console.log(this.state.inputDetails, "filteredData");

    return (
      <>
        <div className={'lodr ' + (this.state.showLoader)}>
          <div className="loaderdiv">
            <div className="loaderwave"></div>
            <div className="loaderwave"></div>
            <div className="loaderwave"></div>
            <div className="loaderwave"></div>
            <div className="loaderwave"></div>
            <div className="loaderwave"></div>
            <div className="loaderwave"></div>
            <div className="loaderwave"></div>
            <div className="loaderwave"></div>
            <div className="loaderwave"></div>
          </div>
          <div className="ldrtext">{t('loading....')}</div>
        </div>
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          onConfirm={this.closeAlert}
        ></SweetAlert>

        <div class="row mb-20p pt-20p">
          <div className="col-sm-12 d-flex">
            <div
              className={
                this.state.showAvailablityTable
                  ? "col-sm-7 "
                  : "col-sm-12 " + "  d-inline"
              }
            >
              <h5 className="fbold d-flex justify-content-between">
                <div>
                  <div className="row">
                    <div className="col-md-12 col-sm-12">
                      <ul className="breadcrumb">
                        <li>
                          <a href={"/dipatchplanhistory?outbound"}>
                            {t('summary_dashboard')}
                          </a>
                        </li>
                        <li className="dispatch-heading">{t('dispatch_plan_details')}</li>
                      </ul>
                    </div>
                  </div>
                  <div className="row">
                    {/* Dispatch Plan Detailsstyle={{ color: '#16323b', fontSize: '18px', fontWeight: 'normal' } */}
                    <div className="col-sm-12
                     summary-heading" >
                      {this.state.locationNameInHeader}{" "}
                      {this.state.transactionId} {this.state.run_type} {this.state.is_reload == '' ? '' : ' - ' + this.state.is_reload} - {t('min_vfr')}({this.state.minVFR}%)
                    </div>
                  </div>
                </div>
                <div className="float-right">
                  <a
                    href={"/dipatchplanhistory?" + urlType}
                    className="btn btn-danger mr-10p mb-10p"
                    onClick={this.onClickBack}
                  >
                    {t('back')}
                  </a>
                </div>
              </h5>

              <div className="row mt-20p mb-20p">
                <div className="col-md-12 col-sm-12">
                  <ul className="d-tabs">
                    <li
                      onClick={this.onClickTab.bind(this, "inputInfo")}
                      className={"lttabs " + this.state.inputInfo}
                    >
                      <button type="button" className="btn">
                        {t('view_order_information')}
                      </button>
                    </li>
                    <li
                      onClick={this.onClickTab.bind(this, "selectedTrucks")}
                      className={"lttabs " + this.state.selectedTrucks}
                    >
                      <button type="button" className="btn">
                        {t('vehicle_configuration')}
                      </button>
                    </li>

                    <li
                      onClick={this.onClickTab.bind(this, "summary")}
                      className={"lttabs " + this.state.summary}
                    >
                      <button type="button" className="btn">
                        {t('summary')}
                      </button>
                    </li>
                    {/* <li
                      onClick={this.onClickTab.bind(this, "vehicleUtilization")}
                      className={"lttabs " + this.state.vehicleUtilization}
                    >
                      <button type="button" className="btn">
                        Vehicle Utilization
                      </button>
                    </li> */}

                    {/* <li onClick={this.onClickTab.bind(this,"config")} className={"lttabs "+(this.state.config)}>
                                        <button type="button" className="btn">Configurations</button>
                                    </li> */}

                    <li
                      onClick={this.onClickTab.bind(this, "plannedTrucks")}
                      className={"lttabs " + this.state.plannedTrucks}
                    >
                      <button type="button" className="btn">
                        {t('planned_summary')}
                      </button>
                    </li>
                    <li
                      onClick={this.onClickTab.bind(this, "planSummary")}
                      className={"lttabs " + this.state.planSummary}
                    >
                      <button type="button" className="btn">
                        {t('planned_vehicles')}
                      </button>
                    </li>

                    <li
                      onClick={this.onClickTab.bind(this, "unPlannedTrucks")}
                      className={"lttabs " + this.state.unPlannedTrucks}
                    >
                      <button type="button" className="btn">
                        {t('unplanned_loads')}
                      </button>
                    </li>

                    <li
                      onClick={this.onClickTab.bind(this, "loadsDropped")}
                      className={"lttabs " + this.state.loadsDropped}
                    >
                      <button type="button" className="btn">
                        {t('invalid_drop_data')}
                      </button>
                    </li>
                    <li
                      onClick={this.onClickTab.bind(this, "vehicleMetrics")}
                      className={"lttabs " + this.state.vehicleMetrics}
                    >
                      <button type="button" className="btn">
                        {t('vehicle_utilization_km')}
                      </button>
                    </li>
                  </ul>

                  {/* <h5 className={"float-right bold-text"}>
                    Plan Executed On : {this.state.created_on}
                  </h5> */}
                </div>
              </div>
              {/* <Counters context={this} /> */}

              <div className={"row mt-20p " + this.state.configData}>
                {this.state.vehicle_type_config.length > 0 ? (
                  <div className="col-md-6 col-sm-6">
                    <div className="col-md-12 col-sm-12">
                      <h5 className="summaryDetailsTitle">
                        {t('vehicle_type_configurations')}
                      </h5>
                    </div>
                    <div
                      id="myGrid"
                      style={{ width: "100%", height: "40vh" }}
                      className="col-sm-12 ag-theme-balham dropdown"
                    >
                      {/* {console.log("hit")} */}
                      <AgGridReact
                        // modules={this.state.modules}
                        rowData={this.state.vehicle_type_config}
                        columnDefs={vehicleTypecolsDefs}
                        gridOptions={{ context: { componentParent: this } }}
                        defaultColDef={this.state.defaultColDef}
                        frameworkComponents={this.state.frameworkComponents}
                        paginationPageSize={this.state.paginationPageSize}
                        pagination={true}
                        enableRangeSelection={true}
                        sideBar={this.state.sideBar}
                        enablePivot={true}
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {this.state.material_configuration.length > 0 ? (
                  <div className="col-md-6 col-sm-6">
                    <div className="col-md-12 col-sm-12">
                      <h5 className="summaryDetailsTitle">
                        {t('material_configurations')}
                      </h5>
                    </div>
                    <div
                      id="myGrid"
                      style={{ width: "100%", height: "40vh" }}
                      className="col-sm-12 ag-theme-balham dropdown"
                    >
                      <AgGridReact
                        // modules={this.state.modules}
                        rowData={this.state.material_configuration}
                        columnDefs={materialConfigcolsDefs}
                        gridOptions={{ context: { componentParent: this } }}
                        defaultColDef={this.state.defaultColDef}
                        frameworkComponents={this.state.frameworkComponents}
                        paginationPageSize={this.state.paginationPageSize}
                        pagination={true}
                        enableRangeSelection={true}
                        sideBar={this.state.sideBar}
                        enablePivot={true}
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {this.state.freight_configuration.length > 0 ? (
                  <div className="col-md-6 col-sm-6">
                    <div className="col-md-12 col-sm-12 mt-20p">
                      <h5 className="summaryDetailsTitle">
                        {t('freight_configurations')}
                      </h5>
                    </div>
                    <div
                      id="myGrid"
                      style={{ width: "100%", height: "40vh" }}
                      className="col-sm-12 ag-theme-balham dropdown"
                    >
                      <AgGridReact
                        // modules={this.state.modules}
                        rowData={this.state.freight_configuration}
                        columnDefs={freightConfigcolsDefs}
                        gridOptions={{ context: { componentParent: this } }}
                        defaultColDef={this.state.defaultColDef}
                        frameworkComponents={this.state.frameworkComponents}
                        paginationPageSize={this.state.paginationPageSize}
                        pagination={true}
                        enableRangeSelection={true}
                        sideBar={this.state.sideBar}
                        enablePivot={true}
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {this.state.vehicle_availability_config.length > 0 ? (
                  <div className="col-md-6 col-sm-6">
                    <div className="col-md-12 col-sm-12 mt-20p">
                      <h5 className="summaryDetailsTitle">
                        {t('vehicle_availability_configurations')}
                      </h5>
                    </div>
                    <div
                      id="myGrid"
                      style={{ width: "100%", height: "40vh" }}
                      className="col-sm-12 ag-theme-balham dropdown"
                    >
                      <AgGridReact
                        rowData={this.state.vehicle_availability_config}
                        columnDefs={vehicleAvailabilityConfigcolsDefs}
                        gridOptions={{ context: { componentParent: this } }}
                        defaultColDef={this.state.defaultColDef}
                        frameworkComponents={this.state.frameworkComponents}
                        paginationPageSize={this.state.paginationPageSize}
                        pagination={true}
                        enableRangeSelection={true}
                        sideBar={this.state.sideBar}
                        enablePivot={true}
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              {/* Selected Trucks */}
              <div className={"row mt-20p " + this.state.selectedVehicle}>
                <div
                  id="myGrid1"
                  style={{ width: "100%", height: "70vh" }}
                  className="col-sm-12 ag-theme-balham dropdown"
                >
                  <AgGridReact
                    // modules={this.state.modules}
                    rowData={this.state.vehiclesSelectedData}
                    columnDefs={vehiclesSelectedDataDefs}
                    gridOptions={{ context: { componentParent: this } }}
                    defaultColDef={this.state.defaultColDef}
                    frameworkComponents={this.state.frameworkComponents}
                    paginationPageSize={this.state.paginationPageSize}
                    pagination={true}
                    enableRangeSelection={true}
                    sideBar={this.state.sideBar}
                    enablePivot={true}
                  />
                </div>
              </div>
              <div className={"row mt-20p " + this.state.plannedTrucksData}>
                {/* <button
                  type="button"
                  onClick={this.onClickShowRoutesData.bind(this)}
                  className="btn btn-success ml-10p"
                >
                  View Planned Available Vehicle Routes
                </button>
                <button
                  type="button"
                  onClick={this.onClickShowReloadedRoutesData.bind(this)}
                  className="btn btn-success ml-10p"
                >
                  View Planned Additional Vehicle Routes
                </button> */}
                {/* {hasFlagOne ?
                <button
                  type="button"
                  onClick={this.onClickRevokeAssignVehicle}
                  className="btn btn-danger ml-10p float-right"
                >
                  <i className="fa fa-times"></i> Revoke All Assignments
                </button>
                :''} */}
                <button
                  type="button"
                  onClick={this.onClickDownloadPlannedData}
                  className="btn btn-danger ml-10p float-right"
                >
                  <i className="fa fa-download"></i> {t('planned_data')}
                </button>
                {/* <div className="col-sm-12 mb-3">
                  <div className="card">
                    <div className="card-header">
                      <div className="row">
                        <div className="col-sm-2 mb-20p">
                          <label>Order Number</label>
                          <Select
                            value={this.state.filterOrder}
                            name="filterMode"
                            onChange={this.onChangeFilterOrder}
                            options={this.state.allOrdersList}
                          />
                        </div>

                        <div className="col-sm-2">
                          <label>Customer</label>
                          <Select
                            value={this.state.filterCustomer}
                            name="filterCustomer"
                            onChange={this.onChangeFilterCustomer}
                            options={this.state.allCustomersList}
                          />
                        </div>

                        <div className="col-sm-2">
                          <button
                            type="button"
                            className="btn btn-success mt-30p"
                            onClick={this.onClickFilterData}
                          >
                            Get Data
                          </button>
                          <button
                            type="button"
                            className="btn btn-warning mt-30p "
                            onClick={this.onClickResetPlannedVehicleData}
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className=" mt-20p mb-3">
                  {/* col-sm-12 container-for-planned beffect */}
                  <div className="col-xl-12 col-lg-12 col-sm-12 beffect ">
                    <div className="card">
                      <div className="card-body">
                        <div className="crm-numbers pb-0">
                          <div className="row">
                            <div className="col cursorPointer">
                              <span className="f13">
                                {/* <FontAwesomeIcon icon={faUser} style={{ fontSize: '24px', color: 'darkgreen' }} /> */}
                                <i className="fa fa-user f24 darkgreen" style={{ fontSize: '24px' }}></i>
                                <br /> {t('total_planned_customers')}
                              </span>
                              <h4 className="darkgreen f30">
                                <span className="counter">
                                  {this.state.total_plan_orders}
                                </span>
                              </h4>
                            </div>

                            <div className="col cursorPointer">
                              <span className="f13">
                                {/* <FontAwesomeIcon icon={faRoad} style={{ fontSize: '24px', color: 'darkgreen' }} /> */}
                                <i className="fa fa-road  f24 txt-primary" style={{ fontSize: '24px' }}></i>
                                <br /> {t('total_round_trip_distance_km')}
                              </span>
                              <h4 className=" txt-primary f30">
                                <span className="counter">
                                  {this.state.total_round_distance}
                                </span>
                              </h4>
                            </div>
                            <div className="col cursorPointer">
                              <span className="f13">
                                {/* <FontAwesomeIcon icon={faPercentage} style={{ fontSize: '24px', color: 'darkgreen' }}❄ /> */}
                                <i className="fa fa-calendar-check-o darkgreen" aria-hidden="true" style={{ fontSize: '24px' }}></i>
                                <br /> {t('planning')} %
                              </span>
                              <h4 className=" darkgreen f30">
                                <span className="counter">
                                  {this.state.planning_percentage}
                                </span>
                              </h4>
                            </div>
                            <div className="col cursorPointer">
                              <span className="f13">
                                {/* <FontAwesomeIcon icon={faPercentage} style={{ fontSize: '24px', color: 'darkgreen' }}❄ /> */}
                                <i className="fa fa-snowflake darkgreen" aria-hidden="true" style={{ fontSize: '24px' }}>❄</i>
                                <br /> {t('cool_chain_adherence')} %
                              </span>
                              <h4 className=" darkgreen f30">
                                <span className="counter">
                                  {/* {((this.state.total_planned_quantity/this.state.total_given_quantity)*100).toFixed(2)} */}
                                  100.00
                                </span>
                              </h4>
                            </div>
                            <div className="col cursorPointer">
                              <span className="f13">
                                {/* <FontAwesomeIcon icon={faPercentage} style={{ fontSize: '24px', color: 'darkgreen' }} /> */}
                                <i className="fa fa-cube  txt-success" style={{ fontSize: '24px' }}></i>
                                <br /> {t('vfr')}  %
                              </span>
                              <h4 className=" txt-success f30">
                                <span className="counter">
                                  {this.state.VFR_percentage}
                                </span>
                              </h4>
                            </div>
                            <div className="col cursorPointer">
                              <span className="f13">
                                {/* <FontAwesomeIcon icon={faClock} style={{ fontSize: '24px', color: 'darkgreen' }} /> */}
                                <i className="fa fa-clock-o" style={{ fontSize: '24px', color: ' red' }}></i>
                                <br /> {t('total_transit_time')}
                              </span>
                              <h4 className=" txt-danger f30">
                                <span className="counter">
                                  {this.state.total_transit_time}
                                </span>
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <div
                  id="myGrid"
                  style={{ width: "100%", height: "70vh" }}
                  className="col-sm-12 ag-theme-balham dropdown mt-3"
                >
                  <AgGridReact
                    rowData={this.state.plan_summary_records}
                    columnDefs={plannedcolsDefs}
                    gridOptions={{ context: { componentParent: this } }}
                    // onGridReady={this.onGridReady}
                    onGridState={this.onGridState}
                    statusBar={this.state.statusBar}
                    sideBar={this.state.sideBar}
                    enablePivot={true}
                    defaultColDef={this.state.defaultColDef}
                    frameworkComponents={this.state.frameworkComponents}
                    paginationPageSize={this.state.paginationPageSize}
                    pagination={true}
                    enableRangeSelection={true}
                    // onCellClicked={this.onCellClickedGrid.bind(this)}
                    masterDetail={true}
                    detailCellRendererParams={
                      this.state.detailCellRendererParams
                    }
                    rowClassRules={this.state.rowClassRules}
                  />
                </div>
              </div>

              {/*Unplanned Trucks Data */}
              <div className={"row mt-20p " + this.state.unPlannedTrucksData}>
                {/* <button
                  type="button"
                  onClick={this.onClickShowLocationData.bind(this)}
                  className="btn btn-danger ml-10p"
                >
                  View Locations
                </button> */}
                <div
                  id="myGrid"
                  style={{ width: "100%", height: "70vh" }}
                  className="col-sm-12 ag-theme-balham dropdown"
                >
                  <AgGridReact
                    // modules={this.state.modules}
                    rowData={this.state.un_planned_records}
                    columnDefs={unPlannedcolsDefs}
                    gridOptions={{ context: { componentParent: this } }}
                    defaultColDef={this.state.defaultColDef}
                    frameworkComponents={this.state.frameworkComponents}
                    paginationPageSize={this.state.paginationPageSize}
                    pagination={true}
                    sideBar={this.state.sideBar}
                    enablePivot={true}
                    enableRangeSelection={true}
                    statusBar={this.state.statusBar}
                  />
                </div>
              </div>

              {/* Load Dropped Data */}
              <div className={"row mt-20p " + this.state.loadsDroppedData}>
                <div
                  id="myGrid"
                  style={{ width: "100%", height: "70vh" }}
                  className="col-sm-12 ag-theme-balham dropdown"
                >
                  <AgGridReact
                    // modules={this.state.modules}
                    rowData={this.state.load_dropped_details}
                    columnDefs={loadsDroppedcolsDefs}
                    gridOptions={{ context: { componentParent: this } }}
                    defaultColDef={this.state.defaultColDef}
                    frameworkComponents={this.state.frameworkComponents}
                    paginationPageSize={this.state.paginationPageSize}
                    pagination={true}
                    enableRangeSelection={true}
                    sideBar={this.state.sideBar}
                    enablePivot={true}
                    statusBar={this.state.statusBar}
                  />
                </div>
              </div>
              <div className={"row mt-20p " + this.state.vehicleMetricsData}>
                <div
                  id="myGrid"
                  style={{ width: "100%", height: "70vh" }}
                  className="col-sm-12 ag-theme-balham dropdown"
                >
                  <AgGridReact
                    // modules={this.state.modules}
                    rowData={this.state.forecasting_vehicles}
                    columnDefs={forecastingVehiclescolsDefs}
                    gridOptions={{ context: { componentParent: this } }}
                    defaultColDef={this.state.defaultColDef}
                    frameworkComponents={this.state.frameworkComponents}
                    paginationPageSize={this.state.paginationPageSize}
                    pagination={true}
                    enableRangeSelection={true}
                    sideBar={this.state.sideBar}
                    enablePivot={true}
                    statusBar={this.state.statusBar}
                  />
                </div>
              </div>
              {/* View Drop Locations */}
              <div className={"row mt-20p " + this.state.inputInfoData}>
                <div
                  id="myGrid"
                  style={{ width: "100%", height: "70vh" }}
                  className="col-sm-12 ag-theme-balham dropdown"
                >
                  <div style={{ fontSize: "12px", color: "#ff0000", textAlign: 'right' }}>{t('note')}: &nbsp; {t('invalid_orders_not_shown')}</div>
                  <AgGridReact
                    // modules={this.state.modules}
                    rowData={this.state.inputDetails}
                    columnDefs={inputDetailscolsDefs}
                    gridOptions={{ context: { componentParent: this } }}
                    defaultColDef={this.state.defaultColDef}
                    frameworkComponents={this.state.frameworkComponents}
                    paginationPageSize={this.state.paginationPageSize}
                    pagination={true}
                    enableRangeSelection={true}
                    sideBar={this.state.sideBar}
                    enablePivot={true}
                    statusBar={this.state.statusBar}
                  />
                </div>
              </div>

              {/* plan Summary Data */}
              <div className={"row mt-20p " + this.state.planSummaryData}>
                <div
                  id="myGrid"
                  style={{ width: "100%", height: "70vh" }}
                  className="col-sm-12 ag-theme-balham dropdown"
                >
                  <AgGridReact
                    // modules={this.state.modules}
                    rowData={this.state.plannedRecords}
                    columnDefs={planSummarycolsDefs}
                    gridOptions={{ context: { componentParent: this } }}
                    defaultColDef={this.state.defaultColDef}
                    frameworkComponents={this.state.frameworkComponents}
                    paginationPageSize={this.state.paginationPageSize}
                    pagination={true}
                    sideBar={this.state.sideBar}
                    enablePivot={true}
                    enableRangeSelection={true}
                    statusBar={this.state.statusBar}
                  />
                </div>
              </div>

              <div className={"mb-20p" + this.state.summaryData} style={{ width: "100%" }}>
                {(this.state.kpi_summary_loaded && Object.keys(this.state.kpi_summary).length !== 0) && (
                  <>
                    <div className="d-flex flex-row" style={{width: "100%"}}>
                      <div className="mb-20p" style={{ width: "25%", marginLeft: "5px", marginRight: "5px" }}>
                        <NewColumnChart
                          data={this.state.kpi_summary.total_trips}
                          title= {t('total_trips')}
                          xAxisTitle="Vehicle Type"
                          yAxisTitle={t("number_of_trips")}
                        />
                      </div>
                      <div className="mb-20p" style={{ width: "25%", marginLeft: "5px", marginRight: "5px" }}>
                        <NewColumnChart
                          data={this.state.kpi_summary.km_run}
                          title= {t('avg_kms_travelled')}
                          xAxisTitle="Vehicle Type"
                          yAxisTitle={t("Number of kms Travelled")}
                        />
                      </div>
                      <div className="" style={{ width: "25%", marginLeft: "5px", marginRight: "5px" }}>
                        <NewColumnChart
                          data={this.state.kpi_summary.cost}
                          title= {t('total_cost')}
                          xAxisTitle="Vehicle Type"
                          yAxisTitle={t("Cost")}
                        />
                      </div>
                      <div className="" style={{ width: "25%", marginLeft: "5px", marginRight: "5px" }}>
                        <NewColumnChart
                          data={this.state.kpi_summary.vehicle_fill_rate}
                          title= {t('total_vehicle_fill_rate')}
                          xAxisTitle="Vehicle Type"
                          yAxisTitle={t("Vehicle Fill Rate")}
                        />
                      </div>
                    </div>
                    <div className="d-flex flex-row align-items-start" style={{width: "100%"}}>
                    <div className="" style={{ width: "24.5%", marginLeft: "5px", marginRight: "5px" }} >
                      <NewColumnChart
                        data={this.state.kpi_summary.percent_km_run_sob}
                        title={t('percent_km_run_sob')}
                        xAxisTitle="Vehicle Type"
                        yAxisTitle={t("Vehicle KM Run %")}
                      />
                    </div>
                    <div className="d-flex flex-row" style={{width: "75%"}}>
                      <div className="bg-primary" style={{ width: "25%", borderRadius: "10px", backgroundColor: "#fff", padding: "20px", marginLeft: "5px", marginRight: "10px" }}>
                        <p style={{ fontWeight: "bold", fontSize: "20px", textAlign: "center" }}>{t('unplanned')}</p>
                        <p style={{ fontWeight: "bold", fontSize: "17px", margin: "0px", marginBottom: "10px" }}>{t('quantity')} : {this.state.kpi_summary.unplanned.quantity}</p>
                        <p style={{ fontWeight: "bold", fontSize: "17px", margin: "0px", marginBottom: "10px" }}>{t('weight')} : {this.state.kpi_summary.unplanned.weight}</p>
                        <p style={{ fontWeight: "bold", fontSize: "17px", margin: "0px", marginBottom: "10px" }}>{t('volume')} : {this.state.kpi_summary.unplanned.volume}</p>
                      </div>
                      <div className="bg-warning" style={{ width: "25%", borderRadius: "10px", backgroundColor: "#fff", padding: "20px", marginRight: "10px" }}>
                        <p style={{ fontWeight: "bold", fontSize: "20px", textAlign: "center" }}>{t('percent_unplanned')}</p>
                        <p style={{ fontWeight: "bold", fontSize: "17px", margin: "0px", marginBottom: "10px" }}>{t('quantity')} : {this.state.kpi_summary.percent_unplanned.quantity}%</p>
                        <p style={{ fontWeight: "bold", fontSize: "17px", margin: "0px", marginBottom: "10px" }}>{t('weight')} : {this.state.kpi_summary.percent_unplanned.weight}%</p>
                        <p style={{ fontWeight: "bold", fontSize: "17px", margin: "0px", marginBottom: "10px" }}>{t('volume')} : {this.state.kpi_summary.percent_unplanned.volume}%</p>
                      </div>
                      <div className="bg-info" style={{ width: "25%", borderRadius: "10px", backgroundColor: "#fff", padding: "20px", marginRight: "10px" }}>
                        <p style={{ fontWeight: "bold", fontSize: "20px", textAlign: "center" }}>{t('percent_ontime_dispatch')}</p>
                        <p style={{ fontWeight: "bold", fontSize: "17px", margin: "0px", marginBottom: "10px" }}>{t('local')} : {this.state.kpi_summary.percent_ontime_dispatch.local}%</p>
                        <p style={{ fontWeight: "bold", fontSize: "17px", margin: "0px", marginBottom: "10px" }}>{t('upcountry')} : {this.state.kpi_summary.percent_ontime_dispatch.upcountry}%</p>
                      </div>
                      <div className="bg-success" style={{ width: "25%", borderRadius: "10px", backgroundColor: "#fff", padding: "20px", marginRight: "10px" }}>
                        <p style={{ fontWeight: "bold", fontSize: "20px", textAlign: "center" }}>{t('percent_spot_vehicles_trips')}</p>
                        <p style={{ fontWeight: "bold", fontSize: "20px", marginTop: "10px", marginBottom: "10px", textAlign: "center" }}>{this.state.kpi_summary.percent_spot_vehicles_trips}%</p>
                      </div>
                      </div>
                      </div>
                  </>
                )}
              </div>
              <div className={"row mt-20p " + this.state.vehicleUtilizationData}>
                <div
                  className="col-sm-12"
                >
                  <HeatmapChart data={this.state.vehicle_utilization_matrix} />
                </div>
              </div>
              <div
                className={"sliderBlock2 " + this.state.showSlideBlockUpload}
              >
                <h5 className="crd-bg p-10p">{this.state.sliderTitle}</h5>
                <div className="row">
                  <div className="col-sm-12">
                    {this.state.latLngArray.length > 0 ? (
                      <LoadSummaryDrawmap
                        context={this}
                        coords={this.state.latLngArray}
                        loadingLocation={this.state.loadingLocation}
                        stepcoords={1}
                        mapType={this.state.mapType}
                        mapFor={"load_management"}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className={"overlay-block " + this.state.overlayBlock}
            onClick={this.hideSlideBlock}
          ></div>
          <div className={"sliderBlock2 " + this.state.showSlideMap}>
            <h5 className="crd-bg p-10p">
              {t('view_routes')}
              <span className="float-right f13 ml-30p">
                {t('round_trip_distance')}:{" "}
                {this.state.tripinfo.length > 0
                  ? this.state.tripinfo[0].round_trip_distance
                  : ""}{" "}
                Kms
              </span>
              <span className="float-right f13">
                {t('total_trip_distance')}:{" "}
                {this.state.tripinfo.length > 0
                  ? this.state.tripinfo[0].distance
                  : ""}{" "}
                Kms
              </span>
            </h5>
            <div className="row">
              <div className="col-sm-12">
                {this.state.trip_gps_data.length > 0 ? (
                  <DrawMapDisptach
                    context={this}
                    plant_loc_data={this.state.plant_loc_data}
                    trip_gps_data={availableVehiclesGpsData}
                    trip_nodes_data={availableVehiclesNodeData}
                    palletLoadLocations={this.state.unPlannedData}
                    viewtype={this.state.viewcount}
                    map_for={this.state.mapFor}
                  />
                ) : (
                  ""
                )}
                {/* <div id="mapRoute" className="loadSummaryMap" style={{width:'100%',height:"85vh"}}></div> */}
              </div>
            </div>
          </div>
          <div className={"sliderBlock2 " + this.state.showSlideMapLocations}>
            <h5 className="crd-bg p-10p">{t('view_locations')}</h5>
            <div className="row">
              <div className="col-sm-12">
                {this.state.palletLoadLocations.length > 0 ? (
                  <DrawMapDisptachPallet
                    context={this}
                    palletLoadLocations={this.state.palletLoadLocations}
                    viewtype={this.state.viewcount}
                  />
                ) : (
                  ""
                )}
                {/* <div id="mapRoute" className="loadSummaryMap" style={{width:'100%',height:"85vh"}}></div> */}
              </div>
            </div>
          </div>

          <div
            className={"sliderBlock2 " + this.state.showSlideAssignVehicle}
            style={{ overflowX: "hidden" }}
          >
            <h5 className="crd-bg p-10p">{t('assign_vehicle')}</h5>
            <div className="row p-15p">
              <div className="col-sm-12">
                <div className="form-group">
                  <label>{t('select_vehicle')}</label>
                  <Select
                    value={this.state.assignvehicle}
                    onChange={this.onChangeAssignVehicle.bind(this)}
                    options={this.state.assign_vehicle_list}
                  />
                </div>
              </div>
              <div className={"col-sm-12 " + this.state.showAssignFields}>
                <div className="form-group">
                  <label>{t('enter_vehicle_no')}</label>
                  <input
                    type="text"
                    name="other_vehicle_no"
                    className="form-control"
                    value={this.state.other_vehicle_no}
                    onChange={this.changeHandler.bind(this)}
                  />
                </div>
              </div>
              <div className="col-sm-12">
                <div className="form-group">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={this.onClickAssignSelectedVehicle.bind(this)}
                  >
                    {t('assign_vehicle')}
                  </button>
                </div>
              </div>
              <div className={"chartLoading " + this.state.assignVehicleloader}>
                <div className="loaderpos">
                  <img src={require("../../assets/images/loading.gif")} />
                </div>
              </div>
            </div>
          </div>

          <Modal
            closeOnOverlayClick={false}
            open={this.state.openReassignVehicleModel}
            onClose={this.handelModelCancel}
            classNames={{ modal: "vehiclepriority-modelClass handle-postion" }}
          >
            <div className="col-sm-12">
              <div className="mt-20p mb-10p">
                <h6>
                  {t('vehicle_has_active_trip_shipment_id')}-
                  {this.state.selectedShipmentId}. <br />
                  {t('do_you_wish_to')} <b>{t('force_close_assign')}</b> ?
                </h6>
              </div>

              <div className="col-md-4">
                <div className="form-group ">
                  <label
                    htmlFor="forceClose_reason"
                    className="col-form-label f12"
                  >
                    {t('force_close_reason')}
                  </label>

                  <select
                    name="forceClose_reason"
                    id="forceClose_reason"
                    value={this.state.forceClose_reason}
                    required
                    className="forminp form-control"
                    onChange={this.forceCloseInputField}
                  >
                    {/* <option value="" disabled selected>Select</option> */}
                    <option value="gps_issue">{t('gps_issue')}</option>
                    <option value="trip_closed">{t('trip_closed')}</option>
                    <option value="load_cancelled">{t('load_cancelled')}</option>
                    <option value="new_trip_allocated">
                      {t('new_trip_allocated')}
                    </option>
                    <option value="driver_changed">{t('driver_changed')}</option>
                  </select>
                </div>
              </div>

              <div className="btns-center">
                <button
                  type="button"
                  onClick={this.forceCloseAndReassign}
                  className="btn tbtn assign-btn"
                >
                  {t('assign_vehicle')}
                </button>
                <button
                  type="button"
                  className="btn tbtn cancel-btn ass-cancel-btn"
                  onClick={this.handelModelCancel}
                >
                  {t('cancel')}
                </button>
              </div>
              <div className={"chartLoading " + this.state.assignVehicleloader}>
                <div className="loaderpos">
                  <img src={require("../../assets/images/loading.gif")} />
                </div>
              </div>
            </div>
          </Modal>

          <Modal
            closeOnOverlayClick={false}
            open={this.state.openUpdateVehicleModel}
            onClose={this.handelUpdateModelCancel}
            classNames={{ modal: "vehiclepriority-modelClass handle-postion" }}
          >
            <div className="col-sm-12">
              <div className="mt-20p mb-10p">
                <h6>
                  {t('are_you_sure_you_want_to_update')} <b>{t('vehicle_number')}</b> ?
                </h6>
              </div>

              <div className="btns-center">
                <button
                  type="button"
                  onClick={this.handleUpdateVehicle}
                  className="btn tbtn assign-btn"
                >
                  {t('yes')}
                </button>
                <button
                  type="button"
                  className="btn tbtn cancel-btn ass-cancel-btn"
                  onClick={this.handelUpdateModelCancel}
                >
                  {t('no')}
                </button>
              </div>
            </div>
          </Modal>
          <div className={"loader " + this.state.loadshow}></div>
        </div>
      </>
    );
  }
}

export default withTranslation()(ViewForecastDispatchPlanDetails)
function groupBy(list, keyGetter) {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

function loadScript(url) {
  var index = window.document.getElementsByTagName("script")[0];
  var script = window.document.createElement("script");
  script.src = url;
  script.async = true;
  script.defer = true;
  index.parentNode.insertBefore(script, index);
}

function GetSortAscOrder(prop) {
  return function (a, b) {
    if (a[prop] > b[prop]) {
      return 1;
    } else if (a[prop] < b[prop]) {
      return -1;
    }
    return 0;
  };
}

function arr_diff(a1, a2) {
  var a = [],
    diff = [];

  for (var i = 0; i < a1.length; i++) {
    a[a1[i]] = true;
  }

  for (var i = 0; i < a2.length; i++) {
    if (a[a2[i]]) {
      delete a[a2[i]];
    } else {
      a[a2[i]] = true;
    }
  }

  for (var k in a) {
    diff.push(k);
  }

  return diff;
}

function addTimes(timeArray) {
  let totalSeconds = 0;

  for (let i = 0; i < timeArray.length; i++) {
    const [hours, minutes, seconds] = timeArray[i].split(":").map(Number);
    if (seconds != undefined && seconds != "") {
      totalSeconds += hours * 3600 + minutes * 60 + seconds;
    } else {
      totalSeconds += hours * 3600 + minutes * 60;
    }
  }

  let hours = Math.floor(totalSeconds / 3600);
  let minutes = Math.floor((totalSeconds % 3600) / 60);
  let seconds = totalSeconds % 60;

  return `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
}
