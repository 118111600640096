
import React, { Component } from 'react';
import { FaRoute } from "react-icons/fa";


const RouteButton = (props) => {

    const handleOnClick = () => {
        props.colDef.params.onClickFunction(props, true);
    };
    return (
        <div>

            <button className={" grid-btn"} onClick={handleOnClick} title="View Route" style={{ border: "0px", backgroundColor: '#f7f7f7' }}>
                <FaRoute size={16} color={"#4a4a4a"}/>
            </button>

        </div>
    );
};

export default RouteButton;
