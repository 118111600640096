import React from "react";
import { MdEmail } from "react-icons/md";

const EmailBtn = (props) => {
  const handleOnClick = () => {
    props.colDef.params.onClickFunction(props, true);
  };

  return (
    <div>
      <button
        type="button"
        className="btn grid-btn"
        style={{ color: "#4a4a4a", boxShadow: "none" }}
        onClick={handleOnClick}
      >
        <MdEmail className="f16" />
      </button>
    </div>
  );
};

export default EmailBtn;
