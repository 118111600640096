import React,{Component} from "react";
import {AllModules} from '@ag-grid-enterprise/all-modules';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
import 'ag-grid-enterprise';
import Constant from "../common/constants";
import { withTranslation } from "react-i18next";
import Modal from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import redirectURL from '../redirectURL';
import Select from 'react-select';
import SweetAlert from 'react-bootstrap-sweetalert';
import GridButton from './gridButton';
import getStackingToolConfiguration from "./commonFunctions";

 class StackingVehicleDetails extends Component{
    constructor(props){
        super(props);
        this.state={
            modules : AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
                resizable:true,
                minWidth:100,
                initialWidth: 200,
                wrapHeaderText: true,
                autoHeaderHeight: true,
            },
            frameworkComponents:{
                GridButton:GridButton,
            },
            rowData:[],
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
			detailCellRendererParams:{},
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:100,
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            animateRows: true,
            openVehicleConfigurationModel:false,
            vehicle_type:'',
            vehicle_name:'',
            vehicle_dimensions_width_mm:'',
            vehicle_dimensions_depth_mm:'',
            vehicle_dimensions_height_mm:'',
            vehicle_max_utilization:'',
            vehicle_max_weight:'',
            show:false,
            basicTitle:"",
            basicType:"default",
            data_type:'',
            location:'',
            locationNames:[],
            vehicle_capacity:'',
            container_id:'',
            vehicle_type_count:''

        }
        
    }

    async componentDidMount(){
        var curl = window.location.search;
        var ul= curl.split("?");
        // console.log("ul ",ul)
        // let url_type = window.location.href
        let url_type = ul[parseInt(ul.length)-1];
        // console.log("url_type ", url_type)
        // let path_type = url_type.split('/')
        let path_type = url_type
        let data_type
        // let path = path_type[path_type.length-1]
        let path =url_type

        if (path_type == 'itemstacking'){
            data_type = 1
            this.setState({data_type : data_type})
        }else{
            data_type = 2
            this.setState({data_type : data_type})
        }
        let params = {
            data_type : data_type
        }
        await getStackingToolConfiguration(params)
        await this.getStackingVehiclesData(params)
        await this.getStackingPlantWarehouseLocationsData(params)
    }

    getStackingVehiclesData = (params) =>{
        redirectURL.post('stacking/getStackingVehiclesData',params)
        .then(response =>{
            if(response.data.status == 'success'){

            this.setState({
                rowData:response.data.vehiclesData
            })
        }
        })
    }

    openModelForVehicleConfiguration = () =>{
        this.setState(prevState =>({
            openVehicleConfigurationModel :!prevState.openVehicleConfigurationModel
        }))
    }


    handelVehicleConfigurationModelCancel = ()=>{
        this.setState(prevState =>({
            openVehicleConfigurationModel :!prevState.openVehicleConfigurationModel,
            vehicle_type:'',
            vehicle_name:'',
            vehicle_dimensions_width_mm:'',
            vehicle_dimensions_depth_mm:'',
            vehicle_dimensions_height_mm:'',
            vehicle_max_utilization:'',
            vehicle_max_weight:'',
            vehicle_capacity:'',
            container_id : '',
            vehicle_type_count:'',
        }))

    }


    closeAlert = async() => {
        await this.setState({
            show: false
        });
	}

    
    vehicleConfigurationInputFields = (e) =>{
        let name = e.target.name
        let value = e.target.value
        this.setState({ [name] : value })
    }

    locationChanged = (location) =>{
        this.setState({
            location : location
        })
    }

    getStackingPlantWarehouseLocationsData = async(param) => {

      let locationNames = this.state.locationNames;
      await redirectURL.post('stacking/getStackingPlantWarehouseLocationsData',param)
        .then(async (response) =>{
            if(response.data.status == 'success'){
                let boundLocations = response.data.plantWarehouseLocations
                if(boundLocations.length > 0){
                    boundLocations.map(item =>{
                        locationNames.push({ value : item.location_code,
                            label : item.location_name })
                    })
            await this.setState({
                        locationNames : locationNames,
                        location : locationNames[0]
                    })
                }
            }
        })
        
    }

    
    saveStackingVehicleConfigurationData = (params)=>{
        let data_type = this.state.data_type
        redirectURL.post('stacking/saveStackingVehicleConfiguration',params)
        .then(response =>{
                    
            if(response.data.status == 'success'){
                let param = {
                    data_type : data_type
                }
                this.getStackingVehiclesData(param)
                this.setState({openVehicleConfigurationModel:false,
                            vehicle_type:'',
                            vehicle_name:'',
                            vehicle_dimensions_width_mm:'',
                            vehicle_dimensions_depth_mm:'',
                            vehicle_dimensions_height_mm:'',
                            vehicle_max_utilization:'',
                            vehicle_max_weight:'',
                            vehicle_capacity:'',
                            vehicle_type_count:'',
                        })
                    }else{
                        this.setState({
                            show:true,
                            basicTitle:"Data Not Saved successfully",
                            basicType:"danger"
                        })
                    }

                })
    }


    formVehicleConfiguration = (e)=>{
        e.preventDefault();
        let vehicle_type = this.state.vehicle_type;
        let vehicle_name = this.state.vehicle_name;
        let vehicle_dimensions_width_mm = this.state.vehicle_dimensions_width_mm;
        let vehicle_dimensions_depth_mm = this.state.vehicle_dimensions_depth_mm;
        let vehicle_dimensions_height_mm = this.state.vehicle_dimensions_height_mm;
        let vehicle_max_utilization = this.state.vehicle_max_utilization;
        let vehicle_max_weight = this.state.vehicle_max_weight;
        let data_type = this.state.data_type;
        let location = this.state.location;
        let vehicle_capacity = this.state.vehicle_capacity;
        let container_id = this.state.container_id;
        let vehicle_type_count = this.state.vehicle_type_count
        let toolConfig = JSON.parse(localStorage.getItem('tlcfg'))

        
  
        let params = {
            vehicle_type : vehicle_type,
            vehicle_name : vehicle_name,
            vehicle_dimensions_width_mm : parseInt(vehicle_dimensions_width_mm),
            vehicle_dimensions_depth_mm : parseInt(vehicle_dimensions_depth_mm),
            vehicle_dimensions_height_mm : parseInt(vehicle_dimensions_height_mm),
            vehicle_max_utilization : parseInt(vehicle_max_utilization),
            vehicle_max_weight : parseInt(vehicle_max_weight),
            data_type : data_type,
            location_code : location.value,
            location_name : location.label,
            vehicle_capacity : parseInt(vehicle_capacity),
            vehicle_type_count : parseInt(vehicle_type_count)
            
        }

        if(container_id != ''){

            params.container_id = container_id
            
                this.updateStackingVehicleConfigurationData(params)
            

        }else{

            if(location.value != null){

                    this.saveStackingVehicleConfigurationData(params)
            }else{
                this.setState({
                    show:true,
                    basicTitle:"Please add "+ Constant.STACKING_VEHICLE_DETAILS_CONFIGURATION_LOCATION + " First",
                    basicType:"danger"
                })
            }


        }

    }


    updateActionStackingVehicleConfigurationData = async(propdata) =>{
        let data = propdata.data

            await this.setState({
                vehicle_type:data.vehicle_type,
                vehicle_name:data.vehicle_name,
                vehicle_dimensions_width_mm:data.vehicle_dimensions_width_mm,
                vehicle_dimensions_depth_mm:data.vehicle_dimensions_depth_mm,
                vehicle_dimensions_height_mm:data.vehicle_dimensions_height_mm,
                vehicle_max_utilization:data.vehicle_max_utilization,
                vehicle_max_weight:data.vehicle_max_weight,
                vehicle_capacity:data.vehicle_capacity,
                vehicle_type_count:data.vehicle_type_count,
                container_id:data.container_id

            })
            this.openModelForVehicleConfiguration()
    
    }

    updateStackingVehicleConfigurationData = (params) =>{
        let data_type = this.state.data_type

        redirectURL.post('stacking/updateStackingVehicleConfigurationData',params)
        .then(response =>{
                            
            if(response.data.status == 'success'){
                let param = {
                    data_type : data_type
                }
                this.getStackingVehiclesData(param)
                this.setState({openVehicleConfigurationModel:false,
                            vehicle_type:'',
                            vehicle_name:'',
                            vehicle_dimensions_width_mm:'',
                            vehicle_dimensions_depth_mm:'',
                            vehicle_dimensions_height_mm:'',
                            vehicle_max_utilization:'',
                            vehicle_max_weight:'',
                            vehicle_capacity:'',
                            container_id:'',
                            vehicle_type_count:''

                        })
                    }else{
                        this.setState({
                            show:true,
                            basicTitle:"Data Not Saved successfully",
                            basicType:"danger"
                        })
                    }

                })
    }

    deleteStackingVehicleDetails = (propData) =>{
        let data = propData.data
        let data_type = this.state.data_type
        
        let params = {
            delete_id : data._id
        }
        redirectURL.post('stacking/deleteStackingVehicleDetails',params)
        .then(response =>{
            if(response.data.status == 'success'){
                let param = {
                    data_type : data_type
                }
                this.getStackingVehiclesData(param)
          }else{
            this.setState({
                show:true,
                basicTitle:"Failed to delete the data",
                basicType:"danger"
            })
          }
        })
    }


    render(){
        const {t} = this.props
        var colsDefs = [

            {
                headerName:"Vehicle Type",
                field:"vehicle_type",
                width:"100"
            },
            {
                headerName:"Vehicle Name",
                field:"vehicle_name",
                width:"100"
            },
            {
                headerName:"No of Vehicles",
                field:"vehicle_type_count",
                width:"80",
                initialWidth:"80",
                editable:true,
            },
            {
                headerName:"Vehicle Width (mm)",
                field:"vehicle_dimensions_width_mm",
                width:"120"
            },
            {
                headerName:"Vehicle Height (mm)",
                field:"vehicle_dimensions_height_mm",
                width:"125"
            },
            {
                headerName:"Vehicle Depth (mm)",
                field:"vehicle_dimensions_depth_mm",
                width:"120"
            },
            {
                headerName:"Vehicle Max Utilization (%)",
                field:"vehicle_max_utilization",
                width:"145"
            },
            {
                headerName:"Vehicle Max Weight (Pounds)",
                field:"vehicle_max_weight",
                width:"145"
            },
            {
                headerName:"Vehicle Capacity (CMT)",
                field:"vehicle_capacity",
                width:"135"
            },
            {
                headerName:"Actions",
                field:"cluster",
                width:"20",
                pinned:'left',
                params:{buttonName: t('edit'),
                        onClickFunction:this.updateActionStackingVehicleConfigurationData},
                cellRendererSelector:function(params){
                    
                    var rendComponent = {
                        component: "GridButton"
                    };
                    return rendComponent
                
                },
            },
            {
                headerName:"",
                field:"cluster",
                width:"80",
                pinned:'left',
                params:{buttonName:t('delete'),iconName:"fa fa-trash",
                        onClickFunction:this.deleteStackingVehicleDetails},
                cellRendererSelector:function(params){
                    
                    var rendComponent = {
                        component: "GridButton"
                    };
                    return rendComponent
                
                },
            },
        ]
        let data = this.state.rowData
        let locValue = this.state.location.value   
        let filteredData = data.filter(item => item.location_code == locValue)

        let vehicleTypeCnt = 4;

        let toolConfig = JSON.parse(localStorage.getItem('tlcfg'))
        if(toolConfig !=undefined && toolConfig !="")
        {
            if(toolConfig.length > 0)
            {
                vehicleTypeCnt = toolConfig[0].max_no_of_trucks;
            }
        }



        return(
            <div className="">
            <SweetAlert
                show={this.state.show}
                type={this.state.basicType}
                title={this.state.basicTitle}
                onConfirm={this.closeAlert}>
            </SweetAlert>
                
                <div class="row mb-20p pt-20p">
                <div className="col-sm-12">                    
                        <h5 className='fbold d-flex justify-content-between'>
                            <span>{Constant.STACKING_VEHICLE_DETAILS_HEADER_NAME}</span>
                            {vehicleTypeCnt > this.state.rowData.length ?
                            <div className='float-right'>
                            <a href="javascript:;" onClick={this.openModelForVehicleConfiguration} className="f18 float-right themefont fbold mr-10p mb-10p">{Constant.STACKING_VEHICLE_DETAILS_BUTTON_NAME}</a>
                            {/* <span className="float-right">
                            <a href="javascript:;"  onClick={this.openUploadOptionForPickupDrop} className="btn btn-warning f12 mr-10p">{Constant.MASTER_VEHICLE_DATA_CONFIGURATION_BUTTON_NAME}</a>
                            </span> */}
                            </div> 
                            : <div className='float-right'>
                            <a href="javascript:;" data-toggle="tooltip" title="Max-Limit Reached" className="f18 float-right themefont fbold mr-10p mb-10p">{Constant.STACKING_VEHICLE_DETAILS_BUTTON_NAME}</a>
                            
                            {/* <span className="float-right">
                            <a href="javascript:;"  onClick={this.openUploadOptionForPickupDrop} className="btn btn-warning f12 mr-10p">{Constant.MASTER_VEHICLE_DATA_CONFIGURATION_BUTTON_NAME}</a>
                            </span> */}
                            </div>}

                        </h5>
                        <div className="col-md-4 col-sm-12">
                            <div className="form-group ">
                                <label className="col-form-label f12">{Constant.STACKING_VEHICLE_DETAILS_CONFIGURATION_LOCATION}</label>
                                <Select
                                    placeholder={"Select"}
                                    onChange={this.locationChanged}
                                    name="location"
                                    value={this.state.location}
                                    options={this.state.locationNames} 
                                    />

                            </div>
                        </div>

                        <div id="myGrid" style={{width:"100%",height:"70vh"}} className="col-sm-12 ag-theme-balham dropdown">
                        <AgGridReact
                        modules={this.state.modules} 
                            rowData={filteredData}
                            columnDefs={colsDefs}
                            gridOptions={{context:{componentParent:this}}}
                            defaultColDef={this.state.defaultColDef}
                            frameworkComponents={this.state.frameworkComponents}
                            paginationPageSize={this.state.paginationPageSize}
                            pagination={true}
                        />
                        </div>
                        
                    </div>
                </div>

                <Modal closeOnOverlayClick={false} open={this.state.openVehicleConfigurationModel} onClose={this.handelVehicleConfigurationModelCancel} 
                    classNames={{modal: 'pickupdrop-modelClass'}} >
                        <div className="col-sm-12">
                    <form className="theme-form" onSubmit={this.formVehicleConfiguration}>

                        <div className="row number">
                        <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{Constant.STACKING_VEHICLE_DETAILS_CONFIGURATION_LOCATION}</label>
                                    <Select
                                        isDisabled
                                        name="location"
                                        value={this.state.location}
                                         />
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{Constant.STACKING_VEHICLE_DETAILS_VEHICLE_TYPE} </label>
                                    <input required type="text" className="forminp form-control" id="vehicle_type" name="vehicle_type" value={this.state.vehicle_type} 
                                    onChange={this.vehicleConfigurationInputFields}
                                     placeholder="" autoComplete="off" />
                                    
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{Constant.STACKING_VEHICLE_DETAILS_VEHICLE_NAME}</label>
                                    <input required type="text" className="forminp form-control" name="vehicle_name" value={this.state.vehicle_name}
                                     onChange={this.vehicleConfigurationInputFields} 
                                      placeholder="" autoComplete="off" />
                                                                          
                                </div>
                            </div>
                            

                            <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{Constant.STACKING_VEHICLE_DETAILS_VEHICLE_DIMENSIONS}</label>
                                    <div className="d-flex justify-content-between number">
                                    <div className="col-md-4 col-sm-4 d-flex control-padding">
                                        <label className="col-form-label f14 d-inline font-bold">W</label>
                                        <input required type="number" min = '0' className="forminp form-control d-inline control-padding-right" id="width" name="vehicle_dimensions_width_mm" value={this.state.vehicle_dimensions_width_mm} 
                                    onChange={this.vehicleConfigurationInputFields}
                                     placeholder="width" autoComplete="off" />
                                    </div>

                                    <div className="col-md-4 col-sm-4 d-flex control-padding">
                                        <label className="col-form-label f14 d-inline font-bold">H</label>
                                        <input required type="number" min = '0' className="forminp form-control d-inline control-padding-right" id="height" name="vehicle_dimensions_height_mm" value={this.state.vehicle_dimensions_height_mm} 
                                    onChange={this.vehicleConfigurationInputFields}
                                     placeholder="height" autoComplete="off" />
                                    </div>

                                    <div className="col-md-3 col-sm-4 d-flex control-padding">
                                        <label className="col-form-label f14 d-inline font-bold">D</label>
                                        <input required type="number" min = '0' className="forminp form-control d-inline control-padding-right" id="depth" name="vehicle_dimensions_depth_mm" value={this.state.vehicle_dimensions_depth_mm} 
                                    onChange={this.vehicleConfigurationInputFields}
                                     placeholder="depth" autoComplete="off" />
                                    </div>
                                    </div>
                                    
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{Constant.STACKING_VEHICLE_DETAILS_VEHICLE_MAX_UTILIZATION} </label>
                                    <input required type="number" min = '0' className="forminp form-control" id="vehicle_max_utilization" name="vehicle_max_utilization" value={this.state.vehicle_max_utilization} 
                                    onChange={this.vehicleConfigurationInputFields}
                                     placeholder="" autoComplete="off" />
                                    
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{Constant.STACKING_VEHICLE_DETAILS_VEHICLE_MAX_WEIGHT} </label>
                                    <input required type="number" min = '0' className="forminp form-control" id="vehicle_max_weight" name="vehicle_max_weight" value={this.state.vehicle_max_weight} 
                                    onChange={this.vehicleConfigurationInputFields}
                                     placeholder="" autoComplete="off" />
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{Constant.STACKING_VEHICLE_DETAILS_VEHICLE_CAPACITY} </label>
                                    <input required type="number" min = '0' className="forminp form-control" id="vehicle_capacity" name="vehicle_capacity" value={this.state.vehicle_capacity} 
                                    onChange={this.vehicleConfigurationInputFields}
                                     placeholder="" autoComplete="off" />
                                </div>
                            </div>
                            

                            <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">Vehicle Type Count</label>
                                    <input type="number" min = '0' id="vehicle_type_count" name="vehicle_type_count" value={this.state.vehicle_type_count} 
                                        onChange={this.vehicleConfigurationInputFields}
                                        placeholder="" autoComplete="off" 
                                        required = {true}
                                        className=  "forminp form-control"
                                    />
                                    
                                </div>
                            </div>


                        </div>
                        <div className="form-group">
                            <button type="submit" className="btn tbtn float-right save-btn" id="saveGeo">SAVE</button>
                            <button type='button' className="btn tbtn float-right cancel-btn" onClick={this.handelVehicleConfigurationModelCancel}>CANCEL</button>	
                        </div>
                    </form>
                </div>
                </Modal>


            </div>
        )
    }
}

export default withTranslation()(StackingVehicleDetails)