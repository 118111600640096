import React,{Component} from "react";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import redirectURL from "../redirectURL";
import { withTranslation } from "react-i18next";
import Select from 'react-select';
import Constant from "../common/constants";
import ViewMap from "../optirun-admin/viewmap";
import SweetAlert from 'react-bootstrap-sweetalert';

 class OBDAnalysis extends Component {

    constructor(props){
        super(props);
        this.state = {
            radius:100,
            month:new Date().setMonth(new Date().getMonth() - 2),
            locationNames:[],
            location:'',
            demand_cmt_within_radius:0,
            percentage_demand_cmt_within_radius:0,
            percentage_quantity_cases_within_radius:0,
            percentage_weight_kgs_within_radius:0,
            quantity_cases_within_radius:0,
            weight_kgs_within_radius:0,
            dealers_data:[],
            input_qty:0,
            input_volume:0,
            input_weight:0,
            num_invalid_dealers:0,
            num_valid_dealers:0,
            plant_latitude:39.718392735488486,
            plant_longitude:-101.99397794056718,
            percent_dealers_within_radius:0,
            num_dealers_within_radius:0,
            show:false,
            basicTitle:"",
            basicType:"default",
        }
    }



componentDidMount(){

    let params = {
        data_type : 2
    }
    this.boundTypeLocations(params)
}



boundTypeLocations = async(param) => {

    let location_access = JSON.parse(localStorage.getItem("location_access"))

    let locationNames = this.state.locationNames;
    
    await redirectURL.post('master/getBoundTypeLocations',param)
      .then(async (response) =>{
          if(response.data.status == 'success'){
              let boundLocations = response.data.boundLocations
              if(boundLocations.length > 0){
                  boundLocations.map(item =>{
                    if(location_access != null){
                        if(location_access.includes(item.location_code)){
                            locationNames.push({ value : item.location_code,
                                                label : item.location_name })

                            }
                        }else{
                            locationNames.push({ value : item.location_code,
                                                label : item.location_name })
                        }
                  })
                  
                  if(locationNames.length>0){
                    await this.setState({
                                locationNames : locationNames,
                                location : locationNames[0]
                            },()=>this.onClickGetData())
                }else{
                    await this.setState({
                        locationNames : [{label:'Please Add Data',value:'no_data'}],
                        location : [{label:'Please Add Data',value:'no_data'}]
                    })
                }
              }
          }
          this.setState({loadshow:'show-n'})
      })
      .catch(function (error) {
        var errDetails={
            url:"master/getBoundTypeLocations",
            screen:'OBD Analysis',
          error,
          }
        redirectURL.post("/master/logErrorsForApiCalls",errDetails)
        
    })   
      
}

  locationChanged = (location) =>{
    this.setState({
        location : location
    })
}

handleUploadDataMonth = (date) =>{
    this.setState({month:date})
  }

  handleRadius = (event) => {
    let value = parseInt(event.target.value);
    this.setState({radius:value});
}  

onClickGetData = () =>{
    let radius_kms = this.state.radius;
    let plant_location_code = this.state.location.value;
    let month = this.state.month

    let para = {
        radius_kms:radius_kms,
        plant_location_code:plant_location_code,
        month:month
    }
    console.log(para,'para')
    redirectURL.post("forecastvehicle/getOBDAnalysisData",para)
    .then(response =>{
        // console.log(response,'eriuui',response.data.status == 'success')
        if(response.data.status == 'success'){

            let data = response.data.data

            if(data.length >0){            
            
            this.setState({
                demand_cmt_within_radius:data[0].demand_cmt_within_radius,
                percentage_demand_cmt_within_radius:(data[0].percentage_demand_cmt_within_radius)*100,
                percentage_quantity_cases_within_radius:(data[0].percentage_quantity_cases_within_radius)*100,
                percentage_weight_kgs_within_radius:(data[0].percentage_weight_kgs_within_radius)*100,
                quantity_cases_within_radius:data[0].quantity_cases_within_radius,
                weight_kgs_within_radius:data[0].weight_kgs_within_radius,
                dealers_data:data[0].dealers_data,
                input_qty:data[0].input_qty,
                input_volume:data[0].input_volume,
                input_weight:data[0].input_weight,
                num_invalid_dealers:data[0].num_invalid_dealers,
                num_valid_dealers:data[0].num_valid_dealers,
                plant_latitude:data[0].plant_latitude,
                plant_longitude:data[0].plant_longitude,
                num_dealers_within_radius:data[0].num_dealers_within_radius,
                percent_dealers_within_radius:data[0].percent_dealers_within_radius
            })
            }else{
                this.setState({
                    basicTitle : "No Data",
                    basicType : "warning",
                    show : true,
                })
            }
        }else{
            console.log('rghih')
            this.setState({
                basicTitle : "No Data",
                basicType : "warning",
                show : true,
            })
        }
    })

}

closeAlert = async() => {
    
        this.setState({
            show: false
        });
}

render(){
    const {t} = this.props
    const {location,locationNames,radius,demand_cmt_within_radius,percentage_demand_cmt_within_radius,percentage_quantity_cases_within_radius,
        percentage_weight_kgs_within_radius,quantity_cases_within_radius,weight_kgs_within_radius,dealers_data,input_qty,input_volume,
        input_weight,num_invalid_dealers,num_valid_dealers,plant_latitude,plant_longitude,percent_dealers_within_radius,num_dealers_within_radius} = this.state;
    return(
        <>
        <SweetAlert
                show={this.state.show}
                type={this.state.basicType}
                title={this.state.basicTitle}
                onConfirm={this.closeAlert}>
            </SweetAlert>
            
            <div className="row mb-20p pt-20p">
            <div className="col-sm-12">
                <h5 className='fbold  d-flex justify-content-between'>
                    <span>{t('obd_analysis')}</span>
                </h5>
            </div>
            </div> 
            <div className="row"> 
                <div className="col-md-2 col-sm-12">
                    <div className="form-group ">
                        <label className="col-form-label f12">{t('pickup_point')}</label>
                        <Select
                            placeholder={"Select"}
                            onChange={this.locationChanged}
                            name="location"
                            value={location}
                            options={locationNames} />
                    </div>
                </div>

                <div className=" col-sm-12 col-md-2">
                <div className="form-group ">
                    <label className="col-form-label f12"> {t('select_month')} </label>
                      <DatePicker
                        selected={this.state.month}
                        onChange={this.handleUploadDataMonth}
                        dateFormat="MMMM/yyyy"
                        showMonthYearPicker
                        className="forminp form-control"
                      />
                </div>
                </div>
                <div className=" col-sm-12 col-md-2">
                <div className="form-group">
                    <label className="col-form-label f12">{t('location_radius_miles')} </label>
                    <input type="number" name="radius" value={radius} 
                    onChange = {this.handleRadius}   className="form-control forminp" placeholder="Time" min="0" max="120" step="5" />
                </div>
                </div>

                <div className=" col-sm-12 col-md-2">
                <button
                    type="button"
                    className="btn btn-success mt-35p"
                    onClick={this.onClickGetData}
                    >
                    {t('submit')}
                </button>
                </div>
            </div>

            <div className="row mt-10p"> 
                <div className="col-sm-3 col-md-6">
                <h5 style={{color:"#1D5D9B"}}>{t('total_obd_details')}</h5>
                <div className="col-md-12 mb-10p">
                        <div className=" d-flex">
                        <div className="counter-card-qty">
                        <p>{t('valid_dealers')}</p>
                        <p className="bold-text">{num_valid_dealers}</p>
                        </div>
                        <div className="counter-card-qty">
                        <p>{t('invalid_dealers')}</p>
                        <p className="bold-text">{num_invalid_dealers}</p>
                        </div>
                        <div className="counter-card-qty">
                        <p>{t('volume_cmt')}</p>
                        <p className="bold-text">{input_volume}</p>
                        </div>
                        <div className="counter-card-qty">
                        <p>{t('weight_kgs')}</p>
                        <p className="bold-text">{input_weight}</p>
                        </div>
                        <div className="counter-card-qty">
                        <p>{t('quantity')}</p>
                        <p className="bold-text">{input_qty}</p>
                        </div>
                        </div>
                    </div>

                    <h5 style={{color:"#1D5D9B"}}>{t('obd_details_with_in_radius')}</h5>
                    <div className="col-md-12 mt-10p">
                    <div className=" d-flex">
                    <div className="counter-card-qty">
                        <p>{t('dealers')}</p>
                        <p className="bold-text">{num_dealers_within_radius}</p>
                        </div>
                        <div className="counter-card-qty">
                        <p>{t('volume_cmt')}</p>
                        <p className="bold-text">{demand_cmt_within_radius}</p>
                        </div>
                        <div className="counter-card-qty">
                        <p>{t('weight_kgs')}</p>
                        <p className="bold-text">{weight_kgs_within_radius}</p>
                        </div>
                        <div className="counter-card-qty">
                        <p>{t('quantity')}</p>
                        <p className="bold-text">{quantity_cases_within_radius}</p>
                        </div>
                        
                    </div>  
                    </div>
                    <div className="col-md-12 mt-10p">
                        <div className=" d-flex">
                        <div className="counter-card-qty">
                        <p>{t('dealers_percent')}</p>
                        <p className="bold-text">{((percent_dealers_within_radius)*100).toFixed(2)} %</p>
                        </div>
                        <div className="counter-card-qty">
                        <p>{t('volume_percent')}</p>
                        <p className="bold-text">{percentage_demand_cmt_within_radius.toFixed(2)} %</p>
                        </div>
                        <div className="counter-card-qty">
                        <p>{t('weight_pounds_percent')}</p>
                        <p className="bold-text">{percentage_weight_kgs_within_radius.toFixed(2)} %</p>
                        </div>
                        <div className="counter-card-qty">
                        <p>{t('quantity_percent')}</p>
                        <p className="bold-text">{percentage_quantity_cases_within_radius.toFixed(2)} %</p>
                        </div>
                        
                        </div>
                    </div>
                </div>

                <div className="col-sm-3 col-md-6">
                    <ViewMap 
                    locationsData = {dealers_data}
                    radius={radius}
                    plantinfo = {[{latitude:plant_latitude,longitude:plant_longitude}]}

                    />
                </div>
            </div>
        </>
    )
}

}

export default withTranslation()(OBDAnalysis)

