import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Form } from "react-bootstrap";

 function ScrollBar(props) {
  const {t} = useTranslation()
  useEffect(() => {
    setValue(props.data.value);
  }, [props]);
  var [value, setValue] = useState(props.data.value); // Initialize value

  const handleChange = async (event) => {
    await setValue(parseInt(event.target.value)); // Update value when slider is moved
    props.data.value = parseInt(event.target.value);
  };

  var min = 1;
  var max = 7;
  if (props.name === "Number of Days to Use Planning Window") {
    max = 7;
  } else if (
    props.name === "Min VFR Per Vehicle Type" ||
    props.name === "Min VFR Per Spot Vehicle"
  ) {
    max = 100;
  } else if (
    props.name === "Number of Loading Docks" ||
    props.name === "Max Local No of Customers Per Vehicle" ||
    props.name === "Max Upcountry No of Customers Per Vehicle"
  ) {
    max = 20;
  } else if (props.name === "MOQ for Planning") {
    max = 100;
  }
  return (
    <>
      <div className="d-flex align-items-start flex-column justify-content-start mt-5p">
        {props.name === "Number of Days to Use Planning Window" ? (
          <label className="ml-2" style={{ color: "black" }}>
            <input
              checked={!props.data.disabled}
              disabled={true}
              className="mr-2"
              type="checkbox"
            />
            {t(props.name)}
          </label>
        ) : (
          <label className="ml-3" style={{ color: "black" }}>
            {t(props.name)}
          </label>
        )}
        <div className="d-flex align-center justify-content-center">
          <Form.Range
            style={{ marginLeft: "30px" }}
            min={min}
            disabled={props.data.disabled}
            max={max}
            value={value}
            onChange={handleChange}
          />
          <span>
            &nbsp;&nbsp;&nbsp;
            <span className="mt-0 mb-3 pb-1" style={{ color: "black" }}>
              {" "}
              &nbsp;{t(value)}&nbsp;
            </span>
          </span>
        </div>
      </div>
    </>
  );
}

export default ScrollBar