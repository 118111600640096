import React,{Component} from "react";
import {AllModules} from '@ag-grid-enterprise/all-modules';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
import 'ag-grid-enterprise';
import Constant from "../common/constants";
import Modal from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import { withTranslation } from "react-i18next";
import redirectURL from '../redirectURL';
import Select from 'react-select';
import SweetAlert from 'react-bootstrap-sweetalert';
import GridButton from './gridButton';
import TimeRangePicker from '@wojtekmaj/react-timerange-picker';
import getToolConfiguration from "./commonFunctions";
import { getHyphenDDMMMYYYYHHMM } from "../common/utils";
var infoBox = require('../common/google-infowindow');

var map
var overlay;
var infowindows=[];
var markersArraylist=[];
 class VehiclesLocation extends Component{
    constructor(props){
        super(props);
        this.state={
            modules : AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
                resizable:true,
                minWidth:100,
                initialWidth: 200,
                wrapHeaderText: true,
                autoHeaderHeight: true,
            },
            frameworkComponents:{
                GridButton:GridButton,
            },
            rowData:[],
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
			detailCellRendererParams:{},
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:100,
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            animateRows: true,
            openVehicleConfigurationModel:false,
            vehicle_type:'',
            vehicle_name:'',
            vehicle_priority:"Owned",
            min_volume_cmt:'',
            max_volume_cmt:'',
            vehicle_dimensions_width_mm:'',
            vehicle_dimensions_depth_mm:'',
            vehicle_dimensions_height_mm:'',
            vehicle_min_weight:'',
            vehicle_max_weight:'',
            max_no_of_nodes:'',
            variable_vehicle_cost_expandable:[],
            show:false,
            basicTitle:"",
            basicType:"default",
            showAvailablityTable:false,
            data_type:'',
            location:'',
            locationNames:[],
            vehicle_id:'',
            truckslist:[],
            plantinfo:[],
            vicinity:1000,
            activeTrucks:[]
        }
        
    }

   async componentDidMount(){
        var vicinity= 0;

        let toolConfig = JSON.parse(localStorage.getItem('tlcfg'))
        // console.log(toolConfig,"toolConfigtoolConfig")
        if(toolConfig !=undefined && toolConfig !="")
        {
            if(toolConfig.length > 0)
            {
                vicinity = toolConfig[0].gps_truck_location_vicinity;
            }
        }
        this.setState({
            vicinity:vicinity
        })

        var curl = window.location.search;
        var ul= curl.split("?");
        // console.log("ul ",ul)
        // let url_type = window.location.href
        let url_type = ul[parseInt(ul.length)-1];
        // console.log("url_type ", url_type)
        // let path_type = url_type.split('/')
        let path_type = url_type
        let data_type
        // let path = path_type[path_type.length-1]
        let path =url_type

        if (path_type == 'inbound'){
            data_type = 1
            this.setState({data_type : data_type})
        }else{
            data_type = 2
            this.setState({data_type : data_type})
        }
        let params = {
            data_type : data_type
        }
        let toolparameters = {
            data_type : data_type
        }

        await getToolConfiguration(toolparameters)
        await this.getTrucksLocationData(params)
        // await this.boundTypeLocations(params)
        // await this.getVehiclePriorityOrder(params)
        
    }

    componentDidUpdate(oldProps){
        let data_type = this.state.data_type;
        let params = {
            data_type : data_type
        }

        if(oldProps.plantLocation != this.props.plantLocation){
            this.setState({location:this.props.plantLocation})
            this.getTrucksLocationData(params)
        }

    }

    getTrucksLocationData(params)
    {
        params.plant_code = this.props.plantLocation.value;
        redirectURL.post('master/trucklocations',params)
        .then((response) => {
            var records = response.data.records;
            var plantinfo = response.data.plantinfo;
            // console.log("records ", records)
            // console.log("plantinfo ", plantinfo)
            this.setState({
                truckslist:records,
                plantinfo:plantinfo
            })
            this.renderMap();
        })
        .catch(function (error) {
            var errDetails={
                url:"master/trucklocations",
                screen:'Vehicle Availability Configurations',
                error,
              }
            redirectURL.post("/master/logErrorsForApiCalls",errDetails)
            
        })
        
        redirectURL.post('master/fetchActiveTrucks')
        .then((response)=>{
            if (response.data.status !== "Failure") {
                this.setState({activeTrucks:response.data})
            }
        })
    }
    closeAlert = async() => {
        await this.setState({
            show: false
        });
	}
    renderMap = () => {

        //   loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyA9LP10EYr__tHTyPWXJCTrIdBBeSrdjTY&libraries=places,drawing&callback=initMap");
        loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyC6cJAlLsrTTgm5Ck795Vg6X_EToa7FG-A&libraries=places,drawing&callback=initMap");
        window.initMap = this.initMap
    }

    initMap = () => 
    {
        
        var lt=28.6139391;
		var ln=77.2090212;
        
        var mapOptions = {
            zoom: 11,
            zoomControl: true,
            mapTypeControl: true,
            scaleControl: true,
            streetViewControl: true,
            rotateControl: true,
            fullscreenControl: true,
            labels:true,
            mapTypeControlOptions: {
                mapTypeIds: ['satellite', 'roadmap'],
            },
            center: new window.google.maps.LatLng(lt,ln),
            mapTypeId: window.google.maps.MapTypeId.ROADMAP,
            styles: [
                    
				{
					"featureType": "administrative",
					"elementType": "labels.text.fill",
					"stylers": [
						{
							"color": "#444444"
						}
					]
				},
				{
					"featureType": "landscape",
					"elementType": "all",
					"stylers": [
						{
							"saturation": -100
						},
						{
							"lightness": 65
						},
						{
							"visibility": "on"
						}
					]
				},
				
				{
					"featureType": "landscape.man_made",
					"elementType": "all",
					"stylers": [
						{
							"visibility": "on"
						}
					]
				},
				{
					"featureType": "landscape.man_made",
					"elementType": "geometry",
					"stylers": [
						{
							"visibility": "on"
						}
					]
				},
				{
					"featureType": "landscape.man_made",
					"elementType": "geometry.fill",
					"stylers": [
						{
							"visibility": "on"
						}
					]
				},
				{
					"featureType": "landscape.man_made",
					"elementType": "geometry.stroke",
					"stylers": [
						{
							"color": "#c1c2c2"
						},
						{
							"visibility": "on"
						}
					]
				},
				{
					"featureType": "poi.park",
					"elementType": "all",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "poi",
					"elementType": "labels",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "road",
					"elementType": "all",
					"stylers": [
						{
							"saturation": -100
						},
						{
							"lightness": 45
						}
					]
				},
				{
					"featureType": "road.highway",
					"elementType": "all",
					"stylers": [
						{
							"visibility": "simplified"
						}
					]
				},
				{
					"featureType": "road.arterial",
					"elementType": "labels.icon",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "transit",
					"elementType": "all",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
			],
        };
        try{
            map = new window.google.maps.Map(document.getElementById('locationMap'), mapOptions);
        }catch(e){}
        
        
        var bounds = new window.google.maps.LatLngBounds();
        this.maploadData();

		// const iconBase = require("../../assets/images/");
		// const icons = {
		// 	plant: {
		// 	name: "Pickup",
		// 	icon: require("../../assets/images/plant-location.png"),
		// 	},
		// 	supplier: {
		// 	name: "Drop",
		// 	icon: require("../../assets/images/customer_pin_38.png"),
		// 	},
		// 	// both: {
		// 	// name: "Both",
		// 	// icon: require("../../assets/images/business.png"),
		// 	// },
			
		// };
        
        // var legend = document.getElementById("legend");
		// legend.innerHTML = '';
		// for (var key in icons) {
		// 	var type = icons[key];
		// 	var name = type.name;
		// 	var icon = type.icon;
		// 	var div = document.createElement("div");
			
		// 	div.innerHTML = '<img class="legend-height" src="' + icon + '"> ' + name;
		// 	legend.appendChild(div);
		// }

		// map.controls[window.google.maps.ControlPosition.TOP_RIGHT].push(legend);

        // overlay = new window.google.maps.OverlayView();
        // overlay.draw = function() {};
        // overlay.setMap(map);
	 
        window.google.maps.event.addListener(map, 'click', (function() {
            closeAllInfoWindows()
        }))
    }

    maploadData = () =>{
		let data_type = this.state.data_type
		
        for (let i = 0; i < markersArraylist.length; i++) {
            markersArraylist[i].setMap(null);
        }
        
        var locations = this.state.truckslist;
        // console.log("locations ", locations)
        // var lt=17.432143; 
        // var ln=78.374427;
		var lt=21.83715037983873; 
        var ln=77.96570454960938;
        
        var bounds = new window.google.maps.LatLngBounds();
        bounds.extend(new window.google.maps.LatLng((lt),(ln)));
    
        try{
            var infowindow = new window.google.maps.InfoWindow();
            var vicinity = this.state.vicinity;
            if(locations.length > 0)
            {

                var image =  require('../../assets/images/business.png');
                    
                locations.map(function(e,index){
                    // console.log("ee ", e)
                    var image =  require('../../assets/images/truck_3_32.png');
						
                    bounds.extend(new window.google.maps.LatLng(parseFloat(e.latitude),parseFloat(e.longitude)));
                    var marker = new window.google.maps.Marker({
                        position: new window.google.maps.LatLng((e.latitude), (e.longitude)),
                        icon: image,
                        map: map,	
                    });
                    window.google.maps.event.addListener(marker, 'click', (function(marker) {
                        return function() {
                            
                            var contentarr = []
                        
                        var header = e.vehicle_no
						contentarr.push({"key":"Vehicle No", "value":e.vehicle_no})
                        contentarr.push({"key":"Address", "value":e.address})
                        contentarr.push({"key":"Last Packet Time", "value":getHyphenDDMMMYYYYHHMM(e.timestamp)})
                        // contentarr.push({"key":"End Time", "value":e.to_time})
                        var contentString = infoBox(marker.icon, header, contentarr,'')
                                                
                        infowindow.setContent(contentString);
                        infowindows.push(infowindow);
                        
                        infowindow.open(map, marker);
                        
                        }
                    })(marker));
                    
                })  
                
            }
            var plantinfo = this.state.plantinfo;
            // console.log("plantinfo ", this.props.plantLocation.value)
            if(plantinfo.length > 0)
            {
                plantinfo.map((itm) => {
                    var image =  require('../../assets/images/business.png');
						
                    bounds.extend(new window.google.maps.LatLng((itm.latitude),(itm.longitude)));
                    var marker = new window.google.maps.Marker({
                        position: new window.google.maps.LatLng((itm.latitude), (itm.longitude)),
                        icon: image,
                        map: map,	
                    });
                    window.google.maps.event.addListener(marker, 'click', (function(marker) {
                        return function() {
                            
                            var contentarr = []
                        
                        var header = itm.location_name
                        contentarr.push({"key":"Address", "value":itm.location_address})
						contentarr.push({"key":"City", "value":itm.location_city})
                        contentarr.push({"key":"State", "value":itm.location_state})
                        // contentarr.push({"key":"Last Packet Time", "value":getHyphenDDMMMYYYYHHMM(e.timestamp)})
                        // contentarr.push({"key":"End Time", "value":e.to_time})
                        var contentString = infoBox(marker.icon, header, contentarr,'')
                                                
                        infowindow.setContent(contentString);
                        infowindows.push(infowindow);
                        
                        infowindow.open(map, marker);
                        
                        }
                    })(marker));
                    var markerLatlng1 = new window.google.maps.LatLng(itm.latitude,itm.longitude);
                    new window.google.maps.Circle({
                        strokeColor: '#71caab',
                        strokeOpacity: 0.7,
                        strokeWeight: 1,
                        fillColor: '#71caab',
                        fillOpacity: 0.6,
                        map: map,
                        center: markerLatlng1,
                        radius: vicinity
                    });
                })
            }
            map.fitBounds(bounds)
        }catch(e){}
    
    }

    render(){
        const {t} = this.props
        let data = this.state.rowData
        let locValue = this.state.location.value   
        // let filteredData = data.filter(item => item.location_code == locValue)

        let vehicleTypeCnt = 4;
        let truck_min_max = 0;
        let truckPriorities = 0;
        let distancePreferences = 0;
        let operationalTimes = 0;
        let operationalTimesType = 0;
        let clubbingLimits = 0;
        let minLoad = 0;
        let vehicleAvailability = 0;
        let vehicleMinMaxWeight = 0;
        let vehicleAvailabilityType =0;
        var monthly_distance_preferences= 0;

        let toolConfig = JSON.parse(localStorage.getItem('tlcfg'))
        if(toolConfig !=undefined && toolConfig !="")
        {
            if(toolConfig.length > 0)
            {
                vehicleTypeCnt = toolConfig[0].max_no_of_trucks;
                truck_min_max = toolConfig[0].truck_min_max_configuration_preferences;
                truckPriorities = toolConfig[0].truck_priorities;
                distancePreferences = toolConfig[0].distance_preferences;
                operationalTimes = toolConfig[0].vehicle_operational_times;
                if(toolConfig[0].generic_time_windows===1){
                    operationalTimesType=1
                } if(toolConfig[0].individual_truck_time_windows===1){
                    operationalTimesType=2
                }
                clubbingLimits = toolConfig[0].clubbing_limits;
                minLoad = toolConfig[0].min_load_preferences;
                vehicleAvailability = toolConfig[0].vehicle_availability;
                

                if(toolConfig[0].vehicle_availability_by_vehicle_type===1){
                    vehicleAvailabilityType =1
                } if(toolConfig[0].vehicle_availability_by_individual_vehicle===1){
                    vehicleAvailabilityType =2
                }
                monthly_distance_preferences = toolConfig[0].monthly_distance_preferences;
                vehicleMinMaxWeight = toolConfig[0].truck_weight_min_max_preferences;
            }
        }




        // var colsDefs = [
        //     {
        //         headerName:"Facility Name",
        //         field:"location_name",
        //         width:"200"
        //     },
        //     {
        //         headerName:"Vehicle No",
        //         field:"vehicle_no",
        //         width:"100",
        //         cellClass:function(params){
        //             try {
        //                 if(params.data.timestamp === undefined || params.data.timestamp === "")
        //                 {
        //                     return "redbg";
        //                 }
        //             } catch (error) {
                        
        //             }
        //         }
        //     },
        //     {
        //         headerName:"Address",
        //         field:"address",
        //         width:"300",
        //         cellClass:function(params){
        //             try {
        //                 if(params.data.timestamp === undefined || params.data.timestamp === "")
        //                 {
        //                     return "redbg";
        //                 }
        //             } catch (error) {
                        
        //             }
        //         }
        //     },
        //     {
        //         headerName:"Last Packet Time",
        //         field:"timestamp",
        //         width:"150",
        //         valueGetter:function(params){
        //             try {
        //                 if(params.data.timestamp !== "" && params.data.timestamp !== undefined)
        //                 {
        //                     return getHyphenDDMMMYYYYHHMM(params.data.timestamp);
        //                 }
        //                 else{
        //                     return "";
        //                 }
        //             } catch (error) {
                        
        //             }
        //         },
        //         cellClass:function(params){
        //             try {
        //                 if(params.data.timestamp === undefined || params.data.timestamp === "")
        //                 {
        //                     return "redbg";
        //                 }
        //             } catch (error) {
                        
        //             }
        //         }
        //     },
        //     {
        //         headerName:"Latitude",
        //         field:"latitude",
        //         width:"110",
        //         cellClass:function(params){
        //             try {
        //                 if(params.data.timestamp === undefined || params.data.timestamp === "")
        //                 {
        //                     return "redbg";
        //                 }
        //             } catch (error) {
                        
        //             }
        //         }
        //     },
        //     {
        //         headerName:"Longitude",
        //         field:"longitude",
        //         width:"110",
        //         cellClass:function(params){
        //             try {
        //                 if(params.data.timestamp === undefined || params.data.timestamp === "")
        //                 {
        //                     return "redbg";
        //                 }
        //             } catch (error) {
                        
        //             }
        //         }
        //     },
        //     {
        //         headerName:"Service Provider",
        //         field:"provider",
        //         width:"130",
        //         cellClass:function(params){
        //             try {
        //                 if(params.data.timestamp === undefined || params.data.timestamp === "")
        //                 {
        //                     return "redbg";
        //                 }
        //             } catch (error) {
                        
        //             }
        //         }
        //     },
        //     {
        //         headerName:"Temperature",
        //         field:"temperature",
        //         width:"130",
        //         valueGetter:function(params){
        //             // console.log(params.data.temperature.toFixed(2))
        //             if(params.data.temperature != undefined && params.data.temperature != ''){
        //             return params.data.temperature.toFixed(2)
        //             }

        //         }
        //     },
        //     {
        //         headerName:'On Trip',
        //         width:'70',
        //         valueGetter:(params)=>{
        //             if(this.state.activeTrucks.includes(params.data.vehicle_no)){
        //                 return 'Yes'
        //             }else{
        //                 return 'No'
        //             }
        //         }
        //     }
        // ]


        var colsDefs = [
            {
                headerName: t('facility_name'),
                field: "location_name",
                width: "200"
            },
            {
                headerName: t('vehicle_no'),
                field: "vehicle_no",
                width: "100",
                cellClass: function(params) {
                    try {
                        if (params.data.timestamp === undefined || params.data.timestamp === "") {
                            return "redbg";
                        }
                    } catch (error) {
                        // Handle error
                    }
                }
            },
            {
                headerName: t('address'),
                field: "address",
                width: "300",
                cellClass: function(params) {
                    try {
                        if (params.data.timestamp === undefined || params.data.timestamp === "") {
                            return "redbg";
                        }
                    } catch (error) {
                        // Handle error
                    }
                }
            },
            {
                headerName: t('last_packet_time'),
                field: "timestamp",
                width: "150",
                valueGetter: function(params) {
                    try {
                        if (params.data.timestamp !== "" && params.data.timestamp !== undefined) {
                            return getHyphenDDMMMYYYYHHMM(params.data.timestamp);
                        } else {
                            return "";
                        }
                    } catch (error) {
                        // Handle error
                    }
                },
                cellClass: function(params) {
                    try {
                        if (params.data.timestamp === undefined || params.data.timestamp === "") {
                            return "redbg";
                        }
                    } catch (error) {
                        // Handle error
                    }
                }
            },
            {
                headerName: t('latitude'),
                field: "latitude",
                width: "110",
                cellClass: function(params) {
                    try {
                        if (params.data.timestamp === undefined || params.data.timestamp === "") {
                            return "redbg";
                        }
                    } catch (error) {
                        // Handle error
                    }
                }
            },
            {
                headerName: t('longitude'),
                field: "longitude",
                width: "110",
                cellClass: function(params) {
                    try {
                        if (params.data.timestamp === undefined || params.data.timestamp === "") {
                            return "redbg";
                        }
                    } catch (error) {
                        // Handle error
                    }
                }
            },
            {
                headerName: t('service_provider'),
                field: "provider",
                width: "130",
                cellClass: function(params) {
                    try {
                        if (params.data.timestamp === undefined || params.data.timestamp === "") {
                            return "redbg";
                        }
                    } catch (error) {
                        // Handle error
                    }
                }
            },
            {
                headerName: t('temperature'),
                field: "temperature",
                width: "130",
                valueGetter: function(params) {
                    if (params.data.temperature !== undefined && params.data.temperature !== '') {
                        return params.data.temperature.toFixed(2);
                    }
                }
            },
            {
                headerName: t('on_trip'),
                width: "70",
                valueGetter: (params) => {
                    if (this.state.activeTrucks.includes(params.data.vehicle_no)) {
                        return 'Yes';
                    } else {
                        return 'No';
                    }
                }
            }
        ];
        


        // if(this.state.data_type == 1){
        //     colsDefs.push({
        //         headerName:"Suppliers / Vendor Clubbing Limits" ,
        //         field:"max_no_of_nodes",
        //         width:"300"
        //     });
        // }else{
        //     colsDefs.push({
        //         headerName:"Consignees / Dealers Clubbing Limits" ,
        //         field:"max_no_of_nodes",
        //         width:"300"
        //     });
        // }
        
        
       

        return(
            <div className="">
            <SweetAlert
                show={this.state.show}
                type={this.state.basicType}
                title={this.state.basicTitle}
                onConfirm={this.closeAlert}>
            </SweetAlert>
           
                
                <div className="row">
                    <div id="locationMap" style={{width:"100%", height:"60vh"}}></div>
                    
                </div>
                <div className="row card">
                    <div className="card-header">
                        <h5>{t('vehicle_details')}</h5>
                    </div>
                    <div className="card-body">
                        <div className="row">
                                <div id="myGrid" style={{width:"100%",height:"70vh"}} className="col-sm-12 ag-theme-balham dropdown">
                                    <AgGridReact
                                    // modules={this.state.modules} 
                                        rowData={this.state.truckslist}
                                        columnDefs={colsDefs}
                                        gridOptions={{context:{componentParent:this}}}
                                        defaultColDef={this.state.defaultColDef}
                                        frameworkComponents={this.state.frameworkComponents}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        enableRangeSelection = {true}
                                        enableCellContextMenu={true}
                                        statusBar={this.state.statusBar}
                                        sideBar = {this.state.sideBar}
                                    />
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(VehiclesLocation)


function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function closeAllInfoWindows() {
    for (var i=0;i<infowindows.length;i++) {
        infowindows[i].close();
    }
}