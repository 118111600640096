
import React, { Component } from 'react';

 
const OrderButton = (props) => {
    
    return (
        
        <div style={{"textAlign":"center"}}>
           
        <button className={'btn grid-btn'} style={{border:"0px", backgroundColor: "#C8C7C7"}}>
            <i className="fa fa-eye"></i> View
        </button>
        
    </div>
    );
};

export default OrderButton;
