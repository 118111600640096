import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

// import Level3 from "./level3";

const PWConfigL2 = (props) => {
  var { data, status } = props;
  const {t} = useTranslation()
  useEffect(() => {
    if (status === 0) {
      statusChange();
    }
  }, [status]);

  const statusChange = () => {
    const updatedData = { ...data };
    Object.keys(updatedData).forEach((item1) => {
      if (
        item1 !== "type" &&
        item1 !== "disabled" &&
        item1 !== "value" &&
        item1 !== "name" &&
        item1 !== "id"
      ) {
        updatedData[item1].value = 0;
      }
    });
  };

  const changeHandler = (item, event) => {
    const updatedData = { ...data };
    const keys = Object.keys(updatedData);

    if (updatedData[item].value === 0) {
      updatedData[item].value = 1;
      if (updatedData[item].type === "radio") {
        keys.forEach((key) => {
          if (
            updatedData[item].id === updatedData[key].id &&
            updatedData[item].name !== updatedData[key].name
          ) {
            updatedData[key].value = 0;
          }
        });
      }
    } else {
      updatedData[item].value = 0;
    }

    if (updatedData[item].type === "number") {
      updatedData[item].value = parseInt(event.target.value);
    }

    setClick((prevClick) => !prevClick);
  };

  const [click, setClick] = React.useState(true);
  return (
    <div>
      {Object.keys(data).map((item, idx) => {
        if (
          item !== "type" &&
          item !== "value" &&
          item !== "disabled" &&
          status === 1 &&
          item !== "name" &&
          item !== "id" &&
          props.name === "Optimization Type*"
        ) {
          return (
            <div
              className="row ml-3 per col-md-12"
              key={idx}
              id={item}
              style={{ display: "block" }}
            >
              <label className="" style={{ color: "black", fontSize: "10" }}>
                <input
                  type={data[item].type}
                  onChange={(event) => changeHandler(item, event)}
                  disabled={data[item].disabled}
                  checked={data[item].value === 1}
                  value={data[item].value}
                  name={data[item].id}
                />
                &nbsp;&nbsp;{t(item)}
              </label>
              {/* <Level3 data={data[item]} status={data[item].value} /> */}
            </div>
          );
        }
        return null;
      })}
    </div>
  );
};

export default PWConfigL2;
