import React,{Component} from "react";
import {AllModules} from '@ag-grid-enterprise/all-modules';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
import 'ag-grid-enterprise';
import Constant from "../common/constants";
import 'react-responsive-modal/styles.css';
import redirectURL from '../redirectURL';
import { getHyphenDDMMMYYYYHHMM,  getHyphenYYYYMMDDHHMMSS} from '../common/utils'; 
import Select from 'react-select';
import SweetAlert from 'react-bootstrap-sweetalert';
import GridButton from './gridButton';
import getToolConfiguration from "./commonFunctions";
import { withTranslation } from 'react-i18next';
import $ from "jquery";
import Modal from 'react-responsive-modal';
import OnMapVehiclesLocation from "./onMapVehicleLocation";
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import SpotVehicle from "./spotVehicle";
import Level1 from "./configurations/confingL1";
import PWConfigL1 from "./plantWConfigL1";
import ConfigsInRunNew from "./configsInRunNew";
// import { it } from "node:test";

var moment = require("moment");
 class PlanNewDispatch extends Component{
    constructor(props){
        super(props);
        this.state={
            modules : AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
                resizable:true,
                minWidth:100,
                initialWidth: 200,
                wrapHeaderText: true,
                autoHeaderHeight: true,
            },
            frameworkComponents:{
                GridButton:GridButton,
            },
            rowData:[],
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
			detailCellRendererParams:{},
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:100,
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            animateRows: true,
            openMaterialConfigurationModel:false,            
            show:false,
            basicTitle:"",
            basicType:"default",
            data_type:'',
            location:'',
            material_code:'',
            material_name:'',
            material_type:'',
            material_weight:'',
            material_dimensions_width_mm:'',
            material_dimensions_depth_mm:'',
            material_dimensions_height_mm:'',
            rowId:'',
            locationNames:[],
            showSlideBlock:"",
            overlayBlock:"show-n",
            csvfiledata:[],
            droppedList:[],
            order_id:"",
            urlorder_id:"",
            inputDetails:[],
            config:"activet",
            inputInfo:"",
            vehicleinfo:"",
            configData:"show-m",
            inputInfoData:"show-n",
            vehicleinfoData:"show-n",
            nongpsvehicleinfoData:"show-n",
            nongpsvehicleinfo:'',
            opti_type:0,
            openTimeOptimizationParamModel:false,
            time_limit:72,
            average_speed: 60,
            order_date:"",
            warehouseTitle:"",
            sla:1,
            vehicleDetails:[],
            truck_location_vicinity:'',
            routeprefer:true,
            nonGpsData:[],
            run_with_transit:true,
            run_with_transit_cityclub:false,
            run_with_RDD:false,
            masterVehicleAvailabilityData:[],
            plan_with_spot_vehicles:0,
            no_of_spot_vehicles_given:[],
            vehicle_type: '',
            vehicleTypeList: [],
            no_of_vehicles_required:0,
            selectReload:false,
            selectedVFR:{ label: "0%", value: 0},
            updatedVehicles:[],
            noOfSpotVch:false,
            spotTrucks:{},
            simulationTime:'',
            openConfigModel:false,
            plantConfigs:[],
            openSavedConfigs:false,
            spotButtonVisble:1,
            planConfig:'',
            planConfigData:'show-n',
            transitVehicles:[],
            vehicles_considered_for_planning:[],
            consolidatedTrnsxId:'',
            is_sunday_planning_enabled:0,
            reconcilation: 0,
            enable_sunday_planning:false
        }   
        // var vfr_options=[{value:0,lable:0},{value:25,lable:25},{value:50,lable:50}]     
    }

    async componentDidMount(){
        var curl = window.location.search;
        try {
            var ulc= curl.split("?");
            var ul = ulc[1].split("&");
            // console.log("ulc", ulc)
            let url_type = ul[0];
            let url_typeDate = ul[1];
            var order_id =url_type
            var urlorder_id = ""
            var osla = url_typeDate;
            
            var oDate = "";
            // console.log("S1")
            if(order_id !=undefined && order_id !="undefined" && order_id !="")
            {
                // console.log("S2",base64DecodeUnicode(order_id))
                var orderID = window.atob(order_id);
                urlorder_id = orderID;
                // console.log("S3",orderID)
                var oId = orderID.split("_");
                // console.log("oId ", oId)

                order_id = oId[0];
                oDate = moment(oId[1],"YYYY-MM-DD").format("DD-MM-YYYY");
                // console.log("order_id ", order_id)
            }
            
            // if(oDate !=undefined && oDate !="undefined" && oDate !="")
            // {
            //     oDate = window.atob(oDate);
            // }
            // console.log("urlorder_id ", urlorder_id)
            // console.log("order_id ", order_id)
            // console.log("oDate ", oDate)
            // console.log("osla ", osla)
            await this.setState({order_id : order_id, order_date:oDate,sla:osla, urlorder_id:urlorder_id})
            await this.getPlanDetails()
            await this.getPlanInputDetails()
            // await this.getTransitVehicles()
            // await this.getPlanningConsideredVehicles()
            await redirectURL.post("/master/getPlantwiseConfigs")
            .then((response)=>{
                var data=response.data[0][this.state.order_id].Outbound
                var spotReqired=data['Plan Spot Vehicles'].value
                this.setState({spotButtonVisble:spotReqired,
                    plantConfigs:data
                })
            })
        } catch (error) {
            
        }
        await this.enablesundayplanning()

        
    }

    enablesundayplanning = () =>{
        var currentDate=''
        if (this.state.simulationTime === "") {
          currentDate = new Date();
        } else {
          currentDate = new Date(this.state.simulationTime);
        }
        const currentDay = currentDate.getDay();

        const currentHour = currentDate.getHours();
        const enableCheckbox =
        (currentDay === 6 && currentHour >= 16) || 
        (currentDay === 0 && currentHour < 16); 

        if (enableCheckbox) {
            this.setState({
                enable_sunday_planning:true
            })
        } else {
            this.setState({
                enable_sunday_planning:false
            })
        }

    }

    getPlanningConsideredVehicles =async () =>{
        let orderid = this.state.urlorder_id
        var toolConfig = (JSON.parse(localStorage.getItem("tlcfg")))[0]
        // console.log(toolConfig,'tool')
        let VehicleAvailabilityData = []
        var params={}
        params.plant_location_code = this.state.order_id
        let masterVehicleAvailabilityData = this.state.masterVehicleAvailabilityData
        // console.log('first',masterVehicleAvailabilityData)
        await this.gridApi3.forEachNode(function (rowNode, index) {
            var data = rowNode.data;
            if(rowNode.selected === false){
            VehicleAvailabilityData.push(data)
            }
        });
        params.order_id = orderid
        params.transaction_id = orderid
        params.selected_vehicles_df = JSON.stringify(VehicleAvailabilityData)
        params.month_end_planning_start = toolConfig.month_end_threshold_day 
        params.no_of_loading_docs = toolConfig.number_of_loading_docs
        params.plan_execution_date = getHyphenYYYYMMDDHHMMSS(new Date())
        if (this.state.simulationTime !== undefined && this.state.simulationTime !== ''){
            params.plan_execution_date = getHyphenYYYYMMDDHHMMSS(this.state.simulationTime)
        }
        if(toolConfig.static_planning_window !== 0){

            params.is_static_planning_window = "true"
            params.static_planning_window = toolConfig.static_planning_window
        }else{
            params.is_static_planning_window = "false";
            params.static_planning_window = 0
        }
        // console.log(params,'params',this.state.masterVehicleAvailabilityData)
        await redirectURL.post("master/getplanConsideredVehicles",params)
        .then(async (response)=>{
            // console.log('res',response)
            if(response.data != undefined && response.data.length >= 0){
                let veh_considered = response.data
                masterVehicleAvailabilityData.map(itm=>{
                    if(veh_considered.includes(itm.vehicle_no)){
                        itm.veh_considered_for_planning = 'yes'
                    }else{
                        itm.veh_considered_for_planning = 'no'
                    }
                })
                await this.setState({
                    masterVehicleAvailabilityData : masterVehicleAvailabilityData
                })
            }
        })
    }

    getTransitVehicles =async () =>{
        await redirectURL.post("/master/getTrucksInTransit")
        .then(async (response)=>{
            // console.log(response.data,'hello')
            await this.setState({transitVehicles:response.data})
        })
    }
    getPlanDetails = () =>{
        var params = {
            order_id : this.state.urlorder_id,
            // order_date:this.state.order_date,
            // sla:this.state.sla
        }
       
        redirectURL.post('dispatch/getDispatchOrderDetails',params)
        .then(async response =>{
            if(response.data.length > 0)
            {
                var toolParams = {
                    data_type : response.data[0].data_type,
                    location_code:response.data[0].location_code
                }
                let toolparameters = {
                    data_type : response.data[0].data_type,
                    plant_code:response.data[0].location_code
                }
                await getToolConfiguration(toolparameters)
                await this.boundTypeLocations(toolParams)
                await this.getVehiclesData(toolParams)
                this.setState({
                    data_type : response.data[0].data_type,
                    transactionDetails:response.data,
                    warehouseTitle:this.state.order_id,
                    consolidatedTrnsxId:response.data[0].consolidatedTrnsxId,
                    loadshow:'show-n',
                    overly:'show-n',
                    overlayBlock:"show-n",
                })
                var params ={
                    data_type : response.data[0].data_type,
                    plant_code : response.data[0].location_code
                };
                await this.getMasterAvailabilityVehiclesData(params) 
                
            }
            else{
                this.setState({
                    loadshow:'show-n',
                    overly:'show-n',
                    overlayBlock:"show-n",
                })
            }
        })
        .catch(function (error) {
            var errDetails={
                url:"dispatch/getDispatchOrderDetails",
              screen:'Run New Plan',
              error,
              
          }
            redirectURL.post("/master/logErrorsForApiCalls",errDetails)
            
        })
            
    
    }

    getPlanInputDetails = () =>{
        var params = {
            order_id : this.state.urlorder_id,
            // order_date:this.state.order_date,
            location_code:this.state.order_id,
            sla:this.state.sla
        }
        
        redirectURL.post('dispatch/getTransactionInputDetails',params)
        .then(async response =>{

            var inputDetails = response.data;
            // console.log("inputDetails ", inputDetails)
            this.setState({
                inputDetails:inputDetails,
            })
        })
        .catch(function (error) {
            var errDetails={
                url:"dispatch/getTransactionInputDetails",
              screen:'Run New Plan',
              error,
              
          }
            redirectURL.post("/master/logErrorsForApiCalls",errDetails)
            
        })
           

    }

    getVehiclesData = (params) =>{
        let vehicleTypeList = this.state.vehicleTypeList
       
        redirectURL.post('master/getVehiclesData',params)
        .then(async response =>{
            if(response.data.status == 'success'){
                let vehiclesData = response.data.vehiclesData
                if(vehiclesData.length > 0){

                await vehiclesData.map(item => {
                    vehicleTypeList.push({ value : item.vehicle_type,
                                            label : item.vehicle_type,volume:item.max_volume_m3,veh_weight:item.vehicle_max_weight })

                    if(item.vehicle_capacity){
                    // item.vehicle_capacity= Math.round((item.vehicle_capacity/1000000000)*100)/100
                    item.vehicle_capacity = item.vehicle_capacity/1000;
                    item.vehicle_dimensions_width_mm = item.vehicle_dimensions_width_mm/1000;
                    }
                })
                await this.setState({
                    vehicleTypeList : vehicleTypeList,
                    vehicle_type : vehicleTypeList[0],
                    rowData:vehiclesData,
                    nonGpsData:vehiclesData
                })
                }
            }
        })
        .catch(function (error) {
            var errDetails={
                url:"master/getVehiclesData",
              screen:'Run New Plan',
              error,
              
          }
            redirectURL.post("/master/logErrorsForApiCalls",errDetails)
            
        })
    
    }

    closeAlert = async() => {
        if(this.state.basicType == "success")
        {
            var data_type = this.state.data_type;
            var path = "outbound";
            if(data_type == 1)
            {
                path = "inbound";
            }
            window.location.href="dipatchplanhistory?"+path;
        }
        else
        {
            this.setState({
                show: false
            });
        }
	}

    boundTypeLocations = async(param) => {

      let locationNames = this.state.locationNames;
     
        await redirectURL.post('master/getBoundTypeLocations',param)
            .then(async (response) =>{
                if(response.data.status == 'success'){
                    let boundLocations = response.data.boundLocations
                    if(boundLocations.length > 0){
                        boundLocations.map(item =>{
                            locationNames.push({ value : item.location_code,
                                label : item.location_name })
                        })
                        await this.setState({
                            locationNames : locationNames,
                            location : locationNames[0]
                        })
                    }
                }
            })
            .catch(function (error) {
                var errDetails={
                    url:"master/getBoundTypeLocations",
                  screen:'Run New Plan',
                  error,
                  
              }
                redirectURL.post("/master/logErrorsForApiCalls",errDetails)
                
            })
       
        
    }

    hideSlideBlock = () =>{
        document.getElementById("bulkUploadForm").reset()
        document.getElementById("inValidDataInfo").innerHTML = "";
        this.setState({
            overlayBlock:"show-n",
            showSlideBlock:"",
        })
    }

    uploadBulkFormHandler(event){
        event.preventDefault();
        var flag = 0;
        let saveData = new FormData();
        if(flag == 0)
        {      
            saveData.append("loadFile", this.state.loadFile);
            var notify_email = $("#notify_email").val();
            saveData.append("notify_email", notify_email);
            saveData.append("module_type", 1);
            saveData.append("data_type", this.state.data_type);
            saveData.append("material_configurations", this.state.material_configurations)
            let location = this.state.location;
            saveData.append("location_code", location.value)
            saveData.append("location_name", location.label)
            saveData.append("user_name", localStorage.getItem('username'))
            saveData.append("email", localStorage.getItem('email'))
            // console.log(saveData,saveData);
            this.setState({
                loadshow:'show-m',
                overly:'show-m',
            });
           
                redirectURL.post("/dispatch/generateLoad",saveData,{
                    headers:{
                        'content-type': 'multipart/form-data',
                        'Access-Control-Allow-Origin':"*",
                        'Access-Control-Allow-Methods':'POST',
                        'Access-Control-Allow-Headers': 'Origin, Content-Type'
                    }
                }).then((response)=>{
                    console.log(response,"responseFromProcess")
                    var statusCode = response.data.status;
                    if(statusCode == 8)
                    {
                        this.setState({
                            processErrMsg : response.data.message,
                            processErrShow : 1,
                            transactionId : response.data.transactionId,
                            droppedList : response.data.droppedList,
                            statusCode : statusCode,
                            loadshow:'show-n',
                            overly:'show-n',
                            overlayBlock:"show-n",
                        });
                    }
                    else if(statusCode > 3 && statusCode != 8)
                    {
                        if(statusCode == 11)
                        {
                            var processMsg = "Use the standard input data file template for uploading loads";
                        }
                        else
                        {
                            var processMsg = response.data.message;
                        }
                        this.setState({
                            processErrMsg : response.data.message,
                            processErrShow : 1,
                            transactionId : response.data.transactionId,
                            statusCode : statusCode,
                            loadshow:'show-n',
                            overly:'show-n',
                            overlayBlock:"show-n",
                        });
                    }
                    else
                    {
                        this.checkTransactionStatus(response.data.transactionId);
                        // this.setState({                    
                        //     basicTitle : response.data.message,
                        //     basicType : "success",
                        //     show : true,
                        //     loadshow:'show-n',
                        //     overly:'show-n',
                        //     showSlideBlockUpload:"",
                        // });
                    }
                    //window.location.reload();
                })
                .catch(function (error) {
                    var errDetails={
                        url:"/dispatch/generateLoad",
                      screen:'Run New Plan',
                      error,
                      
                  }
                    redirectURL.post("/master/logErrorsForApiCalls",errDetails)
                    
                })
            
        }
        else
        {
            this.setState({
                basicTitle : "Please upload the file",
                basicType : "warning",
                show : true,
            })
        }
    };

    checkTransactionStatus = (transactionId) =>{
        var query = {
            transaction_id : transactionId
        }
        
            redirectURL.post("/dispatch/checkTransactionStatus",query)
            .then((response)=>{
                // console.log(response,"responseFromcheckTransactionStatus")
                var statusCode = response.data.status;
                if(statusCode == 8)
                {
                    this.setState({
                        processErrMsg : response.data.message,
                        processErrShow : 1,
                        transactionId : response.data.transactionId,
                        droppedList : response.data.droppedList,
                        statusCode : statusCode,
                        loadshow:'show-n',
                        overly:'show-n',
                        overlayBlock:"show-n",
                    });
                }
                else if(statusCode > 3 && statusCode != 8)
                {
                    this.setState({
                        processErrMsg : response.data.message,
                        processErrShow : 1,
                        transactionId : response.data.transactionId,
                        statusCode : statusCode,
                        loadshow:'show-n',
                        overly:'show-n',
                        overlayBlock:"show-n",
                        basicTitle : response.data.message,
                        basicType : "warning",
                        show : true,
                    });
                }
                else if(statusCode == 0)
                {
                    var checkCount = this.state.checkCount;
                    var updatedCnt = parseInt(checkCount)+1;
                    this.setState({
                        checkCount : updatedCnt
                    });
                    // console.log(updatedCnt,"checkCount")
                    if(updatedCnt < 3)
                    {
                        this.chkStatus(response.data.transactionId);
                    }
                    else
                    {
                        this.setState({                    
                            basicTitle : "Dispatch Planning is in progress..!!! for "+this.state.order_id,
                            basicType : "success",
                            show : true,
                            loadshow:'show-n',
                            overly:'show-n',
                            overlayBlock:"show-n",
                            showSlideBlockUpload:"",
                            loadFile:''
                        })
                        $("#uploadFile").val("");
                        $("#notify_email").val("");

                    }
                }
                else
                {
                    this.setState({                    
                        basicTitle : response.data.message,
                        basicType : "success",
                        show : true,
                        loadshow:'show-n',
                        overly:'show-n',
                        overlayBlock:"show-n",
                        showSlideBlockUpload:"",
                    });
                    // this.getDispatchPlanData()
                }
            })
            .catch(function (error) {
                var errDetails={
                    url:"/dispatch/checkTransactionStatus",
                  screen:'Run New Plan',
                  error,
                  
              }
                redirectURL.post("/master/logErrorsForApiCalls",errDetails)
                
            })
        
    }

    chkStatus = (transactionId) => {
        this.checkTransactionStatus(transactionId);
    }
    onGridReady = params => {
        this.gridApi = params.api;  
        this.gridColumnApi = params.columnApi;
    };
    onGridState = () =>{
        this.colState = this.gridColumnApi.getColumnState();
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
    }
    onGridReady1 = params => {
		this.gridApi1 = params.api;
	    this.gridColumnApi1 = params.columnApi;
	};
	
	onGridState1 = () =>{
        this.colState1 = this.gridColumnApi1.getColumnState();
        this.rowGroupState1 = this.gridColumnApi1.getRowGroupColumns();
        this.pivotColumns1 = this.gridColumnApi1.getPivotColumns();
        window.filterState1 = this.gridApi1.getFilterModel();
        this.gridApi1.setFilterModel(window.filterState1);
	}
    
    onGridReady2 = params => {
		this.gridApi2 = params.api;
	    this.gridColumnApi2 = params.columnApi;
	};
	
	onGridState2 = () =>{
        this.colState2 = this.gridColumnApi2.getColumnState();
        this.rowGroupState2 = this.gridColumnApi2.getRowGroupColumns();
        this.pivotColumns2 = this.gridColumnApi2.getPivotColumns();
        window.filterState2 = this.gridApi2.getFilterModel();
        this.gridApi2.setFilterModel(window.filterState2);
	}
    
    onGridReady3 = params => {
		this.gridApi3 = params.api;
	    this.gridColumnApi2 = params.columnApi;
	};
	
	onGridState3 = () =>{
        this.colState3 = this.gridColumnApi3.getColumnState();
        this.rowGroupState3 = this.gridColumnApi3.getRowGroupColumns();
        this.pivotColumns3 = this.gridColumnApi3.getPivotColumns();
        window.filterState3 = this.gridApi3.getFilterModel();
        this.gridApi3.setFilterModel(window.filterState3);
	}

    onGridReady4 = params => {
		this.gridApi4 = params.api;
	    this.gridColumnApi4 = params.columnApi;
	};

    onGridState4 = () =>{
        this.colState4 = this.gridColumnApi4.getColumnState();
        this.rowGroupState4 = this.gridColumnApi4.getRowGroupColumns();
        this.pivotColumns4 = this.gridColumnApi4.getPivotColumns();
        window.filterState4 = this.gridApi4.getFilterModel();
        this.gridApi4.setFilterModel(window.filterState4);
	}
    deleteNode = (propData) => {
        let logParams = {
            "location_code" : this.state.order_id,
            // "location_name" : this.state.location.label,
            "user_name" : localStorage.getItem("username"),
            "useremail" : localStorage.getItem("email"),
            "client" : localStorage.getItem("client"),
            "screen" : "Run Plan",
            "activity" : 'clicked on Delete Icon in configurations aggrid',
            "event" : "Row deleted from the aggrid",
            "data_type" : this.state.data_type,
        }
    
        redirectURL.post("/master/loguserUpdatesorChanges",logParams)
        var nodeToDelete = propData.data;
        this.gridApi.updateRowData({ remove: [nodeToDelete] }, { deltaRowDataMode: true });
        var updatedNodes = this.gridApi.getRenderedNodes().map((node) => node.data);
        this.setState({
            rowData:updatedNodes
        });
    };
    fixProcess = () => {
        this.setState({
            processErrMsg : "",
            processErrShow : 0,
            loadFile:''
        });
        $("#uploadFile").val("");
        $("#notify_email").val("");
        //document.getElementById("upform").reset();
    }
    ignoreProcessError = () => {
        this.setState({
            loadshow:'show-m',
            overly:'show-m',
        });
        var transactionId = this.state.transactionId;
        // console.log("transactionId",transactionId)
        var params = {
            transactionId : transactionId,
            data_type : this.state.data_type,
        }
        
            redirectURL.post("dispatch/generateLoadByTransactionId",params)
            .then((response)=>{
                //console.log(response.data,"response")
                var statusCode = response.data.status;
                if(statusCode == 0 || statusCode == 1)
                {
                    this.setState({                    
                        basicTitle : response.data.message,
                        basicType : "success",
                        show : true,
                        processErrMsg : "",
                        processErrShow : 0,
                        loadshow:'show-n',
                        overly:'show-n',
                        overlayBlock:"show-n",
                        showSlideBlockUpload:"",
                    });
                }
                else
                {
                    this.setState({
                        processErrMsg : response.data.message,
                        processErrShow : 1,
                        transactionId : response.data.transactionId,
                        loadshow:'show-n',
                        overly:'show-n',
                        overlayBlock:"show-n",
                    });
                }
                //window.location.reload();
            })
            .catch(function (error) {
                var errDetails={
                    url:"dispatch/generateLoadByTransactionId",
                  screen:'Run New Plan',
                  error,
                  
              }
                redirectURL.post("/master/logErrorsForApiCalls",errDetails)
                
            })
       
    }

    vehicleAvailabilityCheckConfig=(currentAvailableVehicles)=>{
        if (currentAvailableVehicles.length===0){
            currentAvailableVehicles=this.state.masterVehicleAvailabilityData
        }
        var availableCheck=false
        var availableVehiclesForPlan=[...currentAvailableVehicles,...this.state.no_of_spot_vehicles_given]
        const currentDate = new Date();
        if (currentDate.getHours() >= 0 && currentDate.getHours() < 18) {
            const today = currentDate.toISOString().split('T')[0]; 
            const tomorrow = new Date();
            const third_day=new Date()
            tomorrow.setDate(currentDate.getDate() + 1);
            third_day.setDate(currentDate.getDate() + 2);
            const nextDay = tomorrow.toISOString().split('T')[0];
            const thirdDay = third_day.toISOString().split('T')[0];
            availableVehiclesForPlan.map(obj =>{
                let endTime=new Date(obj.vehicle_next_available_date)
                var timeCheck=endTime.getHours()<18
                if (obj.available_date_time!==undefined){
                        let endTime=new Date(obj.available_date_time)
                        var timeCheck=endTime.getHours()<18
                        // console.log(timeCheck,endTime)
                        var spotDate=getHyphenYYYYMMDDHHMMSS(obj.available_date_time)
                        if ((spotDate.startsWith(today) ||  spotDate.startsWith(nextDay) ||  (spotDate.startsWith(thirdDay)&&timeCheck))){
                            availableCheck=true
                        }
                }else{
                if ((obj.vehicle_next_available_date.startsWith(today) ||  obj.vehicle_next_available_date.startsWith(nextDay) ||  (obj.vehicle_next_available_date.startsWith(thirdDay)&&timeCheck))){
                    availableCheck=true
                }}
            });
        } 
        else
         {
            const today = currentDate.toISOString().split('T')[0]; 
            const tomorrow = new Date(currentDate);
            tomorrow.setDate(currentDate.getDate() + 1);
            const nextDay = tomorrow.toISOString().split('T')[0];
            const second_day=new Date()
            second_day.setDate(second_day.getDate()+2)
            const third_day=new Date()
            third_day.setDate(third_day.getDate()+3)
            const secondDay=second_day.toISOString().split('T')[0]
            const thirdDay= third_day.toISOString().split('T')[0]
            const timeCheck=third_day.getHours()<18

            availableVehiclesForPlan.map(obj => {
                let endTime=new Date(obj.vehicle_next_available_date)
                var timeCheck=endTime.getHours()<18
                if (obj.available_date_time!==undefined){
                    let endTime=new Date(obj.available_date_time)
                    var timeCheck=endTime.getHours()<18
                    var spotDate=getHyphenYYYYMMDDHHMMSS(obj.available_date_time)
                    if ((spotDate.startsWith(today) || spotDate.startsWith(nextDay) ||  spotDate.startsWith(secondDay) ||  (spotDate.startsWith(thirdDay)&&timeCheck))){
                        availableCheck=true
                    }
                }else{
                    if( obj.vehicle_next_available_date.startsWith(today) ||   obj.vehicle_next_available_date.startsWith(nextDay) ||  obj.vehicle_next_available_date.startsWith(secondDay) ||  (obj.vehicle_next_available_date.startsWith(thirdDay)&&timeCheck)){
                        availableCheck=true
                    }
                }
            })       
        }
        return availableCheck
    }

    vehicleCnfCheck = () =>{
        let vehcnf = window.confirm("Vehicles are not available for planning !, Do you wish to proceed ?");
        return vehcnf;
    }

    planNewDispatchWithConfig =async () => {
        // console.log('hit here')
        let logParams = {
            "location_code" : this.state.order_id,
            // "location_name" : this.state.location.label,
            "user_name" : localStorage.getItem("username"),
            "useremail" : localStorage.getItem("email"),
            "client" : localStorage.getItem("client"),
            "screen" : "Run Plan",
            "activity" : 'clicked on Run Plan Button',
            "event" : "Alert is opened with yes or no",
            "data_type" : this.state.data_type,
        }
      
        redirectURL.post("/master/loguserUpdatesorChanges",logParams)
        // this.state.updatedVehicles.map((item)=>{
           
        // })

        let vehicleCountGiven = 0;
        let rowCount = this.state.rowData.length
        if(this.state.reconcilation){
            if(this.state.simulationTime){
                if(this.state.plan_with_spot_vehicles === 1){
                    if(this.state.no_of_spot_vehicles_given.length == 0){
                        return this.setState({
                            basicTitle : "Please Select Spot Vehicles",
                            basicType : "warning",
                            show : true,
                            loadshow:'show-n',
                            overly:'show-n',
                            overlayBlock:"show-n",
                        })
                    }
                }
                if(this.state.run_with_transit !== false || this.state.run_with_RDD !== false || this.state.run_with_transit_cityclub !== false){
                    var run_with_transit_cityclub=0
                    var min_VFR=0
                    var spot_min_VFR = 0
                    var min_qty_threshold = 0
                    // await redirectURL.post("/master/getPlantwiseConfigs")
                    // .then((response)=>{
                    //     var data=response.data[0][this.state.order_id].Outbound 
                    run_with_transit=this.state.plantConfigs['Prioritize Multi City Dealers First in a Vehicle'].value
                        min_VFR=this.state.plantConfigs['Min VFR Per Vehicle Type'].value
                        spot_min_VFR=this.state.plantConfigs['Min VFR Per Spot Vehicle'].value
                        min_qty_threshold = this.state.plantConfigs['MOQ for Planning'].value
                    // })
                var cnf = window.confirm("Are you sure you want to proceed?");
                if(cnf === true)
                {
                    this.setState({
                        loadshow:'show-m',
                        overly:'show-m',
                        overlayBlock:"show-m",
                    })
                    redirectURL.post("/master/logVehicleTimeEdit",this.state.updatedVehicles)
                    let logParams = {
                        "location_code" : this.state.order_id,
                        // "location_name" : this.state.location.label,
                        "user_name" : localStorage.getItem("username"),
                        "useremail" : localStorage.getItem("email"),
                        "client" : localStorage.getItem("client"),
                        "screen" : "Run New Plan",
                        "activity" : 'Run Plan',
                        "event" : "Run Plan",
                        "data_type" : this.state.data_type,
                    }
                    
                        redirectURL.post("/master/logUserUpdateOrSaveDataActivities",logParams)
                        .then(()=>{})
                        .catch(function (error) {
                            var errDetails={
                                url:"/master/logUserUpdateOrSaveDataActivities",
                              screen:'Run New Plan',
                              error,
                              
                          }
                            redirectURL.post("/master/logErrorsForApiCalls",errDetails)
                            
                        })
                    
                    var vehicleData = [];
                    this.gridApi.forEachNode(function (rowNode, index) {
                        // console.log("rowNode Run ", rowNode)
                        var data = rowNode.data;
                        vehicleData.push(data);
                    });
                    var inputselectedData = [];
                    this.gridApi2.forEachNode(function (rowNode, index) {
                        var data = rowNode.data;
                        delete data._id;
                        // delete data.valid_data;
                        inputselectedData.push(data);
                    });
        
        
                    // commented as multi select is removed
                    // var gpsVehiclesSelectedData = []
                    // gpsVehiclesSelectedData = this.gridApi3.getSelectedRows()
        
        
                    var currentAvailableVehicles = []
                    this.gridApi3.forEachNode(function (rowNode, index) {
                        var data = rowNode.data;
                        delete data._id;
                        // delete data.valid_data;
                        currentAvailableVehicles.push(data);
                    });
                await this.setState({
                  masterVehicleAvailabilityData: currentAvailableVehicles,
                });
                await this.getPlanningConsideredVehicles();
                    var nongpsVehiclesData = [];
                    // this.gridApi4.forEachNode(function (rowNode, index) {
                    //     var data = rowNode.data;
                    //     delete data._id;
                    //     // delete data.valid_data;
                    //     if(data.vehicle_available_count === undefined ){
                    //         data.vehicle_available_count = 0;
                    //         vehicleCountGiven += 1
                    //     }
                    //     nongpsVehiclesData.push(data);
                    // });
                    
                    //for testing team added this 
                    if(this.state.simulationTime != ''){
                        var availableCheck = true
                    }else{
                        var availableCheck =await this.vehicleAvailabilityCheckConfig(currentAvailableVehicles)
                    }
                    
                    if(this.state.routeprefer === true)
                    {
                        var routeprefer = 1;
                    }
                    else{
                        var routeprefer = 0;
                    }
                    if(this.state.run_with_transit === true)
                    {
                        var run_with_transit = 1;
                    }
                    else{
                        var run_with_transit = 0;
                    }
                    if(this.state.run_with_RDD === true)
                    {
                        var run_with_RDD = 1;
                    }
                    else{
                        var run_with_RDD = 0;
                    }
        
                    //rdd commented
                    // if(this.state.run_with_transit === true && this.state.run_with_RDD === true){
                    //     var run_with_transit = 1;
                    //     var run_with_RDD = 0;
                    // }
        
                    if(this.state.run_with_transit_cityclub === true){
                        // var run_with_transit_cityclub = 1;
                    }else{
                        // var run_with_transit_cityclub = 0;
                    }
        
                    if(this.state.run_with_transit === true && this.state.run_with_transit_cityclub === true){
                        var run_with_transit = 1;
                        // var run_with_transit_cityclub = 0;
                    }
        
                    if(this.state.selectReload === true){
                        var selectReload = 1;
                    }else{
                        var selectReload = 0;
                    }
        
                    
                    
                    // console.log(inputselectedData,"inputselectedData");
                    if(localStorage.getItem('client')==='nestle'){
                        var url = window.location.host;
                        var environment = ''
                        if (url === '216.48.191.229:3011') {
                            environment='DEV'
                        }else if (url==='164.52.218.255:3009'){
                            environment='QA'
                        }else if(url==='nestle.enmovil.net'){
                            environment='UAT'
                        }else if(url==='nestle.optirun.in'){
                            environment='PROD'
                        }else{
                            environment="Local"
                        }
                            var params={}
                            params.e=localStorage.getItem('email')
                            params.environment=environment
                            params.msg='Plan Executed In'
                            params.location_code=this.state.order_id
                            params.location_name=this.state.rowData[0].location_name
                            redirectURL.post("/master/triggerEmail",params)
                            .then((response)=>{
        
                            })  
                            .catch(function (error) {
                                var errDetails={
                                    url:"/master/triggerEmail",
                                  screen:'Run New Plan',
                                  error,
                                  
                              }
                                redirectURL.post("/master/logErrorsForApiCalls",errDetails)
                                
                            })
                                                                                            
                    }
                    var params=this.state.plantConfigs
                    params.order_id=this.state.urlorder_id    
                    redirectURL.post("/master/saveConfigsWithOrder",params)
                    delete params.order_id
                    let selectedVehicles = this.gridApi3.getSelectedRows()
                    // console.log(currentAvailableVehicles,'hello')
                    var filteredTrucks = []
                    currentAvailableVehicles.map((truck)=>{
                        var check=false
                        selectedVehicles.map((item)=> {
                            if (item.vehicle_no===truck.vehicle_no){
                                check = true
                            }
                            return true
                        })
                        if (!check){
                            filteredTrucks.push(truck)
                        }
                        if (check){
                            truck.assigned_to_ot=true
                        }else{
                            truck.assigned_to_ot=false
                        }
                        return true
                    })
                    // console.log(currentAvailableVehicles,'filteredTrucks',this.state.simulationTime,)
                    var params = {
                        reconcilation: this.state.reconcilation,
                        module_type : localStorage.getItem("module_type"),
                        data_type : this.state.data_type,
                        sla : this.state.sla,
                        // location_code : this.state.transactionDetails[0].location_code,
                        location_code : this.state.order_id,
                        no_of_runs : parseInt(this.state.transactionDetails[0].no_of_runs)+1,
                        order_id : this.state.urlorder_id,
                        vehicleData : vehicleData,
                        inputselectedData : JSON.stringify(inputselectedData),
                        "opti_type" : this.state.opti_type,
                        average_speed : parseInt(this.state.average_speed),
                        time_limit : parseInt(this.state.time_limit),
                        order_date:this.state.order_date,
                        routeprefer:routeprefer,
                        gpsVehiclesSelectedData:filteredTrucks,
                        // nongpsVehiclesData:nongpsVehiclesData,
                        useremail: localStorage.getItem('email'),
                        client : localStorage.getItem('client'),
                        run_with_RDD:run_with_RDD,
                        run_with_transit:run_with_transit,
                        run_with_transit_cityclub:run_with_transit_cityclub,
                        currentAvailableVehicles:filteredTrucks,
                        no_of_spot_vehicles_given:this.state.no_of_spot_vehicles_given, 
                        plan_with_spot_vehicles:this.state.plan_with_spot_vehicles,
                        no_of_vehicles_required:this.state.no_of_vehicles_required,
                        selectReload:selectReload,
                        min_VFR:min_VFR,
                        simulation_time:this.state.simulationTime,
                        total_vehicles:currentAvailableVehicles,
                        consolidatedTrnsxId:this.state.consolidatedTrnsxId,
                        is_sunday_planning_enabled:this.state.is_sunday_planning_enabled,
                        spot_min_VFR:spot_min_VFR,
                        min_qty_threshold:min_qty_threshold
                    }
                    // if(vehicleCountGiven == 0 || vehicleCountGiven < rowCount){ for non-gps trucks
                    // || this.state.no_of_spot_vehicles_given.length >0
                    // if(currentAvailableVehicles.length>0  || this.state.no_of_spot_vehicles_given.length >0){
                    // console.log(params,'availableCheck')
        
                    let vehcnfcheck = true
                    if(!availableCheck){
                        vehcnfcheck = this.vehicleCnfCheck()
                        // console.log(vehcnfcheck,'sdfjio')
                    }
                    if(vehcnfcheck === true)
                    {
                        console.log(params,"paramssssss")
                    // if(availableCheck){spot vehicle auto considered so commented
                            redirectURL.post("/dispatch/generateLoadByConfiguration",params)
                            .then((response)=>{
                                // console.log(response,"responseFromProcess")
                                var statusCode = response.data.status;
                                if(statusCode == 8)
                                {
                                    this.setState({
                                        processErrMsg : response.data.message,
                                        processErrShow : 1,
                                        transactionId : response.data.transactionId,
                                        droppedList : response.data.droppedList,
                                        statusCode : statusCode,
                                        loadshow:'show-n',
                                        overly:'show-n',
                                        overlayBlock:"show-n",
                                    });
                                }
                                else if(statusCode > 3 && statusCode != 8)
                                {
                                    if(statusCode == 11)
                                    {
                                        var processMsg = "Use the standard input data file template for uploading loads";
                                    }
                                    else
                                    {
                                        var processMsg = response.data.message;
                                    }
                                    this.setState({
                                        processErrMsg : response.data.message,
                                        processErrShow : 1,
                                        transactionId : response.data.transactionId,
                                        statusCode : statusCode,
                                        loadshow:'show-n',
                                        overly:'show-n',
                                        overlayBlock:"show-n",
                                    });
                                }
                                else
                                {
                                    // if(this.state.run_with_transit === true && this.state.run_with_RDD === true){
                                    //     params.run_with_RDD = 1;
                                    //     params.run_with_transit = 0;
                                    //     params.no_of_runs = params.no_of_runs + 1;
                                    //     setTimeout(function(){
                                    //         redirectURL.post("/dispatch/generateLoadByConfiguration",params).then((response)=>{
                                    //         })
                                    //     },1000)
                                        
                                    // }
        
                                    if(this.state.run_with_transit === true && this.state.run_with_transit_cityclub === true){
                                        params.run_with_transit_cityclub = 1;
                                        params.run_with_transit = 0;
                                        params.no_of_runs = params.no_of_runs + 1;
                                        setTimeout(function(){                                   
                                                redirectURL.post("/dispatch/generateLoadByConfiguration",params)
                                                .then((response)=>{
                                                })
                                                .catch(function (error) {
                                                    var errDetails={
                                                        url:"/dispatch/generateLoadByConfiguration",
                                                      screen:'Run New Plan',
                                                      error,
                                                      
                                                  }
                                                    redirectURL.post("/master/logErrorsForApiCalls",errDetails)
                                                    
                                                })
                                            
                                        },1000)
                                        
                                    }
                                    
                                    this.checkTransactionStatus(response.data.transactionId);
                                }
                            })
                            .catch(function (error) {
                                var errDetails={
                                    url:"/dispatch/generateLoadByConfiguration",
                                  screen:'Run New Plan',
                                  error,
                                  
                              }
                                redirectURL.post("/master/logErrorsForApiCalls",errDetails)
                                
                            })
                        
        
                   
                        redirectURL.post("/master/updateDateFromPlan",{vehicleDetails:currentAvailableVehicles})
                        .then((response)=>{
                        })
                        .catch(function (error) {
                            var errDetails={
                                url:"/master/updateDateFromPlan",
                              screen:'Run New Plan',
                              error,
                              
                          }
                            redirectURL.post("/master/logErrorsForApiCalls",errDetails)
                            
                        })
                    
        
        
                    }
                    else{
                        this.setState({
                            // basicTitle : "There are no vehicles available, please select spot vehicles",
                            // basicType : "warning",
                            // show : true,
                            loadshow:'show-n',
                            overly:'show-n',
                            overlayBlock:"show-n",
                        })
                    }
                
                }else{
                    this.getPlanDetails();
                }
                }else{
                    this.setState({
                        basicTitle : "Please Select Plan Preference",
                        basicType : "warning",
                        show : true,
                        loadshow:'show-n',
                        overly:'show-n',
                        overlayBlock:"show-n",
                    })
                }
            }else{
                this.setState({
                    show: true,
                    basicTitle:"Please Select Date and Run",
                    basicType: "warning"
                })
            }
        } else{
            if(this.state.plan_with_spot_vehicles === 1){
                if(this.state.no_of_spot_vehicles_given.length == 0){
                    return this.setState({
                        basicTitle : "Please Select Spot Vehicles",
                        basicType : "warning",
                        show : true,
                        loadshow:'show-n',
                        overly:'show-n',
                        overlayBlock:"show-n",
                    })
                }
            }
            if(this.state.run_with_transit !== false || this.state.run_with_RDD !== false || this.state.run_with_transit_cityclub !== false){
                var run_with_transit_cityclub=0
                var min_VFR=0
                var spot_min_VFR = 0
                var min_qty_threshold = 0
                // await redirectURL.post("/master/getPlantwiseConfigs")
                // .then((response)=>{
                //     var data=response.data[0][this.state.order_id].Outbound 
                run_with_transit=this.state.plantConfigs['Prioritize Multi City Dealers First in a Vehicle'].value
                    min_VFR=this.state.plantConfigs['Min VFR Per Vehicle Type'].value
                    spot_min_VFR=this.state.plantConfigs['Min VFR Per Spot Vehicle'].value
                    min_qty_threshold = this.state.plantConfigs['MOQ for Planning'].value
                // })
            var cnf = window.confirm("Are you sure you want to proceed?");
            if(cnf === true)
            {
                this.setState({
                    loadshow:'show-m',
                    overly:'show-m',
                    overlayBlock:"show-m",
                })
                redirectURL.post("/master/logVehicleTimeEdit",this.state.updatedVehicles)
                let logParams = {
                    "location_code" : this.state.order_id,
                    // "location_name" : this.state.location.label,
                    "user_name" : localStorage.getItem("username"),
                    "useremail" : localStorage.getItem("email"),
                    "client" : localStorage.getItem("client"),
                    "screen" : "Run New Plan",
                    "activity" : 'Run Plan',
                    "event" : "Run Plan",
                    "data_type" : this.state.data_type,
                }
                
                    redirectURL.post("/master/logUserUpdateOrSaveDataActivities",logParams)
                    .then(()=>{})
                    .catch(function (error) {
                        var errDetails={
                            url:"/master/logUserUpdateOrSaveDataActivities",
                          screen:'Run New Plan',
                          error,
                          
                      }
                        redirectURL.post("/master/logErrorsForApiCalls",errDetails)
                        
                    })
                
                var vehicleData = [];
                this.gridApi.forEachNode(function (rowNode, index) {
                    // console.log("rowNode Run ", rowNode)
                    var data = rowNode.data;
                    vehicleData.push(data);
                });
                var inputselectedData = [];
                this.gridApi2.forEachNode(function (rowNode, index) {
                    var data = rowNode.data;
                    delete data._id;
                    // delete data.valid_data;
                    inputselectedData.push(data);
                });
    
    
                // commented as multi select is removed
                // var gpsVehiclesSelectedData = []
                // gpsVehiclesSelectedData = this.gridApi3.getSelectedRows()
    
    
                var currentAvailableVehicles = []
                this.gridApi3.forEachNode(function (rowNode, index) {
                    var data = rowNode.data;
                    delete data._id;
                    // delete data.valid_data;
                    currentAvailableVehicles.push(data);
                });
            await this.setState({
              masterVehicleAvailabilityData: currentAvailableVehicles,
            });
            await this.getPlanningConsideredVehicles();
                var nongpsVehiclesData = [];
                // this.gridApi4.forEachNode(function (rowNode, index) {
                //     var data = rowNode.data;
                //     delete data._id;
                //     // delete data.valid_data;
                //     if(data.vehicle_available_count === undefined ){
                //         data.vehicle_available_count = 0;
                //         vehicleCountGiven += 1
                //     }
                //     nongpsVehiclesData.push(data);
                // });
                
                //for testing team added this 
                if(this.state.simulationTime != ''){
                    var availableCheck = true
                }else{
                    var availableCheck =await this.vehicleAvailabilityCheckConfig(currentAvailableVehicles)
                }
                
                if(this.state.routeprefer === true)
                {
                    var routeprefer = 1;
                }
                else{
                    var routeprefer = 0;
                }
                if(this.state.run_with_transit === true)
                {
                    var run_with_transit = 1;
                }
                else{
                    var run_with_transit = 0;
                }
                if(this.state.run_with_RDD === true)
                {
                    var run_with_RDD = 1;
                }
                else{
                    var run_with_RDD = 0;
                }
    
                //rdd commented
                // if(this.state.run_with_transit === true && this.state.run_with_RDD === true){
                //     var run_with_transit = 1;
                //     var run_with_RDD = 0;
                // }
    
                if(this.state.run_with_transit_cityclub === true){
                    // var run_with_transit_cityclub = 1;
                }else{
                    // var run_with_transit_cityclub = 0;
                }
    
                if(this.state.run_with_transit === true && this.state.run_with_transit_cityclub === true){
                    var run_with_transit = 1;
                    // var run_with_transit_cityclub = 0;
                }
    
                if(this.state.selectReload === true){
                    var selectReload = 1;
                }else{
                    var selectReload = 0;
                }
    
                
                
                // console.log(inputselectedData,"inputselectedData");
                if(localStorage.getItem('client')==='nestle'){
                    var url = window.location.host;
                    var environment = ''
                    if (url === '216.48.191.229:3011') {
                        environment='DEV'
                    }else if (url==='164.52.218.255:3009'){
                        environment='QA'
                    }else if(url==='nestle.enmovil.net'){
                        environment='UAT'
                    }else if(url==='nestle.optirun.in'){
                        environment='PROD'
                    }else{
                        environment="Local"
                    }
                        var params={}
                        params.e=localStorage.getItem('email')
                        params.environment=environment
                        params.msg='Plan Executed In'
                        params.location_code=this.state.order_id
                        params.location_name=this.state.rowData[0].location_name
                        redirectURL.post("/master/triggerEmail",params)
                        .then((response)=>{
    
                        })  
                        .catch(function (error) {
                            var errDetails={
                                url:"/master/triggerEmail",
                              screen:'Run New Plan',
                              error,
                              
                          }
                            redirectURL.post("/master/logErrorsForApiCalls",errDetails)
                            
                        })
                                                                                        
                }
                var params=this.state.plantConfigs
                params.order_id=this.state.urlorder_id    
                redirectURL.post("/master/saveConfigsWithOrder",params)
                delete params.order_id
                let selectedVehicles = this.gridApi3.getSelectedRows()
                // console.log(currentAvailableVehicles,'hello')
                var filteredTrucks = []
                currentAvailableVehicles.map((truck)=>{
                    var check=false
                    selectedVehicles.map((item)=> {
                        if (item.vehicle_no===truck.vehicle_no){
                            check = true
                        }
                        return true
                    })
                    if (!check){
                        filteredTrucks.push(truck)
                    }
                    if (check){
                        truck.assigned_to_ot=true
                    }else{
                        truck.assigned_to_ot=false
                    }
                    return true
                })
                // console.log(currentAvailableVehicles,'filteredTrucks',this.state.simulationTime,)
                var params = {
                    reconcilation: this.state.reconcilation,
                    module_type : localStorage.getItem("module_type"),
                    data_type : this.state.data_type,
                    sla : this.state.sla,
                    // location_code : this.state.transactionDetails[0].location_code,
                    location_code : this.state.order_id,
                    no_of_runs : parseInt(this.state.transactionDetails[0].no_of_runs)+1,
                    order_id : this.state.urlorder_id,
                    vehicleData : vehicleData,
                    inputselectedData : JSON.stringify(inputselectedData),
                    "opti_type" : this.state.opti_type,
                    average_speed : parseInt(this.state.average_speed),
                    time_limit : parseInt(this.state.time_limit),
                    order_date:this.state.order_date,
                    routeprefer:routeprefer,
                    gpsVehiclesSelectedData:filteredTrucks,
                    // nongpsVehiclesData:nongpsVehiclesData,
                    useremail: localStorage.getItem('email'),
                    client : localStorage.getItem('client'),
                    run_with_RDD:run_with_RDD,
                    run_with_transit:run_with_transit,
                    run_with_transit_cityclub:run_with_transit_cityclub,
                    currentAvailableVehicles:filteredTrucks,
                    no_of_spot_vehicles_given:this.state.no_of_spot_vehicles_given, 
                    plan_with_spot_vehicles:this.state.plan_with_spot_vehicles,
                    no_of_vehicles_required:this.state.no_of_vehicles_required,
                    selectReload:selectReload,
                    min_VFR:min_VFR,
                    simulation_time:this.state.simulationTime,
                    total_vehicles:currentAvailableVehicles,
                    consolidatedTrnsxId:this.state.consolidatedTrnsxId,
                    is_sunday_planning_enabled:this.state.is_sunday_planning_enabled,
                    spot_min_VFR:spot_min_VFR,
                    min_qty_threshold:min_qty_threshold
                }
                // if(vehicleCountGiven == 0 || vehicleCountGiven < rowCount){ for non-gps trucks
                // || this.state.no_of_spot_vehicles_given.length >0
                // if(currentAvailableVehicles.length>0  || this.state.no_of_spot_vehicles_given.length >0){
                // console.log(params,'availableCheck')
    
                let vehcnfcheck = true
                if(!availableCheck){
                    vehcnfcheck = this.vehicleCnfCheck()
                    // console.log(vehcnfcheck,'sdfjio')
                }
                if(vehcnfcheck === true)
                {
                    console.log(params,"paramssssss")
                // if(availableCheck){spot vehicle auto considered so commented
                        redirectURL.post("/dispatch/generateLoadByConfiguration",params)
                        .then((response)=>{
                            // console.log(response,"responseFromProcess")
                            var statusCode = response.data.status;
                            if(statusCode == 8)
                            {
                                this.setState({
                                    processErrMsg : response.data.message,
                                    processErrShow : 1,
                                    transactionId : response.data.transactionId,
                                    droppedList : response.data.droppedList,
                                    statusCode : statusCode,
                                    loadshow:'show-n',
                                    overly:'show-n',
                                    overlayBlock:"show-n",
                                });
                            }
                            else if(statusCode > 3 && statusCode != 8)
                            {
                                if(statusCode == 11)
                                {
                                    var processMsg = "Use the standard input data file template for uploading loads";
                                }
                                else
                                {
                                    var processMsg = response.data.message;
                                }
                                this.setState({
                                    processErrMsg : response.data.message,
                                    processErrShow : 1,
                                    transactionId : response.data.transactionId,
                                    statusCode : statusCode,
                                    loadshow:'show-n',
                                    overly:'show-n',
                                    overlayBlock:"show-n",
                                });
                            }
                            else
                            {
                                // if(this.state.run_with_transit === true && this.state.run_with_RDD === true){
                                //     params.run_with_RDD = 1;
                                //     params.run_with_transit = 0;
                                //     params.no_of_runs = params.no_of_runs + 1;
                                //     setTimeout(function(){
                                //         redirectURL.post("/dispatch/generateLoadByConfiguration",params).then((response)=>{
                                //         })
                                //     },1000)
                                    
                                // }
    
                                if(this.state.run_with_transit === true && this.state.run_with_transit_cityclub === true){
                                    params.run_with_transit_cityclub = 1;
                                    params.run_with_transit = 0;
                                    params.no_of_runs = params.no_of_runs + 1;
                                    setTimeout(function(){                                   
                                            redirectURL.post("/dispatch/generateLoadByConfiguration",params)
                                            .then((response)=>{
                                            })
                                            .catch(function (error) {
                                                var errDetails={
                                                    url:"/dispatch/generateLoadByConfiguration",
                                                  screen:'Run New Plan',
                                                  error,
                                                  
                                              }
                                                redirectURL.post("/master/logErrorsForApiCalls",errDetails)
                                                
                                            })
                                        
                                    },1000)
                                    
                                }
                                
                                this.checkTransactionStatus(response.data.transactionId);
                            }
                        })
                        .catch(function (error) {
                            var errDetails={
                                url:"/dispatch/generateLoadByConfiguration",
                              screen:'Run New Plan',
                              error,
                              
                          }
                            redirectURL.post("/master/logErrorsForApiCalls",errDetails)
                            
                        })
                    
    
               
                    redirectURL.post("/master/updateDateFromPlan",{vehicleDetails:currentAvailableVehicles})
                    .then((response)=>{
                    })
                    .catch(function (error) {
                        var errDetails={
                            url:"/master/updateDateFromPlan",
                          screen:'Run New Plan',
                          error,
                          
                      }
                        redirectURL.post("/master/logErrorsForApiCalls",errDetails)
                        
                    })
                
    
    
                }
                else{
                    this.setState({
                        // basicTitle : "There are no vehicles available, please select spot vehicles",
                        // basicType : "warning",
                        // show : true,
                        loadshow:'show-n',
                        overly:'show-n',
                        overlayBlock:"show-n",
                    })
                }
            
            }else{
                this.getPlanDetails();
            }
            }else{
                this.setState({
                    basicTitle : "Please Select Plan Preference",
                    basicType : "warning",
                    show : true,
                    loadshow:'show-n',
                    overly:'show-n',
                    overlayBlock:"show-n",
                })
            }
        }
    }
    onClickTab = (pageType) => {
        var data_type = this.state.data_type;
        var planConfig='',planConfigData='show-n'
        if(pageType == "config")
        {
            var config = "activet";
            var inputInfo = "";
            var configData = "show-m";
            var inputInfoData = "show-n";
            var vehicleinfo = "";
            var vehicleinfoData = "show-n";
            var nongpsvehicleinfoData = "show-n";
            var nongpsvehicleinfo = "";
            planConfig=''
            planConfigData='show-n'
        }
        else if(pageType == "inputInfo")
        {
            var config = "";
            var inputInfo = "activet";
            var configData = "show-n";
            var vehicleinfo = "";
            var vehicleinfoData = "show-n";
            var inputInfoData = "show-m";
            var nongpsvehicleinfoData = "show-n";
            var nongpsvehicleinfo = "";
            planConfig=''
            planConfigData='show-n'
        }
        
        else if(pageType == "vehicles")
        {
            var config = "";
            var inputInfo = "";
            var configData = "show-n";
            var inputInfoData = "show-n";
            var vehicleinfoData = "show-m";
            var vehicleinfo = "activet";
            var params ={
                data_type : data_type

            };
            planConfig=''
            planConfigData='show-n'
            // this.getVehicleAvailability(params);
            // this.getAssignVehiclesNearPlant()
            params.plant_code = this.state.transactionDetails[0].location_code;
            this.getMasterAvailabilityVehiclesData(params)

        }

        else if(pageType == "nongpsvehicles")
        {
            var config = "";
            var inputInfo = "";
            var configData = "show-n";
            var inputInfoData = "show-n";
            var nongpsvehicleinfoData = "show-m";
            var nongpsvehicleinfo = "activet";
            planConfig=''
            planConfigData='show-n'
        }else if(pageType === 'planconfigs'){
            this.getPlantConfigs()
             planConfig='activet'
             planConfigData='show-m'
            // var config = this.state.config;
            // var inputInfo = this.state.inputInfo;
            // var configData = this.state.configData;
            // var inputInfoData = this.state.inputInfoData;
            // var nongpsvehicleinfoData = this.state.nongpsvehicleinfoData;
            // var nongpsvehicleinfo = this.state.nongpsvehicleinfo;
            // var vehicleinfoData=this.state.vehicleinfoData
            // var vehicleinfo=this.state.vehicleinfo
            var config = "";
            var inputInfo = "";
            var configData = "show-n";
            var inputInfoData = "show-n";
            var nongpsvehicleinfoData = "show-n";
            var nongpsvehicleinfo = "";
            var vehicleinfo = "";
            var vehicleinfoData = "show-n";
        }
        this.setState({
            config:config,
            inputInfo:inputInfo,
            configData:configData,
            inputInfoData:inputInfoData,
            vehicleinfo:vehicleinfo,
            vehicleinfoData:vehicleinfoData,
            nongpsvehicleinfo:nongpsvehicleinfo,
            nongpsvehicleinfoData:nongpsvehicleinfoData,
            planConfig,
            planConfigData
        })
    }
    getVehicleAvailability(params){
        params.plant_location_code = this.state.transactionDetails[0].location_code;
        let toolConfig = JSON.parse(localStorage.getItem('tlcfg'))
        if(toolConfig !=undefined && toolConfig !="")
        {
            if(toolConfig.length > 0)
            {
                if(params.radius === undefined){

                    if(toolConfig[0].gps_truck_location_vicinity !== "" && toolConfig[0].gps_truck_location_vicinity !== undefined)
                    {
                        params.radius = parseFloat(toolConfig[0].gps_truck_location_vicinity)/1000;
                        this.setState({truck_location_vicinity:toolConfig[0].gps_truck_location_vicinity})
                    }
                    else{
                        params.radius = 0.5;
                    }
                }
                
            }
        }
        params.gps_records_flag = 0;
       
       
            redirectURL.post('master/fetchTrucksNearPickupLocation',params)
            .then(async (response) =>{
                // console.log("response.data ", response.data)
                if(response.data.status != "Failed"){
                let vehicleAvailabilityData = response.data;
                // vehicleAvailabilityData.map(item => item.vehicle_date = moment.parseZone(item.vehicle_date).format("YYYY-MM-DD"))
                await this.setState({
                    vehicleDetails:vehicleAvailabilityData
                })
                }
            })
            .catch(function (error) {
                var errDetails={
                    url:"master/fetchTrucksNearPickupLocation",
                  screen:'Run New Plan',
                  error,
                  
              }
                redirectURL.post("/master/logErrorsForApiCalls",errDetails)
                
            })
        
    }

    updateOptimizationType = (e) =>{
        let logParams = {
            "location_code" : this.state.order_id,
            // "location_name" : this.state.location.label,
            "user_name" : localStorage.getItem("username"),
            "useremail" : localStorage.getItem("email"),
            "client" : localStorage.getItem("client"),
            "screen" : "Run Plan",
            "activity" : 'clicked on Optimize by distance check box',
            "event" : "No change",
            "data_type" : this.state.data_type,
        }
    
        redirectURL.post("/master/loguserUpdatesorChanges",logParams)
        let name = e.target.name
        if(name == "opti_root" ){
            this.setState({opti_type:0})
        }else{
            this.setState({opti_type:1,
            openTimeOptimizationParamModel:true})
        }
    }

    updateRoutePrefer = (e) =>{
        let name = e.target.name
        if(name == 'run_with_transit'){
            let logParams = {
                "location_code" : this.state.location.value,
            "location_name" : this.state.location.label,
                "user_name" : localStorage.getItem("username"),
                "useremail" : localStorage.getItem("email"),
                "client" : localStorage.getItem("client"),
                "screen" : "Run Plan",
                "activity" : 'clicked on transit time multiple city clubbing check box',
                "event" : "checked/unchecked",
                "data_type" : this.state.data_type,
            }
        
            redirectURL.post("/master/loguserUpdatesorChanges",logParams)
            this.setState({
                run_with_transit:!this.state.run_with_transit
            })
        }else if(name == 'run_with_RDD'){
            this.setState({
                run_with_RDD:!this.state.run_with_RDD
            })
        }else if(name == 'routeprefer'){
            this.setState({
                routeprefer:!this.state.routeprefer
            })
        }else if(name == 'run_with_transit_cityclub'){
            let logParams = {
                "location_code" : this.state.order_id,
            // "location_name" : this.state.location.label,
                "user_name" : localStorage.getItem("username"),
                "useremail" : localStorage.getItem("email"),
                "client" : localStorage.getItem("client"),
                "screen" : "Run Plan",
                "activity" : 'clicked on transit time single city clubbing check box',
                "event" : "checked/unchecked",
                "data_type" : this.state.data_type,
            }
        
            redirectURL.post("/master/loguserUpdatesorChanges",logParams)
            this.setState({
                run_with_transit_cityclub:!this.state.run_with_transit_cityclub
            })
        }
    }

    materialDetailsInputFields = (e) =>{
        let name = e.target.name
        let value = e.target.value
        this.setState({ [name] : value })
    }
    
        onClickOk=()=>{
            // console.log('hello',this.state.no_of_vehicles_required)
            if(this.state.no_of_vehicles_required>0){
            this.setState({noOfSpotVch:true})
            }
        }
    handelMaterialConfigurationModelCancel = ()=>{
        this.setState(prevState =>({
            openTimeOptimizationParamModel :!prevState.openTimeOptimizationParamModel,
            time_limit:72,
            average_speed: 60,
            
        }))

    }

    saveTimeOptimizationParams = () =>{
        
        this.setState(prevState =>({
            openSpotVehicleModel :!prevState.openSpotVehicleModel,
            
        }))
    }
    
    handleSpotVehicleRequired = async() =>{
        let logParams = {
            // "location_code" : location.value,
            // "location_name" : location.label,
            "user_name" : localStorage.getItem("username"),
            "useremail" : localStorage.getItem("email"),
            "client" : localStorage.getItem("client"),
            "screen" : "Run New Plan ",
            "activity" : 'clicked on Spot Vehicle Required Button',
            "event" : "Modal Opened",
            // "data_type" : this.state.data_type,
        }
    
        redirectURL.post("/master/loguserUpdatesorChanges",logParams)
        // console.log('hlello')
         
        // this.getPlantConfigs()
        if(this.state.plan_with_spot_vehicles==1){
            await this.setState({
                plan_with_spot_vehicles:0,
                openSpotVehicleModel:false,
                no_of_spot_vehicles_given:[],
                no_of_vehicles_required:0,
                spotTrucks:{}
            })
        }else{
            await this.setState({plan_with_spot_vehicles:1,
            openSpotVehicleModel:true,
            noOfSpotVch:false
        })
        }
    }

    onChangeVFR=async(name, selOption)=>{
        this.setState({ [name]: selOption });
    }

    saveNoOfSpotVehicles = (e) =>{
        
        e.preventDefault()
        var check=false
        this.state.no_of_spot_vehicles_given.map((item)=>{
            if(item.available_date_time===""){
                check=true
            }
        })
        if (check){
            alert("please fill all availability fields")
        }else{
        if (this.state.noOfSpotVch){
        this.setState(prevState =>({
            openSpotVehicleModel :!prevState.openSpotVehicleModel,
            
        }))
        }
     }
    }

    handelSpotVehicleModelCancel = ()=>{
       
        this.setState(prevState =>({
            openSpotVehicleModel :!prevState.openSpotVehicleModel,
            no_of_spot_vehicles_given:[],  
            plan_with_spot_vehicles:0,
            no_of_vehicles_required:0,
            spotTrucks:{}          
        }))
    

    }

    handelconfigModelCancel = ()=>{
       
        this.setState(prevState =>({
            openConfigModel:false          
        }))
    

    }

    checkCellValidation(rownode){
        console.log("checkCellValidation ", rownode)
    }

    changeHandler(event){
        var name= event.target.name;
        var value= event.target.value;
        let radius = 1000
        let toolConfig = JSON.parse(localStorage.getItem('tlcfg'))
        if(toolConfig !=undefined && toolConfig !="")
        {
            if(toolConfig.length > 0)
            {
                if(toolConfig[0].gps_truck_location_vicinity !== "" && toolConfig[0].gps_truck_location_vicinity !== undefined)
                    {
                        radius = toolConfig[0].gps_truck_location_vicinity
                    }
                }
        }
        
        this.setState({
            [name]:parseInt(value)
        })
        if(value >= radius){
            var params ={
                data_type : this.state.data_type,
                radius : value/1000
            };
            this.getVehicleAvailability(params);
            document.getElementById("warning-text").innerHTML ="";
            
        }else{
            document.getElementById("warning-text").innerHTML ="**Value Should be more than the Configuration value**";
        }
    }

    getAssignVehiclesNearPlant = () =>{
       
            redirectURL.post("/dispatch/getAssignVehiclesNearPlant")
            .then((response)=>{
                if(response.data){
                    if(response.data.truck_data_for_dispatch_plan != undefined){
                        let data = JSON.parse(response.data.truck_data_for_dispatch_plan)
                        if(data.length>0){
                            let filterData = data.filter(itm => itm.plant_code == this.state.order_id && itm.latitude != null)
                            
                            this.setState({assignableVehicles:filterData})
                        }
                    }
                }
                
            })
            .catch(function (error) {
                var errDetails={
                    url:"/dispatch/getAssignVehiclesNearPlant",
                  screen:'Run New Plan',
                  error,
                  
              }
                redirectURL.post("/master/logErrorsForApiCalls",errDetails)
                
            })
       
    }

    getMasterAvailabilityVehiclesData = async(params) =>{
        let availableVehicles = [];
            redirectURL.post('master/getMasterAvailabilityVehiclesData',params)
            .then(async (response) =>{
                if(response.data.status == 'success'){
                    // Get the current date and time
                    // const currentDate = new Date();
                    // console.log(currentDate.toISOString())
                    // if (currentDate.getHours() >= 0 && currentDate.getHours() < 18) {
                    // const today = currentDate.toISOString().split('T')[0]; 
                    // const tomorrow = new Date();
                    // const third_day=new Date()
                    // tomorrow.setDate(currentDate.getDate() + 1);
                    // third_day.setDate(currentDate.getDate() + 2);
                    // const nextDay = tomorrow.toISOString().split('T')[0];
                    // const thirdDay = third_day.toISOString().split('T')[0];
                    // // console.log(typeof third_day)
                    // // const endTime=new Date(third_day).setHours(18)
                    // // const timeCheck=third_day.getHours()
                    // // console.log(timeCheck,third_day)
                    // // console.log('2023-10-07T12:27:42.689Z')
                    // availableVehicles = masterVehicleAvailabilityData.filter(obj =>{
                    //     let endTime=new Date(obj.vehicle_next_available_date)
                    //     const timeCheck=endTime.getHours()<18
                    //     if ((obj.vehicle_next_available_date.startsWith(today) ||  obj.vehicle_next_available_date.startsWith(nextDay) ||  (obj.vehicle_next_available_date.startsWith(thirdDay)&&timeCheck))){
                    //         return obj
                    //     }
                    // }  );
                    // } 
                    // else {
                    // const tomorrow = new Date(currentDate);
                    // tomorrow.setDate(currentDate.getDate() + 1);
                    // const nextDay = tomorrow.toISOString().split('T')[0];
                    // const second_day=new Date()
                    // second_day.setDate(second_day.getDate()+2)
                    // const third_day=new Date()
                    // third_day.setDate(third_day.getDate()+3)
                    // const secondDay=second_day.toISOString().split('T')[0]
                    // const thirdDay= third_day.toISOString().split('T')[0]
                    // const timeCheck=third_day.getHours()<18
                    // // console.log(timeCheck)     
                    // var availableVehicles=[]
                    // availableVehicles = masterVehicleAvailabilityData.filter(obj => {
                    //     let endTime=new Date(obj.vehicle_next_available_date)
                    //     const timeCheck=endTime.getHours()<18
                    //    if( obj.vehicle_next_available_date.startsWith(nextDay) ||  obj.vehicle_next_available_date.startsWith(secondDay) ||  (obj.vehicle_next_available_date.startsWith(thirdDay)&&timeCheck)){
                    //     return obj
                    //    }
                    // })       
                    // }
                    // console.log(availableVehicles[0])
                    let masterVehicleAvailabilityData = response.data.masterVehicleAvailabilityData
                    masterVehicleAvailabilityData=masterVehicleAvailabilityData.filter((item)=>item.vehicle_breakdown!==1)
                                   
                        masterVehicleAvailabilityData.map((item)=>{
                            if(item.timestamp != undefined){
                                const timestamp = item.timestamp;
                                const timestampDate = new Date(timestamp);
                                const timeDifference = new Date() - timestampDate;
                                const isLessThan24Hours = timeDifference > (24 * 60 * 60 * 1000);
                                if ( isLessThan24Hours){
                                    item.gps_status_24hrs='no'
                                }else{
                                    item.gps_status_24hrs='yes'
                                }
                            }else{
                                item.gps_status_24hrs='N/A'   
                            }
                        })
                    await this.setState({
                        masterVehicleAvailabilityData},()=>this.getPlanningConsideredVehicles()
                    )
                    
            }
            })
            .catch(function (error) {
                var errDetails={
                    url:"master/getMasterAvailabilityVehiclesData",
                  screen:'Run New Plan',
                  error,
                  
              }
                redirectURL.post("/master/logErrorsForApiCalls",errDetails)
                
            })
            
    }

    vehicleTypeChanged = (vehicle_type) =>{
        this.setState({
            vehicle_type : vehicle_type
        })
    }

    collectSpotVehicleData =async (data) => {
        let spot=[] ;
        var spotTrucks=this.state.spotTrucks
        var key=data.id
        spotTrucks[key]=data
        await this.setState({spotTrucks})
        var no_of_spot_vehicles_given=[]
        for (var i of Object.keys(spotTrucks)){
            no_of_spot_vehicles_given.push(spotTrucks[i])
        }
        await this.setState({no_of_spot_vehicles_given})
        // if(data.vehicle_type != '' && data.available_date_time != ''){
        //     spot.push(data)
        
        // this.setState((prevState) => {
        //     if(prevState.no_of_spot_vehicles_given.length>=this.state.no_of_vehicles_required){
        //         let inputData = [...prevState.no_of_spot_vehicles_given, ...spot].slice(-this.state.no_of_vehicles_required)
        //         // console.log(inputData,'inpu')
        //         return {  no_of_spot_vehicles_given:inputData }
        //     }else{
        //         // console.log('impe',[...prevState.no_of_spot_vehicles_given, ...spot])
        //         return {  no_of_spot_vehicles_given: [...prevState.no_of_spot_vehicles_given, ...spot]}
        //     }
            
        // });
        //  }
      };

    //   handleSelectReload = () => {
    //     this.setState((prevState) => ({
    //         selectReload : !prevState.selectReload
    //     }))
    //   }
    
      onCellValueChanged=async (params)=> {
        if (params.colDef.field === "vehicle_next_available_date") {
           
            const inputValue = params.newValue;
            const dateToValidate = inputValue;
            if (params.newValue!==params.oldValue && validateDate(dateToValidate)){
                await this.getPlanningConsideredVehicles()
                // var vehicle_no=params.data.vehicle_no
                // var transitVehicles=this.state.transitVehicles 
                // var filterVehicles=transitVehicles.filter((truck)=>truck.vehicle_no===vehicle_no)
                // var returnPlantTime = getHyphenYYYYMMDDHHMMSS(filterVehicles[0].vehicle_next_availble_time)
                // // console.log(inputValue,'ll',getHyphenYYYYMMDDHHMMSS(filterVehicles[0].vehicle_next_availble_time)<inputValue,'lll',filterVehicles[0].vehicle_next_availble_time)
                // if ( inputValue < returnPlantTime){
                //     // console.log('bigger')
                //     this.setState({
                //         show:true,
                //         basicTitle:'Given Time is less than next avbl Time',
                //         basicType:'info'
                //     })
                // }
                let logParams = {
                    "vehicle_no": params.data.vehicle_no,
                    "process": "Vehicle manual update from Run Plan screen",
                    "vehicle_next_available_date": params.newValue,
                    "previous_available_date":params.oldValue,
                    "plant": this.state.order_id,
                    "updated_by": "user",
 
                }
                
                
                await this.setState((perState)=>({
                    updatedVehicles: [...perState.updatedVehicles,logParams]
                }))
                // console.log(this.state.updatedVehicles,this.state.location)
            }
        if (validateDate(dateToValidate)) {
            // console.log(`${dateToValidate} is a valid date.`);
        } else {
            // console.log(`${dateToValidate} is not a valid date.`);
            params.data.vehicle_next_available_date = params.oldValue

        }
          }
      }
      handlerStartDateTime=async (name,event)=>{
        
        var d = getHyphenYYYYMMDDHHMMSS(event._d);
        await this.setState({[name]:d})
        this.enablesundayplanning()
      }

      
      getPlantConfigs= async()=>{
        var plantConfigs=[]
        await redirectURL.post("/master/getPlantwiseConfigs")
            .then(async (response) => {
                 plantConfigs=response.data[0][this.state.order_id].Outbound
                 
            })
        this.setState({openSavedConfigs:true,plantConfigs:plantConfigs})
      }
      savePlantConfigs=(event)=>{
        event.preventDefault()
        var configs=[]
        var userType=localStorage.getItem("user_type") === "admin";
        if(userType){
        redirectURL.post("/master/getPlantwiseConfigs")
            .then(async (response) => {
                configs=response.data[0][this.state.order_id].Outbound
                var newConfigs=this.state.plantConfigs
                Object.keys(newConfigs).map((item)=>{
                    configs[item]=newConfigs[item]
                })
                var outboundRec=configs
                outboundRec.location = this.state.order_id
                outboundRec.name = 'Outbound'
                
                redirectURL.post('master/savePlantWiseConfigs', outboundRec)
                this.setState({spotButtonVisble:configs['Plan Spot Vehicles'].value,
                    basicTitle : 'Configs Updated',
                    basicType : "info",
                    show : true,
                    loadshow:'show-n',
                    overly:'show-n',
                    })
                
            })}
        this.setState({openSavedConfigs:false ,
        })
      }
       
//   onClickConfigs=async()=>{
//     var configs=[]
//     await redirectURL.post("/master/getorderConfigs",{order_id:this.state.orderId})
//     .then( (response)=>{
//       configs=response.data
//     })
//     await this.setState({openSavedConfigs:true,
//       configs:configs
//     })
//   }
  
  onClickCloseConfigs=()=>{
    this.setState({openSavedConfigs:false})
  }

  handlesundayplanning = () => {
    
    if(this.state.is_sunday_planning_enabled == 1){
        this.setState({is_sunday_planning_enabled:0})
    }else{
        this.setState({is_sunday_planning_enabled:1})
    }
    
  }
  handleReconcilation = (e) => {
    this.setState({ reconcilation: e.target.checked }, ()=>{

        console.log(e.target.checked,"reconcilationreconcilation")
    });
  }
    render(){
        const {t} = this.props
        var material_configuration_chk = 0;
        var monthly_distance_preferences = 0;
        var toolConfig = JSON.parse(localStorage.getItem('tlcfg'))
        if(toolConfig !=undefined && toolConfig !="")
        {
            if(toolConfig.length > 0)
            {
                material_configuration_chk = toolConfig[0].material_configuration;
                monthly_distance_preferences = toolConfig[0].monthly_distance_preferences;
            }
        }
        // var inputDetailscolsDefs = [
        //     {
        //         headerName: "Pickup Location (Source)",
        //         field: "pickup_location_code",
        //         width:130,
        //         filter: true,
        //         resizable: true,
        //         editable:false           
        //     },
        //     {
        //         headerName: "Drop Location (Destination)",
        //         field: "drop_location_code",
        //         width:130,
        //         filter: true,
        //         resizable: true,
        //         editable:false
        //     },
        //     {
        //         headerName:"Order Number",
        //         field:"order_number",
        //         width:"170",
        //     },
        //     {
        //         headerName:"Invoicing Date",
        //         field:"invoicing_date",
        //         width:"130",
        //     },
        //     {
        //         headerName:"Quantity",
        //         field:"quantity",
        //         width:"100",
        //         valueGetter:function(params){
        //             if ( params.data.quantity!=='' && params.data.quantity!=='undefined' && params.data.quantity!== undefined){
        //                 return (parseFloat(params.data.quantity)).toFixed(2)
        //             }
        //         }                  
        //     },
        //     {
        //         headerName: "Demand (CFT)",
        //         field: "demand_cmt",
        //         width:110,
        //         filter: true,
        //         resizable: true,
        //         editable:false
        //     },
        //     {
        //         headerName: "Weight (Pounds)",
        //         field: "weight_tons",
        //         width:110,
        //         filter: true,
        //         resizable: true,
        //         editable:false
        //     },
        //     {
        //         headerName:"Dealer Available From",
        //         field:"dealer_available_start",
        //         width:140,
        //     },
        //     {
        //         headerName:"Dealer Available To",
        //         field:"dealer_available_end",
        //         width:"140",
        //     },
        //     {
        //         headerName:"Delivery Date",
        //         field:"delivery_date",
        //         width:"120",
        //     },
        //     // {
        //     //     headerName: "Order Date",
        //     //     field: "order_date",
        //     //     width:200,
        //     //     filter: true,
        //     //     resizable: true,
        //     //     editable:false,
        //     //     valueGetter:function(params){
        //     //         try {
        //     //             if(params.data.order_date !== "" && params.data.order_date !== undefined)
        //     //             {
        //     //                 return getHyphenDDMMMYYYY(params.data.order_date);
        //     //             }
        //     //             else{
        //     //                 return "";
        //     //             }
        //     //         } catch (error) {
                        
        //     //         }
        //     //     }
        //     // }
        // ]

        var inputDetailscolsDefs = [
            {
                headerName: t('pickup_location_source'),
                field: "pickup_location_code",
                width: 130,
                filter: true,
                resizable: true,
                editable: false           
            },
            {
                headerName: t('drop_location_destination'),
                field: "drop_location_code",
                width: 130,
                filter: true,
                resizable: true,
                editable: false
            },
            {
                headerName: t('order_number'),
                field: "order_number",
                width: "170",
            },
            {
                headerName: t('invoicing_date'),
                field: "invoicing_date",
                width: "130",
            },
            {
                headerName: t('quantity'),
                field: "quantity",
                width: "100",
                valueGetter: function(params) {
                    if (params.data.quantity !== '' && params.data.quantity !== 'undefined' && params.data.quantity !== undefined) {
                        return (parseFloat(params.data.quantity)).toFixed(2);
                    }
                }                  
            },
            {
                headerName: t('demand_cft'),
                field: "demand_cmt",
                width: 110,
                filter: true,
                resizable: true,
                editable: false
            },
            {
                headerName: t('weight_pounds'),
                field: "weight_tons",
                width: 110,
                filter: true,
                resizable: true,
                editable: false
            },
            {
                headerName: t('dealer_available_from'),
                field: "dealer_available_start",
                width: 140,
            },
            {
                headerName: t('dealer_available_to'),
                field: "dealer_available_end",
                width: "140",
            },
            {
                headerName: t('delivery_date'),
                field: "delivery_date",
                width: "120",
            },
            // {
            //     headerName: t('order_date'),
            //     field: "order_date",
            //     width: 200,
            //     filter: true,
            //     resizable: true,
            //     editable: false,
            //     valueGetter: function(params) {
            //         try {
            //             if(params.data.order_date !== "" && params.data.order_date !== undefined) {
            //                 return getHyphenDDMMMYYYY(params.data.order_date);
            //             } else {
            //                 return "";
            //             }
            //         } catch (error) {
            //         }
            //     }
            // }
        ]
        
        if(material_configuration_chk == 1)
        {
            inputDetailscolsDefs.push(
                {
                    headerName: t('material'),
                    field:"material_code",
                    width:"250",
                },
                {
                    headerName: t('quantity'),
                    field:"quantity",
                    width:"250",
                }
            );
        }
        // else
        // {
        //     inputDetailscolsDefs.push(
        //         {
        //             headerName:"No of Passengers",
        //             field:"demand_cmt",
        //             width:"250",
        //         }
        //     );
        // }
        var vehiclecolsDefs = [
            {
                headerName:"Vehicle Number",
                field:"vehicle_no",
                width:"200",
                checkboxSelection: true,
                headerCheckboxSelection: true,
            },
            {
                headerName:"Vehicle Type",
                field:"vehicle_type",
                width:"150"
            },
            {
                headerName:"Transporter Code",
                field:"transporter_code",
                width:"150"
            },
            {
                headerName:"Transporter Name",
                field:"transporter_name",
                width:"200"
            },
            {
                headerName:"Last Packet Time",
                field:"timestamp",
                width:"200",
                valueGetter:function(params){
                    try {
                        if(params.data.timestamp !== "" && params.data.timestamp !== undefined)
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.timestamp);
                        }
                        else{
                            return "";
                        }
                    } catch (error) {
                        
                    }
                }
            },
        ];

        // var assignableVehiclecolsDefs = [
        //     {
        //         headerName:"Vehicle Number",
        //         // field:"truck_no",
        //         field:'vehicle_no',
        //         width:"130",
        //         // checkboxSelection: true,
        //         // headerCheckboxSelection: true,
        //         cellClass:function(params){
        //             try {
        //                 // console.log('edi',params.data.veh_considered_for_planning)
        //                 if(params.data.veh_considered_for_planning != undefined && params.data.veh_considered_for_planning != 'no'){
        //                     return "gHighlight";
        //                 }else{
        //                     if(params.data.gps_status_24hrs != undefined && params.data.gps_status_24hrs != ""){
        //                     if(params.data.gps_status_24hrs == 'no' || params.data.gps_status_24hrs == 'N/A'){
        //                         return "redbg";
        //                     }
                                
        //                     }else{
        //                         return "redbg";
        //                     }
        //                 }
        //             } catch (error) {
                        
        //             }
        //         }
        //     },
        //     {
        //         headerName:"Vehicle Type",
        //         field:"vehicle_type",
        //         width:"150",
        //         cellClass:function(params){
        //             try {
        //                 if(params.data.veh_considered_for_planning != undefined && params.data.veh_considered_for_planning != 'no'){
        //                     return "gHighlight";
        //                 }else{
        //                     if(params.data.gps_status_24hrs != undefined && params.data.gps_status_24hrs != ""){
        //                         if(params.data.gps_status_24hrs == 'no' || params.data.gps_status_24hrs == 'N/A'){
        //                         return "redbg";
        //                     }
                                
        //                     }else{
        //                         return "redbg";
        //                     }
        //                 }
        //             } catch (error) {
                        
        //             }
        //         }
        //     },
        //     {
        //         headerName:"Transit Type",
        //         field:"transit_type",
        //         width:"130",
        //         cellClass:function(params){
        //             try {
        //                 if(params.data.veh_considered_for_planning != undefined && params.data.veh_considered_for_planning != 'no'){
        //                     return "gHighlight";
        //                 }else{
        //                     if(params.data.gps_status_24hrs != undefined && params.data.gps_status_24hrs != ""){
        //                         if(params.data.gps_status_24hrs == 'no' || params.data.gps_status_24hrs == 'N/A'){
        //                         return "redbg";
        //                     }
                                
        //                     }else{
        //                         return "redbg";
        //                     }
        //                 }
        //             } catch (error) {
                        
        //             }
        //         }
        //       },
        //     // {
        //     //     headerName:"Distance From Plant(Kms)",
        //     //     field:"distance_from_plant_km",
        //     //     width:"150"
        //     // },      
        //     // {
        //     //     headerName:"Last Moved",
        //     //     field:"last_moved_time",
        //     //     width:"150"
        //     // },
        //     // {
        //     //     headerName:"Transporter Code",
        //     //     field:"transporter_code",
        //     //     width:"110",
        //     //     cellClass:function(params){
        //     //         try {
        //     //             if(params.data.timestamp != undefined && params.data.timestamp != ""){
        //     //             const timestamp = params.data.timestamp;
        //     //             const timestampDate = new Date(timestamp);
        //     //             const timeDifference = new Date() - timestampDate;
        //     //             const isLessThan24Hours = timeDifference > (24 * 60 * 60 * 1000);
        //     //             if(isLessThan24Hours){
        //     //                 return "redbg";
        //     //             }
                            
        //     //             }else{
        //     //                 return "redbg";
        //     //             }
        //     //         } catch (error) {
                        
        //     //         }
        //     //     }
        //     // },
        //     // {
        //     //     headerName:"Transporter Name",
        //     //     field:"transporter_name",
        //     //     width:"150",
        //     //     cellClass:function(params){
        //     //         try {
        //     //             if(params.data.timestamp != undefined && params.data.timestamp != ""){
        //     //             const timestamp = params.data.timestamp;
        //     //             const timestampDate = new Date(timestamp);
        //     //             const timeDifference = new Date() - timestampDate;
        //     //             const isLessThan24Hours = timeDifference > (24 * 60 * 60 * 1000);
        //     //             if(isLessThan24Hours){
        //     //                 return "redbg";
        //     //             }
                            
        //     //             }else{
        //     //                 return "redbg";
        //     //             }
        //     //         } catch (error) {
                        
        //     //         }
        //     //     }
        //     // },
        //     // {
        //     //     headerName:"Last Location",
        //     //     field:"last_known_address",
        //     //     width:"250"
        //     // },
        //     // {
        //     //     headerName:"Last Packet Time",
        //     //     field:"timestamp",
        //     //     width:"200",
        //     //     valueGetter:function(params){
        //     //         try {
        //     //             if(params.data.timestamp !== "" && params.data.timestamp !== undefined)
        //     //             {
        //     //                 return getHyphenDDMMMYYYYHHMM(params.data.timestamp);
        //     //             }
        //     //             else{
        //     //                 return "";
        //     //             }
        //     //         } catch (error) {
                        
        //     //         }
        //     //     }
        //     // },
        //     // {
        //     //     headerName:"Max Distance Per Plan",
        //     //     field:"max_distance_per_day",
        //     //     width:"130",
        //     //     cellClass:function(params){
        //     //         try {
        //     //             if(params.data.timestamp != undefined && params.data.timestamp != ""){
        //     //             const timestamp = params.data.timestamp;
        //     //             const timestampDate = new Date(timestamp);
        //     //             const timeDifference = new Date() - timestampDate;
        //     //             const isLessThan24Hours = timeDifference > (24 * 60 * 60 * 1000);
        //     //             if(isLessThan24Hours){
        //     //                 return "redbg";
        //     //             }
                            
        //     //             }else{
        //     //                 return "redbg";
        //     //             }
        //     //         } catch (error) {
                        
        //     //         }
        //     //     }
        //     // },
        //     {
        //         headerName:"Monthly Minimum Distance",
        //         field:"monthly_min_planning_distance",
        //         width:"130",
        //         cellClass:function(params){
        //             try {
        //                 if(params.data.veh_considered_for_planning != undefined && params.data.veh_considered_for_planning != 'no'){
        //                     return "gHighlight";
        //                 }else{
        //                     if(params.data.gps_status_24hrs != undefined && params.data.gps_status_24hrs != ""){
        //                         if(params.data.gps_status_24hrs == 'no' || params.data.gps_status_24hrs == 'N/A'){
        //                         return "redbg";
        //                     }
                                
        //                     }else{
        //                         return "redbg";
        //                     }
        //                 }
        //             } catch (error) {
                        
        //             }
        //         }
        //     },
        //     {
        //         headerName:"Remaining Distance",
        //         field:"remaining_distance",
        //         width:"130",
        //         valueGetter:(params)=>{
        //             if(params.data.remaining_distance != undefined){
        //                 return parseFloat(params.data.remaining_distance).toFixed(2)
        //             }
        //         },
        //         cellClass:function(params){
        //             try {
        //                 if(params.data.veh_considered_for_planning != undefined && params.data.veh_considered_for_planning != 'no'){
        //                     return "gHighlight";
        //                 }else{
        //                     if(params.data.gps_status_24hrs != undefined && params.data.gps_status_24hrs != ""){
        //                         if(params.data.gps_status_24hrs == 'no' || params.data.gps_status_24hrs == 'N/A'){
        //                         return "redbg";
        //                     }
                                
        //                     }else{
        //                         return "redbg";
        //                     }
        //                 }
        //             } catch (error) {
                        
        //             }
        //         }
        //     },
        //     // {   
        //     //     headerName: 'Shipment ID',
        //     //     field: 'shipment_id',
        //     //     width: 110,
        //     //     valueGetter:(params)=>{
        //     //         if (params.data.shipment_id !== undefined && params.data.shipment_id !== '' ){
        //     //             return params.data.shipment_id 
        //     //         }else{
        //     //             return 'N/A'
        //     //         }
        //     //     },
        //     //     cellClass:function(params){
        //     //         try {
        //     //             if(params.data.timestamp != undefined && params.data.timestamp != ""){
        //     //                 const timestamp = params.data.timestamp;
        //     //                 const timestampDate = new Date(timestamp);
        //     //                 const timeDifference = new Date() - timestampDate;
        //     //                 const isLessThan24Hours = timeDifference > (24 * 60 * 60 * 1000);
        //     //                 if(isLessThan24Hours){
        //     //                     return "redbg";
        //     //                 }
        //     //                 // else{
        //     //                 //     return "cell-bg";
        //     //                 //     }
        //     //             }else{
        //     //                 return "redbg";
        //     //             }
        //     //         } catch (error) {
                        
        //     //         }
        //     //     }
        //     // },
        //     // {   
        //     //     headerName: 'Expected Return To Plant (plan)',
        //     //     field: 'vehicle_next_availble_time',
        //     //     width: 140,
        //     //     valueGetter:(params)=>{
        //     //         if (params.data.vehicle_next_availble_time !== undefined && params.data.vehicle_next_availble_time !== '' ){
        //     //             return moment.parseZone(params.data.vehicle_next_availble_time).format("YYYY-MM-DD HH:mm")
        //     //         }else{
        //     //             return 'N/A'
        //     //         }
        //     //     },
        //     //     cellClass:function(params){
        //     //         try {
        //     //             if(params.data.vehicle_next_availble_time != undefined && params.data.vehicle_next_availble_time != "" && params.data.vehicle_next_available_date != undefined && params.data.vehicle_next_available_date != ""){
        //     //                 var avblTime=getHyphenYYYYMMDDHHMMSS(params.data.vehicle_next_available_date)
        //     //                 var nxtAvblTime = getHyphenYYYYMMDDHHMMSS(params.data.vehicle_next_availble_time)
        //     //                 const isLessThannxttime = avblTime < nxtAvblTime;
        //     //                 // console.log(avblTime<nxtAvblTime,'hello',avblTime,nxtAvblTime)
        //     //                 if(isLessThannxttime){
        //     //                     return "cellbg-vehicle";
        //     //                 }
        //     //             }
        //     //             // else{
        //     //             //     return "redbg";
        //     //             // }
        //     //         } catch (error) {
                        
        //     //         }
        //     //     }
        //     // },
        //     {
        //         headerName:"Vehicle Available Date (YYYY-MM-DD HH:mm)",
        //         field:"vehicle_next_available_date",
        //         width:"150",
        //         valueGetter:function(params){
        //                     try {
        //                         if(params.data.vehicle_next_available_date !== "" && params.data.vehicle_next_available_date !== undefined)
        //                         {
        //                             // return getDDMMYYYYHHMM(params.data.vehicle_next_available_date);
        //                             return moment.parseZone(params.data.vehicle_next_available_date).format("YYYY-MM-DD HH:mm")
        //                         }
        //                         else{
        //                             return "";
        //                         }
        //                     } catch (error) {
                                
        //                     }
        //         },
        //         editable:true,
        //         cellClass:function(params){
        //             try {
        //                 if(params.data.veh_considered_for_planning != undefined && params.data.veh_considered_for_planning != 'no'){
        //                     return "gHighlight";
        //                 }else{
        //                     if(params.data.gps_status_24hrs != undefined && params.data.gps_status_24hrs != ""){
        //                         if(params.data.gps_status_24hrs == 'no' || params.data.gps_status_24hrs == 'N/A'){
        //                             return "redbg";
        //                         }else{
        //                             return "cell-bg";
        //                         }
        //                     }else{
        //                         return "redbg";
        //                     }
        //                 }
        //             } catch (error) {
                        
        //             }
        //         }
        //     },
        //     // {   
        //     //     headerName: 'Assigned to OT',
        //     //     field: 'checkbox',
        //     //     checkboxSelection: true,
        //     //     headerCheckboxSelection: false,
        //     //     // width:"10px", 
        //     //     width:10,
        //     //     suppressRowClickSelection: true,
        //     // },
        // ];

        var assignableVehiclecolsDefs = [
            {
                headerName: t('vehicle_number'),
                // field:"truck_no",
                field: 'vehicle_no',
                width: "130",
                // checkboxSelection: true,
                // headerCheckboxSelection: true,
                cellClass: function (params) {
                    try {
                        if (params.data.veh_considered_for_planning != undefined && params.data.veh_considered_for_planning != 'no') {
                            return "gHighlight";
                        } else {
                            if (params.data.gps_status_24hrs != undefined && params.data.gps_status_24hrs != "") {
                                if (params.data.gps_status_24hrs == 'no' || params.data.gps_status_24hrs == 'N/A') {
                                    return "redbg";
                                }
                            } else {
                                return "redbg";
                            }
                        }
                    } catch (error) {
        
                    }
                }
            },
            {
                headerName: t('vehicle_type'),
                field: "vehicle_type",
                width: "150",
                cellClass: function (params) {
                    try {
                        if (params.data.veh_considered_for_planning != undefined && params.data.veh_considered_for_planning != 'no') {
                            return "gHighlight";
                        } else {
                            if (params.data.gps_status_24hrs != undefined && params.data.gps_status_24hrs != "") {
                                if (params.data.gps_status_24hrs == 'no' || params.data.gps_status_24hrs == 'N/A') {
                                    return "redbg";
                                }
                            } else {
                                return "redbg";
                            }
                        }
                    } catch (error) {
        
                    }
                }
            },
            {
                headerName: t('transit_type'),
                field: "transit_type",
                width: "130",
                cellClass: function (params) {
                    try {
                        if (params.data.veh_considered_for_planning != undefined && params.data.veh_considered_for_planning != 'no') {
                            return "gHighlight";
                        } else {
                            if (params.data.gps_status_24hrs != undefined && params.data.gps_status_24hrs != "") {
                                if (params.data.gps_status_24hrs == 'no' || params.data.gps_status_24hrs == 'N/A') {
                                    return "redbg";
                                }
                            } else {
                                return "redbg";
                            }
                        }
                    } catch (error) {
        
                    }
                }
            },
            // {
            //     headerName: t('distance_from_plant_kms'),
            //     field: "distance_from_plant_km",
            //     width: "150"
            // },
            // {
            //     headerName: t('last_moved'),
            //     field: "last_moved_time",
            //     width: "150"
            // },
            // {
            //     headerName: t('transporter_code'),
            //     field: "transporter_code",
            //     width: "110",
            //     cellClass: function (params) {
            //         try {
            //             if (params.data.timestamp != undefined && params.data.timestamp != "") {
            //                 const timestamp = params.data.timestamp;
            //                 const timestampDate = new Date(timestamp);
            //                 const timeDifference = new Date() - timestampDate;
            //                 const isLessThan24Hours = timeDifference > (24 * 60 * 60 * 1000);
            //                 if (isLessThan24Hours) {
            //                     return "redbg";
            //                 }
            //             } else {
            //                 return "redbg";
            //             }
            //         } catch (error) {
        
            //         }
            //     }
            // },
            // {
            //     headerName: t('transporter_name'),
            //     field: "transporter_name",
            //     width: "150",
            //     cellClass: function (params) {
            //         try {
            //             if (params.data.timestamp != undefined && params.data.timestamp != "") {
            //                 const timestamp = params.data.timestamp;
            //                 const timestampDate = new Date(timestamp);
            //                 const timeDifference = new Date() - timestampDate;
            //                 const isLessThan24Hours = timeDifference > (24 * 60 * 60 * 1000);
            //                 if (isLessThan24Hours) {
            //                     return "redbg";
            //                 }
            //             } else {
            //                 return "redbg";
            //             }
            //         } catch (error) {
        
            //         }
            //     }
            // },
            // {
            //     headerName: t('last_location'),
            //     field: "last_known_address",
            //     width: "250"
            // },
            // {
            //     headerName: t('last_packet_time'),
            //     field: "timestamp",
            //     width: "200",
            //     valueGetter: function (params) {
            //         try {
            //             if (params.data.timestamp !== "" && params.data.timestamp !== undefined) {
            //                 return getHyphenDDMMMYYYYHHMM(params.data.timestamp);
            //             } else {
            //                 return "";
            //             }
            //         } catch (error) {
        
            //         }
            //     }
            // },
            // {
            //     headerName: t('max_distance_per_plan'),
            //     field: "max_distance_per_day",
            //     width: "130",
            //     cellClass: function (params) {
            //         try {
            //             if (params.data.timestamp != undefined && params.data.timestamp != "") {
            //                 const timestamp = params.data.timestamp;
            //                 const timestampDate = new Date(timestamp);
            //                 const timeDifference = new Date() - timestampDate;
            //                 const isLessThan24Hours = timeDifference > (24 * 60 * 60 * 1000);
            //                 if (isLessThan24Hours) {
            //                     return "redbg";
            //                 }
            //             } else {
            //                 return "redbg";
            //             }
            //         } catch (error) {
        
            //         }
            //     }
            // },
            {
                headerName: t('monthly_minimum_distance'),
                field: "monthly_min_planning_distance",
                width: "130",
                cellClass: function (params) {
                    try {
                        if (params.data.veh_considered_for_planning != undefined && params.data.veh_considered_for_planning != 'no') {
                            return "gHighlight";
                        } else {
                            if (params.data.gps_status_24hrs != undefined && params.data.gps_status_24hrs != "") {
                                if (params.data.gps_status_24hrs == 'no' || params.data.gps_status_24hrs == 'N/A') {
                                    return "redbg";
                                }
                            } else {
                                return "redbg";
                            }
                        }
                    } catch (error) {
        
                    }
                }
            },
            {
                headerName: t('remaining_distance'),
                field: "remaining_distance",
                width: "130",
                valueGetter: (params) => {
                    if (params.data.remaining_distance != undefined) {
                        return parseFloat(params.data.remaining_distance).toFixed(2);
                    }
                },
                cellClass: function (params) {
                    try {
                        if (params.data.veh_considered_for_planning != undefined && params.data.veh_considered_for_planning != 'no') {
                            return "gHighlight";
                        } else {
                            if (params.data.gps_status_24hrs != undefined && params.data.gps_status_24hrs != "") {
                                if (params.data.gps_status_24hrs == 'no' || params.data.gps_status_24hrs == 'N/A') {
                                    return "redbg";
                                }
                            } else {
                                return "redbg";
                            }
                        }
                    } catch (error) {
        
                    }
                }
            },
            {
                headerName: t('vehicle_available_date'),
                field: "vehicle_next_available_date",
                width: "150",
                valueGetter: function (params) {
                    try {
                        if (params.data.vehicle_next_available_date !== "" && params.data.vehicle_next_available_date !== undefined) {
                            return moment.parseZone(params.data.vehicle_next_available_date).format("YYYY-MM-DD HH:mm");
                        } else {
                            return "";
                        }
                    } catch (error) {
        
                    }
                },
                editable: true,
                cellClass: function (params) {
                    try {
                        if (params.data.veh_considered_for_planning != undefined && params.data.veh_considered_for_planning != 'no') {
                            return "gHighlight";
                        } else {
                            if (params.data.gps_status_24hrs != undefined && params.data.gps_status_24hrs != "") {
                                if (params.data.gps_status_24hrs == 'no' || params.data.gps_status_24hrs == 'N/A') {
                                    return "redbg";
                                } else {
                                    return "cell-bg";
                                }
                            } else {
                                return "redbg";
                            }
                        }
                    } catch (error) {
        
                    }
                }
            }
            // {
            //     headerName: t('assigned_to_ot'),
            //     field: 'checkbox',
            //     checkboxSelection: true,
            //     headerCheckboxSelection: false,
            //     width: 10,
            //     suppressRowClickSelection: true,
            // },
        ];
        
        
        var nongpsvehiclecolsDefs = [
            {
                headerName:"Vehicle Name",
                field:"vehicle_name",
            },
            {
                headerName:"Vehicle Type",
                field:"vehicle_type",
            },
            {
                headerName:"Vehicle Count",
                field:"vehicle_available_count",
                width:"145",
                editable:true,
                cellEditor: ValidationCellEditor
            },
            {
                headerName:"Vehicle Max Weight (Pounds)",
                field:"vehicle_max_weight",
            },
            {
                headerName:"Vehicle Max Volume (CFT)",
                field:"max_volume_m3",
            },
            
        ];

        // var colsDefs = [
        //     {
        //         headerName:"Vehicle Type",
        //         field:"vehicle_type",
        //         width:"250"
        //     },
        //     {
        //         headerName:"Vehicle Name",
        //         field:"vehicle_name",
        //         width:"135"
        //     },
        //     // {
        //     //     headerName:"Vehicle Operational(from)",
        //     //     field:"vehicle_availability_from",
        //     //     width:"150",
        //     // },
        //     // {
        //     //     headerName:"Vehicle Operational(to)",
        //     //     field:"vehicle_availability_to",
        //     //     width:"140"
        //     // },
        //     // {
        //     //     headerName:"Break Time(from)",
        //     //     field:"break_time_from",
        //     //     width:"110",
        //     // },
        //     // {
        //     //     headerName:"Break Time(to) ",
        //     //     field:"break_time_to",
        //     //     width:"100",
        //     // },
        //     // {
        //     //     headerName:"Min Seating Capacity",
        //     //     field:"min_volume_cmt",
        //     //     width:"120",
        //     //     editable:true
        //     // },
        //     // {
        //     //     headerName:"Max Seating Capacity",
        //     //     field:"max_volume_cmt",
        //     //     width:"120",
        //     //     editable:true
        //     // },
        //     // {
        //     //     headerName:"Vehicle Seating Capacity",
        //     //     field:"vehicle_dimensions_width_mm",
        //     //     width:"160",
        //     //     editable:true
        //     // },
        //     // {
        //     //     headerName:"Vehicle Height (mm)",
        //     //     field:"vehicle_dimensions_height_mm",
        //     //     width:"125",
        //     //     editable:true
        //     // },
        //     // {
        //     //     headerName:"Vehicle Depth (mm)",
        //     //     field:"vehicle_dimensions_depth_mm",
        //     //     width:"120",
        //     //     editable:true
        //     // },
        //     {
        //         headerName:"Vehicle Min Weight (Pounds)",
        //         field:"vehicle_min_weight",
        //         width:"130",
        //         editable:true,
        //         cellEditor: ValidationCellEditor
        //     },
        //     {
        //         headerName:"Vehicle Max Weight (Pounds)",
        //         field:"vehicle_max_weight",
        //         width:"130",
        //         editable:true,
        //         cellEditor: ValidationCellEditor
        //     },
        //     // {
        //     //     headerName:"Vehicle Capacity (CMT)",
        //     //     field:"vehicle_capacity",
        //     //     width:"135",
        //     //     editable:true
        //     // },
        //     // {
        //     //     headerName:"No Of Deliveries",
        //     //     field:"max_no_of_nodes",
        //     //     width:"100",
        //     //     editable:true
        //     // },
        //     // {
        //     //     headerName:"Min Load Per Delivery",
        //     //     field:"min_load_per_delivery",
        //     //     width:"140",
        //     //     editable:true
        //     // },
        //     {
        //         headerName:"Vehicle Min Volume (CFT)",
        //         field:"min_volume_m3",
        //         width:"130",
        //         editable:true,
        //         cellEditor: ValidationCellEditor
        //     },
        //     {
        //         headerName:"Vehicle Max Volume (CFT)",
        //         field:"max_volume_m3",
        //         width:"130",
        //         editable:true,
        //         cellEditor: ValidationCellEditor
        //     },
        //     {
        //         headerName:"Max Distance per Trip (Miles)",
        //         field:"max_distance",
        //         width:"120",
        //         editable:true,
        //         valueGetter: (params) => {
        //             if (params.data.max_distance != "" && params.data.max_distance != undefined && params.data.max_distance !== null) {
        //                 return (params.data.max_distance*0.621).toFixed(0);
        //             }
        //         }
        //     },
        //     {
        //         headerName:"Monthly Min Planning Distance (Miles)",
        //         field:"monthly_min_planning_distance",
        //         hide:(monthly_distance_preferences === 1?false:true),
        //         width:150,
        //         valueGetter: (params) => {
        //             if (params.data.monthly_min_planning_distance != "" && params.data.monthly_min_planning_distance != undefined && params.data.monthly_min_planning_distance !== null) {
        //                 return (params.data.monthly_min_planning_distance*0.621).toFixed(0);
        //             }
        //         }
        //     },
            
        //     {
        //         headerName:"Vehicle Priority",
        //         field:"vehicle_priority",
        //         width:"100"
        //     },
        //     {
        //         headerName:"",
        //         field:"cluster",
        //         width:"80",
        //         pinned:'left',
        //         params:{buttonName: t('delete'),iconName:"fa fa-trash",
        //         onClickFunction:this.deleteNode},
        //         cellRendererSelector:function(params){
                    
        //             var rendComponent = {
        //                 component: "GridButton"
        //             };
        //             return rendComponent
                
        //         },
        //     },
        // ]

        var colsDefs = [
            {
                headerName: t('vehicle_type'),
                field: "vehicle_type",
                width: "250"
            },
            {
                headerName: t('vehicle_name'),
                field: "vehicle_name",
                width: "135"
            },
            // {
            //     headerName: t('vehicle_operational_from'),
            //     field: "vehicle_availability_from",
            //     width: "150",
            // },
            // {
            //     headerName: t('vehicle_operational_to'),
            //     field: "vehicle_availability_to",
            //     width: "140"
            // },
            // {
            //     headerName: t('break_time_from'),
            //     field: "break_time_from",
            //     width: "110",
            // },
            // {
            //     headerName: t('break_time_to'),
            //     field: "break_time_to",
            //     width: "100",
            // },
            // {
            //     headerName: t('min_seating_capacity'),
            //     field: "min_volume_cmt",
            //     width: "120",
            //     editable: true
            // },
            // {
            //     headerName: t('max_seating_capacity'),
            //     field: "max_volume_cmt",
            //     width: "120",
            //     editable: true
            // },
            // {
            //     headerName: t('vehicle_seating_capacity'),
            //     field: "vehicle_dimensions_width_mm",
            //     width: "160",
            //     editable: true
            // },
            // {
            //     headerName: t('vehicle_height_mm'),
            //     field: "vehicle_dimensions_height_mm",
            //     width: "125",
            //     editable: true
            // },
            // {
            //     headerName: t('vehicle_depth_mm'),
            //     field: "vehicle_dimensions_depth_mm",
            //     width: "120",
            // },
            {
                headerName: t('vehicle_min_weight_pounds'),
                field: "vehicle_min_weight",
                width: "130",
                editable: true,
                cellEditor: ValidationCellEditor
            },
            {
                headerName: t('vehicle_max_weight_pounds'),
                field: "vehicle_max_weight",
                width: "130",
                editable: true,
                cellEditor: ValidationCellEditor
            },
            // {
            //     headerName: t('vehicle_capacity_cmt'),
            //     field: "vehicle_capacity",
            //     width: "135",
            //     editable: true
            // },
            // {
            //     headerName: t('no_of_deliveries'),
            //     field: "max_no_of_nodes",
            //     width: "100",
            //     editable: true
            // },
            // {
            //     headerName: t('min_load_per_delivery'),
            //     field: "min_load_per_delivery",
            //     width: "140",
            //     editable: true
            // },
            {
                headerName: t('vehicle_min_volume_cft'),
                field: "min_volume_m3",
                width: "130",
                editable: true,
                cellEditor: ValidationCellEditor
            },
            {
                headerName: t('vehicle_max_volume_cft'),
                field: "max_volume_m3",
                width: "130",
                editable: true,
                cellEditor: ValidationCellEditor
            },
            {
                headerName: t('max_distance_per_trip_miles'),
                field: "max_distance",
                width: "120",
                editable: true,
                valueGetter: (params) => {
                    if (params.data.max_distance != "" && params.data.max_distance != undefined && params.data.max_distance !== null) {
                        return (params.data.max_distance * 0.621).toFixed(0);
                    }
                }
            },
            {
                headerName: t('monthly_min_planning_distance_miles'),
                field: "monthly_min_planning_distance",
                hide: (monthly_distance_preferences === 1 ? false : true),
                width: 150,
                valueGetter: (params) => {
                    if (params.data.monthly_min_planning_distance != "" && params.data.monthly_min_planning_distance != undefined && params.data.monthly_min_planning_distance !== null) {
                        return (params.data.monthly_min_planning_distance * 0.621).toFixed(0);
                    }
                }
            },
            {
                headerName: t('vehicle_priority'),
                field: "vehicle_priority",
                width: "100"
            },
            {
                headerName: "",
                field: "cluster",
                width: "80",
                pinned: 'left',
                params: {
                    buttonName: t('delete'),
                    iconName: "fa fa-trash",
                    onClickFunction: this.deleteNode
                },
                cellRendererSelector: function (params) {
                    var rendComponent = {
                        component: "GridButton"
                    };
                    return rendComponent;
                }
            }
        ];
        

        let data = this.state.rowData
        let locValue = this.state.location.value   
        let filteredData = data.filter(item => item.location_code == locValue)

        let vehicleTypeCnt = 4;
        let truck_min_max = 0;
        let truckPriorities = 0;
        let distancePreferences = 0;
        let operationalTimes = 0;
        let operationalTimesType = 0;
        let clubbingLimits = 0;
        let minLoad = 0;
        let vehicleAvailability = 0;
        var material_configurations = 0;

        var toolConfig = JSON.parse(localStorage.getItem('tlcfg'))
        if(toolConfig !=undefined && toolConfig !="")
        {
            if(toolConfig.length > 0)
            {
                vehicleTypeCnt = toolConfig[0].max_no_of_trucks;
                truck_min_max = toolConfig[0].truck_min_max_configuration_preferences;
                truckPriorities = toolConfig[0].truck_priorities;
                distancePreferences = toolConfig[0].distance_preferences;
                operationalTimes = toolConfig[0].vehicle_operational_times;
                if(toolConfig[0].generic_time_windows===1){
                    operationalTimesType=1
                } if(toolConfig[0].individual_truck_time_windows===1){
                    operationalTimesType=2
                }
                clubbingLimits = toolConfig[0].clubbing_limits;
                minLoad = toolConfig[0].min_load_preferences
                vehicleAvailability = toolConfig[0].vehicle_availability
                material_configurations = toolConfig[0].material_configuration;
            }
        }
        var columnwithDefsForDropped = [      
            {
                headerName: "Dealer",
                field: "dealer_code",
                width:130,
                filter: true,
                resizable: true,
                editable:false,            
            },
            {
                headerName: "Demand",
                field: "demand",
                width:130,
                filter: true,
                resizable: true,
                editable:false
            },
            {
                headerName: "Remarks",
                field: "remarks",
                width:200,
                filter: true,
                resizable: true,
                editable:false
            },
        ]
        if(material_configurations == 1)
        {
            var templatePath = require('../../assets/json/input_data_with_material_config_template.csv');
        }
        else
        {
            var templatePath = require('../../assets/json/input_data_template.csv');
        }

        
        let locationName
        if(this.state.rowData.length>0){
         locationName = this.state.rowData[0].location_name
        }
        // let filteredList=[]
        // // console.log(locationName,filteredList,this.state.vehicleTypeList)
        // if (locationName==='IN DC Kolkata'){
        //     this.state.vehicleTypeList.map((item)=>{
        //         if (item.label==='IN416'){
        //             filteredList.push(item)
                    
        //         }
        //     })
        // }else if (locationName==='IN DC NCR'){
        //     this.state.vehicleTypeList.map((item)=>{
                
        //         if (item.label==='IN414_3500_10_CNG_DELHI' || item.label==='IN414_3500_10_DIESEL_NON_DELHI'){
        //             filteredList.push(item)
        //             // console.log(item.label==='IN414_3500_10_CNG_DELHI')
                    
        //         }
        //     })
        // }
        
        // console.log(this.state.simulationTime,'helloooooo')
        
        
        
        // var configs=this.state.plantConfigs

        
        // console.log('kk',configs)
        
        var userMailCheck=localStorage.getItem('user_type')==='admin'?"show-m":'show-n'
        return(
            <>
            <SweetAlert
                show={this.state.show}
                type={this.state.basicType}
                title={this.state.basicTitle}
                onConfirm={this.closeAlert}>
            </SweetAlert>
                
                <div class="row mb-20p pt-20p">
                    <div className="col-sm-12">                   
                        <h5 className='fbold'>
                            <div className="col-sm-5">
                                <div className="row">
                                    <div className="col-md-12 col-sm-12">
                                        <ul className="breadcrumb">
                                            <li>
                                                <a href={"/loadprocessrun?outbound"}>
                                                    {t('run_new_plan')}
                                                </a>
                                            </li>
                                            <li>
                                                {t('new_plan_details')}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        {t('order')} {this.state.urlorder_id} - {locationName}
                                    </div>
                                </div>
                            </div>
                            
                            <div className='float-right col-sm-2'>
                                <span className="float-right">
                                <a href="javascript:;"  onClick={this.planNewDispatchWithConfig} className="btn btn-warning f12 mr-10p nbold">{t('run_plan')}</a>
                                </span>
                            </div>
                            {/* <div className="col-sm-5 float-right f12" style={{textAlign:"right"}}>
                                <span className="bxPref">
                                <input type="checkbox" id="run_with_transit" checked = {this.state.run_with_transit ? true : false} onClick={this.updateRoutePrefer} name="run_with_transit" />
                                <label for="css">&nbsp;&nbsp;Transit Time Multiple City Clubing&nbsp;&nbsp;&nbsp;&nbsp;</label> */}
                                {/* <input type="checkbox" id="run_with_RDD" checked = {this.state.run_with_RDD ? true : false} onClick={this.updateRoutePrefer} name="run_with_RDD" />
                                <label for="css">&nbsp;&nbsp;RDD Preference</label> */}
                                {/* <input type="checkbox" id="run_with_transit_cityclub" checked = {this.state.run_with_transit_cityclub ? true : false} onClick={this.updateRoutePrefer} name="run_with_transit_cityclub" />
                                <label for="css">&nbsp;&nbsp;Transit Time Single City Clubing</label> */}
                                    {/* <input type="radio" id="rootopti" checked = {this.state.opti_type ? false : true} onClick={this.updateOptimizationType} name="opti_root" value={this.state.opti_type}/>
                                    <label for="html">&nbsp;&nbsp;Optimize by Distance&nbsp;&nbsp;&nbsp;&nbsp;</label>
                                    <input type="radio" id="timeopti" checked = {this.state.opti_type ? true : false} onClick={this.updateOptimizationType} name="opti_time" value={this.state.opti_type}/>
                                    <label for="css">&nbsp;&nbsp;Optimize by Time</label> */}
                                {/* </span> */}
                                {/* &nbsp;&nbsp;
                                <input type="checkbox" id="routeprefer" checked = {this.state.routeprefer ? true : false} onClick={this.updateRoutePrefer} name="routeprefer" />
                                <label for="css">&nbsp;&nbsp;Route Preferences</label> */}
                                
                            {/* </div> */}
                        </h5>
                        {
                            this.state.reconcilation? 
                            
                        // <div className={" col-sm-2 mt-0 float-right "+ userMailCheck}>
                        <div className={" col-sm-2 mt-0 float-right"}>
                        <label>Simulation Time</label>  

                       <Datetime
                           inputProps={{  name: 'startDate', autoComplete:'off', required:true,readOnly:'true' }} 
                           placeholder={"Date"}
                           dateFormat="YYYY-MM-DD" 
                           timeFormat="HH:mm:ss"  
                           name="simulationTime"
                           // closeOnSelect="true"
                           onChange={this.handlerStartDateTime.bind(this,'simulationTime')}
                           />  
                       </div>
                            : ""
                        }
                        
                     
                    </div>
                </div>
                <div className="row mt-20p">
                    <div className="col-md-12 col-sm-12">
                        <ul className="d-tabs" style={{paddingLeft:"10px !important"}}>
                            <li onClick={this.onClickTab.bind(this,"config")} className={"lttabs "+(this.state.config)}>
                                <button type="button" className="btn">{t('vehicle_configs')}</button>
                            </li>

                            <li onClick={this.onClickTab.bind(this,"inputInfo")} className={"lttabs "+(this.state.inputInfo)}>
                                <button type="button" className="btn">{t('order_information')}</button>
                            </li>  
                            <li onClick={this.onClickTab.bind(this,"planconfigs")} className={"lttabs "+(this.state.planConfig)}>
                                <button type="button" className="btn">{t('plan_configs')}</button> 
                            </li>   
                            <li onClick={this.onClickTab.bind(this,"vehicles")} className={"lttabs "+(this.state.vehicleinfo)}>
                                <button type="button" className="btn">{t('vehicle_availability')}</button>
                            </li>  
                            {/* <li onClick={this.onClickTab.bind(this,"nongpsvehicles")} className={"lttabs "+(this.state.nongpsvehicleinfo)}>
                                <button type="button" className="btn">Vehicle Availability</button>
                            </li>                                     */}
                        </ul>
                    </div>
                </div >
                
                <div className="mt-5p">
                {
                    this.state.vehicleinfo === 'activet' ? (                 
                    <>
                        <span className="col-sm-6 red fbold">{t('note_highlighted_rows_red')}</span><br className="br"/>
                        <span className="col-sm-6 greenfont fbold">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {t('highlighted_rows_green')} </span>
                    </>) : " "}
                
                <div className="float-right d-flex align-items-center  justify-content-end  col-sm-6 ">
                    {/* <div> */}
                    {/* <span className="float-right"> */}
                    {/* <label style={{margin:'0px'}} htmlFor="selectedVFR"> Min VFR% {String.fromCharCode(160)}</label>
                    </div> */}
                    {/* <div className="cursorPointer ">
                    <Select
                        className="border-radius-0 col-xs-2"
                        id="selectedVFR"
                        options={[{ label: "0%", value: 0}, { label: "25%", value: 25},{ label: "50%", value: 50},]}
                        onChange={this.onChangeVFR.bind(this, "selectedVFR")}
                        placeholder={'0%'}
                        value={this.state.selectedVFR}
                        
                    />
                    </div> */}
                        {/* <input className="ml-2 "   type="checkbox" id="rootopti" checked = {this.state.opti_type ? false : true} onClick={this.updateOptimizationType} name="opti_root" value={this.state.opti_type}/>
                        <label className="cursorPointer " style={{margin:'0px'}} for="html">&nbsp;&nbsp;Optimize by Distance&nbsp;&nbsp;&nbsp;&nbsp;</label> */}
                    {/* </span> */}
                    {/* <input type="radio" id="timeopti" checked = {this.state.opti_type ? true : false} onClick={this.updateOptimizationType} name="opti_time" value={this.state.opti_type}/>
                    <label for="css">&nbsp;&nbsp;Optimize by Time</label> */}
                    {/* <select id="minvfr" >
                        <option>0%</option>
                        <option>25%</option>
                        <option>50%</option>
                    </select> */}
                   {this.state.spotButtonVisble===1?<>
                    {/* <input className="ml-2 mr-0 float-right"  type="checkbox" id="spotVehicle" checked = {this.state.plan_with_spot_vehicles === 1 ? true : false} onClick={this.handleSpotVehicleRequired} name="plan_with_spot_vehicles" value={this.state.plan_with_spot_vehicles}/>
                    <label className="float-right" style={{margin:'0px'}} for="css">&nbsp;&nbsp;Spot Vehicle Required &nbsp;&nbsp;</label>  */}
                    </>:''}
                    {/* <input type="checkbox" id="selectReload" checked = {this.state.selectReload ? true : false} onClick={this.handleSelectReload} name="selectReload" value={this.state.selectReload}/>
                    <label for="css">&nbsp;&nbsp; Reload Vehicles</label> */}
                    <input className="ml-2 mr-0 float-right"  type="checkbox" id="reconcilation" onClick={this.handleReconcilation} name="reconcilation" checked={this.state.reconcilation} />
                    <label className="float-right" style={{margin:'0px'}} for="css" htmlFor="reconcilation">&nbsp;&nbsp;{t('enable_reconciliation')} &nbsp;&nbsp;</label> 
                    <input className="ml-2 mr-0 float-right"  type="checkbox" id="sundayplanning" checked = {this.state.is_sunday_planning_enabled === 1 ? true : false} onClick={this.handlesundayplanning} disabled={!this.state.enable_sunday_planning} name="is_sunday_planning_enabled" value={this.state.is_sunday_planning_enabled}/>
                    <label className="float-right" style={{margin:'0px'}} for="css" htmlFor="sundayplanning">&nbsp;&nbsp; {t('enable_dc_week_off_planning')} &nbsp;&nbsp;</label> 
                </div>
                </div>               
                
                    
                <div className={"row mt-20p "+this.state.configData}>
                    <div className="col-sm-12">
                        <div id="myGrid" style={{width:"100%",height:"70vh"}} className="col-sm-12 ag-theme-balham dropdown">
                        <AgGridReact
                        // modules={this.state.modules} 
                            rowData={this.state.rowData}
                            columnDefs={colsDefs}
                            gridOptions={{context:{componentParent:this}}}
                            defaultColDef={this.state.defaultColDef}
                            frameworkComponents={this.state.frameworkComponents}
                            paginationPageSize={this.state.paginationPageSize}
                            pagination={true}
                            onGridReady={this.onGridReady}
                            onGridState={this.onGridState}
                            // onCellEditingStopped={this.checkCellValidation.bind(this)}
                            stopEditingWhenCellsLoseFocus = {true}
                        />
                        </div>
                    </div>
                </div>

                {/* Input Data */}
                <div className={"row mt-20p "+this.state.inputInfoData}>
                    <div className="col-sm-12">
                        <div id="myGrid2" style={{width:"100%",height:"80vh"}} className="col-sm-12 ag-theme-balham dropdown">
                            <AgGridReact
                            // modules={this.state.modules} 
                                rowData={this.state.inputDetails}
                                columnDefs={inputDetailscolsDefs}
                                gridOptions={{context:{componentParent:this}}}
                                defaultColDef={this.state.defaultColDef}
                                frameworkComponents={this.state.frameworkComponents}
                                paginationPageSize={this.state.paginationPageSize}
                                pagination={true}
                                enableRangeSelection= {true}
                                onGridReady={this.onGridReady2}
                                onGridState={this.onGridState2}
                            />
                        </div>
                    </div>
                </div>
                
                {/* Vehicle Availablity gps data*/}
                <div className={"row mt-20p "+this.state.vehicleinfoData}>
                    {/* <div  className="col-sm-12">
                        <label className="fw-600">Plant Vicinity Circle
                            &nbsp;&nbsp;<input name="truck_location_vicinity" id="truck_location_vicinity" type="number" value={this.state.truck_location_vicinity}
                                onChange={this.changeHandler.bind(this)} 
                                step="100" min={1000} /> Mtrs
                        </label> 
                        <p id='warning-text' className="redColor"></p>
                    </div>
                    <div className="mb-20p p-20p">
                    <OnMapVehiclesLocation truck_location_vicinity={this.state.truck_location_vicinity}/>
                    </div>
                    <div id="myGrid3" style={{width:"100%",height:"70vh"}} className="col-sm-12 ag-theme-balham dropdown">
                        <AgGridReact
                        // modules={this.state.modules} 
                            rowData={this.state.vehicleDetails}
                            columnDefs={vehiclecolsDefs}
                            gridOptions={{context:{componentParent:this},rowSelection: 'multiple'}}
                            defaultColDef={this.state.defaultColDef}
                            frameworkComponents={this.state.frameworkComponents}
                            paginationPageSize={this.state.paginationPageSize}
                            pagination={true}
                            enableRangeSelection= {true}
                            onGridReady={this.onGridReady3}
                            onGridState={this.onGridState3}
                        />
                    </div> */}
                    

                    <div id="myGrid3" style={{width:"100%",height:"70vh"}} className="col-sm-12 ag-theme-balham dropdown">
                        <AgGridReact
                        // modules={this.state.modules} 
                            rowData={this.state.masterVehicleAvailabilityData}
                            columnDefs={assignableVehiclecolsDefs}
                            gridOptions={{context:{componentParent:this},rowSelection: 'multiple',suppressRowClickSelection: true,}}
                            defaultColDef={this.state.defaultColDef}
                            frameworkComponents={this.state.frameworkComponents}
                            paginationPageSize={this.state.paginationPageSize}
                            pagination={true}
                            enableRangeSelection= {true}
                            onGridReady={this.onGridReady3}
                            onGridState={this.onGridState3}
                            onCellEditingStopped={this.onCellValueChanged.bind(this)}
                        />
                    </div>
                </div>
                <div className={'row '+this.state.planConfigData}>
                  <ConfigsInRunNew className='m-2' savePlantConfigs={this.savePlantConfigs}  handleOnClose={this.onClickCloseConfigs} openConfigmd={this.state.openSavedConfigs} data={this.state.plantConfigs}/>
                </div>
                {/* Vehicle Availablity non-gps data*/}
                {/* <div className={"row mt-20p "+this.state.nongpsvehicleinfoData}>
                <div id="myGrid3" style={{width:"100%",height:"70vh"}} className="col-sm-12 ag-theme-balham dropdown">
                        <AgGridReact
                        // modules={this.state.modules} 
                            rowData={this.state.nonGpsData}
                            columnDefs={nongpsvehiclecolsDefs}
                            gridOptions={{context:{componentParent:this}}}
                            defaultColDef={this.state.defaultColDef}
                            paginationPageSize={this.state.paginationPageSize}
                            pagination={true}
                            enableRangeSelection= {true}
                            onGridReady={this.onGridReady4}
                            onGridState={this.onGridState4}
                        />
                    </div>
                </div> */}
            
                <div className={"overlay-block "+(this.state.overlayBlock)} onClick={this.hideSlideBlock}></div>
                <div className={"sliderBlock2 "+(this.state.showSlideBlockUpload)}>
                    <h5 className="crd-bg p-10p">{t('upload_input_file')}</h5>
                    <div className="row">
                        
                        <div className="col-sm-12">
                            {this.state.processErrShow == 0 ?
                            <form onSubmit={this.uploadBulkFormHandler.bind(this)}>
                                <div className="row p-20p">
                                    <div className="form-group col-sm-12">
                                        <a className="btn btn-warning" href={templatePath} target="_blank">{t('download_sample_template')}</a>                            
                                    </div>
                                    <div className="form-group col-sm-12 mb-20p">
                                        <label style={{color:"#000"}}>{t('upload_file')}</label> 
                                        <input type="file" name="uploadFile" id="uploadFile" onChange={this.changeFileHandler}  className="form-control" required  />
                                    </div>                        
                                    <div className="form-group col-sm-12 mb-20p">
                                        <label style={{color:"#000"}}>Notify Email Users After Process (comma seperated)</label> 
                                        <textarea name="notify_email" id="notify_email" className="form-control"></textarea>
                                    </div>
                                    <div className="form-group col-sm-12 mb-20p">
                                        <button type="submit" className="btn btn-info">{t('save')}</button>
                                        <button type="button" className="btn btn-danger" onClick={this.hideSlideBlock.bind(this)}>{t('cancel')}</button>
                                    </div>
                                </div>
                            </form>
                            :
                            <div className="" style={{marginTop:"1em"}}>
                                <p style={{marginBottom:"1em"}}>{this.state.processErrMsg}</p>
                                {this.state.droppedList.length > 0 ? 
                                    <div id="myGrid" style={{width:"100%",height:"300px",marginBottom:"1em"}} className={"ag-theme-balham "+this.state.showGridData}>    
                                    <AgGridReact
                                        // modules={this.state.modules}
                                        columnDefs={columnwithDefsForDropped}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.droppedList}
                                        enableCharts={false}
                                        // autoGroupColumnDef={this.state.autoGroupColumnDef}
                                        onGridReady={this.onGridReady1}
                                        onGridState={this.onGridState1}
                                        frameworkComponents={this.state.frameworkComponents}
                                        statusBar={this.state.statusBar}
                                        sideBar={this.state.sideBar}
                                        stopEditingWhenGridLosesFocus= {true}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={false}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        // components={this.state.components}
                                        enableRangeSelection= {true}
                                        //onCellClicked={this.onCellClicked}
                                        // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                                    />
                                </div>
                                :
                                ""
                                }
                                <button type="button"  onClick={this.fixProcess.bind(this)} className="btn btn-success">{t('fix_and_reupload')}</button>
                                {this.state.statusCode == 11 ?
                                    <button type="button" onClick={this.hideSlideBlock.bind(this)} className="btn btn-default">{t('cancel')}</button>
                                :
                                    <button type="button" onClick={this.ignoreProcessError.bind(this)} className="btn btn-default">{t('ignore')}</button>
                                }
                                
                            </div>
                            }
                        </div>
                    </div>
                </div>
                {/* <div className={"dataLoadpage " +(this.state.loadshow)}></div> */}
                <div className={"loader "+this.state.loadshow}></div>
                <div className={"overlay-part "+(this.state.overly)} onClick={this.hideSlideBlock.bind(this)}></div>


                <Modal closeOnOverlayClick={false} open={this.state.openTimeOptimizationParamModel} onClose={this.handelMaterialConfigurationModelCancel} 
                    classNames={{modal: 'pickupdrop-modelClass'}} >
                        <div className="col-sm-12">
                        <div>
                            <h4>{t('optimize_by_time_details')}</h4>
                        </div>
                    {/* <form className="theme-form" onSubmit={this.formMaterialDetails}> */}

                        <div className="row number">
                        {/* <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{Constant.MASTER_VEHICLE_DATA_CONFIGURATION_BOUND_LOCATION}</label>
                                    <Select
                                        isDisabled
                                        name="location"
                                        value={this.state.location}
                                         />
                                </div>
                            </div> */}

                            {/* <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{Constant.MASTER_VEHICLE_DATA_CONFIGURATION_VEHICLE_TYPE}</label>
                                    <Select
                                        placeholder={"Select"}
                                        onChange={this.vehicleTypeChanged}
                                        name="vehicle_type"
                                        value={this.state.vehicle_type}
                                        options={this.state.vehicleTypeList} 
                                        />

                                </div>
                            </div> */}

                            {/* <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">Average Speed(KMPH) </label>
                                    <input required type="number" min='5' step={5}  className="forminp form-control" id="average_speed" name="average_speed" value={this.state.average_speed} 
                                    onChange={this.materialDetailsInputFields}
                                     placeholder="KMPH" autoComplete="off" />
                                    
                                </div>
                            </div> */}
                            <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">Max Time Limit(Hrs) </label>
                                    <input required type="number" min='1' step={1}  className="forminp form-control" id="time_limit" name="time_limit" value={this.state.time_limit} 
                                    onChange={this.materialDetailsInputFields}
                                     placeholder="Hrs" autoComplete="off" />
                                    
                                </div>
                            </div>
                           

                        </div>
                        <div className="form-group">
                            <button type="button" className="btn tbtn float-right save-btn" onClick={this.saveTimeOptimizationParams}>{t('save')}</button>
                            <button type='button' className="btn tbtn float-right cancel-btn" onClick={this.handelMaterialConfigurationModelCancel}>{t('cancel')}</button>	
                        </div>
                    {/* </form> */}
                </div>
                    </Modal>

                    <Modal closeOnOverlayClick={false} open={this.state.openSpotVehicleModel} onClose={this.handelSpotVehicleModelCancel} 
                    classNames={{modal: 'spotVehicle-modelClass'}} >
                        <div className="col-sm-12">
                        <div>
                            <h4>{t('spot_vehicles_to_be_planned')}</h4>
                        </div>
                    
                        <form className="theme-form" id='dataForm' onSubmit={this.saveNoOfSpotVehicles}>
                        <div className="number">
                            
                            {/* {this.state.no_of_vehicles_required <= 0 || this.state.no_of_vehicles_required == undefined ? */}
                            {this.state.noOfSpotVch===false?

                            <div className="col-md-4 col-sm-12">
                            <div className="form-group ">
                                <label className="col-form-label f12">{t('no_of_spot_vehicles')} </label>
                                <input required type="number" min='1' step={1}  className="forminp form-control" id="no_of_vehicles_required" name="no_of_vehicles_required" value={this.state.no_of_vehicles_required} 
                                onChange={this.materialDetailsInputFields}
                                    autoComplete="off" />
                                <button type='button' className="mt-4 btn tbtn float-center cancel-btn" onClick={this.onClickOk}>{t('ok')}</button>	
                            </div>
                            </div>
                            :
                            <ul>
                                {Array.from({ length: this.state.no_of_vehicles_required }).map((arr, idx) => (
                                <React.Fragment key={idx}>
                                {
                                    <SpotVehicle
                                    vehicle_type_list={this.state.vehicleTypeList}
                                    id={idx}
                                    onCollectData={this.collectSpotVehicleData}/>
                                }
                                </React.Fragment>
                                ))}
                            </ul> 
                        }
                        </div>
                        <div className="form-group">
                            <button type="submit" className="btn tbtn float-right save-btn" >{t('save')}</button>
                            <button type='button' className="btn tbtn float-right cancel-btn" onClick={this.handelSpotVehicleModelCancel}>{t('cancel')}</button>	
                        </div>
                    </form>
                </div>
                </Modal>

                
                
          
                

            </>
        )
    }
}

export default withTranslation()(PlanNewDispatch)
function base64DecodeUnicode(str) {
    // Convert Base64 encoded bytes to percent-encoding, and then get the original string.
   var percentEncodedStr = atob(str).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join('');


    return decodeURIComponent(percentEncodedStr);
}

function ValidationCellEditor() {}

ValidationCellEditor.prototype.init = function(params) {
  this.eGui = document.createElement('div');
  
  if(params.value === undefined){
    params.value = 'EnterValue'
  }
  this.eGui.innerHTML = `
    <input value=${params.value} />
  `;
  this.eInput = this.eGui.querySelector('input');
  this.eInput.addEventListener('input', this.inputChanged.bind(this));
  
}

ValidationCellEditor.prototype.inputChanged = function(event) {
  const val = event.target.value;
  if(!this.isValid(val)) {
    this.eInput.classList.add('invalid-cell');
  } else {
    this.eInput.classList.remove('invalid-cell');
  }
}

ValidationCellEditor.prototype.isValid = function(value) {
    // console.log("isNaN(value) ", isNaN(value))
    if(isNaN(value) === false)
    {
        return (value.length >= 1 || value.length === 20);
    }
}

ValidationCellEditor.prototype.getValue = function() {
  return parseInt(this.eInput.value);
}

ValidationCellEditor.prototype.isCancelAfterEnd = function() {
  return !this.isValid(this.eInput.value);
}

ValidationCellEditor.prototype.getGui = function() {
  return this.eGui;
}

ValidationCellEditor.prototype.destroy = function() {
  this.eInput.removeEventListener('input', this.inputChanged);
}

function validateDate(dateString) {
    // Define a regular expression pattern to match the format "yyyy-mm-dd HH:MM"
    const datePattern = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}$/;

    // Check if the date string matches the pattern
    if (!datePattern.test(dateString)) {
        return false;
    }

    // Split the date string into date and time parts
    const [datePart, timePart] = dateString.split(' ');

    // Parse the date and time separately to validate them
    const [year, month, day] = datePart.split('-');
    const [hours, minutes] = timePart.split(':');

    // Create a new Date object and validate the components
    const date = new Date(year, month - 1, day, hours, minutes);

    // Check if the parsed date is valid
    if (
        date.getDate() == day &&
        date.getMonth() == month - 1 &&
        date.getFullYear() == year &&
        date.getHours() == hours &&
        date.getMinutes() == minutes
    ) {
        return true;
    } else {
        return false;
    }
}