import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import Modal from "react-responsive-modal";
// import FPWConfigL1 from "../optirun-admin/plantWConfigL1";
import FPWConfigL1 from "./forcastconfig";
function ConfigInForecast(props) {
  const { t } = useTranslation();

  var { openConfigmd } = props;
  var { handleOnClose, data } = props;
  var {updatemodalstates,config,configdata,scenario,scenariodata,
    demand_increase,demand_decrease,setDemandDecrease,setDemandIncrease,upcountry,local,vfr,serviceLevel,time_increase,time_decrease,
    setUpcountry,setLocal,setVFR,setServiceLevel,setTimeIncrease,setTimeDecrease  } = props;
  console.log("first", openConfigmd, data);
  var newData = {
    IN416: { type: "number", disabled: false, value: 4, name: "IN416" },
    IN436: { type: "number", disabled: false, value: 5, name: "IN436" },
    IN417: { type: "number", disabled: false, value: 2, name: "IN417" },
    "Auto Select Vehicles": {
      type: "checkbox",
      disabled: false,
      value: 0,
      name: "autoselect",
    },
    "Vehicle Availability": {
      type: "checkbox",
      disabled: true,
      value: 1,
      name: "autoselect",
    },
    // type: "checkbox",
    // disabled: false,
    // value: 1,
    // name: "autoselect",
  };
  var uppKeys = [
    "Prioritize Local Loads",
    "City Based Entry Restriction",
    "Follow Telescopic Routes",
    "Consider Vehicle Break Times (Night Driving)",
    "Consider Dropping Distant Smaller Loads",
    "Consider Holiday Restrictions",
    "Consider Realtime Vehicle Available Times",
    "Plan Spot Vehicles",
    "Consider Transporter SOB",
  ];

  var lower1 = [
    "Use Dynamic Planning Window",
    "Number of Days to Use Planning Window",
    "Optimization Type*",
  ];

  var lower2 = [
    // "Use Volume for Vehicle Fill Rate (VFR)",
    // "Use Weight for Vehicle Fill Rate (VFR)",
    "Min VFR Per Vehicle Type",
    "Min VFR Per Spot Vehicle",
    "Vehicle Availability",
    "Auto Select Vehicles",
    "IN416",
    "IN436",
    "IN417",
    // "MOQ for Planning",
  ];
  var lower3 = [
    "Number of Loading Docks",
    "Max No of Customers Per Vehicle",
    "Max Local No of Customers Per Vehicle",
    "Max Upcountry No of Customers Per Vehicle",
    "Prioritize Same Dealers First in a Vehicle",
    "Prioritize Multi City Dealers First in a Vehicle",
  ];
  var k = "Auto Select Vehicles";
  var uppOpt = {},
    lowOpt1 = {},
    lowOpt2 = {},
    lowOpt3 = {};
  // console.log(Object.keys(configs,'len'))
  var configs = {};
  if (Object.keys(data).length) {
    // configs = data;
    // configs["Auto Select Vehicles"] = newData;
    configs = { ...newData, ...data };
  } else {
    openConfigmd = false;
  }
  if (Object.keys(configs).length > 0) {
    uppKeys = uppKeys.filter((key) => configs.hasOwnProperty(key));
    uppKeys.map((item) => {
      uppOpt[item] = configs[item];
      //   uppOpt[item].disabled = true;
      return "";
    });
    lower1 = lower1.filter((key) => configs.hasOwnProperty(key));
    lower1.map((item) => {
      lowOpt1[item] = configs[item];
      //   lowOpt1[item].disabled = true;
      // if (item === "Optimization Type*") {
      //   lowOpt1["Optimization Type*"]["Cost Optimization"].disabled = true;
      //   lowOpt1["Optimization Type*"][
      //     "Weight based optimization"
      //   ].disabled = true;
      //   lowOpt1["Optimization Type*"][
      //     "Distance based optimization"
      //   ].disabled = true;
      //   lowOpt1["Optimization Type*"][
      //     "Time based optimization"
      //   ].disabled = true;
      // }
      return "";
    });
    lower2 = lower2.filter((key) => configs.hasOwnProperty(key));
    lower2.map((item) => {
      lowOpt2[item] = configs[item];
      //   lowOpt2[item].disabled = true;
      return "";
    });
    lower3 = lower3.filter((key) => configs.hasOwnProperty(key));
    lower3.map((item) => {
      lowOpt3[item] = configs[item];
      //   lowOpt3[item].disabled = true;
      return "";
    });
  }

  const updatemodalstatesscenario =() =>{
    updatemodalstates('scenario')
  }

  const updatemodalstatesconfig =() =>{
    updatemodalstates('config')
  }


  const handleDemandIncrease = (e) =>{
    setDemandIncrease(e.target.value)
  }
  const handleDemandDecrease = (e) =>{
    setDemandDecrease(e.target.value)
  }

  const handleTimeIncrease = (e) =>{
    setTimeIncrease(e.target.value)
  }

  const handleTimeDecrease = (e) =>{
    setTimeDecrease(e.target.value)
  }

  const handleVFR = (e) =>{
    setVFR(e.target.value)
  }

  const handleServiceLevel = (e) =>{
    setServiceLevel(e.target.value)
  }

  const handleUpcountry = (e) =>{
    setUpcountry(e.target.value)
  }

  const handleLocal = (e) =>{
    setLocal(e.target.value)
  }

  return (
    <>
      <Modal
        closeOnOverlayClick={false}
        open={openConfigmd}
        onClose={handleOnClose}
        classNames={{ modal: "forecast-modelClass" }}
      >
        <div className="">
          <div className="row mt-3p">
                  <div className="col-md-12 col-sm-12">
                    <ul className="d-tabs">
                      <li
                        onClick={updatemodalstatesconfig}
                        className={"lttabs " + config}
                      >
                        <button type="button" className="btn">
                        {t('planning_configurations')}
                        </button>
                      </li>
                      <li
                        onClick={updatemodalstatesscenario}
                        className={"lttabs " + scenario}
                      >
                        <button type="button" className="btn">
                          {t('planning_scenarios')}
                        </button>
                      </li>
                      </ul>
                  </div>
          </div>
          
          <div className={"row mt-1 " + scenariodata}>
              <div
              style={{
                borderStyle: "solid",
                padding: "20px",
                paddingTop: "10px",
                paddingBottom: "10px",
                borderWidth: "3px",
                borderColor: "lightgrey",
                borderRadius: "10px",
                marginTop: "0",
                paddingLeft: "-10px",
              }}
              className="float-right col-sm-12 d-flex flex-column mt-0 pl-0 pr-0"
            >
              <h6
                className="mb-0 mt-3 pl-2"
                style={{
                  fontWeight: "bold",
                  color: "black",
                  padding: "0",
                  margin: "0",
                }}
              >
                {t('scenarios')}
              </h6>
              {/* {Object.keys(uppOpt).length > 0 ? (
                <div
                  className="d-flex flex-row mt-10p"
                  style={{ marginTop: "10px" }}
                >
                  <FPWConfigL1 data={uppOpt} colLength={4} />
                </div>
              ) : (
                ""
              )} */}
              <div className="col-sm-12">
                <div className="col-sm-6">
                  <div className="pt-10p" style={{height:'100px'}}>
                    <h6 style={{ color: "black"}}>1. {t('what_if_demand_fluctuation')}</h6>
                    <div className="pad-left col-sm-12">
                    <input className="col-sm-1 pad-left" style={{marginTop:'3px'}} type="checkbox" checked={demand_increase} value={demand_increase} onClick={handleDemandIncrease} ></input>
                    <span className="col-sm-6" style={{ color: "black", fontSize: "10" }}>{t('increase_decrease_demand_by')}</span>
                    <input className="col-sm-3" disabled={demand_increase?false:true} type="number" min={-50} max={100} step={5}></input> &nbsp;&nbsp; %
                    </div>

                    {/* <div className="pad-left col-sm-12 " style={{marginTop:'3px'}}>
                    <input className="col-sm-1 pad-left" style={{marginTop:'3px'}} type="checkbox" checked={demand_decrease} value={demand_decrease} onClick={handleDemandDecrease}></input>
                    <span className="col-sm-6" style={{ color: "black", fontSize: "10" }}>Decrease demand by</span>
                    <input className="col-sm-3" disabled={demand_decrease?false:true} type="number" min={0} step={5}></input> &nbsp;&nbsp; %
                    </div> */}
                  </div>

                  <div className="pt-10p" style={{height:'100px'}}>
                    <h6 style={{ color: "black"}}>2. {t('what_if_customer_priority_changes')}</h6>
                    <div className="pad-left col-sm-12">
                    <input className="col-sm-1 pad-left" style={{marginTop:'3px'}} type="checkbox" checked={upcountry} value={upcountry} onClick={handleUpcountry}></input>
                    <span className="col-sm-6" style={{ color: "black", fontSize: "10" }}>{t('prioritize_upcountry')}</span>
                    {/* <input className="col-sm-3" type="number" ></input> &nbsp;&nbsp; % */}
                    </div>

                    <div className="pad-left col-sm-12 " style={{marginTop:'3px'}}>
                    <input className="col-sm-1 pad-left" style={{marginTop:'3px'}} type="checkbox" checked={local} value={local} onClick={handleLocal}></input>
                    <span className="col-sm-6" style={{ color: "black", fontSize: "10" }}>{t('prioritize_local')}</span>
                    {/* <input className="col-sm-3" type="number" ></input> &nbsp;&nbsp; % */}
                    </div>
                  </div>

                  <div className="pt-10p" style={{height:'100px'}}>
                    <h6 style={{ color: "black"}}>3. {t('what_if_service_level_target_changes')}</h6>
                    <div className="pad-left col-sm-12">
                    <input className="col-sm-1 pad-left" style={{marginTop:'3px'}} type="checkbox" checked={serviceLevel} value={serviceLevel} onClick={handleServiceLevel}></input>
                    <span className="col-sm-7" style={{ color: "black", fontSize: "10" }}>{t('set_service_level_target_to')}</span>
                    <input className="col-sm-3" disabled={serviceLevel?false:true} type="number" min={0} step={5}></input> &nbsp;&nbsp; %
                    </div>
                  </div>

                  <div className="pt-10p" style={{height:'100px'}}>
                    <h6 style={{ color: "black"}}>4. {t('what_if_vehicle_fill_rate_changes')}</h6>
                    <div className="pad-left col-sm-12">
                    <input className="col-sm-1 pad-left" style={{marginTop:'3px'}} type="checkbox" checked={vfr} value={vfr} onClick={handleVFR}></input>
                    <span className="col-sm-7" style={{ color: "black", fontSize: "10" }}>{t('set_vehicle_fill_rate_to')}</span>
                    <input className="col-sm-3" disabled={vfr?false:true} type="number" min={0} step={5}></input> &nbsp;&nbsp; %
                    </div>
                  </div>

                </div>

                <div className="col-sm-6">
                  <div className="pt-10p" style={{height:'150px'}}>
                    <h6 style={{ color: "black"}}>5. {t('what_if_customer_specific_delivery_time')}</h6>
                    <div className="pad-left col-sm-12">
                    <input className="col-sm-1 pad-left" style={{marginTop:'3px'}} type="checkbox" checked={time_increase} value={time_increase} onClick={handleTimeIncrease}></input>
                    <span className="col-sm-6" style={{ color: "black", fontSize: "10" }}>{t('increase_decrease_delivery_time_window_by')}</span>
                    <input className="col-sm-3" disabled={time_increase?false:true} type="number" min={-50} max={100} step={5} ></input> &nbsp;&nbsp; %
                    </div>

                    {/* <div className="pad-left col-sm-12 " style={{marginTop:'3px'}}>
                    <input className="col-sm-1 pad-left" style={{marginTop:'3px'}} type="checkbox" checked={time_decrease} value={time_decrease} onClick={handleTimeDecrease}></input>
                    <span className="col-sm-6" style={{ color: "black", fontSize: "10" }}>Decrease Delivery Time Window by</span>
                    <input className="col-sm-3" disabled={time_decrease?false:true} type="number" min={0} step={5}></input> &nbsp;&nbsp; %
                    </div> */}
                  </div>

                  <div className="pt-10p col-sm-12 d-flex" style={{height:'100px'}}>
                    <h6 style={{ color: "black"}}>{t('scenario_name')}</h6>
                    <input className="col-sm-9 pad-left" style={{marginTop:'3px',height:'50px'}} type="text" ></input>
                  </div>

                  <div className="pt-10p col-sm-12" >
                    <h6 style={{ color: "black"}}>{t('scenario_description')}</h6>
                    <textarea className="col-sm-12 pad-left" rows='3' style={{marginTop:'3px'}} ></textarea>
                  </div>
                  
                </div>
              </div>
            </div>
            <div className="float-right col-sm-12 mt-3">
              <div className="form-group">
                <button
                  type="button" style={{minWidth: "fit-content"}}
                  className="btn tbtn float-right cancel-btn"
                  onClick={handleOnClose}
                >
                  {t('cancel')}
                </button>
                <button
                  type="button"
                  onClick={handleOnClose}
                  className="btn tbtn float-right save-btn"
                >
                  {t('save')}
                </button>
              </div>
            </div>
          </div>

          <div className={"row mt-1 " + configdata}>
          <form
            className="theme-form row mt-0"
            id="config"
            // onSubmit={this.savePlantConfigs}
          >
            <div
              style={{
                borderStyle: "solid",
                padding: "20px",
                paddingTop: "10px",
                paddingBottom: "10px",
                borderWidth: "3px",
                borderColor: "lightgrey",
                borderRadius: "10px",
                marginTop: "0",
                paddingLeft: "-10px",
              }}
              className="float-right col-sm-12 d-flex flex-column mt-0 pl-0 pr-0"
            >
              <h6
                className="mb-0 mt-3 pl-2"
                style={{
                  fontWeight: "bold",
                  color: "black",
                  padding: "0",
                  margin: "0",
                }}
              >
                {t('general_settings')}
              </h6>
              {Object.keys(uppOpt).length > 0 ? (
                <div
                  className="d-flex flex-row mt-10p"
                  style={{ marginTop: "10px" }}
                >
                  <FPWConfigL1 data={uppOpt} colLength={4} />
                </div>
              ) : (
                ""
              )}
            </div>
            <div className=" ml-0" style={{ marginLeft: "0px" }}>
              <div
                className="col-sm-4 ml-0 mt-1 pl-3"
                style={{
                  borderStyle: "solid",
                  borderWidth: "3px",
                  borderColor: "lightgrey",
                  borderRadius: "10px",
                  paddingBottom: "41px",
                  height: "98%"
                }}
              >
                <h6
                  className="mb-0 mt-3"
                  style={{ fontWeight: "bold", color: "black" }}
                >
                  {t('planning_optimization_settings')}
                </h6>
                {Object.keys(lowOpt1).length > 0 ? (
                  <FPWConfigL1 screen={""} data={lowOpt1} colLength={12} />
                ) : (
                  ""
                )}
              </div>
              <div
                className="col-sm-4 ml-0 mt-1 "
                style={{
                  borderStyle: "solid",
                  borderWidth: "3px",
                  borderColor: "lightgrey",
                  borderRadius: "10px",
                  paddingBottom: "20px",
                  height: "98%",
                }}
              >
                <h6
                  className="mb-0 mt-3 mr-3"
                  style={{ fontWeight: "bold", color: "black" }}
                >
                  {t('vehicle_fill_rate_vfr_settings')}
                </h6>
                <div style={{ padding: "0", margin: "0" }}>
                  {Object.keys(lowOpt2).length > 0 ? (
                    <FPWConfigL1 data={lowOpt2} />
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div
                className="col-sm-4 ml-0 mt-1"
                style={{
                  borderStyle: "solid",
                  borderWidth: "3px",
                  borderColor: "lightgrey",
                  borderRadius: "10px",
                  height: "98%",
                }}
              >
                <h6
                  className="mb-0 mt-2"
                  style={{
                    fontWeight: "bold",
                    color: "black",
                    padding: "0",
                    margin: "0",
                  }}
                >
                  {t('load_planning_settings')}
                </h6>
                <div className="mt-0" style={{ padding: "0", margin: "0" }}>
                  {Object.keys(lowOpt3).length > 0 ? (
                    <FPWConfigL1 data={lowOpt3} />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            {/* <div className="float-right col-sm-12 mt-3">
              <div className="form-group">
                <button
                  type="button"
                  className="btn tbtn float-right cancel-btn"
                  onClick={handleOnClose}
                >
                  CANCEL
                </button>
                <button
                  type="button"
                  onClick={handleOnClose}
                  className="btn tbtn float-right save-btn"
                >
                  SAVE
                </button>
              </div>
            </div> */}
          </form>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ConfigInForecast