import React, { Component } from 'react';
import redirectURL from '../redirectURL';
import Select from 'react-select';
import ColumnChartLoadingDashboard from './columnChartLoadingDashboard';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
import 'ag-grid-enterprise';
import ColumnChartUnLoadingDashboard from './columnChartUnloading';
import SweetAlert from 'react-bootstrap-sweetalert';

import Datetime from 'react-datetime';

import {getHyphenYYYYMMDDHHMMSS} from "../common/utils"
var moment = require('moment');
export default class UnLoadingDashboard extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: '',
            show: false,
            fromDate: moment.parseZone().subtract(2, 'months').format('YYYY-MM-DD'),
            toDate:moment.parseZone().format('YYYY-MM-DD'),
            consigner:"",
            location:"",
            transporter:"",
            plants:[],
            citiesList:[],
            dealersList:[],
            flPlant:{label:'All',value:''},
            flCity:"",
            flDealer:"",
            pre_sdate:moment.parseZone().subtract(2, 'months').format('YYYY-MM-DD'),
			pre_edate:moment.parseZone().format('YYYY-MM-DD'),
            state:"",
            statesList:[],
            flState:[{value:'ANDHRA PRADESH',label:'ANDHRA PRADESH'}],
            show:false,
            basicTitle:'',
            basicType:'default',
            locationNames:[],
            location:'',
            defaultColDef : {
                sortable: true,
                filter: true,
                editable: false,
                resizable: true,
                minWidth: 70,
                initialWidth: 200,
                wrapHeaderText: true,
                autoHeaderHeight: true,
            },
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left"
                    },
                    {
                        statusPanel: "agTotalRowCountComponent",
                        align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" }
                ]
            },
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel"
                    },
                    {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ]
            },

            loadshow: 'show-n',
            overlayBlock:'show-n',

        }
    }
    // {label:'Andhra Pradesh',value:'Andhra Pradesh'}
    async componentDidMount() {
        this.setState({
            loadshow: "show-m",
            overly: "show-m",
            overlayBlock:"show-m"
        })
        var dpt
        let params = {
            data_type : 2
        }
		this.boundTypeLocations(params)        
        redirectURL.post("/tracking/plants", { dept_code: (dpt) })
				.then(async(resp) => {
					let responseData = resp.data.plants;                    
                    var plants = [{
                        label: "All",
                        value: "",
                    }]
                    
                    var values=[]
                    responseData.map(function (p) {
                        if(!values.includes(p.plant_code)){
                        plants.push({
                            label:p.plant_name,
                            value: p.plant_code,
                        });}
                        values.push(p.plant_code)
                    });

					this.setState({
						plants
					});
				})
				.catch((err)=>{
					var errDetails={
						url:"/tracking/plants",
						screen:'Trips',
						err,
					}
					redirectURL.post("/master/logErrorsForApiCalls",errDetails)
					
				})
				
			// console.log(localStorage.getItem('roles'),"line 395")
        redirectURL.post("/tracking/getConsignmentsNewFilterKeysForSndTrucks", { dept_code: (dpt)})
            .then((resp) => {
                var data = resp.data.data
                
                var cities = data.cities;
                var filterCities = [];
                if (cities.length > 0) {
                    filterCities.push({
                        label: "All",
                        value: "",
                    });
                    cities.map(function (c) {
                        filterCities.push({
                            label: c,
                            value: c,
                        });
                    });
                }
                var customers = data.customers;
                var filterCustomers = [];
                if (customers.length > 0) {
                    filterCustomers.push({
                        label: "All",
                        value: "",
                    });
                    customers.map(function (c) {
                        filterCustomers.push({
                            label:c.location_name,
                            value: c.location_code,
                            plantCode:c.drop_corresponding_pickup_location_code,
                        });
                    });
                }

                this.setState({
                    citiesList: filterCities,
                    dealersList: filterCustomers,
                });
            })
            .catch((err)=>{
                // console.log('hit err here for the')
                var errDetails={
                    url:"/tracking/getConsignmentsNewFilterKeysForSndTrucks",
                    screen:'Trips',
                    err,
                }
                // console.log('hitttt',errDetails)
                redirectURL.post("/master/logErrorsForApiCalls",errDetails)
            })

        await this.getStates()
        await this.getUnloadingData()

    }

    boundTypeLocations = async(param) => {

        let location_access = JSON.parse(localStorage.getItem("location_access"))

        let locationNames = [];

        if(location_access == null){
            locationNames.push({label:'ALL',value:''})
        }
        
        await redirectURL.post('master/getBoundTypeLocations',param)
          .then(async (response) =>{
              if(response.data.status == 'success'){
                  let boundLocations = response.data.boundLocations
                  if(boundLocations.length > 0){
                      boundLocations.map(item =>{
                        if(location_access != null){
                            if(location_access.includes(item.location_code)){
                                locationNames.push({ value : item.location_code,
                                                    label : item.location_name })

                                }
                            }else{
                                locationNames.push({ value : item.location_code,
                                                    label : item.location_name })
                            }
                      })
                      
                      if(locationNames.length>0){
                        await this.setState({
                                    locationNames : locationNames,
                                    location : locationNames[0]
                                })
                    }else{
                        await this.setState({
                            locationNames : [{label:'Please Add Data',value:'no_data'}],
                            location : [{label:'Please Add Data',value:'no_data'}]
                        })
                    }
                  }
              }
          })
          .catch(function (error) {
            var errDetails={
                url:"master/getBoundTypeLocations",
                screen:'Trips Screen',
              error,
              }
            redirectURL.post("/master/logErrorsForApiCalls",errDetails)
            
        })
      }

    closeAlert = async() => {
        var {statesList}=this.state
        await this.setState({
            show: false,
            fromDate: moment.parseZone().subtract(2, 'months').format('YYYY-MM-DD HH:mm'),
            toDate:moment.parseZone().format('YYYY-MM-DD HH:mm'),
            plantCode:"",
            flstate:statesList[0],
            flCity:'',
            flDealer:'',
        });
        // this.getAnalysisData()
	}
    async getStates(){
        await redirectURL.post("/tracking/getStatesForDashboard")
        .then(async(resp) => {
            // console.log(resp.data,'hello')
            var states=[]
            resp.data.map((item)=>{
                states.push({label:item.name,value:item.name})
            })
            if(states.length){
            await this.setState({statesList:states,flState:states[0]})}
        })

    }
    getUnloadingData = () => {
        this.setState({
            loadshow:"show-m",
            overlayBlock:"show-m"
        })
        let consignees = this.state.flDealer
        let fromDate = this.state.fromDate
        let toDate = this.state.toDate
        var cities = this.state.flCity
        var plantCodes = this.state.flPlant 
        // console.log(plantCodes,'heloo')
        var flstate=this.state.flState
        var consignee_codes = []
        var consignee_city = []
        var plant_code = []
        var states=[]

        // console.log(flstate,'hiii')
        if(flstate.length && flstate!==''){
            flstate.map((item)=>{
                states.push(item.value)
            })
        }
        if (consignees.length>0){
            consignees.map(e => 
                consignee_codes.push(e.value))            
        }
        if(cities.length > 0){
            cities.map(c => 
                consignee_city.push(c.label))
        }

        if(plantCodes.value!==''){
            plant_code.push(plantCodes.value)
        }
        // if(plantCodes!==''){
            
        //     plantCodes=plantCodes.value
        // }
        // console.log(plant_code,'plant_code')
        var params= {            
            consignee_codes,
            from_date:getHyphenYYYYMMDDHHMMSS(fromDate),
            to_date:getHyphenYYYYMMDDHHMMSS(toDate), 
            consignee_city,           
            plant_code:plant_code,
            states
        }
        // console.log(params)

        redirectURL.post("/tracking/getUnloadingData",params)
        .then(async(response) => {
            // console.log(response.data, 'response')
            
            var data = response.data
            if(data.status!=="Failure"){
                var keys=Object.keys(data)
                if(Object.keys(data).length>0){
                    var check=true
                    keys.map((item)=>{
                        // console.log(data[item]==='[]','chekc',data)
                        if(data[item]!=='[]'){
                            check=false
                        }
                    })
                    if(!check){
                        this.setState({ 
                            data: data, 
                            loadshow:"show-n",
                            overlayBlock:"show-n"
                        })
                    }else{
                        await this.setState({
                            show:true,
                            basicTitle:"No Data Available",
                            basicType:"danger",  
                            loadshow:"show-n",
                            overlayBlock:"show-n"                      
                        })
                    }
                }else{
                    await this.setState({
                        show:true,
                        basicTitle:"No Data Available",
                        basicType:"danger",   
                        loadshow:"show-n",
                        overlayBlock:"show-n"                     
                    })
                }
            }        
            else{
                await this.setState({
                    show:true,
                    basicTitle:"No Data Available",
                    basicType:"danger",  
                    loadshow:"show-n",
                    overlayBlock:"show-n"                      
                })
            }
            // console.log(data)
            // }
        })
        .catch((err)=>{
            var errDetails={
                url:"/tracking/getUnloadingData",
                screen:'unloadingDashboard',
                err,
            }
            redirectURL.post("/master/logErrorsForApiCalls",errDetails)    
            this.setState({
                loadshow:"show-n",
                overlayBlock:"show-n"
            })        
        })



    }
    onClickSubmit = async() => {
        this.getUnloadingData()
    }

    handlerStartDateTime = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);
		this.pre_sdate=this.state.fromDate
		var fromdate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());
		var pre_sdate=this.state.fromDate
		this.setState({
			fromDate: fromdate,
			pre_sdate:pre_sdate
		});
		//let value = event.target.value;
		//this.setState({'startDate':value});
	}

	handlerEndDateTime = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);
		
		// this.pre_edate=this.state.endDate
		var todate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate())
		var pre_edate=this.state.toDate
		this.setState({
			toDate: todate,
			pre_edate
		});
	}


    newFilter = (filterType, selOption) => {
        
		this.setState({ [filterType]: selOption });
        
        // console.log(filterType,selOption,'hei')
	}

    render() {
        var { data } = this.state

        var preferredTimeSlot = [], categories = {}, i = 0
        var cityUldData = [], cityDeaUldData = []
        var top10Dealers=[]
        if (data !== '') {
            preferredTimeSlot = JSON.parse(data.Preferred_time_slot)
            cityDeaUldData = JSON.parse(data.city_dealer_uld_data)
            cityUldData = JSON.parse(data.city_uld_data)
            top10Dealers= JSON.parse(data['top_10 _dealer_data'])
            // console.log(data,'helo')
            // console.log(cityUldData,'city uld data')
        }
        
        // console.log('hh',data)
        
        var cityUnloadingData = [], prefTimeSlot = [], uldCityDeaData = []
        var uldCitytrip = [], cityUnloadintrip = [], cityUnloadingCat = {}, cityDealerCat = {}
        preferredTimeSlot.map((item) => {
            
            prefTimeSlot.push(item.preferred_time_slot)
            // prefTimeSlot.push(item['Preferred Time Slot'])
            categories[i] = item.consignee_name
            i += 1
        })
        i = 0
        cityDeaUldData.map((item) => {
            uldCityDeaData.push(parseFloat((item.unloading_time_in_hrs.toFixed(2))))
            uldCitytrip.push((item.no_of_trips))
            cityDealerCat[i] = item.consignee_name
            i += 1
        })
        i = 0
        cityUldData.map((item) => {
            cityUnloadingData.push(parseFloat((item.unloading_time_in_hrs.toFixed(2))))
            cityUnloadintrip.push(item.no_of_trips)
            cityUnloadingCat[i] = item.consignee_city
            i += 1
        })
        var series = [
            {
                name: 'Preferred Time Slot',
                data: prefTimeSlot
            }
        ]
        // console.log(uldCityDeaData,'kkk')
        var cityDealUldSeries = [
            {
                name: 'Unloading Time(hrs)',
                data: uldCityDeaData,
                tooltip: {
                    pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y} Hrs</b><br/>' // custom tooltip format for series 1
                }
            },
            {
                name: 'No of Trips',
                data: uldCitytrip,
                tooltip: {
                    pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y} </b><br/>' // custom tooltip format for series 1
                }
            }
        ]
        var cityUldSeries = [
            {
                name: 'Unloading Time(hrs)',
                data: cityUnloadingData,
                tooltip: {
                    pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y} Hrs</b><br/>' // custom tooltip format for series 1
                }
            },
            {
                name: 'No of Trips',
                data: cityUnloadintrip,
                tooltip: {
                    pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y} </b><br/>' // custom tooltip format for series 1
                }
            }

        ]
        // console.log(typeof [], 'ca')
        var columnwithDefs = [
            {
                headerName: "City",
                field: "consignee_city",
                // width: 150
            },
            {
                headerName: "Code",
                field: "consignee_code",
                // width: 150
            },
            {
                headerName: "Dealer Name",
                field: "consignee_name",
                // width: 150
            },
            {
                headerName:"State",
                field:"consignee_state",
                // width:150,
                valueGetter: function (params) {
                    if (params.data["consignee_state"] !== undefined || params.data["consignee_state"] !== null) {
                        return params.data["consignee_state"];
                    }
                    else {
                        return "N/A"
                    }
                }
            },
            {
                headerName: "No of Trips",
                field: "no_of_trips",
                // width: 150
            },
            {
                headerName: "Unloading Time (Hrs)",
                field: "unloading_time_in_hrs",
                // width: 150
            },
            
        ]

        let tooltip={
            headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
            pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b><br/>'
        }
        let selectedPlant = this.state.flPlant;
        // console.log(selectedPlant.value)
        let filterCustomers = this.state.dealersList;
        var filteredCustomers 
        if (selectedPlant.value !== ""){
			var filteredCustomers = filterCustomers.filter(each => each.plantCode === selectedPlant.value);
		}else {
			filteredCustomers = filterCustomers;	
		}
        console.log(filteredCustomers.length)

        return (
            <>
            <SweetAlert
                show={this.state.show}
                type={this.state.basicType}
                title={this.state.basicTitle}
                onConfirm={this.closeAlert}
            >
            </SweetAlert>
            <div className="row">
                <div className="row col-xl-12 col-lg-12 mt-5p p-2p">
                    <div className="row col-xl-12 col-lg-12 mb-3p">                            
                        <div className="form-group col-xl-2 col-lg-2">
                            <label style={{color:"black"}}>Select Plant: </label>
                            <Select
                                maxHeight={200}
                                placeholder={"All"} closeMenuOnSelect={true}
                                value={this.state.flPlant} 
                                className="border-radius-0"
                                onChange={this.newFilter.bind(this,"flPlant")}
                                style={{ borderRadius: "0px" }}
                                options={this.state.locationNames}
                                required />
                        </div>

                        <div className="form-gplantwiseloadingroup col-xl-2 col-lg-2">
                            <label style={{color:"black"}}>Select City: </label>
                            <Select placeholder={"All"}
                                isMulti={true}
                                value={this.state.flCity}
                                className="border-radius-0"
                                onChange={this.newFilter.bind(this, "flCity")}
                                style={{ borderRadius: "0px" }}
                                options={this.state.citiesList}
                                required />

                        </div>
                        <div className="form-group col-xl-2 col-lg-2">
                            <label style={{color:"black"}}>Select Dealer : </label>
                            <Select
                                placeholder={"All"}
                                isMulti={true}
                                value={this.state.flDealer}
                                className="border-radius-0"
                                onChange={this.newFilter.bind(this, "flDealer")}
                                style={{ borderRadius: "0px" }}
                                options={filteredCustomers} required />
                        </div>
                        <div className="form-group col-xl-4 col-lg-4">
                            <label style={{color:"black"}}>Select State: </label>
                            <Select
                                maxHeight={200}
                                placeholder={'All'} closeMenuOnSelect={true}
                                value={this.state.flState} 
                                isMulti={true}
                                className="border-radius-0"
                                onChange={this.newFilter.bind(this,"flState")}
                                style={{ borderRadius: "0px", overflowY:"auto" }}
                                options={this.state.statesList}
                                required />
                        </div>
                        
                    </div>
                    <div className='row col-xl-12 col-lg-12 mb-3p'>
                        <div className="col-xl-2 col-lg-2 form-group">
                            <label style={{color:"black"}}>Start Date</label>
                            <Datetime
                                disableCloseOnClickOutside={false}
                                closeOnSelect={true}
                                inputProps={{ name: 'fromDate', autoComplete: 'off', readOnly: 'true'}}
                                dateFormat="YYYY-MM-DD"
                                timeFormat={false}
                                value={this.state.fromDate}
                                placeholder={moment.parseZone().format('YYYY-MM-DD')}
                                onChange={this.handlerStartDateTime} />
                        </div>
                        <div className="col-xl-2 col-lg-2 form-group">
                            <label style={{color:"black"}}>End Date</label>
                            <Datetime
                                disableCloseOnClickOutside={false}
                                closeOnSelect={true}
                                inputProps={{ name: 'toDate', autoComplete: 'off', readOnly: 'true'}}
                                dateFormat="YYYY-MM-DD"
                                timeFormat={false}
                                value={this.state.toDate}
                                placeholder={moment.parseZone().format('YYYY-MM-DD')}
                                onChange={this.handlerEndDateTime} />
                        </div>

                        <div className="form-group col-xl-2 col-lg-2">
                            <label>&nbsp;</label><br />
                            <button type="button" className="btn btn-success" onClick={this.onClickSubmit} >Submit</button>
                        </div>
                    </div>

                    <div className="row col-xl-12 col-lg-12 mb-3p">
                        <div className="col-xl-6 col-lg-6 mb-2 " style={{width:"50%",height:"40%",marginTop:"2em"}}>
                            <ColumnChartUnLoadingDashboard
                                title={"City Unloading Data"}
                                subtitle={""}
                                context={this}
                                xaxistitle={"City Name"}
                                tooltip={tooltip}
                                yaxistitle={"Unloading Time (Hrs)"}
                                pointpostfix={"Hrs"}
                                categories={cityUnloadingCat}
                                series={cityUldSeries}
                            />
                        </div>
                        <div className="col-xl-6 col-lg-6 mb-2 " style={{width:"50%",height:"40%",marginTop:"2em"}}>
                            <ColumnChartUnLoadingDashboard
                                title={"City Dealer Unloading Data"}
                                subtitle={""}
                                context={this}
                                xaxistitle={"Delaer Name"}
                                tooltip={tooltip}
                                yaxistitle={"Unloading Time (Hrs)"}
                                pointpostfix={"Hrs"}
                                categories={cityDealerCat}
                                series={cityDealUldSeries}
                            />
                        </div>
                    </div>
                   
                   <div className='row col-xl-12 col-lg-12 mb-3p'>
                   <div className="col-xl-6 col-lg-6 mb-3p" >
                        <ColumnChartUnLoadingDashboard                                
                            title={"Dealers Preferred Time Slot"}
                            subtitle={""}
                            context={this}
                            xaxistitle={"Dealer Name"}
                            yaxistitle={"Unloading Time (Hrs)"}
                            pointpostfix={"Hrs"}
                            categories={categories}
                            series={series}
                        />
                    </div>                     
                   </div>                   

                    <div className="col-xl-12 col-lg-12 m-2 p-1"
                        style={{ backgroundColor: "white" }}>
                        <h5>Top 10 Dealers Data </h5>
                        <div id="myGrid" style={{ height: "480px", width: "100%" }} className="ag-theme-balham">
                            <AgGridReact
                                // modules={top10Dealers}
                                columnDefs={columnwithDefs}
                                defaultColDef={this.state.defaultColDef}
                                rowData={top10Dealers}
                                enableCharts={true}
                                enableRangeSelection={true}
                                // onGridReady={this.onGridReady}
                                // onGridState={this.onGridState}
                                statusBar={this.state.statusBar}
                                sideBar={this.state.sideBar}
                                // paginationPageSize={this.state.paginationPageSize}
                                pagination={true}
                            />

                        </div>
                    </div>

                </div>


            </div> 

            <div
                className={"overlay-block " + this.state.overlayBlock}
                onClick={this.hideSlideBlock}
            ></div>
            <div className={"loader " + this.state.loadshow}></div>           
            </>

        );
    }
}

function distinctArrayBy(arr, propName) {
    var result = arr.reduce(function (arr1, e1) {
        var matches = arr1.filter(function (e2) {
            return e1[propName] == e2[propName];
        })
        if (matches.length == 0)
            arr1.push(e1)
        return arr1;
    }, []);

    return result;
}