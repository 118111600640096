import React, {Component} from 'react';
import {Navigate} from 'react-router-dom';
import redirectURL from "../redirectURL";
import './styles.css';
import Cookies from 'js-cookie';
import Canvas from "react-numeric-captcha";
import Lottie from 'react-lottie';
import WorldMap from '../../assets/json/truck_map_animation.json';
import "./captch.css";

export default class Login extends Component {
	constructor(props)
	{
		super(props);
		this.state={
			isNav:0,
			email:"",
			password:"",
			loginMessage:"",
			clickReset:false,
			isCaptchaValid:false,
			captchaMsg:''
		}
		this.changeHandler = this.changeHandler.bind(this);
	}
	
	componentDidMount(){
		// redirectURL.post("/login/welcome")
		// .then((response) => {
		// 	console.log("response ", response.data)
		// })
	}

	onClickGoToDashboard(){
		redirectURL.post("/login/welcome")
		.then((response) => {
			// console.log("response ", response.data)
			localStorage.setItem("token","1xEdr125dg6577dfgjseXbnddgxuy2kg")
		
			this.setState({
				isNav:1
			});
		})
		
	}
	changeHandler(event){
		var name = event.target.name;
		var value = event.target.value;
		this.setState({
			[name]:value
		})
	}
	formSubmit(event){
		event.preventDefault();
		if(this.state.email !=undefined && this.state.email !="" && this.state.password !=undefined && this.state.password !="")
		{
			var params = {
				e:this.state.email,
				d:this.state.password
			}
			redirectURL.post("/verify",params)
			.then((response) => {
				if(response.data.status == "success")
				{
				if (!this.state.isCaptchaValid){
					this.setState({captchaMsg:'Invalid Captcha',
									loginMessage:''})
					}else{
					localStorage.setItem("email",response.data.user[0].email);
					localStorage.setItem("firstname",response.data.user[0].firstname);
					localStorage.setItem("lastname",response.data.user[0].lastname);
					localStorage.setItem("username",response.data.user[0].username);
					localStorage.setItem("organization",response.data.user[0].organization);
					localStorage.setItem("unique_id",response.data.user[0]._id);

					Cookies.set('username',response.data.user[0].username,{ expires: 7 })
					Cookies.set('token',response.data.user[0].username,{ expires: 7 })
					// Cookies.set("token",response.data.token,{ expires: (40/(24*60*60)) });
					localStorage.setItem("token",response.data.token);
					localStorage.setItem("user_type",response.data.user[0].user_type);
					localStorage.setItem("client",response.data.user[0].client);
					localStorage.setItem("userid",response.data.user[0]._id);
					localStorage.setItem("language", JSON.stringify(response.data.user[0].language));
					if(response.data.user[0].location_access != undefined){
					localStorage.setItem("location_access",JSON.stringify(response.data.user[0].location_access));
					}
					localStorage.setItem("role",response.data.user[0].role);
					localStorage.setItem("user_code",response.data.user[0].user_code);

					localStorage.setItem("activeSBar",'planningTools');

					
					this.setState({
						isNav : 1
					})
                    var url = window.location.host;
                    var environment = ''
                    if (url === '4.240.97.194:3005') {
                        environment='DEV'
                    }else if (url==='164.52.218.255:3009'){
                        environment='QA'
                    }else if(url==='nestle.enmovil.net'){
                        environment='UAT'
                    }else if(url==='demo.optirun.in'){
                        environment='PROD'
                    }else{
                        environment="Local"
                    }
					params.environment=environment
					params.msg='Logged Into'
					if(response.data.user[0].client =='nestle'){
						redirectURL.post("/master/triggerEmail",params)
						.then((response)=>{
							})
					}
				}
				}
				else
				{
					this.setState({
						loginMessage : response.data.message,
						captchaMsg:''
					})
				}
			})
		}
		else
		{
			this.setState({
				loginMessage : "Enter Email and Password",
				captchaMsg:''
			})
		}
		
	}
	clickOnForgotPasswd=()=>{
		this.setState({clickReset:true})
		return <Navigate  to={"/reset"} push={true} />
	}
	
	handleCaptchaChange = (isValid) => {
		this.setState({isCaptchaValid:isValid})
	  };

    render(){
		const defaultOptions = {
			loop: true,
			autoplay: true,
			speed: 0.0005,
			animationData: WorldMap,
			rendererSettings: {
			  preserveAspectRatio: "xMidYMid slice"
			}
		};
		if(this.state.isNav === 1)
		{
			if(localStorage.getItem("user_type") === "SERVICE"){
				// return <Navigate  to={"/trips"} push={true} />
				window.location.href = "/trips";
			}else{
				// console.log('first if')
				if(localStorage.getItem("user_type") === "quality" || localStorage.getItem("user_type") === "security"){
					// return <Navigate  to={"/vehicle_checklist"} push={true} />
					window.location.href = "/vehicle_checklist";
				} else {
					// return <Navigate  to={"/"} push={true} />
					window.location.href = "/";
				}
			}
			
		}
		// console.log(Cookies.get('token'),'hello')
		// console.log(Cookies.get("token"),'cook',localStorage.getItem("token"))
		if(localStorage.getItem("token") != null){
		if (Cookies.get('token')!==undefined && Cookies.get('token')!=='' && Cookies.get('token')!==null  ){
			return <Navigate  to={"/"} push={true} />
		}}
		if(this.state.clickReset){
			return <Navigate  to={"/reset"} push={true} />
		}
        return (
            // <div className="login_wrapper">
			// 	<div className="animate form login_form">
			// 		<section className="login_content">
			// 			<form className="user" onSubmit={this.formSubmit.bind(this)}>
			// 				<img src={require("../../assets/images/enmovil.png")} />
			// 				<div className="form-group" style={{marginTop: "2em"}}>
			// 					<input type="email" className="form-control form-control-user"
			// 						id="exampleInputEmail" name="email" onChange={this.changeHandler} aria-describedby="emailHelp"
			// 						placeholder="Enter Email Address..." />
			// 				</div>
			// 				<div className="form-group">
			// 					<input type="password" className="form-control form-control-user"
			// 						id="exampleInputPassword" name="password" onChange={this.changeHandler} placeholder="Password" />
			// 				</div>
			// 				{/* <div className="form-group">
			// 					<div className="custom-control custom-checkbox small">
			// 						<input type="checkbox" className="custom-control-input" id="customCheck" />
			// 						<label className="custom-control-label" for="customCheck">Remember
			// 							Me</label>
			// 					</div>
			// 				</div> */}
			// 				<button type="submit" className="btn btn-primary btn-user btn-block">
			// 					Login
			// 				</button>
			// 			</form>										
			// 		</section>
			// 	</div>
			// </div>
			<div className="d-flex flex-row justify-content-center align-items-center vw-100 vh-100">
				<div className='d-flex flex-column justify-content-center align-items-center w-50 h-100'>
					{/* <div className='d-flex justify-content-center align-items-center mb-5'>
						<img src={require("../../assets/images/srm_logo.png")} style={{ width: 200 }}></img>
					</div> */}
					<div className='d-flex justify-content-center align-items-center position-relative mb-5'>
						<img src={require("../../assets/images/enmovil.png")} style={{ width: 250, height: 65 }}/>
						<span style={{ width: 200, position: "absolute", top: 55, right: -65, color: "#222", fontWeight: "bold", fontSize: "12px" }}>Powered By<img src={require("../../assets/images/enmovil_logo.png")} style={{ width: 90, marginBottom: 5, marginLeft: 5 }}></img></span>
					</div>
					<div className='form-body mx-3 h-50'>
						{/* <div> */}
						<form className="loginForm  " onSubmit={this.formSubmit.bind(this)} style={{alignItems:'center',display:'flex',flexDirection:'column'}}>
							{/* <h4>Login</h4> */}
							{(this.state.loginMessage)?
							<div className="alert alert-danger">{this.state.loginMessage}</div>
							:""}
							<div className="form-group" style={{marginTop: "2em",width:'90%', marginBottom: "1.5rem"}}>
								<input type="email" className="form-control form-control-user" id="exampleInputEmail" name="email" onChange={this.changeHandler} aria-describedby="emailHelp" placeholder="Email" autocomplete="off" />
							</div>
							<div className="form-group  txt-center d-flex justify-content-center" style={{width:'90%', marginBottom: "1.5rem"}}>
								<input type="password" className="form-control form-control-password" id="exampleInputPassword" name="password" onChange={this.changeHandler} placeholder="Password" autocomplete="off" />
							</div>{(this.state.captchaMsg)?
							<div className="alert alert-danger">{this.state.captchaMsg}</div>
							:""}
							<div className="form-group form-row  txt-center d-flex justify-content-center " style={{width:'90%', marginBottom: "1rem"}}>
								<Canvas
									onChange={this.handleCaptchaChange}
									charLength={6} // Set the number of characters for the captcha
									fontSize={20} // Set the font size of the captcha characters
								/>
							</div>
							<div className="form-group  txt-center" style={{width:'55%'}}>
								<button type="submit" className="btn btn-user">
									Login
								</button>
							</div>
						</form>
						</div>
						{/* <button onClick={this.clickOnForgotPasswd} className="btn btn-user">
									Forgotten password?
					</button>
					</div> */}
					
				</div>				
				<Lottie 
					options={defaultOptions}
					speed={0.05}
					height={650}
					width={800}
					style={{
						marginRight: 30,
						marginLeft: 0,
						// position: 'absolute',
						// width: '50%',
						// height: '100%',
						// top: 0,
						// right: 10,
						// opacity: 0.6,
						zIndex: -1, // Ensures the Lottie animation stays in the background
					  }}
				/>
			</div>
        )
    }
}
