import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import $ from "jquery";
import "jquery-ui";
import 'jquery-ui/ui/widgets/datepicker';
import 'jquery-ui/themes/base/datepicker.css';
import Select from "react-select";
import i18n from '../../i18n'
import Datetime from "react-datetime";
import { withTranslation  } from 'react-i18next';
import "react-datetime/css/react-datetime.css";
import SweetAlert from "react-bootstrap-sweetalert";
import Constant from "../common/constants";
import { AllModules } from "@ag-grid-enterprise/all-modules";
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-balham.css"; // Optional theme CSS
import "ag-grid-enterprise";
import { getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY } from "../common/utils";
import { verifyext } from "../common/verifyext";
import getToolConfiguration from "../optirun-admin/commonFunctions";
import redirectURL from "../redirectURL";
import GridButton from "../optirun-admin/gridButton";
import CSVFileValidator from "csv-file-validator";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import VehicleTypesModal from "./vehicleTypesInForecast";
import ConfigInForecast from "./configInForecast";

var infoBox = require("../common/google-infowindow");
const scaleanimation = {
  transition: "all 1s easy-out",
};

var moment = require("moment");
var markersArray;
var map;
var mapcluster;

var overlay;
var infowindows = [];
var markersArraylist = [];
let loc_code = ''

 class ForecastOrderProcessRun extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modules: AllModules,
      defaultColDef: {
        sortable: true,
        filter: true,
        editable: false,
        resizable: true,
        minWidth: 100,
        initialWidth: 200,
        wrapHeaderText: true,
        autoHeaderHeight: true,
      },
      frameworkComponents: {
        GridButton: GridButton,
      },
      rowData: [],
      rowSelection: "multiple",
      rowModelhighchartoptionsType: "enterprise",
      rowGroupPanelShow: "always",
      detailCellRendererParams: {},
      statusBar: {
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left",
          },
          {
            statusPanel: "agTotalRowCountComponent",
            align: "center",
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" },
        ],
      },
      paginationPageSize: 1000,
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
          },
          {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel",
          },
        ],
      },
      animateRows: true,
      showSlideBlockRoute: "sidebarcls",
      showSlideBlockUpload: "sidebarcls",
      showSlideBlockStack: "sidebarcls",
      overlayBlock: "show-n",
      routeData: [],
      rfreshmap: 1,
      deliverpickupslist: [],
      dealers: [],
      plants: [],
      uploadfile: "",
      alert: null,
      show: false,
      basicTitle: "",
      basicType: "default",
      loadplan: [],
      allLoadPlan: [],
      deliverydates: [],
      deliveryopts: [],
      filterDeliveryDate: { value: "", label: "Select Delivery Date" },
      clusterlist: [],
      filtercluster: { value: "", label: "Select Cluster" },
      vehicletypelist: [],
      filterVehicleType: { value: "", label: "All" },
      mapcol: "show-n",
      gridocl: "col-sm-12",
      filter_date: "",
      vtypediv: "show-n",
      vsupplierdiv: "show-n",
      selectedCluster: "",
      fdealerlist: [],
      filterdealer: { value: "", label: "All" },
      showloader: "show-n",
      mpnode: [],
      mpfor: "",
      clusterpoints: [],
      nmapcluster: "show-n",
      nmap: "show-m",
      scluster: "",
      stackContent: "",
      stackImage: "",
      stacktbl: [],
      stacktblbottom: [],
      stackOriginalData: [],
      showrunprocessbtn: "show-m",
      showanimate: "show-n",
      locationNames: [],
      loadFile: "",
      material_configurations: 0,
      location: { label: "Select Location", value: "" },
      loadshow: "show-m",
      overly: "show-m",
      processErrMsg: "",
      processErrShow: "",
      transactionId: "",
      droppedList: [],
      pickupList: [],
      dropList: [],
      materialList: [],
      boundLocations: [],
      previous_order: [{ label: "Select", value: "" }],
      club_order: "",
      showMoreInfo: false,
      ignoreProcess: false,
      clickOnsave: false,
      clickOnIgnore: false,
      month: new Date(),
      uploadDetails: [],
      showOrderDetails: "",
      orderDetails: [],
      showVehicleTypes: false,
      vehicleTypeList: [],
      clickonVehicles: false,
      configs: [],
      openConfigs: false,
      modelconfig : 'activet',
      modelconfigdata : "show-m",
      modelscenario : '',
      modelscenariodata : "show-n",
      demand_increase:false,
      demand_decrease:false,
      upcountry:false,
      local:false,
      serviceLevel:false,
      vfr:false,
      time_increase:false,
      time_decrease:false,
    };
    this.onCellValueChanged = this.onCellValueChanged.bind(this);
  }
  async componentDidMount() {
    $( ".datepicker" ).datepicker({
        dateFormat:"dd-mm-yy",
        changeYear:true
    });
    let url_type = window.location.href;
    // console.log(url_type,"url_type")
    let path_type = url_type.split("?");
    // console.log(path_type,"path_type")
    let data_type;
    let path = path_type[path_type.length - 1];

    // console.log(path,"path")
    if (path == "inbound") {
      data_type = 1;
    } else {
      data_type = 2;
    }
    await this.setState({ data_type: data_type });
    let params = {
      data_type: data_type,
    };
    await getToolConfiguration(params);
    let toolConfig = JSON.parse(localStorage.getItem("tlcfg"));
    var material_configurations = 0;
    if (toolConfig != undefined && toolConfig != "") {
      if (toolConfig.length > 0) {
        material_configurations = toolConfig[0].material_configuration;
      }
    }
    this.setState({
      material_configurations: material_configurations,
    });
    
    redirectURL
      .post("master/getBoundLocations", params)
      .then(async (response) => {
        let boundLocations = response.data.boundLocations;
        let materialconfigurations = response.data.materialconfigurations;
        if (boundLocations.length > 0) {
          var pickupData = boundLocations.filter(
            (bl) => bl.location_type == 1 || bl.location_type == 3
          );
          var pickupList = [];
          pickupData.map((pd) => {
            pickupList.push(pd.location_code);
          });
          var dropData = boundLocations.filter(
            (bl) =>
              (bl.location_type == 2 || bl.location_type == 3) &&
              pickupList[0] == bl.drop_corresponding_pickup_location_code
          );
          var dropList = [];
          dropData.map((dd) => {
            dropList.push(dd.location_name + "-" + dd.location_code);
          });
          this.setState({
            pickupList: pickupList,
            dropList: dropList,
            boundLocations: boundLocations,
          });
        }

        if (materialconfigurations.length > 0) {
          var materialList = [];
          materialconfigurations.map((pd) => {
            materialList.push(pd.material_code);
            this.setState({
              materialList: materialList,
            });
          });
        }
      })
      .catch(function (error) {
        var errDetails={
          url:"master/getBoundLocations",
          screen:'Run New Plan',
          error,
          
      }
        redirectURL.post("/master/logErrorsForApiCalls",errDetails)
        
    })
    
    await this.boundTypeLocations(params);
    this.setState({
      loadshow: "show-n",
      overly: "show-n",
      overlayBlock: "show-n",
    });
    // await this.getDispatchPlanData()
    await this.getOBDData()
    await this.onLoadGetConfigData()
  }

  onclickUploadFile=()=>{
  let logParams = {
        "location_code" : this.state.location.value,
        "location_name" : this.state.location.label,
        "user_name" : localStorage.getItem("username"),
        "useremail" : localStorage.getItem("email"),
        "client" : localStorage.getItem("client"),
        "screen" : "Forecast Plan",
        "activity" : 'clicked on Upload File Option in the slide',
        "event" : "File Upload slide opened",
        "data_type" : this.state.data_type,
    }

    redirectURL.post("/master/loguserUpdatesorChanges",logParams)
    }
  onClickDownloadSample=()=>{
    let logParams = {
      "location_code" : this.state.location.value,
        "location_name" : this.state.location.label,
      "user_name" : localStorage.getItem("username"),
      "useremail" : localStorage.getItem("email"),
      "client" : localStorage.getItem("client"),
      "screen" : "Run New Plan",
      "activity" : 'clicked on Download Sample Data Button in the slider',
      "event" : "Sample template has been Downloaded",
      "data_type" : this.state.data_type,
  }

  redirectURL.post("/master/loguserUpdatesorChanges",logParams)
  }

  // getDispatchPlanData = () =>{
  //     var params = {
  //         data_type : this.state.data_type,
  //         location_code : this.state.location.value,
  //     }
  //     redirectURL.post('dispatch/getDispatchPlanData',params)
  //     .then(response =>{
  //         this.setState({
  //             rowData:response.data,
  //             loadshow:'show-n',
  //             overly:'show-n',
  //             overlayBlock:"show-n"
  //         })
  //     })
  // }

  boundTypeLocations = async (param) => {
    let location_access = JSON.parse(localStorage.getItem("location_access"));

    let locationNames = this.state.locationNames;
    
    await redirectURL
      .post("master/getBoundTypeLocations", param)
      .then(async (response) => {
        if (response.data.status == "success") {
          let boundLocations = response.data.boundLocations;
          if (boundLocations.length > 0) {
            boundLocations.map((item) => {
              if (location_access != null) {
                if (location_access.includes(item.location_code)) {
                  locationNames.push({
                    value: item.location_code,
                    label: item.location_name,
                  });
                }
              } else {
                locationNames.push({
                  value: item.location_code,
                  label: item.location_name,
                });
              }
            });
            if (locationNames.length > 0) {
              await this.setState({
                locationNames: locationNames,
                location: locationNames[0],
                boundLocations: boundLocations,
              });
              var params = {
                data_type: 2,
                location_code: locationNames[0].value,
              };
              await this.getVehiclesData(params);
              await this.onLoadGetConfigData();
            } else {
              await this.setState({
                locationNames: [{ label: "Please Add Data", value: "no_data" }],
                location: [{ label: "Please Add Data", value: "no_data" }],
              });
            }
            param.location_code = locationNames[0].value;
            // this.getLatestDispatchOrderId(param);
          }
        }
      })
      .catch(function (error) {
        var errDetails={
          url:"master/getBoundTypeLocations",
          screen:'Run New Plan',
          error,
          
      }
        redirectURL.post("/master/logErrorsForApiCalls",errDetails)
        
    })
  };

  closeAlert = () => {
    if (this.state.basicTitle == "File is processing") {
      let url_type = window.location.href;
      let path_type = url_type.split("?");
      let path = path_type[path_type.length - 1];
      window.location.href = "dipatchplanhistory?" + path;
    } else {
      this.setState({
        show: false,
      });
    }
  };

  onClickShowUpload = () => {
    let logParams = {
      "location_code" : this.state.location.value,
        "location_name" : this.state.location.label,
      "user_name" : localStorage.getItem("username"),
      "useremail" : localStorage.getItem("email"),
      "client" : localStorage.getItem("client"),
      "screen" : "Forecast Plan",
      "activity" : 'clicked on Forecast By File Button',
      "event" : "Slider Opened with a option to upload file",
      "data_type" : this.state.data_type,
  }

  redirectURL.post("/master/loguserUpdatesorChanges",logParams)
    this.setState({
      showSlideBlockUpload: "slide25",
      overlayBlock: "show-m",
    });
  };

  onClickShowVehicles=()=>{
    // console.log('first openConfigs vehicle typesa',)
    this.setState({
      // showVehicleTypes: true,
      clickonVehicles: true,
      openConfigs: true,
    });
  }

  hideSlideBlock = () => {
    let logParams = {
      "location_code" : this.state.location.value,
        "location_name" : this.state.location.label,
      "user_name" : localStorage.getItem("username"),
      "useremail" : localStorage.getItem("email"),
      "client" : localStorage.getItem("client"),
      "screen" : "Run New Plan",
      "activity" : 'clicked on Cancel Button in the slider',
      "event" : "Slider closes",
      "data_type" : this.state.data_type,
  }

  redirectURL.post("/master/loguserUpdatesorChanges",logParams)
    this.setState({
      overlayBlock: "show-n",
      showSlideBlock: "",
      showSlideBlockUpload: "",
      showSlideBlockRoute: "",
      showSlideBlockStack: "",
      loadFile: "",
      loadshow: "show-n",
      overly: "show-n",
      club_order: [{ label: "Select", value: "" }],
      showOrderDetails:''
    });
    $("#uploadFile").val("");
    $("#notify_email").val("");
    document.getElementById("inValidDataInfo").innerHTML = "";
  };
  fixProcess = () => {
    this.setState({
      processErrMsg: "",
      processErrShow: 0,
      loadFile: "",clickOnsave:false
    });
    $("#uploadFile").val("");
    $("#notify_email").val("");
    //document.getElementById("upform").reset();
  };
  ignoreProcessError = () => {
    this.setState({
      loadshow: "show-m",
      overly: "show-m",
    });
    var transactionId = this.state.transactionId;
    // console.log("transactionId",transactionId)
    var params = {
      transactionId: transactionId,
      data_type: this.state.data_type,
    };
    
    redirectURL
      .post("dispatch/generateLoadByTransactionId", params)
      .then((response) => {
        //console.log(response.data,"response")
        var statusCode = response.data.status;
        if (statusCode == 0 || statusCode == 1) {
          this.setState({
            basicTitle: response.data.message,
            basicType: "success",
            show: true,
            processErrMsg: "",
            processErrShow: 0,
            loadshow: "show-n",
            overly: "show-n",
            overlayBlock: "show-n",
            showSlideBlockUpload: "",
          });
        } else {
          this.setState({
            processErrMsg: response.data.message,
            processErrShow: 1,
            transactionId: response.data.transactionId,
            loadshow: "show-n",
            overly: "show-n",
            overlayBlock: "show-n",
          });
        }
        //window.location.reload();
      })
      .catch(function (error) {
        var errDetails={
          url:"dispatch/generateLoadByTransactionId",
          screen:'Run New Plan',
          error,
          
      }
        redirectURL.post("/master/logErrorsForApiCalls",errDetails)
        
    })
    
  };

  changeFileHandler = async (e) => {
    loc_code = this.state.location.value
    let logParams = {
      "location_code" : this.state.location.value,
        "location_name" : this.state.location.label,
      "user_name" : localStorage.getItem("username"),
      "useremail" : localStorage.getItem("email"),
      "client" : localStorage.getItem("client"),
      "screen" : "Forecast Plan",
      "activity" : 'Uploaded the File',
      "event" : "Uploaded the File",
      "data_type" : this.state.data_type,
  }

  redirectURL.post("/master/loguserUpdatesorChanges",logParams)
    this.setState({
      loadFile: e.target.files[0],
    });
    document.getElementById("inValidDataInfo").innerHTML = "";
    var check = verifyext(e.target.files[0].name);
    // console.log("Check ", check);
    if (check) {
      // console.log("hit");
      if (
        e.target.files[0].type == "" ||
        e.target.files[0].type == "text/csv" ||
        e.target.files[0].type == "application/vnd.ms-excel"
      ) {
        // console.log("hit");
        // console.log(fileData);
        // console.log(typeof fileData);
        const config = {
          headers: [
            {
              name: "Pickup Location",
              inputName: "Pickup Location",
              required: true,
              validate: function(code) {
                return isPickUPCodeValid(code)
              },
              requiredError: function (headerName, rowNumber, columnNumber) {
                return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
              },
            },
            {
              name: "Drop Location",
              inputName: "Drop Location",
              required: true,
              requiredError: function (headerName, rowNumber, columnNumber) {
                return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
              },
            },
            {
              name: "Order Number",
              inputName: "Order Number",
              required: true,
              unique: true,
              uniqueError: function (headerName) {
                return `${headerName} is not unique`;
              },
              requiredError: function (headerName, rowNumber, columnNumber) {
                return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
              },
            },
            {
              name: "Invoicing Date",
              inputName: "Invoicing Date",
              required: true,
              requiredError: function (headerName, rowNumber, columnNumber) {
                return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
              },
              validate: function (date) {
                return isDateFormatValid(date);
              },
              validateError: function (headerName, rowNumber, columnNumber) {
                return `${headerName} is not valid in the ${rowNumber} row / ${columnNumber} column`;
              },
            },

            {
              name: "Quantity",
              inputName: "Quantity",
              required: true,
              requiredError: function (headerName, rowNumber, columnNumber) {
                return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
              },
            },
            {
              name: "Demand",
              inputName: "Demand",
              required: true,
              requiredError: function (headerName, rowNumber, columnNumber) {
                return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
              },
            },
            {
              name: "Weight",
              inputName: "Weight",
              required: true,
              requiredError: function (headerName, rowNumber, columnNumber) {
                return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
              },
              validate: function (weight) {
                return isValidWeight(weight);
              },
              validateError: function (headerName, rowNumber, columnNumber) {
                return `${headerName} is not valid in the ${rowNumber} row / ${columnNumber} column`;
              },
            },
            {
              name: "Dealer Available Time (From 00:00)",
              inputName: "Dealer Available Time (From 00:00)",
              required: false,
            },
            {
              name: "Dealer Available Time (To 23:59)",
              inputName: "Dealer Available Time (To 23:59)",
              required: false,
            },
            {
              name: "Delivery Date",
              inputName: "Delivery Date",
              // required: true,
              // requiredError: function (headerName, rowNumber, columnNumber) {
              //     return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
              // }
              validate: function (date) {
                return isDateFormatValid(date);
              },
              validateError: function (headerName, rowNumber, columnNumber) {
                return `${headerName} is not valid in the ${rowNumber} row / ${columnNumber} column`;
              },
            },
          ],
        };
        // var data =
        CSVFileValidator(e.target.files[0], config)
          .then((csvData) => {
            document.getElementById("inValidDataInfo").innerHTML = "";
            if (csvData.inValidData.length > 0) {
              $("#uploadFile").val("");
              document.getElementById("bulkUploadBtn").type = "button";
              document
                .getElementById("bulkUploadBtn")
                .classList.remove("btn-info");
              document
                .getElementById("bulkUploadBtn")
                .classList.add("btn-secondary");
              let invalidData = csvData.inValidData;
              let element = document.getElementById("inValidDataInfo");
              invalidData.map((item) => {
                let row, column;
                if (item.rowIndex == undefined) {
                  row = "NA";
                } else {
                  row = item.rowIndex;
                }
                if (item.columnIndex == undefined) {
                  column = "NA";
                } else {
                  column = item.columnIndex;
                }

                element.innerHTML +=
                  "Column : " +
                  column +
                  "," +
                  " Row : " +
                  row +
                  "--" +
                  item.message +
                  "<br></br>";
              });
              let throwMsg = 0;
              invalidData.map((itm) => {
                if (itm.columnIndex == 10) {
                  throwMsg = 1;
                }
              });
              if (throwMsg) {
                this.setState({
                  uploadFile: "",
                  show: true,
                  basicType: "danger",
                  basicTitle:
                    "Please Input Valid Data and also Ensure Date is in this Format only DD-MM-YYYY (or) DD/MM/YYYY",
                });
              }
            } else {
              document.getElementById("bulkUploadBtn").type = "submit";
              document
                .getElementById("bulkUploadBtn")
                .classList.remove("btn-secondary");
              document
                .getElementById("bulkUploadBtn")
                .classList.add("btn-info");
            }
          })
          .catch((err) => {
          
            var errDetails={
                url:"CSVFileValidator",
                err,
                screen:'Run New Plan'
            }
            redirectURL.post("/master/logErrorsForApiCalls",errDetails)
        
           });
      } else {
        e.target.value = null;
        this.setState({
          uploadFile: "",
          show: true,
          basicType: "danger",
          basicTitle: "Please upload file having extensions .csv only.",
        });
      }
    } else {
      e.target.value = null;
      this.setState({
        uploadFile: "",
        show: true,
        basicType: "danger",
        basicTitle: "Please upload file having extensions .csv only.",
      });
    }
  };

  uploadBulkFormHandler=async(event)=> {
    
    let ignoreProcess = this.state.ignoreProcess;
    var logParams = {
      "location_code" : this.state.location.value,
      "location_name" : this.state.location.label,
      "user_name" : localStorage.getItem("username"),
      "useremail" : localStorage.getItem("email"),
      "client" : localStorage.getItem("client"),
      "screen" : "Summary Dashboard",
      "activity" : 'clicked on SAVE Button in the slider',
      "event" : "navigated to run plan page",
      "data_type" : this.state.data_type,
  }
  redirectURL.post("/master/loguserUpdatesorChanges",logParams)

  if(ignoreProcess == false){
    event.preventDefault();
  }
    
    
    let saveData = new FormData();
    // var cnf = window.confirm("Are you sure you want to proceed ?");
    if (this.state.clickOnsave !== true) {
      await this.setState({clickOnsave: true})
      // if () {}
      saveData.append("loadFile", this.state.loadFile);
      var notify_email = $("#notify_email").val();
      saveData.append("notify_email", notify_email);
      saveData.append("module_type", 1);
      saveData.append("data_type", this.state.data_type);
      saveData.append(
        "material_configurations",
        this.state.material_configurations
      );
      let location = this.state.location;
      saveData.append("location_code", location.value);
      saveData.append("location_name", location.label);
      saveData.append("user_name", localStorage.getItem("username"));
      // saveData.append("email", localStorage.getItem('email'))
      saveData.append("useremail", localStorage.getItem("email"));
      saveData.append("client", localStorage.getItem("client"));
      let old_order_id = this.state.club_order;
      if (old_order_id.value != undefined && old_order_id.value != "") {
        saveData.append("old_order_id", old_order_id.value);
      }
      // console.log("saveData",saveData);
      this.setState({
        loadshow: "show-m",
        overly: "show-m",
        // showSlideBlockUpload: "",
        // overlayBlock: "show-n",
      });
      var logParams = {
        "location_code" : location.value,
        "location_name" : location.label,
        "user_name" : localStorage.getItem("username"),
        "useremail" : localStorage.getItem("email"),
        "client" : localStorage.getItem("client"),
        "screen" : "Summary Dashboard",
        "activity" : 'Input Data File Upload',
        "event" : "Run New Plan By File",
        "data_type" : this.state.data_type,
      }
     
      redirectURL.post("/master/logUserUpdateOrSaveDataActivities",logParams)
      .then(()=>{

      })
      .catch(function (error) {
        var errDetails={
          url:"/master/logUserUpdateOrSaveDataActivities",
          screen:'Run New Plan',
          error,
          
      }
        redirectURL.post("/master/logErrorsForApiCalls",errDetails)
        
    })
         
    if(ignoreProcess == false){
    redirectURL
        .post("/dispatch/validateInputDealerCode", saveData, {
          headers: {
            "content-type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, POST, OPTIONS, PUT, DELETE",
            "Access-Control-Allow-Headers": "Origin, Content-Type",
          },
        })
        .then((response) => {
          var newStatusCode = response.data.status;
    
    if(newStatusCode == 1){   
      redirectURL
        .post("/dispatch/generateLoad", saveData, {
          headers: {
            "content-type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, POST, OPTIONS, PUT, DELETE",
            "Access-Control-Allow-Headers": "Origin, Content-Type",
          },
        })
        .then((response) => {
          // console.log(response, "responseFromProcess");
          var statusCode = response.data.status;
          if (statusCode == 8) {
            this.setState({
              processErrMsg: response.data.message,
              processErrShow: 1,
              transactionId: response.data.transactionId,
              droppedList: response.data.droppedList,
              statusCode: statusCode,
              loadshow: "show-n",
              overly: "show-n",
              overlayBlock: "show-n",
            });
          } else if (statusCode > 3 && statusCode != 8) {
            if (statusCode == 11) {
              var processMsg =
                "Use the standard input data file template for uploading loads";
            } else {
              var processMsg = response.data.message;
            }
            this.setState({
              processErrMsg: response.data.message,
              processErrShow: 1,
              transactionId: response.data.transactionId,
              statusCode: statusCode,
              loadshow: "show-n",
              overly: "show-n",
              overlayBlock: "show-n",
            });
          } else {
            let basestring = response.data.transactionId;
            let encryptedstring = window.btoa(basestring);
            window.location.href = "/plannewdispatch?" + encryptedstring;

            //temp fix location need to be passed
            localStorage.setItem("runPlanLocation", this.state.location.value);
          }
        })
        .catch(function (error) {
          var errDetails={
            url:"/dispatch/generateLoad",
            screen:'Run New Plan',
            error,
            
        }
          redirectURL.post("/master/logErrorsForApiCalls",errDetails)
          
      })

      }else{
          if (newStatusCode == 8) {
            this.setState({
              processErrMsg: response.data.message,
              processErrShow: 1,
              droppedList: response.data.droppedList,
              statusCode: newStatusCode,
              loadshow: "show-n",
              overly: "show-n",
              overlayBlock: "show-n",
            });
          }
        }
      })
    }else{
      redirectURL
      .post("/dispatch/generateLoad", saveData, {
        headers: {
          "content-type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, OPTIONS, PUT, DELETE",
          "Access-Control-Allow-Headers": "Origin, Content-Type",
        },
      })
      .then((response) => {
        // console.log(response, "responseFromProcess");
        var statusCode = response.data.status;
        if (statusCode == 8) {
          this.setState({
            processErrMsg: response.data.message,
            processErrShow: 1,
            transactionId: response.data.transactionId,
            droppedList: response.data.droppedList,
            statusCode: statusCode,
            loadshow: "show-n",
            overly: "show-n",
            overlayBlock: "show-n",
          });
        } else if (statusCode > 3 && statusCode != 8) {
          if (statusCode == 11) {
            var processMsg =
              "Use the standard input data file template for uploading loads";
          } else {
            var processMsg = response.data.message;
          }
          this.setState({
            processErrMsg: response.data.message,
            processErrShow: 1,
            transactionId: response.data.transactionId,
            statusCode: statusCode,
            loadshow: "show-n",
            overly: "show-n",
            overlayBlock: "show-n",
          });
        } else {
          let basestring = response.data.transactionId;
          let encryptedstring = window.btoa(basestring);
          window.location.href = "/plannewdispatch?" + encryptedstring;

          //temp fix location need to be passed
          localStorage.setItem("runPlanLocation", this.state.location.value);
        }
      })
    }
          
    } 
    // else {
    //   this.setState({
    //     basicTitle: "Please upload the file",
    //     basicType: "warning",
    //     show: true,
    //   });
    // }
  }

  checkTransactionStatus = (transactionId) => {
    var query = {
      transaction_id: transactionId,
    };
   
    redirectURL
      .post("/dispatch/checkTransactionStatus", query)
      .then((response) => {
        // console.log(response, "responseFromcheckTransactionStatus");
        var statusCode = response.data.status;
        if (statusCode == 8) {
          this.setState({
            processErrMsg: response.data.message,
            processErrShow: 1,
            transactionId: response.data.transactionId,
            droppedList: response.data.droppedList,
            statusCode: statusCode,
            loadshow: "show-n",
            overly: "show-n",
            overlayBlock: "show-n",
          });
        } else if (statusCode > 3 && statusCode != 8) {
          this.setState({
            processErrMsg: response.data.message,
            processErrShow: 1,
            transactionId: response.data.transactionId,
            statusCode: statusCode,
            loadshow: "show-n",
            overly: "show-n",
            overlayBlock: "show-n",
          });
        } else if (statusCode == 0) {
          var checkCount = this.state.checkCount;
          var updatedCnt = parseInt(checkCount) + 1;
          this.setState({
            checkCount: updatedCnt,
          });
          // console.log(updatedCnt, "checkCount");
          if (updatedCnt < 3) {
            this.chkStatus(response.data.transactionId);
          } else {
            this.setState({
              basicTitle: i18n.t('File is processing'),
              basicType: "success",
              show: true,
              loadshow: "show-n",
              overly: "show-n",
              overlayBlock: "show-n",
              showSlideBlockUpload: "",
              loadFile: "",
            });
            $("#uploadFile").val("");
            $("#notify_email").val("");
          }
        } else {
          this.setState({
            basicTitle: response.data.message,
            basicType: "success",
            show: true,
            loadshow: "show-n",
            overly: "show-n",
            overlayBlock: "show-n",
            showSlideBlockUpload: "",
          });
          this.getDispatchPlanData();
        }
      })
      .catch(function (error) {
        var errDetails={
          url:"/dispatch/checkTransactionStatus",
          screen:'Run New Plan',
          error,
          
      }
        redirectURL.post("/master/logErrorsForApiCalls",errDetails)
        
    })
        
  };

  chkStatus = (transactionId) => {
    this.checkTransactionStatus(transactionId);
  };

  viewPlanDetails = async (propdata) => {
    let data_type = this.state.data_type;
    let location = this.state.location.value;
    let qry = {
      dispatch_planning_type: data_type,
      plant_location_code: location,
    };
    localStorage.setItem("qry", JSON.stringify(qry));

    var data = propdata.data;
    let basestring = data.transaction_id;
    let encryptedstring = window.btoa(basestring);
    window.location.href = "/viewdispatchplandetails?" + encryptedstring;
  };

  locationChanged = async (location) => {
    let boundLocations = this.state.boundLocations;
    var dropData = boundLocations.filter(
      (bl) =>
        (bl.location_type == 2 || bl.location_type == 3) &&
        location.value == bl.drop_corresponding_pickup_location_code
    );
    var dropList = [];
    dropData.map((dd) => {
      dropList.push(dd.location_name + "-" + dd.location_code);
    });
    // console.log(dropList)
    await this.setState({
      location: location,
      dropList: dropList,
    });
    let params = {
      location_code: location.value,
    };

    // await this.getDispatchPlanData()
    this.getLatestDispatchOrderId(params);
    params={
      data_type:2,
      location_code:location.value
    }
    this.getVehiclesData(params)
  };
  addNewRow() {
    // console.log(this.gridApi);
    let logParams = {
      "location_code" : this.state.location.value,
        "location_name" : this.state.location.label,
      "user_name" : localStorage.getItem("username"),
      "useremail" : localStorage.getItem("email"),
      "client" : localStorage.getItem("client"),
      "screen" : "Run New Plan",
      "activity" : 'clicked on Add Plan Dispatch Data Button',
      "event" : "New Row is added to aggrid",
      "data_type" : this.state.data_type,
  }

  redirectURL.post("/master/loguserUpdatesorChanges",logParams)
    if (this.state.material_configurations == 1) {
      var newRowData = [
        {
          pickup_location_code: "",
          drop_location_code: "",
          material_code: "",
          quantity: "",
        },
      ];
      var transaction = {
        add: newRowData,
        addIndex: -1,
      };
      this.gridApi.updateRowData(transaction);
    } else {
      var newRowData = [
        {
          pickup_location_code: "",
          drop_location_code: "",
          demand_cmt: "",
          weight_tons: "",
          delivery_date: "",
          invoicing_date: "",
          quantity: '',
          order_number: ''

        },
      ];
      var transaction = {
        add: newRowData,
        addIndex: -1,
      };
      this.gridApi.updateRowData(transaction);
    }
  }

  deleteRow = (params) => {
    let logParams = {
      "location_code" : this.state.location.value,
        "location_name" : this.state.location.label,
      "user_name" : localStorage.getItem("username"),
      "useremail" : localStorage.getItem("email"),
      "client" : localStorage.getItem("client"),
      "screen" : "Run New Plan",
      "activity" : 'clicked on Delete Icon Button',
      "event" : "Row is deleted from the aggrid",
      "data_type" : this.state.data_type,
    }

    redirectURL.post("/master/loguserUpdatesorChanges",logParams)
    var deleteTransaction = [params.data];
    this.gridApi.updateRowData({ remove: deleteTransaction });
  };
  onGridReady1 = (params) => {
    this.gridApi1 = params.api;
    this.gridColumnApi1 = params.columnApi;
  };

  onGridState1 = () => {
    this.colState1 = this.gridColumnApi1.getColumnState();
    this.rowGroupState1 = this.gridColumnApi1.getRowGroupColumns();
    this.pivotColumns1 = this.gridColumnApi1.getPivotColumns();
    window.filterState1 = this.gridApi1.getFilterModel();
    this.gridApi1.setFilterModel(window.filterState1);
  };
  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };
  onGridState = () => {
    this.colState = this.gridColumnApi.getColumnState();
    this.rowGroupState = this.gridColumnApi.getRowGroupColumns();
    this.pivotColumns = this.gridColumnApi.getPivotColumns();
    window.filterState = this.gridApi.getFilterModel();
    this.gridApi.setFilterModel(window.filterState);
  };
  onClickRunDispatchPlan = () => {
    let logParams = {
      "location_code" : this.state.location.value,
        "location_name" : this.state.location.label,
      "user_name" : localStorage.getItem("username"),
      "useremail" : localStorage.getItem("email"),
      "client" : localStorage.getItem("client"),
      "screen" : "Run New Plan",
      "activity" : 'clicked on Submit & Run Button',
      "event" : "alert is opened with yes or no",
      "data_type" : this.state.data_type,
  }

  redirectURL.post("/master/loguserUpdatesorChanges",logParams)
    var inputData = [];
    var drop_location_code = "";
    var errorField = "";
    var check_for_empty = false;
    var check_for_invalid = false;
    var check_for_valid_loc = false
    if (localStorage.getItem("data_type") == 1) {
      drop_location_code = this.state.location.value;
    }
    var pickup_location_code = "";
    if (localStorage.getItem("data_type") == 2) {
      pickup_location_code = this.state.location.value;
    }
    var dropList = this.state.dropList
    this.gridApi.forEachNode(function (rowNode, index) {
      var data = rowNode.data;
      // console.log(rowNode);
      if (localStorage.getItem("data_type") == 1) {
        data.drop_location_code = drop_location_code;
        // data.delivery_date = "";
        // data.invoicing_date = "";
      }
      if (localStorage.getItem("data_type") == 2) {
        data.pickup_location_code = pickup_location_code;
        // data.delivery_date = "";
        // data.invoicing_date = "";
      }
      if (
        data.dealer_available_start == "" ||
        data.dealer_available_start == undefined
      ) {
        data.dealer_available_start = "09:00";
      }
      if (
        data.dealer_available_end == "" ||
        data.dealer_available_end == undefined
      ) {
        data.dealer_available_end = "18:00";
      }
      inputData.push(data);
      // console.log(Object.keys(data));
      var drop_location_code_check = data.drop_location_code
      // console.log(drop_location_code_check)

      // console.log(dropList)

      for (var k of Object.keys(data)) {
        // console.log(k, data[k], 'check')

        if (data[k] === "" || data[k] == null) {
          check_for_empty = true;
          errorField = k;
        }
        if (data[k] === "In valid Format") {
          // console.log(data[k], k, 'data')
          check_for_invalid = true;
          errorField = k;
        }
        if (!dropList.includes(drop_location_code_check)) {
          check_for_valid_loc = true

        }

      }
    });
    // console.log(check_for_empty, 'empty', check_for_invalid, 'invalid', check_for_valid_loc, 'valid loc')

    if (check_for_empty) {
      alert(`Please Fill ${errorField} Field`);
    } else if (check_for_invalid) {
      alert(`Please Fill ${errorField} in a valid Format`);
    } else if (check_for_valid_loc) {
      alert('Please choose a Valid Drop Location')
    } else {
      // console.log(inputData.length)

      if (inputData.length) {
        var cnf = window.confirm("Do you want to proceed?");
        if (cnf) {
          let saveData = new FormData();
          saveData.append("module_type", 1);
          saveData.append("data_type", this.state.data_type);
          saveData.append(
            "material_configurations",
            this.state.material_configurations
          );
          let location = this.state.location;
          saveData.append("location_code", location.value);
          saveData.append("location_name", location.label);
          saveData.append("inputData", JSON.stringify(inputData));
          saveData.append("user_name", localStorage.getItem("username"));
          saveData.append("useremail", localStorage.getItem("email"));
          saveData.append("client", localStorage.getItem("client"));
          // console.log(saveData, saveData);
          this.setState({
            loadshow: "show-m",
            overly: "show-m",
          });
          let logParams = {
            "location_code" : location.value,
            "location_name" : location.label,
            "user_name" : localStorage.getItem("username"),
            "useremail" : localStorage.getItem("email"),
            "client" : localStorage.getItem("client"),
            "screen" : "Summary Dashboard",
            "activity" : 'Input Data Entered',
            "event" : "Run New Plan Manually",
            "data_type" : this.state.data_type,
          }
          // console.log(logParams,'params')
          
          redirectURL.post("/master/logUserUpdateOrSaveDataActivities",logParams)
          .then(()=>{})
          .catch(function (error) {
            var errDetails={
              url:"/master/logUserUpdateOrSaveDataActivities",
              screen:'Run New Plan',
              error,
              
          }
            redirectURL.post("/master/logErrorsForApiCalls",errDetails)
            
        })
            
          
          redirectURL
            .post("/dispatch/generateLoadByInputData", saveData, {
              headers: {
                "content-type": "multipart/form-data",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET, POST, OPTIONS, PUT, DELETE",
                "Access-Control-Allow-Headers": "Origin, Content-Type",
              },
            })
            .then((response) => {
              // console.log(response, "responseFromProcess");
              var statusCode = response.data.status;
              if (statusCode == 8) {
                this.setState({
                  processErrMsg: response.data.message,
                  processErrShow: 1,
                  transactionId: response.data.transactionId,
                  droppedList: response.data.droppedList,
                  statusCode: statusCode,
                  loadshow: "show-n",
                  overly: "show-n",
                  overlayBlock: "show-n",
                });
              } else if (statusCode > 3 && statusCode != 8) {
                if (statusCode == 11) {
                  var processMsg =
                    "Use the standard input data file template for uploading loads";
                } else {
                  var processMsg = response.data.message;
                }
                this.setState({
                  processErrMsg: response.data.message,
                  processErrShow: 1,
                  transactionId: response.data.transactionId,
                  statusCode: statusCode,
                  loadshow: "show-n",
                  overly: "show-n",
                  overlayBlock: "show-n",
                });
              } else {
                let basestring = response.data.transactionId;
                let encryptedstring = window.btoa(basestring);
                window.location.href = "/plannewdispatch?" + encryptedstring;

                //temp fix location need to be passed
                localStorage.setItem(
                  "runPlanLocation",
                  this.state.location.value
                );
              }
              //window.location.reload();
            })
            .catch(function (error) {
              var errDetails={
                url:"/dispatch/generateLoadByInputData",
                screen:'Run New Plan',
                error,
                
            }
              redirectURL.post("/master/logErrorsForApiCalls",errDetails)
              
          })
              
        }
      } else {
        this.setState({
          basicTitle: i18n.t('Enter Input Data To Continue'),
          basicType: "warning",
          show: true,
        });
      }
    }
    // }
  };

  onCellValueChanged(params) {
    // console.log(params,'para')
    if (params.colDef.field === "invoicing_date") {
      const inputValue = params.newValue;
      const isValidDate = validateDate(inputValue);
      if (!isValidDate) {
        params.node.setDataValue("invoicing_date", "In valid Format");
      }
    } else if (params.colDef.field === "delivery_date") {
      const inputValue = params.newValue;
      const isValidDate = validateDate(inputValue);
      if (!isValidDate) {
        params.node.setDataValue("delivery_date", "In valid Format");
      }
    } else if (params.colDef.field === "weight_tons") {
      const inputValue = params.newValue;

      if (typeof inputValue === "undefined") {
        params.node.setDataValue("weight_tons", "In valid Format");
      }
    } else if (params.colDef.field === "quantity") {
      const inputValue = parseInt(params.newValue);
      // console.log('typeof', inputValue);
      if (!Number.isInteger(inputValue)) {
        params.node.setDataValue("quantity", "In valid Format");
      }
    } else if (params.colDef.field === "order_number") {
      const inputValue = parseInt(params.newValue);
      //   console.log(typeof inputValue);
      if (!Number.isInteger(inputValue)) {
        params.node.setDataValue("order_number", "In valid Format");
      }
    } else if (params.colDef.field === "dealer_available_start") {
      const inputValue = params.newValue;
      const isValidDate = validateTime(inputValue);
      // console.log(typeof inputValue);
      // console.log(isValidDate);

      if (!isValidDate) {
        params.node.setDataValue(
          "dealer_available_start",
          "In valid Format"
        );
      }
    } else if (params.colDef.field === "dealer_available_end") {
      const inputValue = params.newValue;
      const isValidDate = validateTime(inputValue);
      // console.log(typeof inputValue);
      // console.log(isValidDate);

      if (!isValidDate) {
        params.node.setDataValue(
          "dealer_available_end",
          "In valid Format"
        );
      }
    }

    // if (params.colDef.field === "dealer_available_start"){
    // if(params.colDef.field === "dealer_available_start"){
    //   const inputValue = params.newValue;
    // const isValidDate = validateTime(inputValue);
    //   if (!isValidDate) {
    //     params.node.setDataValue('dealer_available_start', "In valid Time Format Please Input 24Hr Format");
    //   }
    // }

    // if(params.colDef.field === "dealer_available_end"){
    //     const inputValue = params.newValue;
    //   const isValidDate = validateTime(inputValue);
    //   if (!isValidDate) {
    //     params.node.setDataValue('dealer_available_end', "In valid Time Format Please Input 24Hr Format");
    //   }
    // }
    // }
  }

  previousOrderSelected = (club_order) => {
    this.setState({ club_order });
  };

  getLatestDispatchOrderId = (params) => {
    let previous_order = [{ label: "Select", value: "" }];
    let data_type = this.state.data_type;
    params.data_type = data_type;
    
    redirectURL
      .post("/dispatch/getLatestDispatchOrderId", params)
      .then((response) => {
        if (response.data.orderId.length > 0) {
          let id = response.data.orderId[0].order_id;
          this.setState({
            previous_order: [...previous_order, { label: id, value: id }],
          });
        } else {
          this.setState({ previous_order: [{ label: "Select", value: "" }] });
        }
      })
      .catch(function (error) {
        var errDetails={
          url:"/dispatch/getLatestDispatchOrderId",
          screen:'Run New Plan',
          error,
          
      }
        redirectURL.post("/master/logErrorsForApiCalls",errDetails)
        
    })
        
  };

  toggleMoreInfo = () => {
    this.setState((prevState) => ({ showMoreInfo: !prevState.showMoreInfo }));
  };

  handleIgnoreProcess = async() =>{
    if (!this.state.clickOnIgnore){
    await this.setState({ignoreProcess:true,clickOnsave:false,clickOnIgnore:true})
    await this.uploadVehicleForecastFormHandler()
    }
  }

  // handleRunPlanData = () =>{

  //   let data = this.state.rowData;

  //   if (data.length>0){
  //       var cnf = window.confirm("Do you want to proceed?");
  //       if (cnf) {
  //         let saveData = new FormData();
  //         saveData.append("module_type", 1);
  //         saveData.append("data_type", this.state.data_type);
  //         let location = this.state.location;
  //         saveData.append("location_code", location.value);
  //         saveData.append("location_name", location.label);
  //         saveData.append("inputData", JSON.stringify(data));
  //         saveData.append("user_name", localStorage.getItem("username"));
  //         saveData.append("useremail", localStorage.getItem("email"));
  //         saveData.append("client", localStorage.getItem("client"));
  //         // console.log(saveData, saveData);
  //         this.setState({
  //           loadshow: "show-m",
  //           overly: "show-m",
  //         });
  //         let logParams = {
  //           "location_code" : location.value,
  //           "location_name" : location.label,
  //           "user_name" : localStorage.getItem("username"),
  //           "useremail" : localStorage.getItem("email"),
  //           "client" : localStorage.getItem("client"),
  //           "screen" : "Forecast Vehicle Screen",
  //           "activity" : 'Input Data Entered',
  //           "event" : "Next Button Clicked",
  //           "data_type" : this.state.data_type,
  //         }
  //         // console.log(logParams,'params')
          
  //         redirectURL.post("/master/logUserUpdateOrSaveDataActivities",logParams)
  //         .then(()=>{})
  //         .catch(function (error) {
  //           var errDetails={
  //             url:"/master/logUserUpdateOrSaveDataActivities",
  //             screen:'Forecast Vehicle Screen',
  //             error,
              
  //         }
  //           redirectURL.post("/master/logErrorsForApiCalls",errDetails)
            
  //       })
            
          
  //         redirectURL
  //           .post("/forecastvehicle/generateForecastByInputData", saveData, {
  //             headers: {
  //               "content-type": "multipart/form-data",
  //               "Access-Control-Allow-Origin": "*",
  //               "Access-Control-Allow-Methods": "GET, POST, OPTIONS, PUT, DELETE",
  //               "Access-Control-Allow-Headers": "Origin, Content-Type",
  //             },
  //           })
  //           .then((response) => {
  //             // console.log(response, "responseFromProcess");
  //             var statusCode = response.data.status;
              
  //               let basestring = response.data.transactionId;
  //               let encryptedstring = window.btoa(basestring);
  //               window.location.href = "/VehicleForecastRunPlan?" + encryptedstring;

  //               //temp fix location need to be passed
  //               // localStorage.setItem(
  //               //   "runPlanLocation",
  //               //   this.state.location.value
  //               // );
              
  //             //window.location.reload();
  //           })
  //           .catch(function (error) {
  //             var errDetails={
  //               url:"/forecastvehicle/generateForecastByInputData",
  //               screen:'Run New Plan',
  //               error,
                
  //           }
  //             redirectURL.post("/master/logErrorsForApiCalls",errDetails)
              
  //         })
              
  //       }

  //   }else{
  //       this.setState({
  //           basicTitle: "No Data",
  //           basicType: "warning",
  //           show: true
  //         });
  //   }

  // }

  onClickGetData = () =>{
    var fromDate = $("#fromDate").val();
    var toDate = $("#toDate").val();
    
    let params = {
        from_date : fromDate,
        to_date : toDate,
        location_code : this.state.location.value,
        client : localStorage.getItem('client')
    }

    if(fromDate != '' && toDate != ''){

    

    redirectURL.post("forecastvehicle/getOrderInputDataForPeriod",params)
    .then(resp =>{
        let data = resp.data.result
        if(resp.data.result.length>0){
            this.setState({
                rowData:data
            })

        }else{
            this.setState({
                basicTitle: i18n.t('No Data'),
                basicType: "warning",
                show: true,
                rowData:data
              });
        }
    })
    }else{
        this.setState({
            basicTitle: i18n.t('please_select_date'),
            basicType: "warning",
            show: true,
          });
    }

  }

  handleUploadDataMonth = (date) =>{
    console.log(date,'ehiu')
    this.setState({month:date})
  }


  uploadVehicleForecastFormHandler=async(event)=> {
    
    let ignoreProcess = this.state.ignoreProcess;
    var logParams = {
      "location_code" : this.state.location.value,
      "location_name" : this.state.location.label,
      "user_name" : localStorage.getItem("username"),
      "useremail" : localStorage.getItem("email"),
      "client" : localStorage.getItem("client"),
      "screen" : "Forecast Vehicle",
      "activity" : 'clicked on SAVE Button in the slider',
      "event" : "navigated to run plan page",
      "data_type" : this.state.data_type,
  }
  redirectURL.post("/master/loguserUpdatesorChanges",logParams)

  if(ignoreProcess == false){
    event.preventDefault();
  }
    
    
    let saveData = new FormData();
    // var cnf = window.confirm("Are you sure you want to proceed ?");
    if (this.state.clickOnsave !== true) {
      await this.setState({clickOnsave: true})
      // if () {}
      saveData.append("loadFile", this.state.loadFile);
      var notify_email = $("#notify_email").val();
      saveData.append("notify_email", notify_email);
      saveData.append("module_type", 1);
      saveData.append("data_type", this.state.data_type);
      // saveData.append(
      //   "material_configurations",
      //   this.state.material_configurations
      // );
      let location = this.state.location;
      saveData.append("location_code", location.value);
      saveData.append("location_name", location.label);
      saveData.append("user_name", localStorage.getItem("username"));
      // saveData.append("email", localStorage.getItem('email'))
      saveData.append("useremail", localStorage.getItem("email"));
      saveData.append("client", localStorage.getItem("client"));
      saveData.append("month", this.state.month);
      console.log(this.state.month,'sefy')
      console.log("saveData",saveData);
      this.setState({
        loadshow: "show-m",
        overly: "show-m",
        // showSlideBlockUpload: "",
        // overlayBlock: "show-n",
      });
      var logParams = {
        "location_code" : location.value,
        "location_name" : location.label,
        "user_name" : localStorage.getItem("username"),
        "useremail" : localStorage.getItem("email"),
        "client" : localStorage.getItem("client"),
        "screen" : "Forecast Vehicle",
        "activity" : 'Input Data File Upload',
        "event" : "Run New Plan By File",
        "data_type" : this.state.data_type,
      }
     
      redirectURL.post("/master/logUserUpdateOrSaveDataActivities",logParams)
      .then(()=>{

      })
      .catch(function (error) {
        var errDetails={
          url:"/master/logUserUpdateOrSaveDataActivities",
          screen:'Run New Plan',
          error,
          
      }
        redirectURL.post("/master/logErrorsForApiCalls",errDetails)
        
    })
         
    if(ignoreProcess == false){
    redirectURL
        .post("/dispatch/validateInputDealerCode", saveData, {
          headers: {
            "content-type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, POST, OPTIONS, PUT, DELETE",
            "Access-Control-Allow-Headers": "Origin, Content-Type",
          },
        })
        .then((response) => {
          var newStatusCode = response.data.status;
    
    if(newStatusCode == 1){   
      redirectURL
        .post("/forecastvehicle/saveOBDInputData", saveData, {
          headers: {
            "content-type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST",
            "Access-Control-Allow-Headers": "Origin, Content-Type",
          },
        })
        .then((response) => {
          // console.log(response, "responseFromProcess");
          if(response.data.status == 1){
            console.log(response, "responseFromProcess",response.data.status );
            this.setState({
              basicTitle: i18n.t('data_uploaded'),
              basicType: "success",
              show: true,
              loadshow: "show-n",
              overly: "show-n",
              overlayBlock: "show-n",
              showSlideBlockUpload:''
            })
            this.getOBDData()
          }
          // var statusCode = response.data.status;
          
            // let basestring = response.data.transactionId;
            // let encryptedstring = window.btoa(basestring);
            // window.location.href = "/VehicleForecastRunPlan?" + encryptedstring;

          
        })
        .catch(function (error) {
          var errDetails={
            url:"/forecastvehicle/saveOBDInputData",
            screen:'Forecast Run New Plan',
            error,
            
        }
          redirectURL.post("/master/logErrorsForApiCalls",errDetails)
          
      })

      }else{
          if (newStatusCode == 8) {
            this.setState({
              processErrMsg: response.data.message,
              processErrShow: 1,
              droppedList: response.data.droppedList,
              statusCode: newStatusCode,
              loadshow: "show-n",
              overly: "show-n",
              overlayBlock: "show-n",
            });
          }
        }
      })
    }else{
      redirectURL
      .post("/forecastvehicle/saveOBDInputData", saveData, {
        headers: {
          "content-type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, OPTIONS, PUT, DELETE",
          "Access-Control-Allow-Headers": "Origin, Content-Type",
        },
      })
      .then((response) => {
        if(response.data.status == 1){
        this.setState({
          basicTitle: i18n.t('data_uploaded'),
          basicType: "success",
          show: true,
          loadshow: "show-n",
          overly: "show-n",
          overlayBlock: "show-n",
          showSlideBlockUpload:''
        })
        this.getOBDData()
      }
        // console.log(response, "responseFromProcess");
        // var statusCode = response.data.status;
        
          // let basestring = response.data.transactionId;
          // let encryptedstring = window.btoa(basestring);
          // window.location.href = "/forecastvehicle/generateForecastByInputData?" + encryptedstring;

        
      })
    }
          
    } 
    // else {
    //   this.setState({
    //     basicTitle: "Please upload the file",
    //     basicType: "warning",
    //     show: true,
    //   });
    // }
  }

  getOBDData = () =>{

    redirectURL.post("/forecastvehicle/getOBDData")
    .then(response =>{
      if(response.data.status == 'success'){
        this.setState({uploadDetails:response.data.data})
      }

    })
  }

  getVehiclesData = (params) =>{
    let vehicleTypeList = [];

    redirectURL.post('forecastvehicle/getVehiclesForecastData',params)
    .then(async (response) =>{
        if(response.data.status == 'success'){
            // console.log(response.data.vehiclesData)

            let vehiclesData = response.data.vehiclesData
            this.setState({
                vehiclesData
            })

            if(vehiclesData.length > 0){
                vehiclesData.map(item =>{
                    vehicleTypeList.push({ value : 0,
                        label : item.vehicle_type })
                })
                await this.setState({
                    vehicleTypeList : vehicleTypeList,
                    vehicle_type : vehicleTypeList[0]
                })
            }

            else{
                this.setState({
                    vehicleTypeList:[],
                    vehicle_type:null

                })
            }
    }
    })
    .catch(function (error) {
        var errDetails={
            url:"master/getVehiclesData",
            screen:'Vehicle Availability Configurations',
          error,
          }
        redirectURL.post("/master/logErrorsForApiCalls",errDetails)
        
    })
}
  handleSelectedFileRunPlanData = () =>{
    let data = this.gridApi.getSelectedRows()
  
    let dataCheck = true
    // let vehdata = this.state.vehicleTypeList
    // dataCheck = vehdata.some(item => item.value > 0);
    
    if(!dataCheck){
      this.setState({
        basicTitle: i18n.t("Please Select No of Vehicles"),
        basicType: "warning",
        show: true
      })
      return 
    }

    if(data.length>0){  
      let module_type = 1;
      let data_type = this.state.data_type;
      let location_code = this.state.location.value;
      let location_name = this.state.location.label;
      let filename = data[0].filename;
      let month = data[0].month;
      let year = data[0].year;
      let username = localStorage.getItem("username");
      let client = localStorage.getItem("client");
      let useremail = localStorage.getItem("email");
      let selected_data = data
      let uniqueUploadId = data[0].uniqueUploadId
      var cnf = window.confirm("Do you want to proceed?");
        if (cnf) {
          
          this.setState({
            loadshow: "show-m",
            overly: "show-m",
          });
          let logParams = {
            "location_code" : location_code,
            "location_name" : location_name,
            "user_name" : localStorage.getItem("username"),
            "useremail" : localStorage.getItem("email"),
            "client" : localStorage.getItem("client"),
            "screen" : "Forecast Vehicle Screen",
            "activity" : 'File Data Selected',
            "event" : "Run plan Button Clicked",
            "data_type" : this.state.data_type,
          }
          // console.log(logParams,'params')
          
          redirectURL.post("/master/logUserUpdateOrSaveDataActivities",logParams)
          .then(()=>{})
          .catch(function (error) {
            var errDetails={
              url:"/master/logUserUpdateOrSaveDataActivities",
              screen:'Forecast Vehicle Screen',
              error,
              
          }
            redirectURL.post("/master/logErrorsForApiCalls",errDetails)  
        })

        let parameters = {
          // module_type : module_type,
          // data_type : data_type,
          // location_code : location_code,
          // location_name : location_name,
          filename : filename,
          month : month,
          year : year,
          // username : username,
          // client : client,
          // useremail : useremail,
          // selected_data : JSON.stringify(selected_data)
          uniqueUploadId:uniqueUploadId

        }

        // process removed
        // redirectURL
        //     .post("/forecastvehicle/generateForecastBySelectedFileData", parameters, {
        //       // headers: {
        //       //   "content-type": "multipart/form-data",
        //       //   "Access-Control-Allow-Origin": "*",
        //       //   "Access-Control-Allow-Methods": "GET, POST, OPTIONS, PUT, DELETE",
        //       //   "Access-Control-Allow-Headers": "Origin, Content-Type",
        //       // },
        //     })
        //     .then((response) => {

        //       // console.log(response, "responseFromProcess");
        //       var statusCode = response.data.status;
        //       if(statusCode == 1){
              
        //         let basestring = response.data.transactionId;
        //         let encryptedstring = window.btoa(basestring);
        //         // window.location.href = "/VehicleForecastRunPlan?" + encryptedstring;
        //       }else{
        //         this.setState({
        //           basicTitle: "Process Failed",
        //           basicType: "danger",
        //           show: true,

        //         });
        //       }

        //     })
        //     .catch(function (error) {
        //       var errDetails={
        //         url:"/forecastvehicle/generateForecastBySelectedFileData",
        //         screen:'Forecast Plan',
        //         error,
                
        //     }
        //       redirectURL.post("/master/logErrorsForApiCalls",errDetails)
              
        //   })
          this.onClickRunPlanForecast(parameters)
        }

    }else{
      this.setState({
        basicTitle: i18n.t('Please Select File'),
        basicType: "warning",
        show: true
      });

    }
  }
  viewOrderDetails=async(params)=> {
    // console.log('hit here',new Date('03-07-2024'))
    this.setState({showOrderDetails:'slide45',
        // showSlideBlockUpload:'slide25',
            overlayBlock: "show-m",
            }) 
    var req_params={uniqueUploadId:params.data.uniqueUploadId,
                    month:params.data.month,
                    year:params.data.year
                  }
    redirectURL.post('forecastvehicle/getForecastviewOrderDetails',req_params)
    .then((response)=>{
      // console.log(response.data,'helo')
      this.setState({orderDetails:response.data})
    })
  } 

  onCloseVTM=()=>{
    var {vehicleTypeList}=this.state
    vehicleTypeList.map((item)=>{
      item.value=0
    })
    this.setState({showVehicleTypes:false})
  }

  saveVehicleData=()=>{
    // console.log('this.state.vehicleTypeList',this.state.vehicleTypeList)
    let dataCheck = false
    let data = this.state.vehicleTypeList
    dataCheck = data.some(item => item.value > 0);
    if(dataCheck){
    this.setState({showVehicleTypes:false})
    }else{
      this.setState({
        basicTitle: i18n.t("Please Input Valid Data"),
        basicType: "warning",
        show: true
      });
    }
  }

  onClickRunPlanForecast=(parameters)=>{

    var {vehiclesData,vehicleTypeList}=this.state
    // console.log(vehiclesData,'rer')
    vehiclesData.map((item)=>{
      vehicleTypeList.map((vehicle)=>{
        if(item.vehicle_type===vehicle.label){
          item.no_of_vehicles=vehicle.value
        }
      })
    })

    let location_code = this.state.location.value;
    let location_name = this.state.location.label;

          var params = {
              module_type : localStorage.getItem("module_type"),
              data_type : this.state.data_type,
              location_code : location_code,
              location_name : location_name,
              vehicleData : vehiclesData,
              useremail: localStorage.getItem('email'),
              client : localStorage.getItem('client'),
              username : localStorage.getItem("username"),
              ...parameters
          }
          // console.log(params,'hello')
          redirectURL.post('forecastvehicle/runForecastVehiclePlan',params)
          .then(resp =>{

              if(resp.data.status == 'success'){

                  this.setState({
                      basicTitle : resp.data.message,
                      basicType : "success",
                      show : true,
                      loadshow: "show-n",
                      overly: "show-n",
                    });
              }

          })

      // }else{
      //     if(data.length == 0){
      //         this.setState({
      //             basicTitle: "No Data",
      //             basicType: "warning",
      //             show: true
      //           });
      //     }else if(vehicle_count == false){
      //         this.setState({
      //             basicTitle: "Please Enter No Of Vehicles",
      //             basicType: "warning",
      //             show: true
      //           });
      //     }
          
      // }
  }

  
  onLoadGetConfigData = async() =>{
    // console.log("first pick23", this.state.location);
    var locationCode = this.state.location.value;
    var configs = [];
    await redirectURL.post("/master/getPlantwiseConfigs").then((response) => {
      // console.log("first response", response.data);
      configs = response.data[0][locationCode].Outbound;
    });
  //  console.log("first configs", configs, locationCode);
   await this.setState({
     configs: configs,
   });
  }


  onClickCloseConfigs=()=>{
    this.setState({ openConfigs: false });
  }

  updatemodalstates=(type)=>{
    if('config' == type){
      var config = 'activet'
      var configdata = "show-m"
      var scenario = ''
      var scenariodata = "show-n"
    }else if('scenario' == type){
      var config = ''
      var configdata = "show-n"
      var scenario = 'activet'
      var scenariodata = "show-m"
    }
    this.setState({modelconfig:config,
      modelconfigdata:configdata,
      modelscenario:scenario,
      modelscenariodata:scenariodata})
    }

    setDemandIncrease=(val)=>{
      if(val == 'false'){
        this.setState({demand_increase:true,
                        demand_decrease:false
                    })
      }else{
        this.setState({demand_increase:false,
                      demand_decrease:true
        })
      }
      
    }

    setDemandDecrease=(val)=>{
      if(val == 'false'){
        this.setState({demand_decrease:true,
          demand_increase:false
        })
      }else{
        this.setState({demand_decrease:false,
                      demand_increase:true
                 })
      }
    }
  
    setTimeDecrease=(val)=>{
      if(val == 'false'){
        this.setState({time_decrease:true,
          time_increase:false
        })
      }else{
        this.setState({time_decrease:false,
          time_increase:true
                 })
      }
    }
    setTimeIncrease=(val)=>{
      if(val == 'false'){
        this.setState({time_decrease:false,
          time_increase:true
        })
      }else{
        this.setState({time_decrease:true,
          time_increase:false
                 })
      }
    }
    setVFR=(val)=>{
      if(val == 'false'){
        this.setState({
          vfr:true
        })
      }else{
        this.setState({
          vfr:false
                 })
      }
    }
    setServiceLevel=(val)=>{
      if(val == 'false'){
        this.setState({
          serviceLevel:true
        })
      }else{
        this.setState({
          serviceLevel:false
                 })
      }
    }
    setUpcountry=(val)=>{
      if(val == 'false'){
        this.setState({local:false,
          upcountry:true
        })
      }else{
        this.setState({local:true,
          upcountry:false
                 })
      }
    }
    setLocal=(val)=>{
      if(val == 'false'){
        this.setState({local:true,
          upcountry:false
        })
      }else{
        this.setState({local:false,
          upcountry:true
                 })
      }
    }

  // onClickCloseConfigs=()=>{
  //   this.setState({openSavedConfigs:false})
  // }
  render() {
    const {t} = this.props
    // console.log("first pick", this.state.location);    
    if (localStorage.getItem("data_type") === 1) {
      var inputDetailscolsDefs = [
        {
          headerName: "Actions",
          field: "delete",
          width: "80",
          pinned: "left",
          params: {
            buttonName: t('delete'),
            iconName: "fa fa-trash",
            onClickFunction: this.deleteRow,
          },
          cellRendererSelector: function (params) {
            var rendComponent = {
              component: "GridButton",
            };
            return rendComponent;
          },
        },
        {
          headerName: "Pick Up Location (Source)",
          field: "pickup_location_code",
          width: "150",
          editable: true,
          cellEditor: "agSelectCellEditor",
          cellEditorParams: {
            values: this.state.pickupList,
          },
        },
        {
          headerName: "Drop Location (Destination)",
          field: "drop_location_code",
          width: "150",
          editable: false,
          // cellEditor: 'agSelectCellEditor',
          // cellEditorParams: {
          //     values: this.state.dropList,
          // },
          valueGetter: () => {
            var location = this.state.location;
            if (location != undefined && location != "") {
              return location.value;
            }
          },
        },
      ];
    } else {
      var inputDetailscolsDefs = [
        {
          headerName: "Pick Up Location (Source)",
          field: "pickup_location_code",
          width: "150",
          editable: false,
          // cellEditor: 'agSelectCellEditor',
          // cellEditorParams: {
          //     values: this.state.pickupList,
          // },
          valueGetter: () => {
            var location = this.state.location;
            if (location != undefined && location != "") {
              return location.value;
            }
          },
        },
        {
          headerName: "Drop Location (Destination)",
          field: "drop_location_code",
          width: "250",
          editable: true,
          cellEditor: "agSelectCellEditor",
          cellEditorParams: {
            values: this.state.dropList,
          },
        },
        {
          headerName: "Order Number",
          field: "order_number",
          width: "200",
          editable: true,
        },
        {
          headerName: "Invoicing Date(DD-MM-YYYY)",
          field: "invoicing_date",
          width: "150",
          editable: true,
        },
        {
          headerName: "Quantity",
          field: "quantity",
          width: "100",
          editable: true,
        },
        {
          headerName: "Dealer Available Time (from 00:00)",
          field: "dealer_available_start",
          width: "150",
          editable: true,
          valueGetter: function (params) {
            if (
              params.data.dealer_available_start == undefined ||
              params.data.dealer_available_start == ""
            ) {
              return "09:00";
            } else {
              return params.data.dealer_available_start;
            }
          },
        },
        {
          headerName: "Dealer Available Time (to 23:59)",
          field: "dealer_available_end",
          width: "150",
          editable: true,
          valueGetter: function (params) {
            if (
              params.data.dealer_available_end == undefined ||
              params.data.dealer_available_end == ""
            ) {
              return "18:00";
            } else {
              return params.data.dealer_available_end;
            }
          },
        },
        // {
        //   headerName: "Delivery Date(DD-MM-YYYY)",
        //   field: "delivery_date",
        //   width: "230",
        //   editable: true,
        // },
        {
          headerName: "Actions",
          field: "delete",
          width: "80",
          pinned: "left",
          params: {
            buttonName: t('delete'),
            iconName: "fa fa-trash",
            onClickFunction: this.deleteRow,
          },
          cellRendererSelector: function (params) {
            var rendComponent = {
              component: "GridButton",
            };
            return rendComponent;
          },
        },
      ];
    }
    if (this.state.material_configurations == 1) {
      inputDetailscolsDefs.push(
        {
          headerName: "Material",
          field: "material_code",
          width: "250",
          editable: true,
          cellEditor: "agSelectCellEditor",
          cellEditorParams: {
            values: this.state.materialList,
          },
        },
        {
          headerName: "Quantity",
          field: "quantity",
          width: "250",
          editable: true,
        }
      );
    } else {
      inputDetailscolsDefs.push({
        headerName: t('volume_m3'),
        field: "demand_cmt",
        width: "120",
        editable: true,
        cellEditor: ValidationCellEditor,
      });
      inputDetailscolsDefs.push(
        {
          headerName: t('weight_kgs'),
          field: "weight_tons",
          width: "120",
          editable: true,
          cellEditor: ValidationCellEditor,
        },
        {
          headerName: "Delivery Date(DD-MM-YYYY)",
          field: "delivery_date",
          width: "120",
          editable: true,
        }
      );
      // inputDetailscolsDefs.push({
      //   headerName: "actions",
      //   field: "delete",
      //   width: "80",
      //   pinned: "left",
      //   params: {
      //     buttonName: "Delete",
      //     iconName: "fa fa-trash",
      //     onClickFunction: this.deleteRow,
      //   },
      //   cellRendererSelector: function (params) {
      //     var rendComponent = {
      //       component: "GridButton",
      //     };
      //     return rendComponent;
      //   },
      // });
    }

    var columnwithDefsForDropped = [
      {
        headerName: t('dealer_code'),
        field: "dealer_code",
        width: 130,
        filter: true,
        resizable: true,
        editable: false,
      },
      // {
      //   headerName: "Demand",
      //   field: "demand",
      //   width: 130,
      //   filter: true,
      //   resizable: true,
      //   editable: false,
      // },
      {
        headerName: t('remarks'),
        field: "remarks",
        width: 200,
        filter: true,
        resizable: true,
        editable: false,
        cellRenderer: params => {
          if (params.value === null || params.value === undefined) {
            return 'Dealer Details Not Found'; 
          } else {
            return params.value;
          }
        },
      },
    ];

    var inputDataDetailscolsDefs = [
        {
            headerName:"Pick Up Location (Source)",
            field:"pickup_location_code",
            width:"150",
        },
        {
            headerName:"Drop Location Code (Destination)",
            field:"drop_location_code",
            width:"150"
        },
        {
            headerName:"Drop Location Name (Destination)",
            field:"drop_location_name",
            width:"200"
        },
        // {
        //     headerName:"Drop Location City",
        //     field:"location_city",
        //     width:"150"
        // },
        {
            headerName:"Order Number",
            field:"order_number",
            width:"100",
        },
        {
            headerName: "Order Date",
            field: "order_date",
            width:200,
            filter: true,
            resizable: true,
            editable:false,
            valueGetter:function(params){
                try {
                    if(params.data.order_date !== "" && params.data.order_date !== undefined)
                    {
                        return getHyphenDDMMMYYYY(params.data.order_date);
                    }
                    else{
                        return "";
                    }
                } catch (error) {
                    
                }
            }
        },
        {
            headerName:"Invoicing Date",
            field:"invoicing_date",
            width:"120",
        },
        {
            headerName:"Quantity",
            field:"quantity",
            width:"100",                  
        },
        {
            headerName:"Dealer Available From",
            field:"dealer_available_start",
            width:"110",
        },
        {
            headerName:"Dealer Available To",
            field:"dealer_available_end",
            width:"120",
        },
        {
            headerName:"Delivery Date",
            field:"delivery_date",
            width:"120",
        },
        {
            headerName: "Weight (Kgs)",
            field: "weight_tons",
            width:100,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Demand Volume (M3)",
            field: "demand_cmt",
            width:100,
            filter: true,
            resizable: true,
            editable:false
        },
        
    ]

    var uploadDetailscolsDefs = [
      {   
          headerName: '',
          field: 'checkbox',
          checkboxSelection: true,
          headerCheckboxSelection: false,
          width:"10px", 
      },
      {
        headerName : t('drop_details'),
        width:140,
        resizable : true,
        editable : false,
        params:{buttonName: t('view'),iconName:'fa fa-eye',buttonCls:'btn btn-info',
        onClickFunction:this.viewOrderDetails},
        cellRendererSelector:function(params){
            var rendComponent = {
                component: "GridButton"
            };
            return rendComponent
        },
      },
      {
          headerName: t('month'),
          field:"month",
          width:"80",
          valueGetter:function(params){
            try {
                if(params.data.month !== "" && params.data.month !== undefined)
                {
                  if(params.data.month == 1){
                    return "JAN"
                  }else if(params.data.month == 2){
                    return "FEB"
                  }else if(params.data.month == 3){
                    return "MAR"
                  }else if(params.data.month == 4){
                    return "APR"
                  }else if(params.data.month == 5){
                    return "MAY"
                  }else if(params.data.month == 6){
                    return "JUN"
                  }else if(params.data.month == 7){
                    return "JUL"
                  }else if(params.data.month == 8){
                    return "AUG"
                  }else if(params.data.month == 9){
                    return "SEP"
                  }else if(params.data.month == 10){
                    return "OCT"
                  }else if(params.data.month == 11){
                    return "NOV"
                  }else if(params.data.month == 12){
                    return "DEC"
                  }  
                }
                else{
                    return "";
                }
            } catch (error) {
                
            }
        }
      },
      {
        headerName: t('year'),
        field:"year",
        width:"80",
      },
      {
          headerName: t('file_name'),
          field:"filename",
          width:"200",
          hide:true
      },
      {
          headerName: t('total_quantity'),
          field:"quantity",
          width:"100",      
          valueGetter:(params)=>{
            if(params.data.quantity != undefined){
                return params.data.quantity.toFixed(0)
            }
        }            
      },
      {
          headerName: t('total_weight_kgs'),
          field: "weight_tons",
          width:150,
          filter: true,
          resizable: true,
          editable:false,
          valueGetter:(params)=>{
            if(params.data.weight_tons != undefined){
                return params.data.weight_tons.toFixed(0)
            }
        } 
      },
      {
          headerName: t('total_demand_volume_m3'),
          field: "demand_cmt",
          width:150,
          filter: true,
          resizable: true,
          editable:false,
          valueGetter:(params)=>{
            if(params.data.demand_cmt != undefined){
                return params.data.demand_cmt.toFixed(0)
            }
        } 
      },
      {
        headerName: t('user'),
        field:"useremail",
        width:"150",                  
      },
      {
        headerName: t('uploaded_date'),
        field:"created_on",
        width:"150",  
        valueGetter:function(params){
          try {
              if(params.data.created_on !== "" && params.data.created_on !== undefined)
              {
                  return getHyphenDDMMMYYYYHHMM(params.data.created_on);
              }
              else{
                  return "";
              }
          } catch (error) {
              
          }
      }                
      },

  ]

    if (this.state.material_configurations == 1) {
      var templatePath = require("../../assets/json/input_data_with_material_config_template.csv");
    } else {
      var templatePath = require("../../assets/json/input_data_template.csv");
    }

    let data = this.state.uploadDetails
    let locValue = this.state.location.value
      
    let filteredData = data.filter(item => item.pickup_location_code == locValue)

      // var orderColDefs=[
      //       // {
      //       //   headerName:"Created On",
      //       //   field:"created_on",
      //       //   width:"150",                  
      //       // },
      //       {
      //         headerName:"Pick Up Location Code",
      //         field:"pickup_location_code",
      //         width:"120",                  
      //       },
      //       {
      //         headerName:"Quantity",
      //         field:"quantity",
      //         width:"100",                  
      //       },
      //       {
      //         headerName:"Drop Location",
      //         field:"drop_location_code",
      //         width:"120",                  
      //       },
      //       {
      //         headerName:"Drop Location Name",
      //         field:"drop_location_name",
      //         width:"120",                  
      //       },
      //       {
      //         headerName:"Weight",
      //         field:"weight_tons",
      //         width:"100",                  
      //       },
      //       {
      //         headerName:"Volume",
      //         field:"demand_cmt",
      //         width:"100",                  
      //       },
      //       {
      //         headerName:"Delivery Date",
      //         field:"delivery_date",
      //         width:"120",                  
      //       },
      //       {
      //         headerName:"Invoice Date",
      //         field:"invoicing_date",
      //         width:"120",                  
      //       },
      //       // {
      //       //   headerName:"Order Date",
      //       //   field:"order_date",
      //       //   width:"150",                  
      //       // },
      //       {
      //         headerName:"Order No",
      //         field:"order_number",
      //         width:"120",                  
      //       },
      //       {
      //         headerName:"Available Start Time",
      //         field:"dealer_available_start",
      //         width:"120",                  
      //       },
      //       {
      //         headerName:"Available End Time",
      //         field:"dealer_available_end",
      //         width:"120",                  
      //       }
      // ]

      var orderColDefs = [
        {
            headerName: t('pick_up_location_code'),
            field: "pickup_location_code",
            width: "120",
        },
        {
            headerName: t('quantity'),
            field: "quantity",
            width: "100",
        },
        {
            headerName: t('drop_location_code'),
            field: "drop_location_code",
            width: "120",
        },
        {
            headerName: t('drop_location_name'),
            field: "drop_location_name",
            width: "120",
        },
        {
            headerName: t('weight'),
            field: "weight_tons",
            width: "100",
        },
        {
            headerName: t('volume'),
            field: "demand_cmt",
            width: "100",
        },
        {
            headerName: t('delivery_date'),
            field: "delivery_date",
            width: "120",
        },
        {
            headerName: t('invoice_date'),
            field: "invoicing_date",
            width: "120",
        },
        {
            headerName: t('order_no'),
            field: "order_number",
            width: "120",
        },
        {
            headerName: t('available_start_time'),
            field: "dealer_available_start",
            width: "120",
        },
        {
            headerName: t('available_end_time'),
            field: "dealer_available_end",
            width: "120",
        }
    ];
    
    return (
      <div class="container-fluid">
        <div
          className={"animatenow " + this.state.showanimate}
          style={{ textAlign: "center" }}
        >
          <span className="pp-h">Processing Plan. Please wait.....</span>
          <img
            src={require("../../assets/images/r.gif")}
            style={{ width: "85vh" }}
          />
        </div>
        <div className={"lodr " + this.state.showloader}>
          <div className="loaderdiv">
            <div className="loaderwave"></div>
            <div className="loaderwave"></div>
            <div className="loaderwave"></div>
            <div className="loaderwave"></div>
            <div className="loaderwave"></div>
            <div className="loaderwave"></div>
            <div className="loaderwave"></div>
            <div className="loaderwave"></div>
            <div className="loaderwave"></div>
            <div className="loaderwave"></div>
          </div>
          <div className="ldrtext">Loading....</div>
        </div>
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          onConfirm={this.closeAlert}
        ></SweetAlert>
        {this.state.alert}
        <div class="row mb-20p">
          <div className="col-sm-12 pt-20p">
            <h5 className="fbold">
              {t('forecast_input_information')}
              <button
                type="button"
                className="btn btn-warning float-right font-white"
                onClick={this.onClickShowUpload}
              >
                {t('upload_file')}
              </button>
              {/* <button
                className="btn btn-success"
                style={{ float: "right" }}
                onClick={this.addNewRow.bind(this)}
              >
                Add Dispatch Plan Data
              </button> */}
              <button
                type="button"
                className="btn btn-warning float-right font-white"
                onClick={this.onClickShowVehicles}
              >
                {t('configurations')}
              </button>
              <button
                type="button"
                className="btn btn-warning float-right font-white"
                onClick={this.handleSelectedFileRunPlanData}
              >
                {t('run_plan')}
              </button>
            </h5>
          </div>
        </div>
        <div className="row">
          <div className="col-md-2">
            <div className="form-group ">
              <label className="col-form-label f12">
                {t('pickup_point')}
              </label>
              <Select
                placeholder={"Select"}
                onChange={this.locationChanged}
                name="location"
                value={this.state.location}
                options={this.state.locationNames}
              />
            </div>
          </div>
          {/* <div className="col-md-2">
            <div className="form-group ">
                <label className="col-form-label f12">From Date</label>
                <input type="text" className="form-control datepicker" name="fromDate" id="fromDate" autoComplete='off'  readonly />
            </div>
            </div>
            <div className="col-md-2">
                <div className="form-group ">
                    <label className="col-form-label f12">To Date</label>
                    <input type="text" className="form-control datepicker" name="toDate" id="toDate" autoComplete='off'  readonly />
                </div>
            </div>
            <div className="col-md-2">
            <button type="button" onClick={this.onClickGetData} className="btn btn-warning mt-30p">Get Data</button>
            </div>
          <div className="col-md-6"></div>
          <div className="col-md-3"></div> */}
        </div>
        {/* <div class="row mb-20p">
          <div className={"mxheit col-sm-12 "}>
            <div
              id="myGrid1"
              style={{ width: "100%", height: "55vh" }}
              className={"ag-theme-balham"}
            >
              <AgGridReact
                // modules={this.state.modules}
                columnDefs={inputDetailscolsDefs}
                defaultColDef={this.state.defaultColDef}
                rowData={this.state.rowData}
                enableCharts={false}
                onGridReady={this.onGridReady}
                onGridState={this.onGridState}
                frameworkComponents={this.state.frameworkComponents}
                statusBar={this.state.statusBar}
                sideBar={this.state.sideBar}
                stopEditingWhenGridLosesFocus={true}
                paginationPageSize={this.state.paginationPageSize}
                pagination={false}
                gridOptions={{
                  context: { componentParent: this },
                }}
                // components={this.state.components}
                // onCellDoubleClicked={this.onClickShowClusterRouteOnMap.bind(this)}
                enableRangeSelection={true}
                // onCellEditingStopped={this.onCellEditingCell.bind(this)}
                // masterDetail={true}
                // detailCellRendererParams={this.state.detailCellRendererParams}
                onCellEditingStopped={this.onCellValueChanged.bind(this)}
              />
            </div>
            <button
              type="button"
              className="btn btn-primary mt-20p float-right"
              onClick={this.onClickRunDispatchPlan}
            >
              Submit & Run Plan
            </button>
          </div>
        </div> */}

        <div class="row mb-20p">
          <div className={"mxheit col-sm-12 "}>
            <div
              id="myGrid1"
              style={{ width: "100%", height: "55vh" }}
              className={"ag-theme-balham"}
            >
              <AgGridReact
                rowData={filteredData}
                columnDefs={uploadDetailscolsDefs}
                gridOptions={{ context: { componentParent: this } }}
                defaultColDef={this.state.defaultColDef}
                frameworkComponents={this.state.frameworkComponents}
                paginationPageSize={this.state.paginationPageSize}
                pagination={true}
                enableRangeSelection={true}
                onGridReady={this.onGridReady}
                onGridState={this.onGridState}
              />
            </div>
            {/* <button
              type="button"
              className="btn btn-primary mt-20p float-right"
              onClick={this.handleSelectedFileRunPlanData}
            >
              Next
            </button> */}
          </div>
        </div>

        <div
          className={"overlay-block " + this.state.overlayBlock}
          onClick={this.hideSlideBlock}
        ></div>
        <div className={"sliderBlock2 " + this.state.showSlideBlockUpload}>
          <h5 className="crd-bg p-10p">{t('upload_input_file')}</h5>
          <div className="row">
            <div className="col-sm-12">
              {this.state.processErrShow == 0 ? (
                <form onSubmit={this.uploadVehicleForecastFormHandler}>
                  <div className="row p-20p">
                    <div className="form-group col-sm-12">
                      <a
                        className="btn btn-warning"
                        href={templatePath}
                        target="_blank"
                        onClick={this.onClickDownloadSample}
                      >
                        {t('download_sample_template')}
                      </a>
                    </div>
                    <div className="form-group col-sm-12 mb-20p">
                      {t('select_month')}
                      <DatePicker
                        selected={this.state.month}
                        onChange={this.handleUploadDataMonth}
                        dateFormat="MMMM/yyyy"
                        showMonthYearPicker
                        className="forminp form-control"
                      />
                    </div>
                    <div className="form-group col-sm-12 mb-10p">
                      <label style={{ color: "#000" }}>{t('upload_file')}</label>
                      <span className="note-font pl-15p">
                        {this.state.location.label} ,{t('uploading_data_for_plant')}.{" "}
                      </span>
                      <input
                        type="file"
                        name="uploadFile"
                        id="uploadFile"
                        onChange={this.changeFileHandler}
                        className="form-control"
                        onClick={this.onclickUploadFile}
                        required
                      />
                    </div>

                    <div
                      className="form-group col-sm-12 note-font"
                      onClick={this.toggleMoreInfo}
                    >
                      {t('note_blank_rows_columns_not_allowed')}{" "}
                      <span className="more-btn">
                        {this.state.showMoreInfo ? "Less <" : "More >"}
                      </span>
                      {this.state.showMoreInfo && (
                        <div className="more-info">
                          {t('check_blank_data_instructions')}
                        </div>
                      )}
                    </div>
                    {/* <div className="form-group col-sm-12 mb-20p">
                      <label style={{ color: "#000" }}>
                        Notify users via email once the process is completed
                        (comma seperated)
                      </label>
                      <textarea
                        name="notify_email"
                        id="notify_email"
                        className="form-control"
                        placeholder="abc@mail.com,xyz@mail.com"
                      ></textarea>
                    </div> */}
                    {/* {this.state.previous_order.length > 1 ? (
                      <div className="col-sm-12">
                        <div className="form-group ">
                          <label
                            htmlFor="club_order"
                            className="col-form-label f12"
                          >
                            Club Previous Order
                          </label> */}

                    {/* <select name="club_order" id="club_order" defaultValue={this.state.club_order}
                                            className= "forminp form-control"
                                            onChange={this.previousOrderSelected}>
                                            <option value="" selected>Select</option>
                                            <option value={this.state.previous_order}>{this.state.previous_order}</option>
                                        </select> */}

                    {/* <Select
                            placeholder={"Select"}
                            onChange={this.previousOrderSelected}
                            name="club_order"
                            value={this.state.club_order}
                            options={this.state.previous_order}
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )} */}

                    <div className="form-group col-sm-12 mb-20p">
                      <button
                        type="submit"
                        id="bulkUploadBtn"
                        className="btn btn-info"
                      >
                        {t('save')}
                      </button>
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={this.hideSlideBlock.bind(this)}
                      >
                        {t('cancel')}
                      </button>
                    </div>
                    <div id="inValidDataInfo" style={{ color: "red" }}></div>
                  </div>
                </form>
              ) : (
                <div className="" style={{ marginTop: "1em" }}>
                  <p style={{ marginBottom: "1em" }}>
                    {this.state.processErrMsg}
                  </p>
                  {this.state.droppedList.length > 0 ? (
                    <div
                      id="myGrid"
                      style={{
                        width: "100%",
                        height: "300px",
                        marginBottom: "1em",
                      }}
                      className={"ag-theme-balham " + this.state.showGridData}
                    >
                      <AgGridReact
                        // modules={this.state.modules}
                        columnDefs={columnwithDefsForDropped}
                        defaultColDef={this.state.defaultColDef}
                        rowData={this.state.droppedList}
                        enableCharts={false}
                        // autoGroupColumnDef={this.state.autoGroupColumnDef}
                        onGridReady={this.onGridReady1}
                        onGridState={this.onGridState1}
                        frameworkComponents={this.state.frameworkComponents}
                        statusBar={this.state.statusBar}
                        sideBar={this.state.sideBar}
                        stopEditingWhenGridLosesFocus={true}
                        paginationPageSize={this.state.paginationPageSize}
                        pagination={false}
                        gridOptions={{
                          context: { componentParent: this },
                        }}
                        // components={this.state.components}
                        enableRangeSelection={true}
                        //onCellClicked={this.onCellClicked}
                        // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  <button
                    type="button"
                    onClick={this.fixProcess.bind(this)}
                    className="btn btn-success"
                  >
                    {t('fix_and_reupload')}
                  </button>
                  {this.state.statusCode == 11 ? (
                    <button
                      type="button"
                      onClick={this.hideSlideBlock.bind(this)}
                      className="btn btn-default"
                    >
                      {t('cancel')}
                    </button>
                  ) : (
                    <button
                      type="button"
                      onClick={this.handleIgnoreProcess.bind(this)}
                      className="btn btn-default"
                    >
                      {t('ignore')}
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className={"sliderBlock2 " + this.state.showOrderDetails}>
          <h5 className="crd-bg p-10p">{t('view_order_details')} </h5>
          <div className="row">
            <div className="col-sm-12">
              <div className="col-sm-12">
                <div
                  id="myGrid2"
                  style={{ width: "100%", height: "80vh" }}
                  className="col-sm-12 ag-theme-balham dropdown"
                >
                  <AgGridReact
                    // modules={this.state.modules}
                    rowData={this.state.orderDetails}
                    columnDefs={orderColDefs}
                    gridOptions={{ context: { componentParent: this } }}
                    defaultColDef={this.state.defaultColDef}
                    frameworkComponents={this.state.frameworkComponents}
                    paginationPageSize={this.state.paginationPageSize}
                    pagination={true}
                    enableRangeSelection={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="100vh mt-20">
          <VehicleTypesModal
            openVTM={this.state.showVehicleTypes}
            onCloseVTM={this.onCloseVTM}
            vehicleTypeList={this.state.vehicleTypeList}
            saveVehicleData={this.saveVehicleData}
          />
        </div>
        {/* {console.log("first djhf", this.state.openConfigs)} */}
        <div className="100vh mt-20">
          <ConfigInForecast
            data={this.state.configs}
            handleOnClose={this.onClickCloseConfigs}
            openConfigmd={this.state.openConfigs}
            updatemodalstates={this.updatemodalstates}
            config={this.state.modelconfig}
            configdata={this.state.modelconfigdata}
            scenario={this.state.modelscenario}
            scenariodata={this.state.modelscenariodata}
            demand_decrease={this.state.demand_decrease}
            demand_increase={this.state.demand_increase}
            setDemandDecrease={this.setDemandDecrease}
            setDemandIncrease={this.setDemandIncrease}
            upcountry={this.state.upcountry}
            local={this.state.local}
            serviceLevel={this.state.serviceLevel}
            vfr={this.state.vfr}
            time_increase={this.state.time_increase}
            time_decrease={this.state.time_decrease}
            setTimeIncrease={this.setTimeIncrease}
            setTimeDecrease={this.setTimeDecrease}
            setUpcountry={this.setUpcountry}
            setLocal={this.setLocal}
            setVFR={this.setVFR}
            setServiceLevel={this.setServiceLevel}
          />
        </div>
        {/* <div className={"dataLoadpage " +(this.state.loadshow)}></div> */}
        <div className={"loader " + this.state.loadshow}></div>
        <div
          className={"overlay-part " + this.state.overly}
          onClick={this.hideSlideBlock.bind(this)}
        ></div>
      </div>
    );
  }
}

export default withTranslation()(ForecastOrderProcessRun)

function ValidationCellEditor() { }

ValidationCellEditor.prototype.init = function (params) {
  this.eGui = document.createElement("div");
  this.eGui.innerHTML = `
    <input value=${params.value} >
  `;
  this.eInput = this.eGui.querySelector("input");
  this.eInput.addEventListener("input", this.inputChanged.bind(this));
};

ValidationCellEditor.prototype.inputChanged = function (event) {
  const val = event.target.value;
  if (!this.isValid(val)) {
    this.eInput.classList.add("invalid-cell");
  } else {
    this.eInput.classList.remove("invalid-cell");
  }
};

ValidationCellEditor.prototype.isValid = function (value) {
  // console.log("isNaN(value) ", isNaN(value))
  if (isNaN(value) === false) {
    return value.length >= 1 || value.length === 20;
  } else {
    return "";
  }
};

ValidationCellEditor.prototype.getValue = function () {
  return parseFloat(this.eInput.value);
};

ValidationCellEditor.prototype.isCancelAfterEnd = function () {
  return !this.isValid(this.eInput.value);
};

ValidationCellEditor.prototype.getGui = function () {
  return this.eGui;
};

ValidationCellEditor.prototype.destroy = function () {
  this.eInput.removeEventListener("input", this.inputChanged);
};

function validateDate(inputValue) {
  // const isoDateRegex = /^\d{4}-\d{2}-\d{2}$/;
  const isoDateRegex = /^\d{2}-\d{2}-\d{4}$/;
  // console.log(isoDateRegex.test(inputValue), inputValue, "input");
  if (!isoDateRegex.test(inputValue)) {
    // console.log("Hit");
    return false;
  }
  // const [year, month, day] = inputValue.split("-").map(Number);
  const [day, month, year] = inputValue.split("-").map(Number);
  // console.log(day, month, year);

  const date = new Date(year, month - 1, day);
  // const date = new Date(day, month - 1, year);
  // console.log(date);

  // date.getFullYear() === year &&
  //   date.getMonth() === month - 1 &&
  //   date.getDate() === day;
  return (
    date.getDate() === day &&
    date.getMonth() === month - 1 &&
    date.getFullYear() === year
  );
}

function validateTime(window_time) {
  // console.log(window_time, "Window Time");
  //array[00:00,00:00]
  let frm_hr = window_time[0].split(":");
  let to_hr = window_time[1].split(":");
  // console.log(frm_hr, to_hr);
  // console.log(typeof window_time);
  var timeObj = window_time.split(":");
  if (timeObj[0] > 23) {
    // console.log(timeObj[0]);
    return false;
  }
  if (timeObj[1] > 59) {
    // console.log(timeObj[1]);
    return false;
  }

  if (window_time[0] == window_time[1]) {
    // return "Please Input Time Appropriately 24Hr Format";
    return false;
  } else if (frm_hr[0] > to_hr[0]) {
    // return "Please Input Time Appropriately 24Hr Format";
    return false;
  } else if (frm_hr[0] == to_hr[0]) {
    if (frm_hr[1] > to_hr[1]) {
      // return "Please Input Time Appropriately 24Hr Format";
      return false;
    } else {
      return true;
    }
  } else {
    return true;
  }
}

function isDateFormatValid(date) {
  const dateParts = date.split(/[-/]/);

  if (dateParts.length !== 3) {
    return false;
  }

  const day = parseInt(dateParts[0], 10);
  const month = parseInt(dateParts[1], 10);
  const year = parseInt(dateParts[2], 10);

  if (isNaN(day) || isNaN(month) || isNaN(year)) {
    return false;
  }

  const formattedDate = new Date(year, month - 1, day);

  if (
    formattedDate.getDate() !== day ||
    formattedDate.getMonth() !== month - 1 ||
    formattedDate.getFullYear() !== year
  ) {
    return false;
  }

  return true;
}

function isValidWeight(weight){
  if (weight.includes(',')){
    return false
  }else{
    return true
  }
}

function isPickUPCodeValid(code) {

  if(code != loc_code){
    return false
  }else{
    return true
  }
    
}