import React,{Component} from "react";
import Constant from "../common/constants";
import redirectURL from '../redirectURL';
import Select from 'react-select';
import { withTranslation } from "react-i18next";
import getToolConfiguration from "./commonFunctions";
import FixedVehicleCost from './vehicleFixedCost';
import VehicleCityLevelCost from './vehicleCityLevelVariableCost';
import VehicleLevelCost from './vehicleLevelVariableCost';
import ZoneLevelCost from './zonelevelcost';
import Vehiclecostcustomerspecific from './vehiclecostcustomerspecific'


 class VehicleCostConfigurations extends Component{
    constructor(props){
        super(props);
        this.state={
            locationNames:[],
            location:'',
            fixedCostTab:"",
            vehicleLevelCostTab : "",
            cityWiseCostTab : "",
            zoneWiseCostTab: "",
            fixedCostData: false,
            vehicleLevelCostData: false,
            cityWiseCostData: false,
            zoneWiseCostData: false,
            customercostTab: 'activet', // Only the Cost Details tab is active initially
            customercostData: true,      // Only the Cost Details component renders initially
            plant_wise_freight_fixed_cost: 0,
            plant_wise_freight_vehicle_type_variable_cost: 0,
            plant_wise_freight_city_level_vehicle_cost: 0,
            plant_wise_freight_zone_level_cost: 0,
        }
        
    }

    async componentDidMount(){
        
        var curl = window.location.search;
        var ul= curl.split("?");
        // console.log("ul ",ul)
        // let url_type = window.location.href
        let url_type = ul[parseInt(ul.length)-1];
        // console.log("url_type ", url_type)
        // let path_type = url_type.split('/')
        let path_type = url_type
        let data_type
        // let path = path_type[path_type.length-1]
        let path =url_type

        if (path == 'inbound'){
            data_type = 1
        }else{
            data_type = 2
        }
        await this.setState({data_type : data_type})

        let params = {
            data_type : data_type
        }

        await this.boundTypeLocations(params)
    }

    boundTypeLocations = async(param) => {
        let locationNames = this.state.locationNames;
        let location = {}
        await redirectURL.post('master/getBoundTypeLocations',param)
          .then(async (response) =>{
              if(response.data.status == 'success'){
                  let boundLocations = response.data.boundLocations
                  if(boundLocations.length > 0){
                      boundLocations.map(item =>{
                          locationNames.push({ value : item.location_code,
                              label : item.location_name })
                      })

                      location = locationNames[0]
                      await this.setState({
                          locationNames : locationNames,
                          location : locationNames[0]
                      })
                  }
              }
          })

          await getToolConfiguration({plant_code:location.value})
          this.toolConfigurationSetup()
      }

    toolConfigurationSetup = () => {
        
        let toolConfig = JSON.parse(localStorage.getItem('tlcfg'))
        var plant_wise_freight_configuration = 0;
        var plant_wise_freight_fixed_cost = 0;
        var plant_wise_freight_vehicle_type_variable_cost = 0;
        var plant_wise_freight_city_level_vehicle_cost = 0;
        var plant_wise_freight_zone_level_cost = 0;
    
        if(toolConfig !=undefined && toolConfig !="")
        {
            if(toolConfig.length > 0)
            {
                plant_wise_freight_configuration = toolConfig[0].plant_wise_freight_configuration;
                plant_wise_freight_fixed_cost = toolConfig[0].plant_wise_freight_fixed_cost;
                plant_wise_freight_vehicle_type_variable_cost = toolConfig[0].plant_wise_freight_vehicle_type_variable_cost;
                plant_wise_freight_city_level_vehicle_cost = toolConfig[0].plant_wise_freight_city_level_vehicle_cost;
                plant_wise_freight_zone_level_cost = toolConfig[0].zone_level_cost
            }
    
            if(plant_wise_freight_fixed_cost == 1)
            {
                this.setState({customercostTab : false,
                              customercostData: false,
                              fixedCostTab : "activet",
                              fixedCostData:true})
            }
            else if(plant_wise_freight_vehicle_type_variable_cost == 1)
            {
                this.setState({customercostTab : false,
                                customercostData: false,
                                vehicleLevelCostTab : "activet",
                                vehicleLevelCostData:true})
            }
            else if(plant_wise_freight_city_level_vehicle_cost == 1)
            {
                this.setState({customercostTab : false,
                                customercostData: false,
                                cityWiseCostTab : "activet",
                                cityWiseCostData:true})
            }
            else if(plant_wise_freight_zone_level_cost == 1)
            {
                this.setState({customercostTab : false,
                    customercostData: false,
                    zoneWiseCostTab : "activet",
                    zoneWiseCostData:true})
            }
        }

        this.setState({
            plant_wise_freight_configuration,
            plant_wise_freight_fixed_cost ,
            plant_wise_freight_vehicle_type_variable_cost,
            plant_wise_freight_city_level_vehicle_cost ,
            plant_wise_freight_zone_level_cost
        })
    }

    locationChanged = async (location) => {
        // console.log("location", location)
        this.setState({
            location : location
        })
        await getToolConfiguration({plant_code:location.value})
        this.toolConfigurationSetup(location)
    }

    onClickTab = (pageType) => {
        let newState = {
            fixedCostTab: "",
            vehicleLevelCostTab: "",
            cityWiseCostTab: "",
            zoneWiseCostTab: "",
            customercostTab: "",
            fixedCostData: false,
            vehicleLevelCostData: false,
            cityWiseCostData: false,
            zoneWiseCostData: false,
            customercostData: false
        };

        if (pageType === "fixedCostData") {
            newState.fixedCostTab = "activet";
            newState.fixedCostData = true;
        } else if (pageType === "vehicleLevelCostData") {
            newState.vehicleLevelCostTab = "activet";
            newState.vehicleLevelCostData = true;
        } else if (pageType === "cityWiseCostData") {
            newState.cityWiseCostTab = "activet";
            newState.cityWiseCostData = true;
        } else if (pageType === "zoneWiseCostData") {
            newState.zoneWiseCostTab = "activet";
            newState.zoneWiseCostData = true;
        } else if (pageType === "customercostData") {
            newState.customercostTab = "activet";
            newState.customercostData = true;
        }

        this.setState(newState);
    };

    // onClickTab = (pageType) => {

    //     if(pageType == "fixedCostData")
    //     {
    //         var fixedCostTab = "activet";
    //         var cityWiseCostTab = "";
    //         var vehicleLevelCostTab = "";
    //         var zoneWiseCostTab = "";
    //         var fixedCostData = true;
    //         var vehicleLevelCostData = false;
    //         var cityWiseCostData = false;
    //         var zoneWiseCostData = false;
    //         var customercostTab = "";
    //         var customercostData = false;
            
    //     }
    //     else if(pageType == "vehicleLevelCostData")
    //     {

    //         var fixedCostTab = "";
    //         var vehicleLevelCostTab = "activet";
    //         var cityWiseCostTab = "";
    //         var zoneWiseCostTab = "";
    //         var fixedCostData = false;
    //         var vehicleLevelCostData = true;
    //         var cityWiseCostData = false;
    //         var zoneWiseCostData = false;
    //         var customercostTab = "";
    //         var customercostData = false;
    //     }
    //     else if(pageType == "cityWiseCostData")
    //     {
    //         var fixedCostTab = "";
    //         var vehicleLevelCostTab = "";
    //         var cityWiseCostTab = "activet";
    //         var zoneWiseCostTab = "";
    //         var fixedCostData = false;
    //         var vehicleLevelCostData = false;
    //         var cityWiseCostData = true;
    //         var zoneWiseCostData = false;
    //         var customercostTab = "";
    //         var customercostData = false;
    //     }
    //     else if(pageType == "zoneWiseCostData")
    //     {
    //         var fixedCostTab = "";
    //         var vehicleLevelCostTab = "";
    //         var cityWiseCostTab = "";
    //         var zoneWiseCostTab = "activet";
    //         var fixedCostData = false;
    //         var vehicleLevelCostData = false;
    //         var cityWiseCostData = false;
    //         var zoneWiseCostData = true;
    //         var customercostTab = "";
    //         var customercostData = false;
    //     }else if(pageType == "customercostData")
    //         {
    //             var fixedCostTab = "";
    //             var vehicleLevelCostTab = "";
    //             var cityWiseCostTab = "";
    //             var zoneWiseCostTab = "";
    //             var fixedCostData = false;
    //             var vehicleLevelCostData = false;
    //             var cityWiseCostData = false;
    //             var zoneWiseCostData = true;
    //             var customercostTab = "activet";
    //             var customercostData = true;
    //         }
    //     this.setState({
    //         fixedCostTab:fixedCostTab,
    //         vehicleLevelCostTab:vehicleLevelCostTab,
    //         cityWiseCostTab:cityWiseCostTab,
    //         fixedCostData:fixedCostData,
    //         vehicleLevelCostData:vehicleLevelCostData,
    //         cityWiseCostData:cityWiseCostData,
    //         zoneWiseCostData:zoneWiseCostData,
    //         zoneWiseCostTab:zoneWiseCostTab,
    //         customercostTab:customercostTab,
    //         customercostData:customercostData
    //     })
    // }


    render(){
        const {t} = this.props
            const {
                plant_wise_freight_configuration,
                plant_wise_freight_fixed_cost, 
                plant_wise_freight_vehicle_type_variable_cost,   
                plant_wise_freight_city_level_vehicle_cost,
                plant_wise_freight_zone_level_cost,
                location,locationNames,fixedCostData,vehicleLevelCostData,cityWiseCostData,
            fixedCostTab,vehicleLevelCostTab,cityWiseCostTab,zoneWiseCostData,zoneWiseCostTab,customercostData,customercostTab}=this.state
        // let toolConfig = JSON.parse(localStorage.getItem('tlcfg'))
        // var plant_wise_freight_configuration = 0;
        // var plant_wise_freight_fixed_cost = 0;
        // var plant_wise_freight_vehicle_type_variable_cost = 0;
        // var plant_wise_freight_city_level_vehicle_cost = 0;
        // var plant_wise_freight_zone_level_cost = 0;
        // console.log("toolConfig", toolConfig)
        // if(toolConfig !=undefined && toolConfig !="")
        // {
        //     if(toolConfig.length > 0)
        //     {
        //         plant_wise_freight_configuration = toolConfig[0].plant_wise_freight_configuration
        //         plant_wise_freight_fixed_cost = toolConfig[0].plant_wise_freight_fixed_cost;
        //         plant_wise_freight_vehicle_type_variable_cost = toolConfig[0].plant_wise_freight_vehicle_type_variable_cost;
        //         plant_wise_freight_city_level_vehicle_cost = toolConfig[0].plant_wise_freight_city_level_vehicle_cost;
        //         plant_wise_freight_zone_level_cost = toolConfig[0].zone_level_cost
        //     }
        // }

        console.log("plant_wise_freight_configuration", plant_wise_freight_configuration)
        console.log("plant_wise_freight_fixed_cost", plant_wise_freight_fixed_cost)
        console.log("plant_wise_freight_vehicle_type_variable_cost", plant_wise_freight_vehicle_type_variable_cost)
        console.log("plant_wise_freight_city_level_vehicle_cost", plant_wise_freight_city_level_vehicle_cost)
        console.log("plant_wise_freight_zone_level_cost", plant_wise_freight_zone_level_cost)
       

        var userCheck=localStorage.getItem('role')==='admin'

        return(
            <div className="">
            <div className="row mb-5p pt-3 ml-1">
                    <div className="col-sm-12">
                        
                            <h5 className='fbold  d-flex justify-content-between'>
                                <span>{t('vehicle_cost_configurations')}</span>
                            </h5>
                        
                    </div>
                </div> 
                    <div className="w-full d-flex flex-column justify-content-start">
                    <div className="col-md-2 col-sm-12">
                        <div className="form-group ">
                            <label className="col-form-label f12">{t('pickup_point')}</label>
                            <Select
                                placeholder={"Select"}
                                onChange={this.locationChanged}
                                name="location"
                                value={location}
                                options={locationNames} />

                        </div>
                    </div>

                    <div className="col-md-12 col-sm-12 mt-3 pl-0 ml-2 mb-3" style={{ borderBottom: "1px solid #ccc" }} >
                        <ul className="d-flex justify-content-start align-items-center m-0 pl-0" style={{ gap: "3px" }}>
                            <li onClick={this.onClickTab.bind(this,"customercostData")} className={"lttabs m-0 p-0 border-0 "+(customercostTab)} style={{ outline: "none" }}>
                                <button type="button" className="btn m-0 border-0" style={{ padding: "9px 15px", outline: "none" }}>{t('cost_details')}</button>
                            </li>

                            <li disabled={plant_wise_freight_fixed_cost == 1 ? false : true } onClick={this.onClickTab.bind(this,"fixedCostData")} className={"lttabs m-0 p-0 border-0 "+(fixedCostTab)} style={{ outline: "none" }}>
                                <button type="button"  disabled={plant_wise_freight_fixed_cost == 1 ? false : true } className="btn m-0 border-0" style={{ padding: "9px 15px", outline: "none" }}>{t('fixed_vehicle_cost')}</button>
                            </li>

                            <li disabled={plant_wise_freight_vehicle_type_variable_cost == 1 ? false : true} onClick={this.onClickTab.bind(this,"vehicleLevelCostData")} className={"lttabs border-0 m-0 p-0 "+(vehicleLevelCostTab)} style={{ outline: "none" }}>
                                <button type="button" disabled={plant_wise_freight_vehicle_type_variable_cost == 1 ? false : true} className="btn m-0 border-0" style={{ padding: "9px 15px", outline: "none" }}>{t('variable_vehicle_cost')}</button>
                            </li>

                            <li disabled={plant_wise_freight_city_level_vehicle_cost == 1 ? false: true} onClick={this.onClickTab.bind(this,"cityWiseCostData")} className={"lttabs m-0 p-0 border-0 "+(cityWiseCostTab)} style={{ outline: "none" }}>
                                <button type="button" disabled={plant_wise_freight_city_level_vehicle_cost == 1 ? false: true} className="btn m-0 border-0" style={{ padding: "9px 15px", outline: "none" }}>{t('city_wise_vehicle_cost')}</button>
                            </li>

                            <li disabled={plant_wise_freight_zone_level_cost == 1 ? false: true} onClick={this.onClickTab.bind(this,"zoneWiseCostData")} className={"lttabs m-0 p-0 border-0 "+(zoneWiseCostTab)} style={{ outline: "none" }}>
                                <button type="button" disabled={plant_wise_freight_zone_level_cost == 1 ? false: true} className="btn m-0 border-0" style={{ padding: "9px 15px", outline: "none" }}>{t('zone_wise_cost')}</button>
                            </li>
                        </ul>
                    </div>
                    </div>
                    {customercostData ?  location != '' ? <Vehiclecostcustomerspecific plantLocation={location}/> :'': ''}
                    {fixedCostData ?  location != '' ? <FixedVehicleCost plantLocation={location}/> :'': ''}
                    {vehicleLevelCostData ? location != '' ? <VehicleLevelCost plantLocation={location}/> :'': ''}
                    {cityWiseCostData ? location != '' ? <VehicleCityLevelCost plantLocation={location}/> :'': ''}
                    {zoneWiseCostData ? location != '' ? <ZoneLevelCost plantLocation={location}/> :'': ''}
            </div> 
        )
    }
}   

export default withTranslation()(VehicleCostConfigurations)