
import React, { Component } from 'react';
import { useTranslation } from 'react-i18next';

 
const Consolidatedbtn = (props) => {
    const { t } = useTranslation();
    return (
        <div style={{"textAlign":"center"}}>           
            {t('consolidated_run_plan')}        
        </div>
    );
};

export default Consolidatedbtn;
