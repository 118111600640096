import React,{Component} from "react";
import Datetime from 'react-datetime';
import { withTranslation } from "react-i18next";
import 'react-datetime/css/react-datetime.css';
import { getHyphenDDMMMYYYYHHMM, getHyphenDDMMMYYYY, getYYYYMMDDHHMMSS} from '../common/utils';
import Select from 'react-select';
import Constant from "../common/constants";

 class SpotVehicle extends Component {

    constructor(props){
        super(props);
        this.state={
            available_date_time:'',
            no_of_vehicles_required:0,
            vehicle_type:'',
            volume:0,
            weight:0
        }
    }


    handlerStartDateTime = (event) => {
    	var d = new Date(event._d);
    	
    	var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+d.getDate()+" "+d.getHours()+":"+((d.getMinutes() < 10) ? "0"+d.getMinutes():d.getMinutes())+":"+((d.getSeconds() < 10) ? "0"+d.getSeconds():d.getSeconds());
    	
    	this.setState({
    		available_date_time:getYYYYMMDDHHMMSS(d)
    	});

        this.props.onCollectData({
            available_date_time: getYYYYMMDDHHMMSS(d),
            id:this.props.id,
            vehicle_type:this.state.vehicle_type
          });
    }

    vehicleTypeChanged = (vehicle_type) =>{
        
        this.setState({
            vehicle_type : vehicle_type,
            volume:vehicle_type.volume,
            weight:vehicle_type.veh_weight
        })

        this.props.onCollectData({
            available_date_time: this.state.available_date_time,
            id:this.props.id,
            vehicle_type:vehicle_type
          });

    }

    spotVehicleFieldChanged = (e) =>{
        let name = e.target.name
        let value = e.target.value
        let veh = this.state.vehicle_type
        
        if(name == "volume"){
            veh.volume = value
        }else if(name == "weight"){
            veh.veh_weight = value
        }
        this.setState({[name]:value,
            vehicle_type:veh})
    }

    render(){
        const {t} = this.props
    return(
        <li className="form-row ">
                <div className="col-md-3 col-sm-12">
                    <div className="form-group ">
                        <label className="col-form-label f12">{t('vehicle_type')}</label>
                        <Select
                            placeholder={"Select"}
                            onChange={this.vehicleTypeChanged}
                            name="vehicle_type"
                            value={this.state.vehicle_type}
                            options={this.props.vehicle_type_list} 
                            required
                            />

                    </div>
                </div>

                <div className="col-md-3 col-sm-12 form-group">
                    <label className="col-form-label f12">{t('availability_dateTime')}</label>
                    <Datetime 
                    inputProps={{  name: 'startDate', autoComplete:'off', required:true,readOnly: true, }} 
                    placeholder={"Date"}
                    dateFormat="YYYY-MM-DD" 
                    timeFormat="HH:mm:ss"  
                    // name="startDate"
                    closeOnSelect="true"
                    onChange={this.handlerStartDateTime.bind(this)} />
                </div>

                <div className="col-md-3 col-sm-12">
                    <div className="form-group ">
                        <label className="col-form-label f12">{t('max_volume_cft')}</label>
                        <input type="text" 
                            className= "forminp form-control"
                            // required 
                            disabled
                            // onChange={this.spotVehicleFieldChanged}
                            name="volume" 
                            value={this.state.volume}
                            placeholder="" autoComplete="off" />
                                                             
                    </div>
                </div>

                <div className="col-md-3 col-sm-12">
                    <div className="form-group ">
                        <label className="col-form-label f12">{t('max_weight_pounds')}</label>
                        <input type="text" 
                            className= "forminp form-control"
                            // required 
                            disabled
                            // onChange={this.spotVehicleFieldChanged}
                            name="weight" 
                            value={this.state.weight}
                            placeholder="" autoComplete="off" />
                                                             
                    </div>
                </div>

                {/* <div className="col-md-4 col-sm-12">
                    <div className="form-group ">
                        <label className="col-form-label f12">No Of Spot Vehicles </label>
                        <input required type="number" min='1' step={1}  className="forminp form-control" id="no_of_vehicles_required" name="no_of_vehicles_required" value={this.state.no_of_vehicles_required} 
                        onChange={this.materialDetailsInputFields}
                            autoComplete="off" />
                        
                    </div>
                </div> */}
            </li>
    )
}
}
export default withTranslation()(SpotVehicle)