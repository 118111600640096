import axios from 'axios';
import sjcl from 'sjcl'
var axiosInstance = axios.create({
  baseURL: `https://demo.optirun.in/enmovil/`
  // baseURL: `http://localhost:5005/enmovil/`
});

const key='McKtSdj3uVBABcCK8kGLRNtUxXtNMOEsmUPxQwNI4uo='
// axiosInstance.interceptors.request.use(function (params) {
//   var parameters = params.data;
//   // console.log("parameters ", parameters)
//   var newparameters = {}
//   var flag = 0;
//   for(var key in parameters)
//   {
//     if(parameters[key] == "undefined" || parameters[key] == undefined)
//     {
//       newparameters[key] = "";  
//     }
//     else{
//       try{
        
//         var pm = parameters[key].toString();
//         // newparameters[key] =encode(pm);
//         newparameters[key] =pm;
        
//       }
//       catch(e){

//       }
      
//     }
//   }
//   newparameters["token"] = localStorage.getItem("token");
//   params.data=newparameters;
  
//   return params;


axiosInstance.interceptors.request.use(
  function(req){
    var formdataUrls=['/dispatch/generateLoad','/dispatch/validateInputDealerCode','dispatch/dispatchplandetails',"/dispatch/generateLoadByInputData","http://10.1.0.7:8000/dispatch_planning/fetchLocationAddressDetails",
    "/dispatch/generatePlanData","/dealers/readXLSData","/forecast/readXLSSupplierData","/forecastvehicle/saveOBDInputData","/tsp/addedNewOBDToPlannedData","/dispatch/consolidatedData","/actualdatafo/insertactualdatafo","/actualdatafo/validateInputFoNumberForDuplicates"]
    if (formdataUrls.includes(req.url) ){
      return req
    }else{
      if(req.data!==undefined){
      var params=sjcl.encrypt(key,JSON.stringify(req.data))
      req.data={encryptedparams:params}
      }else{
        var params=sjcl.encrypt(key,JSON.stringify({}))
        req.data={encryptedparams:params}
      }
      return req
    }
  },function(error){
    return Promise.reject(error)
  }
)

axiosInstance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    // console.log('Response data:', response.data);
    if(response.data.encrypted_res !== undefined){
      var output=sjcl.decrypt(key,response.data.encrypted_res)
      response.data=JSON.parse(output) 
    }
    // console.log(response.data,'helo')
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    // console.error('Response interceptor error:', error);
    return Promise.reject(error);
  }
);

export default axiosInstance;
