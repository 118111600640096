import React, { Component } from "react";
import redirectURL from "../../redirectURL";
import Select from "react-select";
import { withTranslation } from "react-i18next";
import CountUp from 'react-countup';
import VehicleStatusMap from "./vehicleStatusMap";
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
import 'ag-grid-enterprise';
import { getHyphenDDMMMYYYYHHMM, getHyphenDDMMMYYYY, getDDMMYYYYHHMM, getDDMMMYYYYHHMMDefault } from "../../common/utils";
// import 'bootstrap/dist/css/bootstrap.min.css';

class VehicleStatusDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            insidePlantCount: [],
            inTransitCount: [],
            nearDealerCount: [],
            returnPlantCount: [],
            emptyOutsidePlant: [],
            allCountersData: [],
            plant_dealers_data: [],
            unplannedData: [],
            inActiveData: [],
            plantData: [],
            customerData: [],
            plantLocationOptions: [],
            plantSelectOption: { "value": "All", "label": "All" },
            vehicles: [],
            vehicles_data_options: [],
            vehicleSelectOption: { "value": "All", "label": "All" },
            tableData: [],
            activeGpsData: [],
            inActiveGpsData: [],
            isActiveSelected: false,
            isInActiveSelected: false,
            isPopupVisible: false,
            countersSelectedArray: {
                isTotalTrucksSelected: true,
                isInsidePlantSelected: false,
                isInTransitSelected: false,
                isNearDealerSelected: false,
                isReturnPlantSelected: false,
                isEmptyOutsidePlantSelected: false,
                isUnplannedSelected: false
            },
            checkboxStates: {
                isInsidePlantSelected: true,
                isInTransitSelected: true,
                isNearDealerSelected: true,
                isReturnPlantSelected: true,
                isEmptyOutsidePlantSelected: true,
                isPlantChecked: false,
                isCustomerChecked: false
            },
            isTotalTrucksSelected: true,
            modules: AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
                resizable: true,
                minWidth: 70,
                initialWidth: 200,
                wrapHeaderText: true,
                autoHeaderHeight: true,
            },
            frameworkComponents: {
            },
            rowData: [],
            rowSelection: "multiple",
            rowModelhighchartoptionsType: "enterprise",
            rowGroupPanelShow: "always",
            detailCellRendererParams: {},
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left",
                    },
                    {
                        statusPanel: "agTotalRowCountComponent",
                        align: "center",
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" },
                ],
            },
            paginationPageSize: 100,
            detailCellRendererParams: {},
            rowClassRules: {
                //"highlitRow": "data.transit_delay == 1",
                //"green":"data.status >= 3"
            },
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel",
                    },
                    {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel",
                    },
                ],
            },
            animateRows: true,
        }
    }
    async componentDidMount() {
        await this.getCountersData();
    }

    getCountersData = async () => {
        try {
            var params = {
                status: 1
            }
            const response = await redirectURL.post("/master/getVehicleStatusCountersData", params);

            if (response.data.status === "success") {
                var inside_plant = [];
                var in_transit = [];
                var near_dealer = [];
                var return_plant = [];
                var empty_outside = [];
                var un_planned = [];
                var in_active = [];
                var plant = [];
                var customer = [];
                var active_gps_data = []
                var in_active_gps_data = []
                response.data.countersData.map((each) => {
                    if (each.gps_status == 1) {
                        active_gps_data.push(each)
                    } else if (each.gps_status == 0) {
                        in_active_gps_data.push(each)
                    }
                })
                response.data.countersData.map((each) => {
                    if (each.vehicle_current_status == 1) {
                        inside_plant.push(each)
                    } else if (each.vehicle_current_status == 2) {
                        in_transit.push(each)
                    } else if (each.vehicle_current_status == 3) {
                        near_dealer.push(each)
                    } else if (each.vehicle_current_status == 4) {
                        return_plant.push(each)
                    } else if (each.vehicle_current_status == 5) {
                        empty_outside.push(each)
                    } else if (each.vehicle_current_status == 6) {
                        un_planned.push(each)
                    }
                })
                response.data.plant_dealers_data.map((each) => {
                    if (each.location_type == 1) {
                        plant.push(each)
                    } if (each.location_type == 2) {
                        customer.push(each)
                    }
                })
                var location_access=JSON.parse(localStorage.getItem("location_access"))
                var plant_location_options = [{ "value": "All", "label": "All" }]
                if (location_access !== null){
                    plant.map((each) => {
                        // console.log(each,'each')
                        if (location_access.includes(each.location_code)){
                        plant_location_options.push({ "value": each.location_code, "label": each.location_name })
                    }
                    })
                }else{
                    plant.map((each) => {
                        // console.log(each,'each')
                        plant_location_options.push({ "value": each.location_code, "label": each.location_name })
                    })
                }
                var vehicles = [{ "value": "All", "label": 'All' }]
                response.data.vehicles.map((each) => {
                    vehicles.push({ value: each.vehicle_no, label: each.vehicle_no, code: each.location_code })
                })
                this.setState({
                    insidePlantCount: inside_plant,
                    inTransitCount: in_transit,
                    nearDealerCount: near_dealer,
                    returnPlantCount: return_plant,
                    emptyOutsidePlant: empty_outside,
                    allCountersData: response.data.countersData,
                    plant_dealers_data: response.data.plant_dealers_data,
                    inActiveData: in_active,
                    unplannedData: un_planned,
                    plantData: plant,
                    customerData: customer,
                    plantLocationOptions: plant_location_options,
                    tableData: response.data.countersData,
                    vehicles: vehicles,
                    vehicles_data_options: vehicles,
                    activeGpsData: active_gps_data,
                    inActiveGpsData: in_active_gps_data
                })
            } else {
                // Handle other cases if needed
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }
    handleCheckboxChange = (checkboxName) => {
        var tab;
        if (checkboxName == "isInsidePlantSelected"){
            tab = "Inside_dc"
        }
        else if(checkboxName == "isInTransitSelected") {
            tab = "In Transit"
        }
        else if (checkboxName == "isNearDealerSelected"){
            tab = "Near Dealer"
        }
        else if (checkboxName == "isReturnPlantSelected"){
            tab = "Return to Plant"
        }
        else if (checkboxName == "isEmptyOutsidePlantSelected"){
            tab = "Outside Plant"
        }        
        var activity = "";
        if (this.state.checkboxStates[checkboxName] == true){
            activity = ` ${tab} UnChecked`
        }
        else{
            activity = `${tab} Checked`
        }        
        console.log(typeof(this.state.checkboxStates))
        this.setState((prevState) => ({
            checkboxStates: {
                ...prevState.checkboxStates,
                [checkboxName]: !prevState.checkboxStates[checkboxName],
            },
        }));
        let logParams = {
            "location_code" : this.state.plantSelectOption.value,
            "location_name" : this.state.plantSelectOption.label,
            "user_name" : localStorage.getItem("username"),
            "useremail" : localStorage.getItem("email"),
            "client" : localStorage.getItem("client"),
            "screen" : "Vehicle Status Screen ",
            "activity" : activity,
            "event" : `Showing trucks details`,
            "data_type" : this.state.data_type,
        }        
         redirectURL.post("/master/loguserUpdatesorChanges",logParams)
    };
    onChangePlant = async (selectedOption) => {
        var filtered_data = []
        var inside_plant = [];
        var in_transit = [];
        var near_dealer = [];
        var return_plant = [];
        var empty_outside = [];
        var un_planned = [];
        var in_active = [];
        var plant = [];
        var customer = [];
        var vehicles = [{ value: "All", label: 'All', code: "" }]
        var active_gps_data = [];
        var in_active_gps_data = [];
        if (selectedOption.value == "All") {
            filtered_data = this.state.allCountersData
            this.state.allCountersData.map((each) => {
                if (each.gps_status == 0) {
                    in_active_gps_data.push(each)
                } else if (each.gps_status == 1) {
                    active_gps_data.push(each)
                }
            })
            this.state.plant_dealers_data.map((each) => {
                if (each.location_type == 1) {
                    plant.push(each)
                } else if (each.location_type == 2) {
                    customer.push(each)
                }
            })
            vehicles = this.state.vehicles
        } else {
            this.state.allCountersData.map((each) => {
                if (selectedOption.value == each.plant_code) {
                    filtered_data.push(each)
                }
            })
            filtered_data.map((each) => {
                if (each.gps_status == 0) {
                    in_active_gps_data.push(each)
                } else if (each.gps_status == 1) {
                    active_gps_data.push(each)
                }
            })
            this.state.plant_dealers_data.map((each) => {
                if (each.location_type == 1 && each.drop_corresponding_pickup_location_code == selectedOption.value) {
                    plant.push(each)
                } else if (each.location_type == 2 && each.drop_corresponding_pickup_location_code == selectedOption.value) {
                    customer.push(each)
                }
            })
        }
        this.state.vehicles.map((each) => {
            if (each.code == selectedOption.value) {
                vehicles.push(each)
            }
        })
        var new_filtered_data = []
        if(this.state.isTotalTrucksSelected){
            new_filtered_data = filtered_data
        }else if (this.state.isActiveSelected){
            new_filtered_data = active_gps_data
        }else if (this.state.isInActiveSelected){
            new_filtered_data = in_active_gps_data
        }
        new_filtered_data.map((each) => {
            if (each.vehicle_current_status == 1) {
                inside_plant.push(each)
            } else if (each.vehicle_current_status == 2) {
                in_transit.push(each)
            } else if (each.vehicle_current_status == 3) {
                near_dealer.push(each)
            } else if (each.vehicle_current_status == 4) {
                return_plant.push(each)
            } else if (each.vehicle_current_status == 5) {
                empty_outside.push(each)
            } else if (each.vehicle_current_status == 6) {
                un_planned.push(each)
            }
        })
        await this.setState({
            plantSelectOption: selectedOption,
            insidePlantCount: inside_plant,
            inTransitCount: in_transit,
            nearDealerCount: near_dealer,
            returnPlantCount: return_plant,
            emptyOutsidePlant: empty_outside,
            inActiveData: in_active,
            unplannedData: un_planned,
            plantData: plant,
            customerData: customer,
            tableData: new_filtered_data,
            vehicles_data_options: vehicles,
            vehicleSelectOption: { value: "All", label: 'All' },
            activeGpsData: active_gps_data,
            inActiveGpsData: in_active_gps_data,
            activeGpsData: active_gps_data
        })
        let logParams = {
            "location_code" : this.state.plantSelectOption.value,
            "location_name" : this.state.plantSelectOption.label,
            "user_name" : localStorage.getItem("username"),
            "useremail" : localStorage.getItem("email"),
            "client" : localStorage.getItem("client"),
            "screen" : "Vehicle Status Screen ",
            "activity" : `Clicked on Dc Dropdown`,
            "event" : `Selected ${selectedOption.value} plant`,
            "data_type" : this.state.data_type,
        }        
        redirectURL.post("/master/loguserUpdatesorChanges",logParams)
    }
    onChangeVehicle = async (selectedOption) => {
        var filtered_data = []
        var inside_plant = [];
        var in_transit = [];
        var near_dealer = [];
        var return_plant = [];
        var empty_outside = [];
        var un_planned = [];
        var in_active = [];
        var plant = [];
        var customer = [];
        var in_active_gps_data = [];
        var active_gps_data = [];
        if (this.state.plantSelectOption.value == "All") {
            if (selectedOption.value == "All") {
                filtered_data = this.state.allCountersData
                this.state.allCountersData.map((each) => {
                    if (each.gps_status == 0) {
                        in_active_gps_data.push(each)
                    } else if (each.gps_status == 1) {
                        active_gps_data.push(each)
                    }
                })
            } else {
                this.state.allCountersData.map((each) => {
                    if (selectedOption.label == each.vehicle_no) {
                        filtered_data.push(each)
                    }
                })
                filtered_data.map((each) => {
                    if (each.gps_status == 0) {
                        in_active_gps_data.push(each)
                    } else if (each.gps_status == 1) {
                        active_gps_data.push(each)
                    }
                })
            }
            this.state.plant_dealers_data.map((each) => {
                if (each.location_type == 1) {
                    plant.push(each)
                } else if (each.location_type == 2) {
                    customer.push(each)
                }
            })
        } else {
            if (selectedOption.value == "All") {
                this.state.allCountersData.map((each) => {
                    if (this.state.plantSelectOption.value == each.plant_code) {
                        filtered_data.push(each)
                    }
                })
                filtered_data.map((each) => {
                    if (each.gps_status == 0) {
                        in_active_gps_data.push(each)
                    } else if (each.gps_status == 1) {
                        active_gps_data.push(each)
                    }
                })
                this.state.plant_dealers_data.map((each) => {
                    if (each.location_type == 1 && each.drop_corresponding_pickup_location_code == this.state.plantSelectOption.value) {
                        plant.push(each)
                    } else if (each.location_type == 2 && each.drop_corresponding_pickup_location_code == this.state.plantSelectOption.value) {
                        customer.push(each)
                    }
                })
            } else {
                this.state.allCountersData.map((each) => {
                    if (this.state.plantSelectOption.value == each.plant_code && selectedOption.label == each.vehicle_no) {
                        filtered_data.push(each)
                    }
                })
                filtered_data.map((each) => {
                    if (each.gps_status == 0) {
                        in_active_gps_data.push(each)
                    } else if (each.gps_status == 1) {
                        active_gps_data.push(each)
                    }
                })
                this.state.plant_dealers_data.map((each) => {
                    if (each.location_type == 1 && each.drop_corresponding_pickup_location_code == selectedOption.code) {
                        plant.push(each)
                    } else if (each.location_type == 2 && each.drop_corresponding_pickup_location_code == selectedOption.code) {
                        customer.push(each)
                    }
                })
            }
        }
        var new_filtered_data = []
        if(this.state.isTotalTrucksSelected){
            new_filtered_data = filtered_data
        }else if (this.state.isActiveSelected){
            new_filtered_data = active_gps_data
        }else if (this.state.isInActiveSelected){
            new_filtered_data = in_active_gps_data
        }
        new_filtered_data.map((each) => {
            if (each.vehicle_current_status == 1) {
                inside_plant.push(each)
            } else if (each.vehicle_current_status == 2) {
                in_transit.push(each)
            } else if (each.vehicle_current_status == 3) {
                near_dealer.push(each)
            } else if (each.vehicle_current_status == 4) {
                return_plant.push(each)
            } else if (each.vehicle_current_status == 5) {
                empty_outside.push(each)
            } else if (each.vehicle_current_status == 6) {
                un_planned.push(each)
            }
        })
        await this.setState({
            vehicleSelectOption: selectedOption,
            insidePlantCount: inside_plant,
            inTransitCount: in_transit,
            nearDealerCount: near_dealer,
            returnPlantCount: return_plant,
            emptyOutsidePlant: empty_outside,
            inActiveData: in_active,
            unplannedData: un_planned,
            plantData: plant,
            customerData: customer,
            tableData: new_filtered_data,
            activeGpsData: active_gps_data,
            inActiveGpsData: in_active_gps_data
        })
        let logParams = {
            "location_code" : this.state.vehicleSelectOption.value,
            "location_name" : this.state.vehicleSelectOption.label,
            "user_name" : localStorage.getItem("username"),
            "useremail" : localStorage.getItem("email"),
            "client" : localStorage.getItem("client"),
            "screen" : "Vehicle Status Screen ",
            "activity" : `Clicked on Vehicle Dropdown`,
            "event" : `Selected ${this.state.vehicleSelectOption.value} Vehicle`,
            "data_type" : this.state.data_type,
        }        
        redirectURL.post("/master/loguserUpdatesorChanges",logParams)
    }
    arrangeStatusData = (status) => {
      var big_data = []
      if(status == "total_trucks"){
        big_data = [...this.state.activeGpsData, ...this.state.inActiveGpsData]
      }else if (status == "active_trucks"){
        big_data = this.state.activeGpsData
      }else if (status == "in_active_trucks"){
        big_data = this.state.inActiveGpsData
      }
      var inside_plant = [];
      var in_transit = [];
      var near_dealer = [];
      var return_plant = [];
      var empty_outside = [];
      big_data.map((each) => {
        if (each.vehicle_current_status == 1) {
            inside_plant.push(each)
        } else if (each.vehicle_current_status == 2) {
            in_transit.push(each)
        } else if (each.vehicle_current_status == 3) {
            near_dealer.push(each)
        } else if (each.vehicle_current_status == 4) {
            return_plant.push(each)
        } else if (each.vehicle_current_status == 5) {
            empty_outside.push(each)
        }
    })
    if(status == "total_trucks"){
        this.setState({
            insidePlantCount: inside_plant,
            inTransitCount: in_transit,
            nearDealerCount: near_dealer,
            returnPlantCount: return_plant,
            emptyOutsidePlant: empty_outside,
            tableData: [...this.state.activeGpsData, ...this.state.inActiveGpsData]
        })
    }else if(status == "active_trucks"){
        this.setState({
            insidePlantCount: inside_plant,
            inTransitCount: in_transit,
            nearDealerCount: near_dealer,
            returnPlantCount: return_plant,
            emptyOutsidePlant: empty_outside,
            tableData: [...this.state.activeGpsData]
        })
    }else if(status == "in_active_trucks"){
        this.setState({
            insidePlantCount: inside_plant,
            inTransitCount: in_transit,
            nearDealerCount: near_dealer,
            returnPlantCount: return_plant,
            emptyOutsidePlant: empty_outside,
            tableData: [...this.state.inActiveGpsData]
        })
    }
    }
    onClickingCounter = async (prop) => {        
        let logParams = {
            "location_code" : this.state.plantSelectOption.value,
            "location_name" : this.state.plantSelectOption.label,
            "user_name" : localStorage.getItem("username"),
            "useremail" : localStorage.getItem("email"),
            "client" : localStorage.getItem("client"),
            "screen" : "Vehicle Status Screen ",
            "activity" : `Clicked on ${prop} tab`,
            "event" : `Showing ${prop} trucks details`,
            "data_type" : this.state.data_type,
        }        
        await redirectURL.post("/master/loguserUpdatesorChanges",logParams)
       switch(prop){
        case "inside_dc":            
            this.setState({
                checkboxStates: {
                    // isTotalTrucksSelected: false,
                    isInsidePlantSelected: true,
                    isInTransitSelected: false,
                    isNearDealerSelected: false,
                    isReturnPlantSelected: false,
                    isEmptyOutsidePlantSelected: false
                },
            })
            break;
            case "in_transit":                
                this.setState({
                    checkboxStates: {
                        // isTotalTrucksSelected: false,
                        isInsidePlantSelected: false,
                        isInTransitSelected: true,
                        isNearDealerSelected: false,
                        isReturnPlantSelected: false,
                        isEmptyOutsidePlantSelected: false
                    },
                })
                break;
                case "near_customer":                    
                    this.setState({
                        checkboxStates: {
                            // isTotalTrucksSelected: false,
                            isInsidePlantSelected: false,
                            isInTransitSelected: false,
                            isNearDealerSelected: true,
                            isReturnPlantSelected: false,
                            isEmptyOutsidePlantSelected: false
                        },
                    })
                    break;
                    case "return_dc":                        
                        this.setState({
                            checkboxStates: {
                                // isTotalTrucksSelected: false,
                                isInsidePlantSelected: false,
                                isInTransitSelected: false,
                                isNearDealerSelected: false,
                                isReturnPlantSelected: true,
                                isEmptyOutsidePlantSelected: false
                            },
                        })
                        break;
                        case "empty_outside_dc":                            
                            this.setState({
                                checkboxStates: {
                                    // isTotalTrucksSelected: false,
                                    isInsidePlantSelected: false,
                                    isInTransitSelected: false,
                                    isNearDealerSelected: false,
                                    isReturnPlantSelected: false,
                                    isEmptyOutsidePlantSelected: true
                                },
                            })
                            break;
                            case "total_trucks":                                
                            this.setState({
                                checkboxStates: {
                                    isInsidePlantSelected: true,
                                    isInTransitSelected: true,
                                    isNearDealerSelected: true,
                                    isReturnPlantSelected: true,
                                    isEmptyOutsidePlantSelected: true,
                                },
                                isActiveSelected: false,
                                isInActiveSelected: false,
                                isTotalTrucksSelected: true,
                            }, () => {this.arrangeStatusData("total_trucks")})
                            break;
                            case "active_trucks": 
                                console.log(this.state.tableData, "active")
                            this.setState({
                                checkboxStates: {
                                    isInsidePlantSelected: true,
                                    isInTransitSelected: true,
                                    isNearDealerSelected: true,
                                    isReturnPlantSelected: true,
                                    isEmptyOutsidePlantSelected: true,
                                },
                                isActiveSelected: true,
                                isInActiveSelected: false,
                                isTotalTrucksSelected: false,
                            }, () => {this.arrangeStatusData("active_trucks")})
                            break;
                            case "in_active_trucks":                                
                                console.log(this.state.tableData, "in_active")
                            this.setState({
                                checkboxStates: {
                                    isInsidePlantSelected: true,
                                    isInTransitSelected: true,
                                    isNearDealerSelected: true,
                                    isReturnPlantSelected: true,
                                    isEmptyOutsidePlantSelected: true
                                },
                                isActiveSelected: false,
                                isInActiveSelected: true,
                                isTotalTrucksSelected: false,
                            }, () => {this.arrangeStatusData("in_active_trucks")})
                            break;
        default:
            break;
       }
    }
    render() {
        const {t} = this.props;
        const gridOptions = {
            pagination: true,
            paginationPageSize: this.state.paginationPageSize,
            defaultColDef: this.state.defaultColDef,
            // domLayout: 'autoHeight',
            rowSelection: 'multiple',
            getRowStyle: function (params) {
                return { fontWeight: "500", color: "black", fontSize: "13px", height: "200px" }
            }
        };
        // const vehicleStatusColDefs = [
        //     {
        //         headerName: "Truck No",
        //         field: "vehicle_no",
        //         width: 110,
        //         filter: true,
        //         resizable: true,
        //         editable: false,
        //         headerClass: ["column-header-style"]
        //     },
        //     {
        //         headerName: "Facility Name",
        //         field: "plant_name",
        //         width: 140,
        //         filter: true,
        //         resizable: true,
        //         editable: false,
        //         headerClass: ["column-header-style"]
        //     },
        //     {
        //         headerName: "Current Status",
        //         width: 170,
        //         filter: true,
        //         resizable: true,
        //         editable: false,
        //         valueGetter: (params) => {
        //             if (params.data.vehicle_current_status == 1) {
        //                 return "Inside DC"
        //             } else if (params.data.vehicle_current_status == 2) {
        //                 return "In-Transit to Customer"
        //             } else if (params.data.vehicle_current_status == 3) {
        //                 return "Near Customer"
        //             } else if (params.data.vehicle_current_status == 4) {
        //                 return "Return to DC"
        //             } else if (params.data.vehicle_current_status == 5) {
        //                 return "Empty Outside DC"
        //             } else if (params.data.vehicle_current_status == 6) {
        //                 return "Unplanned"
        //             }
        //         },
        //         headerClass: ["column-header-style"]
        //     },
        //     // {
        //     //     headerName: "Truck Location",
        //     //     field: "last_known_address",
        //     //     width: 150,
        //     //     filter: true,
        //     //     resizable: true,
        //     //     editable: false,
        //     //     headerClass: ["column-header-style"]
        //     // },
        //     {
        //         headerName: "Next Availability Date At DC",
        //         field: "vehicle_next_available_date",
        //         width: 230,
        //         filter: true,
        //         resizable: true,
        //         editable: false,
        //         headerClass: ["column-header-style"],
        //         valueGetter: (params) => {
        //             if (params.data.vehicle_next_available_date == "") {
        //                 return "NA"
        //             } else {         
        //                 const formattedTimestamp = getDDMMMYYYYHHMMDefault(params.data.vehicle_next_available_date)          
        //                 return formattedTimestamp;
        //             }
        //         },
        //     },
        //     {
        //         headerName: "Transporter Name",
        //         field: "transporter_name",
        //         width: 170,
        //         filter: true,
        //         resizable: true,
        //         editable: false,
        //         headerClass: ["column-header-style"]
        //     },
        //     {
        //         headerName: "GPS Status",
        //         width: 130,
        //         filter: true,
        //         resizable: true,
        //         editable: false,
        //         headerClass: ["column-header-style"],
        //         valueGetter: (params) => {
        //             if (params.data.gps_status == 0) {
        //                 return "Inactive"
        //             } else if (params.data.gps_status == 1) {
        //                 return "Active"
        //             }
        //         },
        //     },
        // ]

        const vehicleStatusColDefs = [
            {
                headerName: t('truck_no'),
                field: "vehicle_no",
                width: 110,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: t('facility_name'),
                field: "plant_name",
                width: 140,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: t('current_status'),
                width: 170,
                filter: true,
                resizable: true,
                editable: false,
                valueGetter: (params) => {
                    if (params.data.vehicle_current_status == 1) {
                        return t('inside_dc')
                    } else if (params.data.vehicle_current_status == 2) {
                        return t('in_transit_to_customer')
                    } else if (params.data.vehicle_current_status == 3) {
                        return t('near_customer')
                    } else if (params.data.vehicle_current_status == 4) {
                        return t('return_to_dc')
                    } else if (params.data.vehicle_current_status == 5) {
                        return t('empty_outside_dc')
                    } else if (params.data.vehicle_current_status == 6) {
                        return t('unplanned')
                    }
                },
                headerClass: ["column-header-style"]
            },
            // {
            //     headerName: t('truck_location'),
            //     field: "last_known_address",
            //     width: 150,
            //     filter: true,
            //     resizable: true,
            //     editable: false,
            //     headerClass: ["column-header-style"]
            // },
            {
                headerName: t('next_availability_date_at_dc'),
                field: "vehicle_next_available_date",
                width: 230,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"],
                valueGetter: (params) => {
                    if (params.data.vehicle_next_available_date == "") {
                        return t('na')
                    } else {
                        const formattedTimestamp = getDDMMMYYYYHHMMDefault(params.data.vehicle_next_available_date)
                        return formattedTimestamp;
                    }
                },
            },
            {
                headerName: t('transporter_name'),
                field: "transporter_name",
                width: 170,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: t('gps_status'),
                width: 130,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"],
                valueGetter: (params) => {
                    if (params.data.gps_status == 0) {
                        return t('inactive')
                    } else if (params.data.gps_status == 1) {
                        return t('active')
                    }
                },
            },
        ]
        

        var viewMapData = []
        var aggriddata = []
        if (this.state.checkboxStates.isInsidePlantSelected == true &&
            this.state.checkboxStates.isInTransitSelected == true &&
            this.state.checkboxStates.isNearDealerSelected == true &&
            this.state.checkboxStates.isReturnPlantSelected == true &&
            this.state.checkboxStates.isEmptyOutsidePlantSelected == true
            ) {
            var plant_data = []
            var customer_data = []
            var active_data = []
            var in_active_data = []
            if(this.state.checkboxStates.isPlantChecked){
                plant_data = [...this.state.plantData, ...plant_data]
            }if(this.state.checkboxStates.isCustomerChecked){
                customer_data = [...this.state.customerData, ...customer_data]
            }
            if(this.state.isTotalTrucksSelected){
                viewMapData = [...this.state.tableData, ...plant_data, ...customer_data]
                aggriddata = this.state.tableData
            }else if(this.state.isActiveSelected){
                this.state.tableData.map((each) => {
                    if(each.gps_status == 1){
                        active_data.push(each)
                    }
                })
                viewMapData = [...active_data, ...plant_data, ...customer_data]
                aggriddata = active_data
            }else if(this.state.isInActiveSelected){
                this.state.tableData.map((each) => {
                    if(each.gps_status == 0){
                        in_active_data.push(each)
                    }
                })
                viewMapData = [...in_active_data, ...plant_data, ...customer_data]
                aggriddata = in_active_data
            }
        } else {
            if (this.state.checkboxStates.isPlantChecked) {
                viewMapData = [...this.state.plantData, ...viewMapData]
            } if (this.state.checkboxStates.isCustomerChecked) {
                viewMapData = [...this.state.customerData, ...viewMapData]
            } if (this.state.checkboxStates.isInsidePlantSelected) {
                viewMapData = [...this.state.insidePlantCount, ...viewMapData]
                aggriddata = [...this.state.insidePlantCount, ...aggriddata]
            } if (this.state.checkboxStates.isInTransitSelected) {
                viewMapData = [...this.state.inTransitCount, ...viewMapData]
                aggriddata = [...this.state.inTransitCount, ...aggriddata]
            } if (this.state.checkboxStates.isNearDealerSelected) {
                viewMapData = [...this.state.nearDealerCount, ...viewMapData]
                aggriddata = [...this.state.nearDealerCount, ...aggriddata]
            } if (this.state.checkboxStates.isReturnPlantSelected) {
                viewMapData = [...this.state.returnPlantCount, ...viewMapData]
                aggriddata = [...this.state.returnPlantCount, ...aggriddata]
            } if (this.state.checkboxStates.isEmptyOutsidePlantSelected) {
                viewMapData = [...this.state.emptyOutsidePlant, ...viewMapData]
                aggriddata = [...this.state.emptyOutsidePlant, ...aggriddata]
            }
        }
        const customStyles = {
            control: (provided) => ({
                ...provided,
                width: 150, // Adjust the width as needed
            }),
        };
        // console.log(this.state.tableData, "tableData")
        return (
            <div className="row">
            <div className="container" style={{ height: "100vh" }}>
                <div className="col-12 pl-2 my-2">
                   <h5 className="fbold">{t('vehicle_status_dashboard')}</h5>
                </div>
                <div className="row mb-20p mt-3 pl-2">
                    <div className="col-12 d-flex flex-row">
                        <div className="col-2" style={{ paddingLeft: "0px" }}>
                            <p style={{ fontSize: "17px", fontWeight: "bold", marginBottom: "8px", color: "#000" }}>{t('dc')}</p>
                            <Select
                                value={this.state.plantSelectOption}
                                onChange={(selectedOption) => { this.onChangePlant(selectedOption) }}
                                options={this.state.plantLocationOptions}
                                placeholder="Select option"
                            // styles={customStyles}
                            />
                        </div>
                        <div className="col-2" style={{ paddingLeft: "0px" }}>
                            <p style={{ fontSize: "17px", fontWeight: "bold", marginBottom: "8px", color: "#000" }}>{t('vehicles')}</p>
                            <Select
                                value={this.state.vehicleSelectOption}
                                onChange={(selectedOption) => { this.onChangeVehicle(selectedOption) }}
                                options={this.state.vehicles_data_options}
                                placeholder="Select option"
                            // styles={customStyles}
                            />
                        </div>
                    </div>
                </div>
                <div className="row mb-20p">
                    <div className="col-12 d-flex flex-row">
                        <div className="d-flex mr-5p" style={{ backgroundColor: "#fff", width: "17%", padding: "5px", borderRadius: "5px" }}>
                            <div className="d-flex flex-column justify-content-between align-items-center pl-3p pr-3p" style={{ width: "100%", paddingLeft: "5px", paddingRight: "5px", cursor: "pointer" }} onClick={() => {this.onClickingCounter("total_trucks")}}>
                                <p style={{ fontSize: "17px", margin: "0px", fontWeight: "bold", color: "#A94438" }}>{t('total_trucks')}</p>
                                <div className="d-flex align-items-center">
                                  <CountUp end={this.state.activeGpsData.length + this.state.inActiveGpsData.length} duration={2.5} style={{ fontWeight: "bold", color: "#000", fontSize: "23px", marginTop: "10px" }} />
                                  {/* {this.state.isTotalTrucksSelected && <i class="fa fa-check" aria-hidden="true" style={{color: "green", fontSize: "20px", marginLeft: "10px"}}></i>} */}
                                </div>
                            </div>
                            <div className="d-flex flex-column justify-content-start align-items-start" style={{ width: "40%", marginLeft: "10px" }}> 
                                <div style={{cursor: "pointer"}} onClick={() => {this.onClickingCounter("active_trucks")}}>
                                  <div className="d-flex align-items-center">
                                    <p style={{ fontSize: "15px", margin: "0px", fontWeight: "bold", color: "#A94438", marginRight: "10px" }}>{t('active')}</p>
                                    <i
                                        class="fa fa-info-circle info-icon"
                                        aria-hidden="true"
                                        data-tooltip="Trucks with GPS Within the last 24 Hrs"
                                    ></i>
                                  </div>
                                  <div className="d-flex align-items-center">
                                    <CountUp end={this.state.activeGpsData.length} duration={2.5} style={{ fontWeight: "bold", color: "#000", fontSize: "20px" }} />
                                    {this.state.isActiveSelected && <i class="fa fa-check" aria-hidden="true" style={{color: "green", fontSize: "20px", marginLeft: "10px"}}></i>}
                                  </div>
                                </div>
                                <div style={{cursor: "pointer"}} onClick={() => {this.onClickingCounter("in_active_trucks")}}>
                                  <div className="d-flex align-items-center">
                                    <p style={{ fontSize: "15px", margin: "0px", fontWeight: "bold", color: "#A94438", marginRight: "10px"}}>{t('inactive')}</p>
                                    <i
                                        class="fa fa-info-circle info-icon"
                                        aria-hidden="true"
                                        data-tooltip=" Trucks with No GPS in last 24 Hrs / Not Integrated"
                                    ></i>
                                  </div>
                                  <div className="d-flex align-items-center">
                                    <CountUp end={this.state.inActiveGpsData.length} duration={2.5} style={{ fontWeight: "bold", color: "#000", fontSize: "20px" }} />
                                    {this.state.isInActiveSelected && <i class="fa fa-check" aria-hidden="true" style={{color: "green", fontSize: "20px", marginLeft: "10px"}}></i>}
                                  </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex ml-5p mr-5p" style={{ backgroundColor: "#fff", width: "17%", padding: "5px", borderRadius: "5px" }}>
                            <div className="d-flex" style={{ width: "70%", borderRight: "1px dashed lightgrey", paddingLeft: "5px", paddingRight: "5px" }}>
                                <div className="d-flex flex-column justify-content-between" style={{ width: "85%", cursor: "pointer" }} onClick={() => {this.onClickingCounter("inside_dc")}}>
                                    <p style={{ fontSize: "17px", margin: "0px", fontWeight: "bold", color: "#132EDE" }}>{t('inside_dc')}</p>
                                    <CountUp end={this.state.insidePlantCount.length} duration={2.5} style={{ fontWeight: "bold", color: "#000", fontSize: "23px", marginTop: "10px" }} />
                                </div>
                                <div className="d-flex justify-content-center align-items-start" style={{width: "15%"}}>
                                    <div>
                                        <i
                                            class="fa fa-info-circle info-icon"
                                            aria-hidden="true"
                                            data-tooltip="Trucks which are with in the plant loading area"
                                        ></i>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-column justify-content-between align-items-center" style={{ width: "30%" }}>
                                <img src={require("../../../assets/images/inside-truck.png")} style={{ width: "35px", height: "35px" }} />
                                <input type="checkbox" style={{ width: "15px", height: "15px", marginBottom: "10px" }} checked={this.state.checkboxStates.isInsidePlantSelected} onChange={() => this.handleCheckboxChange('isInsidePlantSelected')} />
                            </div>
                        </div>
                        <div className="d-flex ml-5p mr-5p" style={{ backgroundColor: "#fff", width: "17%", padding: "5px", borderRadius: "5px" }}>
                            <div className="d-flex" style={{ width: "70%", borderRight: "1px dashed lightgrey", paddingLeft: "5px", paddingRight: "5px" }}>
                                <div className="d-flex flex-column justify-content-between" style={{width: "85%", cursor: "pointer"}} onClick={() => {this.onClickingCounter("in_transit")}}>
                                  <p style={{ fontSize: "17px", margin: "0px", fontWeight: "bold", color: "#43a047" }}>{t('in_transit_to_customer')}</p>
                                  <CountUp end={this.state.inTransitCount.length} duration={2.5} style={{ fontWeight: "bold", color: "#000", fontSize: "23px", marginTop: "10px" }} />
                                </div>
                                <div className="d-flex justify-content-center align-items-start" style={{ width: "15%"}}>
                                    <div>
                                        <i
                                            class="fa fa-info-circle info-icon"
                                            aria-hidden="true"
                                            data-tooltip="Trucks are travelling  towards customers"
                                        ></i>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-column justify-content-between align-items-center" style={{ width: "30%" }}>
                                <img src={require("../../../assets/images/intransit-truck.png")} style={{ width: "35px", height: "35px" }} />
                                <input type="checkbox" style={{ width: "15px", height: "15px", marginBottom: "10px" }} checked={this.state.checkboxStates.isInTransitSelected} onChange={() => this.handleCheckboxChange('isInTransitSelected')} />
                            </div>
                        </div>
                        <div className="d-flex ml-5p mr-5p" style={{ backgroundColor: "#fff", width: "17%", padding: "5px", borderRadius: "5px" }}>
                            <div className="d-flex" style={{ width: "70%", borderRight: "1px dashed lightgrey", paddingLeft: "5px", paddingRight: "5px" }}>
                                <div className="d-flex flex-column justify-content-between" style={{width: "85%", cursor: "pointer"}} onClick={() => {this.onClickingCounter("near_customer")}}>
                                  <p style={{ fontSize: "17px", margin: "0px", fontWeight: "bold", color: "#AD834E" }}>{t('near_customer')}</p>
                                  <CountUp end={this.state.nearDealerCount.length} duration={2.5} style={{ fontWeight: "bold", color: "#000", fontSize: "23px", marginTop: "10px" }} />
                                </div>
                                <div className="d-flex justify-content-center align-items-start" style={{ width: "15%"}}>
                                    <div>
                                        <i
                                            class="fa fa-info-circle info-icon"
                                            aria-hidden="true"
                                            data-tooltip="Trucks which are within 1 km radius to Customer"
                                        ></i>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-column justify-content-between align-items-center" style={{ width: "30%" }}>
                                <img src={require("../../../assets/images/near-truck.png")} style={{ width: "35px", height: "35px" }} />
                                <input type="checkbox" style={{ width: "15px", height: "15px", marginBottom: "10px" }} checked={this.state.checkboxStates.isNearDealerSelected} onChange={() => this.handleCheckboxChange('isNearDealerSelected')} />
                            </div>
                        </div>
                        <div className="d-flex ml-5p mr-5p" style={{ backgroundColor: "#fff", width: "17%", padding: "5px", borderRadius: "5px" }}>
                            <div className="d-flex" style={{ width: "70%", borderRight: "1px dashed lightgrey", paddingLeft: "5px", paddingRight: "5px" }}>
                                <div className="d-flex flex-column justify-content-between" style={{width: "85%", cursor: "pointer"}} onClick={() => {this.onClickingCounter("return_dc")}}>
                                  <p style={{ fontSize: "17px", margin: "0px", fontWeight: "bold", color: "#297AB0" }}>{t('return_to_dc')}</p>
                                  <CountUp end={this.state.returnPlantCount.length} duration={2.5} style={{ fontWeight: "bold", color: "#000", fontSize: "23px", marginTop: "10px" }} />
                                </div>
                                <div className="d-flex justify-content-center align-items-start" style={{ width: "15%"}}>
                                    <div>
                                        <i
                                            class="fa fa-info-circle info-icon"
                                            aria-hidden="true"
                                            data-tooltip="Trucks that completed their shipments and returning to DC / Empty Trucks and are > 2KMs from DC"
                                        ></i>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-column justify-content-between align-items-center" style={{ width: "30%" }}>
                                <img src={require("../../../assets/images/return-truck.png")} style={{ width: "35px", height: "35px" }} />
                                <input type="checkbox" style={{ width: "15px", height: "15px", marginBottom: "10px" }} checked={this.state.checkboxStates.isReturnPlantSelected} onChange={() => this.handleCheckboxChange('isReturnPlantSelected')} />
                            </div>
                        </div>
                        <div className="d-flex ml-5p mr-5p" style={{ backgroundColor: "#fff", width: "17%", padding: "5px", borderRadius: "5px" }}>
                            <div className="d-flex" style={{ width: "70%", borderRight: "1px dashed lightgrey", paddingLeft: "5px", paddingRight: "5px" }}>
                                <div className="d-flex flex-column justify-content-between" style={{width: "85%", cursor: "pointer"}} onClick={() => {this.onClickingCounter("empty_outside_dc")}}>
                                  <p style={{ fontSize: "17px", margin: "0px", fontWeight: "bold", color: "#944E63" }}>{t('empty_outside_dc')}</p>
                                  <CountUp end={this.state.emptyOutsidePlant.length} duration={2.5} style={{ fontWeight: "bold", color: "#000", fontSize: "23px", marginTop: "10px" }} />
                                </div>
                                <div className="d-flex justify-content-center align-items-start" style={{ width: "15%"}}>
                                    <div>
                                        <i
                                            class="fa fa-info-circle info-icon info-icon-right"
                                            aria-hidden="true"
                                            data-tooltip="Trucks that completed their shipments / Empty Trucks and are outside of Plant (<= 2KMs)"
                                        ></i>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-column justify-content-between align-items-center" style={{ width: "30%" }}>
                                <img src={require("../../../assets/images/empty-truck.png")} style={{ width: "35px", height: "35px" }} />
                                <input type="checkbox" style={{ width: "15px", height: "15px", marginBottom: "10px" }} checked={this.state.checkboxStates.isEmptyOutsidePlantSelected} onChange={() => this.handleCheckboxChange('isEmptyOutsidePlantSelected')} />
                            </div>
                        </div>
                        {/* <div className="d-flex ml-5p mr-5p" style={{ backgroundColor: "#fff", width: "14%", padding: "5px", borderRadius: "5px" }}>
                            <div className="d-flex flex-column justify-content-between" style={{ width: "60%", borderRight: "1px dashed lightgrey", paddingLeft: "5px", paddingRight: "5px" }}>
                                <p style={{ fontSize: "17px", margin: "0px", fontWeight: "bold", color: "#474F7A" }}>Unplanned</p>
                                <CountUp end={this.state.unplannedData.length} duration={2.5} style={{ fontWeight: "bold", color: "#000", fontSize: "23px", marginTop: "10px" }} />
                            </div>
                            <div className="d-flex flex-column justify-content-between align-items-center" style={{ width: "40%" }}>
                                <img src={require("../../../assets/images/unplanned-truck.png")} style={{ width: "35px", height: "35px" }} />
                                <input type="checkbox" style={{ width: "15px", height: "15px", marginBottom: "10px" }} checked={this.state.checkboxStates.isUnplannedSelected} onChange={() => this.handleCheckboxChange('isUnplannedSelected')} />
                            </div>
                        </div> */}
                    </div>
                </div>
                {/* <div className="row mb-10p">
                    <div className="col-12 d-flex flex-row">
                        <div style={{
                            width: "26%", height: "90px", padding: "5px", backgroundColor: "#AA4BB4", borderRadius: "10px", marginRight: "8px", cursor: "pointer",
                            // transform: `translateZ(${this.state.countersSelectedArray.isTotalTrucksSelected ? '30px' : '0'}) scale(${this.state.countersSelectedArray.isTotalTrucksSelected ? '1.1' : '1'})`, // Rotate, tilt, elevate, and scale
                            // transition: "transform 0.5s ease",
                            // boxShadow: this.state.countersSelectedArray.isTotalTrucksSelected ? "0px 0px 20px rgba(128, 0, 128, 0.7)" : "none"
                        }}
                            className="d-flex flex-column justify-content-start"
                        // onClick={this.onClickingTotalTrucks
                        >
                            <p style={{ fontSize: "15px", color: "#f9f9f9", fontWeight: "bold", textAlign: "center", marginBottom: "0px" }}>Total Trucks</p>
                            <div className="d-flex justify-content-center">
                                <CountUp end={this.state.activeGpsData.length + this.state.inActiveGpsData.length} duration={2.5} style={{ fontWeight: "bold", marginTop: "0px", color: "#f9f9f9", fontSize: "23px" }} />
                            </div>
                            <div className="d-flex flex-row justify-content-center align-items-center">
                                <CountUp end={this.state.activeGpsData.length} duration={2.5} style={{ fontWeight: "bold", margin: "0px", color: "#f9f9f9", fontSize: "17px" }} />
                                <p style={{ fontSize: "14px", color: "#f9f9f9", fontWeight: "bold", marginBottom: "0px" }}>&nbsp;- Active &nbsp;&nbsp;{"|"}&nbsp;&nbsp;</p>
                                <CountUp end={this.state.inActiveGpsData.length} duration={2.5} style={{ fontWeight: "bold", margin: "0px", color: "#f9f9f9", fontSize: "17px" }} />
                                <p style={{ fontSize: "14px", color: "#f9f9f9", fontWeight: "bold", marginBottom: "0px" }}>&nbsp;- Inactive </p>
                            </div>
                        </div>
                        <div style={{
                            width: "13%", height: "90px", padding: "10px", backgroundColor: "#132EDE", borderRadius: "10px", marginLeft: "8px", marginRight: "8px", cursor: "pointer",
                        }}
                            // onClick={this.onClickingInsidePlant}
                            className="d-flex flex-column justify-content-between"
                        >
                            <p style={{ fontSize: "15px", color: "#f9f9f9", fontWeight: "bold", textAlign: "center", marginBottom: "0px" }}>Inside DC</p>
                            <div className="d-flex flex-row justify-content-center">
                                <CountUp end={this.state.insidePlantCount.length} duration={2.5} style={{ fontWeight: "bold", color: "#f9f9f9", fontSize: "25px" }} />
                            </div>
                        </div>
                        <div style={{
                            width: "14%", height: "90px", padding: "10px", backgroundColor: "#20C997", borderRadius: "10px", marginLeft: "8px", marginRight: "8px", cursor: "pointer",
                        }}
                            // onClick={this.onClickingIntransit}
                            className="d-flex flex-column justify-content-between"
                        >
                            <p style={{ fontSize: "14px", color: "#f9f9f9", fontWeight: "bold", textAlign: "center", marginBottom: "0px" }}>In-Transit to Customer</p>
                            <div className="d-flex flex-row justify-content-center">
                                <CountUp end={this.state.inTransitCount.length} duration={2.5} style={{ fontWeight: "bold", color: "#f9f9f9", fontSize: "25px" }} />
                            </div>
                        </div>
                        <div style={{
                            width: "13%", height: "90px", padding: "10px", backgroundColor: "#FCC419E3", borderRadius: "10px", marginLeft: "8px", marginRight: "8px", cursor: "pointer"
                        }}
                            // onClick={this.onClickingNearDealer}
                            className="d-flex flex-column justify-content-between"
                        >
                            <p style={{ fontSize: "15px", color: "#f9f9f9", fontWeight: "bold", textAlign: "center", marginBottom: "0px" }}>Near Customer</p>
                            <div className="d-flex flex-row justify-content-center align-items-end">
                                <CountUp end={this.state.nearDealerCount.length} duration={2.5} style={{ fontWeight: "bold", color: "#f9f9f9", fontSize: "25px" }} />
                            </div>
                        </div>
                        <div style={{
                            width: "13%", height: "90px", padding: "10px", backgroundColor: "#40A2E3", borderRadius: "10px", marginLeft: "8px", marginRight: "8px", cursor: "pointer",
                        }}
                            // onClick={this.onClickingReturnPlant}
                            className="d-flex flex-column justify-content-between"
                        >
                            <p style={{ fontSize: "15px", color: "#f9f9f9", fontWeight: "bold", textAlign: "center", marginBottom: "0px" }}>Return to DC</p>
                            <div className="d-flex flex-row justify-content-center">
                                <CountUp end={this.state.returnPlantCount.length} duration={2.5} style={{ fontWeight: "bold", color: "#f9f9f9", fontSize: "25px" }} />
                            </div>
                        </div>
                        <div style={{
                            width: "13%", height: "90px", padding: "10px", backgroundColor: "#FA5252", borderRadius: "10px", marginLeft: "8px", marginRight: "8px", cursor: "pointer"
                        }}
                            // onClick={this.onClickingEmptyOutsidePlant}
                            className="d-flex flex-column justify-content-between"
                        >
                            <p style={{ fontSize: "15px", color: "#f9f9f9", fontWeight: "bold", textAlign: "center", marginBottom: "0px" }}>Empty Outside DC</p>
                            <div className="d-flex flex-row justify-content-center">
                                <CountUp end={this.state.emptyOutsidePlant.length} duration={2.5} style={{ fontWeight: "bold", color: "#f9f9f9", fontSize: "25px" }} />
                            </div>
                        </div>
                        {/* <div style={{
                            width: "13%", height: "90px", padding: "10px", paddingLeft: "10px", paddingRight: "10px", backgroundColor: "#944E63", borderRadius: "10px", marginLeft: "8px", marginRight: "8px", cursor: "pointer",
                        }}
                        // onClick={this.onClickingInactive}
                        >
                            <p style={{ fontSize: "15px", color: "#f9f9f9", fontWeight: "bold", textAlign: "center" }}>Inactive GPS</p>
                            <div className="d-flex flex-row justify-content-center">
                                <CountUp end={this.state.inActiveData.length} duration={2.5} style={{ fontWeight: "bold", color: "#f9f9f9", fontSize: "25px" }} />
                            </div>
                        </div>
                        <div style={{
                            width: "13%", height: "90px", padding: "10px", backgroundColor: "#474F7A", borderRadius: "10px", marginLeft: "8px", cursor: "pointer",
                        }}
                            // onClick={this.onClickingUnplanned}
                            className="d-flex flex-column justify-content-between"
                        >
                            <p style={{ fontSize: "15px", color: "#f9f9f9", fontWeight: "bold", textAlign: "center", marginBottom: "0px" }}>Unplanned</p>
                            <div className="d-flex flex-row justify-content-center">
                                <CountUp end={this.state.unplannedData.length} duration={2.5} style={{ fontWeight: "bold", color: "#f9f9f9", fontSize: "25px" }} />
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="row" style={{ height: "450px" }}>
                    <div className="col-12 d-flex flex-row" style={{ padding: "20px", paddingTop: "10px", borderRadius: "20px" }}>
                        <div id="myGrid1" className={"ag-theme-balham"} style={{ width: "35%", marginRight: "10px" }}>
                            <div style={{ height: "500px" }}>
                                <AgGridReact
                                    rowData={aggriddata}
                                    columnDefs={vehicleStatusColDefs}
                                    gridOptions={{ context: { componentParent: this } }}
                                    // onGridReady={this.onGridReady}
                                    onGridState={this.onGridState}
                                    statusBar={this.state.statusBar}
                                    sideBar={this.state.sideBar}
                                    enablePivot={true}
                                    defaultColDef={this.state.defaultColDef}
                                    frameworkComponents={this.state.frameworkComponents}
                                    paginationPageSize={this.state.paginationPageSize}
                                    pagination={true}
                                    enableRangeSelection={true}
                                    // onCellClicked={this.onCellClickedGrid.bind(this)}
                                    masterDetail={true}
                                    detailCellRendererParams={
                                        this.state.detailCellRendererParams
                                    }
                                    rowClassRules={this.state.rowClassRules}
                                />
                            </div>
                        </div>
                        <div style={{ width: "65%", marginLeft: "10px" }}>
                            <div style={{ backgroundColor: "#ededed", display: "flex", alignItems: "center", justifyContent: "end", flexWrap: "wrap", padding: "5px" }}>
                                {/* <div className="d-flex align-items-center ml-10p mr-10p">
                                    <img src={require("../../../assets/images/truck-cluster.png")} style={{ width: "35px", height: "35px" }} />
                                    <span style={{ fontSize: "15px", fontWeight: "bold", color: "#000", margin: "0px" }}>&nbsp;- Truck Cluster</span>
                                </div>
                                <div className="d-flex align-items-center ml-10p mr-10p">
                                    <img src={require("../../../assets/images/plant-cluster.png")} style={{ width: "35px", height: "35px" }} />
                                    <span style={{ fontSize: "15px", fontWeight: "bold", color: "#000", margin: "0px" }}>&nbsp;- DC Cluster</span>
                                </div>
                                <div className="d-flex align-items-center ml-10p mr-10p">
                                    <img src={require("../../../assets/images/customer-cluster.png")} style={{ width: "35px", height: "35px" }} />
                                    <span style={{ fontSize: "15px", fontWeight: "bold", color: "#000", margin: "0px" }}>&nbsp;- Customer Cluster</span>
                                </div> */}
                                <div className="d-flex align-items-center ml-10p mr-10p">
                                    <input type="checkbox" style={{ width: "15px", height: "15px", marginRight: "5px" }} checked={this.state.checkboxStates.isPlantChecked} onChange={() => this.handleCheckboxChange('isPlantChecked')} />
                                    <img src={require("../../../assets/images/plant-location.png")} style={{ width: "25px", height: "25px" }} />
                                    <span style={{ fontSize: "15px", fontWeight: "bold", color: "#000", margin: "0px" }}>&nbsp;- {t('dc_location')}</span>
                                </div>
                                <div className="d-flex align-items-center ml-10p mr-10p">
                                    <input type="checkbox" style={{ width: "15px", height: "15px", marginRight: "5px" }} checked={this.state.checkboxStates.isCustomerChecked} onChange={() => this.handleCheckboxChange('isCustomerChecked')} />
                                    <img src={require("../../../assets/images/customer_pin_38.png")} style={{ width: "25px", height: "25px" }} />
                                    <p style={{ fontSize: "15px", fontWeight: "bold", color: "#000", margin: "0px" }}>&nbsp;- {t('customer_location')}</p>
                                </div>
                            </div>
                            <VehicleStatusMap data={viewMapData} />
                        </div>
                    </div>
                </div>
            </div>
            </div>
        )
    }
}
export default withTranslation()(VehicleStatusDashboard)