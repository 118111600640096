import React,{Component} from 'react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
import 'ag-grid-enterprise';
import { withTranslation } from 'react-i18next';
import Select from 'react-select';
import redirectURL from '../../redirectURL';
import GridButton from '../gridButton';

 class GeofenceList extends Component{
    constructor(props){
        super(props);
    this.state = {
        modules : AllModules,
        defaultColDef: {
            sortable: true,
            filter: true,
            editable: false,
            resizable:true,
            minWidth:100,
            initialWidth:200,
            wrapHeaderText: true,
            autoHeaderHeight: true,
        },
            frameworkComponents:{
                GridButton:GridButton,
            },
            rowData:[],
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
			detailCellRendererParams:{},
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:100,
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            animateRows: true,
            locationNames:[],
            location:'',
            rowData:[],
        }
    }

    componentDidMount(){
        this.getRouteRestrictionsGeo()
    }

    getRouteRestrictionsGeo = () =>{
        redirectURL.post('/geofence/getRouteRestrictionsGeo')
        .then(res=>{
            console.log(res.data)
            this.setState({
                rowData:res.data
            })
        })
    }

    editrouterestriction = (param) =>{
        window.location.href='/editgeofence?'+param.data.restricted_area;
    }

    render(){
        const {t} = this.props
    //    var colsDefs=[
    //     {
    //         headerName:"Location Code",
    //         field:"location_code",
    //         width:"120"
    //     },
    //     {
    //         headerName:"Location Name",
    //         field:"location_name",
    //         width:"120"
    //     },
    //     {
    //         headerName:"Restriction Area",
    //         field:"restricted_area",
    //         width:"120"
    //     },
    //     {
    //         headerName:"Vehicle List",
    //         field:"vehicle_list",
    //         width:"120"
    //     },
    //     {
    //         headerName:"From Time",
    //         field:"from_time",
    //         width:"120"
    //     },
    //     {
    //         headerName:"To Time",
    //         field:"to_time",
    //         width:"120"
    //     },
    //     {
    //         headerName:"Actions",
    //         field:"cluster",
    //         width:"100",
    //         pinned:'left',
    //         filter:false,
    //         sortable:false,
    //         params:{buttonName:'Edit',
    //                 onClickFunction:this.editrouterestriction},
    //         cellRendererSelector:function(params){
                
    //             var rendComponent = {
    //                 component: "GridButton"
    //             };
    //             return rendComponent
            
    //         },
    //     },
    //    ]
    var colsDefs = [
        {
            headerName: t('location_code'),
            field: "location_code",
            width: "120"
        },
        {
            headerName: t('location_name'),
            field: "location_name",
            width: "120"
        },
        {
            headerName: t('restricted_area'),
            field: "restricted_area",
            width: "120"
        },
        {
            headerName: t('vehicle_list'),
            field: "vehicle_list",
            width: "120"
        },
        {
            headerName: t('from_time'),
            field: "from_time",
            width: "120"
        },
        {
            headerName: t('to_time'),
            field: "to_time",
            width: "120"
        },
        {
            headerName: t('actions'),
            field: "cluster",
            width: "100",
            pinned: 'left',
            filter: false,
            sortable: false,
            params: {
                buttonName: t('edit'),
                onClickFunction: this.editrouterestriction
            },
            cellRendererSelector: function (params) {
                var rendComponent = {
                    component: "GridButton"
                };
                return rendComponent;
            }
        }
    ];
    
        return(
            <>
            <div className="row mb-5p ">
                    <div className="col-sm-12 pt-5p">
                        <h5 className='fbold '>
                            <span> {t('route_restrictions')}</span>
                            <div className='float-right col-sm-3' style={{marginTop:'30px'}}>
                                
                                <span className={"float-right "}>
                                <a href="/addGeoFence"   className="btn btn-warning f12 mr-10p"><i className="icon-plus"></i>{t('add_route_restriction')}</a>
                                </span>
                            </div>
                        </h5>
                        {/* <div className="col-md-2 col-sm-12">
                            <div className="form-group ">
                                <label className="col-form-label f12">{Constant.MASTER_VEHICLE_DATA_CONFIGURATION_BOUND_LOCATION}</label>
                                <Select
                                placeholder={"Select"}
                                onChange={this.locationChanged}
                                name="location"
                                value={this.state.location}
                                options={this.state.locationNames} />
                            </div>
                        </div> */}
                    </div>
                </div>
            <div className="col-sm-12 d-inline">
                            <div id="myGrid" style={{width:"100%",height:"70vh",marginTop:"2em"}} className="ag-theme-balham">
                            <AgGridReact
                                // modules={this.state.modules} 
                                rowData={this.state.rowData}
                                columnDefs={colsDefs}
                                gridOptions={{context:{componentParent:this}}}
                                defaultColDef={this.state.defaultColDef}
                                frameworkComponents={this.state.frameworkComponents}
                                paginationPageSize={this.state.paginationPageSize}
                                pagination={true}
                                enableRangeSelection = {true}
                                enableCellContextMenu={true}
                                statusBar={this.state.statusBar}
                                sideBar = {this.state.sideBar}
                                // enablePivot = {true}
                            />
                            </div>
                        </div> 
            
            </>
        )
    }

}

export default withTranslation()(GeofenceList)