import React,{Component} from "react";
import {AllModules} from '@ag-grid-enterprise/all-modules';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
import 'ag-grid-enterprise';
import Constant from "../common/constants";
import Modal from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import { withTranslation } from 'react-i18next';

import redirectURL from '../redirectURL';
import Select from 'react-select';
import SweetAlert from 'react-bootstrap-sweetalert';
import GridButton from './gridButton';
import getToolConfiguration from "./commonFunctions";
import CSVFileValidator from 'csv-file-validator';


 class TransporterOperations extends Component{
    constructor(props){
        super(props);
        this.state={
            modules : AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
                resizable:true,
                minWidth:100,
                initialWidth: 200,
                wrapHeaderText: true,
                autoHeaderHeight: true,
            },
            frameworkComponents:{
                GridButton:GridButton,
            },
            rowData:[],
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
			detailCellRendererParams:{},
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:100,
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            animateRows: true,
            openTransporterDetailsModel:false,            
            show:false,
            basicTitle:"",
            basicType:"default",
            data_type:'',
            location:this.props.plantLocation,
            vehicle_type:{"label":"Select Vehicle Type",value:""},
            state_name:{"label":"Select State",value:""},
            rowId:'',
            locationNames:[],
            vehicle_types:[],
            state_names:[],
            transporter_code:{"label":"Select Transporter Code",value:""},
            transporterList:[],
            showSlideBlock:"",
            overlayBlock:"show-n",
            csvfiledata:[],
            bulkFileName:''
        }
        
    }

    async componentDidMount(){
        
        var curl = window.location.search;
        var ul= curl.split("?");
        // console.log("ul ",ul)
        // let url_type = window.location.href
        let url_type = ul[parseInt(ul.length)-1];
        // console.log("url_type ", url_type)
        // let path_type = url_type.split('/')
        let path_type = url_type
        let data_type
        // let path = path_type[path_type.length-1]
        let path =url_type

        if (path_type == 'inbound'){
            data_type = 1
        }else{
            data_type = 2
        }
        await this.setState({data_type : data_type})
        let params = {
            data_type : data_type
        }
        
        await getToolConfiguration(params)
        await this.getVehiclesData(params)
        await this.getStatesData(params)
        await this.getTransporterDetails(params)
        await this.getTransporterMasterDetails(params)
    }

    getVehiclesData = (params) =>{
        redirectURL.post('master/getVehiclesData',params)
        .then(response =>{
            if(response.data.status == 'success'){

            this.setState({
                vehicle_types:response.data.vehiclesData
            })
        }
        })
    }

    getStatesData = (params) =>{
        redirectURL.post('master/getStatesData',params)
        .then(response =>{
            this.setState({
                state_names:response.data
            })
        })
    }


    openTransporterDetailsModel = () =>{
        this.setState(prevState =>({
            openTransporterDetailsModel :!prevState.openTransporterDetailsModel
        }))
    }

    handelTransporterDetailsModelCancel = ()=>{
        this.setState(prevState =>({
            openTransporterDetailsModel :!prevState.openTransporterDetailsModel,
            vehicle_type:{"label":"Select Vehicle Type",value:""},
            state_name:{"label":"Select State",value:""},
            rowId:'',
            transporter_code:{"label":"Select Transporter Code",value:""}
            
        }))

    }

    closeAlert = async() => {
        await this.setState({
            show: false
        });
	}
    
    transporterDetailsInputFields = (e) =>{
        let name = e.target.name
        let value = e.target.value

        if(name == "transporter_code"){
            value = value.toUpperCase()
        }
        this.setState({ [name] : value })
    }

    locationChanged = (location) =>{
        this.setState({
            location : location
        })
    }

    getTransporterMasterDetails = (params) =>{
        let transporterList = []
        redirectURL.post('master/getTransporterMasterDetails',params)
        .then(async response =>{
            if(response.data.status == 'success'){
                let transporterMaster = response.data.transporterDetailsData
                if(transporterMaster.length > 0){
                    transporterMaster.map(item =>{
                        transporterList.push({ value : item.transporter_code,
                            label : item.transporter_name })
                    })
                    await this.setState({
                        transporterList : transporterList,
                    })
                }
          }
        })
    }

    saveTransporterDetails = (params)=>{
        let data_type = this.state.data_type
        redirectURL.post('master/saveTransporterDetails',params)
        .then(response =>{
            if(response.data.status == 'success'){
                let param = {
                    data_type : data_type
                }
                this.getTransporterDetails(param)
                this.setState({
                        openTransporterDetailsModel:false,
                        vehicle_type:{"label":"Select Vehicle Type",value:""},
                        state_name:{"label":"Select State",value:""},
                        rowId:'',
                        transporter_code:{"label":"Select Transporter Code",value:""},
                    })
            }else{
                if(response.data.status == 'failed'){
                    this.setState({
                        show:true,
                        basicTitle:response.data.message,
                        basicType:"danger"
                    })
                }else{
                this.setState({
                    show:true,
                    basicTitle:"Failed to save the data",
                    basicType:"danger"
                })
                }
            }
        })
    }

    updateTransporterDetails = (params)=>{
        let data_type = this.state.data_type
        redirectURL.post('master/updateTransporterDetails',params)
        .then(response =>{
            if(response.data.status == 'success'){
                let param = {
                    data_type : data_type
                }
                this.getTransporterDetails(param)
                this.setState({
                        openTransporterDetailsModel:false,
                        vehicle_type:{"label":"Select Vehicle Type",value:""},
                        state_name:{"label":"Select State",value:""},
                        rowId:'',
                        transporter_code:{"label":"Select Transporter Code",value:""},
                    })
            }else{
                if(response.data.status == 'failed'){
                    this.setState({
                        show:true,
                        basicTitle:response.data.message,
                        basicType:"danger"
                    })
                }else{
                this.setState({
                    show:true,
                    basicTitle:"Failed to save the data",
                    basicType:"danger"
                })
                }
            }
        })
    }


    formTransporterDetails = (e)=>{
        e.preventDefault();
        let vehicle_type = this.state.vehicle_type;
        let state_name = this.state.state_name;
        let data_type = this.state.data_type;
        let location = this.state.location;
        let rowId = this.state.rowId;
        let transporter_code = this.state.transporter_code

        let params = {
            vehicle_type : vehicle_type.value,
            state : state_name.value,
            data_type : data_type,
            location_code : location.value,
            location_name : location.label,
            transporter_code : transporter_code.value,
            transporter_name : transporter_code.label
        }

        if (rowId != ''){
            params.transporter_id = rowId

            this.updateTransporterDetails(params)
        }
        else{

            if(location.value != null){
                if(state_name.value != ''){
                   if(vehicle_type.value != ''){
                        if(transporter_code.value != ''){

                            this.saveTransporterDetails(params)

                        }else{
                            this.setState({
                                show:true,
                                basicTitle:"Please select Transporter Code",
                                basicType:"danger"
                            })
                        }
                    }else{
                        this.setState({
                            show:true,
                            basicTitle:"Please select vehicle type",
                            basicType:"danger"
                        })
                    }
                }else{
                    this.setState({
                        show:true,
                        basicTitle:"Please select State",
                        basicType:"danger"
                    })
                }
                
            }else{
                this.setState({
                    show:true,
                    basicTitle:"Please add "+ Constant.MASTER_VEHICLE_DATA_CONFIGURATION_BOUND_LOCATION + " First",
                    basicType:"danger"
                })
            }
        }
    }

    editTransporterDetails = async(propdata) =>{
        let data = propdata.data;
        var vehicle_type = "";
        if(data.vehicle_type !=undefined && data.vehicle_type !="")
        {
            var vehicle_types = this.state.vehicle_types;
            var vety = vehicle_types.filter((vt) => vt.vehicle_type == data.vehicle_type);
            if(vety.length > 0)
            {
                vehicle_type = {"label":vety[0].vehicle_type,"value":vety[0].vehicle_type}
            }
        }
        var state_data = "";
        if(data.state !=undefined && data.state !="")
        {
            var state_names = this.state.state_names;
            var sns = state_names.filter((vt) => vt.state == data.state);
            if(sns.length > 0)
            {
                state_data = {"label":sns[0].state,"value":sns[0].state}
            }
        }
        
        await this.setState({
            "vehicle_type" : vehicle_type,
            "state_name" : state_data,
            "rowId" : data.transporter_id,
            "transporter_code" : {"label":data.transporter_code,"value":data.transporter_name}
        })
        this.openTransporterDetailsModel()
    }
    onChangeVehicleType(vehicle_type){
		this.setState(
            { vehicle_type },
            () => console.log(`Option selected:`, this.state.vehicle_type)
        );
	}
    onChangeStateNames(state_name){
		this.setState(
            { state_name },
            () => console.log(`Option selected:`, this.state.state_name)
        );
	}
    onChangeTransporter(transporter_code){
		this.setState(
            { transporter_code },
            () => console.log(`Option selected:`, this.state.transporter_code)
        );
	}
    getVehicleTypes =()=> {
		let items = [];    
		try{
			if(this.state.vehicle_types.length > 0)
			{
				this.state.vehicle_types.map((item) =>{
					items.push({"value":item.vehicle_type,"label":item.vehicle_type})
				})
			}
		}
		catch(e){
		}		
		return items;
	}
    getStateNames =()=> {
		let items = [];    
		try{
			if(this.state.state_names.length > 0)
			{
				this.state.state_names.map((item) =>{
					items.push({"value":item.state,"label":item.state})
				})
			}
		}
		catch(e){
		}		
		return items;
	}
    // getTransporterNames =()=> {
	// 	let items = [];    
	// 	try{
	// 		if(this.state.transporterList.length > 0)
	// 		{
	// 			this.state.transporterList.map((item) =>{
	// 				items.push({"value":item.transporter_code,"label":item.transporter_code})
	// 			})
	// 		}
	// 	}
	// 	catch(e){
	// 	}		
	// 	return items;
	// }

    getTransporterDetails = (params) =>{
        redirectURL.post('master/getTransporterDetails',params)
        .then(response =>{
            if(response.data.status == 'success'){
            this.setState({
                rowData:response.data.transporterDetailsData
            })
          }
        })
    }

    deleteTransporterDetails = (propData) =>{
        let data = propData.data
        let data_type = this.state.data_type
        
        let params = {
            delete_id : data._id
        }
        redirectURL.post('master/deleteTransporterDetails',params)
        .then(response =>{
            if(response.data.status == 'success'){
                let param = {
                    data_type : data_type
                }
                this.getTransporterDetails(param)
          }else{
            this.setState({
                show:true,
                basicTitle:"Failed to delete the data",
                basicType:"danger"
            })
          }
        })
    }

    openUploadOptionForTransporters = () =>{
        document.getElementById("bulkUploadForm").reset()
        document.getElementById("inValidDataInfo").innerHTML = "";
        this.setState({
            showSlideBlock:"slide25",
            overlayBlock:"show-m",
          })
    }

    hideSlideBlock = () =>{
        document.getElementById("bulkUploadForm").reset()
        document.getElementById("inValidDataInfo").innerHTML = "";
        this.setState({
            overlayBlock:"show-n",
            showSlideBlock:"",
        })
      }

      formCSVUpload = (event) =>
      {
        let location = this.state.location
        let data_type = this.state.data_type
        event.preventDefault();
        var csvfiledata = this.state.csvfiledata;
        var p = {
          filedata: csvfiledata,
          data_type:data_type,
          location_code : location.value,
          location_name : location.label,
        }
        redirectURL.post("/master/uploadTransportersData",p)
        .then(async(resp) => {
          
          if(resp.data.status == 'success'){
            await this.setState({
                show:true,
                basicTitle:resp.data.message,
                basicType:"success",
                overlayBlock:"show-n",
                showSlideBlock:"",
            })
            window.location.reload();
            
          }else{
            this.setState({
                show:true,
                basicTitle:resp.data.message,
                basicType:"danger"
            })
          }
        })
      }


      changeCSVFileHandler = async (e) => {
        const config = {
            headers: [
                // { 
                //     name: 'Location Code',
                //     inputName: 'location_code',
                //     required: true,
                //     requiredError: function (headerName, rowNumber, columnNumber) {
                //         return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                //     }
                // },
                // { 
                //     name: 'Location Name',
                //     inputName: 'location_name',
                //     required: true,
                //     requiredError: function (headerName, rowNumber, columnNumber) {
                //         return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                //     }
                // },
                { 
                    name: 'Vehicle Type',
                    inputName: 'vehicle_type',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                { 
                    name: 'Transporter Code',
                    inputName: 'transporter_code',
                    required: true,
                    unique: true,
                    uniqueError: function (headerName) {
                        return `${headerName} is not unique`
                    },
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                { 
                    name: 'Transporter Name',
                    inputName: 'transporter_name',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                 },
                { 
                    name: 'State',
                    inputName: 'state',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                
            ]
        }
        var data = CSVFileValidator(e.target.files[0], config)
        .then((csvData) => {

            document.getElementById("inValidDataInfo").innerHTML = "";
            if(csvData.inValidData.length >0){
                document.getElementById("bulkUploadBtn").type = "button"
                document.getElementById("bulkUploadBtn").classList.remove("btn-danger");
                document.getElementById("bulkUploadBtn").classList.add("btn-secondary");
                let invalidData = csvData.inValidData;
                let element = document.getElementById("inValidDataInfo")
                invalidData.map(item =>{
                    let row,column
                    if(item.rowIndex == undefined){
                        row = 'NA'
                    }else{
                        row = item.rowIndex
                    }
                    if(item.columnIndex == undefined){
                        column = 'NA'
                    }else{
                        column = item.columnIndex
                    }
                    
                    element.innerHTML += "Column : "+column+","+" Row : " + row +"--"+ item.message +"<br></br>"
                })
                 
            }else{
                document.getElementById("bulkUploadBtn").type = "submit"
                document.getElementById("bulkUploadBtn").classList.remove("btn-secondary");
                document.getElementById("bulkUploadBtn").classList.add("btn-danger");
                this.setState({
                    csvfiledata:csvData.data
                });
            }
        })
        .catch(err => {})
    }

    render(){
        const {t} = this.props
        var colsDefs = [
            {
                headerName:Constant.FORM_VEHICLE_TYPES,
                field:"vehicle_type",
                width:"120"
            },
            {
                headerName:"Transporter Code",
                field:"transporter_code",
                
            },
            {
                headerName:Constant.DEALER_FORM_STATE,
                field:"state",
                width:"130"
            },
            {
                headerName:"Actions",
                field:"cluster",
                width:"80",
                pinned:'left',
                params:{buttonName:t('edit'),
                        onClickFunction:this.editTransporterDetails},
                cellRendererSelector:function(params){
                    
                    var rendComponent = {
                        component: "GridButton"
                    };
                    return rendComponent
                
                },
            },
            {
                headerName:"",
                field:"cluster",
                width:"80",
                pinned:'left',
                params:{buttonName: t('delete'),iconName:"fa fa-trash",
                        onClickFunction:this.deleteTransporterDetails},
                cellRendererSelector:function(params){
                    
                    var rendComponent = {
                        component: "GridButton"
                    };
                    return rendComponent
                
                },
            },
        ]

        let toolConfig = JSON.parse(localStorage.getItem('tlcfg'))
        let data = this.state.rowData
        let locValue = this.state.location.value
         
        let filteredData = data.filter(item => item.location_code == locValue)
        
        if(toolConfig !=undefined && toolConfig !="")
        {
            if(toolConfig.length > 0)
            {
                
            }
        }

        var templatePath = require('../../assets/json/Transporters_input_data_template.csv');

        return(
            <>
            <SweetAlert
                show={this.state.show}
                type={this.state.basicType}
                title={this.state.basicTitle}
                onConfirm={this.closeAlert}>
            </SweetAlert>
                
                <div class="row mb-20p pt-20p">
                <div className="col-sm-12">
                        <h5 className='fbold  d-flex justify-content-between'>
                            <span>Transporter Operations</span>
                                <div className='float-right col-sm-6'>
                                    <a href="javascript:;" onClick={this.openTransporterDetailsModel} className="f12 btn btn-default float-right themefont fbold mr-10p mb-10p">{Constant.TRANSPORTER_OPERATIONS_SCREEN_BUTTON_NAME}</a>
                                    <span className="float-right">
                                    <a href="javascript:;"  onClick={this.openUploadOptionForTransporters} className="btn btn-warning f12 mr-10p">{Constant.TRANSPORTER_OPERATIONS_SCREEN_BULK_BUTTON_NAME}</a>
                                    </span>
                                </div> 

                        </h5>
                   
                        <div id="myGrid" style={{width:"100%",height:"70vh"}} className="col-sm-12 ag-theme-balham dropdown">
                        <AgGridReact
                        modules={this.state.modules} 
                            rowData={filteredData}
                            columnDefs={colsDefs}
                            gridOptions={{context:{componentParent:this}}}
                            defaultColDef={this.state.defaultColDef}
                            frameworkComponents={this.state.frameworkComponents}
                            paginationPageSize={this.state.paginationPageSize}
                            pagination={true}
                        />
                        </div>
                    </div>
                </div>

                <Modal closeOnOverlayClick={false} open={this.state.openTransporterDetailsModel} onClose={this.handelTransporterDetailsModelCancel} 
                    classNames={{modal: 'pickupdrop-modelClass'}} >
                        <div className="col-sm-12">
                        <div>
                            <h4>Transaporter Operations</h4>
                        </div>
                    <form className="theme-form" onSubmit={this.formTransporterDetails}>

                        <div className="row number">
                        <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{Constant.MASTER_VEHICLE_DATA_CONFIGURATION_BOUND_LOCATION}</label>
                                    <Select
                                        isDisabled
                                        name="location"
                                        value={this.state.location}
                                         />
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{Constant.FORM_VEHICLE_TYPES} </label>
                                    <Select 
                                    placeholder={"Select "+Constant.FORM_VEHICLE_TYPES}
                                    closeMenuOnSelect={true}
                                    onChange={this.onChangeVehicleType.bind(this)} 
                                    className={"border-radius-0"}
                                    style={{borderRadius:"0px"}}
                                    value={this.state.vehicle_type}
                                    options={this.getVehicleTypes()} />  
                                </div>
                            </div>


                            {/* <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{Constant.TRANSPORTER_CODE}</label>
                                    <input type="text" 
                                        className= "forminp form-control"
                                        required 
                                        name="transporter_code" 
                                        value={this.state.transporter_code}
                                        onChange={this.transporterDetailsInputFields} 
                                        placeholder="" autoComplete="off" />
                                                                          
                                </div>
                            </div> */}
                            <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{Constant.TRANSPORTER_CODE} </label>
                                    <Select 
                                    placeholder={"Select "+Constant.TRANSPORTER_CODE }
                                    closeMenuOnSelect={true}
                                    onChange={this.onChangeTransporter.bind(this)} 
                                    className={"border-radius-0"}
                                    style={{borderRadius:"0px"}}
                                    value={this.state.transporter_code}
                                    options={this.state.transporterList} />  
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{Constant.TRANSPORTER_FORM_STATE} </label>
                                    <Select 
                                    placeholder={"Select "+Constant.TRANSPORTER_FORM_STATE}
                                    closeMenuOnSelect={true}
                                    onChange={this.onChangeStateNames.bind(this)} 
                                    className={"border-radius-0"}
                                    style={{borderRadius:"0px"}}
                                    value={this.state.state_name}
                                    options={this.getStateNames()} />  
                                </div>
                            </div>

                            

                        </div>
                        <div className="form-group">
                            <button type="submit" className="btn tbtn float-right save-btn" id="saveGeo">SAVE</button>
                            <button type='button' className="btn tbtn float-right cancel-btn" onClick={this.handelTransporterDetailsModelCancel}>CANCEL</button>	
                        </div>
                    </form>
                </div>
                    </Modal>

                    <div className={"overlay-block "+(this.state.overlayBlock)} onClick={this.hideSlideBlock}></div>
                    <div className={"sliderBlock2 "+(this.state.showSlideBlock)}>
                    <div className="row">   
                    <div className="col-sm-12">
                    <form id="bulkUploadForm" onSubmit={this.formCSVUpload}>
                    <div className="row p-20p">
                        <div className="form-group col-sm-12">
                            <a className="btn btn-warning" href={templatePath} target="_blank">Download Sample Template</a>                            
                        </div>
                        <div className="form-group col-sm-12 mb-20p">                            
                            <input type="file" name="csvfile" id="csvfile" className="form-control" onChange={this.changeCSVFileHandler} required />
                        </div>
                        <div className="form-group col-sm-12 mb-20p">
                        <button type="submit" id="bulkUploadBtn" className="btn btn-danger">SAVE</button>
                        <button type="button" className="btn btn-default" onClick={this.hideSlideBlock}>CANCEL</button>
                        </div>
                        <div id="inValidDataInfo" style={{color:"red"}}></div>
                    </div>
                    </form>
                    </div>
                </div>
                </div>
            </>
        )
    }
}

export default withTranslation()(TransporterOperations)