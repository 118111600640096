import React, { Component, useState, useEffect } from 'react';
import redirectURL from "../redirectURL";
import { withTranslation } from 'react-i18next';

import Select from "react-select";

class KpiTable extends Component {
    // Your data object
    constructor(props) {
        super(props);
        this.state = {
            transactionOptions: [],
            selectedOption1: {},
            selectedOption2: {},
            selectedOption3: {},
            summaryData: [{}, {}, {}],
            showPopup: false,
            overlayBlock: "show-n",
            showPopupMessage: ""
        }
    }
    async componentDidMount() {
        await this.fetchTransactionData();
    }
    fetchTransactionData = async () => {
        try {
            const url = window.location.href;
            const encodedString = url.split('?')[1];
            const decodedString = atob(encodedString);
            const splitValues = decodedString.split('&');
            var params = {
                transactionList: splitValues
            }
            const response = await redirectURL.post("/forecastvehicle/getKpiSummaryData", params);

            if (response.data.status === "success") {
                var summary_list = response.data.kpi_summary_list
                var new_transaction_id_list = response.data.kpi_summary_transaction_id_list.map((each) => {
                    return { label: each, value: each }
                })
                if (summary_list.length > 0) {
                    if (summary_list.length == 1) {
                        this.setState({ selectedOption1: { "value": summary_list[0].transaction_id, "label": summary_list[0].transaction_id } })
                    } else if (summary_list.length == 2) {
                        this.setState({ selectedOption1: { "value": summary_list[0].transaction_id, "label": summary_list[0].transaction_id } })
                        this.setState({ selectedOption2: { "value": summary_list[1].transaction_id, "label": summary_list[1].transaction_id } })
                    } else {
                        this.setState({ selectedOption1: { "value": summary_list[0].transaction_id, "label": summary_list[0].transaction_id } })
                        this.setState({ selectedOption2: { "value": summary_list[1].transaction_id, "label": summary_list[1].transaction_id } })
                        this.setState({ selectedOption3: { "value": summary_list[2].transaction_id, "label": summary_list[2].transaction_id } })
                    }
                    this.setState({ summaryData: summary_list })
                } else {
                    this.setState({ summaryData: [{}, {}, {}] })
                }
                this.setState({ transactionOptions: new_transaction_id_list })
            } else {
                // Handle other cases if needed
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    onClickingCompareButton = async (selectedOption, no) => {
        var params = {
            selectedTransactionList: selectedOption.value
        }
        try {
            const response = await redirectURL.post("/forecastvehicle/getSelectedKpiSummaryData", params);

            if (response.data.status === "success") {
                var new_summary_data = this.state.summaryData
                new_summary_data[no - 1] = response.data.kpi_summary_selected_list[0]
                this.setState({ summaryData: new_summary_data })
            } else {
                // Handle other cases if needed
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }
    handleSelectChange = async (selectedOption, no) => {
        // setSelectedOptions(selectedOption);
        if (no == 1) {
            if (selectedOption == this.state.selectedOption1 || selectedOption == this.state.selectedOption2 || selectedOption == this.state.selectedOption3) {
                this.setState({ overlayBlock: "show-m", showPopupMessage: <span>This Scenario is Already Selected</span>, showPopup: true });
            } else {
                await this.setState({ selectedOption1: selectedOption })
                await this.onClickingCompareButton(selectedOption, no)
            }
        } else if (no == 2) {
            if (selectedOption == this.state.selectedOption1 || selectedOption == this.state.selectedOption2 || selectedOption == this.state.selectedOption3) {
                this.setState({ overlayBlock: "show-m", showPopupMessage: <span>This Scenario is Already Selected</span>, showPopup: true });
            } else {
                await this.setState({ selectedOption2: selectedOption })
                await this.onClickingCompareButton(selectedOption, no)
            }
        } else if (no == 3) {
            if (selectedOption == this.state.selectedOption1 || selectedOption == this.state.selectedOption2 || selectedOption == this.state.selectedOption3) {
                this.setState({ overlayBlock: "show-m", showPopupMessage: <span>This Scenario is Already Selected</span>, showPopup: true });
            } else {
                await this.setState({ selectedOption3: selectedOption })
                await this.onClickingCompareButton(selectedOption, no)
            }
        }
    };
    formatSpecificationName = (name) => {
        return name
            .split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };
    formatTransactionName = (dateString) => {
    
    if(dateString != undefined){
    const parts = dateString.split(/[_-]/);
  
        if (parts.length === 5) {
            const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
            
            const month = parseInt(parts[1]);
            const year = parseInt(parts[2]);
            const iteration = parseInt(parts[4]);
            
            if (!isNaN(month) && month >= 1 && month <= 12 && !isNaN(year) && !isNaN(iteration)) {
            const monthName = monthNames[month - 1];
            return `${monthName} ${year}, iteration ${iteration}`;
            } else {
            return dateString;
            }
        } else {
            return dateString;
        }
        }
    };
    onClickingCancelBtn = () => {
        this.setState({overlayBlock: "show-n", showPopup: false, showPopupMessage: ""})
    }
    hideSlideBlock = () => {
        this.setState({
            overlayBlock: "show-n",
            showPopup: false,
            showPopupMessage: ""
        })
    }
    renderSubTable = (subTableData) => {
        return (
            <table style={{ width: '100%', border: '1px solid lightgrey', marginTop: '8px', marginBottom: "8px" }}>
                <thead>
                    <tr style={{ border: '1px solid lightgrey' }}>
                        {Object.keys(subTableData).map((key) => (
                            <th key={key} style={{ border: '1px solid lightgrey', padding: '8px', textAlign: 'center' }}>{this.formatSpecificationName(key)}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    <tr style={{}}>
                        {Object.values(subTableData).map((value, index) => (
                            <td key={index} style={{ border: '1px solid lightgrey', padding: '8px', textAlign: 'center' }}>{value}</td>
                        ))}
                    </tr>
                </tbody>
            </table>
        );
    };
    render() {
        const {t} = this.props
        const colors = ["#FAF6F0", "#EBE3D5", "#F3EEEA"]
        console.log(this.state.summaryData, "summaryData")
        return (
            <>
                {this.state.showPopup && (
                    <div style={{ position: "fixed", top: "36%", left: "36%", width: "450px", backgroundColor: "#f9f9f9", borderRadius: "10px", padding: "20px", zIndex: "1001" }}>
                        <p style={{ fontSize: "19px", fontWeight: "bold", color: "#000", textAlign: "center" }}>{this.state.showPopupMessage}</p>
                        <div className="d-flex justify-content-end">
                            <button className="btn btn-danger" onClick={this.onClickingCancelBtn}>Cancel</button>
                        </div>
                    </div>
                )}
                <div className={"overlay-block " + (this.state.overlayBlock)} onClick={this.hideSlideBlock}></div>
                <div className="row d-flex flex-row justify-content-end align-items-end">
                    <div className="col-2 mb-20p ml-70p mr-70p p-0p">
                        <p style={{ fontWeight: "bold", fontSize: "17px", marginBottom: "5px", color: "#000" }}>{t('select_scenario')}</p>
                        <Select
                            value={this.state.selectedOption1}
                            onChange={(selectedOption) => { this.handleSelectChange(selectedOption, 1) }}
                            options={this.state.transactionOptions}
                            placeholder="Select options"
                        />
                    </div>
                    <div className="col-2 mb-20p ml-90p mr-80p p-0p">
                        <p style={{ fontWeight: "bold", fontSize: "17px", marginBottom: "5px", color: "#000" }}>{t('select_scenario')}</p>
                        <Select
                            value={this.state.selectedOption2}
                            onChange={(selectedOption) => { this.handleSelectChange(selectedOption, 2) }}
                            options={this.state.transactionOptions}
                            placeholder="Select options"
                        />
                    </div>
                    <div className="col-2 mb-20p ml-90p mr-90p p-0p">
                        <p style={{ fontWeight: "bold", fontSize: "17px", marginBottom: "5px", color: "#000" }}>{t('select_scenario')}</p>
                        <Select
                            value={this.state.selectedOption3}
                            onChange={(selectedOption) => { this.handleSelectChange(selectedOption, 3) }}
                            options={this.state.transactionOptions}
                            placeholder="Select options"
                        />
                    </div>
                    {/* <div className="col-2">
                    <button className="btn btn-success mb-20p" onClick={onClickingCompareButton}>Compare</button>
                </div> */}
                </div>
                <div className="row">
                    <table className="col-12" style={{ backgroundColor: "#f9f9f9", borderCollapse: 'collapse', width: '100%', border: '1px solid #ddd', borderRadius: "10px" }}>
                        <thead>
                            <tr style={{ backgroundColor: "", color: "#000", fontSize: "17px" }}>
                                <th rowSpan="2" style={{ border: '2px solid #ddd', padding: '8px', textAlign: 'center', width: "15%" }}>{t('specifications')}</th>
                                {/* <th colSpan={colors.length} style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center', width: "85%" }}>Plan ID</th> */}
                            </tr>
                            <tr style={{ backgroundColor: "", color: "#000", fontSize: "17px" }}>
                                {colors.map((each, index) => (
                                    <th key={index} style={{ width: "27.5%", border: '2px solid #ddd', padding: '8px', textAlign: 'center' }}>{this.formatTransactionName(this.state.summaryData[index]?.transaction_id)}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {(this.state.summaryData.length > 0 && Object.keys(this.state.summaryData[0]).length > 0) && (Object.keys(this.state.summaryData[0].kpi_summary).map((transaction, index) => (
                                <tr key={index} style={{ color: "#000" }}>
                                    <React.Fragment key={transaction}>
                                        <td style={{ border: '2px solid #ddd', padding: '8px', textAlign: 'center', fontWeight: "bold", backgroundColor: "" }}>{this.formatSpecificationName(transaction)}</td>
                                        {this.state.summaryData.map((each, dataIndex) => (
                                            <td key={dataIndex} style={{
                                                background: Object.keys(each).length > 0 ? colors[dataIndex] : "",
                                                paddingLeft: "10px",
                                                paddingRight: "10px",
                                                border: '1px solid #ddd'
                                            }}
                                            >
                                                {each.kpi_summary && this.renderSubTable(each.kpi_summary[transaction])}
                                                {(each.kpi_summary && Object.keys(each.kpi_summary[transaction]).length == 0) && <td style={{ textAlign: "center", display: "flex", justifyContent: "center", marginBottom: "8px" }}>{each.kpi_summary[transaction]}</td>}
                                            </td>
                                        ))}
                                    </React.Fragment>
                                </tr>
                            )))}
                        </tbody>
                    </table>
                </div>
            </>
        );
    }
};


export default withTranslation()(KpiTable);
