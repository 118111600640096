import React, { useEffect, useState, useRef } from "react";
import redirectURL from "../redirectURL";
import Select from "react-select";
import DeviationRouteActionConsignment from "../manage/deviationRouteActionComponent";
import DrawMap from "../common/drawmap";
import Gps from '../manage/gpsActionComponent';
import NoGps from '../manage/nogpsActionComponent'
import ShipmentLegs from "../tracking/shipmentlegs";
import DeviationConsignmentInformation from "../manage/deviationConsignmentInfo";
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
import 'ag-grid-enterprise';
import SweetAlert from 'react-bootstrap-sweetalert';
import {
	getDDMMYYYY,
	getDDMMYYYYHHMMSS,
	getYYYYMMDD,
	getYYYYMMDDHHMMSS,
	getHyphenYYYYMMDDHHMMSS,
	getHyphenYYYYMMDD,
	getHyphenDDMMYYYYHHMMSS,
	getHyphenDDMMMYYYYHHMM,
	getHyphenDDMMYYYY
} from '../common/utils';
import { useAsyncError } from "react-router-dom";
var moment = require('moment');
export default function DeviationReports() {
  var [locationNames, setLocationNames] = useState([{value: "", label: "ALL"}]);
  var [location, setLocation] = useState({value: "", label: "ALL"});
  var [loadshow, setLoadshow] = useState("show-n");
  const [basicType, setBasicType] = useState("default");
  const [basicTitle, setBasicTitle] = useState("");
  const [show, setShow] = useState(false);
  const [startDate, setStartDate] = useState(moment.parseZone().subtract(1, "months").format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment.parseZone().format("YYYY-MM-DD"));
  const [priority, setPriority] = useState(0);
  const [rowData, setRowData] = useState([]);
  const [overly, setOverly] = useState('show-n');
  const [overlyRing, setOverlyRing] = useState(false);
  const [screen_type, setScreenType] = useState("");
  const [sliderRouteTranslate, setSlideRouterTranslate] = useState("");
  const [showDiv, setShowDiv] = useState("show-n");
  const [mapinfo, setMapInfo] = useState("show-n");
  const [dealer, setDealer] = useState("");
  const [defTransitCoords, setDefTransitCoords] = useState("")
  const [consignment_code, setConsignmentCode] = useState("");
  const [routeTruck, setRouteTruck] = useState({});
  const [consigner_coordinates, setConsignerCoordinates] = useState([]);
  const [consignee_coordinates, setConsigneeCoordinates] = useState([]);
  const [baseRouteCoords, setBaseRouteCoords] = useState([]);
  const [rownode, setRowNode] = useState([]);
  const [maptruckno, setMapTruckNo] = useState("")
  const [tolls, setTolls] = useState([]);
  const [map_type_of_tracking, setMapTypeOfTracking] = useState("");
  const [base_route_show, setBaseRouteShow] = useState(false)
  const [sidebarSubHeader, setSideBarSubHeader] = useState("");
  const [uploadDivWidth, setUploadDivWidth] = useState("");
  const [tabsliderTranslate, setTabSliderTranslate] = useState("");
  const [uploadFile, setUploadFile] = useState("");
  const [file, setFile] = useState("");
  const [csvMessage, setCsvMessage] = useState("");
  const [sliderCommentTranslate, setSliderCommentTranslate] = useState("")
  var gridRef = useRef();

  // tabs states
  const [ distanceDeviationTab, setDistanceDeviationTab] = useState("activet")
  const [ dropSequenceTab, setDropSequenceTab] = useState("")
  const [ distanceInCustomerServingTab, setDistanceInCustomerServingTab] = useState("")

  // page type
  const[ pageType, setPageType] = useState("distanceDeviationData")
  
  // tabs data
  const [ distanceDeviationTabData, setDistanceDeviationTabData] = useState(true);
  const [ distanceDifTabData, setDropSequenceData] = useState(false);
  const [ distanceInCustomerServingTabData, setDistanceInCustomerServingTabData] = useState(false);

  // Aggrid state
  const [ columnwithDefs, setColumnwithDefs ] = useState([]);
  const [frameworkComponents, setFrameworkComponents] = useState({
    // DeviationRouteActionConsignment: DeviationRouteActionConsignment,
    DeviationRouteActionConsignment: (props) => (
      <DeviationRouteActionConsignment
          context={{ 
            componentParent: {
            }
           }}
          {...props}
      />
    ),
    gps: Gps,
    nogps: NoGps,
    ShipmentLegs: ShipmentLegs,    
    DeviationConsignmentInformation: (props) => {
      <DeviationConsignmentInformation 
        context={{
          componentParent:{

          }
        }}
        {...props}
      />
    },
  })
  const [ defaultColDef, setDefaultColDef ] = useState({
      sortable: true,
      filter: true,
      editable: false,
      resizable: true,
      minWidth: 70,
      initialWidth: 200,
      wrapHeaderText: true,
      autoHeaderHeight: true,
    },
  )
  const [ statusBar, setStatusBar ] = useState({
    statusPanels: [
      {
        statusPanel: "agTotalAndFilteredRowCountComponent",
        align: "left"
      },
      {
        statusPanel: "agTotalRowCountComponent",
        align: "center"
      },
      { statusPanel: "agFilteredRowCountComponent" },
      { statusPanel: "agSelectedRowCountComponent" },
      { statusPanel: "agAggregationComponent" }
    ]
  })
  const [ sideBar, setSideBar ] = useState({
    toolPanels: [
      {
        id: "columns",
        labelDefault: "Columns",
        labelKey: "columns",
        iconKey: "columns",
        toolPanel: "agColumnsToolPanel"
      },
      {
        id: "filters",
        labelDefault: "Filters",
        labelKey: "filters",
        iconKey: "filter",
        toolPanel: "agFiltersToolPanel"
      }
    ]
  })
  const [ paginationPageSize, setPaginationPageSize] = useState(1000)
  const [rowIds, setRowIds] = useState([])
  const [detailCellRendererParams, setDetailedCellRendererParams] = useState({})
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);

  useEffect(() => {
    boundTypeLocations();
    setDistanceDeviationTab("activet");
    setDistanceDeviationTabData(true);
    getDeviationRecords()
    onClickTab('distanceDeviationData')
  }, []);
  useEffect(() => {
    if (gridRef.current) {
        setGridApi(gridRef.current.api);
        setGridColumnApi(gridRef.current.columnApi)
    }
}, [gridRef]);
  
  // on load function calls 
  const boundTypeLocations = async () => {
    let location_access = JSON.parse(localStorage.getItem("location_access"));
    let params = {
      data_type: 2
    }
    let planttNames = [];

    await redirectURL
      .post("master/getBoundTypeLocations", params)
      .then(async (response) => {
        if (response.data.status == "success") {
          let boundLocations = response.data.boundLocations;
          if (boundLocations.length > 0) {
            boundLocations.map((item) => {
              if (location_access != null) {
                if (location_access.includes(item.location_code)) {
                  planttNames.push({
                    value: item.location_code,
                    label: item.location_name,
                  });
                }
              } else {
                planttNames.push({
                  value: item.location_code,
                  label: item.location_name,
                });
              }
            });
            if (planttNames.length > 0) {
              setLocationNames((prevState)=>[...prevState , ...planttNames])
              // setLocation(locationNames[0]);
              // setLocationNames(locationNames);
            } else {
              setLocationNames([
                { label: "Please Add Data", value: "no_data" },
              ]);
              setLocation([{ label: "Please Add Data", value: "no_data" }]);
            }
          }
        }
      })
      .catch(function (error) {
        var errDetails = {
          url: "master/getBoundTypeLocations",
          screen: "Vehicle Availability Configurations",
          error,
        };
        redirectURL.post("/master/logErrorsForApiCalls", errDetails);
      });
  };
  const getDeviationRecords = async (e) => {
      let parameters = {}
      setLoadshow("show-m")
      setOverly("show-m")
      if(e && e === "onClick-dev-tab"){
        parameters.from_date = startDate;
        parameters.to_date = endDate;
      }else {
        parameters.from_date=moment.parseZone().subtract(1, 'months').format('YYYY-MM-DD')
        parameters.to_date=moment.parseZone().format('YYYY-MM-DD')
      }
			parameters.consigner_code= location.value
      var reqparams={
        ...parameters,
        screen_type: "distanceDeviationData",
        data_type: 'allTrucks',
        priority: priority,
        showRail: 0
      }
      // console.log(reqparams,'req',parameters)
      await redirectURL.post('/tracking/intranitRoadMobileData', reqparams)
					.then((response) => {
						// this.setState({assignVehicleloader:'show-n',})
				
				var trucksArr = response.data;
        // console.log(trucksArr,"deviation records trucksArr")

				
				if(trucksArr.length > 0)
				{
					trucksArr = trucksArr.filter((ta)=>ta.status <= 15);
				}
				if (typeof response.data == 'object') {
					var recordsarr = response.data;
				}
				else {
					var dd = response.data;
					var recordsarr = JSON.parse(response.data);
				}
				// console.log(recordsarr,"recordsarr")
				let records = recordsarr.sort(GetSortDescOrder("invoice_time"));
        setRowData(records)
        console.log(rowData,"rowData")
        setLoadshow('show-n')
        setOverly('show-n')
			})
			.catch(function (error) {
				var errDetails={
                    url:"/tracking/intranitRoadMobileData",
                    screen:'DeviationReports',
                    error,
                                }
                redirectURL.post("/master/logErrorsForApiCalls",errDetails)
				// console.log('error met')
				console.log(error);
			});
  }
  const getDropSequenceRecords = async () => {
      setLoadshow("show-m")
      setOverly("show-m")
      let parameters = {}
      parameters.from_date= startDate
			parameters.to_date= endDate
			parameters.consigner_code= location.value
      var reqparams={
        ...parameters,
        screen_type: "dropSequenceData",
        data_type: 'allTrucks',
        priority: priority,
        showRail: 0
      }
      // console.log(reqparams,'drop sequence req')
      await redirectURL.post('/tracking/intranitRoadMobileData', reqparams)
					.then((response) => {
						// this.setState({assignVehicleloader:'show-n',})
				
				var trucksArr = response.data;
        // console.log(trucksArr,"drop sequence trucksArr")

				
				if(trucksArr.length > 0)
				{
					trucksArr = trucksArr.filter((ta)=>ta.status <= 15);
				}
				if (typeof response.data == 'object') {
					var recordsarr = response.data;
				}
				else {
					var dd = response.data;
					var recordsarr = JSON.parse(response.data);
				}
				console.log(recordsarr,"recordsarr")
				let records = recordsarr.sort(GetSortDescOrder("invoice_time"));
				console.log(records,"records sorted")
        setRowData(records)
        console.log(rowData,"rowData")
        setLoadshow('show-n')
        setOverly('show-n')
			})
			.catch(function (error) {
				var errDetails={
                    url:"/tracking/intranitRoadMobileData",
                    screen:'DeviationReports',
                    error,
                                }
                redirectURL.post("/master/logErrorsForApiCalls",errDetails)
				// console.log('error met')
				console.log(error);
			});
  }
  const getDeviationInCustomerServing = async () => {
    setLoadshow("show-m")
      setOverly("show-m")
      let parameters = {}
      parameters.from_date= startDate
			parameters.to_date= endDate
			parameters.consigner_code= location.value
      var reqparams={
        ...parameters,
        screen_type: "distanceInCustomerServingData",
        data_type: 'allTrucks',
        priority: priority,
        showRail: 0
      }
      console.log(reqparams,'req',parameters)
      await redirectURL.post('/tracking/intranitRoadMobileData', reqparams)
					.then((response) => {
						// this.setState({assignVehicleloader:'show-n',})
				
				var trucksArr = response.data;
        console.log(trucksArr,"trucksArr")

				
				if(trucksArr.length > 0)
				{
					trucksArr = trucksArr.filter((ta)=>ta.status <= 15);
				}
				if (typeof response.data == 'object') {
					var recordsarr = response.data;
				}
				else {
					var dd = response.data;
					var recordsarr = JSON.parse(response.data);
				}
				console.log(recordsarr,"recordsarr")
				let records = recordsarr.sort(GetSortDescOrder("invoice_time"));
				console.log(records,"records sorted")
        setRowData(records)
        console.log(rowData,"rowData")
        setLoadshow('show-n')
        setOverly('show-n')
			})
			.catch(function (error) {
				var errDetails={
                    url:"/tracking/intranitRoadMobileData",
                    screen:'DeviationReports',
                    error,
                                }
                redirectURL.post("/master/logErrorsForApiCalls",errDetails)
				// console.log('error met')
				console.log(error);
			});
  }
  //  Aggrid related functions
  const onRowSelection = (event) => {
    var rowIds = [];
    var rwCount = event.api.getSelectedNodes();
    rwCount.map((item) => {
      rowIds.push(item.data.shipment_id)
    });
    setRowIds(rowIds)		
    // console.log("checkedlist ", rowIds)
  }

  // Aggrid icon functionalities
  const onClickShowConsignmentInformation = async (rownode) =>  {
    console.log("rownode", rownode.data)
    // console.log(pageType,"onclick i icon")
    var data=rownode.data
    let logParams = {
        // "location_code" : location.value,
        // "location_name" : location.label,
        "user_name" : localStorage.getItem("username"),
        "useremail" : localStorage.getItem("email"),
        "client" : localStorage.getItem("client"),
        "screen" : "Trips",
        "activity" : 'clicked on consignment information icon',
        "event" : "Aggrid opened below the row",
        // "data_type" : this.state.data_type,
    }

    redirectURL.post("/master/loguserUpdatesorChanges",logParams)
    let screen_type = pageType;
    var params = {
        shipment_id: rownode.data.shipment_id
    }
    var newCommentHeaders = [
      {
        headerName : "Order No",
        field : "consignment_code",
        resizable : true,
        width:130,
        filter : true,
      },
      {
        headerName : "Dealer Code",
        field : "consignee_code",
        resizable : true,
        width:120,
        filter : true,
      },
      // {
      //     headerName:"Shipment Status",
      //     field:"status",
      //     width:130,
      //     resizable : true,
      //     //pinned:'left',
      //     valueGetter:function(params){
      //     console.log('parrrrrrrrrrrrrrrrr', params)
      //     try {
      //       var status = "Shipment Created"
      //       // console.log('hit')
      //       if(screen_type == 'Delivered' || screen_type == "distanceDeviationData" || screen_type == "dropSequenceData" || screen_type == "distanceInCustomerServingData"){
      //         console.log('hit info')
      //         const numericArray = params.data.status_list?params.data.status_list.map(Number):[];
      //         const maxValue = Math.max(...numericArray);
      //         console.log(maxValue,"maxValuemaxValue")
      //         if (maxValue == 12 || params.data.status == 12) {
      //           status = "Left Customer";
      //         }
      //         if (maxValue == 13  || params.data.status == 13) {
      //           status = "Possible Trip Closure";
      //         }
      //         if (maxValue == 14  || params.data.status == 14) {
      //           status = "Proximity Trip Closure";
      //         }
      //         if (maxValue == 15  || params.data.status == 15) {
      //           console.log("sindi 15")
      //           if(params.data.dealer_not_served == 1){
      //             status ="Dealer Not Served"
      //           }else{
      //             status = "Reached Plant";
      //           }
                
      //         }
      //         if (params.data.status == 6 ) {
      //           status = "Force Closure";
      //         }
      //       }else{
      //         // console.log('params.data.status',params.data.status)
      //       if (params.data.status == 1 || params.data.status == "1") {
      //         status = "Shipment Created";
      //       }
      //       // if (params.data.status == 2 || params.data.status == "2") {
      //       // 	// status = "Intransit To Collection Center";
      //       // 	status = "Intransit";
      //       // }
      //       if (params.data.status == 3 || params.data.status == "3") {
      //         // status = "Intransit To Customer";
      //         status = "Inside Plant";
      //       }
      //       if (params.data.status == 4 || params.data.status == "4") {
      //         // status = "Possible Customer Location";
      //         status = "Inside Loading Yard";
      //       }
      //       if (params.data.status == 5 || params.data.status == "5") {
      //         // status = "Near Customer Location";
      //         status = "Outside Loading Yard";
      //       }
      //       if (params.data.status == 6 || params.data.status == "6") {
      //         status = "Force Closure";
      //       }
      //       // if (params.data.status == 7 || params.data.status == "7") {
      //       // 	status = "Reached Dispatch Center";
      //       // }
      //       if (params.data.status == 8 || params.data.status == "8") {
      //         status = "In Transit to Customer";
      //       }
      //       if (params.data.status == 9 || params.data.status == "9") {
      //         status = "Proximity Customer Location";
      //       }
      //       if (params.data.status == 10 || params.data.status == "10") {
      //         status = "Near Customer Vicinity";
      //       }
      //       if (params.data.status == 11 || params.data.status == "11") {
      //         status = "Reached Customer";
      //       }
      //       if (params.data.status == 12 || params.data.status == "12") {
      //         status = "Left Customer";
      //       }
      //       if (params.data.status == 13 || params.data.status == "13") {
      //         status = "Possible Trip Closure";
      //       }
      //       if (params.data.status == 14 || params.data.status == "14") {
      //         status = "Proximity Trip Closure";
      //       }
      //       if (params.data.status == 15 || params.data.status == "15") {
      //         if(params.data.dealer_not_served == 1){
      //           status ="Dealer Not Served"
      //         }else{
      //         status = "Reached Plant";
      //         }
      //       }
      //     }
      //     console.log(status,"final status")
      //       return status
      //     }
      //         catch(e){}
      //     }
      // },
      // {
      //   headerName : "CD Status",
      //   field : "cd_status",
      //   resizable : true,
      //   width:110,
      //   filter : true,
      //   valueGetter:(params)=>{
      //     try {
      //       var status = "Shipment Created"
      //       if (params.data.cd_status === 1 || params.data.cd_status === '1') {
      //         status = "Shipment Created";
      //       }
      //       // if (params.data.cd_status == 2 || params.data.cd_status == "2") {
      //       // 	// status = "Intransit To Collection Center";
      //       // 	status = "Intransit";
      //       // }
      //       if (params.data.cd_status === 3 || params.data.cd_status === '3') {
      //         // status = "Intransit To Customer";
      //         status = "Inside Plant";
      //       }
      //       if (params.data.cd_status === 4 || params.data.cd_status === '4') {
      //         // status = "Possible Customer Location";
      //         status = "Inside Loading Yard";
      //       }
      //       if (params.data.cd_status === 5 || params.data.cd_status === '5') {
      //         // status = "Near Customer Location";
      //         status = "Outside Loading Yard";
      //       }
      //       if (params.data.cd_status === 6 || params.data.cd_status === '6') {
      //         status = "Force Closure";
      //       }
      //       // if (params.data.cd_status == 7 || params.data.cd_status == "7") {
      //       // 	status = "Reached Dispatch Center";
      //       // }
      //       if (params.data.cd_status === 8 || params.data.cd_status === '8') {
      //         status = "In Transit to Customer";
      //       }
      //       if (params.data.cd_status === 9 || params.data.cd_status === '9') {
      //         status = "Proximity Customer Location";
      //       }
      //       if (params.data.cd_status === 10 || params.data.cd_status === '10') {
      //         status = "Near Customer Vicinity";
      //       }
      //       if (params.data.cd_status === 11 || params.data.cd_status === '11') {
      //         status = "Reached Customer";
      //       }
      //       if (params.data.cd_status === 12 || params.data.cd_status === '12') {
      //         status = "Left Customer";
      //       }
      //       if (params.data.cd_status === 13 || params.data.cd_status === '13') {
      //         status = "Possible Trip Closure";
      //       }
      //       if (params.data.cd_status === 14 || params.data.cd_status === '14') {
      //         status = "Proximity Trip Closure";
      //       }
      //       if (params.data.cd_status === 15 || params.data.cd_status === '15') {
      //         status = "Reached Plant";
      //       }
          
      //       return status
      //     }
      //     catch(e){}
      //   }
      // },
      // {
      //   headerName : "Plant Name",
      //   field : "consigner_name",
      //   resizable : true,
      //   width:160,
      //   filter : true,
      // },
      {
        headerName : "Dealer Name",
        field : "consignee_name",
        resizable : true,
        width:140,
        filter : true,
      },
      {
        headerName : "Quantity (Cases)",
        field : "demand_quantity",
        resizable : true,
        width:130,
        filter : true,
        valueGetter:function(params){
          if(params.data.demand_quantity!==undefined && params.data.demand_quantity!==''){
            return (params.data.demand_quantity).toFixed(2)
          }else{
            return 'N/A'
          }
          
          console.log(params.data.demand_quantity)
        }
      },
      {
        headerName : "Weight (Pounds)",
        field : "demand_weight",
        resizable : true,
        width:110,
        filter : true,
      },
      {
        headerName : "Volume (CFT)",
        field : "demand_volume",
        resizable : true,
        width:115,
        filter : true,
      },
      {
        headerName : "Trip Sequence",
        field : "drop_sequence",
        resizable : true,
        width:120,
        filter : true,
      },
      {
        headerName : "Actual Sequence",
        field : "actual_sequence_no",
        resizable : true,
        width:120,
        filter : true,
      }, 
      {
        headerName : "Reached Customer At",
        field : "recent_dealer_reported",
        resizable : true,
        width:170,
        filter : true,
        hide: pageType === "distanceInCustomerServingData"? false: true,
        valueGetter:function(params){
          return getHyphenDDMMMYYYYHHMM(params.data.recent_dealer_reported);
        }
      },
      {
        headerName : "Left Customer At",
        field : "left_dealer_marked",
        resizable : true,
        width:150,
        filter : true,
        hide: pageType === "distanceInCustomerServingData"? false: true,
        valueGetter:function(params){
          return getHyphenDDMMMYYYYHHMM(params.data.left_dealer_marked);
        }
      }, 
      // {
      //   headerName : "Expected ETA",
      //   field : "drop_node_total_time_hrs",
      //   resizable : true,
      //   width:150,
      //   filter : true,
        
      // },
      // {
      //   headerName : "Unloading Duration",
      //   field : "unloading_time_in_hrs",
      //   resizable : true,
      //   width:150,
      //   filter : true,
        
      // },
      // {
      //   headerName : "Actual Sequence No",
      //   field : "actual_sequence_no",
      //   resizable : true,
      //   width:150,
      //   filter : true,
        
      // }  
    ]
    
    
    setDetailedCellRendererParams({
      detailGridOptions: {
      columnDefs: newCommentHeaders,
      overlayNoRowsTemplate: 'No rows to show',
      defaultColDef: defaultColDef,
      statusBar: statusBar,
      sideBar: sideBar,
      enableRangeSelection:true
    },
    getDetailRowData: async function (param) {
      var leg_details=data.leg_details
      var legDetails=JSON.parse(leg_details)
      console.log(legDetails,"legDetails")
      param.successCallback(legDetails);
    },
    masterDetail: true
    })
    // console.log(propsdata);
    if(rownode.column.colDef.field == 'consignmentinformation')
    {

        rownode.node.setExpanded(!rownode.node.expanded);
    }
    else{

        rownode.node.setExpanded(false);
    }
  }
  var hideforsales = false;
  var hideData=false;
  let hideForReverse=""

  // Aggrid column defs
  var commonDefs = [ 
    {
      headerName: "",
      field: "_id",
      width: 60,
      pinned: 'left',
      // cellRenderer: 'DeviationRouteActionConsignment',
      cellRendererSelector: function (params) {
        try{
          if(params.data._id){
            return {
              component: DeviationRouteActionConsignment,
              params: {
                onCloseUploadDiv: onCloseUploadDiv,
                onCloseRouteDiv: onCloseRouteDiv,
                onShowRouteDiv: onShowRouteDiv
              }
            }
          }
        }catch(e){
          console.log(e,"error while cell rednering")
        }
      },
      hide:hideForReverse,
      suppressColumnsToolPanel:hideForReverse,
    },
    {
      headerName: "",
      field: "consignmentinformation",
      width: 50,
      pinned: 'left',
      // cellRenderer:'ConsignmentInformation',
      cellRendererSelector: function (params) {
        try{
          if(params.data._id){
            return {
              component: DeviationConsignmentInformation,
              params: {
                onClickShowConsignmentInformation: onClickShowConsignmentInformation,
              }
            }
          }
        }catch(e){
          console.log(e,"error while cell rednering")
        }
      },
      filter: false,resizable: true,
    },
    {
      headerName:"Facility Code",
      field:"consigner_code",
      width:130
    }, 
    {
      headerName:"Facility Name",
      field:"consigner_name",
      width:160
    },   
    {
      headerName:"Truck No",
      field:"truck_no",
      width:100,
      // pinned:"left",
      valueGetter: function(params){
          if(params.data.truck_no !=undefined && params.data.truck_no !="")
          {
              return params.data.truck_no;
          }
          else
          {
              return "No Data Available";
          }
      }
    },
    {
      headerName:"Shipment Id",
      field:"shipment_id",
      width:140,
      hide:hideData,
      suppressColumnsToolPanel:hideforsales,
    },
    {
      headerName:"Invoice Date",
      field:"invoice_time",
      width:130,
      valueGetter:function(params){
          try{
              
              if(params.data.invoice_time != "" && params.data.invoice_time != undefined)
              {
                  return getHyphenDDMMMYYYYHHMM(params.data.invoice_time);
              }
              else{
                  return "Not Available";
              }
          }
          catch(e){}
      },
      comparator: dateComparator,
      hide:hideForReverse,
      suppressColumnsToolPanel:hideForReverse,
    },
    {
      headerName:"Transporter Name",
      field:"transporter_name",
      width:150,
    },
    
    // {
    //   headerName:"Consignment Creation Time",
    //   field:"shipment_time",
    //   width:130,
    //   valueGetter:function(params){
    //       try{
              
    //           if(params.data.shipment_time != "" && params.data.shipment_time != undefined)
    //           {
    //               return getHyphenDDMMMYYYYHHMM(params.data.shipment_time);
    //           }
    //           else{
    //               return "Not Available";
    //           }
    //       }
    //       catch(e){}
    //   },
    // },
    // {
    //   headerName:"Shipment Status",
    //   field:"status",
    //   width:130,
    //   //pinned:'left',
    //   valueGetter:function(params){
    //     try {
    //       var status = "Shipment Created"

    //       if(screen_type == 'Delivered' || screen_type == "distanceInCustomerServingData" || screen_type == "dropSequenceData" || screen_type == "distanceDeviationData"){
    //         const numericArray = params.data.status_list.map(Number);
    //         const maxValue = Math.max(...numericArray);
            
    //         if (maxValue == 12 ) {
    //           status = "Left Customer";
    //         }
    //         if (maxValue == 13 ) {
    //           status = "Possible Trip Closure";
    //         }
    //         if (maxValue == 14 ) {
    //           status = "Proximity Trip Closure";
    //         }
    //         if (maxValue == 15 ) {
    //           status = "Reached Plant";
    //         }
    //         if (params.data.status == 6 ) {
    //           status = "Force Closure";
    //         }
    //       }else{
    //       if (params.data.status == 1 || params.data.status == "1") {
    //         status = "Shipment Created";
    //       }
    //       // if (params.data.status == 2 || params.data.status == "2") {
    //       // 	// status = "Intransit To Collection Center";
    //       // 	status = "Intransit";
    //       // }
    //       if (params.data.status == 3 || params.data.status == "3") {
    //         // status = "Intransit To Customer";
    //         status = "Inside Plant";
    //       }
    //       if (params.data.status == 4 || params.data.status == "4") {
    //         // status = "Possible Customer Location";
    //         status = "Inside Loading Yard";
    //       }
    //       if (params.data.status == 5 || params.data.status == "5") {
    //         // status = "Near Customer Location";
    //         status = "Outside Loading Yard";
    //       }
    //       if (params.data.status == 6 || params.data.status == "6") {
    //         status = "Force Closure";
    //       }
    //       // if (params.data.status == 7 || params.data.status == "7") {
    //       // 	status = "Reached Dispatch Center";
    //       // }
    //       if (params.data.status == 8 || params.data.status == "8") {
    //         status = "In Transit to Customer";
    //       }
    //       if (params.data.status == 9 || params.data.status == "9") {
    //         status = "Proximity Customer Location";
    //       }
    //       if (params.data.status == 10 || params.data.status == "10") {
    //         status = "Near Customer Vicinity";
    //       }
    //       if (params.data.status == 11 || params.data.status == "11") {
    //         status = "Reached Customer";
    //       }
    //       if (params.data.status == 12 || params.data.status == "12") {
    //         status = "Left Customer";
    //       }
    //       if (params.data.status == 13 || params.data.status == "13") {
    //         status = "Possible Trip Closure";
    //       }
    //       if (params.data.status == 14 || params.data.status == "14") {
    //         status = "Proximity Trip Closure";
    //       }
    //       if (params.data.status == 15 || params.data.status == "15") {
    //         status = "Reached Plant";
    //       }
    //     }
    //       return status
    //     }
    //         catch(e){}
    //   }
    // },
    // {
    //   headerName:"Dealer Code",
    //   field:"consignee_code",
    //   width:110,
    //   resizable : true,
    //   filter : true,
    //   hide:hideData,
    //   suppressColumnsToolPanel:hideData,
    // },
    // {
    //   headerName:"Dealer Name",
    //   field:"consignee_name",
    //   width:120,
    //   resizable : true,
    //   filter : true,
    // }, 
    // {
    //   headerName:"Dealer City",
    //   field:"consignee_city",
    //   width:110,
    //   resizable : true,
    //   filter : true,
    // },
    // {
    //   headerName:"Dealer State",
    //   field:"consignee_state",
    //   width:120,
    //   valueGetter:function(params){
    //     try{
    //       if(params.data.consignee_state != "" && params.data.consignee_state != undefined)
    //       {
    //         return params.data.consignee_state;
    //       }
    //       else{
    //         return "No Data Available";
    //       }

    //     }
    //     catch(e){}
    //   },
    //   resizable : true,
    // },
    // {
    //   headerName: "Last Packet Time",
    //   headerTooltip:'Last Packet Time',
    //   field: "timestamp",
    //   width: 140,
      
    //   resizable: true,
      
    //   // filter: "agDateColumnFilter",
    //   comparator: dateComparator,
    //   valueGetter:function(params){
    //     try{
    //       if(params.data.timestamp !== "" && params.data.timestamp !== undefined)
    //       {
    //         return getHyphenDDMMMYYYYHHMM(params.data.timestamp);
    //       }
          
    //       else{
    //         return "No Data Available";
    //       }

    //     }
    //     catch(e){}
    //   },
    //   hide:hideForReverse,
    //   suppressColumnsToolPanel:hideForReverse,
    // },
    // {
    //   headerName:"Last Known Address",
    //   headerTooltip:'Last Known Address',
    //   field:"address",
    //   width:175
    // }
  ]
  // let lastColCommonDefs = []
  // lastColCommonDefs = commonDefs.pop()
  
  var distanceInDeviationDefs = [            
    {
      headerName:"Expected Distance",
      field:"round_trip_distance",
      width:160,
      resizable : true,
      valueGetter:function(params){
        try{
          if(params.data.round_trip_distance != undefined && params.data.round_trip_distance != '')
          {
            return params.data.round_trip_distance;
          }
          else{
            return "No Data Available";
          }

        }
        catch(e){}
      }
    },
    {
      headerName:"Actual Distance",
      field:"actual_round_trip_km",
      width:140,
      resizable : true,
      valueGetter:function(params){
        try{
          if(params.data.actual_round_trip_km != undefined && params.data.actual_round_trip_km != '')
          {
            return params.data.actual_round_trip_km;
          }
          else{
            return "No Data Available";
          }

        }
        catch(e){}
      }
    },
    {
      headerName:"Distance Difference",
      field:"distance_difference",
      width:160,
      resizable : true,
      valueGetter:function(params){
        try{
          if(params.data.distance_difference != undefined && params.data.distance_difference != '')
          {
            return params.data.distance_difference;
          }
          else{
            return "No Data Available";
          }

        }
        catch(e){}
      }
    },
    // {
    //   headerName:"Deviation in Distance",
    //   field:"distance_deviation",
    //   width:160,
    //   resizable : true,
    //   valueGetter:function(params){
    //     try{
    //       if(params.data.distance_deviation !== undefined && params.data.distance_deviation !== '')
    //       {
    //         return params.data.distance_deviation == 1 ? 'Yes' :'No';
    //       }
    //       else{
    //         return "No Data Available";
    //       }

    //     }
    //     catch(e){}
    //   }
    // }
  ]
  var DevDisInCustomServDefs = [    
    {
      headerName:"Planned Customers",
      field:"planned_customers",
      width:160,
      resizable : true,
      valueGetter:function(params){
        try{
          if(params.data.planned_customers !== undefined && params.data.planned_customers !== '')
          {
            return params.data.planned_customers;
          }
          else{
            return "No Data Available";
          }

        }
        catch(e){}
      }
    },
    {
      headerName:"Served Customers",
      field:"served_customers",
      width:160,
      resizable : true,
      valueGetter:function(params){
        try{
          if(params.data.served_customers !== undefined && params.data.served_customers !== '')
          {
            return params.data.served_customers;
          }
          else{
            return "No Data Available";
          }

        }
        catch(e){}
      }
    },
    // {
    //   headerName:"Deviation in Customer Serving",
    //   headerTooltip:'Deviation in Customer Serving',
    //   field:"deviation_in_customer_serving",
    //   width:160,
    //   resizable : true,
    //   valueGetter:function(params){
    //     try{
    //       if(params.data.deviation_in_customer_serving !== undefined && params.data.deviation_in_customer_serving !== '')
    //       {
    //         return params.data.deviation_in_customer_serving == 1 ? 'Yes' :'No';
    //       }
    //       else{
    //         return "No Data Available";
    //       }

    //     }
    //     catch(e){}
    //   }
    // }
  ]
  var dropSequenceDefs = [
    {
      headerName:"Deviation in Sequence",
      headerTooltip:'Deviation in Sequence',
      field:"sequence_not_followed",
      width:180,
      resizable : true,
      valueGetter:function(params){
        try{
          if(params.data.sequence_not_followed !== undefined && params.data.sequence_not_followed !== '')
          {
            return params.data.sequence_not_followed == 1 ? 'Yes' :'No';
          }
          else{
            return "No Data Available";
          }

        }
        catch(e){}
      }
    }
  ]


  // general functionalities
  const onClickHideAll = () => {
    setLoadshow('show-n')
    setOverly("show-n")
    setSlideRouterTranslate("")
	}
  const onClickTab = async (e) => {
    // console.log(pageType,'helo')
    if(e == "distanceDeviationData")
    {
        await setPageType('distanceDeviationData')
        var distanceDeviationTab = "activet";
        var distanceInCustomerServingTab = "";
        var dropSequenceTab = "";
        var distanceDeviationTabData = true;        
        var dropSequenceData = false;
        var distanceInCustomerServingData = false;
        var activity = "Clicked On Distance Deviation Tab";
        var action = "Navigated To Distance Deviation Tab"; 
        setColumnwithDefs([ ...commonDefs,...distanceInDeviationDefs]) 
        getDeviationRecords('onClick-dev-tab')
    }
    if(e == "dropSequenceData")
    {
      await setPageType("dropSequenceData")
      var distanceDeviationTab = "";
      var distanceInCustomerServingTab = "";
      var dropSequenceTab = "activet";
      var distanceDeviationTabData = false;        
      var dropSequenceData = true;
      var distanceInCustomerServingData = false;
      var activity = "Clicked On Distance Difference Tab";
      var action = "Navigated To Distance Difference Tab"; 
      setColumnwithDefs([...commonDefs, ...dropSequenceDefs]) 
      getDropSequenceRecords()
    }
    else if(e == "distanceInCustomerServingData")
    {
      await setPageType("distanceInCustomerServingData")
      var distanceDeviationTab = "";
      var distanceInCustomerServingTab = "activet";
      var dropSequenceTab = "";
      var distanceDeviationTabData = false;        
      var dropSequenceData = false;
      var distanceInCustomerServingData = true;
      var activity = "Clicked On Distance In Customer Serving Tab";
      var action = "Navigated To Distance In Customer Serving Tab"; 
      setColumnwithDefs([...commonDefs, ...DevDisInCustomServDefs]) 
      getDeviationInCustomerServing()
    }

    setDistanceDeviationTab(distanceDeviationTab)
    setDistanceInCustomerServingTab(distanceInCustomerServingTab)
    setDropSequenceTab(dropSequenceTab)
    setDistanceDeviationTabData(distanceDeviationTabData)
    setDropSequenceData(dropSequenceData)
    setDistanceInCustomerServingTabData(distanceInCustomerServingData)

    let data_type = 2
    let logParams = {
        "location_code" : location.value,
        "location_name" : location.label,
        "user_name" : localStorage.getItem("username"),
        "useremail" : localStorage.getItem("email"),
        "client" : localStorage.getItem("client"),
        "screen" : "Deviation Reports ",
        "activity" : activity,
        "event" : action,
        "data_type" : data_type,
    }
  
    redirectURL.post("/master/loguserUpdatesorChanges",logParams)
  }
  useEffect(()=>{
    onClickTab(pageType)
  },[pageType])


  // form functionalities
  const locationChanged = (location) => {
    console.log(location,"optionnn")
    setLocation(location);
  };
  const formHandler = async (event) => 
    {
      setLoadshow('show-m')    
      event.preventDefault();
      var check=startDate < endDate
      //    console.log(startDate,endDate,'dakjkj')
      // if (location.value === ""){
      //   setBasicTitle('Please Enter Plant Name')
      //   setBasicType("danger")
      //   setLoadshow('show-n')
      //   setShow(true);
      // }else 
      if(!check){
        setBasicTitle('Start Date Should be Less than End Date')
        setBasicType("danger")
        setLoadshow('show-n')
        setShow(true);
      }
      else {
          let formdata = {}
          if(!location.value){
              formdata= {
                  from_date: getHyphenYYYYMMDDHHMMSS(startDate).split(' ')[0],
                  to_date: getHyphenYYYYMMDDHHMMSS(endDate).split(' ')[0],
              } 
              formdata.consigner_code='ALL'
              
          } else {
            console.log(location.label,"location picked")
            formdata= {
                consigner_code: location.value,
                from_date: getHyphenYYYYMMDDHHMMSS(startDate).split(' ')[0],
                to_date: getHyphenYYYYMMDDHHMMSS(endDate).split(' ')[0],
            } 
          } 
        var reqparams={
          ...formdata,
          screen_type: pageType,
          data_type: 'allTrucks',
          priority: priority,
          showRail: 0,
          
        }
        // console.log(reqparams,'generate params')
        await redirectURL.post('/tracking/intranitRoadMobileData', reqparams)
            .then((response) => {
              // this.setState({assignVehicleloader:'show-n',})
          
          var trucksArr = response.data;
          console.log(trucksArr,"trucksArr")

          
          if(trucksArr.length > 0)
          {
            trucksArr = trucksArr.filter((ta)=>ta.status <= 15);
          }
          if (typeof response.data == 'object') {
            var recordsarr = response.data;
          }
          else {
            var dd = response.data;
            var recordsarr = JSON.parse(response.data);
          }
          console.log(recordsarr,"recordsarr")
          let records = recordsarr.sort(GetSortDescOrder("invoice_time"));
          console.log(records,"records sorted")
          setRowData(records)
          console.log(rowData,"rowData")
          setLoadshow('show-n')
          setOverly('show-n')
        })
        .catch(function (error) {
          var errDetails={
                      url:"/tracking/intranitRoadMobileData",
                      screen:'DeviationReports',
                      error,
                                  }
                  redirectURL.post("/master/logErrorsForApiCalls",errDetails)
          // console.log('error met')
          console.log(error);
        });
      
        // await redirectURL.post("master/getPlantBasedRecords",formdata)
        // .then(
        //   async (result) => {
        //     var plantWiseRecords = result.data;
        //     if(result.data.status === "fail") {
        //         this.setState({
        //             show:true,
        //             basicTitle:'No Data Found',
        //             basicType:"danger",
        //             loadshow:'show-n',
        //             defaultRecords: []
        //         })
        //     } else {
        //         let plantLookup = {};
        //         this.state.plantDetails.forEach(plant => {
        //               plantLookup[plant.plant_code] = plant.plant_name;
        //       });
        //       plantWiseRecords.forEach(obj => {
        //           obj.plant_name = plantLookup[obj.plant_code] || "Unknown Plant";
        //       });

        //         this.setState({
        //           defaultRecords: plantWiseRecords,
        //           loadshow:'show-n',
        //           overly:'show-n',
        //         })
        //     }             
        //   }
        // )
        // .catch(function (error) {
        //   console.log(error);
        // });
      }
    }
  const onChangeStartDate = (e) => {
    setStartDate(e.target.value)
  }
  const onChangeEndDate = (e) => {
    setEndDate(e.target.value)
  }
  const getCurrentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // January is 0
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }
  
  function onShowRouteDiv(e) {
		console.log("e ", e)
		var consignmentcodes = []
		//console.log("leg_details ", JSON.parse(e.leg_details))
		if(e.leg_details!="" && e.leg_details!=undefined){
			var legs = JSON.parse(e.leg_details);
			console.log('hit legs',legs)
			legs.map((item) => {
				consignmentcodes.push(item.consignment_code)
			})
		}
		console.log("consignmentcodes ", consignmentcodes)
    setLoadshow("show-m");
    setOverly("show-m")
		
		var startdate = ""
		if(e.tpa_entry_time != undefined && e.tpa_entry_time != "" && e.tpa_entry_time != null){
			startdate = getYYYYMMDDHHMMSS(e.tpa_entry_time)
		}
		else if(e.shipment_time != undefined && e.shipment_time != "" && e.shipment_time != null){
			startdate = getYYYYMMDDHHMMSS(e.shipment_time)
		}
		else if(e.invoice_time != undefined && e.invoice_time != "" && e.invoice_time != null){
			startdate = getYYYYMMDDHHMMSS(e.invoice_time)
		}
		else{
            startdate = moment.parseZone().format('YYYY-MM-DD')+" 00:00:00";
		}
		let sD = startdate.split(" ")
		let date = sD[0].replaceAll("/", "-")
		startdate = date + " " + sD[1]

		if(e.status == "9" || e.status == "10" ||e.status == "11" || e.status == 9 || e.status == 10|| e.status == 11) 
    {
			if(e.recent_dealer_reported !=undefined && e.recent_dealer_reported !="")
			{
				var edate = moment.parseZone(e.recent_dealer_reported).format("YYYY-MM-DD HH:mm:ss");
			}
			else
			{
        var edate = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
			}
    }
    else if(e.status == "12" || e.status == "13" ||e.status == "14" || e.status == 12 || e.status == 13|| e.status == 14)
    {
      if(e.left_dealer_marked !=undefined && e.left_dealer_marked !="")
      {
        var edate = moment.parseZone(e.left_dealer_marked).format("YYYY-MM-DD HH:mm:ss");
      }
      else
      {
                var edate = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
      }
    }
		else if(e.status == "15" || e.status == 15)
    {
			if(e.reached_plant_at !=undefined && e.reached_plant_at !="")
			{
				var edate = moment.parseZone(e.reached_plant_at).format("YYYY-MM-DD HH:mm:ss");
			}
			else
			{
        var edate = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
			}
    }
    else{
      if(e.status == 6 || e.status == "6")
      {
        if(e.force_closure_time !=undefined && e.force_closure_time !="")
        {
          var edate = moment.parseZone(e.force_closure_time).format('YYYY-MM-DD HH:mm:ss');
        }
        else{
          var edate = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
        } 
			}
      else{
				var edate = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
			}           
    }
		var reqData = {
			truck_no: e.truck_no,
      shipment_id:e.shipment_id,
			consignment_codes: consignmentcodes,
			fromdate: startdate,
			todate: edate
		}
		console.log("reqData: ", reqData);
		var mapUrl = '/tracking/truckmap';
		redirectURL.post(mapUrl, reqData)
			.then((response) => {
				var records = response.data;
				// console.log("check ",Array.isArray(records))
				console.log("Props data ", response.data)
				try{
					if(Array.isArray(records) == false)
					{
						var recs = records.replace(/NaN/g,"0")
						records = JSON.parse(recs)
					}
				}
				catch(e){

				}
				
				console.log("records list ", records);
        setLoadshow("show-m")
				
				// console.log("S1")
				try {
					// console.log("S2")
					console.log("records.coords ", records.coords)
					if (records.coords.length == 0) {
						// console.log("S3")
						//console.log("records", records.coords);
            setShow(true);
            setBasicTitle('No Route Available');
            setBasicType("danger");
            setLoadshow("show-n");
					}
					else {
						// console.log("S4")
						// console.log("recordss ", records)
						// if (records != '') {
            setSlideRouterTranslate("slider-translate-60p");
            setOverly("show-m")
            setShowDiv("show-m");
            setMapInfo(records);
            setDealer(records.consignee_coordinates);
            setConsignmentCode("Truck NO : " + e.truck_no);
            setMapTruckNo(e.truck_no);
            setRouteTruck({ "truck_no": e.truck_no, "startTime": records.route_details.start_time, "endTime": records.route_details.end_time }); 
            setConsignerCoordinates(records.consigner_coordinates);
            setConsigneeCoordinates(records.consignee_coordinates);
            setBaseRouteCoords(records.base_route);
            setTolls(records.tolls);
            setMapTypeOfTracking(records.type_of_tracking);
            setBaseRouteShow(true);
						setSideBarSubHeader("Truck Route Details")
            setRowNode(e)
							// console.log("s5 ")
							// this.renderMap();
						// }
					}
				}
				catch (e) {
          setShow(true);
          setBasicTitle('No Route Available');
          setBasicType("danger");
          setLoadshow("show-n");
				}
			})
			.catch(function (error) {
				// console.log(error);
				var errDetails={
					url:'/tracking/truckmap',
					screen:'Trips',
					error,
					
				}
				redirectURL.post("/master/logErrorsForApiCalls",errDetails)
			});
  }
  // function onClickShowConsignmentInformation(rownode) {
  //   console.log("rownode", rownode.data)
  //   var data=rownode.data
  //   let logParams = {
  //       // "location_code" : location.value,
  //       // "location_name" : location.label,
  //       "user_name" : localStorage.getItem("username"),
  //       "useremail" : localStorage.getItem("email"),
  //       "client" : localStorage.getItem("client"),
  //       "screen" : "Trips",
  //       "activity" : 'clicked on consignment information icon',
  //       "event" : "Aggrid opened below the row",
  //       // "data_type" : this.state.data_type,
  //   }

  //   redirectURL.post("/master/loguserUpdatesorChanges",logParams)
  //   let screen_type = pageType;
  //   var params = {
  //       shipment_id: rownode.data.shipment_id
  //   }
  //   var newCommentHeaders = [
  //     {
  //       headerName : "Order No",
  //       field : "consignment_code",
  //       resizable : true,
  //       width:130,
  //       filter : true,
  //     },
  //     {
  //         headerName:"Shipment Status",
  //         field:"status",
  //         width:130,
  //         resizable : true,
  //         //pinned:'left',
  //         valueGetter:function(params){
  //   // console.log('hit')
  //   try {
  //     var status = "Shipment Created"
  //     // console.log('hit')
  //     if(screen_type == 'Delivered' || screen_type == "distanceInCustomerServingData" || screen_type == "dropSequenceData" || screen_type == "distanceDeviationData")
  //     {
  //       console.log('hit delivered trucks')
  //       const numericArray = params.data.status_list.map(Number);
  //       const maxValue = Math.max(...numericArray);
        
  //       if (maxValue == 12 ) {
  //         status = "Left Customer";
  //       }
  //       if (maxValue == 13 ) {
  //         status = "Possible Trip Closure";
  //       }
  //       if (maxValue == 14 ) {
  //         status = "Proximity Trip Closure";
  //       }
  //       if (maxValue == 15 ) {
  //         if(params.data.dealer_not_served == 1){
  //           status ="Dealer Not Served"
  //         }else{
  //           status = "Reached Plant";
  //         }
          
  //       }
  //       if (params.data.status == 6 ) {
  //         status = "Force Closure";
  //       }
  //     }
  //     else
  //     {
  //       // console.log('params.data.status',params.data.status)
  //       if (params.data.status == 1 || params.data.status == "1") {
  //         status = "Shipment Created";
  //       }
  //       // if (params.data.status == 2 || params.data.status == "2") {
  //       // 	// status = "Intransit To Collection Center";
  //       // 	status = "Intransit";
  //       // }
  //       if (params.data.status == 3 || params.data.status == "3") {
  //         // status = "Intransit To Customer";
  //         status = "Inside Plant";
  //       }
  //       if (params.data.status == 4 || params.data.status == "4") {
  //         // status = "Possible Customer Location";
  //         status = "Inside Loading Yard";
  //       }
  //       if (params.data.status == 5 || params.data.status == "5") {
  //         // status = "Near Customer Location";
  //         status = "Outside Loading Yard";
  //       }
  //       if (params.data.status == 6 || params.data.status == "6") {
  //         status = "Force Closure";
  //       }
  //       // if (params.data.status == 7 || params.data.status == "7") {
  //       // 	status = "Reached Dispatch Center";
  //       // }
  //       if (params.data.status == 8 || params.data.status == "8") {
  //         status = "In Transit to Customer";
  //       }
  //       if (params.data.status == 9 || params.data.status == "9") {
  //         status = "Proximity Customer Location";
  //       }
  //       if (params.data.status == 10 || params.data.status == "10") {
  //         status = "Near Customer Vicinity";
  //       }
  //       if (params.data.status == 11 || params.data.status == "11") {
  //         status = "Reached Customer";
  //       }
  //       if (params.data.status == 12 || params.data.status == "12") {
  //         status = "Left Customer";
  //       }
  //       if (params.data.status == 13 || params.data.status == "13") {
  //         status = "Possible Trip Closure";
  //       }
  //       if (params.data.status == 14 || params.data.status == "14") {
  //         status = "Proximity Trip Closure";
  //       }
  //       if (params.data.status == 15 || params.data.status == "15") {
  //         if(params.data.dealer_not_served == 1){
  //           status ="Dealer Not Served"
  //         }else{
  //         status = "Reached Plant";
  //         }
  //       }
  //     }
  //       return status
  //     }
  //     catch(e){}
  //     }
  //     },
  //     {
  //       headerName : "CD Status",
  //       field : "cd_status",
  //       resizable : true,
  //       width:110,
  //       filter : true,
  //       valueGetter:(params)=>{
  //         try {
  //           var status = "Shipment Created"
  //           if (params.data.cd_status === 1 || params.data.cd_status === '1') {
  //             status = "Shipment Created";
  //           }
  //           // if (params.data.cd_status == 2 || params.data.cd_status == "2") {
  //           // 	// status = "Intransit To Collection Center";
  //           // 	status = "Intransit";
  //           // }
  //           if (params.data.cd_status === 3 || params.data.cd_status === '3') {
  //             // status = "Intransit To Customer";
  //             status = "Inside Plant";
  //           }
  //           if (params.data.cd_status === 4 || params.data.cd_status === '4') {
  //             // status = "Possible Customer Location";
  //             status = "Inside Loading Yard";
  //           }
  //           if (params.data.cd_status === 5 || params.data.cd_status === '5') {
  //             // status = "Near Customer Location";
  //             status = "Outside Loading Yard";
  //           }
  //           if (params.data.cd_status === 6 || params.data.cd_status === '6') {
  //             status = "Force Closure";
  //           }
  //           // if (params.data.cd_status == 7 || params.data.cd_status == "7") {
  //           // 	status = "Reached Dispatch Center";
  //           // }
  //           if (params.data.cd_status === 8 || params.data.cd_status === '8') {
  //             status = "In Transit to Customer";
  //           }
  //           if (params.data.cd_status === 9 || params.data.cd_status === '9') {
  //             status = "Proximity Customer Location";
  //           }
  //           if (params.data.cd_status === 10 || params.data.cd_status === '10') {
  //             status = "Near Customer Vicinity";
  //           }
  //           if (params.data.cd_status === 11 || params.data.cd_status === '11') {
  //             status = "Reached Customer";
  //           }
  //           if (params.data.cd_status === 12 || params.data.cd_status === '12') {
  //             status = "Left Customer";
  //           }
  //           if (params.data.cd_status === 13 || params.data.cd_status === '13') {
  //             status = "Possible Trip Closure";
  //           }
  //           if (params.data.cd_status === 14 || params.data.cd_status === '14') {
  //             status = "Proximity Trip Closure";
  //           }
  //           if (params.data.cd_status === 15 || params.data.cd_status === '15') {
  //             status = "Reached Plant";
  //           }
          
  //           return status
  //         }
  //         catch(e){}
  //       }
  //     },
  //     {
  //       headerName : "Plant Name",
  //       field : "consigner_name",
  //       resizable : true,
  //       width:160,
  //       filter : true,
  //     },
  //     {
  //       headerName : "Trip Sequence",
  //       field : "drop_sequence",
  //       resizable : true,
  //       width:120,
  //       filter : true,
  //     },
  //     {
  //       headerName : "Dealer Code",
  //       field : "consignee_code",
  //       resizable : true,
  //       width:120,
  //       filter : true,
  //     },
  //     {
  //       headerName : "Dealer Name",
  //       field : "consignee_name",
  //       resizable : true,
  //       width:140,
  //       filter : true,
  //     },
  //     {
  //       headerName : "Quantity (Cases)",
  //       field : "demand_quantity",
  //       resizable : true,
  //       width:130,
  //       filter : true,
  //       valueGetter:function(params){
  //         if(params.data.demand_quantity!==undefined && params.data.demand_quantity!==''){
  //           return (params.data.demand_quantity).toFixed(2)
  //         }else{
  //           return 'N/A'
  //         }
          
  //         console.log(params.data.demand_quantity)
  //       }
  //     },
  //     {
  //       headerName : "Weight (Pounds)",
  //       field : "demand_weight",
  //       resizable : true,
  //       width:110,
  //       filter : true,
  //     },
  //     {
  //       headerName : "Volume (CFT)",
  //       field : "demand_volume",
  //       resizable : true,
  //       width:115,
  //       filter : true,
  //     },
  //     {
  //       headerName : "Reached Customer At",
  //       field : "recent_dealer_reported",
  //       resizable : true,
  //       width:170,
  //       filter : true,
  //       valueGetter:function(params){
  //         return getHyphenDDMMMYYYYHHMM(params.data.recent_dealer_reported);
  //       }
  //     },
  //     {
  //       headerName : "Left Customer At",
  //       field : "left_dealer_marked",
  //       resizable : true,
  //       width:150,
  //       filter : true,
  //       valueGetter:function(params){
  //         return getHyphenDDMMMYYYYHHMM(params.data.left_dealer_marked);
  //       }
  //     },
  //     {
  //       headerName : "Expected ETA",
  //       field : "drop_node_total_time_hrs",
  //       resizable : true,
  //       width:150,
  //       filter : true,
        
  //     },
  //     {
  //       headerName : "Unloading Duration",
  //       field : "unloading_time_in_hrs",
  //       resizable : true,
  //       width:150,
  //       filter : true,
        
  //     },
  //     {
  //       headerName : "Actual Sequence No",
  //       field : "actual_sequence_no",
  //       resizable : true,
  //       width:150,
  //       filter : true,
        
  //     }
  //   ]
  //   setDetailedCellRendererParams({
  //     detailCellRendererParams:{
  //         detailGridOptions: {
  //         columnDefs: newCommentHeaders,
  //         overlayNoRowsTemplate: 'No rows to show',
  //         defaultColDef: defaultColDef,
  //         statusBar: statusBar,
  //         sideBar: sideBar,
  //         enableRangeSelection:true
  //       },
  //       getDetailRowData: async function (param) {
  //         var leg_details=data.leg_details
  //         var legDetails=JSON.parse(leg_details)
  //         param.successCallback(legDetails);
  //         await redirectURL.post("/tracking/getConsignmentsData", params)
  //         .then((response) => {
  //           if (response.status === 200) {
  //       		console.log("legs response",response.data)
  //           param.successCallback([response.data]);
  //             }
  //             else {
  //                 console.log("error")
  //             }
  //         })
  //         .catch((err)=>{
  //       			var errDetails={
  //       				url:"/tracking/getConsignmentsData",
  //       				screen:'Trips',
  //       				err,
  //       			}
  //       			redirectURL.post("/master/logErrorsForApiCalls",errDetails)              
  //       		})
      
  //       },
  //       masterDetail: true
  //     }
  //   })
  //   // console.log(propsdata);
  //   if(rownode.column.colDef.field == 'consignmentinformation')
  //   {

  //       rownode.node.setExpanded(!rownode.node.expanded);
  //   }
  //   else{

  //       rownode.node.setExpanded(false);
  //   }
  // }
  function onCloseUploadDiv (params){
		console.log("onCloseUploadDiv");
    // setUploadDivWidth('0%');
    // setSliderTranslate("");
    // setSideBar("");
    // setTabSliderTranslate("");
    // setShowDiv("show-n");
    // setUploadFile("");
    // setFile("");
    // setCsvMessage("");
    // setOverly('show-n');
	}
  function onCloseRouteDiv (params){
    console.log("onCloseRouteDiv")
    setUploadDivWidth("0%");
    setSlideRouterTranslate("");
    setShowDiv("show-n");
    setSliderCommentTranslate("");
    setUploadFile("");
    setFile("");
    setCsvMessage("");
    setOverly('show-n');
	}
  const closeAlert = () => {
    setShow(false);
    setLoadshow('show-n')
    // setOverlyRing('show-n')
		
		// window.location.reload()
	}
  const handle_base_route = () => {
    setBaseRouteShow((prevState) => !prevState.base_route_show)
  }
  return (
    <>
      <div className="row mb-20p mt-0">
      <SweetAlert
					show={show}
					type={basicType}
					title={basicTitle}
					onConfirm={closeAlert}
				>
			</SweetAlert>
        <div className="col-sm-12">
          <h5 className="fbold  d-flex justify-content-between">
            <span>Deviation Reports</span>
          </h5>
        </div>
        <div className="row col-xl-12 col-lg-12">
	          <form className="row theme-form col-md-12 " onSubmit={formHandler}>
              <div className="col-md-2 col-sm-12">
            <div className="form-group ">
              <label className="col-form-label f12">Pickup Locations</label>
              <Select
                placeholder={"Select"}
                onChange={locationChanged}
                name="location"
                value={location}
                options={locationNames}
              />
            </div>
              </div>
              <div className="col-md-2 form-group">
                  <label className="c-lbl fbold">Start Date</label>
                  <input
                      type="date"
                      className="form-control"
                      name="startData"
                      id="startData"
                      autoComplete="off"
                      onKeyDown={(e) => e.preventDefault()}
                      style={{ borderRadius: "0px" }}
                      max={getCurrentDateTime()}
                      onChange={onChangeStartDate}
                      value={startDate}
                      readonly
                  />
              </div>
              <div className="col-md-2 form-group">
                  <label className="c-lbl fbold">End Date</label>
                  <input
                      type="date"
                      className="form-control"
                      name="endDate"
                      id="endDate"
                      autoComplete="off"
                      style={{ borderRadius: "0px" }}
                      onKeyDown={(e) => e.preventDefault()}
                      max={getCurrentDateTime()}
                      onChange={onChangeEndDate}
                      value={endDate}
                      readonly
                  />
              </div>
              <div className="col-xl-2 col-lg-2  form-group">
                      <label></label>
                <button type="submit" className="btn btn-success" style={{marginTop:"3em"}}>Generate</button>
              </div>
            </form>
        </div>
        <div className="col-md-12 col-sm-12 mt-33p">
          <ul className="d-tabs">
              <li onClick={(e) => onClickTab("distanceDeviationData")} className={"lttabs "+(distanceDeviationTab)}>
                  <button type="button" className="btn">Deviation Distance</button>
              </li>              
              <li onClick={(e) => onClickTab("dropSequenceData")} className={"lttabs "+(dropSequenceTab)}>
                  <button type="button" className="btn">Deviation In Drop Sequence</button>
              </li>
              
              <li onClick={(e) => onClickTab("distanceInCustomerServingData")} className={"lttabs "+(distanceInCustomerServingTab)}>
                  <button type="button" className="btn">Deviation In Customer Serving</button>
              </li>
          </ul>
      </div>
      <div className="col-xl-12 col-lg-12">
        <div className="card">
          <div className="card-body pt-10px" >
            <div className="row">
              <div className="col-xl-12 col-lg-12 mb-10p">
                {(localStorage.getItem('roles')!="CUSTOMER") ?
                  <span className="float-right" style={{ fontSize: "13px", fontWeight: "100" }}>
                    {/* <i className="fa fa-globe f20" style={{ fontSize: "22px" }}></i> - View Route */}
                    <img style={{ height: "18px" }} src={require("../../assets/icons/distance.png")} /> - View Route
                    {/* <img style={{ height: "18px" }} src={require("../../assets/icons/distance")} /> - View Route */}
                    &nbsp;
                    {/* <i className="fa fa-clock-o" style={{ color: "#ff0000", fontSize: "22px" }}></i> - Transit Delay */}
                    <i className="fa fa-info-circle" style={{ color: "#17a2b8", fontSize: "22px" }}></i> - Consignment Information
                    {/* &nbsp;<i className="fa fa-map-marker txt-danger" style={{ fontSize: "22px" }}></i> - GPS Data Not Available
                    &nbsp;<i className="fa fa-map-marker txt-secondary" style={{ fontSize: "22px" }}></i> - GPS Data Not Adequate */}
                    {/* &nbsp;<i className="fa fa-tachometer txt-success" style={{ fontSize: "22px	" }}></i> - Overspeed
                    <span>&nbsp;<i className="fa fa-moon-o txt-primary" style={{ fontSize: "22px" }}></i> - Night Driving</span> */}
                    {/* <span>&nbsp;<i className="icofont icofont-list" style={{ fontSize: "22px" }}></i> - Day Wise Distance Trip
                    </span> */}
                  </span>
                :""}
              </div>
              {/* <div className="col-xl-12 mb-10p">
                <span className="layoutbtns">
                  <button className="float-right custom-btn white btn-danger" style={{ marginRight: "10px" }}  onClick={this.onClickSaveGridState.bind(this)}>
                    <i className="icofont icofont-save"></i> Save Grid Layout
                  </button>
                  <button className="float-right custom-btn white btn-info" style={{ marginRight: "10px" }} onClick={this.resetState.bind(this)}>
                    <i className="icofont icofont-refresh"></i> Reset Default Layout
                  </button>
                  {(localStorage.getItem("user_type") == "TECHUSER")  ?
                  <button className="float-right custom-btn white btn-warning" style={{ marginRight: "10px" }} onClick={this.onClickCreateshipment.bind(this)}>
                    Create Shipment
                  </button>
                  :""}
                    
                    <button className={"float-right custom-btn white btn btn-secondary " + this.state.alltrucksBtnShow} type="button"  style={{ marginRight: "10px" }} onClick={this.showAllTrucks.bind(this)}> Show All Trucks
                    </button>
                </span>
              </div> */}
            </div>
            <div id="myGrid" style={{ height: "580px", width: "100%" }} className="ag-theme-balham">
              {/* {console.log('hit',this.state.rowData)} */}
              <AgGridReact
                // modules={this.state.modules}
                columnDefs={columnwithDefs}
                defaultColDef={defaultColDef}
                rowData={rowData}
                enableCharts={true}
                enableRangeSelection={true}
                // onGridReady={onGridReady}

                ref={gridRef}
                // onGridState={onGridState}
                statusBar={statusBar}
                sideBar={sideBar}
                paginationPageSize={paginationPageSize}
                pagination={true}
                frameworkComponents={frameworkComponents}
                detailCellRendererParams={detailCellRendererParams}

                stopEditingWhenGridLosesFocus={true}
                //floatingFilter={true}
                enableCellChangeFlash={true}
                suppressCellFlash={true}
                gridOptions={{
                  context: { componentParent: this }
                }}
                masterDetail={true}
                // rowSelection={this.state.rowSelection}
                onRowSelected={onRowSelection}
              />


            </div>
          </div>
        </div>
      </div>
      {sliderRouteTranslate != '' ?
					<div className={"slide-r " + (sliderRouteTranslate)} style={{ overflow: "auto" }} >
						<div className="slide-r-title">
							<h4>
								{(consignment_code)}
								<span className="float-right closebtn" style={{ marginRight: "25px",cursor:"pointer" }} onClick={onCloseRouteDiv} >X</span>

							</h4>
						</div>
						<div className="slide-r-body" style={{ position: "relative" }}>
							<div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
								<DrawMap
									mapFor={"truck"}
									consignercords={consigner_coordinates}
									tolls={tolls}
									consigneecords={consignee_coordinates}
									baseRouteCoords={baseRouteCoords}
									rownode={rownode}
									truckno={maptruckno}
									dealer={dealer}
									mapinfo={mapinfo}
									defTransitCoords={defTransitCoords}
									base_route_show={base_route_show}
									handle_base_route={handle_base_route} />
								<div className="col-xl-12 col-lg-12 n-p-0">
									<div className="crm-numbers pb-0">
										<h3 className="subH">{sidebarSubHeader}</h3>
										<div className="col-xl-12 col-lg-12 row">
											<div className="col route-block">
												<label className="sidebar-label">Truck No</label>
												<div>{routeTruck.truck_no}</div>
											</div>
											<div className="col route-block">
												<label className="sidebar-label">GPS Start Time</label>
												<div>{(routeTruck.startTime != '') ? getDDMMYYYYHHMMSS(routeTruck.startTime) : "NA"}</div>
											</div>
											<div className="col route-block">
												<label className="sidebar-label">GPS End Time</label>
												<div>{(routeTruck.endTime != '') ? getDDMMYYYYHHMMSS(routeTruck.endTime) : "NA"}</div>
											</div>

											<div className="col route-block">
												<label className="sidebar-label">Distance</label>
												<div>{(!isEmpty(mapinfo.route_details)) ? (Math.round(mapinfo.route_details.distance / 1000)*0.621).toFixed(0) : "0"} Miles</div>
											</div>

											<div className="col route-block">
												<label className="sidebar-label">Avg Temparature</label>
												<div>{(!isEmpty(mapinfo.route_details)) ? (mapinfo.route_details.average_temperature).toFixed(2) : "0"} °C</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					: ""
				}
      </div>
      {/* <div className={"loader " + loadshow}></div> */}
      <div className={"dataLoadpageimg " + (loadshow)}>
        <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
      </div>
      <div className={"overlay-part " + (overly)} onClick={onClickHideAll}></div>
      {/* <div className={"overlay-part "+(overlyRing)} onClick={hideSlideBlock}></div> */}
    </>
  );
}
function GetSortDescOrder(prop) {    
	return function(a, b) {    
		if (a[prop] < b[prop]) {    
			return 1;    
		} else if (a[prop] > b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 
function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
		// console.log(date1,date2);
		var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
		date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
		var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
		date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
		// console.log(date1,date2);
		var date1Number = monthToComparableNumber(date1);
		var date2Number = monthToComparableNumber(date2);
		// console.log(date1Number,date2Number);
		if (date1Number === null && date2Number === null) {
			return 0;
		}
		if (date1Number === null) {
			return -1;
		}
		if (date2Number === null) {
			return 1;
		}
		return date1Number - date2Number;
	}
}
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	// console.log(yearNumber, monthNumber, dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName) {
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
	];
	var monthNum = months.indexOf(monthName) + 1;
	if (monthNum < 10) {
		monthNum = "0" + monthNum.toString();
	}
	return monthNum;
}
function isEmpty(obj) {
	for (var key in obj) {
		if (obj.hasOwnProperty(key))
			return false;
	}
	return true;
}