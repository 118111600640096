import React, { Component } from "react";
import { AllModules } from "@ag-grid-enterprise/all-modules";
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-balham.css"; // Optional theme CSS
import "ag-grid-enterprise";
import { withTranslation } from "react-i18next";
import redirectURL from "../redirectURL";
import GridButton from "../optirun-admin/gridButton";
import { getHyphenDDMMMYYYY } from "../common/utils";


class GridInPlanManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modules: AllModules,
      defaultColDef: {
        sortable: true,
        filter: true,
        editable: false,
        resizable: true,
        minWidth: 70,
        initialWidth: 200,
        wrapHeaderText: true,
        autoHeaderHeight: true,
      },
      frameworkComponents: {
        GridButton: GridButton,
      },
      rowData: [],
      rowSelection: "multiple",
      rowModelhighchartoptionsType: "enterprise",
      rowGroupPanelShow: "always",
      detailCellRendererParams: {},
      statusBar: {
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left",
          },
          {
            statusPanel: "agTotalRowCountComponent",
            align: "center",
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" },
        ],
      },
      paginationPageSize: 100,
      detailCellRendererParams: {},
      rowClassRules: {
        //"highlitRow": "data.transit_delay == 1",
        //"green":"data.status >= 3"
      },
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
          },
          {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel",
          },
        ],
      },
      animateRows: true,
    };
  }
  componentDidMount() {
    this.setState({ rowData: this.props.data });
  }
  linkCellRenderer = (params) => {
    if (params.value && params.value.length > 0) {
      // Check if transIds array for the current row is not empty
      return params.value.map((transaction_id, index) => {
        const encryptedstring = window.btoa(transaction_id);
        const url =
          window.location.origin +
          "/viewdispatchplandetails?" +
          encryptedstring;
        return (
          <a
            href={url}
            target="_blank"
            key={index}
            style={{
              fontWeight: "bold",
              textDecoration: "none",
            }}
          >
            <span style={{ textDecoration: "underline" }}>
              {transaction_id}
            </span>
            &nbsp;
          </a>
        );
      });
    } else {
      // Return an empty string if transIds array is empty
      return "";
    }
  };
  componentWillReceiveProps(newprops) {
    this.setState({ rowData: newprops.data });
  }
  // onShowLegsData = async (row) => {
  //   // console.log("clicked on view", row);
  //   var detailColdef = [
  //     {
  //       headerName: t('plant_code'),
  //       field: "pickup_node",
  //       width: 130,
  //     },
  //     {
  //       headerName: t('plant_name'),
  //       field: "location_name",
  //       width: 130,
  //     },
  //     {
  //       headerName: t('total_trips'),
  //       field: "totalplans",
  //       width: 130,
  //     },
  //     {
  //       headerName: t('trips_edited'),
  //       field: "plansEdited",
  //       width: 130,
  //     },
  //     {
  //       headerName: t('edited_transaction_ids'),
  //       field: "transIds",
  //       width: 230,
  //       cellRenderer: this.linkCellRenderer,
  //     },
  //     {
  //       headerName: t('trips_removed'),
  //       field: "plansRemvd",
  //       width: 130,
  //     },
  //     {
  //       headerName: t('removed_transaction_ids'),
  //       field: "remvdTrans",
  //       width: 230,
  //       cellRenderer: this.linkCellRenderer,
  //     },
  //   ];
    
  //   await this.setState({
  //     detailCellRendererParams: {
  //       detailGridOptions: {
  //         columnDefs: detailColdef,
  //         overlayNoRowsTemplate: "No rows to show",
  //         defaultColDef: this.state.defaultColDef,
  //         statusBar: this.state.statusBar,
  //         sideBar: this.state.sideBar,
  //         enableRangeSelection: true,
  //       },
  //       getDetailRowData: async function (param) {
  //         // console.log("param ", param.data);
  //         param.successCallback(row.data.legDetails);
  //       },
  //       masterDetail: true,
  //     },
  //   });
  //   if (row.colDef.field == "details") {
  //     row.node.setExpanded(!row.node.expanded);
  //   } else {
  //     row.node.setExpanded(false);
  //   }
  // };
  render() {
    const { t } = this.props;
    const onShowLegsData = async (row) => {
      // console.log("clicked on view", row);
      var detailColdef = [
        {
          headerName: t('plant_code'),
          field: "pickup_node",
          width: 130,
        },
        {
          headerName: t('plant_name'),
          field: "location_name",
          width: 130,
        },
        {
          headerName: t('total_trips'),
          field: "totalplans",
          width: 130,
        },
        {
          headerName: t('trips_edited'),
          field: "plansEdited",
          width: 130,
        },
        {
          headerName: t('edited_transaction_ids'),
          field: "transIds",
          width: 230,
          cellRenderer: this.linkCellRenderer,
        },
        {
          headerName: t('trips_removed'),
          field: "plansRemvd",
          width: 130,
        },
        {
          headerName: t('removed_transaction_ids'),
          field: "remvdTrans",
          width: 230,
          cellRenderer: this.linkCellRenderer,
        },
      ];
      
      await this.setState({
        detailCellRendererParams: {
          detailGridOptions: {
            columnDefs: detailColdef,
            overlayNoRowsTemplate: "No rows to show",
            defaultColDef: this.state.defaultColDef,
            statusBar: this.state.statusBar,
            sideBar: this.state.sideBar,
            enableRangeSelection: true,
          },
          getDetailRowData: async function (param) {
            // console.log("param ", param.data);
            param.successCallback(row.data.legDetails);
          },
          masterDetail: true,
        },
      });
      if (row.colDef.field == "details") {
        row.node.setExpanded(!row.node.expanded);
      } else {
        row.node.setExpanded(false);
      }
    };
    var colDefs = [
      {
        headerName: t('details'),
        field: "details",
        width: 120,
        filter: true,
        resizable: true,
        editable: false,
        params: {
          buttonName: t('view'),
          iconName: "fa fa-eye",
          onClickFunction: onShowLegsData,
        },
        cellRendererSelector: function (params) {
          var rendComponent = {
            component: "GridButton",
          };
          return rendComponent;
        },
      },
      {
        headerName: t('date'),
        field: "createdDate",
        width: 120,
        valueGetter: (params) => {
          if (
            params.data.createdDate !== undefined &&
            params.data.createdDate !== ""
          ) {
            return getHyphenDDMMMYYYY(params.data.createdDate);
          } else {
            return "N/A";
          }
        },
      },
      {
        headerName: t('total_trips'),
        field: "totalplans",
        width: 140,
      },
      {
        headerName: t('trips_edited'),
        field: "editplans",
        width: 140,
      },
      {
        headerName: t('trips_removed'),
        field: "plansRemoved",
        width: 140,
        // valueGetter:()=>{
        //   return 0
        // }
      },
      {
        headerName: t('trips_edit_percentage'),
        field: "editPerctg",
        width: 140,
      },
    ];
    return (
      <>
        <div className="col-sm-12">
          {/* <h5 style={{ marginLeft: "8px" }}>Recent Plans</h5> */}
          <div
            id="myGrid"
            style={{ width: "100%", height: "80vh", marginLeft: "0px" }}
            className="col-sm-12 ag-theme-balham"
          >
            <AgGridReact
              rowData={this.state.rowData}
              columnDefs={colDefs}
              gridOptions={{ context: { componentParent: this } }}
              statusBar={this.state.statusBar}
              sideBar={this.state.sideBar}
              enablePivot={true}
              defaultColDef={this.state.defaultColDef}
              frameworkComponents={this.state.frameworkComponents}
              paginationPageSize={this.state.paginationPageSize}
              pagination={true}
              enableRangeSelection={true}
              // onCellClicked={this.onCellClickedGrid.bind(this)}
              masterDetail={true}
              detailCellRendererParams={this.state.detailCellRendererParams}
              rowClassRules={this.state.rowClassRules}
            />
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(GridInPlanManagement)
