import React from "react";
import { useTranslation } from "react-i18next";
import Highcharts, { color } from "highcharts";
import HighchartsReact from "highcharts-react-official";


function PieChartwithpercentage(props) {
    console.log(props.data,'pie chart')
    const {t} = useTranslation()
    const translatedData = props.data.map(item => ({
        ...item,
        name: t(item.name), // Translate the name using t()
    }));

    const highchartoptions = {
        chart: {
            // plotBackgroundColor: null,
            // plotBorderWidth: null,
            // plotShadow: false,
            type: "pie",
            height: "70%",
            padding:[1,1,1,1],
            margin:[1,1,1,1],
        },
        title: {
            text: props.title,
            align:"center",
            style: {
                color: 'black',
                fontSize: '11px', 
                fontWeight: 'bold', 
                fontFamily: 'sans-serif',
                marginBottom:"3px"            
            }
        },
        tooltip: {
            useHTML: true,
            formatter: function() {
                var total = 0;
                this.series.chart.series[0].points.forEach(function(point) {
                    total += point.y;
                });
                
                var tooltipText = "<div>";
                this.series.chart.series[0].points.forEach(function(point, index) {
                    var percentage = Math.round((point.y / total) * 100);
                    tooltipText += '<span style="color:' + point.color + '">' + point.name + ': ' + point.y + ' (' + percentage + '%)</span><br>';
                });
                tooltipText += "</div>";
                
                return tooltipText;
            }
        },    
        // accessibility: {
        //     point: {
        //       valueSuffix: "%",
        //     },
        //   },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    distance: -40,
                    format: "{point.name}: <b>{point.percentage:.1f}%</b>",
                    style: {
                        fontSize: '10px',
                        textOutline: 'none',
                        opacity: 0.7,
                        color:"white"
                    },
                    filter: {
                        operator: '>',
                        property: 'percentage',
                        value: 10
                    }
                },
                showInLegend: true,
                size:"55%",
            }
        },
        series: [
            {
                name: t("Percentage"),
                colorByPoint: true,
                data: translatedData, // Pass data from props
              },           
        ],
        colors: [ "#4dacd1", "#0f6080","#4ca688","#6ae6bc","#517a8a"],
        credits: false,
        legend: {
            layout: "horizontal",
            align: "center",
            verticalAlign: "bottom",
            itemStyle: {
                fontSize: '8px',
                marginTop:"8px"
            }                      
        },
    };

    return (
        <div>
        <HighchartsReact highcharts={Highcharts} options={highchartoptions} />
      </div>
    )
}

export default PieChartwithpercentage;




