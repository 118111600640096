import React, {Component} from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import $ from 'jquery';
import 'jquery-ui';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import 'jquery-ui/themes/base/datepicker.css';
import Select from 'react-select';
import Datetime from 'react-datetime';
import Modal from 'react-responsive-modal';
import i18n from 'i18next';
import 'react-datetime/css/react-datetime.css'
import SweetAlert from 'react-bootstrap-sweetalert';
import Constant from "../common/constants";
import {AllModules} from '@ag-grid-enterprise/all-modules';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
import 'ag-grid-enterprise';
import CSVFileValidator from 'csv-file-validator';
import PapaParse from 'papaparse';
import { getHyphenDDMMMYYYYHHMM, getHyphenDDMMMYYYY} from '../common/utils'; 
import {verifyext} from "../common/verifyext";
import getToolConfiguration from "./commonFunctions";
import redirectURL from '../redirectURL';
import GridButton from './gridButton';
import NAButton from './naButton';
import StaticButton from './static-button';
import FlagIcon from "./fontFlag";
import OrderButton from "./orderviewbtn";
import PlanFollowedButton from "./planfollowbtn";
import CountDownTimer from './timerComponent';
import vehicleAssigned from './vehicleAssigned';
import * as XLSX from 'xlsx';
import Consolidatedbtn from "./consolidatedbtn.js";
import { withTranslation  } from 'react-i18next';
import '../../i18n.js'


var infoBox = require('../common/google-infowindow');
const scaleanimation = {
  transition: 'all 1s easy-out'
}

var moment = require("moment");
var markersArray;
var map;
var mapcluster;

var overlay;
var infowindows=[];
var markersArraylist=[];

class LoadProcessRun extends Component {
    constructor(props){
        super(props);
        this.state = {
            modules : AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
                resizable:true,
                minWidth:100,
                initialWidth:200,
                wrapHeaderText: true,
                autoHeaderHeight: true,
            },
            frameworkComponents:{
                GridButton:GridButton,
                NAButton:NAButton,
                FlagIcon:FlagIcon,
                OrderButton:OrderButton,
                PlanFollowedButton:PlanFollowedButton,
                vehicleAssigned:vehicleAssigned,
                Consolidatedbtn:Consolidatedbtn
            },
            rowData:[],            
            sliderRso: "",
            latestData:[],
            selectedFile: null,
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
			detailCellRendererParams:{},
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:1000,
            loadFile: "",
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            animateRows: true,
            showSlideBlockRoute:"sidebarcls",
            showSlideBlockUpload:"sidebarcls",
            sliderRso: "sidebarcls",
            showSlideBlockStack:"sidebarcls",
            overlayBlock:"show-n",
            routeData:[],
            rfreshmap:1,
            csvcontent: [],
            deliverpickupslist:[],
            dealers:[],
            plants:[],
            uploadfile:'',        
            alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
            loadplan:[],
            allLoadPlan:[],
            deliverydates:[],
            deliveryopts:[],
            filterDeliveryDate:{"value":"","label":"Select Delivery Date"},
            clusterlist:[],
            filtercluster:{"value":"","label":"Select Cluster"},
            vehicletypelist:[],
            filterVehicleType:{"value":"","label":"All"},
            mapcol:"show-n",
            gridocl:"col-sm-12",
            filter_date:"",
            vtypediv:"show-n",
            vsupplierdiv:"show-n",
            selectedCluster:"",
            fdealerlist:[],
            filterdealer:{"value":"","label":"All"},
            showloader:"show-n",
            mpnode:[],
            mpfor:"",
            clusterpoints:[],
            nmapcluster:"show-n",
            nmap:"show-m",
            scluster:"",
            stackContent:"",
            stackImage:"",
            stacktbl:[],
            stacktblbottom:[],
            stackOriginalData:[],
            showrunprocessbtn:"show-m",
            showanimate:"show-n",
            locationNames:[],
            loadFile:'',
            material_configurations:0,
            location:{label:"Select Location",value:""},
            loadshow:'show-m',
            overly:'show-m',
            processErrMsg : "",
            processErrShow : "",
            transactionId : "",
            droppedList : [],
            inputDetails:[],
            tool_config:[],
            defFlag:0,
            planRowData:[],
            openSLA:false,
            sla:{value:"",label:"Select"},
            allTransactionIds:[],
        }
        this.getDispatchPlanData = this.getDispatchPlanData.bind(this);
    }
    async componentDidMount() {
        $( ".datepicker" ).datepicker({
            dateFormat:"dd-mm-yy",
            changeYear:true
        });
        // $(".datepicker").val(moment.parseZone().format("DD-MM-YYYY"))
        let url_type = window.location.href
        // console.log(url_type,"url_type")
        let path_type = url_type.split('?')
        // console.log(path_type,"path_type")
        let data_type
        let path = path_type[path_type.length-1]

        // console.log(path,"path")
        if (path == 'inbound'){
            data_type = 1
        }else{
            data_type = 2
        }
        await this.setState({data_type : data_type})
        let params = {
            data_type : data_type
        }
        // var locationAcces=JSON.parse(localStorage.getItem('location_access'))
        
        // if (locationAcces!==null){
        //     params.plant_code=locationAcces[0]
        // }
        // console.log(params)
        let toolparameters = {
            data_type : data_type
        }
        await getToolConfiguration(toolparameters)
        
        let toolConfig = JSON.parse(localStorage.getItem('tlcfg'));
        var material_configurations = 0;
        if(toolConfig !=undefined && toolConfig !="")
        {
            if(toolConfig.length > 0)
            {
                material_configurations = toolConfig[0].material_configuration;
            }
        }
        this.setState({
            material_configurations : material_configurations
        });
        await this.boundTypeLocations(params)
        await this.getDispatchPlanData()
        this.intervalId = setInterval(() => {
            this.getPendingTransactions(this.state.latestData)
            }, 60000);
    }

    componentWillUnmount() {
        clearInterval(this.intervalId);
    }

    onClickGetData=async()=>{
        await this.getDispatchPlanData()
        let logParams = {
            "location_code" : this.state.location.value,
            "location_name" : this.state.location.label,
            "user_name" : localStorage.getItem("username"),
            "useremail" : localStorage.getItem("email"),
            "client" : localStorage.getItem("client"),
            "screen" : "Summary Dashboard",
            "activity" : 'clicked on Get Data Button',
            "event" : "Data in aggrid filtered",
            "data_type" : this.state.data_type,
        }
    
        redirectURL.post("/master/loguserUpdatesorChanges",logParams)
    }

    onUploadOBDDetails = async () => {
        this.setState({
            overlayBlock:"show-m",
            sliderRso: "slide25"
        });
    }

    getDispatchPlanData = () =>{
        // console.log('hit')
        this.setState({loadshow:'show-m',
        overlayBlock:'show-m'})
        setTimeout(()=>{
            

            var selectDate = $("#selectDate").val();
            var generateDate = $("#generateDate").val();
            if (generateDate !== undefined && generateDate !== '' && selectDate !== undefined && selectDate !== ''){
                if (selectDate < generateDate){
                this.setState({
                    show:true,
                    basicTitle:"Date Created should be less than or equal to Order Date",
                    basicType:"danger",
                    loadshow:"show-n",
                    overlayBlock:"show-n"
                })
                return
                }
            }
            // moment(req.body.selectDate,"DD-MM-YYYY").format("YYYY-MM-DD")
            if (selectDate !== undefined && selectDate !== '' ){
                selectDate = moment(selectDate,"YYYY-MM-DD").format("DD-MM-YYYY")
            }
            if (generateDate !== undefined && generateDate !== '' ){
                generateDate = moment(generateDate,"YYYY-MM-DD").format("DD-MM-YYYY")
            }
            
            // console.log("this.state.location.value ", 'this.state.locationNames')
            // if(this.state.location.value !== "")
            // {
                try {
                    if(this.state.defFlag === 0)
                    {
                        var params = {
                            data_type : this.state.data_type,
                            location_code : this.state.locationNames[0].value,
                            useremail: localStorage.getItem('email'),
                            client : localStorage.getItem('client'),
                            userType : localStorage.getItem('user_type')
                        }
                    }
                    else{
                        var params = {
                            data_type : this.state.data_type,
                            location_code : this.state.location.value,
                            selectDate:selectDate,
                            useremail: localStorage.getItem('email'),
                            client : localStorage.getItem('client'),
                            userType : localStorage.getItem('user_type'),
                            generateDate
                        }
                    }
                    
                } catch (error) {
                    
                }
                
                redirectURL.post('dispatch/getDispatchPlanData',params)
                .then(async response =>{
                    try {
                        var records = response.data;
                        if(records.length > 0)
                        {

                            var rowData = records.sort(GetSortDescOrder("order_date"));
                            var latestData = [];
                            var otherData = [];
                            var rowDataarr = [];
                            if(rowData.length > 0)
                            {
                                var latestData = await rowData.filter((rd) => rd.latest_run == 1);
                                var otherData = await rowData.filter((rd) => (rd.latest_run == 0 || rd.latest_run == undefined));
                                rowData.map((item) => {
                                    var converoDate = moment.parseZone(item.order_date).format("x");
                                    item.orderpos = converoDate;
                                    rowDataarr.push(item)
                                })
                            }
                            // console.log("rowDataarr ", rowDataarr)
                            if(rowDataarr.length > 0)
                            {
                                rowDataarr = rowDataarr.sort(GetSortDescOrder("orderpos"));
                            }
                            if(latestData.length>0){
                                this.getPendingTransactions(latestData)
                            }
                            
                            this.setState({
                                latestData:latestData,
                                rowData:otherData,
                                loadshow:'show-n',
                                overly:'show-n',
                                overlayBlock:"show-n",
                                defFlag:1
                            })
                        }
                        else{
                            this.setState({
                                rowData:[],
                                loadshow:'show-n',
                                overly:'show-n',
                                overlayBlock:"show-n",
                                defFlag:1
                            })
                        }
                    } catch (error) {
                        
                    }
                    
                })
                .catch(function (error) {
                    var errDetails={
                        url:'dispatch/getDispatchPlanData',
                        screen:'Summary Dashboard',
                        error,
                        
                    }
                    redirectURL.post("/master/logErrorsForApiCalls",errDetails)
                    
                })
            
            // }
            // else{
            //     this.setState({
            //         rowData:[],
            //         loadshow:'show-n',
            //         overly:'show-n',
            //         overlayBlock:"show-n"
            //     })
            // }
            
        },300)
    }

    boundTypeLocations = async(param) => {
      let location_access = JSON.parse(localStorage.getItem("location_access"))
      
      let locationNames = this.state.locationNames;

      const response = await redirectURL.post('master/getUniquePickupNodes',{})
      console.log(response, "response413");
      var distinctPickupNodes = response.data
      const filteredData = distinctPickupNodes.filter(item => item !== "");
      console.log(filteredData, 'filteredData');
      
      
      
      
      await redirectURL.post('master/getBoundTypeLocations',param)
        .then(async (response) =>{
            if(response.data.status == 'success'){
                let boundLocations = response.data.boundLocations
                // locationNames.push({ value : "",
                //     label : "Select" })
                if(boundLocations.length > 0){
                    boundLocations.map(item =>{
                        if(location_access != null){
                            if(location_access.includes(item.location_code)){
                                locationNames.push({ value : item.location_code,
                                                    label : item.location_name })

                                }
                            }else{
                                locationNames.push({ value : item.location_code,
                                                    label : item.location_name })
                            }
                    })

                    if(locationNames.length>0){
                        console.log(locationNames, "locationNames");
                        const filteredData2 = locationNames.filter(item => filteredData.includes(item.value));

                        console.log(filteredData2, "filteredData2");
                        
                        await this.setState({
                            locationNames : filteredData2,
                            location : filteredData2[0]
                        })
                    }else{
                        await this.setState({
                            locationNames : [{label:'Please Add Data',value:'no_data'}],
                            location : [{label:'Please Add Data',value:'no_data'}]
                        })
                    }
                }
            }
        })
        .catch(function (error) {
            var errDetails={
                url:'master/getBoundTypeLocations',
                screen:'Summary Dashboard',
                error,
                
            }
            redirectURL.post("/master/logErrorsForApiCalls",errDetails)
            
        })
    
        
    }

    closeAlert = () => {
        this.setState({
            show: false
        });
    }
    onSubmitBulkUpload = (event) => {
        event.preventDefault()
        let modifiedUploadedDetails = this.state.csvcontent.filter((e)=> e.location_code === this.state.location.value).map((e)=>{
            return {
                created_date: getHyphenDDMMMYYYYHHMM(new Date()),
                ...e,
                is_processed: 0
            }
        })
        // console.log(modifiedUploadedDetails,"modifiedUploadedDetails")
        redirectURL.post("master/uploadOBDDetails", modifiedUploadedDetails)
        .then((resp)=>{
            // console.log(resp.data,"response after uploading");
            if(resp.data.status === "success"){                
                document.getElementById("uploadfile").value = null
                this.setState({
                    show:true,
                    basicTitle:resp.data.message,
                    basicType:"success",
                    sliderRso:"",
                    overlayBlock:"show-n"
                })
            } else {
                this.setState({
                    show:true,
                    basicTitle:resp.data.message,
                    basicType:"danger",
                    sliderRso:"",
                    overlayBlock:"show-n"
                })        
                document.getElementById("uploadfile").value = null
            }
        })
        .catch(function (error) {
            var errDetails={
                url:'master/uploadOBDDetails',
                screen:'Summary Dashboard',
                error,
                
            }
            redirectURL.post("/master/uploadOBDDetails",errDetails)
            
        })
    }
    changeFileHandlerFc = async (e) => {
        let fileExtension = e.target.files[0].name.split(".").pop();
        if (fileExtension === "xlsx" || fileExtension === "csv") {
            this.setState({
                selectedFile: e.target.files[0]
            }, () => {
                if (this.state.selectedFile) {
                    if (fileExtension === "xlsx") {
                        const fileReader = new FileReader();
                        fileReader.onload = (event) => {
                            const data = new Uint8Array(event.target.result);
                            const workbook = XLSX.read(data, { type: 'array' });
                            const csvData = XLSX.utils.sheet_to_csv(workbook.Sheets[workbook.SheetNames[0]]);
                            this.validateCSVData(csvData);
                        };
                        fileReader.readAsArrayBuffer(this.state.selectedFile);
                    } else if (fileExtension === "csv") {
                        PapaParse.parse(this.state.selectedFile, {
                            complete: (results) => {
                                const csvData = PapaParse.unparse(results.data);
                                console.log(csvData,"csvDatacsvData")
                                this.validateCSVData(csvData);
                            }
                        });
                    }
                }
            });
        } else {
            document.getElementById("uploadfile").value = null;
            this.setState({
                show: true,
                basicType: 'danger',
                basicTitle: 'Please upload file having extensions xlsx or csv only.',
            });
        }
    }
    
    validateCSVData = (csvData) => {
        const config = {
            headers: [
                {
                    name: 'Pickup_Location_Code',
                    inputName: 'location_code',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'order_number',
                    inputName: 'order_number',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                }
            ]
        };
    
        CSVFileValidator(csvData, config).then((csvContent) => {
            // console.log(csvContent,"GGGGGGGGGGG")
            if (csvContent.inValidData.length === 0) {
                // Additional validation for alphanumeric characters
                // console.log(csvContent.data,"CSV Content")
                const dataErrors = [];
                if(csvContent.data.length === 0){
                    dataErrors.push("1")
                }
                csvContent.data.forEach((row, rowIndex) => {
                    Object.keys(row).forEach((key, columnIndex) => {
                        const value = row[key];
                        if (!/^[a-zA-Z0-9]+$/.test(value)) {
                            dataErrors.push({
                                rowIndex: rowIndex + 2,
                                columnIndex: columnIndex + 1,
                                message: `${key} contains invalid characters in the ${rowIndex + 2} row / ${columnIndex + 1} column`
                            });
                        }
                        if (key === 'location_code' && value !== this.state.location.value) {
                            dataErrors.push({
                              rowIndex: rowIndex + 2,
                              columnIndex: columnIndex + 1,
                              message: `location_code does not match in the ${rowIndex + 2} row / ${columnIndex + 1} column`
                            });
                        }
                    });
                });
    
                if (dataErrors.length > 0) {
                    this.setState({
                        show: true,
                        basicType: 'danger',
                        basicTitle: 'Please correct the invalid data',
                        errors: dataErrors
                    });
                    document.getElementById("uploadfile").value = null;
                } else {
                    this.setState({
                        csvcontent: csvContent.data
                    }, () => {
                        console.log("Valid data is found");
                    });
                }
            } else {
                document.getElementById("uploadfile").value = null;
                this.setState({
                    show: true,
                    basicType: 'danger',
                    basicTitle: 'Please upload file having valid Details',
                    errors: csvContent.inValidData
                });
            }
        }).catch((err) => {
            console.log("err", err);
        });
    }
    
    onClickShowUpload = () =>{
        // this.setState({
        //     showSlideBlockUpload:"slide25",
        //     overlayBlock:"show-m"
        // })
        let url_type = window.location.href;
        let path_type = url_type.split('?')
        let path = path_type[path_type.length-1]
        window.location.href="loadprocessrun?"+path;
    }

    hideSlideBlock = () =>{
        this.setState({
            overlayBlock:"show-n",
            showSlideBlock:"",
            showSlideBlockUpload:"",
            showSlideBlockRoute:"",
            showSlideBlockStack:"",
            loadFile:'',
            loadshow:'show-n',
            overly:'show-n',
            sliderRso:"",            
            loadFile: ""
        })
        $("#uploadFile").val("");
        $("#notify_email").val("");
    }
    fixProcess = () => {
        this.setState({
            processErrMsg : "",
            processErrShow : 0,
            loadFile:''
        });
        $("#uploadFile").val("");
        $("#notify_email").val("");
        //document.getElementById("upform").reset();
    }
    ignoreProcessError = () => {
        this.setState({
            loadshow:'show-m',
            overly:'show-m',
        });
        var transactionId = this.state.transactionId;
        // console.log("transactionId",transactionId)
        var params = {
            transactionId : transactionId,
            data_type : this.state.data_type,
        }
        
        redirectURL.post("dispatch/generateLoadByTransactionId",params)
        .then((response)=>{
            //console.log(response.data,"response")
            var statusCode = response.data.status;
            if(statusCode == 0 || statusCode == 1)
            {
                this.setState({                    
                    basicTitle : response.data.message,
                    basicType : "success",
                    show : true,
                    processErrMsg : "",
                    processErrShow : 0,
                    loadshow:'show-n',
                    overly:'show-n',
                    overlayBlock:"show-n",
                    showSlideBlockUpload:"",
                });
            }
            else
            {
                this.setState({
                    processErrMsg : response.data.message,
                    processErrShow : 1,
                    transactionId : response.data.transactionId,
                    loadshow:'show-n',
                    overly:'show-n',
                    overlayBlock:"show-n",
                });
            }
            //window.location.reload();
        })
        .catch(function (error) {
            var errDetails={
                url:"dispatch/generateLoadByTransactionId",
                screen:'Summary Dashboard',
                error,
                
            }
            redirectURL.post("/master/logErrorsForApiCalls",errDetails)
            
        })
        
        
    }
    
    changeFileHandler = async (e) => {
        // console.log(e);
        this.setState({
            loadFile : e.target.files[0]
        });  
        
        var check = verifyext(e.target.files[0].name);
        //console.log("Check ", check)
        if(check == true)
        {
            if(e.target.files[0].type == '' ||e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
            {
                // console.log(fileData);
                // console.log(typeof(fileData))
                
            }
            else{
                e.target.value = null;
                this.setState({
                    uploadFile:'',
                    show: true, 
                    basicType:'danger', 
                    basicTitle:'Please upload file having extensions .csv only.',
                });
            }
        }
        else{
            e.target.value = null;
            this.setState({
                uploadFile:'',
                show: true, 
                basicType:'danger', 
                basicTitle:'Please upload file having extensions .csv only.',
            });
        }
        
    }
    
    uploadBulkFormHandler(event){
        event.preventDefault();
        var flag = 0;
        let saveData = new FormData();
        if(flag == 0)
        {      
            saveData.append("loadFile", this.state.loadFile);
            var notify_email = $("#notify_email").val();
            saveData.append("notify_email", notify_email);
            saveData.append("module_type", 1);
            saveData.append("data_type", this.state.data_type);
            saveData.append("material_configurations", this.state.material_configurations)
            let location = this.state.location;
            saveData.append("location_code", location.value)
            saveData.append("location_name", location.label)
            saveData.append("user_name", localStorage.getItem('username'))
            saveData.append("email", localStorage.getItem('email'))
            console.log(saveData,saveData);
            this.setState({
                loadshow:'show-m',
                overly:'show-m',
            });
            
            redirectURL.post("/dispatch/generateLoad",saveData,{
                headers:{
                    'content-type': 'multipart/form-data',
                    'Access-Control-Allow-Origin':"*",
                    'Access-Control-Allow-Methods':'POST',
                    'Access-Control-Allow-Headers': 'Origin, Content-Type'
                }
            }).then((response)=>{
                console.log(response,"responseFromProcess")
                var statusCode = response.data.status;
                if(statusCode == 8)
                {
                    this.setState({
                        processErrMsg : response.data.message,
                        processErrShow : 1,
                        transactionId : response.data.transactionId,
                        droppedList : response.data.droppedList,
                        statusCode : statusCode,
                        loadshow:'show-n',
                        overly:'show-n',
                        overlayBlock:"show-n",
                    });
                }
                else if(statusCode > 3 && statusCode != 8)
                {
                    if(statusCode == 11)
                    {
                        var processMsg = "Use the standard input data file template for uploading loads";
                    }
                    else
                    {
                        var processMsg = response.data.message;
                    }
                    this.setState({
                        processErrMsg : response.data.message,
                        processErrShow : 1,
                        transactionId : response.data.transactionId,
                        statusCode : statusCode,
                        loadshow:'show-n',
                        overly:'show-n',
                        overlayBlock:"show-n",
                    });
                }
                else
                {
                    this.checkTransactionStatus(response.data.transactionId);
                    // this.setState({                    
                    //     basicTitle : response.data.message,
                    //     basicType : "success",
                    //     show : true,
                    //     loadshow:'show-n',
                    //     overly:'show-n',
                    //     showSlideBlockUpload:"",
                    // });
                }
                //window.location.reload();
            })
            .catch(function (error) {
                var errDetails={
                    url:'/dispatch/generateLoad',
                    screen:'Summary Dashboard',
                    error,
                    
                }
                redirectURL.post("/master/logErrorsForApiCalls",errDetails)
                
            })
            
        }
        else
        {
            this.setState({
                basicTitle : "Please upload the file",
                basicType : "warning",
                show : true,
            })
        }
    };

    checkTransactionStatus = (transactionId) =>{
        var query = {
            transaction_id : transactionId
        }
        
        redirectURL.post("/dispatch/checkTransactionStatus",query)
        .then((response)=>{
            // console.log(response,"responseFromcheckTransactionStatus")
            var statusCode = response.data.status;
            if(statusCode == 8)
            {
                this.setState({
                    processErrMsg : response.data.message,
                    processErrShow : 1,
                    transactionId : response.data.transactionId,
                    droppedList : response.data.droppedList,
                    statusCode : statusCode,
                    loadshow:'show-n',
                    overly:'show-n',
                    overlayBlock:"show-n",
                });
            }
            else if(statusCode > 3 && statusCode != 8)
            {
                this.setState({
                    processErrMsg : response.data.message,
                    processErrShow : 1,
                    transactionId : response.data.transactionId,
                    statusCode : statusCode,
                    loadshow:'show-n',
                    overly:'show-n',
                    overlayBlock:"show-n",
                });
            }
            else if(statusCode == 0)
            {
                var checkCount = this.state.checkCount;
                var updatedCnt = parseInt(checkCount)+1;
                this.setState({
                    checkCount : updatedCnt
                });
                // console.log(updatedCnt,"checkCount")
                if(updatedCnt < 3)
                {
                    this.chkStatus(response.data.transactionId);
                }
                else
                {
                    this.setState({                    
                        basicTitle : "File is processing",
                        basicType : "success",
                        show : true,
                        loadshow:'show-n',
                        overly:'show-n',
                        overlayBlock:"show-n",
                        showSlideBlockUpload:"",
                        loadFile:''
                    })
                    $("#uploadFile").val("");
                    $("#notify_email").val("");
                }
            }
            else
            {
                this.setState({                    
                    basicTitle : response.data.message,
                    basicType : "success",
                    show : true,
                    loadshow:'show-n',
                    overly:'show-n',
                    overlayBlock:"show-n",
                    showSlideBlockUpload:"",
                });
                this.getDispatchPlanData()
            }
        })
        .catch(function (error) {
            var errDetails={
                url:'/dispatch/checkTransactionStatus',
                screen:'Summary Dashboard',
                error,
                
            }
            redirectURL.post("/master/logErrorsForApiCalls",errDetails)
            
        })
        ;
    
    }

    chkStatus = (transactionId) => {
        this.checkTransactionStatus(transactionId);
    }

    viewPlanDetails = async(propdata) =>{
        // console.log('hit')
        let logParams = {
            "location_code" : this.state.location.value,
            "location_name" : this.state.location.label,
            "user_name" : localStorage.getItem("username"),
            "useremail" : localStorage.getItem("email"),
            "client" : localStorage.getItem("client"),
            "screen" : "Summary Dashboard",
            "activity" : 'clicked on View Details Button in the sub aggrid',
            "event" : "Navigated to summary dashboard page",
            "data_type" : this.state.data_type,
        }
    
        redirectURL.post("/master/loguserUpdatesorChanges",logParams)
        if(propdata.data.data_processed == 1 || propdata.data.data_processed == 2 || propdata.data.data_processed == 3){
            if(propdata.colDef.field == "transaction_details")
            {
                let data_type = this.state.data_type;
                let location = this.state.location.value;
                let qry = {
                    dispatch_planning_type : data_type,
                    plant_location_code : location
                }
                localStorage.setItem('qry',JSON.stringify(qry))
                
                var data = propdata.data;
                let basestring = data.transaction_id;
                let encryptedstring = window.btoa(basestring);
                // window.location.href = '/viewdispatchplandetails?'+encryptedstring;
                var ownerMails = [
                //   "nanda@enmovil.in",
                //   "ravi@enmovil.in",
                //   "nanda@enmovil.ai",
                //   "ravi@enmovil.ai",
                //   "venkat.moganty@enmovil.in",
                  "venkatesh.jalla@enmovil.in"
                ];
                var email = localStorage.getItem("email");
                if (ownerMails.includes(email)) {
                  window.open(
                    window.location.origin +
                      "/dispatchplandetails?" +
                      encryptedstring,
                    "_blank"
                  );
                } else {
                  window.open(
                    window.location.origin +
                      "/viewdispatchplandetails?" +
                      encryptedstring,
                    "_blank"
                  );
                }
            }
        }
        
        
        if(propdata.colDef.field == "order_details")
        {
            var data = propdata.data;
            // console.log("data ", data)
            var order_id = data.order_id;
            var tool_config = data.tool_config;
            this.setState({
                tool_config : tool_config
            })
            var params = {
                order_id : order_id,
                // sla:data.days_of_runs,
                location_code:data.location_code,
                order_date:moment.parseZone(data.order_date).format("DD-MM-YYYY")
            }
            
            redirectURL.post('dispatch/getTransactionInputDetails',params)
            .then(async response =>{
                var inputDetails = response.data;
                this.setState({
                    inputDetails:inputDetails,
                    showSlideBlockUpload:"slide45",
                    overlayBlock:"show-m"
                })
            })
            .catch(function (error) {
                var errDetails={
                    url:'dispatch/getTransactionInputDetails',
                    screen:'Summary Dashboard',
                    error,
                    
                }
                redirectURL.post("/master/logErrorsForApiCalls",errDetails)
                
            })
        
        }
        
        
        if(propdata.colDef.field == "plan_followed" && propdata.data.is_plan_follow != 1)
        {
            var data = propdata.data;
            var order_id = data.order_id;
            var tool_config = data.tool_config;
            this.setState({
                tool_config : tool_config
            })
            
            var cnf = window.confirm("Are you sure you want to proceed?");
            if(cnf === true)
            {
                var params = {
                    order_id : order_id,
                    // sla:data.days_of_runs,
                    transaction_id:data.transaction_id,
                    location_code:data.location_code,
                    order_date:moment.parseZone(data.order_date).format("DD-MM-YYYY"),
                    user:localStorage.getItem("username")
                }
               
                redirectURL.post('dispatch/setPlanFollowed',params)
                .then(async response =>{
                    
                

                    redirectURL.post('dispatch/addApprovedPlanDetails',params)
                    .then(async response =>{

                    })
                    await this.getDispatchPlanData()
                })
                .catch(function (error) {
                    var errDetails={
                        url:'dispatch/setPlanFollowed',
                        screen:'Summary Dashboard',
                        error,
                        
                    }
                    redirectURL.post("/master/logErrorsForApiCalls",errDetails)
                    
                })
           
            }
        }
        
    }

    viewOrderDetails = async(propdata) =>{
        try {
            var data = propdata.data;
            // console.log("data ", data)
            var order_id = data.order_id;
            var tool_config = data.tool_config;
            this.setState({
                tool_config : tool_config
            })
            var params = {
                order_id : order_id,
                // sla:data.days_of_runs,
                location_code:data.location_code,
                order_date:moment.parseZone(data.order_date).format("DD-MM-YYYY")
            }

            let logParams = {
                "location_code" : this.state.location.value,
                "location_name" : this.state.location.label,
                "user_name" : localStorage.getItem("username"),
                "useremail" : localStorage.getItem("email"),
                "client" : localStorage.getItem("client"),
                "screen" : "Summary Dashboard",
                "activity" : 'clicked on Drop Details View Button',
                "event" : "Slider Opened with Aggrid",
                "data_type" : this.state.data_type,
            }
        
            redirectURL.post("/master/loguserUpdatesorChanges",logParams)

            redirectURL.post('dispatch/getTransactionInputDetails',params)
            .then(async response =>{
                var inputDetails = response.data;
                this.setState({
                    inputDetails:inputDetails,
                    showSlideBlockUpload:"slide45",
                    overlayBlock:"show-m"
                })
            })
            .catch(function (error) {
                var errDetails={
                    url:'dispatch/getTransactionInputDetails',
                    screen:'Summary Dashboard',
                    error,
                    
                }
                redirectURL.post("/master/logErrorsForApiCalls",errDetails)
                
            })
           
        } catch (error) {
            
        }
        
    }
    

    planFollowed = async(propdata) =>{
        try {
            var data = propdata.data;
            // console.log("data ", data)
            // var order_id = data.order_id;
            // var tool_config = data.tool_config;
            // this.setState({
            //     tool_config : tool_config
            // })
            // var params = {
            //     order_id : order_id,
            //     // sla:data.days_of_runs,
            //     location_code:data.location_code,
            //     order_date:moment.parseZone(data.order_date).format("DD-MM-YYYY")
            // }
            // redirectURL.post('dispatch/getTransactionInputDetails',params)
            // .then(async response =>{
            //     var inputDetails = response.data;
            //     this.setState({
            //         inputDetails:inputDetails,
            //         showSlideBlockUpload:"slide45",
            //         overlayBlock:"show-m"
            //     })
            // })
        } catch (error) {
            
        }
        
    }

    locationChanged = async(location) =>{
        await this.setState({
            location : location
        })
        // await this.getDispatchPlanData()
        await getToolConfiguration({plant_code:location.value})
    }

    viewHistoryDetails = async(row) => { 
        // console.log(row,"row")
        let logParams = {
            "location_code" : this.state.location.value,
            "location_name" : this.state.location.label,
            "user_name" : localStorage.getItem("username"),
            "useremail" : localStorage.getItem("email"),
            "client" : localStorage.getItem("client"),
            "screen" : "Summary Dashboard",
            "activity" : 'clicked on Plan history View Button',
            "event" : "Aggrid opended below the row",
            "data_type" : this.state.data_type,
        }
    
        redirectURL.post("/master/loguserUpdatesorChanges",logParams)
        var plannedcolsDefs1 = [
            {
                headerName: i18n.t('plan_id'),
                field: "transaction_id",
                width:200,
                filter: true,
                resizable: true,
                editable:false
            },
            // {
            //     headerName: "SLA",
            //     field: "days_of_runs",
            //     width:150,
            //     filter: true,
            //     resizable: true,
            //     editable:false,
            //     valueGetter:function(params){
            //         try {
            //             if(params.data.days_of_runs !== "" && params.data.days_of_runs !== undefined)
            //             {
            //                 if(parseInt(params.data.days_of_runs) === 1)
            //                 {
            //                     return "Single Day";
            //                 }
            //                 else{
            //                     return "Multiple Days"
            //                 }
            //             }
            //             else{
            //                 return "";
            //             }
            //         } catch (error) {
                        
            //         }
            //     }
            // },
            {
                headerName: i18n.t('date_created'),
                field: "createDate",
                width:140,
                filter: true,
                resizable: true,
                editable:false,
                valueGetter:function(params){
                    if(params.data.createdon != ''){
                        return getHyphenDDMMMYYYYHHMM(params.data.createDate);
                    }
                    else{
                        return "";
                    }
                },
            },
            {
                headerName: i18n.t('status'),
                field: "data_processed",
                width:140,
                filter: true,
                resizable: true,
                editable:false,
                valueGetter:function(params){

                    let givenDateTime = moment.parseZone(params.data.createDate).format('DD-MM-YYYY HH:mm');
                    let currentDateTime = moment.parseZone(new Date()).format('DD-MM-YYYY HH:mm');
                    
                    
                    givenDateTime = moment(givenDateTime, 'DD-MM-YYYY HH:mm');
                    currentDateTime = moment(currentDateTime, 'DD-MM-YYYY HH:mm');
                    const differenceInMinutes = currentDateTime.diff(givenDateTime, 'minutes');
                    
                    const targetTimeDifferenceInMinutes = 35;
                    let flag = true;
                    
                    if (differenceInMinutes >= targetTimeDifferenceInMinutes) {
                        flag = false
                        }
                    if(params.data.data_processed == 0)
                    {
                        if(flag){
                            return "Processing";
                        }else{
                            return "Please Contact Admin";
                        }                        
                    }
                    else if(params.data.data_processed == 1)
                    {
                        return "Completed";
                    }
                    else if(params.data.data_processed == 2)
                    {
                        return "SOLVER_RECO_PROCESSED";
                    }
                    else if(params.data.data_processed == 3)
                    {
                        return "SOLVER_SOB_DONE";
                    }
                    else if(params.data.data_processed == 4)
                    {
                        return "SOLVER_INVALID_INPUT_DEALER_LOCATION";
                    }
                    else if(params.data.data_processed == 5)
                    {
                        return "SOLVER_INVALID_INPUT_MATERIALS";
                    }
                    else if(params.data.data_processed == 6)
                    {
                        return "SOLVER_INVALID_INPUT_DEALER_LOCATION_AND_MATERIALS";
                    }
                    else if(params.data.data_processed == 7)
                    {
                        return "SOLVER_INPUT_DATA_NOT_VALID";
                    }
                    else if(params.data.data_processed == 8)
                    {
                        return "SOLVER_FIX_DROPED_DEALERS";
                    }
                    else if(params.data.data_processed == 9)
                    {
                        return "SOLVER_VEHICLE_DETAILS_MISSING";
                    }
                    else if(params.data.data_processed == 10)
                    {
                        return "SOLVER_INPUT_FILE_DATA_ERROR";
                    }
                    else if(params.data.data_processed == 11)
                    {
                        return "SOLVER_INVALID_FILE_TEMPLATE";
                    }
                    else if(params.data.data_processed == 12)
                    {
                        return "PROCESS_TIMED_OUT";
                    }
                    else if(params.data.data_processed == 13)
                    {
                        return "SOLVER_SOB_FAIL";
                    }
                    else if(params.data.data_processed == 14)
                    {
                        return "SOLVER_RECO_FAILED";
                    }
                    else if(params.data.data_processed == 15)
                    {
                        return "SOLVER_NO_TOOL_CONFIG";
                    }
                    else if(params.data.data_processed == 16)
                    {
                        return "SOLVER_INVALID_TOOL_CONFIG";
                    }else{
                        return "Please Contact Admin";
                    }
                }
            },
            // {
            //     headerName: "Optimization Type",
            //     field: "opti_type",
            //     width:150,
            //     filter: true,
            //     resizable: true,
            //     editable:false,
            //     valueGetter:function(params){
            //         if(params.data.opti_type == 0 || params.data.opti_type == "0")
            //         {
            //             return "Optimize by Distance";
            //         }
            //         else if(params.data.opti_type == 1 || params.data.opti_type == "1"){
            //             return "Optimize by Time";
            //         }
            //     }
            // },
            {
                headerName: i18n.t('optimization_type'),
                field: "opti_type",
                width:200,
                filter: true,
                resizable: true,
                editable:false,
                valueGetter:function(params){
                    if(params.data.run_with_transit == 1 || params.data.run_with_transit == "1")
                    {
                        return "Transit Time With Multiple City Clubing";
                    }
                    else if(params.data.run_with_RDD == 1 || params.data.run_with_RDD == "1"){
                        return "RDD Preference";
                    }
                    else if(params.data.run_with_transit_cityclub == 1 || params.data.run_with_transit_cityclub == "1"){
                        return "Transit Time With Single City Clubing";
                    }
                }
            },
            // {
            //     headerName: "No. of Trucks Planned",
            //     field: "no_of_vehicles_planned",
            //     width:160,
            //     filter: true,
            //     resizable: true,
            //     editable:false
            // },
            // {
            //     headerName: "Total Planned Volume (CFT)",
            //     field: "planned_load_volume",
            //     width:200,
            //     filter: true,
            //     resizable: true,
            //     editable:false,
            //     valueGetter:function(params){
            //                 if(params.data.planned_load_volume)
            //                 {
            //                     return params.data.planned_load_volume.toFixed(2);
            //                 }
            //     }
            // },
            // {
            //     headerName: "Total Planned Weight (Pounds)",
            //     field: "planned_load_weight",
            //     width:200,
            //     filter: true,
            //     resizable: true,
            //     editable:false,
            //     valueGetter:function(params){
            //         if(params.data.planned_load_weight)
            //         {
            //             return params.data.planned_load_weight.toFixed(2);
            //         }
            //     }
            // },
            // {
            //     headerName: "Total Planned Distance (KM)",
            //     field: "total_plan_distance_km",
            //     width:200,
            //     filter: true,
            //     resizable: true,
            //     editable:false,
		    //     valueGetter:function(params){
            //         console.log(params,'hello')
            //                 if(params.data.total_plan_distance_km!=='' && params.data.total_plan_distance_km!==undefined)
            //                 {
            //                     return params.data.total_plan_distance_km.toFixed(2);
            //                 }
		    //     }
            // },
            // {
            //     headerName: "Total Planned Time (Mins)",
            //     field: "total_plan_time_mins",
            //     width:200,
            //     filter: true,
            //     resizable: true,
            //     editable:false
            // },
            
            // {
            //     headerName: "Non-Pallet Load (lbs)",
            //     field: "planned_load_cmt",
            //     width:180,
            //     filter: true,
            //     resizable: true,
            //     editable:false,
            //     valueGetter:function(params){
            //         try {
            //             if(params.data.planned_load_cmt !== "" && params.data.planned_load_cmt !== undefined)
            //             {
            //                 return Math.round(params.data.planned_load_cmt);
            //             }
            //             else{
            //                 return "";
            //             }
            //         } catch (error) {
                        
            //         }
            //     }
            // },
            // {
            //     headerName: "Pallet Load (lbs)",
            //     field: "unplanned_load_cmt",
            //     width:150,
            //     filter: true,
            //     resizable: true,
            //     editable:false,
            //     valueGetter:function(params){
            //         try {
            //             if(params.data.unplanned_load_cmt !== "" && params.data.unplanned_load_cmt !== undefined)
            //             {
            //                 return Math.round(params.data.unplanned_load_cmt);
            //             }
            //             else{
            //                 return "";
            //             }
            //         } catch (error) {
                        
            //         }
            //     }
            // },
            // {
            //     headerName: "No. of Passengers Planned",
            //     field: "planned_load_cmt",
            //     width:150,
            //     filter: true,
            //     resizable: true,
            //     editable:false
            // },
            // {
            //     headerName: "No. of Passengers Ignored",
            //     field: "unplanned_load_cmt",
            //     width:150,
            //     filter: true,
            //     resizable: true,
            //     editable:false
            // },
            // {
            //     headerName: "Remarks",
            //     field: "remarks",
            //     width:200,
            //     filter: true,
            //     resizable: true,
            //     editable:false,
            //     valueGetter:function(params){
            //         if(params.data.remarks !=undefined && params.data.remarks !="")
            //         {
            //             return params.data.remarks;
            //         }
            //         else
            //         {
            //             return "N/A";
            //         }
            //     }
            // },
            {
                pinned:"left",
                headerName : i18n.t('view_details'),
                field:"transaction_details",
                width:120,
                resizable : true,
                editable : false,
                // params:{buttonName:'View',iconName:'fa fa-eye',
                // onClickFunction:this.viewPlanDetails},
                cellRenderer:function(params){
                    // console.log(":params ", params.data)
                    if(params.data.data_processed == 1 || params.data.data_processed == 2 || params.data.data_processed == 3)
                    {
                        return <StaticButton/>
                    }
                    else
                    { 
                        return <NAButton/>
                    }
                },
            },
            // {
            //     headerName : "Implement Plan",
            //     field:"plan_followed",
            //     pinned:"left",
            //     width:180,
            //     resizable : true,
            //     editable : false,
                // cellClass:function(params){
                //     try {
                //         if(params.data.vehicle_planned_flag === 1)
                //         {
                //             return "gHighlight";
                //         }
                //         else{
                //             return "";
                //         }
                //     } catch (error) {
                        
                //     }
                // },
                // params:{buttonName:'Freeze Plan',iconName:'fa fa-eye',buttonCls:'btn btn-info',
                // onClickFunction:this.planFollowed},
                // cellRenderer:function(params){
                //     if(params.data.is_plan_follow !== undefined && params.data.is_plan_follow === 1 && params.data.approved_by !== undefined && params.data.approved_by != '')
                //     {
                //         return "Selected Plan";
                //     }
                //     else if(params.data.is_plan_follow !== undefined && params.data.is_plan_follow == 0){
                //         return "Plan Implemented"
                //     }
                //     else{
                //         return <PlanFollowedButton/>
                //     }
                        
                // },
            // },
            {
                    headerName: i18n.t('plan_run_by'),
                    field: "useremail",
                    width:170,
                    filter: true,
                    resizable: true,
                    editable:false,
                },
            // {
            //     headerName: "Plan Approved By",
            //     field: "approved_by",
            //     width:170,
            //     filter: true,
            //     resizable: true,
            //     editable:false,
            //     valueGetter:function(params){
            //         if(params.data.approved_by != undefined && params.data.approved_by != ''){
            //             return params.data.approved_by;
            //         }
            //         else{
            //             return "NA";
            //         }
            //     },
            // },
            // {
            //     headerName: "Approved Time",
            //     field: "approved_time",
            //     width:170,
            //     filter: true,
            //     resizable: true,
            //     editable:false,
            //     valueGetter:function(params){
            //         if(params.data.approved_time != undefined && params.data.approved_time != ''){
            //             return moment.parseZone(params.data.approved_time).format("DD-MM-YYYY HH:MM")
            //         }
            //         else{
            //             return "NA";
            //         }
            //     },
            // },
            // {
            //     pinned:"left",
            //     headerName : "Order Details",
            //     field:"order_details",
            //     width:120,
            //     resizable : true,
            //     editable : false,
            //     // cellClass:function(params){
            //     //     try {
            //     //         if(params.data.vehicle_planned_flag === 1)
            //     //         {
            //     //             return "gHighlight";
            //     //         }
            //     //         else{
            //     //             return "";
            //     //         }
            //     //     } catch (error) {
                        
            //     //     }
            //     // },
            //     params:{buttonName:'View',iconName:'fa fa-eye',buttonCls:'btn btn-warning',
            //     onClickFunction:this.viewOrderDetails},
            //     cellRenderer:function(params){
                
            //             return <OrderButton/>
                    
            //     },
            // },
            
        ]
        var {generateDate}=this.state
        await this.setState({                        
            detailCellRendererParams:{
                detailGridOptions: {
                    columnDefs: plannedcolsDefs1,
                    overlayNoRowsTemplate: 'No rows to show',
                    onCellClicked:this.viewPlanDetails,
                    height: 200,
                },
                getDetailRowData:async function(param) {
                    // console.log('hit')
                    param.successCallback([]);
                    await redirectURL.post("/dispatch/getPlanRunHistory",{
                        order_id : row.data.order_id,
                        generateDate
                    }).then(async (response) =>{
                        // console.log(response.data)
                        param.successCallback(response.data);							
                    }).catch(function(error){
                        console.log(error);
                    
                        var errDetails={
                            url:'/dispatch/getPlanRunHistory',
                            error,
                            screen:'Summary Dashboard'
                        }
                        redirectURL.post("/master/logErrorsForApiCalls",errDetails)
                    
                    })                            
                },
                masterDetail: true
            }
        });
        if(row.colDef.field == "run_history")
        {
            row.node.setExpanded(!row.node.expanded);
        }
        else{    
            row.node.setExpanded(false);
        }
    }
    planNewDispatch = (row) => {
        // this.setState({
        //     planRowData:row.data,
        //     openSLA:!this.state.openSLA
        // })
        // console.log('hit')
        
        
        redirectURL.post("/dispatch/getDispatchPlanDataForOrder",{
            order_id : row.data.order_id
        })
        .then(async (response) =>{
        let transData = response.data
        if(transData[0].vehicle_assigned == 1){
            window.confirm("Vehicle Already Assigned For This Order")
        }else{
            var cnf = window.confirm("Are you sure you want to proceed?");
            if(cnf === true)
            {
                let logParams = {
                    "location_code" : this.state.location.value,
                    "location_name" : this.state.location.label,
                    "user_name" : localStorage.getItem("username"),
                    "useremail" : localStorage.getItem("email"),
                    "client" : localStorage.getItem("client"),
                    "screen" : "Summary Dashboard",
                    "activity" : 'clicked on Run New Button',
                    "event" : "Navigated to Run New Plan Page",
                    "data_type" : this.state.data_type,
                }
            
                redirectURL.post("/master/loguserUpdatesorChanges",logParams)
                var planRowData = row.data;
                // var sla = this.state.sla.value;
                // console.log("planRowData ", planRowData)
                let basestring = planRowData.order_id;
                let encryptedstring = window.btoa(basestring);
                // window.location.href="/plannewdispatch?"+encryptedstring; 
    
                var selectDate = moment.parseZone(planRowData.order_date).format("DD-MM-YYYY");
                let basestringDat = selectDate;
                let encryptedstringDate = window.btoa(basestringDat);
                window.location.href="/plannewdispatch?"+encryptedstring;
            }
        }
        })
        .catch(function (error) {
            var errDetails={
                url:"/dispatch/getDispatchPlanDataForOrder",
                screen:'Summary Dashboard',
                error,
                
            }
            redirectURL.post("/master/logErrorsForApiCalls",errDetails)
            
        })
    
       
    }
    executePlanNow(event){
        
        event.preventDefault();
        var planRowData = this.state.planRowData;
        var sla = this.state.sla.value;
        // console.log("planRowData ", planRowData)
        let basestring = planRowData.order_id;
        let encryptedstring = window.btoa(basestring);
        // window.location.href="/plannewdispatch?"+encryptedstring; 

        var selectDate = moment.parseZone(planRowData.order_date).format("DD-MM-YYYY");
        let basestringDat = selectDate;
        let encryptedstringDate = window.btoa(basestringDat);
        window.location.href="/plannewdispatch?"+encryptedstring+"&"+sla;
    }
    
    handelPickupDropModelCancel = ()=>{
        this.setState(prevState =>({
            openSLA :false,
            addressLng:'',
            addressLat:'',
            address_name:'',
            location_name:'',
            location_type:'',
            location_code:'',
            pickup_window_time:['00:00','23:59'],
            drop_window_time:['00:00','23:59'],
        }))

    }
    
    slaChanged(sla){
        this.setState({sla},()=>{
            if(this.state.sla.value === 2)
            {
                var selectDate = this.state.planRowData.order_date;
                // console.log("selectDate ", selectDate)
                // var c =moment(selectDate,"DD-MM-YYYY").format("YYYY-MM-DD")
                var c =moment.parseZone(selectDate).format("DD-MM-YYYY");
                var d =moment.parseZone(selectDate).add(1,"days").format("DD-MM-YYYY");
                $("#fDate").val(c); 
                $("#tDate").val(d); 
            }
            else{
                var selectDate = this.state.planRowData.order_date;
                var c =moment.parseZone(selectDate).format("DD-MM-YYYY");
                $("#fDate").val(c); 
                $("#tDate").val(c); 
            }
        })
    }

    getPendingTransactions = (latestData) =>{
        let data
        if(latestData.length>0){
            data = latestData  
        }else{
            data = this.state.latestData
        }

            
        let locValue = this.state.location.value   
        let transactions = data.filter(item => item.location_code == locValue)
        let allTransactionIds = [];   
        
        redirectURL.post("/dispatch/getPlanRunHistory",{
            order_id : transactions[0].order_id
        })
        .then(async (response) =>{
        let transData = response.data
        
        transData.map(itm => {
            if(itm.data_processed != 1 && itm.data_processed != 17){
                
                let givenDateTime = moment.parseZone(itm.createDate).format('DD-MM-YYYY HH:mm');
                let currentDateTime = moment.parseZone(new Date()).format('DD-MM-YYYY HH:mm');
                
                
                givenDateTime = moment(givenDateTime, 'DD-MM-YYYY HH:mm');
                currentDateTime = moment(currentDateTime, 'DD-MM-YYYY HH:mm');
                const differenceInMinutes = currentDateTime.diff(givenDateTime, 'minutes');
                
                const targetTimeDifferenceInMinutes = 25;
                let flag = true;
                
                if (differenceInMinutes >= targetTimeDifferenceInMinutes) {
                    flag = false
                }
                
                if(differenceInMinutes <= 8){
                    if(localStorage.getItem(itm.transaction_id) != 0 && flag){
                        allTransactionIds.push({id:itm.transaction_id,duration:(8-differenceInMinutes)*60})
                    }
                }else{
                    if(localStorage.getItem(itm.transaction_id) != 0 && flag){
                        allTransactionIds.push({id:itm.transaction_id,duration:8*60})
                    }
                }
                
                if(localStorage.getItem(itm.transaction_id) == 0){
                    this.setState({
                        show: true, 
                        basicType:'danger', 
                        basicTitle:'Please Contact Admin Time Elapsed',
                    });
                    var url = window.location.host;
                    var environment = ''
                    if (url === '4.240.97.194:3005') {
                        environment='DEV'
                    }else if (url==='164.52.218.255:3009'){
                        environment='QA'
                    }else if(url==='nestle.enmovil.net'){
                        environment='UAT'
                    }else if(url==='nestle.optirun.in'){
                        environment='PROD'
                    }else{
                        environment="Local"
                    }
                    var details={env:environment,
                                    tran_id:itm.transaction_id,
                                    plant_code:this.state.location.value,
                                    email:localStorage.getItem('email')
                                }

                    redirectURL.post("/master/triggerEmailOnTimeOut",details)
                    localStorage.removeItem(itm.transaction_id)
                }

            }else{
                localStorage.removeItem(itm.transaction_id)
            }
    
        })
        if(allTransactionIds.length==0){
            clearInterval(this.intervalId);
        }
        this.setState({allTransactionIds:allTransactionIds})

        })
        .catch(function (error) {
            var errDetails={
                url:'/dispatch/getPlanRunHistory',
                screen:'Summary Dashboard',
                error,
                
            }
            redirectURL.post("/master/logErrorsForApiCalls",errDetails)
            
        })
    
        
    }
    
    
    getCurrentDateTime() {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0'); // January is 0
        const day = String(now.getDate()).padStart(2, '0');
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }
    render() {
    const { t } = this.props;
        var tool_config = this.state.tool_config;
        var material_configuration_chk = 0;
        if(tool_config !=undefined && tool_config !="")
        {
            material_configuration_chk = tool_config[0].material_configuration;
        }
        // var inputDetailscolsDefs = [
        //     {
        //         headerName:"Pick Up Location (Source)",
        //         field:"pickup_location_code",
        //         width:"150",
        //     },
        //     {
        //         headerName:"Drop Location Code (Destination)",
        //         field:"drop_location_code",
        //         width:"150"
        //     },
        //     {
        //         headerName:"Drop Location Name (Destination)",
        //         field:"drop_location_name",
        //         width:"200"
        //     },
        //     {
        //         headerName:"Drop Location City",
        //         field:"location_city",
        //         width:"150"
        //     },
        //     {
        //         headerName:"Order Number",
        //         field:"order_number",
        //         width:"100",
        //     },
        //     {
        //         headerName:"Invoicing Date",
        //         field:"invoicing_date",
        //         width:"120",
        //     },
        //     {
        //         headerName:"Quantity",
        //         field:"quantity",
        //         width:"100",                  
        //     },
        //     {
        //         headerName: "Demand Volume (CFT)",
        //         field: "demand_cmt",
        //         width:100,
        //         filter: true,
        //         resizable: true,
        //         editable:false
        //     },
        //     {
        //         headerName: "Weight (Pounds)",
        //         field: "weight_tons",
        //         width:100,
        //         filter: true,
        //         resizable: true,
        //         editable:false
        //     },
        //     {
        //         headerName:"Dealer Available From",
        //         field:"dealer_available_start",
        //         width:"110",
        //     },
        //     {
        //         headerName:"Dealer Available To",
        //         field:"dealer_available_end",
        //         width:"120",
        //     },
        //     {
        //         headerName:"Delivery Date",
        //         field:"delivery_date",
        //         width:"120",
        //     },
        //     // {
        //     //     headerName: "Warehouse Name",
        //     //     field: "pickup_location_code",
        //     //     width:130,
        //     //     filter: true,
        //     //     resizable: true,
        //     //     editable:false           
        //     // },
        //     // {
        //     //     headerName: "Destination City",
        //     //     field: "drop_location_city",
        //     //     width:130,
        //     //     filter: true,
        //     //     resizable: true,
        //     //     editable:false
        //     // },
        //     // {
        //     //     headerName: "Destination State",
        //     //     field: "drop_location_state",
        //     //     width:200,
        //     //     filter: true,
        //     //     resizable: true,
        //     //     editable:false
        //     // },
        //     // {
        //     //     headerName: "Sales Order No",
        //     //     field: "so_number",
        //     //     width:200,
        //     //     filter: true,
        //     //     resizable: true,
        //     //     editable:false
        //     // },
        //     // {
        //     //     headerName: "No. of Tires",
        //     //     field: "no_of_tires",
        //     //     width:200,
        //     //     filter: true,
        //     //     resizable: true,
        //     //     editable:false
        //     // },
        //     // {
        //     //     headerName: "Order Date",
        //     //     field: "order_date",
        //     //     width:200,
        //     //     filter: true,
        //     //     resizable: true,
        //     //     editable:false,
        //     //     valueGetter:function(params){
        //     //         try {
        //     //             if(params.data.order_date !== "" && params.data.order_date !== undefined)
        //     //             {
        //     //                 return getHyphenDDMMMYYYY(params.data.order_date);
        //     //             }
        //     //             else{
        //     //                 return "";
        //     //             }
        //     //         } catch (error) {
                        
        //     //         }
        //     //     }
        //     // }
        // ]

        var inputDetailscolsDefs = [
            {
                headerName: t("pickup_location_source"),
                field: "pickup_location_code",
                width: "150",
            },
            {
                headerName: t("drop_location_code_destination"),
                field: "drop_location_code",
                width: "150"
            },
            {
                headerName: t("drop_location_name_destination"),
                field: "drop_location_name",
                width: "200"
            },
            {
                headerName: t("drop_location_city"),
                field: "location_city",
                width: "150"
            },
            {
                headerName: t("order_number"),
                field: "order_number",
                width: "100",
            },
            {
                headerName: t("invoicing_date"),
                field: "invoicing_date",
                width: "120",
            },
            {
                headerName: t("quantity"),
                field: "quantity",
                width: "100",                  
            },
            {
                headerName: t("demand_volume_cft"),
                field: "demand_cmt",
                width: "100",
                filter: true,
                resizable: true,
                editable: false
            },
            {
                headerName: t("weight_pounds"),
                field: "weight_tons",
                width: "100",
                filter: true,
                resizable: true,
                editable: false
            },
            {
                headerName: t("dealer_available_from"),
                field: "dealer_available_start",
                width: "110",
            },
            {
                headerName: t("dealer_available_to"),
                field: "dealer_available_end",
                width: "120",
            },
            {
                headerName: t("delivery_date"),
                field: "delivery_date",
                width: "120",
            },
            {
                headerName: t("warehouse_name"),
                field: "pickup_location_code",
                width: "130",
                filter: true,
                resizable: true,
                editable: false           
            },
            {
                headerName: t("destination_city"),
                field: "drop_location_city",
                width: "130",
                filter: true,
                resizable: true,
                editable: false
            },
            {
                headerName: t("destination_state"),
                field: "drop_location_state",
                width: "200",
                filter: true,
                resizable: true,
                editable: false
            },
            {
                headerName: t("sales_order_no"),
                field: "so_number",
                width: "200",
                filter: true,
                resizable: true,
                editable: false
            },
            {
                headerName: t("no_of_tires"),
                field: "no_of_tires",
                width: "200",
                filter: true,
                resizable: true,
                editable: false
            },
            {
                headerName: t("order_date"),
                field: "order_date",
                width: "200",
                filter: true,
                resizable: true,
                editable: false,
                valueGetter: function (params) {
                    try {
                        if (params.data.order_date !== "" && params.data.order_date !== undefined) {
                            return getHyphenDDMMMYYYY(params.data.order_date);
                        } else {
                            return "";
                        }
                    } catch (error) {}
                }
            }
        ];
        
        if(material_configuration_chk == 1)
        {
            inputDetailscolsDefs.push(
                {
                    headerName: t('material'),
                    field:"material_code",
                    width:"200",
                },
                {
                    headerName: t('quantity'),
                    field:"quantity",
                    width:"200",
                }
            );
        }
        // else
        // {
        //     inputDetailscolsDefs.push(
        //         {
        //             headerName:"No of Passengers",
        //             field:"demand_cmt",
        //             width:"250",
        //         }
        //     );
        // }
        var columnwithDefs = [
            {
                headerName: t('order_id'),
                field: "order_id",
                width:200,
                filter: true,
                resizable: true,
                // cellClass:function(params){
                //     try {
                //         if(params.data.vehicle_planned_flag === 1)
                //         {
                //             return "gHighlight";
                //         }
                //         else{
                //             return "";
                //         }
                //     } catch (error) {
                        
                //     }
                // },
                editable:false
            },
            // {
            //     headerName: "Order Date",
            //     field: "order_date",
            //     width:200,
            //     filter: true,
            //     resizable: true,
            //     editable:false,
            //     cellClass:function(params){
            //         try {
            //             if(params.data.vehicle_planned_flag === 1)
            //             {
            //                 return "gHighlight";
            //             }
            //             else{
            //                 return "";
            //             }
            //         } catch (error) {
                        
            //         }
            //     },
            //     valueGetter:function(params){
            //         if(params.data.order_date != ''){
            //             return getHyphenDDMMMYYYYHHMM(params.data.order_date);
            //         }
            //         else{
            //             return "";
            //         }
            //     },

            // },
            {
                headerName: t('no_of_runs'),
                field: "no_of_runs",
                width:80,
                filter: true,
                resizable: true,
                // cellClass:function(params){
                //     try {
                //         if(params.data.vehicle_planned_flag === 1)
                //         {
                //             return "gHighlight";
                //         }
                //         else{
                //             return "";
                //         }
                //     } catch (error) {
                        
                //     }
                // },
                editable:false
            },
            {
                headerName: t('created_on'),
                field: "createDate",
                width:130,
                filter: true,
                resizable: true,
                editable:false,
                // cellClass:function(params){
                //     try {
                //         if(params.data.vehicle_planned_flag === 1)
                //         {
                //             return "gHighlight";
                //         }
                //         else{
                //             return "";
                //         }
                //     } catch (error) {
                        
                //     }
                // },
                valueGetter:function(params){
                    if(params.data.createDate != ''){
                        return getHyphenDDMMMYYYYHHMM(params.data.createDate);
                    }
                    else{
                        return "";
                    }
                },
            },
            {
                headerName: t('plan_run_by'),
                field: "useremail",
                width:170,
                filter: true,
                resizable: true,
                editable:false,
            },
            // {
            //     headerName: "Plan Approved By",
            //     field: "approved_by",
            //     width:170,
            //     filter: true,
            //     resizable: true,
            //     editable:false,
            //     valueGetter:function(params){
            //         if(params.data.approved_by != undefined && params.data.approved_by != ''){
            //             return params.data.approved_by;
            //         }
            //         else{
            //             return "NA";
            //         }
            //     },
            // },
            // {
            //     headerName: "Approved Time",
            //     field: "approved_time",
            //     width:170,
            //     filter: true,
            //     resizable: true,
            //     editable:false,
            //     valueGetter:function(params){
            //         if(params.data.approved_time != undefined && params.data.approved_time != ''){
            //             return moment.parseZone(params.data.approved_time).format("DD-MM-YYYY HH:MM")
            //         }
            //         else{
            //             return "NA";
            //         }
            //     },
            // },
            // {
            //     headerName: "",
            //     field: "vehicle_planned_flag",
            //     width:70,
            //     filter: true,
            //     resizable: true,
            //     editable:false,
            //     // cellClass:function(params){
            //     //     try {
            //     //         if(params.data.vehicle_planned_flag === 1)
            //     //         {
            //     //             return "gHighlight";
            //     //         }
            //     //         else{
            //     //             return "";
            //     //         }
            //     //     } catch (error) {
                        
            //     //     }
            //     // },
            //     cellRenderer:"FlagIcon",
            // },
            {
                pinned:"left",
                headerName : t('plan_history'),
                field:"run_history",
                width:150,
                resizable : true,
                editable : false,
                cellClass:function(params){
                    try {
                        if(params.data.vehicle_assigned === 1)
                        {
                            return "light-bg";
                        }
                        else{
                            return "";
                        }
                    } catch (error) {
                        
                    }
                },
                params:{buttonName:t('view'),iconName:'fa fa-eye',buttonCls:'btn btn-danger',
                onClickFunction:this.viewHistoryDetails},
                cellRendererSelector:function(params){
                    var rendComponent = {
                        component: "GridButton"
                    };
                    return rendComponent
                },
            },
            // {
            //     pinned:"left",
            //     headerName : "Order Details",
            //     field:"order_id",
            //     width:150,
            //     resizable : true,
            //     editable : false,
            //     cellClass:function(params){
            //         try {
            //             if(params.data.vehicle_planned_flag === 1)
            //             {
            //                 return "gHighlight";
            //             }
            //             else{
            //                 return "";
            //             }
            //         } catch (error) {
                        
            //         }
            //     },
            //     params:{buttonName:'View',iconName:'fa fa-eye',buttonCls:'btn btn-info',
            //     onClickFunction:this.viewOrderDetails},
            //     cellRendererSelector:function(params){
            //         var rendComponent = {
            //             component: "GridButton"
            //         };
            //         return rendComponent
            //     },
            // },
            {
                pinned:"left",
                headerName : t('drop_details'),
                field:"order_id",
                width:150,
                resizable : true,
                editable : false,
                params:{buttonName: t('view'),iconName:'fa fa-eye',buttonCls:'btn btn-info',
                onClickFunction:this.viewOrderDetails},
                cellRendererSelector:function(params){
                    var rendComponent = {
                        component: "GridButton"
                    };
                    return rendComponent
                },
                cellClass:function(params){
                    try {
                        if(params.data.vehicle_assigned == 1)
                        {
                            return "light-bg";
                        }
                        else{
                            return "";
                        }
                    } catch (error) {
                        
                    }
                },
            },
            {
                pinned:"left",
                headerName : t('generate_plan'),
                field:"pla_new_dispatch",
                width:150,
                resizable : true,
                editable : false,
                cellClass:function(params){
                    try {
                        if(params.data.vehicle_assigned == 1)
                        {
                            return "light-bg";
                        }
                        else{
                            return "";
                        }
                    } catch (error) {
                        
                    }
                },
                params:{buttonName: t('run_new_plan'),iconName:'fa fa-tasks',
                onClickFunction:this.planNewDispatch},
                cellRendererSelector:function(params){
                    if(params.data.is_consolidated_plan != undefined && params.data.is_consolidated_plan == 1){
                        var rendComponent = {
                            component: 'Consolidatedbtn'
                        };
                        return rendComponent
                    }else if(params.data.transaction_update == 1 || params.data.vehicle_assigned == 1){
                        var rendComponent = {
                            component: 'vehicleAssigned'
                        };
                        return rendComponent
                    }else{
                        var rendComponent = {
                            component: "GridButton"
                        };
                        return rendComponent
                    }
                },
            },
        ]
        var columnwithDefsForDropped = [      
            {
                headerName: "Dealer",
                field: "dealer_code",
                width:130,
                filter: true,
                resizable: true,
                editable:false,            
            },
            {
                headerName: "Demand",
                field: "demand",
                width:130,
                filter: true,
                resizable: true,
                editable:false
            },
            {
                headerName: "Remarks",
                field: "remarks",
                width:200,
                filter: true,
                resizable: true,
                editable:false
            },
        ]
        if(this.state.material_configurations == 1)
        {
            var templatePath = require('../../assets/json/input_data_with_material_config_template.csv');
        }
        else
        {
            var templatePath = require('../../assets/json/input_data_template.csv');
        }
        if(this.state.planRowData !== "" && this.state.planRowData !== undefined)
        {
            if(this.state.planRowData.order_date !== "" && this.state.planRowData.order_date !== undefined)
            {
                var sorder_date = moment.parseZone(this.state.planRowData.order_date).format("DD-MM-YYYY");
            }
            else{
                var sorder_date = "";
            }
        }
        else{
            var sorder_date = "";
        }

        // let data = this.state.latestData
        // let otherData = this.state.rowData
        let data = [...this.state.latestData,...this.state.rowData]
        let locValue = this.state.location.value   
        let filteredData = data.filter(item => item.location_code == locValue)
        // let otherFilteredData = otherData.filter(item => item.location_code == locValue)
        // console.log(this.state.allTransactionIds.length )
        // this.setState({})

        return (
            <>
            <div class="container-fluid">
                <div className={"animatenow "+(this.state.showanimate)} style={{textAlign:"center"}}>
                    <span className="pp-h">Processing Plan. Please wait.....</span>
                    <img src={require("../../assets/images/r.gif")} style={{width:"85vh"}} />
                </div>
                <div className={'lodr '+(this.state.showloader)}>
                    <div className="loaderdiv">
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                    </div>
                    <div className="ldrtext">Loading....</div>
                </div>
                 <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                > 
              </SweetAlert>
              {this.state.alert}
                <div class="row mb-3p">
                    <div className="col-sm-12 pt-3p">
                        <div className='col-sm-6'>
                            <h5 className='fbold'>
                                {t('summary_dashboard')}
                                {/* <button type="button" className="btn btn-warning float-right font-white" onClick={this.onClickShowUpload}>Run New Plan</button> */}
                            </h5>
                        </div>
                    </div>
                </div>
                
                <div className="row">
                    <div className="col-md-2">
                        <div className="form-group ">
                            <label className="col-form-label f12">{t('pickup_point')}</label>
                            <Select
                                placeholder={"Select"}
                                onChange={this.locationChanged}
                                name="location"
                                value={this.state.location}
                                options={this.state.locationNames}
                            />
                        </div>
                    </div>
                    {/* <div className="col-md-2">
                        <div className="form-group ">
                            <label className="col-form-label f12">{Constant.DISPATCH_PLAN_DATA_CONFIGURATION_SELECTE_DATE}</label>
                            <input type="date" className="form-control" name="selectDate" id="selectDate" autoComplete='off' 
                            onKeyDown={(e) => e.preventDefault()}  
                            max={this.getCurrentDateTime()} 
                            readonly />
                        </div>
                    </div> */}
                    <div className="col-md-2">
                        <div className="form-group ">
                            <label className="col-form-label f12">{t('date_created')}</label>
                            <input type="date" className="form-control" name="generateDate" id="generateDate" autoComplete='off'
                            onKeyDown={(e) => e.preventDefault()}  
                            max={this.getCurrentDateTime()} 
                            readonly />
                        </div>
                    </div>
                    <div className="col-md-2">
                        <button type="button" onClick={this.onClickGetData} className="btn btn-warning mt-30px" style={{marginTop:'35px'}}>{t('get_data')}</button>
                    </div> 
                    <div className='col-md-6'>                   
                        <div className='col-sm-8'>
                            {
                                this.state.allTransactionIds.length > 0 ? 
                                <div>
                                    { this.state.allTransactionIds.length > 0 ? 
                                        <CountDownTimer
                                        allTransactionIds = {this.state.allTransactionIds} />
                                        : ''
                                    }
                                </div>: ""
                            }
                        </div>
                        <div className="col-sm-4" style={{display:"flex", justifyContent:"right"}}>
                            <button type="button" onClick={this.onUploadOBDDetails} className="btn btn-danger mt-30px" style={{marginTop:'35px'}}>{t('Discard')}{t('obd')}</button>
                        </div>
                    </div>
                </div>
                {/*<div class="row mb-10p">
                    <div className={"mxheit col-sm-12 "} >
                        <h5>Recent Plan Details</h5>
                        <div id="myGrid" style={{width:"100%",height:"40vh"}} className={"ag-theme-balham"}>    
                            <AgGridReact
                                // modules={this.state.modules}
                                columnDefs={columnwithDefs}
                                defaultColDef={this.state.defaultColDef}
                                rowData={filteredData}
                                enableCharts={false}
                                onGridReady={this.onGridReady}
                                onGridState={this.onGridState}
                                frameworkComponents={this.state.frameworkComponents}
                                statusBar={this.state.statusBar}
                                sideBar={this.state.sideBar}
                                stopEditingWhenGridLosesFocus= {true}
                                paginationPageSize={this.state.paginationPageSize}
                                pagination={true}
                                gridOptions={{
                                    context: { componentParent: this }
                                }}
                                enableRangeSelection= {true}
                                masterDetail={true}
                                detailCellRendererParams={this.state.detailCellRendererParams}
                                rowClassRules={this.state.rowClassRules}       
                            />
                        </div>
                    </div>
                </div>*/}
                <div class="row mb-10p">
                    <div className={"mxheit col-sm-12 "} >
                        <h5>{t('plan_details')}</h5>
                        <div id="myGrid1" style={{width:"100%",height:"70vh"}} className={"ag-theme-balham"}>    
                            <AgGridReact
                                // modules={this.state.modules}
                                columnDefs={columnwithDefs}
                                defaultColDef={this.state.defaultColDef}
                                rowData={filteredData}
                                enableCharts={false}
                                onGridReady={this.onGridReady}
                                onGridState={this.onGridState}
                                frameworkComponents={this.state.frameworkComponents}
                                statusBar={this.state.statusBar}
                                sideBar={this.state.sideBar}
                                stopEditingWhenGridLosesFocus= {true}
                                paginationPageSize={this.state.paginationPageSize}
                                pagination={true}
                                gridOptions={{
                                    context: { componentParent: this }
                                }}
                                enableRangeSelection= {true}
                                enableCellContextMenu={true}
                                // allowContextMenuWithControlKey={true}
                                suppressContextMenu={false}
                                masterDetail={true}
                                detailCellRendererParams={this.state.detailCellRendererParams}
                                rowClassRules={this.state.rowClassRules}							
                            />
                        </div>
                    </div>
                </div>
                <div className={"overlay-block "+(this.state.overlayBlock)} onClick={this.hideSlideBlock}></div>
                <div className={"sliderBlock2 "+(this.state.showSlideBlockUpload)}>
                    <h5 className="crd-bg p-10p">{t('drop_details')}</h5>
                    <div className="col-sm-12">
                        <div id="myGrid2" style={{width:"100%",height:"80vh"}} className="col-sm-12 ag-theme-balham dropdown">
                            <AgGridReact
                            // modules={this.state.modules} 
                                rowData={this.state.inputDetails}
                                columnDefs={inputDetailscolsDefs}
                                gridOptions={{context:{componentParent:this}}}
                                defaultColDef={this.state.defaultColDef}
                                frameworkComponents={this.state.frameworkComponents}
                                paginationPageSize={this.state.paginationPageSize}
                                pagination={true}
                                enableRangeSelection= {true}
                            />
                        </div>
                    </div>
                </div>

                {/* slider area */}
                <div className={"sliderBlock2 " + (this.state.sliderRso)} style={{ overflow: "auto" }}>
                    <h5 className="crd-bg p-10p">{t('upload_obd_details')}
                    <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.hideSlideBlock.bind(this)} >X</span></h5>
                    <div className={"row"} style={{ padding: '10px 10px', width: '100%'}} >

                    <div className="col-xl-12 col-lg-12 form-group">
                        <a href={require("../../assets/json/OBD_Upload_CSV_Download_sample_template4.csv")} target="_blank" className="btn btn-warning">{t('download_template')}</a>
                    </div>
                    <div className="theme-form col-xl-12 col-lg-12">

                        <form action="" onSubmit={this.onSubmitBulkUpload.bind(this)}>
                        <div className="col-xl-12 col-lg-12 row form-group">
                            <label className="col-xl-12 col-lg-12">{t('upload_file')} *</label>
                            <input
                            type="file"
                            name="uploadfile"
                            id="uploadfile"
                            className="form-control"
                            onChange={this.changeFileHandlerFc.bind(this)}
                            required
                            />
                        </div>

                        <div className="col-xl-12 col-lg-12 form-group">
                            <button type="submit" className="btn btn-success">{t('submit')}</button>
                        </div>
                        </form>
                        {this.state.show && (
                            <div>
                                <ul style={{listStyle:"none",margin:"0px", padding:"0px"}}>
                                    {this.state.errors ?this.state.errors.map((error, index) => (
                                        <li key={index} style={{color:"red", fontSize:"16px", marginBottom:"8px"}}>
                                            {error.message}
                                        </li>
                                    )):""}
                                </ul>
                                
                            </div>
                        )}
                    </div>
                    </div>
                </div>

                {/* <div className={"dataLoadpage " +(this.state.loadshow)}></div> */}
                <div className={"loader "+this.state.loadshow}></div>
                <div className={"overlay-part "+(this.state.overly)} onClick={this.hideSlideBlock.bind(this)}></div>

                <Modal closeOnOverlayClick={false} open={this.state.openSLA} onClose={this.handelPickupDropModelCancel} 
                    classNames={{modal: 'pickupdrop-modelClass'}} >


                    <div className="container-fluid">
                        <div className="mappg mprel">
                            <div className="row">
                                <div className={"col-xl-8 col-lg-8 " }>
                                    <form method="POST" onSubmit={this.executePlanNow.bind(this)}>
                                        <div className="form-group" >
                                            <label className="col-form-label f12">Order Date</label>
                                            {sorder_date}

                                        </div>
                                        <div className="form-group" >
                                            <label className="col-form-label f12">Select Planning Duration</label>
                                            <Select
                                                placeholder={"Select"}
                                                onChange={this.slaChanged.bind(this)}
                                                name="sla"
                                                value={this.state.pla}
                                                options={[{value:"",label:"Select"},{value:1,label:"Single Day"}, {value:2,label:"Multiple Days"}]}
                                            />

                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group" >
                                                <label className="col-form-label f12">Start Date</label>
                                                <input type="text" id="fDate" name="fDate" className="form-control" readOnly />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group" >
                                                <label className="col-form-label f12">End Date</label>
                                                <input type="text" id="tDate" name="tDate" className="form-control" readOnly />
                                            </div>
                                        </div>             
                                        <div className="col-md-3">
                                            <button type="submit" className="btn btn-warning mt-30p">Submit</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>

            </div>
            <div className={"loader " + this.state.loadshow}></div>
            </>
        )
    }
}

export default withTranslation()(LoadProcessRun)
$( function() {
    $( ".datepicker" ).datepicker({
        dateFormat:"dd-mm-yy",
        changeYear:true
    });
} );


function GetSortAscOrder(prop) {    
    return function(a, b) {    
        if (a[prop] > b[prop]) {    
            return 1;    
        } else if (a[prop] < b[prop]) {    
            return -1;    
        }    
        return 0;    
    }    
} 

function GetSortDescOrder(prop) {    
    return function(a, b) {    
        if (a[prop] < b[prop]) {    
            return 1;    
        } else if (a[prop] > b[prop]) {    
            return -1;    
        }    
        return 0;    
    }    
} 
function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}
